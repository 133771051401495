import _ from 'lodash';
import { Subject } from './subjects';
import allSubjects from './subjects/subjects_all';
import { Option } from '@/api/types';

export interface Institution extends Option {
    subjects: Subject[];
}

export const InstitutionName: any = {
    BAR01: 'Baruch College',
    BMC01: 'Borough of Manhattan CC',
    BCC01: 'Bronx CC',
    BKL01: 'Brooklyn College',
    CTY01: 'City College',
    CSI01: 'College of Staten Island',
    HOS01: 'Hostos CC',
    HTR01: 'Hunter College',
    JJC01: 'John Jay College',
    KCC01: 'Kingsborough CC',
    LAG01: 'LaGuardia CC',
    LEH01: 'Lehman College',
    MEC01: 'Medgar Evers College',
    NYT01: 'NYC College of Technology',
    QNS01: 'Queens College',
    QCC01: 'Queensborough CC',
    SLU01: 'School of Labor & Urban Studies',
    YRK01: 'York College',

    // MHC01: 'Macaulay Honors College',
    // MED01: 'School of Medicine',
    // SPS01: 'School of Professional Studies',
    // LAW01: 'School of Law',
    // SOJ01: 'School of Journalism',
    // SPH01: 'School of Public Health',
    // NCC01: 'Guttman CC',
    // GRD01: 'Graduate Center',
};

const institutions: Institution[] = [
    {
        value: 'BAR01',
        label: 'Baruch College',
        subjects: []
    },
    {
        value: 'BMC01',
        label: 'Borough of Manhattan CC',
        subjects: []
    },
    {
        value: 'BCC01',
        label: 'Bronx CC',
        subjects: []
    },
    {
        value: 'BKL01',
        label: 'Brooklyn College',
        subjects: []
    },
    {
        value: 'CTY01',
        label: 'City College',
        subjects: []
    },
    {
        value: 'CSI01',
        label: 'College of Staten Island',
        subjects: []
    },
    // {
    //     value: 'GRD01',
    //     label: 'Graduate Center',
    //     subjects: []
    // },
    // {
    //     value: 'NCC01',
    //     label: 'Guttman CC',
    //     subjects: []
    // },
    {
        value: 'HOS01',
        label: 'Hostos CC',
        subjects: []
    },
    {
        value: 'HTR01',
        label: 'Hunter College',
        subjects: []
    },
    {
        value: 'JJC01',
        label: 'John Jay College',
        subjects: []
    },
    {
        value: 'KCC01',
        label: 'Kingsborough CC',
        subjects: []
    },
    {
        value: 'LAG01',
        label: 'LaGuardia CC',
        subjects: []
    },
    {
        value: 'LEH01',
        label: 'Lehman College',
        subjects: []
    },
    // {
    //     value: 'MHC01',
    //     label: 'Macaulay Honors College',
    //     subjects: []
    // },
    {
        value: 'MEC01',
        label: 'Medgar Evers College',
        subjects: []
    },
    {
        value: 'NYT01',
        label: 'NYC College of Technology',
        subjects: []
    },
    {
        value: 'QNS01',
        label: 'Queens College',
        subjects: []
    },
    {
        value: 'QCC01',
        label: 'Queensborough CC',
        subjects: []
    },
    // {
    //     value: 'SOJ01',
    //     label: 'School of Journalism',
    //     subjects: []
    // },
    {
        value: 'SLU01',
        label: 'School of Labor & Urban Studies',
        subjects: []
    },
    // {
    //     value: 'LAW01',
    //     label: 'School of Law',
    //     subjects: []
    // },
    // {
    //     value: 'MED01',
    //     label: 'School of Medicine',
    //     subjects: []
    // },
    // {
    //     value: 'SPS01',
    //     label: 'School of Professional Studies',
    //     subjects: []
    // },
    // {
    //     value: 'SPH01',
    //     label: 'School of Public Health',
    //     subjects: []
    // },
    {
        value: 'YRK01',
        label: 'York College',
        subjects: []
    }
];

institutions.forEach(o => {
    o.subjects = _.filter(allSubjects, {institution: o.value});
});

export const getInstitutionSubjectById = (institutionId: string, subjectId: string): Subject | undefined => {
    const institution = _.find(institutions, {value: institutionId});
    const subject = _.find(institution?.subjects, {value: subjectId});
    return subject;
};

export const getUndergradCareerCode = (institutionId: string): 'UGRD' | 'UKCC' | 'ULAG' => {
    if (institutionId === 'KCC01') return 'UKCC';
    if (institutionId === 'LAG01') return 'ULAG';
    return 'UGRD';
};

export default institutions;
