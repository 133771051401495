import { QuestionConditionEnum, SimulationChoice, SimulationQuestion } from '@/constants/questions';
import { SelectedCourse } from '@/api/types';
import _ from 'lodash';
import { parseCourseShortName, parseMeetingPattern, prependArticle } from '@/api/transformers';
import { InstructionModeSurveyFormat } from '@/constants/instructionModes';

const choices: SimulationChoice[] = [
    {
        value: 'FlexibleSchedule',
        order: 1,
        label: 'I need a flexible schedule because of work and/or family responsibilities.',
        P: false,
        OS: false,
        OA: true,
    },
    {
        value: 'ReduceCommute',
        order: 2,
        label: 'I want to reduce commute time.',
        P: false,
        OS: true,
        OA: true,
    },
    {
        value: 'Disability',
        order: 3,
        label: 'I have an illness/injury/disability (including mental health), and I find that {{originalCourseMode}} courses better meet my needs.',
        P: true,
        OS: true,
        OA: true,
    },
    {
        value: 'Pandemic',
        order: 4,
        label: 'I do not feel comfortable attending classes in person because of the pandemic.',
        P: false,
        OS: true,
        OA: true,
    },
    {
        value: 'ModeBetterFit',
        order: 5,
        label: 'I find that {{originalCourseMode}} courses fit my learning style.',
        P: true,
        OS: true,
        OA: true,
    },
    {
        value: 'LecturesInefficient',
        order: 6,
        label: 'I can learn more efficiently when I don’t have to sit through class lectures.',
        P: false,
        OS: false,
        OA: true,
    },
    {
        value: 'UncomfortableInPerson',
        order: 7,
        label: 'I am more comfortable reading and writing than speaking up in person in class.',
        P: false,
        OS: true,
        OA: true,
    },
    {
        value: 'PreferSynchronous',
        order: 8,
        label: 'I would prefer to take a synchronous course (with online or on-campus meetings), but I could not find course meetings times that worked with my schedule.',
        P: false,
        OS: false,
        OA: true,
    },
    {
        value: 'SyncMoreStructured',
        order: 9,
        label: 'I need the structure of regular class meetings to stay motivated and on track.',
        P: true,
        OS: true,
        OA: false,
    },
    {
        value: 'LecturesLearnBetter',
        order: 10,
        label: 'I learn better from class lectures than from other teaching approaches.',
        P: true,
        OS: true,
        OA: false,
    },
    {
        value: 'NotIsolated',
        order: 11,
        label: 'I feel isolated if I don’t have regular class meetings where I can see my professor and classmates live.',
        P: true,
        OS: true,
        OA: false,
    },
    {
        value: 'MoreComfortableInClass',
        order: 12,
        label: 'I am more comfortable listening and speaking in class than I am reading and writing.',
        P: true,
        OS: true,
        OA: false,
    },
    {
        value: 'DifficultyWithTechnology',
        order: 13,
        label: 'I have difficulty using the technology needed for online courses (e.g., don’t have access to it, don’t know how to use it).',
        P: true,
        OS: false,
        OA: false,
    },
    {
        value: 'PreferAlternateMode',
        order: 14,
        label: 'I would have preferred to take {{alternateAan}} {{alternateCourseMode}} section, but this course was only offered as {{originalAan}} {{originalCourseMode}} course.',
        P: true,
        OS: true,
        OA: true,
    },
    {
        value: 'Other',
        order: 15,
        label: 'Other:___________________________________________________',
        P: true,
        OS: true,
        OA: true,
    },
]
;

const _question: SimulationQuestion = {
    id: QuestionConditionEnum.OriginalCourseSelection,
    label: 'Why did you choose to take {{originalCourseName}} as {{originalAan}} <b>{{originalCourseMode}}</b> course?',
    type: 'checkbox',
    choices,
};

export const getOriginalReasonQuestion = (originalClass: SelectedCourse): SimulationQuestion => {
    const question = _.cloneDeep(_question);

    const originalCourseName = parseCourseShortName(originalClass);
    const originalCourseMode = originalClass.sectionMode!;
    let originalCourseMeetingTimes = '';
    if (originalCourseMode !== 'OA') {
        originalCourseMeetingTimes = originalClass.selectedSections.flatMap(o => o.meetings.map((m: any) => parseMeetingPattern(m))).join(' ');
    }
    const originalAan = prependArticle(InstructionModeSurveyFormat[originalCourseMode]);


    const alternateCourseMode = originalCourseMode === 'OA' ? 'synchronous online or on campus' : 'asynchronous online';
    const alternateAan = prependArticle(alternateCourseMode);

    const questionText = _.upperFirst(question.label
        .replace(/{{originalAan}}/g, originalAan)
        .replace(/{{originalCourseName}}/g, originalCourseName)
        .replace(/{{originalCourseMode}}/g, InstructionModeSurveyFormat[originalCourseMode] || '')
        .replace(/{{originalCourseMeetingTimes}}/g, originalCourseMeetingTimes)
        .replace(/{{alternateCourseMode}}/g, alternateCourseMode)
        .replace(/{{alternateAan}}/g, alternateAan)
    );
    const choices = question.choices.map(choice => ({
        ...choice,
        label: _.upperFirst(choice.label
            .replace(/{{originalAan}}/g, originalAan)
            .replace(/{{originalCourseName}}/g, originalCourseName)
            .replace(/{{originalCourseMode}}/g, InstructionModeSurveyFormat[originalCourseMode] || '')
            .replace(/{{originalCourseMeetingTimes}}/g, originalCourseMeetingTimes)
            .replace(/{{alternateCourseMode}}/g, alternateCourseMode)
            .replace(/{{alternateAan}}/g, alternateAan)
        )
    })) as SimulationChoice[];

    const filteredChoices: SimulationChoice[] = _.filter(choices, choice => {
        return choice[originalCourseMode];
    });

    return {
        id: question.id,
        type: question.type,
        label: questionText,
        choices: filteredChoices
    };
};


export default _question;
