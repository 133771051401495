import { useCallback } from 'react';
import { initSimulationData } from '@/store/simulation/simulationUtils';
import { formatISO } from 'date-fns';
import _ from 'lodash';
import { useGetSimResults, useUpdateSimResults } from '@/api/simulation/SimulationAPI';
import { hashSelectedCourse } from '@/api/transformers';

// aka "Starting" the simulation.
// We also handle removing the unavailable course from the shopping cart
// based on whichever currentStage is calculated.
export default (): () => Promise<any> => {
    const simResults = useGetSimResults();
    const updateSimResults = useUpdateSimResults();

    return useCallback<() => Promise<any>>(() => {
        // If we're starting a brand new simulation then we use simResults.shoppingCart
        // Otherwise when restarting via the SimulationDebugger, we'll want to take the originalSubmittedCart
        // since the parent shoppingCart is also being used to make simulation choices.
        const originalCart = simResults.data?.originalSubmittedCart?.length ? simResults.data.originalSubmittedCart : simResults.shoppingCart;

        const simData = initSimulationData(originalCart, {
            startedTs: formatISO(new Date()),
            availableTimes: simResults.availableTimes,
        });

        const updatedCart = _.cloneDeep(originalCart);

        const unavailable = simData.currentStage?.unavailableClass;
        if (unavailable) {
            const unavailableHash = hashSelectedCourse(unavailable);
            _.remove(updatedCart, o => hashSelectedCourse(o) === unavailableHash);
        }

        return updateSimResults({
            shoppingCart: updatedCart,
            data: simData
        });
    }, [ simResults ]);
};
