import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import 'react-datepicker/dist/react-datepicker.css';
import { isValid, setHours, startOfDay } from 'date-fns';

export const TimeSlotPickerWrapperStyle = styled.div`
    
    input {
        width: 75px;
    }
    .react-datepicker-time__header {
        font-weight: 500;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
        font-weight: 500;
    }

    
    .react-datepicker__close-icon {
        right: -5px;
    }
    & .react-datepicker, & .react-datepicker__day {
        ${tw`font-sans text-gray-700`}
        
        &.react-datepicker__day--disabled {
            ${tw`text-gray-400`}
        }
        
        
        &.react-datepicker__day--selected, &.react-datepicker__day--selected.react-datepicker__day--today, &.react-datepicker__day--keyboard-selected {
            ${tw`text-blue-100 bg-blue-500`}
        }
    }
    
    & .react-datepicker-wrapper {
        ${tw`w-full`}
    }
    
    & .react-datepicker__input-container {
        & .react-datepicker__close-icon {
            &::after {
                ${tw`bg-transparent text-transparent text-base transition-colors duration-150`}
            }
        }
        
        &:hover .react-datepicker__close-icon {
            &::after {
                ${tw`text-gray-500`}
            }
            
            &:hover::after {
                ${tw`text-red-500`}
            }
        }
    }
    
    & .react-datepicker__input-container > input, & .react-datepicker__input-container, & input.react-datepicker-time__input {
        &:focus, &:hover, &:focus-within, &.react-datepicker-ignore-onclickoutside {
            outline: 0;
        }
        
        &::placeholder {
            ${tw`text-gray-500!`}
        }
    }
    
    & .react-datepicker-time__caption {
        ${tw`text-base font-medium`}
    }
    
    & .react-datepicker__input-time-container div.react-datepicker-time__input {
        ${tw`w-24`}
        
        & input {
            ${tw`w-full`}
        }
    }
    
    & .react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
        ${tw`hidden`}
    }
`;
TimeSlotPickerWrapperStyle.displayName = 'TimeSlotPickerWrapperStyle';

interface Props {
    onChange: (date: Date | null) => void;
}

export default ({ onChange }: Props) => {

    const [ internal, setInternal ] = useState<Date | null>(null);

    const onInternalChange = (value: Date | null) => {
        if (!value) {
            onChange(null);
        }

        setInternal(value);
    };

    const onCalendarClose = () => {
        // If they haven't picked a time when this is changing, just cancel.
        if (internal && internal.getHours() === 0) {
            setInternal(null);
            return;
        }

        onChange(isValid(internal) ? internal : null);
    };

    return (
        <TimeSlotPickerWrapperStyle>
            <DatePicker
                selected={internal}
                onChange={onInternalChange}
                onCalendarClose={onCalendarClose}
                showTimeSelect
                showTimeSelectOnly
                isClearable
                minTime={setHours(startOfDay(new Date()), 7)}
                maxTime={setHours(startOfDay(new Date()), 20)}
                timeIntervals={5}
                timeCaption="Time"
                dateFormat="h:mm aa"
            />
        </TimeSlotPickerWrapperStyle>
    );
};
