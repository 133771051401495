import { activeTerm, summerTerm } from '@/constants/terms';
import { InstitutionName } from '@/constants/institutions';
import { InstitutionOption } from '@/api/types';

// Since some schools didn't publish their summer schedules in time, we fallback to using the regular semester schedule.
// Term values pre-generated by querying the dataset in postgres
/*
select distinct on (institution) institution, strm
from ps_class_tbl where
(strm = '1226' or strm = '1219')
and (institution = 'BAR01' or institution = 'BCC01' or institution = 'BKL01' or institution = 'BMC01' or institution = 'CSI01' or institution = 'CTY01' or institution = 'HOS01' or institution = 'HTR01' or institution = 'JJC01' or institution = 'KCC01' or institution = 'LAG01' or institution = 'LEH01' or institution = 'MEC01' or institution = 'NYT01' or institution = 'QCC01' or institution = 'QNS01' or institution = 'SLU01' or institution = 'YRK01')
group by institution, strm
order by institution asc, strm desc;
 */
export const summerInstitutions: InstitutionOption[] = [
    {
        value: 'BAR01',
        label: InstitutionName.BAR01,
        terms: [ summerTerm.id ],
    },
    {
        value: 'BCC01',
        label: InstitutionName.BCC01,
        terms: [ summerTerm.id ],
    },
    {
        value: 'BKL01',
        label: InstitutionName.BKL01,
        terms: [ summerTerm.id ],
    },
    {
        value: 'BMC01',
        label: InstitutionName.BMC01,
        terms: [ summerTerm.id ],
    },
    {
        value: 'CSI01',
        label: InstitutionName.CSI01,
        terms: [ summerTerm.id ],
    },
    {
        value: 'CTY01',
        label: InstitutionName.CTY01,
        terms: [ summerTerm.id ],
    },
    {
        value: 'HOS01',
        label: InstitutionName.HOS01,
        terms: [ activeTerm.id, '1222' ],
    },
    {
        value: 'HTR01',
        label: InstitutionName.HTR01,
        terms: [ summerTerm.id ],
    },
    {
        value: 'JJC01',
        label: InstitutionName.JJC01,
        terms: [ activeTerm.id, '1222' ],
    },
    {
        value: 'KCC01',
        label: InstitutionName.KCC01,
        terms: [ activeTerm.id, '1222' ],
    },
    {
        value: 'LAG01',
        label: InstitutionName.LAG01,
        terms: [ activeTerm.id, '1222' ],
    },
    {
        value: 'LEH01',
        label: InstitutionName.LEH01,
        terms: [ summerTerm.id ],
    },
    {
        value: 'MEC01',
        label: InstitutionName.MEC01,
        terms: [ activeTerm.id, '1222' ],
    },
    {
        value: 'NYT01',
        label: InstitutionName.NYT01,
        terms: [ summerTerm.id ],
    },
    {
        value: 'QCC01',
        label: InstitutionName.QCC01,
        terms: [ summerTerm.id ],
    },
    {
        value: 'QNS01',
        label: InstitutionName.QNS01,
        terms: [ summerTerm.id ],
    },
    {
        value: 'SLU01',
        label: InstitutionName.SLU01,
        terms: [ activeTerm.id, '1222' ],
    },
    {
        value: 'YRK01',
        label: InstitutionName.YRK01,
        terms: [ activeTerm.id, '1222' ],
    },
];

export const getSummerTerms = (institution: string): string[] => {
    const match = summerInstitutions.find(o => o.value === institution);
    return match?.terms || [ activeTerm.id ];
};

