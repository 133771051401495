import React, { useState } from 'react';
import config from '@/config';
import tw from 'twin.macro';
import { Button, Spinner } from '@/components/common';
import styled from 'styled-components/macro';
import { useStoreActions } from '@/store';
import { useHistory } from 'react-router-dom';
import { SimulationDebuggerStyle } from '@/components/simulation/SimulationDebugger';
import { ErrorCodeEnum, ErrorCodeMessages } from '@/api/errors';

const ContainerStyle = styled.div`
    ${tw`w-full h-full min-h-screen`}
    
    ${tw`pt-4 pb-4 px-4 md:px-12 max-w-screen-xl`}
`;

const EntryScreen = () => {
    const history = useHistory();

    const [ cunyId, setCunyId ] = useState<string>('');
    const [ error, setError ] = useState<string>('');
    const [ submitting, setSubmitting ] = useState<boolean>(false);

    const [ replaceScreen, setReplaceScreen ] = useState<string>('');

    const login = useStoreActions(actions => actions.login);

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (cunyId === 'error') {
            throw new Error('testing error capturing');
        }

        if (submitting || !cunyId || cunyId.length < 8) return;

        setError('');
        setSubmitting(true);

        login({studentId: cunyId}).then(errMsg => {
            if (!errMsg) {
                history.replace('/');
            } else {
                setSubmitting(false);
                if (errMsg === ErrorCodeEnum.INVALID_COHORT) {
                    setReplaceScreen(ErrorCodeMessages.INVALID_COHORT);
                } else {
                    setError(errMsg);
                }
            }
        });
    };

    if (replaceScreen) {
        return (
            <ContainerStyle>
                <div css={tw`mb-3 text-center`}>
                    <p css={tw`mt-8 text-lg`}>{replaceScreen}</p>
                </div>
            </ContainerStyle>
        );
    }
    return (
        <ContainerStyle>
            <form onSubmit={(e) => onSubmit(e)}>
                <h3 css={tw`mb-8 font-medium underline text-xl`}>Registration Simulation Entry</h3>

                <div css={tw`mb-3 text-center`}>
                    <p css={tw`inline-block font-medium pr-2 text-lg`}>To start the survey, please enter your CUNYfirst ID number:</p>
                    <input css={tw`align-middle py-2 px-3`} value={cunyId} onChange={(e: any) => setCunyId(e.target.value)}/>
                </div>

                <p css={tw`mb-3 font-medium text-center text-lg`}>
                    This number is only used to identify which students have completed the survey so that we know not to contact you again once you have completed - it will NOT be linked to your survey responses in the final data files.
                </p>

                <p css={tw`mb-3 text-center text-lg`}>
                    Your CUNYfirst ID (EMPLID) is the student ID number given on your CUNY ID card. It is also visible in your CUNYfirst account if you ever forget or lose the number.
                </p>
                <p css={tw`mb-3 text-center text-lg`}>
                    If you are clicking on the link to this survey that was sent to you by email, this step should be unnecessary. So, if you have trouble finding your CUNYfirst ID, you can also try copying or clicking on the full link sent to you originally via email.
                </p>

                {
                    error ?
                        <div css={[ tw`w-full mt-4 bg-red-200 p-2 text-red-600 rounded mb-2 text-center` ]}>
                            <span css={[ tw`font-medium` ]}>Error:</span> {error}
                        </div>
                        : null
                }

                <div css={tw`mt-4 flex justify-center`}>
                    <div style={{
                        width: '120px'
                    }}
                    >
                        {
                            submitting ?

                                <Spinner size={'base'} />
                                : <Button disabled={!cunyId} css={tw`text-lg`} type="submit">Continue</Button>
                        }
                    </div>
                </div>

            </form>

            {
                config.debug ?
                    <SimulationDebuggerStyle>
                        <h3 css={tw`font-normal text-2xl mb-2`}>Simulation Debugger (Will not be shown in live version)</h3>

                        <p>Test Accounts</p>
                        <ul>
                            <li>55511400 (Construction Technology)</li>
                            <li>75511466 (Design)</li>
                            <li>82161789 (Business Administration)</li>
                            <li>44331789 (Art Foundations Studio Art)</li>
                            <li>66631789 (Accounting)</li>

                        </ul>
                    </SimulationDebuggerStyle>
                    : null
            }


        </ContainerStyle>
    );
};

export default EntryScreen;
