import { gql } from '@apollo/client';
import graphClient from '@/api/graph';

export const GET_COURSES = gql`
query getCourses(
    $filter: custom_crse_offer_view_bool_exp,
) {
    items: custom_crse_offer_view(
        where:$filter,
    ) {
        courseId: crse_id
        subject
        courseNumber: catalog_nbr
        institution
        units: units_acad_prog
        academicGroup: acad_group
    }
}
`;

export type CourseExtract = {
    units: number;
    courseId: string;
    subject: string;
    courseNumber: string;
    institution: string;
    academicGroup: string;

    // if transferring in
    sendingCourseId?: string;
}
export const getCoursesByIds = async (courseIds: string[]): Promise<CourseExtract[]> => {
    if (!courseIds?.length) {
        return [];
    }
    const results = await graphClient.query({
        query: GET_COURSES,
        variables: {
            filter: {
                crse_id: {
                    _in: courseIds
                }
            }
        },
        fetchPolicy: 'cache-first'
    });

    return results.data.items || [];

};
