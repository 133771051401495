import { gql } from '@apollo/client';

export default gql`
fragment meetingPatterns on custom_class_mtg_pat {
    meetingNumber: class_mtg_nbr,
    startTime: meeting_time_start,
    endTime: meeting_time_end,
    mon,
    tue: tues,
    wed,
    thu:thurs,
    fri,
    sat,
    sun,
}
`;
