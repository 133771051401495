import tw from 'twin.macro';
import styled from 'styled-components/macro';

export const ConflictingClassContainerStyle = styled.div`
    ${tw`mx-4 text-center flex flex-col justify-between`}
    
    p {
        ${tw`text-sm`}
    }
    p:first-child {
        ${tw`font-medium underline`}
    }
    
    button {
        ${tw`mt-3`}
    }
`;
