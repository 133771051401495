import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';

const StyledTextArea = styled.textarea`
    resize: none;
    ${tw`w-full text-dark text-base`}
    padding: 0.5rem;
    font-family: 'Brandon';
`;

const TextArea = (props: any) => {
    return (
        <StyledTextArea {...props} />
    );
};

export { TextArea };
