import React from 'react';
import { RequisiteCourse } from '@/api/types';
import tw from 'twin.macro';

interface Props {
    course: RequisiteCourse;
    onClick: (course: RequisiteCourse) => void;
}

export default ({ course, onClick }: Props) => {
    return (
        <a onClick={() => onClick(course)} css={tw`text-link cursor-pointer text-sm font-medium`}>
            {course.subject + ' ' + course.courseNumber}
        </a>
    );
};
