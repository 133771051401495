import React, { CSSProperties, useEffect, useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components/macro';
import { Overlay } from './index';
import { TransitionProps } from 'react-transition-group/Transition';
import { breakpoint } from '@/components/common/theme';

const ModalCloseIcon = styled.div`
    ${tw`absolute right-0 top-0 mt-2 mr-2 p-2 cursor-pointer`};
    
    & > svg {
        ${tw`text-xl text-gray-300 transition-all duration-150`};
    }
    
    &:hover > svg {
        ${tw`text-blue-600`};
        transform: rotate(90deg);
    }
`;

const ModalContent = styled.div`
    ${tw`my-1 sm:mt-16 bg-white shadow-lg border border-gray-200 rounded overflow-x-hidden overflow-y-auto`};
    
    max-height: calc(100vh - 0.5rem);
    ${breakpoint('sm')`
        height: auto;
        max-height: calc(100vh - 8rem);
    `};
`;

const Container = styled.div<{ fullscreen?: boolean }>`
    ${tw`mx-1 w-full md:mx-auto md:w-3/4 lg:w-1/2`};
    
    ${props => props.fullscreen && tw`md:w-3/4 lg:w-3/4`};
`;

export interface BaseModalProps {
    visible: boolean;
    children: React.ReactNode;
    appear?: boolean;
    dismissable?: boolean;
    onDismissed?: () => void;
    onBeforeDismiss?: () => boolean;
    style?: CSSProperties;
    fullscreen?: boolean;
    overlayMode?: 'light' | 'dark';
}

type ModalProps = BaseModalProps
    & Omit<TransitionProps, 'in' | 'appear' | 'duration' | 'classNames' | 'onExit' | 'unmountOnExit'>;

const Modal = ({ visible, children, fullscreen, dismissable = true, onBeforeDismiss, style, ...props }: ModalProps) => {
    const [ render, setRender ] = useState(visible);

    useEffect(() => setRender(visible), [ visible ]);

    const dismiss = () => {
        if (!dismissable || (onBeforeDismiss && !onBeforeDismiss())) {
            return;
        }

        setRender(false);
    };

    return (
        <Overlay overlayMode="light" visible={render} withContainer={false} {...props}>
            <div
                onClick={dismiss}
                css={tw`top-0 left-0 fixed w-full h-screen flex justify-center`}
                style={{
                    background: 'rgba(0, 0, 0, 0.7)',
                    zIndex: 9999,
                }}
            >
                <Container fullscreen={fullscreen}>
                    <ModalContent onClick={e => e.stopPropagation()} style={{ ...style }}>
                        <div css={tw`p-4 sm:p-10 relative`}>
                            {dismissable &&
                            <ModalCloseIcon onClick={dismiss}>
                                <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 352 512'} css={tw`w-4`}>
                                    <path
                                        fill={'currentColor'}
                                        d={'M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'}
                                    />
                                </svg>
                            </ModalCloseIcon>
                            }
                            <div css={tw`mt-8 sm:mt-0`}>
                                {children}
                            </div>
                        </div>
                    </ModalContent>
                </Container>
            </div>
        </Overlay>
    );
};

export { Modal };
