import { useEffect, useState } from 'react';
// import {
//     CourseClassExtract,
//     RequisiteCourse,
//     RequisiteGrouping,
// } from '@/api/types';
import { useUpdateSimResults } from '@/api/simulation/SimulationAPI';
// import _ from 'lodash';
import {
    useStudentDetails,
    // RequisiteStatus,
    // validateRequirements,
} from '@/utils/enrollmentUtils';
// import { getClassById } from '@/api/graphql/queries/getClassById';
// import { getRequisiteCourses } from '@/utils/EnrollmentValidator';
// import { getTransferRules } from '@/utils/transferUtils';
// import { CourseExtract } from '@/api/graphql/queries/getCourses';
// import * as Sentry from '@sentry/react';
import { CartCoreqError } from './api/errors';

interface ShoppingCartHooks {
    coReqsErrors: CartCoreqError[];
    // validateShoppingCart: (forceUpdate: boolean) => Promise<void>;
}

export default (): ShoppingCartHooks => {
    const updateSimResults = useUpdateSimResults();

    const studentDetails = useStudentDetails();
    const { shoppingCart } = studentDetails;

    const [ _coReqsErrors, _setCoReqsErrors ] = useState<CartCoreqError[]>([]);

    // const validateShoppingCart = (forceUpdate = false) => {
    //     console.log('validateShoppingCart');
    //     return new Promise<void>((resolve, reject) => {
    //         const updatedCart = _.cloneDeep(shoppingCart);
    //
    //         const corequisiteErrors: CartCoreqError[] = [];
    //
    //         const promises = Promise.all(updatedCart.map(async classItem => {
    //             const selectedClass = await getClassById(parseInt(classItem.classId));
    //             let result = validateRequirements(selectedClass!, studentDetails);
    //
    //             // only validate w/ transferRules if necessary
    //             if (!result.result) {
    //                 console.log('validating with transferRules for class: ', selectedClass!.subject, selectedClass!.courseNumber);
    //                 const transferCourses: CourseExtract[] = await getTransferRules(selectedClass!, studentDetails);
    //                 result = validateRequirements(selectedClass!, {
    //                     ...studentDetails,
    //                     transferCourses,
    //                 });
    //             }
    //
    //             let newStatus: RequisiteStatus;
    //
    //             if (result.result) {
    //                 newStatus = RequisiteStatus.Allowed;
    //             } else if (result.whatIfAddedCoreqsResult) {
    //
    //                 newStatus = RequisiteStatus.MissingCoReq;
    //
    //                 result.coreqsRelatedGroupings.forEach((o) => {
    //                     let requisiteCourses: RequisiteCourse[] = [];
    //                     const courses: RequisiteCourse[] = getRequisiteCourses(o);
    //                     requisiteCourses = requisiteCourses.concat(courses);
    //
    //                     for (let i = 0; i < requisiteCourses.length; i++) {
    //                         for (let j = 0; j < updatedCart.length; j++) {
    //                             if (requisiteCourses[i].courseId === updatedCart[j].courseId) {
    //                                 return;
    //                             }
    //                         }
    //                     }
    //
    //                     const error: CartCoreqError = {
    //                         course: classItem,
    //                         message: '',
    //                         requisiteCourses,
    //                     };
    //
    //                     corequisiteErrors.push(error);
    //                 });
    //
    //                 console.log('corequisiteErrors: ', corequisiteErrors);
    //
    //
    //             } else {
    //                 // console.warn('Item in cart contains unhandled requisite error. Most likely due to already existing in cart.', result);
    //                 newStatus = RequisiteStatus.UnknownError;
    //             }
    //             if (classItem.requisiteStatus !== newStatus) {
    //                 classItem.requisiteStatus = newStatus;
    //             }
    //         }));
    //
    //         promises
    //             .then(() => {
    //                 _setCoReqsErrors(corequisiteErrors);
    //             })
    //             .catch(err => {
    //                 console.error('validate requirements error:', err);
    //                 Sentry.captureException(err);
    //                 reject(err);
    //             });
    //     });
    //
    // };

    useEffect(() => {
        // validateShoppingCart();
    }, [ shoppingCart ]);

    return {
        coReqsErrors: _coReqsErrors,
        // validateShoppingCart
    };
};
