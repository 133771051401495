import http from '@/api/http';

export type AccountRaw = {
    emplid: string;
    is_dev: boolean;
}

export default async (): Promise<AccountRaw[]> => {
    const endpoint = '/accounts';
    const { data } = await http.get(endpoint);
    console.log('data: ', data);
    return data.results || [];
};
