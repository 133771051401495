import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { Button } from '../common';
import { useHistory } from 'react-router-dom';

const ContainerStyle = styled.div`
    ${tw`w-full h-full min-h-screen`}

    ${tw`pt-4 pb-4 px-4 md:px-12 max-w-screen-xl`}
`;

export default () => {
    const history = useHistory();

    const onBack = () => {
        history.push('/availability');
    };
    const onNext = () => {
        history.push('/search');
    };

    return (
        <ContainerStyle>
            <p>
                On the next page, you will be asked to pretend to select courses for fall.  This is <strong>NOT</strong> real--it is a <strong>pretend schedule</strong> so that we can investigate how students choose classes and how they are impacted when they can't get classes they need.
                <br/><br/>
                <strong>Please forget for a moment the courses you may have already chosen for fall 2023, and choose the courses that you would take in fall if this pretend schedule were real.</strong>  After you have selected your pretend schedule, we will ask you follow-up questions about your choices.
                <br/><br/>
                <strong>Anything you do here will NOT impact your actual registration.</strong>  If you have not yet registered, you will <strong>still</strong> need to register in CUNYFirst.  If you have already registered, <strong>nothing</strong> you do here will change your course schedule.  This is just a <strong>pretend</strong> system that we are using for research.
            </p>

            <div css={tw`mt-8 flex justify-between`}>
                <Button type="button" onClick={onBack}>Back</Button>
                <Button type="button" onClick={onNext}>Next</Button>
            </div>
            {/* <PageControlsStyle> */}
            {/*    <div className="content-container"> */}
            {/*        <Button type="button" onClick={onBack}>Back</Button> */}
            {/*        <Button type="button" onClick={onNext}>Next</Button> */}
            {/*    </div> */}
            {/* </PageControlsStyle> */}
        </ContainerStyle>
    );
};
