import React, { useMemo, useState } from 'react';
import { CourseClassWithGroupData, RequirementGroupDetails, RequirementLine, RequisiteCourse } from '@/api/types';
import { InfoSectionContainerStyle } from '@/components/class-detail/styled';
import PropertyInfo from '@/components/class-detail/PropertyInfo';
import { formatGPA, formatGradePoints, parseRequisiteType } from '@/api/transformers';
import {
    parseParenthesesDepth,
    RequirementGroupDetailsWithDepth
} from '@/utils/catalogUtils';
import tw from 'twin.macro';
import styled from 'styled-components/macro';
import RequirementCourseLink from '@/components/class-detail/requirements/RequirementCourseLink';
import RequirementLineCourseList from '@/components/class-detail/requirements/RequirementLineCourseList';
import ConditionInfo from '@/components/class-detail/requirements/conditions/ConditionInfo';
import { RequirementsValidationResult, useStudentDetails, validateRequirements } from '@/utils/enrollmentUtils';
import { getTransferRules } from '@/utils/transferUtils';
import { Spinner } from '@/components/common';

interface Props {
    selectedClass: CourseClassWithGroupData | null;
    goToClassResults: (course: RequisiteCourse) => void;
}

interface RequirementContainerStyleProps {
    depth: number;
    parenthesis: '(' | ')' | '';
}
const RequirementContainerStyle = styled.div<RequirementContainerStyleProps>`
    p {
        ${tw`text-sm`}
    }
    > * {
        margin-left: ${(props) => props.depth}rem;
    }
    
    p.connector {
        margin-left: ${(props) => props.parenthesis === '(' ? props.depth - 1 : props.depth}rem;
        ${tw`font-bold my-1`}
    }
    p.requirement-title {
        ${tw`text-base`}
        a {
            ${tw`text-base`}
        }
    }
    
    border: 1px solid rgb(74,89,140);
    ${tw`px-2 py-2 my-2`}
    
    .line-title-container, .requirement-title-container {
        ${tw`flex flex-row`}
    }
    .line-title {
        ${tw`font-medium underline`}
    }
    .line-detail-container {
        border: 1px solid #ccc;
        ${tw`px-2 py-2 my-2`}
        
        .detail-title {
            // ${tw`font-black`}
        }
    }
`;

export default ({ selectedClass, goToClassResults }: Props) => {
    const [ loading, setLoading ] = useState<boolean>(false);
    const studentDetails = useStudentDetails();

    const conditionGroups = useMemo<NonNullable<RequirementGroupDetails>[]>(() => {
        const results = selectedClass ? parseParenthesesDepth(selectedClass) : [];
        return results;
    }, [ selectedClass ]);

    const renderLineDetail = (detail: RequirementLine) => {
        if (detail.lineDetailType === 'CLST') {
            return (
                <RequirementLineCourseList goToClassResults={goToClassResults} detail={detail}/>
            );
        } else if (detail.lineDetailType === 'COND') {
            return <ConditionInfo detail={detail}/>;

        } else {
            return null;
        }
    };

    const renderRequirementLines = (lines: RequirementLine[]) => {
        // const requirementLines = parseRequirementLines(lines);

        const requirementLines = lines;
        return (
            <div>
                <p>Requirement Lines</p>
                <div className={'lines-container'} css={tw`ml-4`}>
                    {
                        requirementLines.map((lineDetails, index) => {

                            return (
                                <div className={'line-detail-container'} key={index}>
                                    <p css={tw`font-bold`}>
                                        {lineDetails.connect}
                                    </p>

                                    <div className={'line-title-container'}>
                                        { lineDetails.parenthesis === '(' ?
                                            <span css={tw`mx-2`}>(</span>
                                            : null
                                        }

                                        <p className={'line-title'}>{lineDetails.description}</p>

                                        { lineDetails.parenthesis === ')' ?
                                            <span css={tw`mx-2`}>)</span>
                                            : null
                                        }
                                    </div>
                                    <p>Details</p>
                                    <div css={tw`ml-4`}>
                                        {renderLineDetail(lineDetails)}
                                    </div>
                                </div>

                            );
                        })
                    }
                </div>
            </div>
        );
    };

    const renderGroupLine = (groupLine: RequirementGroupDetailsWithDepth) => {
        if (groupLine.groupLineType === 'CRSE') {
            return renderCourseGroupLine(groupLine);
        } else if (groupLine.groupLineType === 'CRSW') {
            return renderWildCardGroupLine(groupLine);
        } else if (groupLine.groupLineType === 'COND') {
            return <ConditionInfo detail={groupLine}/>;
        }

        return (
            <>
                <div className={'requirement-title-container'}>
                    { groupLine.parenthesis === '(' ?
                        <span css={tw`mx-2`}>(</span>
                        : null
                    }

                    <p className={'requirement-title'} css={tw`font-black`}>{groupLine.requirementId ? `[${groupLine.requirementId}] ` : ''}{groupLine.description}</p>

                    { groupLine.parenthesis === ')' ?
                        <span css={tw`mx-2`}>)</span>
                        : null
                    }
                </div>

                <p>Requisite Type: {parseRequisiteType(groupLine.requisiteType)}</p>

                {groupLine.requirements.length ? renderRequirementLines(groupLine.requirements) : null}
            </>
        );
    };

    const renderEquivalentCourses = (detail: RequirementGroupDetailsWithDepth) => {
        if (detail.includeEquivalent) {
            if (detail.includeEquivalent === 'N') {
                return <p>Include Equivalent Courses: No</p>;
            }
            if (detail.includeEquivalent === 'Y' && detail.course?.equivalentCourses?.length) {

                return (
                    <div>
                        <p css={tw`font-medium`}>Include Equivalent Courses:</p>
                        {
                            detail.course.equivalentCourses.map((r, index) => {
                                return (
                                    <span key={index}>
                                        {detail.course!.equivalentCourses!.length > 1 && index === detail.course!.equivalentCourses!.length - 1 ? ' or ' : ''}
                                        <RequirementCourseLink course={r} onClick={goToClassResults}/>
                                        {/* <span>[{r.institution}]</span> */}
                                        {detail.course!.equivalentCourses!.length > 1 && index !== detail.course!.equivalentCourses!.length - 1 ? ', ' : ''}
                                    </span>
                                );
                            })
                        }
                    </div>
                );
            }
        }
        return null;
    };

    const renderCourseParameters = (detail: RequirementGroupDetailsWithDepth) => {

        const minGrade = formatGradePoints(detail.minGradePoints);
        return (
            <>
                {detail.gpaRequired ? <p>GPA Required: {formatGPA(detail.gpaRequired)}</p> : null}
                {minGrade ? <p>Minimum Grade: {minGrade}</p> : null}

                {detail.minUnits ? <p>Minimum Units: {detail.minUnits}</p> : null}
                {detail.minUnitsRequired ? <p>Minimum Units Required: {detail.minUnitsRequired}</p> : null}

                {renderEquivalentCourses(detail)}
            </>
        );
    };

    const renderCourseGroupLine = (groupLine: RequirementGroupDetailsWithDepth) => {
        // may be null in the case of MAT 206.5's requirement of MAT 51IM, which we've removed from our custom catalog
        if (!groupLine.course) return null;
        return (
            <>
                <div className={'requirement-title-container'}>
                    { groupLine.parenthesis === '(' ?
                        <span css={tw`mx-2`}>(</span>
                        : null
                    }

                    <p className={'requirement-title'} css={tw`font-black`}>
                        <RequirementCourseLink course={groupLine.course} onClick={goToClassResults}/>
                    </p>

                    { groupLine.parenthesis === ')' ?
                        <span css={tw`mx-2`}>)</span>
                        : null
                    }
                </div>

                <p>Requisite Type: {parseRequisiteType(groupLine.requisiteType)}</p>

                {renderCourseParameters(groupLine)}
            </>
        );
    };

    const renderWildCardGroupLine = (groupLine: RequirementGroupDetailsWithDepth) => {
        return (
            <>
                <div className={'requirement-title-container'}>
                    { groupLine.parenthesis === '(' ?
                        <span css={tw`mx-2`}>(</span>
                        : null
                    }

                    <p className={'requirement-title'} css={tw`font-black`}>
                        Wild Card Course
                    </p>

                    { groupLine.parenthesis === ')' ?
                        <span css={tw`mx-2`}>)</span>
                        : null
                    }
                </div>

                <p>Requisite Type: {parseRequisiteType(groupLine.requisiteType)}</p>

                { groupLine.academicGroup && <p>Academic Group: {groupLine.academicGroup}</p> }
                { groupLine.subject && <p>Subject: {groupLine.subject}</p> }
                { groupLine.catalogNumberPattern && <p>Course Number Pattern: {groupLine.catalogNumberPattern}</p> }

                {renderCourseParameters(groupLine)}
            </>
        );
    };

    const [ validationResult, setValidationResult ] = useState<RequirementsValidationResult | null>(null);

    const onGenerateExpression = () => {
        setLoading(true);
        getTransferRules(selectedClass!, studentDetails)
            .then((transferCourses) => {
                const result = validateRequirements(selectedClass!, {
                    ...studentDetails,
                    transferCourses,
                });
                console.log('onGenerateExpression result: ', result);
                setValidationResult(result);
            })
            .catch(err => {
                console.error(err);
            })
            .then(() => setLoading(false));
    };

    const fetchTransferRules = () => {
        getTransferRules(selectedClass!, studentDetails)
            .then(result => {
                console.log('fetchTransferRules result: ', result);
            })
            .catch(err => console.error(err));
    };

    if (!selectedClass) return null;
    console.log('selectedClass: ', selectedClass);
    return (
        <InfoSectionContainerStyle>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />
            <h3>Enrollment Information (v3)</h3>
            {
                selectedClass.requirementGroupName ?
                    <div css={tw`mx-1`}>
                        <PropertyInfo label="Requirement Group" value={`[${selectedClass.requirementGroup}] ${selectedClass.requirementGroupName}`}/>
                        <PropertyInfo label="Group Description" value={selectedClass.requirementGroupDescriptionFull}/>
                    </div>
                    : null
            }

            {
            //    TODO: remove or comment out before deploying
                <div>
                    {/* <div><button type="button" onClick={fetchTransferRules}>Fetch Transfer Rules</button></div> */}
                    <div><button type="button" onClick={onGenerateExpression}>Generate boolean expression</button></div>
                    {
                        validationResult ?
                            <>
                                <p>{validationResult.expression}</p>
                                <p>{`Result: ${validationResult.errorMsg ? validationResult.errorMsg : validationResult.result}`}</p>
                                {
                                    validationResult.whatIfAddedCoreqsExpression ?
                                        <>
                                            <hr/>
                                            <p>What If Added All Corequisites</p>
                                            <p>{validationResult.whatIfAddedCoreqsExpression}</p>
                                            <p>{`Result: ${validationResult.whatIfAddedCoreqsResult}`}</p>

                                        </>
                                        : null
                                }

                            </>
                            : null
                    }
                </div>
            }

            {
                conditionGroups.length ?
                    <>
                        <p css={tw`px-1 text-cunyblue font-medium whitespace-no-wrap text-sm`}>Requirements</p>

                        <div css={tw`px-4 my-2`}>
                            {
                                conditionGroups.map((detail, i) => {

                                    return (
                                        <RequirementContainerStyle key={i} depth={detail.depth} parenthesis={detail.parenthesis}>
                                            <p className={'connector'}>{detail.connect}</p>
                                            {/* <p>Depth: {detail.depth}</p> */}

                                            {renderGroupLine(detail)}
                                        </RequirementContainerStyle>
                                    );
                                })
                            }
                        </div>
                    </>
                    :
                    <p css={tw`mx-1 text-sm`}>N/A</p>
            }

        </InfoSectionContainerStyle>
    );
};
