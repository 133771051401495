import { Skipped, YesNoChoices } from '@/components/survey/surveyQuestions';
import { termNameYearLowercase } from '@/constants/terms';

export type MajorResponse = {
    q1a: 'Y' | 'N';
    q1b: {
        institution: string;
        major: string;
    } | Skipped;
    q2a: 'Y' | 'N';
    q2b: {
        major2?: string;
        major3?: string;
    } | Skipped;
    q3a: 'Y' | 'N';
    q3b: {
        institution?: string;
        major?: string;
        major2?: string;
        major3?: string;
    } | Skipped;
    q4: {
        classId: string;
        courseShortName: string;
    }[] | Skipped;
    q5: string;
    submittedTs?: string;
};



export const major1a = {
    id: 'q1a',
    label: 'Our records show that you are currently majoring in <b>{major}</b> at <b>{institution}</b>. Is this correct?',
    choices: YesNoChoices,
    type: 'radio',
};
export const major1b = {
    id: 'q1b',
    label: 'Please confirm your current major:'
};
export const major2a = {
    id: 'q2a',
    label: 'Do you currently have a double or triple major?',
    choices: YesNoChoices,
    type: 'radio',
};
export const major2b = {
    id: 'q2b',
    label: 'Please indicate your additional major(s) as applicable:'
};
export const major3a = {
    id: 'q3a',
    label: 'Do you plan to change your major this semester?',
    choices: YesNoChoices,
    type: 'radio'
};
export const major3b = {
    id: 'q3b',
    label: 'Please identify what your major(s) will be after you make this intended change:',
};
export const major4 = {
    id: 'q4',
    label: 'CUNYfirst shows that you are registered for the following courses. <b>Do you currently plan to retake any of these courses</b> (e.g., because you dropped it)?'
};
export const major5 = {
    id: 'q5',
    label: `Have you spent time discussing your class schedule for the ${termNameYearLowercase} with an advisor?`,
    choices: [
        {
            value: 'a',
            label: `I haven’t discussed my class schedule for the ${termNameYearLowercase} with an advisor`,
        },
        {
            value: 'b',
            label: 'about 10-15 minutes',
        },
        {
            value: 'c',
            label: 'about 30 minutes',
        },
        {
            value: 'd',
            label: 'about 45 minutes',
        },
        {
            value: 'e',
            label: '1 hour or more',
        },
    ]
};
