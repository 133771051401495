import { useGetSimResults, useUpdateSimulationData } from '@/api/simulation/SimulationAPI';
import { useCallback, useMemo } from 'react';
import { formatISO } from 'date-fns';
import { QuestionResponse, ReplacementResponse, StageData } from '@/store/simulation/types';
import { QuestionConditionEnum } from '@/constants/questions';
import { SelectedCourse } from '@/api/types';
import { isInProgress } from '@/store/simulation/simulationUtils';

interface SimulationHooks {
    continueWithoutReplacing: () => Promise<any>;
    isSimulationInProgress: boolean;
    submitReplacement: (replacement: SelectedCourse) => Promise<any>;
}

export default (): SimulationHooks => {
    const simResults = useGetSimResults();
    const updateSimulationData = useUpdateSimulationData();

    const submitReplacement = (replacement: SelectedCourse): Promise<any> => {
        const { data: simulationData } = simResults;
        if (!simulationData || !simulationData.currentStage) return Promise.reject(new Error('simulation not in progress'));

        const ts = formatISO(new Date());
        const replacementResponse: ReplacementResponse = {
            replacementClass: replacement,
            submittedTs: ts,
        };

        const additionalProps: Partial<StageData> = {};

        const { currentStage } = simulationData;
        if (currentStage.id === 't1r2' && currentStage.unavailableClass.sectionMode === replacement.sectionMode) {
            additionalProps.reasonResponse = {
                questionId: QuestionConditionEnum.Undefined,
                answer: [ 'Undefined' ],
                submittedTs: ts
            };
        }

        const updateProps: Partial<StageData> = {
            replacementResponse,
            ...additionalProps,
        };

        return updateSimulationData({
            currentStage: {
                ...simulationData!.currentStage!,
                ...updateProps
            }
        })
            .catch(err => console.error(err));
    };

    const isSimulationInProgress = useMemo<boolean>(() => isInProgress(simResults), [
        simResults.data?.startedTs, simResults.data?.currentStage
    ]);

    // Afterwards, the student should be on negativeExpectations
    const continueWithoutReplacing = useCallback(() => {
        const { data: simulationData } = simResults;
        if (!simulationData.currentStage) {
            throw new Error('currentStage is null');
        }
        // First fill in the responses for the current stage
        const submittedTs = formatISO(new Date());
        const replacementResponse: ReplacementResponse = {
            replacementClass: 'NoReplacement',
            submittedTs,
        };
        const reasonResponse: QuestionResponse = {
            questionId: QuestionConditionEnum.DroppedCourse,
            answer: [ QuestionConditionEnum.DroppedCourse ],
            submittedTs: submittedTs,
        };

        return updateSimulationData({
            currentStage: {
                ...simulationData.currentStage,
                replacementResponse,
                reasonResponse,
            }
        });
    }, [ simResults ]);

    return {
        continueWithoutReplacing,
        isSimulationInProgress,
        submitReplacement,
    };
};
