import React, { useState } from 'react';
import tw from 'twin.macro';
import { ShoppingCartContainerStyle } from './styled';
import ShoppingCartTable from './ShoppingCartTable';
import { Button, Spinner } from '../common';
import { useHistory } from 'react-router';
import { useGetSimResults } from '@/api/simulation/SimulationAPI';
import useRestartSimulation from '@/components/simulation/useRestartSimulation';
import removeClass from '@/components/shopping-cart/api/removeClass';
import { simulationReminder } from '@/constants/instructions';
import { useStoreActions } from '@/store';

export default () => {
    const history = useHistory();
    const [ loading, setLoading ] = useState<boolean>(false);
    const restartSimulation = useRestartSimulation();

    const { clearAllFiltersExceptInstitution } = useStoreActions(actions => actions.search);

    const { shoppingCart } = useGetSimResults();

    // const { coReqsErrors } = useShoppingCartHooks();

    const [ error, setError ] = useState<string>('');

    const goToSearch = () => {
        clearAllFiltersExceptInstitution();
        history.push('/search');
    };

    const onSubmit = () => {
        setError('');
        setLoading(true);
        if (!shoppingCart.length) return;

        restartSimulation()
            .then(() => {
                setLoading(false);
                history.push('/sim');
            })
            .catch(err => {
                setLoading(false);
                console.error(err);
            });
    };

    const onRemoveClass = (classId: string) => {
        setLoading(true);
        removeClass(classId)
            .catch(err => console.error(err))
            .then(() => setLoading(false));
    };

    return (
        <ShoppingCartContainerStyle>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />

            <h3>Shopping Cart</h3>
            <ShoppingCartTable items={shoppingCart} onRemoveClass={onRemoveClass}/>

            {error &&
                <div css={[ tw`w-full mt-4 bg-red-200 p-2 text-red-600 rounded mb-2` ]}>
                    <span css={[ tw`font-medium` ]}>Error:</span> {error}
                </div>
            }

            {/* { coReqsErrors.map((err, i) => (<CoReqErrorLinkable key={i} error={err}/>)) } */}

            <div css={tw`flex justify-between`}>
                <Button onClick={goToSearch}>Choose more classes</Button>
                <Button disabled={!!(loading || !shoppingCart.length)} onClick={onSubmit}>Submit final schedule</Button>
            </div>

            <p css={tw`text-sm mt-8`}>{simulationReminder}</p>
        </ShoppingCartContainerStyle>
    );
};
