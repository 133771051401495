import { ChartOptions, TooltipOptions, FontSpec } from 'chart.js';
import { theme } from 'twin.macro';

export const defaultFont: FontSpec = { weight: 'normal', size: 12, family: 'brandon-grotesque,sans-serif', style: 'normal', lineHeight: 'normal' };

export const defaultTooltipOptions: Partial<TooltipOptions<'bar'>> = {
    padding: 12,
    backgroundColor: 'white',
    titleColor: theme`colors.gray.700`.toString(),
    bodyColor: theme`colors.gray.700`.toString(),
    titleFont: defaultFont,
    bodyFont: { ...defaultFont, size: 16 },
    borderColor: '#e6e6e6',
    borderWidth: 1,
};
export const defaultDoughnutTooltipOptions: Partial<TooltipOptions<'doughnut'>> = {
    padding: 6,
    backgroundColor: 'white',
    titleColor: theme`colors.gray.700`.toString(),
    titleFont: defaultFont,
    bodyFont: { ...defaultFont, size: 16 },
    bodyColor: theme`colors.gray.700`.toString(),
    borderColor: '#e6e6e6',
    borderWidth: 1,
};

export const defaultChartOptions: ChartOptions = {
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: false,
};

export const chartColors = [
    {
        // hex: 5691f5
        normal: 'rgb(66, 133, 244, 0.9)',
        faded: 'rgb(66, 133, 244, 0.4)',
    },
    {
        // hex: 5e63c5
        normal: 'rgba(76,81,191, 0.9)',
        faded: 'rgba(76,81,191, 0.4)',
    },

    {
        // grey
        normal: 'rgba(203,213,224, 0.9)',
        faded: 'rgba(203,213,224, 0.4)',
    },

    {
        // hex: 56cca9 // close to theme green
        normal: 'rgba(59,198,160, 0.9)',
        faded: 'rgba(59,198,160, 0.4)',
    },



    {
        // theme red
        // hex '#D9534F',
        normal: 'rgba(217, 83, 79, 0.9)',
        faded: 'rgba(217, 83, 79, 0.4)',

    },
];

export enum GenderIndex {
    Male = 0,
    Female = 1,
}

export enum PlatformIndex {
    Web = 0,
    iOS = 1,
    Android = 2,
    Missing = 3,
}
