const allSubjects = [
    {
        value: 'AAS',
        label: 'Asian American Studies',
        institution: 'BAR01'
    },
    {
        value: 'ACC',
        label: 'Accounting',
        institution: 'BAR01'
    },
    {
        value: 'ANT',
        label: 'Anthropology',
        institution: 'BAR01'
    },
    {
        value: 'ARB',
        label: 'Arabic',
        institution: 'BAR01'
    },
    {
        value: 'ART',
        label: 'Art',
        institution: 'BAR01'
    },
    {
        value: 'BIO',
        label: 'Biology',
        institution: 'BAR01'
    },
    {
        value: 'BLS',
        label: 'Black Studies',
        institution: 'BAR01'
    },
    {
        value: 'BPL',
        label: 'Business Policy',
        institution: 'BAR01'
    },
    {
        value: 'BUS',
        label: 'Business Administration',
        institution: 'BAR01'
    },
    {
        value: 'CHI',
        label: 'Chinese',
        institution: 'BAR01'
    },
    {
        value: 'CHM',
        label: 'Chemistry',
        institution: 'BAR01'
    },
    {
        value: 'CIS',
        label: 'Computer Information Sys',
        institution: 'BAR01'
    },
    {
        value: 'CMP',
        label: 'Comparative Literature',
        institution: 'BAR01'
    },
    {
        value: 'COM',
        label: 'Communication Studies',
        institution: 'BAR01'
    },
    {
        value: 'CUNBA',
        label: 'CUNY BA',
        institution: 'BAR01'
    },
    {
        value: 'DCT',
        label: 'Exec Doctorate',
        institution: 'BAR01'
    },
    {
        value: 'ECO',
        label: 'Economics',
        institution: 'BAR01'
    },
    {
        value: 'ENG',
        label: 'English',
        institution: 'BAR01'
    },
    {
        value: 'ENV',
        label: 'Environmental Studies',
        institution: 'BAR01'
    },
    {
        value: 'FIN',
        label: 'Finance',
        institution: 'BAR01'
    },
    {
        value: 'FLM',
        label: 'Film',
        institution: 'BAR01'
    },
    {
        value: 'FPA',
        label: 'Fine and Performing Arts',
        institution: 'BAR01'
    },
    {
        value: 'FRE',
        label: 'French',
        institution: 'BAR01'
    },
    {
        value: 'FYS',
        label: 'First Year Seminar',
        institution: 'BAR01'
    },
    {
        value: 'GEOG',
        label: 'Geography',
        institution: 'BAR01'
    },
    {
        value: 'GLS',
        label: 'Global Studies',
        institution: 'BAR01'
    },
    {
        value: 'HEB',
        label: 'Hebrew',
        institution: 'BAR01'
    },
    {
        value: 'HED',
        label: 'Health Education',
        institution: 'BAR01'
    },
    {
        value: 'HIS',
        label: 'History',
        institution: 'BAR01'
    },
    {
        value: 'IBS',
        label: 'International Business',
        institution: 'BAR01'
    },
    {
        value: 'IDC',
        label: 'Interdisciplinary Std',
        institution: 'BAR01'
    },
    {
        value: 'INS',
        label: 'Insurance',
        institution: 'BAR01'
    },
    {
        value: 'ITL',
        label: 'Italian',
        institution: 'BAR01'
    },
    {
        value: 'JPN',
        label: 'Japanese',
        institution: 'BAR01'
    },
    {
        value: 'JRN',
        label: 'Journalism',
        institution: 'BAR01'
    },
    {
        value: 'JWS',
        label: 'Jewish Studies',
        institution: 'BAR01'
    },
    {
        value: 'LACS',
        label: 'Latin Amer & Carib Stu',
        institution: 'BAR01'
    },
    {
        value: 'LAW',
        label: 'Law',
        institution: 'BAR01'
    },
    {
        value: 'LIB',
        label: 'Library',
        institution: 'BAR01'
    },
    {
        value: 'LTS',
        label: 'Latino Studies',
        institution: 'BAR01'
    },
    {
        value: 'MAM',
        label: 'Maintain Matriculation',
        institution: 'BAR01'
    },
    {
        value: 'MGT',
        label: 'Management',
        institution: 'BAR01'
    },
    {
        value: 'MKT',
        label: 'Marketing',
        institution: 'BAR01'
    },
    {
        value: 'MSC',
        label: 'Music',
        institution: 'BAR01'
    },
    {
        value: 'MTH',
        label: 'Mathematics',
        institution: 'BAR01'
    },
    {
        value: 'NMA',
        label: 'New Media Arts',
        institution: 'BAR01'
    },
    {
        value: 'ONFIL',
        label: 'On File',
        institution: 'BAR01'
    },
    {
        value: 'OPR',
        label: 'Operation Research',
        institution: 'BAR01'
    },
    {
        value: 'PAF',
        label: 'Public Affairs',
        institution: 'BAR01'
    },
    {
        value: 'PED',
        label: 'Physical Education',
        institution: 'BAR01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'BAR01'
    },
    {
        value: 'PHI',
        label: 'Philosophy',
        institution: 'BAR01'
    },
    {
        value: 'PHY',
        label: 'Physics',
        institution: 'BAR01'
    },
    {
        value: 'POL',
        label: 'Political Science',
        institution: 'BAR01'
    },
    {
        value: 'POR',
        label: 'Portuguese',
        institution: 'BAR01'
    },
    {
        value: 'PSY',
        label: 'Psychology',
        institution: 'BAR01'
    },
    {
        value: 'REL',
        label: 'Religion',
        institution: 'BAR01'
    },
    {
        value: 'RES',
        label: 'Real Estate',
        institution: 'BAR01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'BAR01'
    },
    {
        value: 'SPA',
        label: 'Spanish',
        institution: 'BAR01'
    },
    {
        value: 'SPS',
        label: 'Student Personnel Srvcs',
        institution: 'BAR01'
    },
    {
        value: 'STA',
        label: 'Statistics',
        institution: 'BAR01'
    },
    {
        value: 'STABD',
        label: 'Study Abroad',
        institution: 'BAR01'
    },
    {
        value: 'TAX',
        label: 'Taxation',
        institution: 'BAR01'
    },
    {
        value: 'THE',
        label: 'Theatre',
        institution: 'BAR01'
    },
    {
        value: 'TRA',
        label: 'Transfer Seminar',
        institution: 'BAR01'
    },
    {
        value: 'WST',
        label: 'Women\'s Studies',
        institution: 'BAR01'
    },
    {
        value: 'ACC',
        label: 'Accounting',
        institution: 'BCC01'
    },
    {
        value: 'ACM',
        label: 'Animal Care and Mgmt',
        institution: 'BCC01'
    },
    {
        value: 'ACS',
        label: 'Automotive Technology',
        institution: 'BCC01'
    },
    {
        value: 'ANT',
        label: 'Anthropology',
        institution: 'BCC01'
    },
    {
        value: 'ARB',
        label: 'Arabic',
        institution: 'BCC01'
    },
    {
        value: 'ART',
        label: 'Art',
        institution: 'BCC01'
    },
    {
        value: 'AST',
        label: 'Astronomy',
        institution: 'BCC01'
    },
    {
        value: 'BIO',
        label: 'Biology',
        institution: 'BCC01'
    },
    {
        value: 'BIS',
        label: 'Business Information Sys',
        institution: 'BCC01'
    },
    {
        value: 'BOT',
        label: 'Botany',
        institution: 'BCC01'
    },
    {
        value: 'BUS',
        label: 'Business Administration',
        institution: 'BCC01'
    },
    {
        value: 'CHM',
        label: 'Chemistry',
        institution: 'BCC01'
    },
    {
        value: 'CLE',
        label: 'Clinical Education',
        institution: 'BCC01'
    },
    {
        value: 'COM',
        label: 'Communication Studies',
        institution: 'BCC01'
    },
    {
        value: 'COMM',
        label: 'Communications',
        institution: 'BCC01'
    },
    {
        value: 'CPR',
        label: 'Cardiopuly Resuscitation',
        institution: 'BCC01'
    },
    {
        value: 'CRJ',
        label: 'Criminal Justice',
        institution: 'BCC01'
    },
    {
        value: 'CSI',
        label: 'Computer Science',
        institution: 'BCC01'
    },
    {
        value: 'CSN',
        label: 'Cybersecurity & Netwrk',
        institution: 'BCC01'
    },
    {
        value: 'CWE',
        label: 'Coop Work Experience I',
        institution: 'BCC01'
    },
    {
        value: 'DAT',
        label: 'Data Processing',
        institution: 'BCC01'
    },
    {
        value: 'ECO',
        label: 'Economics',
        institution: 'BCC01'
    },
    {
        value: 'EDU',
        label: 'Education',
        institution: 'BCC01'
    },
    {
        value: 'EGR',
        label: 'Engineering',
        institution: 'BCC01'
    },
    {
        value: 'ELC',
        label: 'Electrical Technology',
        institution: 'BCC01'
    },
    {
        value: 'ENG',
        label: 'English',
        institution: 'BCC01'
    },
    {
        value: 'ENV',
        label: 'Environmental Studies',
        institution: 'BCC01'
    },
    {
        value: 'ESE',
        label: 'Earth Systems Science',
        institution: 'BCC01'
    },
    {
        value: 'ESL',
        label: 'English as a Second Lang',
        institution: 'BCC01'
    },
    {
        value: 'EST',
        label: 'Energy Servies and Tech',
        institution: 'BCC01'
    },
    {
        value: 'EXS',
        label: 'Exercise Science',
        institution: 'BCC01'
    },
    {
        value: 'FILM',
        label: 'Film Studies',
        institution: 'BCC01'
    },
    {
        value: 'FIN',
        label: 'Finance',
        institution: 'BCC01'
    },
    {
        value: 'FRN',
        label: 'French',
        institution: 'BCC01'
    },
    {
        value: 'FYS',
        label: 'First Year Seminar',
        institution: 'BCC01'
    },
    {
        value: 'GAR',
        label: 'Gardening',
        institution: 'BCC01'
    },
    {
        value: 'GEO',
        label: 'Geography',
        institution: 'BCC01'
    },
    {
        value: 'GIS',
        label: 'Geospatial Technology',
        institution: 'BCC01'
    },
    {
        value: 'HCM',
        label: 'Health Care Management',
        institution: 'BCC01'
    },
    {
        value: 'HIS',
        label: 'History',
        institution: 'BCC01'
    },
    {
        value: 'HLT',
        label: 'Health Education',
        institution: 'BCC01'
    },
    {
        value: 'HRT',
        label: 'Horticulture',
        institution: 'BCC01'
    },
    {
        value: 'HSC',
        label: 'Human Services',
        institution: 'BCC01'
    },
    {
        value: 'IND',
        label: 'Independent Study',
        institution: 'BCC01'
    },
    {
        value: 'INT',
        label: 'Interdiscip Ind Study I',
        institution: 'BCC01'
    },
    {
        value: 'ITL',
        label: 'Italian',
        institution: 'BCC01'
    },
    {
        value: 'JPN',
        label: 'Japanese',
        institution: 'BCC01'
    },
    {
        value: 'KEY',
        label: 'Keyboarding',
        institution: 'BCC01'
    },
    {
        value: 'LAW',
        label: 'Law',
        institution: 'BCC01'
    },
    {
        value: 'LND',
        label: 'Landscape Design',
        institution: 'BCC01'
    },
    {
        value: 'LTL',
        label: 'Learning to Learn',
        institution: 'BCC01'
    },
    {
        value: 'MEDP',
        label: 'Media & Dgtl Film Prod',
        institution: 'BCC01'
    },
    {
        value: 'MEST',
        label: 'Media Studies',
        institution: 'BCC01'
    },
    {
        value: 'MKT',
        label: 'Marketing',
        institution: 'BCC01'
    },
    {
        value: 'MLT',
        label: 'Medical Lab Technician',
        institution: 'BCC01'
    },
    {
        value: 'MTH',
        label: 'Mathematics',
        institution: 'BCC01'
    },
    {
        value: 'MUS',
        label: 'Music',
        institution: 'BCC01'
    },
    {
        value: 'NMT',
        label: 'Nuclear Medicine',
        institution: 'BCC01'
    },
    {
        value: 'NUR',
        label: 'Nursing RN',
        institution: 'BCC01'
    },
    {
        value: 'PEA',
        label: 'Physical Education',
        institution: 'BCC01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'BCC01'
    },
    {
        value: 'PHL',
        label: 'Philosophy',
        institution: 'BCC01'
    },
    {
        value: 'PHM',
        label: 'Pharmacology Comp',
        institution: 'BCC01'
    },
    {
        value: 'PHY',
        label: 'Physics',
        institution: 'BCC01'
    },
    {
        value: 'POL',
        label: 'Political Science',
        institution: 'BCC01'
    },
    {
        value: 'POR',
        label: 'Portuguese',
        institution: 'BCC01'
    },
    {
        value: 'PSY',
        label: 'Psychology',
        institution: 'BCC01'
    },
    {
        value: 'RAD',
        label: 'Radiologic Technology',
        institution: 'BCC01'
    },
    {
        value: 'RDL',
        label: 'Reading',
        institution: 'BCC01'
    },
    {
        value: 'REC',
        label: 'Therapeutic Recreation',
        institution: 'BCC01'
    },
    {
        value: 'SEC',
        label: 'Med Office Pract & Proc',
        institution: 'BCC01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'BCC01'
    },
    {
        value: 'SPN',
        label: 'Spanish',
        institution: 'BCC01'
    },
    {
        value: 'THEA',
        label: 'Theatre',
        institution: 'BCC01'
    },
    {
        value: 'WPR',
        label: 'Word Processing',
        institution: 'BCC01'
    },
    {
        value: 'ACCT',
        label: 'Accounting',
        institution: 'BKL01'
    },
    {
        value: 'AFST',
        label: 'Africana Studies',
        institution: 'BKL01'
    },
    {
        value: 'AMST',
        label: 'American Studies',
        institution: 'BKL01'
    },
    {
        value: 'ANTH',
        label: 'Anthropology',
        institution: 'BKL01'
    },
    {
        value: 'ARAB',
        label: 'Arabic',
        institution: 'BKL01'
    },
    {
        value: 'ARTD',
        label: 'Art',
        institution: 'BKL01'
    },
    {
        value: 'BIOL',
        label: 'Biology',
        institution: 'BKL01'
    },
    {
        value: 'BUSN',
        label: 'Business Management',
        institution: 'BKL01'
    },
    {
        value: 'CASD',
        label: 'Comm Arts, Sci & Dis',
        institution: 'BKL01'
    },
    {
        value: 'CAST',
        label: 'Caribbean Studies',
        institution: 'BKL01'
    },
    {
        value: 'CBSE',
        label: 'Child,  Biling, Spec Ed',
        institution: 'BKL01'
    },
    {
        value: 'CHEM',
        label: 'Chemistry',
        institution: 'BKL01'
    },
    {
        value: 'CHIN',
        label: 'Chinese',
        institution: 'BKL01'
    },
    {
        value: 'CHST',
        label: 'Children\'s Studies',
        institution: 'BKL01'
    },
    {
        value: 'CISC',
        label: 'Comp & Info Science',
        institution: 'BKL01'
    },
    {
        value: 'CLAS',
        label: 'Classics',
        institution: 'BKL01'
    },
    {
        value: 'CMLT',
        label: 'Comparative Literature',
        institution: 'BKL01'
    },
    {
        value: 'COMM',
        label: 'Communications',
        institution: 'BKL01'
    },
    {
        value: 'CREO',
        label: 'Creole',
        institution: 'BKL01'
    },
    {
        value: 'DESW',
        label: 'Dept of Educ Services',
        institution: 'BKL01'
    },
    {
        value: 'ECAE',
        label: 'Early Childhood/ Art Ed',
        institution: 'BKL01'
    },
    {
        value: 'ECON',
        label: 'Economics',
        institution: 'BKL01'
    },
    {
        value: 'EESC',
        label: 'Earth & Environ Sci',
        institution: 'BKL01'
    },
    {
        value: 'ENGL',
        label: 'English',
        institution: 'BKL01'
    },
    {
        value: 'ESLR',
        label: 'Engl As A Second Lang',
        institution: 'BKL01'
    },
    {
        value: 'FILM',
        label: 'Film Studies',
        institution: 'BKL01'
    },
    {
        value: 'FINC',
        label: 'Finance',
        institution: 'BKL01'
    },
    {
        value: 'FREN',
        label: 'French',
        institution: 'BKL01'
    },
    {
        value: 'GERM',
        label: 'German',
        institution: 'BKL01'
    },
    {
        value: 'GRKC',
        label: 'Greek (Ancient)',
        institution: 'BKL01'
    },
    {
        value: 'GSCI',
        label: 'General Science',
        institution: 'BKL01'
    },
    {
        value: 'HARC',
        label: 'Honors Acad Resch Collq',
        institution: 'BKL01'
    },
    {
        value: 'HEBR',
        label: 'Hebrew',
        institution: 'BKL01'
    },
    {
        value: 'HIST',
        label: 'History',
        institution: 'BKL01'
    },
    {
        value: 'HNSC',
        label: 'Health & Nutrition Sci',
        institution: 'BKL01'
    },
    {
        value: 'INDS',
        label: 'Interdiscip Studies',
        institution: 'BKL01'
    },
    {
        value: 'ITAL',
        label: 'Italian',
        institution: 'BKL01'
    },
    {
        value: 'JAPN',
        label: 'Japanese',
        institution: 'BKL01'
    },
    {
        value: 'JUST',
        label: 'Judaic Studies',
        institution: 'BKL01'
    },
    {
        value: 'KINS',
        label: 'Kinesiology',
        institution: 'BKL01'
    },
    {
        value: 'LATN',
        label: 'Latin',
        institution: 'BKL01'
    },
    {
        value: 'LING',
        label: 'Lingusitics',
        institution: 'BKL01'
    },
    {
        value: 'MAM',
        label: 'Maintain Matriculation',
        institution: 'BKL01'
    },
    {
        value: 'MATH',
        label: 'Mathematics',
        institution: 'BKL01'
    },
    {
        value: 'MCHC',
        label: 'Cuny Hon Col Seminar',
        institution: 'BKL01'
    },
    {
        value: 'MLAN',
        label: 'Modern Languages',
        institution: 'BKL01'
    },
    {
        value: 'MUSC',
        label: 'Music',
        institution: 'BKL01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'BKL01'
    },
    {
        value: 'PHIL',
        label: 'Philosophy',
        institution: 'BKL01'
    },
    {
        value: 'PHYS',
        label: 'Physics',
        institution: 'BKL01'
    },
    {
        value: 'PIMA',
        label: 'Perf & Inter Media Arts',
        institution: 'BKL01'
    },
    {
        value: 'POLS',
        label: 'Political Science',
        institution: 'BKL01'
    },
    {
        value: 'PORT',
        label: 'Portuguese',
        institution: 'BKL01'
    },
    {
        value: 'PRLS',
        label: 'Puerto Rican & Lat Stud',
        institution: 'BKL01'
    },
    {
        value: 'PSYC',
        label: 'Psychology',
        institution: 'BKL01'
    },
    {
        value: 'RELG',
        label: 'Religion',
        institution: 'BKL01'
    },
    {
        value: 'RUSS',
        label: 'Russian',
        institution: 'BKL01'
    },
    {
        value: 'SEED',
        label: 'Secondary Education',
        institution: 'BKL01'
    },
    {
        value: 'SEEK',
        label: 'SEEK Academic Program',
        institution: 'BKL01'
    },
    {
        value: 'SOCY',
        label: 'Sociology',
        institution: 'BKL01'
    },
    {
        value: 'SPAN',
        label: 'Spanish',
        institution: 'BKL01'
    },
    {
        value: 'SPCL',
        label: 'Schl Psy, Couns& Ldrshp',
        institution: 'BKL01'
    },
    {
        value: 'SPCM',
        label: 'Scholars\' Program',
        institution: 'BKL01'
    },
    {
        value: 'STABD',
        label: 'Study Abroad',
        institution: 'BKL01'
    },
    {
        value: 'SUST',
        label: 'Urban Sustainability',
        institution: 'BKL01'
    },
    {
        value: 'THEA',
        label: 'Theatre',
        institution: 'BKL01'
    },
    {
        value: 'TVRA',
        label: 'Television & Radio',
        institution: 'BKL01'
    },
    {
        value: 'UNAS',
        label: 'Unassigned',
        institution: 'BKL01'
    },
    {
        value: 'WGST',
        label: 'Womens & Gender Studies',
        institution: 'BKL01'
    },
    {
        value: 'YIDM',
        label: 'Yiddish-Modern',
        institution: 'BKL01'
    },
    {
        value: 'ACC',
        label: 'Accounting',
        institution: 'BMC01'
    },
    {
        value: 'ACR',
        label: 'Acad. Critical Reading',
        institution: 'BMC01'
    },
    {
        value: 'AFL',
        label: 'Africana/Latino Studies',
        institution: 'BMC01'
    },
    {
        value: 'AFN',
        label: 'Africana Studies',
        institution: 'BMC01'
    },
    {
        value: 'ANI',
        label: 'Animation & Motion Grphcs',
        institution: 'BMC01'
    },
    {
        value: 'ANT',
        label: 'Anthropology',
        institution: 'BMC01'
    },
    {
        value: 'ARB',
        label: 'Arabic',
        institution: 'BMC01'
    },
    {
        value: 'ART',
        label: 'Art',
        institution: 'BMC01'
    },
    {
        value: 'ASL',
        label: 'American Sign Language',
        institution: 'BMC01'
    },
    {
        value: 'ASN',
        label: 'Asian Studies',
        institution: 'BMC01'
    },
    {
        value: 'AST',
        label: 'Astronomy',
        institution: 'BMC01'
    },
    {
        value: 'BIO',
        label: 'Biology',
        institution: 'BMC01'
    },
    {
        value: 'BLA',
        label: 'BMCC Learning Academy',
        institution: 'BMC01'
    },
    {
        value: 'BUS',
        label: 'Business Administration',
        institution: 'BMC01'
    },
    {
        value: 'CED',
        label: 'Cooperative Education',
        institution: 'BMC01'
    },
    {
        value: 'CHE',
        label: 'Chemistry',
        institution: 'BMC01'
    },
    {
        value: 'CHI',
        label: 'Chinese',
        institution: 'BMC01'
    },
    {
        value: 'CIS',
        label: 'Computer Information Sys',
        institution: 'BMC01'
    },
    {
        value: 'COM',
        label: 'Communication Studies',
        institution: 'BMC01'
    },
    {
        value: 'CRJ',
        label: 'Criminal Justice',
        institution: 'BMC01'
    },
    {
        value: 'CRT',
        label: 'Critical Thinking',
        institution: 'BMC01'
    },
    {
        value: 'CSC',
        label: 'Computer Science',
        institution: 'BMC01'
    },
    {
        value: 'CST',
        label: 'CUNY Start',
        institution: 'BMC01'
    },
    {
        value: 'ECE',
        label: 'Early Childhood Educ',
        institution: 'BMC01'
    },
    {
        value: 'ECO',
        label: 'Economics',
        institution: 'BMC01'
    },
    {
        value: 'EDB',
        label: 'Bilingual Education',
        institution: 'BMC01'
    },
    {
        value: 'EDS',
        label: 'Secondary Education',
        institution: 'BMC01'
    },
    {
        value: 'EDU',
        label: 'Education',
        institution: 'BMC01'
    },
    {
        value: 'EMC',
        label: 'Emergency Medical Care',
        institution: 'BMC01'
    },
    {
        value: 'ENG',
        label: 'English',
        institution: 'BMC01'
    },
    {
        value: 'ESC',
        label: 'Engineering Science',
        institution: 'BMC01'
    },
    {
        value: 'ESL',
        label: 'English as a Second Lang',
        institution: 'BMC01'
    },
    {
        value: 'FNB',
        label: 'Finance & Banking',
        institution: 'BMC01'
    },
    {
        value: 'FRN',
        label: 'French',
        institution: 'BMC01'
    },
    {
        value: 'FYE',
        label: 'Freshman Year Experience',
        institution: 'BMC01'
    },
    {
        value: 'GEO',
        label: 'Geography',
        institution: 'BMC01'
    },
    {
        value: 'GIS',
        label: 'Geospatial Technology',
        institution: 'BMC01'
    },
    {
        value: 'GWS',
        label: 'Gender and Women\'s Studi',
        institution: 'BMC01'
    },
    {
        value: 'HED',
        label: 'Health Education',
        institution: 'BMC01'
    },
    {
        value: 'HIS',
        label: 'History',
        institution: 'BMC01'
    },
    {
        value: 'HIT',
        label: 'Health Info. Technology',
        institution: 'BMC01'
    },
    {
        value: 'HTT',
        label: 'Hotels, Travel and Touri',
        institution: 'BMC01'
    },
    {
        value: 'HUM',
        label: 'Human Services',
        institution: 'BMC01'
    },
    {
        value: 'ITL',
        label: 'Italian',
        institution: 'BMC01'
    },
    {
        value: 'LAT',
        label: 'Latino Studies',
        institution: 'BMC01'
    },
    {
        value: 'LIN',
        label: 'Linguistics',
        institution: 'BMC01'
    },
    {
        value: 'MAR',
        label: 'Marketing',
        institution: 'BMC01'
    },
    {
        value: 'MAT',
        label: 'Mathematics',
        institution: 'BMC01'
    },
    {
        value: 'MEA',
        label: 'Media Arts & Technology',
        institution: 'BMC01'
    },
    {
        value: 'MES',
        label: 'Media Studies',
        institution: 'BMC01'
    },
    {
        value: 'MMA',
        label: 'Multimedia and Arts',
        institution: 'BMC01'
    },
    {
        value: 'MMP',
        label: 'Multimedia & Programming',
        institution: 'BMC01'
    },
    {
        value: 'MUS',
        label: 'Music',
        institution: 'BMC01'
    },
    {
        value: 'NUR',
        label: 'Nursing RN',
        institution: 'BMC01'
    },
    {
        value: 'OFF',
        label: 'Office Administration',
        institution: 'BMC01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'BMC01'
    },
    {
        value: 'PHI',
        label: 'Philosophy',
        institution: 'BMC01'
    },
    {
        value: 'PHY',
        label: 'Physics',
        institution: 'BMC01'
    },
    {
        value: 'POL',
        label: 'Political Science',
        institution: 'BMC01'
    },
    {
        value: 'PSY',
        label: 'Psychology',
        institution: 'BMC01'
    },
    {
        value: 'RTT',
        label: 'Respiratory Therapy',
        institution: 'BMC01'
    },
    {
        value: 'SBE',
        label: 'Small Business',
        institution: 'BMC01'
    },
    {
        value: 'SCI',
        label: 'Science',
        institution: 'BMC01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'BMC01'
    },
    {
        value: 'SPE',
        label: 'Speech',
        institution: 'BMC01'
    },
    {
        value: 'SPN',
        label: 'Spanish',
        institution: 'BMC01'
    },
    {
        value: 'THE',
        label: 'Theatre',
        institution: 'BMC01'
    },
    {
        value: 'VAT',
        label: 'Video Arts and Techn',
        institution: 'BMC01'
    },
    {
        value: 'AAD',
        label: 'African & African Diasp',
        institution: 'CSI01'
    },
    {
        value: 'ACC',
        label: 'Accounting',
        institution: 'CSI01'
    },
    {
        value: 'AMS',
        label: 'American Studies',
        institution: 'CSI01'
    },
    {
        value: 'ANT',
        label: 'Anthropology',
        institution: 'CSI01'
    },
    {
        value: 'ARB',
        label: 'Arabic',
        institution: 'CSI01'
    },
    {
        value: 'ART',
        label: 'Art',
        institution: 'CSI01'
    },
    {
        value: 'ASD',
        label: 'Autism Spectrum Dis',
        institution: 'CSI01'
    },
    {
        value: 'ASL',
        label: 'American Sign Language',
        institution: 'CSI01'
    },
    {
        value: 'AST',
        label: 'Astronomy',
        institution: 'CSI01'
    },
    {
        value: 'BDA',
        label: 'Business Data Analytics',
        institution: 'CSI01'
    },
    {
        value: 'BIO',
        label: 'Biology',
        institution: 'CSI01'
    },
    {
        value: 'BUS',
        label: 'Business Administration',
        institution: 'CSI01'
    },
    {
        value: 'CERT',
        label: 'Certified Full-Time',
        institution: 'CSI01'
    },
    {
        value: 'CHM',
        label: 'Chemistry',
        institution: 'CSI01'
    },
    {
        value: 'CHN',
        label: 'Chinese',
        institution: 'CSI01'
    },
    {
        value: 'CIN',
        label: 'Cinema Studies',
        institution: 'CSI01'
    },
    {
        value: 'CMC',
        label: 'Cinema/Media Studies',
        institution: 'CSI01'
    },
    {
        value: 'COM',
        label: 'Communication Studies',
        institution: 'CSI01'
    },
    {
        value: 'COR',
        label: 'Core',
        institution: 'CSI01'
    },
    {
        value: 'CSC',
        label: 'Computer Science',
        institution: 'CSI01'
    },
    {
        value: 'CUNBA',
        label: 'CUNY BA',
        institution: 'CSI01'
    },
    {
        value: 'DAN',
        label: 'Dance',
        institution: 'CSI01'
    },
    {
        value: 'DRA',
        label: 'Dramatic Arts',
        institution: 'CSI01'
    },
    {
        value: 'EAS',
        label: 'Earth & Atmospheric Sci',
        institution: 'CSI01'
    },
    {
        value: 'ECO',
        label: 'Economics',
        institution: 'CSI01'
    },
    {
        value: 'EDA',
        label: 'Educ Supervision & Admin',
        institution: 'CSI01'
    },
    {
        value: 'EDC',
        label: 'Educ Early Childhood',
        institution: 'CSI01'
    },
    {
        value: 'EDD',
        label: 'Education: General',
        institution: 'CSI01'
    },
    {
        value: 'EDE',
        label: 'Education: Elementary',
        institution: 'CSI01'
    },
    {
        value: 'EDL',
        label: 'Education TESOL',
        institution: 'CSI01'
    },
    {
        value: 'EDM',
        label: 'Education: Middle School',
        institution: 'CSI01'
    },
    {
        value: 'EDP',
        label: 'Education: Special',
        institution: 'CSI01'
    },
    {
        value: 'EDS',
        label: 'Secondary Education',
        institution: 'CSI01'
    },
    {
        value: 'ELE',
        label: 'Electrical Engineering',
        institution: 'CSI01'
    },
    {
        value: 'ENG',
        label: 'English',
        institution: 'CSI01'
    },
    {
        value: 'ENH',
        label: 'English',
        institution: 'CSI01'
    },
    {
        value: 'ENL',
        label: 'English',
        institution: 'CSI01'
    },
    {
        value: 'ENS',
        label: 'Engineering Science',
        institution: 'CSI01'
    },
    {
        value: 'ESC',
        label: 'Engineering Science',
        institution: 'CSI01'
    },
    {
        value: 'FNC',
        label: 'Finance',
        institution: 'CSI01'
    },
    {
        value: 'FRN',
        label: 'French',
        institution: 'CSI01'
    },
    {
        value: 'GEG',
        label: 'Geography',
        institution: 'CSI01'
    },
    {
        value: 'GEO',
        label: 'Geography',
        institution: 'CSI01'
    },
    {
        value: 'HED',
        label: 'Health Education',
        institution: 'CSI01'
    },
    {
        value: 'HON',
        label: 'Honors College Seminars',
        institution: 'CSI01'
    },
    {
        value: 'HSS',
        label: 'Honors: Hum/Soc Sci',
        institution: 'CSI01'
    },
    {
        value: 'HST',
        label: 'History',
        institution: 'CSI01'
    },
    {
        value: 'INT',
        label: 'Interdiscip Ind Study I',
        institution: 'CSI01'
    },
    {
        value: 'ISI',
        label: 'Info Sys & Infomatics',
        institution: 'CSI01'
    },
    {
        value: 'ITL',
        label: 'Italian',
        institution: 'CSI01'
    },
    {
        value: 'LAT',
        label: 'Latino Studies',
        institution: 'CSI01'
    },
    {
        value: 'LBS',
        label: 'Liberal Studies',
        institution: 'CSI01'
    },
    {
        value: 'LIB',
        label: 'Library',
        institution: 'CSI01'
    },
    {
        value: 'LING',
        label: 'Lingusitics',
        institution: 'CSI01'
    },
    {
        value: 'LNG',
        label: 'Language',
        institution: 'CSI01'
    },
    {
        value: 'MAM',
        label: 'Maintain Matriculation',
        institution: 'CSI01'
    },
    {
        value: 'MDT',
        label: 'Medical Technology',
        institution: 'CSI01'
    },
    {
        value: 'MGT',
        label: 'Management',
        institution: 'CSI01'
    },
    {
        value: 'MKT',
        label: 'Marketing',
        institution: 'CSI01'
    },
    {
        value: 'MLS',
        label: 'Medical Lab Science',
        institution: 'CSI01'
    },
    {
        value: 'MTH',
        label: 'Mathematics',
        institution: 'CSI01'
    },
    {
        value: 'MUS',
        label: 'Music',
        institution: 'CSI01'
    },
    {
        value: 'NRS',
        label: 'Nursing',
        institution: 'CSI01'
    },
    {
        value: 'NSM',
        label: 'Neuroscience',
        institution: 'CSI01'
    },
    {
        value: 'NSO',
        label: 'New Student Orientation',
        institution: 'CSI01'
    },
    {
        value: 'PED',
        label: 'Physical Education',
        institution: 'CSI01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'CSI01'
    },
    {
        value: 'PHL',
        label: 'Philosophy',
        institution: 'CSI01'
    },
    {
        value: 'PHO',
        label: 'Photography',
        institution: 'CSI01'
    },
    {
        value: 'PHT',
        label: 'Physical Therapy',
        institution: 'CSI01'
    },
    {
        value: 'PHY',
        label: 'Physics',
        institution: 'CSI01'
    },
    {
        value: 'POL',
        label: 'Political Science',
        institution: 'CSI01'
    },
    {
        value: 'PSY',
        label: 'Psychology',
        institution: 'CSI01'
    },
    {
        value: 'ROOM',
        label: 'Room Reserved',
        institution: 'CSI01'
    },
    {
        value: 'SLS',
        label: 'Science Letter & Society',
        institution: 'CSI01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'CSI01'
    },
    {
        value: 'SPD',
        label: 'Student Service Dev',
        institution: 'CSI01'
    },
    {
        value: 'SPN',
        label: 'Spanish',
        institution: 'CSI01'
    },
    {
        value: 'STABD',
        label: 'Study Abroad',
        institution: 'CSI01'
    },
    {
        value: 'SWK',
        label: 'Social Work',
        institution: 'CSI01'
    },
    {
        value: 'WGS',
        label: 'Women\'s, Gender & Sex St',
        institution: 'CSI01'
    },
    {
        value: 'AES',
        label: 'Arch & Environl Studies',
        institution: 'CTY01'
    },
    {
        value: 'ANTH',
        label: 'Anthropology',
        institution: 'CTY01'
    },
    {
        value: 'ARAB',
        label: 'Arabic',
        institution: 'CTY01'
    },
    {
        value: 'ARCH',
        label: 'Architecture',
        institution: 'CTY01'
    },
    {
        value: 'ART',
        label: 'Art',
        institution: 'CTY01'
    },
    {
        value: 'ARTE',
        label: 'Art Education',
        institution: 'CTY01'
    },
    {
        value: 'ASIA',
        label: 'Asian Studies',
        institution: 'CTY01'
    },
    {
        value: 'ASTR',
        label: 'Astronomy',
        institution: 'CTY01'
    },
    {
        value: 'BIO',
        label: 'Biology',
        institution: 'CTY01'
    },
    {
        value: 'BIOE',
        label: 'Biology Education',
        institution: 'CTY01'
    },
    {
        value: 'BLST',
        label: 'Black Studies',
        institution: 'CTY01'
    },
    {
        value: 'BME',
        label: 'Biomedical Engineering',
        institution: 'CTY01'
    },
    {
        value: 'CE',
        label: 'Civil Engineering',
        institution: 'CTY01'
    },
    {
        value: 'CHE',
        label: 'Chemistry',
        institution: 'CTY01'
    },
    {
        value: 'CHEM',
        label: 'Chemistry',
        institution: 'CTY01'
    },
    {
        value: 'CHIN',
        label: 'Chinese',
        institution: 'CTY01'
    },
    {
        value: 'CLSS',
        label: 'Classics',
        institution: 'CTY01'
    },
    {
        value: 'CSC',
        label: 'Computer Science',
        institution: 'CTY01'
    },
    {
        value: 'DSE',
        label: 'Data Science and Engin',
        institution: 'CTY01'
    },
    {
        value: 'EAS',
        label: 'Earth & Atmospheric Sci',
        institution: 'CTY01'
    },
    {
        value: 'ECO',
        label: 'Economics',
        institution: 'CTY01'
    },
    {
        value: 'EDCE',
        label: 'Childhood Education',
        institution: 'CTY01'
    },
    {
        value: 'EDLS',
        label: 'Leadership Education',
        institution: 'CTY01'
    },
    {
        value: 'EDSE',
        label: 'Secondary Education',
        institution: 'CTY01'
    },
    {
        value: 'EDUC',
        label: 'Education',
        institution: 'CTY01'
    },
    {
        value: 'EE',
        label: 'Electrical Engineering',
        institution: 'CTY01'
    },
    {
        value: 'ENGL',
        label: 'English',
        institution: 'CTY01'
    },
    {
        value: 'ENGR',
        label: 'Engineering',
        institution: 'CTY01'
    },
    {
        value: 'ESL',
        label: 'English as a Second Lang',
        institution: 'CTY01'
    },
    {
        value: 'FIQWS',
        label: 'Freshman Inq Writ Sem',
        institution: 'CTY01'
    },
    {
        value: 'FQUAN',
        label: 'Freshman Quan Analysis',
        institution: 'CTY01'
    },
    {
        value: 'FREN',
        label: 'French',
        institution: 'CTY01'
    },
    {
        value: 'HEB',
        label: 'Hebrew',
        institution: 'CTY01'
    },
    {
        value: 'HIST',
        label: 'History',
        institution: 'CTY01'
    },
    {
        value: 'IAS',
        label: 'Interdiscip Arts & Sci',
        institution: 'CTY01'
    },
    {
        value: 'INTL',
        label: 'International Studies',
        institution: 'CTY01'
    },
    {
        value: 'IR',
        label: 'International Relations',
        institution: 'CTY01'
    },
    {
        value: 'ITAL',
        label: 'Italian',
        institution: 'CTY01'
    },
    {
        value: 'JAP',
        label: 'Japanese',
        institution: 'CTY01'
    },
    {
        value: 'JWST',
        label: 'Jewish Studies',
        institution: 'CTY01'
    },
    {
        value: 'LAAR',
        label: 'Landscape Architecture',
        institution: 'CTY01'
    },
    {
        value: 'LALS',
        label: 'Latin Amer & Latino Std',
        institution: 'CTY01'
    },
    {
        value: 'LAT',
        label: 'Latino Studies',
        institution: 'CTY01'
    },
    {
        value: 'LIB',
        label: 'Library',
        institution: 'CTY01'
    },
    {
        value: 'MAM',
        label: 'Maintain Matriculation',
        institution: 'CTY01'
    },
    {
        value: 'MATH',
        label: 'Mathematics',
        institution: 'CTY01'
    },
    {
        value: 'MATHE',
        label: 'Mathematics Education',
        institution: 'CTY01'
    },
    {
        value: 'MCA',
        label: 'Media Communication Arts',
        institution: 'CTY01'
    },
    {
        value: 'ME',
        label: 'Mechanical Engineering',
        institution: 'CTY01'
    },
    {
        value: 'MED',
        label: 'Biomedical Education',
        institution: 'CTY01'
    },
    {
        value: 'MEDS',
        label: 'Medical Science',
        institution: 'CTY01'
    },
    {
        value: 'MHC',
        label: 'Macaulay Honors College',
        institution: 'CTY01'
    },
    {
        value: 'MIS',
        label: 'MIS',
        institution: 'CTY01'
    },
    {
        value: 'MSCI',
        label: 'Military Sci',
        institution: 'CTY01'
    },
    {
        value: 'MUS',
        label: 'Music',
        institution: 'CTY01'
    },
    {
        value: 'NCUNY',
        label: 'Non CUNY Permit',
        institution: 'CTY01'
    },
    {
        value: 'NSS',
        label: 'New Student Seminar',
        institution: 'CTY01'
    },
    {
        value: 'PA',
        label: 'Public Administration',
        institution: 'CTY01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'CTY01'
    },
    {
        value: 'PHIL',
        label: 'Philosophy',
        institution: 'CTY01'
    },
    {
        value: 'PHYS',
        label: 'Physics',
        institution: 'CTY01'
    },
    {
        value: 'PHYSE',
        label: 'Physics Education',
        institution: 'CTY01'
    },
    {
        value: 'PORT',
        label: 'Portuguese',
        institution: 'CTY01'
    },
    {
        value: 'PSC',
        label: 'Political Science',
        institution: 'CTY01'
    },
    {
        value: 'PSCE',
        label: 'Political Science Educ',
        institution: 'CTY01'
    },
    {
        value: 'PSM',
        label: 'Public Service Admin',
        institution: 'CTY01'
    },
    {
        value: 'PSY',
        label: 'Psychology',
        institution: 'CTY01'
    },
    {
        value: 'SCI',
        label: 'Science',
        institution: 'CTY01'
    },
    {
        value: 'SCIE',
        label: 'Science Education',
        institution: 'CTY01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'CTY01'
    },
    {
        value: 'SPAN',
        label: 'Spanish',
        institution: 'CTY01'
    },
    {
        value: 'SPCH',
        label: 'Speech',
        institution: 'CTY01'
    },
    {
        value: 'SPED',
        label: 'Special Edu',
        institution: 'CTY01'
    },
    {
        value: 'SSC',
        label: 'Social Science',
        institution: 'CTY01'
    },
    {
        value: 'STABD',
        label: 'Study Abroad',
        institution: 'CTY01'
    },
    {
        value: 'SUS',
        label: 'Sustainability',
        institution: 'CTY01'
    },
    {
        value: 'THTR',
        label: 'Theatre',
        institution: 'CTY01'
    },
    {
        value: 'UD',
        label: 'Urban Design',
        institution: 'CTY01'
    },
    {
        value: 'URB',
        label: 'Urban Studies',
        institution: 'CTY01'
    },
    {
        value: 'USSO',
        label: 'US Society',
        institution: 'CTY01'
    },
    {
        value: 'WCIV',
        label: 'World Civilization',
        institution: 'CTY01'
    },
    {
        value: 'WHUM',
        label: 'World Humanities',
        institution: 'CTY01'
    },
    {
        value: 'WIU',
        label: 'Weighted Instruct Units',
        institution: 'CTY01'
    },
    {
        value: 'WS',
        label: 'Women\'s Studies',
        institution: 'CTY01'
    },
    {
        value: 'ACCT',
        label: 'Accounting',
        institution: 'GRD01'
    },
    {
        value: 'ADM',
        label: 'Office Administration',
        institution: 'GRD01'
    },
    {
        value: 'AFCP',
        label: 'Africana Studies CP',
        institution: 'GRD01'
    },
    {
        value: 'AMNH',
        label: 'Amer Mus Nat Hist',
        institution: 'GRD01'
    },
    {
        value: 'ANTH',
        label: 'Anthropology',
        institution: 'GRD01'
    },
    {
        value: 'ART',
        label: 'Art',
        institution: 'GRD01'
    },
    {
        value: 'ASCP',
        label: 'American Studies',
        institution: 'GRD01'
    },
    {
        value: 'AUD',
        label: 'Audiology',
        institution: 'GRD01'
    },
    {
        value: 'BICM',
        label: 'Biochemistry',
        institution: 'GRD01'
    },
    {
        value: 'BIOL',
        label: 'Biology',
        institution: 'GRD01'
    },
    {
        value: 'BUS',
        label: 'Business Administration',
        institution: 'GRD01'
    },
    {
        value: 'CHEM',
        label: 'Chemistry',
        institution: 'GRD01'
    },
    {
        value: 'CIS',
        label: 'Computer Information Sys',
        institution: 'GRD01'
    },
    {
        value: 'C',
        label: 'Comparative Literature',
        institution: 'GRD01'
    },
    {
        value: 'CLAS',
        label: 'Classics',
        institution: 'GRD01'
    },
    {
        value: 'CNS',
        label: 'Cognitive Neuroscience',
        institution: 'GRD01'
    },
    {
        value: 'COLM',
        label: 'Columbia University',
        institution: 'GRD01'
    },
    {
        value: 'CRJ',
        label: 'Criminal Justice',
        institution: 'GRD01'
    },
    {
        value: 'C',
        label: 'Comparative Literature',
        institution: 'GRD01'
    },
    {
        value: 'CTCP',
        label: 'Critical Theory CP',
        institution: 'GRD01'
    },
    {
        value: 'DATA',
        label: 'Data Analysis',
        institution: 'GRD01'
    },
    {
        value: 'DCP',
        label: 'Demography Cert Program',
        institution: 'GRD01'
    },
    {
        value: 'DHUM',
        label: 'Digital Humanities',
        institution: 'GRD01'
    },
    {
        value: 'ECO',
        label: 'Economics',
        institution: 'GRD01'
    },
    {
        value: 'ECON',
        label: 'Economics',
        institution: 'GRD01'
    },
    {
        value: 'EES',
        label: 'Earth& Environmental Sci',
        institution: 'GRD01'
    },
    {
        value: 'ENGL',
        label: 'English',
        institution: 'GRD01'
    },
    {
        value: 'EPSY',
        label: 'Educational Psychology',
        institution: 'GRD01'
    },
    {
        value: 'FIN',
        label: 'Finance',
        institution: 'GRD01'
    },
    {
        value: 'FORD',
        label: 'Fordham University',
        institution: 'GRD01'
    },
    {
        value: 'FREN',
        label: 'French',
        institution: 'GRD01'
    },
    {
        value: 'FSCP',
        label: 'Film Studies',
        institution: 'GRD01'
    },
    {
        value: 'HIST',
        label: 'History',
        institution: 'GRD01'
    },
    {
        value: 'IDS',
        label: 'IDS -Interdisciplinary Studies',
        institution: 'GRD01'
    },
    {
        value: 'IMS',
        label: 'Int\'l Migration Studies',
        institution: 'GRD01'
    },
    {
        value: 'ITCP',
        label: 'ITCP-Interactive Tech&Pedagogy',
        institution: 'GRD01'
    },
    {
        value: 'LING',
        label: 'Lingusitics',
        institution: 'GRD01'
    },
    {
        value: 'MALS',
        label: 'Liberal Studies',
        institution: 'GRD01'
    },
    {
        value: 'MATH',
        label: 'Mathematics',
        institution: 'GRD01'
    },
    {
        value: 'MES',
        label: 'Media Studies',
        institution: 'GRD01'
    },
    {
        value: 'MGT',
        label: 'Management',
        institution: 'GRD01'
    },
    {
        value: 'MKT',
        label: 'Marketing',
        institution: 'GRD01'
    },
    {
        value: 'M',
        label: 'Maintenance of Matriculation',
        institution: 'GRD01'
    },
    {
        value: 'MSCP',
        label: 'Medieval Studies',
        institution: 'GRD01'
    },
    {
        value: 'MUS',
        label: 'Music',
        institution: 'GRD01'
    },
    {
        value: 'NEWS',
        label: 'New School University',
        institution: 'GRD01'
    },
    {
        value: 'NURS',
        label: 'Nursing',
        institution: 'GRD01'
    },
    {
        value: 'NYU',
        label: 'New York University',
        institution: 'GRD01'
    },
    {
        value: 'PDEV',
        label: 'PDEV -Professional Development',
        institution: 'GRD01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'GRD01'
    },
    {
        value: 'PHIL',
        label: 'Philosophy',
        institution: 'GRD01'
    },
    {
        value: 'PHYS',
        label: 'Physics',
        institution: 'GRD01'
    },
    {
        value: 'PRIN',
        label: 'Princeton University',
        institution: 'GRD01'
    },
    {
        value: 'P',
        label: 'Political Science',
        institution: 'GRD01'
    },
    {
        value: 'PSYC',
        label: 'Psychology',
        institution: 'GRD01'
    },
    {
        value: 'QMSS',
        label: 'Quant Meth in Social Sciences',
        institution: 'GRD01'
    },
    {
        value: 'ROR',
        label: 'Registered on Record',
        institution: 'GRD01'
    },
    {
        value: 'RUTG',
        label: 'Rutgers University',
        institution: 'GRD01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'GRD01'
    },
    {
        value: 'SPAN',
        label: 'Spanish',
        institution: 'GRD01'
    },
    {
        value: 'SPCH',
        label: 'Speech',
        institution: 'GRD01'
    },
    {
        value: 'SSW',
        label: 'School of Social Work',
        institution: 'GRD01'
    },
    {
        value: 'STAT',
        label: 'Statistics',
        institution: 'GRD01'
    },
    {
        value: 'TEAC',
        label: 'Teachers College Consortium',
        institution: 'GRD01'
    },
    {
        value: 'THEA',
        label: 'Theatre',
        institution: 'GRD01'
    },
    {
        value: 'U',
        label: 'Urban Education',
        institution: 'GRD01'
    },
    {
        value: 'WGS',
        label: 'Women\'s, Gender & Sex St',
        institution: 'GRD01'
    },
    {
        value: 'WIU',
        label: 'Weighted Instruct Units',
        institution: 'GRD01'
    },
    {
        value: 'WSCP',
        label: 'Women\'s Studies',
        institution: 'GRD01'
    },
    {
        value: 'ACC',
        label: 'Accounting',
        institution: 'HOS01'
    },
    {
        value: 'ANTH',
        label: 'Anthropology',
        institution: 'HOS01'
    },
    {
        value: 'BIO',
        label: 'Biology',
        institution: 'HOS01'
    },
    {
        value: 'BLS',
        label: 'Black Studies',
        institution: 'HOS01'
    },
    {
        value: 'BUS',
        label: 'Business Administration',
        institution: 'HOS01'
    },
    {
        value: 'CAP',
        label: 'Capstone Course',
        institution: 'HOS01'
    },
    {
        value: 'CHE',
        label: 'Chemistry',
        institution: 'HOS01'
    },
    {
        value: 'CJ',
        label: 'Criminal Justice',
        institution: 'HOS01'
    },
    {
        value: 'COM',
        label: 'Communication Studies',
        institution: 'HOS01'
    },
    {
        value: 'COOP',
        label: 'Cooperative Education',
        institution: 'HOS01'
    },
    {
        value: 'CSC',
        label: 'Computer Science',
        institution: 'HOS01'
    },
    {
        value: 'CSM',
        label: 'CUNYStart Math',
        institution: 'HOS01'
    },
    {
        value: 'CSR',
        label: 'CUNYStart Reading',
        institution: 'HOS01'
    },
    {
        value: 'CSW',
        label: 'CUNYStart Writing',
        institution: 'HOS01'
    },
    {
        value: 'DD',
        label: 'Digital Design',
        institution: 'HOS01'
    },
    {
        value: 'DEN',
        label: 'Dental Hygiene',
        institution: 'HOS01'
    },
    {
        value: 'DM',
        label: 'Digital Music',
        institution: 'HOS01'
    },
    {
        value: 'ECO',
        label: 'Economics',
        institution: 'HOS01'
    },
    {
        value: 'EDU',
        label: 'Education',
        institution: 'HOS01'
    },
    {
        value: 'EN',
        label: 'English Workshop',
        institution: 'HOS01'
    },
    {
        value: 'ENG',
        label: 'English',
        institution: 'HOS01'
    },
    {
        value: 'ENGR',
        label: 'Engineering',
        institution: 'HOS01'
    },
    {
        value: 'ENV',
        label: 'Environmental Studies',
        institution: 'HOS01'
    },
    {
        value: 'ESL',
        label: 'English as a Second Lang',
        institution: 'HOS01'
    },
    {
        value: 'FRE',
        label: 'French',
        institution: 'HOS01'
    },
    {
        value: 'FS',
        label: 'Food Studies',
        institution: 'HOS01'
    },
    {
        value: 'FYS',
        label: 'First Year Seminar',
        institution: 'HOS01'
    },
    {
        value: 'GD',
        label: 'Game Design',
        institution: 'HOS01'
    },
    {
        value: 'GERO',
        label: 'Gerontology',
        institution: 'HOS01'
    },
    {
        value: 'HIS',
        label: 'History',
        institution: 'HOS01'
    },
    {
        value: 'HLT',
        label: 'Health Education',
        institution: 'HOS01'
    },
    {
        value: 'HUM',
        label: 'Human Services',
        institution: 'HOS01'
    },
    {
        value: 'ITA',
        label: 'Italian',
        institution: 'HOS01'
    },
    {
        value: 'JPN',
        label: 'Japanese',
        institution: 'HOS01'
    },
    {
        value: 'LAC',
        label: 'Latin & Caribbean Stds',
        institution: 'HOS01'
    },
    {
        value: 'LAW',
        label: 'Law',
        institution: 'HOS01'
    },
    {
        value: 'LEG',
        label: 'Paralegal Studies',
        institution: 'HOS01'
    },
    {
        value: 'LIN',
        label: 'Linguistics',
        institution: 'HOS01'
    },
    {
        value: 'MA',
        label: 'Math Workshop',
        institution: 'HOS01'
    },
    {
        value: 'MAT',
        label: 'Mathematics',
        institution: 'HOS01'
    },
    {
        value: 'MTS',
        label: 'Math Start Program',
        institution: 'HOS01'
    },
    {
        value: 'MUS',
        label: 'Music',
        institution: 'HOS01'
    },
    {
        value: 'NUR',
        label: 'Nursing RN',
        institution: 'HOS01'
    },
    {
        value: 'OT',
        label: 'Office Technology',
        institution: 'HOS01'
    },
    {
        value: 'PED',
        label: 'Physical Education',
        institution: 'HOS01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'HOS01'
    },
    {
        value: 'PHI',
        label: 'Philosophy',
        institution: 'HOS01'
    },
    {
        value: 'PHY',
        label: 'Physics',
        institution: 'HOS01'
    },
    {
        value: 'POL',
        label: 'Political Science',
        institution: 'HOS01'
    },
    {
        value: 'PPA',
        label: 'Public Policy & Admin',
        institution: 'HOS01'
    },
    {
        value: 'PSY',
        label: 'Psychology',
        institution: 'HOS01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'HOS01'
    },
    {
        value: 'SPA',
        label: 'Spanish',
        institution: 'HOS01'
    },
    {
        value: 'SSD',
        label: 'Freshmen Orientation',
        institution: 'HOS01'
    },
    {
        value: 'SW',
        label: 'Social Work',
        institution: 'HOS01'
    },
    {
        value: 'VPA',
        label: 'Visual & Performing Arts',
        institution: 'HOS01'
    },
    {
        value: 'WGS',
        label: 'Women\'s, Gender & Sex St',
        institution: 'HOS01'
    },
    {
        value: 'XRA',
        label: 'Radiologic Technology',
        institution: 'HOS01'
    },
    {
        value: 'ACC',
        label: 'Accounting',
        institution: 'HTR01'
    },
    {
        value: 'ACCP',
        label: 'Accounting',
        institution: 'HTR01'
    },
    {
        value: 'ACSK',
        label: 'Academic Skills',
        institution: 'HTR01'
    },
    {
        value: 'ADSUP',
        label: 'Admin & Supervision',
        institution: 'HTR01'
    },
    {
        value: 'AFPRL',
        label: 'Africana &Latino Stds',
        institution: 'HTR01'
    },
    {
        value: 'ANTH',
        label: 'Anthropology',
        institution: 'HTR01'
    },
    {
        value: 'ANTHC',
        label: 'Anthropology Culture',
        institution: 'HTR01'
    },
    {
        value: 'ANTHP',
        label: 'Physical Anthropology',
        institution: 'HTR01'
    },
    {
        value: 'ARB',
        label: 'Arabic',
        institution: 'HTR01'
    },
    {
        value: 'ARTCR',
        label: 'Art Creative',
        institution: 'HTR01'
    },
    {
        value: 'ARTED',
        label: 'Art Education',
        institution: 'HTR01'
    },
    {
        value: 'ARTH',
        label: 'Art Theory & History',
        institution: 'HTR01'
    },
    {
        value: 'ARTLA',
        label: 'Art Liberal Arts',
        institution: 'HTR01'
    },
    {
        value: 'ASIAN',
        label: 'Asian American Studies',
        institution: 'HTR01'
    },
    {
        value: 'ASTRO',
        label: 'Astronomy',
        institution: 'HTR01'
    },
    {
        value: 'BILED',
        label: 'BILED',
        institution: 'HTR01'
    },
    {
        value: 'BIOCH',
        label: 'Biochemistry',
        institution: 'HTR01'
    },
    {
        value: 'BIOL',
        label: 'Biology',
        institution: 'HTR01'
    },
    {
        value: 'BUS',
        label: 'Business Administration',
        institution: 'HTR01'
    },
    {
        value: 'CEDC',
        label: 'Curriculum & Teaching',
        institution: 'HTR01'
    },
    {
        value: 'CEDF',
        label: 'Educational Foundations',
        institution: 'HTR01'
    },
    {
        value: 'CHEM',
        label: 'Chemistry',
        institution: 'HTR01'
    },
    {
        value: 'CHIN',
        label: 'Chinese',
        institution: 'HTR01'
    },
    {
        value: 'CHND',
        label: 'Chinese Education',
        institution: 'HTR01'
    },
    {
        value: 'CLA',
        label: 'Classical Cul & Arch',
        institution: 'HTR01'
    },
    {
        value: 'COCO',
        label: 'Core Counseling',
        institution: 'HTR01'
    },
    {
        value: 'COMHE',
        label: 'COMHE',
        institution: 'HTR01'
    },
    {
        value: 'COMPL',
        label: 'Comparative Literature',
        institution: 'HTR01'
    },
    {
        value: 'COMSC',
        label: 'Health Sci Comm Sci',
        institution: 'HTR01'
    },
    {
        value: 'COUNM',
        label: 'Educ Found Mental Hlth',
        institution: 'HTR01'
    },
    {
        value: 'COUNR',
        label: 'Educ found Rehab Coun',
        institution: 'HTR01'
    },
    {
        value: 'COUNS',
        label: 'Educ Found Counseling',
        institution: 'HTR01'
    },
    {
        value: 'CSCI',
        label: 'Computer Sci',
        institution: 'HTR01'
    },
    {
        value: 'CUNBA',
        label: 'CUNY BA',
        institution: 'HTR01'
    },
    {
        value: 'DAN',
        label: 'Dance',
        institution: 'HTR01'
    },
    {
        value: 'DANED',
        label: 'Dance Education',
        institution: 'HTR01'
    },
    {
        value: 'DANEP',
        label: 'Dance Education NLA',
        institution: 'HTR01'
    },
    {
        value: 'ECC',
        label: 'Early Child Ed',
        institution: 'HTR01'
    },
    {
        value: 'ECF',
        label: 'Educational Foundations',
        institution: 'HTR01'
    },
    {
        value: 'ECO',
        label: 'Economics',
        institution: 'HTR01'
    },
    {
        value: 'EDABA',
        label: 'Ed Applied Beh Anlysis',
        institution: 'HTR01'
    },
    {
        value: 'EDESL',
        label: 'Curric & Teach (ESL)',
        institution: 'HTR01'
    },
    {
        value: 'EDF',
        label: 'Educational Foundations',
        institution: 'HTR01'
    },
    {
        value: 'EDLIT',
        label: 'Curr & Teach Literacy',
        institution: 'HTR01'
    },
    {
        value: 'EDPS',
        label: 'Educational Foundations',
        institution: 'HTR01'
    },
    {
        value: 'EDUC',
        label: 'Education',
        institution: 'HTR01'
    },
    {
        value: 'ENGL',
        label: 'English',
        institution: 'HTR01'
    },
    {
        value: 'ENRT',
        label: 'CUNY Enroute Masters',
        institution: 'HTR01'
    },
    {
        value: 'FILM',
        label: 'Film Studies',
        institution: 'HTR01'
    },
    {
        value: 'FILMP',
        label: 'Film',
        institution: 'HTR01'
    },
    {
        value: 'FILPL',
        label: 'Film LA',
        institution: 'HTR01'
    },
    {
        value: 'FREN',
        label: 'French',
        institution: 'HTR01'
    },
    {
        value: 'FYS',
        label: 'First Year Seminar',
        institution: 'HTR01'
    },
    {
        value: 'GEOG',
        label: 'Geography',
        institution: 'HTR01'
    },
    {
        value: 'GEOL',
        label: 'Geology',
        institution: 'HTR01'
    },
    {
        value: 'GERMN',
        label: 'German',
        institution: 'HTR01'
    },
    {
        value: 'GRK',
        label: 'Greek',
        institution: 'HTR01'
    },
    {
        value: 'GSR',
        label: 'Graduate Social Research',
        institution: 'HTR01'
    },
    {
        value: 'GTECH',
        label: 'Geography Technology',
        institution: 'HTR01'
    },
    {
        value: 'HEBR',
        label: 'Hebrew',
        institution: 'HTR01'
    },
    {
        value: 'HED',
        label: 'Health Education',
        institution: 'HTR01'
    },
    {
        value: 'HEDP',
        label: 'Education NLA',
        institution: 'HTR01'
    },
    {
        value: 'HIST',
        label: 'History',
        institution: 'HTR01'
    },
    {
        value: 'HMBIO',
        label: 'Human Biology',
        institution: 'HTR01'
    },
    {
        value: 'HONS',
        label: 'Council On Honors',
        institution: 'HTR01'
    },
    {
        value: 'HR',
        label: 'HR – Human Rights',
        institution: 'HTR01'
    },
    {
        value: 'HUM',
        label: 'Human Services',
        institution: 'HTR01'
    },
    {
        value: 'IMA',
        label: 'Integrated Media Arts',
        institution: 'HTR01'
    },
    {
        value: 'ITAL',
        label: 'Italian',
        institution: 'HTR01'
    },
    {
        value: 'JPN',
        label: 'Japanese',
        institution: 'HTR01'
    },
    {
        value: 'JS',
        label: 'Jewish Studies',
        institution: 'HTR01'
    },
    {
        value: 'LACS',
        label: 'Latin Amer & Carib Stu',
        institution: 'HTR01'
    },
    {
        value: 'LAT',
        label: 'Latino Studies',
        institution: 'HTR01'
    },
    {
        value: 'LATED',
        label: 'Latin Education',
        institution: 'HTR01'
    },
    {
        value: 'LIBR',
        label: 'Library',
        institution: 'HTR01'
    },
    {
        value: 'LING',
        label: 'Lingusitics',
        institution: 'HTR01'
    },
    {
        value: 'MAM',
        label: 'Maintain Matriculation',
        institution: 'HTR01'
    },
    {
        value: 'MATH',
        label: 'Mathematics',
        institution: 'HTR01'
    },
    {
        value: 'MEDIA',
        label: 'Media Studies',
        institution: 'HTR01'
    },
    {
        value: 'MEDP',
        label: 'Media & Dgtl Film Prod',
        institution: 'HTR01'
    },
    {
        value: 'MEDPL',
        label: 'Media Studies LA',
        institution: 'HTR01'
    },
    {
        value: 'MHC',
        label: 'Macaulay Honors College',
        institution: 'HTR01'
    },
    {
        value: 'MLS',
        label: 'Medical Lab Science',
        institution: 'HTR01'
    },
    {
        value: 'MLSP',
        label: 'Medical Lab Science',
        institution: 'HTR01'
    },
    {
        value: 'MUS',
        label: 'Music',
        institution: 'HTR01'
    },
    {
        value: 'MUSED',
        label: 'Music',
        institution: 'HTR01'
    },
    {
        value: 'MUSHL',
        label: 'History',
        institution: 'HTR01'
    },
    {
        value: 'MUSIN',
        label: 'Music -Indiv Stu Prjct',
        institution: 'HTR01'
    },
    {
        value: 'MUSPF',
        label: 'Performance',
        institution: 'HTR01'
    },
    {
        value: 'MUSTH',
        label: 'Theory',
        institution: 'HTR01'
    },
    {
        value: 'NFS',
        label: 'NFS',
        institution: 'HTR01'
    },
    {
        value: 'NURS',
        label: 'Nursing',
        institution: 'HTR01'
    },
    {
        value: 'NUTR',
        label: 'NUTR',
        institution: 'HTR01'
    },
    {
        value: 'ONFIL',
        label: 'On File',
        institution: 'HTR01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'HTR01'
    },
    {
        value: 'PGEOG',
        label: 'Physical',
        institution: 'HTR01'
    },
    {
        value: 'PH',
        label: 'Physics',
        institution: 'HTR01'
    },
    {
        value: 'PHILO',
        label: 'Philosophy',
        institution: 'HTR01'
    },
    {
        value: 'PHYS',
        label: 'Physics',
        institution: 'HTR01'
    },
    {
        value: 'POL',
        label: 'Political Science',
        institution: 'HTR01'
    },
    {
        value: 'POLSC',
        label: 'Political Science',
        institution: 'HTR01'
    },
    {
        value: 'PORT',
        label: 'Portuguese',
        institution: 'HTR01'
    },
    {
        value: 'PSYCH',
        label: 'Psychology',
        institution: 'HTR01'
    },
    {
        value: 'PT',
        label: 'Physical Therapy',
        institution: 'HTR01'
    },
    {
        value: 'PUPOL',
        label: 'Public Policy',
        institution: 'HTR01'
    },
    {
        value: 'QSTAP',
        label: 'Curriculum & Teaching',
        institution: 'HTR01'
    },
    {
        value: 'QSTB',
        label: 'Educational Foundation',
        institution: 'HTR01'
    },
    {
        value: 'QSTP',
        label: 'Educational Foundation',
        institution: 'HTR01'
    },
    {
        value: 'RAS',
        label: 'Russian Area Studies',
        institution: 'HTR01'
    },
    {
        value: 'REL',
        label: 'Religion',
        institution: 'HTR01'
    },
    {
        value: 'RUSS',
        label: 'Russian',
        institution: 'HTR01'
    },
    {
        value: 'SCI',
        label: 'Science',
        institution: 'HTR01'
    },
    {
        value: 'SEDC',
        label: 'Curriculum & Teaching',
        institution: 'HTR01'
    },
    {
        value: 'SEDCP',
        label: 'Curiculum & Teach NLA',
        institution: 'HTR01'
    },
    {
        value: 'SEDF',
        label: 'Educational Foundations',
        institution: 'HTR01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'HTR01'
    },
    {
        value: 'SOSCI',
        label: 'Social Sci (Interdisp)',
        institution: 'HTR01'
    },
    {
        value: 'SPAN',
        label: 'Spanish',
        institution: 'HTR01'
    },
    {
        value: 'SPED',
        label: 'Special Edu',
        institution: 'HTR01'
    },
    {
        value: 'SPEDE',
        label: 'SPEDE',
        institution: 'HTR01'
    },
    {
        value: 'SSW',
        label: 'School of Social Work',
        institution: 'HTR01'
    },
    {
        value: 'STABD',
        label: 'Study Abroad',
        institution: 'HTR01'
    },
    {
        value: 'STAT',
        label: 'Statistics',
        institution: 'HTR01'
    },
    {
        value: 'SW',
        label: 'Social Work',
        institution: 'HTR01'
    },
    {
        value: 'THC',
        label: 'Theatre And Film',
        institution: 'HTR01'
    },
    {
        value: 'THEA',
        label: 'Theatre',
        institution: 'HTR01'
    },
    {
        value: 'URBG',
        label: 'Urban Affairs',
        institution: 'HTR01'
    },
    {
        value: 'URBP',
        label: 'Urban Planning',
        institution: 'HTR01'
    },
    {
        value: 'URBS',
        label: 'Urban Studies',
        institution: 'HTR01'
    },
    {
        value: 'WGS',
        label: 'Women\'s, Gender & Sex St',
        institution: 'HTR01'
    },
    {
        value: 'WGSA',
        label: 'Women & Gender Studies',
        institution: 'HTR01'
    },
    {
        value: 'WGSC',
        label: 'Women & Gender Studies',
        institution: 'HTR01'
    },
    {
        value: 'WGSI',
        label: 'Women & Gender Studies',
        institution: 'HTR01'
    },
    {
        value: 'WGSL',
        label: 'Women & Gender Studies',
        institution: 'HTR01'
    },
    {
        value: 'WGSP',
        label: 'Women & Gender Studies',
        institution: 'HTR01'
    },
    {
        value: 'WGSS',
        label: 'Women & Gender Studies',
        institution: 'HTR01'
    },
    {
        value: 'WGST',
        label: 'Womens & Gender Studies',
        institution: 'HTR01'
    },
    {
        value: 'ACC',
        label: 'Accounting',
        institution: 'JJC01'
    },
    {
        value: 'AFR',
        label: 'Africana Studies',
        institution: 'JJC01'
    },
    {
        value: 'ANT',
        label: 'Anthropology',
        institution: 'JJC01'
    },
    {
        value: 'ARA',
        label: 'Arabic',
        institution: 'JJC01'
    },
    {
        value: 'ART',
        label: 'Art',
        institution: 'JJC01'
    },
    {
        value: 'ASL',
        label: 'American Sign Language',
        institution: 'JJC01'
    },
    {
        value: 'BIO',
        label: 'Biology',
        institution: 'JJC01'
    },
    {
        value: 'CHE',
        label: 'Chemistry',
        institution: 'JJC01'
    },
    {
        value: 'CHI',
        label: 'Chinese',
        institution: 'JJC01'
    },
    {
        value: 'CHS',
        label: 'Counseling & Human Svcs',
        institution: 'JJC01'
    },
    {
        value: 'CJBA',
        label: 'Criminal Justice, BA',
        institution: 'JJC01'
    },
    {
        value: 'CJBS',
        label: 'Criminal Justice, BS',
        institution: 'JJC01'
    },
    {
        value: 'COM',
        label: 'Communication Studies',
        institution: 'JJC01'
    },
    {
        value: 'COR',
        label: 'Core',
        institution: 'JJC01'
    },
    {
        value: 'CRJ',
        label: 'Criminal Justice',
        institution: 'JJC01'
    },
    {
        value: 'CSCI',
        label: 'Computer Sci',
        institution: 'JJC01'
    },
    {
        value: 'CSL',
        label: 'Counseling',
        institution: 'JJC01'
    },
    {
        value: 'CUNBA',
        label: 'CUNY BA',
        institution: 'JJC01'
    },
    {
        value: 'DRA',
        label: 'Dramatic Arts',
        institution: 'JJC01'
    },
    {
        value: 'ECO',
        label: 'Economics',
        institution: 'JJC01'
    },
    {
        value: 'EDU',
        label: 'Education',
        institution: 'JJC01'
    },
    {
        value: 'EJS',
        label: 'EJS – Environmental Justice St',
        institution: 'JJC01'
    },
    {
        value: 'ENG',
        label: 'English',
        institution: 'JJC01'
    },
    {
        value: 'ESA',
        label: 'Emrg Svcs Administration',
        institution: 'JJC01'
    },
    {
        value: 'FCM',
        label: 'Forensic Computing',
        institution: 'JJC01'
    },
    {
        value: 'FIS',
        label: 'Fire Science',
        institution: 'JJC01'
    },
    {
        value: 'FOS',
        label: 'Forensic Science',
        institution: 'JJC01'
    },
    {
        value: 'FRE',
        label: 'French',
        institution: 'JJC01'
    },
    {
        value: 'GEN',
        label: 'Gender Studies',
        institution: 'JJC01'
    },
    {
        value: 'GER',
        label: 'German',
        institution: 'JJC01'
    },
    {
        value: 'HIS',
        label: 'History',
        institution: 'JJC01'
    },
    {
        value: 'HJS',
        label: 'Humanities & Justice',
        institution: 'JJC01'
    },
    {
        value: 'HON',
        label: 'Honors College Seminars',
        institution: 'JJC01'
    },
    {
        value: 'HR',
        label: 'HR – Human Rights',
        institution: 'JJC01'
    },
    {
        value: 'ICJ',
        label: 'Internat Crim Justice',
        institution: 'JJC01'
    },
    {
        value: 'ISM',
        label: 'Immersion Mathematics',
        institution: 'JJC01'
    },
    {
        value: 'ISP',
        label: 'Interdiscip Studies',
        institution: 'JJC01'
    },
    {
        value: 'ITA',
        label: 'Italian',
        institution: 'JJC01'
    },
    {
        value: 'JPN',
        label: 'Japanese',
        institution: 'JJC01'
    },
    {
        value: 'LAW',
        label: 'Law',
        institution: 'JJC01'
    },
    {
        value: 'LIT',
        label: 'Literature',
        institution: 'JJC01'
    },
    {
        value: 'LLS',
        label: 'Latina/o Studies',
        institution: 'JJC01'
    },
    {
        value: 'LWS',
        label: 'Law & Society',
        institution: 'JJC01'
    },
    {
        value: 'MAM',
        label: 'Maintain Matriculation',
        institution: 'JJC01'
    },
    {
        value: 'MAT',
        label: 'Mathematics',
        institution: 'JJC01'
    },
    {
        value: 'MHC',
        label: 'Macaulay Honors College',
        institution: 'JJC01'
    },
    {
        value: 'MUS',
        label: 'Music',
        institution: 'JJC01'
    },
    {
        value: 'PAD',
        label: 'Public Administration',
        institution: 'JJC01'
    },
    {
        value: 'PED',
        label: 'Physical Education',
        institution: 'JJC01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'JJC01'
    },
    {
        value: 'PHI',
        label: 'Philosophy',
        institution: 'JJC01'
    },
    {
        value: 'PHY',
        label: 'Physics',
        institution: 'JJC01'
    },
    {
        value: 'PMT',
        label: 'Protection Management',
        institution: 'JJC01'
    },
    {
        value: 'POL',
        label: 'Political Science',
        institution: 'JJC01'
    },
    {
        value: 'POR',
        label: 'Portuguese',
        institution: 'JJC01'
    },
    {
        value: 'PSC',
        label: 'Political Science',
        institution: 'JJC01'
    },
    {
        value: 'PSY',
        label: 'Psychology',
        institution: 'JJC01'
    },
    {
        value: 'REL',
        label: 'Religion',
        institution: 'JJC01'
    },
    {
        value: 'SCI',
        label: 'Science',
        institution: 'JJC01'
    },
    {
        value: 'SEC',
        label: 'Med Office Pract & Proc',
        institution: 'JJC01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'JJC01'
    },
    {
        value: 'SPA',
        label: 'Spanish',
        institution: 'JJC01'
    },
    {
        value: 'SSC',
        label: 'Social Science',
        institution: 'JJC01'
    },
    {
        value: 'STA',
        label: 'Statistics',
        institution: 'JJC01'
    },
    {
        value: 'STABD',
        label: 'Study Abroad',
        institution: 'JJC01'
    },
    {
        value: 'TOX',
        label: 'Toxicology',
        institution: 'JJC01'
    },
    {
        value: 'UGR',
        label: 'Undergraduate Studies',
        institution: 'JJC01'
    },
    {
        value: 'ACC',
        label: 'Accounting',
        institution: 'KCC01'
    },
    {
        value: 'ADM',
        label: 'Office Administration',
        institution: 'KCC01'
    },
    {
        value: 'ADV',
        label: 'Advisment Seminar',
        institution: 'KCC01'
    },
    {
        value: 'ANT',
        label: 'Anthropology',
        institution: 'KCC01'
    },
    {
        value: 'ARB',
        label: 'Arabic',
        institution: 'KCC01'
    },
    {
        value: 'ART',
        label: 'Art',
        institution: 'KCC01'
    },
    {
        value: 'BA',
        label: 'Business Administration',
        institution: 'KCC01'
    },
    {
        value: 'BF',
        label: 'Business of Fashion',
        institution: 'KCC01'
    },
    {
        value: 'BIO',
        label: 'Biology',
        institution: 'KCC01'
    },
    {
        value: 'BSS',
        label: 'Behavioral Social Scis',
        institution: 'KCC01'
    },
    {
        value: 'CA',
        label: 'Culinary Arts',
        institution: 'KCC01'
    },
    {
        value: 'CE',
        label: 'Civil Engineering',
        institution: 'KCC01'
    },
    {
        value: 'CHI',
        label: 'Chinese',
        institution: 'KCC01'
    },
    {
        value: 'CHM',
        label: 'Chemistry',
        institution: 'KCC01'
    },
    {
        value: 'CIS',
        label: 'Computer Information Sys',
        institution: 'KCC01'
    },
    {
        value: 'COH',
        label: 'Community Health',
        institution: 'KCC01'
    },
    {
        value: 'COM',
        label: 'Communication Studies',
        institution: 'KCC01'
    },
    {
        value: 'CP',
        label: 'Computer Programming',
        institution: 'KCC01'
    },
    {
        value: 'CRJ',
        label: 'Criminal Justice',
        institution: 'KCC01'
    },
    {
        value: 'CS',
        label: 'Computer Science',
        institution: 'KCC01'
    },
    {
        value: 'CSM',
        label: 'CUNYStart Math',
        institution: 'KCC01'
    },
    {
        value: 'CSR',
        label: 'CUNYStart Reading',
        institution: 'KCC01'
    },
    {
        value: 'CSW',
        label: 'CUNYStart Writing',
        institution: 'KCC01'
    },
    {
        value: 'ECO',
        label: 'Economics',
        institution: 'KCC01'
    },
    {
        value: 'EDC',
        label: 'Educ Early Childhood',
        institution: 'KCC01'
    },
    {
        value: 'EDU',
        label: 'Education',
        institution: 'KCC01'
    },
    {
        value: 'EGR',
        label: 'Engineering',
        institution: 'KCC01'
    },
    {
        value: 'EMS',
        label: 'Emergency Medical Srvc',
        institution: 'KCC01'
    },
    {
        value: 'ENG',
        label: 'English',
        institution: 'KCC01'
    },
    {
        value: 'EPS',
        label: 'Earth & Planetary Scis',
        institution: 'KCC01'
    },
    {
        value: 'ESL',
        label: 'English as a Second Lang',
        institution: 'KCC01'
    },
    {
        value: 'EXS',
        label: 'Exercise Science',
        institution: 'KCC01'
    },
    {
        value: 'FD',
        label: 'Fashion Design',
        institution: 'KCC01'
    },
    {
        value: 'FR',
        label: 'French',
        institution: 'KCC01'
    },
    {
        value: 'HE',
        label: 'Health Education',
        institution: 'KCC01'
    },
    {
        value: 'HEB',
        label: 'Hebrew',
        institution: 'KCC01'
    },
    {
        value: 'HIS',
        label: 'History',
        institution: 'KCC01'
    },
    {
        value: 'HPE',
        label: 'Health & Physical Edu',
        institution: 'KCC01'
    },
    {
        value: 'HS',
        label: 'Health Science',
        institution: 'KCC01'
    },
    {
        value: 'HUM',
        label: 'Human Services',
        institution: 'KCC01'
    },
    {
        value: 'IT',
        label: 'Italian',
        institution: 'KCC01'
    },
    {
        value: 'JRL',
        label: 'Journalism',
        institution: 'KCC01'
    },
    {
        value: 'MAT',
        label: 'Mathematics',
        institution: 'KCC01'
    },
    {
        value: 'MCB',
        label: 'Mass Communications',
        institution: 'KCC01'
    },
    {
        value: 'MCF',
        label: 'Mass Communications Film',
        institution: 'KCC01'
    },
    {
        value: 'MCM',
        label: 'Mass Comm Media',
        institution: 'KCC01'
    },
    {
        value: 'MH',
        label: 'Mental Health',
        institution: 'KCC01'
    },
    {
        value: 'MT',
        label: 'Maritime Technology',
        institution: 'KCC01'
    },
    {
        value: 'MUS',
        label: 'Music',
        institution: 'KCC01'
    },
    {
        value: 'NUR',
        label: 'Nursing RN',
        institution: 'KCC01'
    },
    {
        value: 'PEC',
        label: 'Physical Education-Coed',
        institution: 'KCC01'
    },
    {
        value: 'PEM',
        label: 'Men',
        institution: 'KCC01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'KCC01'
    },
    {
        value: 'PEW',
        label: 'Physical Edu Women',
        institution: 'KCC01'
    },
    {
        value: 'PHI',
        label: 'Philosophy',
        institution: 'KCC01'
    },
    {
        value: 'PHY',
        label: 'Physics',
        institution: 'KCC01'
    },
    {
        value: 'POL',
        label: 'Political Science',
        institution: 'KCC01'
    },
    {
        value: 'PSG',
        label: 'Polysomnographic Technology',
        institution: 'KCC01'
    },
    {
        value: 'PSY',
        label: 'Psychology',
        institution: 'KCC01'
    },
    {
        value: 'PTA',
        label: 'Physical Therapy Assist',
        institution: 'KCC01'
    },
    {
        value: 'RPE',
        label: 'Recreation & Physical Ed',
        institution: 'KCC01'
    },
    {
        value: 'SAC',
        label: 'Substance Abuse Counsel',
        institution: 'KCC01'
    },
    {
        value: 'SCI',
        label: 'Science',
        institution: 'KCC01'
    },
    {
        value: 'SD',
        label: 'Student Development',
        institution: 'KCC01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'KCC01'
    },
    {
        value: 'SPA',
        label: 'Spanish',
        institution: 'KCC01'
    },
    {
        value: 'SPE',
        label: 'Speech',
        institution: 'KCC01'
    },
    {
        value: 'ST',
        label: 'Surgical Technology',
        institution: 'KCC01'
    },
    {
        value: 'STAB',
        label: 'Study Abroad',
        institution: 'KCC01'
    },
    {
        value: 'STRM',
        label: 'CUNY Start Math',
        institution: 'KCC01'
    },
    {
        value: 'TAH',
        label: 'Tourism and Hospitality',
        institution: 'KCC01'
    },
    {
        value: 'TEC',
        label: 'Office Technology',
        institution: 'KCC01'
    },
    {
        value: 'THA',
        label: 'Theater Arts',
        institution: 'KCC01'
    },
    {
        value: 'UIP',
        label: 'University Imm Prog',
        institution: 'KCC01'
    },
    {
        value: 'ADV',
        label: 'Advisment Seminar',
        institution: 'LAG01'
    },
    {
        value: 'BTA',
        label: 'Accounting',
        institution: 'LAG01'
    },
    {
        value: 'BTC',
        label: 'Computer & CIS',
        institution: 'LAG01'
    },
    {
        value: 'BTF',
        label: 'First Year Seminar',
        institution: 'LAG01'
    },
    {
        value: 'BTI',
        label: 'Business Internship',
        institution: 'LAG01'
    },
    {
        value: 'BTM',
        label: 'Managerial Studies',
        institution: 'LAG01'
    },
    {
        value: 'BTN',
        label: 'Urban Studies',
        institution: 'LAG01'
    },
    {
        value: 'BTO',
        label: 'Office Skills',
        institution: 'LAG01'
    },
    {
        value: 'BTP',
        label: 'Paralegal',
        institution: 'LAG01'
    },
    {
        value: 'BTT',
        label: 'Travel.,Tourism, Hosp',
        institution: 'LAG01'
    },
    {
        value: 'CJF',
        label: 'CJF-First Year Seminar',
        institution: 'LAG01'
    },
    {
        value: 'CSE',
        label: 'Communication Skills',
        institution: 'LAG01'
    },
    {
        value: 'CSF',
        label: 'First Year Seminar',
        institution: 'LAG01'
    },
    {
        value: 'ECF',
        label: 'Educational Foundations',
        institution: 'LAG01'
    },
    {
        value: 'EDF',
        label: 'Educational Foundations',
        institution: 'LAG01'
    },
    {
        value: 'EIS',
        label: 'Language',
        institution: 'LAG01'
    },
    {
        value: 'ELA',
        label: 'ELA/Arabic',
        institution: 'LAG01'
    },
    {
        value: 'ELC',
        label: 'Electrical Technology',
        institution: 'LAG01'
    },
    {
        value: 'ELE',
        label: 'Electrical Engineering',
        institution: 'LAG01'
    },
    {
        value: 'ELF',
        label: 'French',
        institution: 'LAG01'
    },
    {
        value: 'ELI',
        label: 'ELI',
        institution: 'LAG01'
    },
    {
        value: 'ELJ',
        label: 'Japanese',
        institution: 'LAG01'
    },
    {
        value: 'ELK',
        label: 'Korean',
        institution: 'LAG01'
    },
    {
        value: 'ELL',
        label: 'Education & Language',
        institution: 'LAG01'
    },
    {
        value: 'ELM',
        label: 'Sign Language',
        institution: 'LAG01'
    },
    {
        value: 'ELN',
        label: 'Urban Study',
        institution: 'LAG01'
    },
    {
        value: 'ELP',
        label: 'Polish',
        institution: 'LAG01'
    },
    {
        value: 'ELS',
        label: 'Spanish',
        institution: 'LAG01'
    },
    {
        value: 'ELV',
        label: 'Bengali',
        institution: 'LAG01'
    },
    {
        value: 'ELZ',
        label: 'Portuguese',
        institution: 'LAG01'
    },
    {
        value: 'ENA',
        label: 'English',
        institution: 'LAG01'
    },
    {
        value: 'ENG',
        label: 'English',
        institution: 'LAG01'
    },
    {
        value: 'ENN',
        label: 'English',
        institution: 'LAG01'
    },
    {
        value: 'ENX',
        label: 'English',
        institution: 'LAG01'
    },
    {
        value: 'ESA',
        label: 'Emrg Svcs Administration',
        institution: 'LAG01'
    },
    {
        value: 'ESC',
        label: 'Engineering Science',
        institution: 'LAG01'
    },
    {
        value: 'ESL',
        label: 'English as a Second Lang',
        institution: 'LAG01'
    },
    {
        value: 'FSE',
        label: 'Freshman Seminar',
        institution: 'LAG01'
    },
    {
        value: 'FSG',
        label: 'FSG',
        institution: 'LAG01'
    },
    {
        value: 'FSM',
        label: 'New Student Sem',
        institution: 'LAG01'
    },
    {
        value: 'GRD',
        label: 'Intent to Grad',
        institution: 'LAG01'
    },
    {
        value: 'HSF',
        label: 'Health Science',
        institution: 'LAG01'
    },
    {
        value: 'HSS',
        label: 'Honors: Hum/Soc Sci',
        institution: 'LAG01'
    },
    {
        value: 'HTR',
        label: 'Therapeutic Recreation',
        institution: 'LAG01'
    },
    {
        value: 'HUA',
        label: 'Humanities/Art',
        institution: 'LAG01'
    },
    {
        value: 'HUC',
        label: 'Communication Arts',
        institution: 'LAG01'
    },
    {
        value: 'HUM',
        label: 'Human Services',
        institution: 'LAG01'
    },
    {
        value: 'HUN',
        label: 'Interdisciplinary',
        institution: 'LAG01'
    },
    {
        value: 'HUP',
        label: 'Humanities/Philosophy',
        institution: 'LAG01'
    },
    {
        value: 'HUT',
        label: 'Theatre',
        institution: 'LAG01'
    },
    {
        value: 'HUW',
        label: 'Humanities/New Media',
        institution: 'LAG01'
    },
    {
        value: 'HUX',
        label: 'Music Recording',
        institution: 'LAG01'
    },
    {
        value: 'LBN',
        label: 'Info Citizen Info Age',
        institution: 'LAG01'
    },
    {
        value: 'LIB',
        label: 'Library',
        institution: 'LAG01'
    },
    {
        value: 'LIF',
        label: 'First Year Sem Lib Arts',
        institution: 'LAG01'
    },
    {
        value: 'LMF',
        label: 'LMF-First Year Seminar',
        institution: 'LAG01'
    },
    {
        value: 'LPN',
        label: 'LPN',
        institution: 'LAG01'
    },
    {
        value: 'LRC',
        label: 'LRC',
        institution: 'LAG01'
    },
    {
        value: 'LRN',
        label: 'LRN',
        institution: 'LAG01'
    },
    {
        value: 'MAC',
        label: 'Math, Engg & Comp Sci',
        institution: 'LAG01'
    },
    {
        value: 'MAE',
        label: 'Math, Engg & Comp Sci',
        institution: 'LAG01'
    },
    {
        value: 'MAT',
        label: 'Mathematics',
        institution: 'LAG01'
    },
    {
        value: 'MLA',
        label: 'Medical Leave',
        institution: 'LAG01'
    },
    {
        value: 'MRF',
        label: 'First Year Seminar',
        institution: 'LAG01'
    },
    {
        value: 'NSF',
        label: 'First Year Seminar',
        institution: 'LAG01'
    },
    {
        value: 'NUR',
        label: 'Nursing RN',
        institution: 'LAG01'
    },
    {
        value: 'OTA',
        label: 'OTA',
        institution: 'LAG01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'LAG01'
    },
    {
        value: 'PTA',
        label: 'Physical Therapy Assist',
        institution: 'LAG01'
    },
    {
        value: 'RAD',
        label: 'Radiologic Technology',
        institution: 'LAG01'
    },
    {
        value: 'REG',
        label: 'REG',
        institution: 'LAG01'
    },
    {
        value: 'SCB',
        label: 'Biology',
        institution: 'LAG01'
    },
    {
        value: 'SCC',
        label: 'Chemistry',
        institution: 'LAG01'
    },
    {
        value: 'SCD',
        label: 'Dietetics Food Services',
        institution: 'LAG01'
    },
    {
        value: 'SCE',
        label: 'Paramedic EMT',
        institution: 'LAG01'
    },
    {
        value: 'SCG',
        label: 'Environmental Science',
        institution: 'LAG01'
    },
    {
        value: 'SCH',
        label: 'Interdisciplinary',
        institution: 'LAG01'
    },
    {
        value: 'SCI',
        label: 'Science',
        institution: 'LAG01'
    },
    {
        value: 'SCL',
        label: 'Nursing',
        institution: 'LAG01'
    },
    {
        value: 'SCN',
        label: 'Urban Studies',
        institution: 'LAG01'
    },
    {
        value: 'SCO',
        label: 'Occupational Therapy',
        institution: 'LAG01'
    },
    {
        value: 'SCP',
        label: 'Physical Science',
        institution: 'LAG01'
    },
    {
        value: 'SCR',
        label: 'Nursing',
        institution: 'LAG01'
    },
    {
        value: 'SCT',
        label: 'Physical Therapy',
        institution: 'LAG01'
    },
    {
        value: 'SCV',
        label: 'Vet Tech',
        institution: 'LAG01'
    },
    {
        value: 'SCX',
        label: 'Radiologic Technology',
        institution: 'LAG01'
    },
    {
        value: 'SSA',
        label: 'Anthropology',
        institution: 'LAG01'
    },
    {
        value: 'SSE',
        label: 'Social Science/Economics',
        institution: 'LAG01'
    },
    {
        value: 'SSH',
        label: 'Social Science/History',
        institution: 'LAG01'
    },
    {
        value: 'SSI',
        label: 'Interdisciplinary',
        institution: 'LAG01'
    },
    {
        value: 'SSJ',
        label: 'Criminal Justice',
        institution: 'LAG01'
    },
    {
        value: 'SSN',
        label: 'Urban Studies',
        institution: 'LAG01'
    },
    {
        value: 'SSP',
        label: 'Political Science',
        institution: 'LAG01'
    },
    {
        value: 'SSS',
        label: 'Social Science/Sociology',
        institution: 'LAG01'
    },
    {
        value: 'SSY',
        label: 'Psychology',
        institution: 'LAG01'
    },
    {
        value: 'SYF',
        label: 'SYF-First Year Seminar',
        institution: 'LAG01'
    },
    {
        value: 'USE',
        label: 'USE ESL',
        institution: 'LAG01'
    },
    {
        value: 'USM',
        label: 'USM / Math',
        institution: 'LAG01'
    },
    {
        value: 'USW',
        label: 'USW Writing',
        institution: 'LAG01'
    },
    {
        value: 'LAW',
        label: 'Law',
        institution: 'LAW01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'LAW01'
    },
    {
        value: 'AAS',
        label: 'Asian American Studies',
        institution: 'LEH01'
    },
    {
        value: 'ACC',
        label: 'Accounting',
        institution: 'LEH01'
    },
    {
        value: 'AMS',
        label: 'American Studies',
        institution: 'LEH01'
    },
    {
        value: 'ANT',
        label: 'Anthropology',
        institution: 'LEH01'
    },
    {
        value: 'ARB',
        label: 'Arabic',
        institution: 'LEH01'
    },
    {
        value: 'ARH',
        label: 'Art History',
        institution: 'LEH01'
    },
    {
        value: 'ART',
        label: 'Art',
        institution: 'LEH01'
    },
    {
        value: 'AST',
        label: 'Astronomy',
        institution: 'LEH01'
    },
    {
        value: 'BBA',
        label: 'Business Administration',
        institution: 'LEH01'
    },
    {
        value: 'BIO',
        label: 'Biology',
        institution: 'LEH01'
    },
    {
        value: 'CED',
        label: 'Cooperative Education',
        institution: 'LEH01'
    },
    {
        value: 'CGI',
        label: 'Computer Graphic Imaging',
        institution: 'LEH01'
    },
    {
        value: 'CHE',
        label: 'Chemistry',
        institution: 'LEH01'
    },
    {
        value: 'CHI',
        label: 'Chinese',
        institution: 'LEH01'
    },
    {
        value: 'CIS',
        label: 'Computer Information Sys',
        institution: 'LEH01'
    },
    {
        value: 'CMP',
        label: 'Comparative Literature',
        institution: 'LEH01'
    },
    {
        value: 'DEC',
        label: 'Childhood Education',
        institution: 'LEH01'
    },
    {
        value: 'DFN',
        label: 'Dietetics, Food & Nut',
        institution: 'LEH01'
    },
    {
        value: 'DNC',
        label: 'Dance',
        institution: 'LEH01'
    },
    {
        value: 'DST',
        label: 'Disability Studies',
        institution: 'LEH01'
    },
    {
        value: 'EBS',
        label: 'Grad',
        institution: 'LEH01'
    },
    {
        value: 'ECE',
        label: 'Early Childhood Educ',
        institution: 'LEH01'
    },
    {
        value: 'ECO',
        label: 'Economics',
        institution: 'LEH01'
    },
    {
        value: 'EDC',
        label: 'Educ Early Childhood',
        institution: 'LEH01'
    },
    {
        value: 'EDE',
        label: 'Education: Elementary',
        institution: 'LEH01'
    },
    {
        value: 'EDG',
        label: 'Counselor Education',
        institution: 'LEH01'
    },
    {
        value: 'EDL',
        label: 'Education TESOL',
        institution: 'LEH01'
    },
    {
        value: 'EDR',
        label: 'Reading',
        institution: 'LEH01'
    },
    {
        value: 'EDS',
        label: 'Secondary Education',
        institution: 'LEH01'
    },
    {
        value: 'ENG',
        label: 'English',
        institution: 'LEH01'
    },
    {
        value: 'ENV',
        label: 'Environmental Studies',
        institution: 'LEH01'
    },
    {
        value: 'ENW',
        label: 'English Writing',
        institution: 'LEH01'
    },
    {
        value: 'ESC',
        label: 'Engineering Science',
        institution: 'LEH01'
    },
    {
        value: 'ESL',
        label: 'English as a Second Lang',
        institution: 'LEH01'
    },
    {
        value: 'EXS',
        label: 'Exercise Science',
        institution: 'LEH01'
    },
    {
        value: 'FRE',
        label: 'French',
        institution: 'LEH01'
    },
    {
        value: 'FTS',
        label: 'Film & Television Studie',
        institution: 'LEH01'
    },
    {
        value: 'GEH',
        label: 'Geography',
        institution: 'LEH01'
    },
    {
        value: 'GEO',
        label: 'Geography',
        institution: 'LEH01'
    },
    {
        value: 'GEP',
        label: 'Physical Geography',
        institution: 'LEH01'
    },
    {
        value: 'HEA',
        label: 'Health Education',
        institution: 'LEH01'
    },
    {
        value: 'HIA',
        label: 'Ancient',
        institution: 'LEH01'
    },
    {
        value: 'HIE',
        label: 'Modern European',
        institution: 'LEH01'
    },
    {
        value: 'HIN',
        label: 'History of',
        institution: 'LEH01'
    },
    {
        value: 'HIS',
        label: 'History',
        institution: 'LEH01'
    },
    {
        value: 'HIU',
        label: 'United States',
        institution: 'LEH01'
    },
    {
        value: 'HIW',
        label: 'World',
        institution: 'LEH01'
    },
    {
        value: 'HPI',
        label: 'Health Prof Institute',
        institution: 'LEH01'
    },
    {
        value: 'HSA',
        label: 'Health services Admin',
        institution: 'LEH01'
    },
    {
        value: 'HSD',
        label: 'Health Sci Core Courses',
        institution: 'LEH01'
    },
    {
        value: 'HUM',
        label: 'Human Services',
        institution: 'LEH01'
    },
    {
        value: 'IBA',
        label: 'Individual BA Prog',
        institution: 'LEH01'
    },
    {
        value: 'IDW',
        label: 'World Classics',
        institution: 'LEH01'
    },
    {
        value: 'IRI',
        label: 'Irish',
        institution: 'LEH01'
    },
    {
        value: 'ITA',
        label: 'Italian',
        institution: 'LEH01'
    },
    {
        value: 'JAL',
        label: 'Japanese',
        institution: 'LEH01'
    },
    {
        value: 'JRN',
        label: 'Journalism',
        institution: 'LEH01'
    },
    {
        value: 'LAC',
        label: 'Latin & Caribbean Stds',
        institution: 'LEH01'
    },
    {
        value: 'LEH',
        label: 'General Studies',
        institution: 'LEH01'
    },
    {
        value: 'LNG',
        label: 'Language',
        institution: 'LEH01'
    },
    {
        value: 'LSP',
        label: 'Lehman Scholars Program',
        institution: 'LEH01'
    },
    {
        value: 'LTS',
        label: 'Latino Studies',
        institution: 'LEH01'
    },
    {
        value: 'MAM',
        label: 'Maintain Matriculation',
        institution: 'LEH01'
    },
    {
        value: 'MAT',
        label: 'Mathematics',
        institution: 'LEH01'
    },
    {
        value: 'MCS',
        label: 'Media Communications Std',
        institution: 'LEH01'
    },
    {
        value: 'MES',
        label: 'Media Studies',
        institution: 'LEH01'
    },
    {
        value: 'MHC',
        label: 'Macaulay Honors College',
        institution: 'LEH01'
    },
    {
        value: 'MLS',
        label: 'Medical Lab Science',
        institution: 'LEH01'
    },
    {
        value: 'MSB',
        label: 'MS Business',
        institution: 'LEH01'
    },
    {
        value: 'MSH',
        label: 'Music History',
        institution: 'LEH01'
    },
    {
        value: 'MSP',
        label: 'Music Performance',
        institution: 'LEH01'
    },
    {
        value: 'MST',
        label: 'Music Theory',
        institution: 'LEH01'
    },
    {
        value: 'NSS',
        label: 'New Student Seminar',
        institution: 'LEH01'
    },
    {
        value: 'NUR',
        label: 'Nursing RN',
        institution: 'LEH01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'LEH01'
    },
    {
        value: 'PHE',
        label: 'Public Health',
        institution: 'LEH01'
    },
    {
        value: 'PHI',
        label: 'Philosophy',
        institution: 'LEH01'
    },
    {
        value: 'PHY',
        label: 'Physics',
        institution: 'LEH01'
    },
    {
        value: 'POL',
        label: 'Political Science',
        institution: 'LEH01'
    },
    {
        value: 'PSY',
        label: 'Psychology',
        institution: 'LEH01'
    },
    {
        value: 'REC',
        label: 'Therapeutic Recreation',
        institution: 'LEH01'
    },
    {
        value: 'REH',
        label: 'Rehabilitation',
        institution: 'LEH01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'LEH01'
    },
    {
        value: 'SPA',
        label: 'Spanish',
        institution: 'LEH01'
    },
    {
        value: 'SPE',
        label: 'Speech',
        institution: 'LEH01'
    },
    {
        value: 'SPS',
        label: 'Student Personnel Srvcs',
        institution: 'LEH01'
    },
    {
        value: 'SPV',
        label: 'Speech & Voice',
        institution: 'LEH01'
    },
    {
        value: 'STABD',
        label: 'Study Abroad',
        institution: 'LEH01'
    },
    {
        value: 'SWK',
        label: 'Social Work',
        institution: 'LEH01'
    },
    {
        value: 'THE',
        label: 'Theatre',
        institution: 'LEH01'
    },
    {
        value: 'WFL',
        label: 'World Foreign Languages',
        institution: 'LEH01'
    },
    {
        value: 'WST',
        label: 'Women\'s Studies',
        institution: 'LEH01'
    },
    {
        value: 'ACCT',
        label: 'Accounting',
        institution: 'MEC01'
    },
    {
        value: 'AGRO',
        label: 'Counseling',
        institution: 'MEC01'
    },
    {
        value: 'ANTH',
        label: 'Anthropology',
        institution: 'MEC01'
    },
    {
        value: 'ARAB',
        label: 'Arabic',
        institution: 'MEC01'
    },
    {
        value: 'ARAL',
        label: 'Arabic Language Lab',
        institution: 'MEC01'
    },
    {
        value: 'ART',
        label: 'Art',
        institution: 'MEC01'
    },
    {
        value: 'AST',
        label: 'Astronomy',
        institution: 'MEC01'
    },
    {
        value: 'ASTL',
        label: 'Astronomy Lab',
        institution: 'MEC01'
    },
    {
        value: 'BIO',
        label: 'Biology',
        institution: 'MEC01'
    },
    {
        value: 'BIOL',
        label: 'Biology',
        institution: 'MEC01'
    },
    {
        value: 'BUS',
        label: 'Business Administration',
        institution: 'MEC01'
    },
    {
        value: 'CHIL',
        label: 'Chinese Lab',
        institution: 'MEC01'
    },
    {
        value: 'CHIN',
        label: 'Chinese',
        institution: 'MEC01'
    },
    {
        value: 'CHM',
        label: 'Chemistry',
        institution: 'MEC01'
    },
    {
        value: 'CHML',
        label: 'Chemistry Lab',
        institution: 'MEC01'
    },
    {
        value: 'CIS',
        label: 'Computer Information Sys',
        institution: 'MEC01'
    },
    {
        value: 'CS',
        label: 'Computer Science',
        institution: 'MEC01'
    },
    {
        value: 'DNCE',
        label: 'Dance',
        institution: 'MEC01'
    },
    {
        value: 'ECON',
        label: 'Economics',
        institution: 'MEC01'
    },
    {
        value: 'EDUC',
        label: 'Education',
        institution: 'MEC01'
    },
    {
        value: 'ENGL',
        label: 'English',
        institution: 'MEC01'
    },
    {
        value: 'ENGR',
        label: 'Engineering',
        institution: 'MEC01'
    },
    {
        value: 'ENGW',
        label: 'English Writing',
        institution: 'MEC01'
    },
    {
        value: 'ENVS',
        label: 'Environmental Science',
        institution: 'MEC01'
    },
    {
        value: 'FIN',
        label: 'Finance',
        institution: 'MEC01'
    },
    {
        value: 'FREL',
        label: 'French Lab',
        institution: 'MEC01'
    },
    {
        value: 'FREN',
        label: 'French',
        institution: 'MEC01'
    },
    {
        value: 'FS',
        label: 'Food Studies',
        institution: 'MEC01'
    },
    {
        value: 'GEOG',
        label: 'Geography',
        institution: 'MEC01'
    },
    {
        value: 'HACR',
        label: 'Haitian Creole',
        institution: 'MEC01'
    },
    {
        value: 'HIST',
        label: 'History',
        institution: 'MEC01'
    },
    {
        value: 'HSA',
        label: 'Health services Admin',
        institution: 'MEC01'
    },
    {
        value: 'HSC',
        label: 'Human Services',
        institution: 'MEC01'
    },
    {
        value: 'HUM',
        label: 'Human Services',
        institution: 'MEC01'
    },
    {
        value: 'LAW',
        label: 'Law',
        institution: 'MEC01'
    },
    {
        value: 'LIB',
        label: 'Library',
        institution: 'MEC01'
    },
    {
        value: 'MAN',
        label: 'Management',
        institution: 'MEC01'
    },
    {
        value: 'MAR',
        label: 'Marketing',
        institution: 'MEC01'
    },
    {
        value: 'MASS',
        label: 'Mass Communications',
        institution: 'MEC01'
    },
    {
        value: 'MED',
        label: 'Biomedical Education',
        institution: 'MEC01'
    },
    {
        value: 'MTH',
        label: 'Mathematics',
        institution: 'MEC01'
    },
    {
        value: 'MUS',
        label: 'Music',
        institution: 'MEC01'
    },
    {
        value: 'NUR',
        label: 'Nursing RN',
        institution: 'MEC01'
    },
    {
        value: 'NURC',
        label: 'Nursing Clinical',
        institution: 'MEC01'
    },
    {
        value: 'NURL',
        label: 'Nursing Lab',
        institution: 'MEC01'
    },
    {
        value: 'NURS',
        label: 'Nursing',
        institution: 'MEC01'
    },
    {
        value: 'PA',
        label: 'Public Administration',
        institution: 'MEC01'
    },
    {
        value: 'PERF',
        label: 'Performing Arts',
        institution: 'MEC01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'MEC01'
    },
    {
        value: 'PHIL',
        label: 'Philosophy',
        institution: 'MEC01'
    },
    {
        value: 'PHS',
        label: 'Pharmaceutical Science',
        institution: 'MEC01'
    },
    {
        value: 'PHY',
        label: 'Physics',
        institution: 'MEC01'
    },
    {
        value: 'PHYL',
        label: 'Physics Lab',
        institution: 'MEC01'
    },
    {
        value: 'PHYW',
        label: 'Physics Workshop',
        institution: 'MEC01'
    },
    {
        value: 'POL',
        label: 'Political Science',
        institution: 'MEC01'
    },
    {
        value: 'PORL',
        label: 'Portuguese Language Lab',
        institution: 'MEC01'
    },
    {
        value: 'PORT',
        label: 'Portuguese',
        institution: 'MEC01'
    },
    {
        value: 'PSYC',
        label: 'Psychology',
        institution: 'MEC01'
    },
    {
        value: 'REL',
        label: 'Religion',
        institution: 'MEC01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'MEC01'
    },
    {
        value: 'SPAE',
        label: 'Acad Enhancement (SEEK)',
        institution: 'MEC01'
    },
    {
        value: 'SPAL',
        label: 'Spanish Lab',
        institution: 'MEC01'
    },
    {
        value: 'SPAN',
        label: 'Spanish',
        institution: 'MEC01'
    },
    {
        value: 'SP',
        label: 'Speech Communication',
        institution: 'MEC01'
    },
    {
        value: 'SPCH',
        label: 'Speech',
        institution: 'MEC01'
    },
    {
        value: 'SPTH',
        label: 'Speech, Theater',
        institution: 'MEC01'
    },
    {
        value: 'SSC',
        label: 'Social Science',
        institution: 'MEC01'
    },
    {
        value: 'STABD',
        label: 'Study Abroad',
        institution: 'MEC01'
    },
    {
        value: 'SW',
        label: 'Social Work',
        institution: 'MEC01'
    },
    {
        value: 'MAM',
        label: 'Maintain Matriculation',
        institution: 'MED01'
    },
    {
        value: 'MED',
        label: 'Biomedical Education',
        institution: 'MED01'
    },
    {
        value: 'PA',
        label: 'Public Administration',
        institution: 'MED01'
    },
    {
        value: 'MHC',
        label: 'Macaulay Honors College',
        institution: 'MHC01'
    },
    {
        value: 'ACCT',
        label: 'Accounting',
        institution: 'NCC01'
    },
    {
        value: 'ANTH',
        label: 'Anthropology',
        institution: 'NCC01'
    },
    {
        value: 'BIOL',
        label: 'Biology',
        institution: 'NCC01'
    },
    {
        value: 'BUSI',
        label: 'Business',
        institution: 'NCC01'
    },
    {
        value: 'CHEM',
        label: 'Chemistry',
        institution: 'NCC01'
    },
    {
        value: 'COMM',
        label: 'Communications',
        institution: 'NCC01'
    },
    {
        value: 'CSM',
        label: 'CUNYStart Math',
        institution: 'NCC01'
    },
    {
        value: 'ECON',
        label: 'Economics',
        institution: 'NCC01'
    },
    {
        value: 'ELEC',
        label: 'Elective Credit',
        institution: 'NCC01'
    },
    {
        value: 'ENGL',
        label: 'English',
        institution: 'NCC01'
    },
    {
        value: 'FYS',
        label: 'First Year Seminar',
        institution: 'NCC01'
    },
    {
        value: 'GOVT',
        label: 'Government',
        institution: 'NCC01'
    },
    {
        value: 'HIST',
        label: 'History',
        institution: 'NCC01'
    },
    {
        value: 'HSVC',
        label: 'Human Services',
        institution: 'NCC01'
    },
    {
        value: 'INFT',
        label: 'Information Technology',
        institution: 'NCC01'
    },
    {
        value: 'LASC',
        label: 'Liberal Arts & Sciences',
        institution: 'NCC01'
    },
    {
        value: 'MATH',
        label: 'Mathematics',
        institution: 'NCC01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'NCC01'
    },
    {
        value: 'PHIL',
        label: 'Philosophy',
        institution: 'NCC01'
    },
    {
        value: 'PSYC',
        label: 'Psychology',
        institution: 'NCC01'
    },
    {
        value: 'SCI',
        label: 'Science',
        institution: 'NCC01'
    },
    {
        value: 'SOCI',
        label: 'Sociology',
        institution: 'NCC01'
    },
    {
        value: 'SOSC',
        label: 'Social Sciences',
        institution: 'NCC01'
    },
    {
        value: 'UBST',
        label: 'Urban Studies',
        institution: 'NCC01'
    },
    {
        value: 'AAA',
        label: 'Academic Access',
        institution: 'NYT01'
    },
    {
        value: 'ACC',
        label: 'Accounting',
        institution: 'NYT01'
    },
    {
        value: 'AFR',
        label: 'Africana Studies',
        institution: 'NYT01'
    },
    {
        value: 'ANTH',
        label: 'Anthropology',
        institution: 'NYT01'
    },
    {
        value: 'ARB',
        label: 'Arabic',
        institution: 'NYT01'
    },
    {
        value: 'ARCH',
        label: 'Architecture',
        institution: 'NYT01'
    },
    {
        value: 'ARTH',
        label: 'Art Theory & History',
        institution: 'NYT01'
    },
    {
        value: 'ASL',
        label: 'American Sign Language',
        institution: 'NYT01'
    },
    {
        value: 'BIO',
        label: 'Biology',
        institution: 'NYT01'
    },
    {
        value: 'BUF',
        label: 'Business Merchandising',
        institution: 'NYT01'
    },
    {
        value: 'BUS',
        label: 'Business Administration',
        institution: 'NYT01'
    },
    {
        value: 'CET',
        label: 'Comp Engineering Tech',
        institution: 'NYT01'
    },
    {
        value: 'CHEM',
        label: 'Chemistry',
        institution: 'NYT01'
    },
    {
        value: 'CHN',
        label: 'Chinese',
        institution: 'NYT01'
    },
    {
        value: 'CMCE',
        label: 'Construction Tech',
        institution: 'NYT01'
    },
    {
        value: 'COM',
        label: 'Communication Studies',
        institution: 'NYT01'
    },
    {
        value: 'COMD',
        label: 'Communication Design',
        institution: 'NYT01'
    },
    {
        value: 'CST',
        label: 'CUNY Start',
        institution: 'NYT01'
    },
    {
        value: 'DEN',
        label: 'Dental Hygiene',
        institution: 'NYT01'
    },
    {
        value: 'ECON',
        label: 'Economics',
        institution: 'NYT01'
    },
    {
        value: 'EDU',
        label: 'Education',
        institution: 'NYT01'
    },
    {
        value: 'EET',
        label: 'Electrical Eng Tech',
        institution: 'NYT01'
    },
    {
        value: 'EMT',
        label: 'Electro-Mech Eng Tech',
        institution: 'NYT01'
    },
    {
        value: 'ENG',
        label: 'English',
        institution: 'NYT01'
    },
    {
        value: 'ENT',
        label: 'Entertainment Technology',
        institution: 'NYT01'
    },
    {
        value: 'ENVC',
        label: 'Environ Control Tech',
        institution: 'NYT01'
    },
    {
        value: 'ESCI',
        label: 'Environmental Science',
        institution: 'NYT01'
    },
    {
        value: 'ESOL',
        label: 'Col Eng as a Second',
        institution: 'NYT01'
    },
    {
        value: 'FMGT',
        label: 'Facilities Management',
        institution: 'NYT01'
    },
    {
        value: 'FREN',
        label: 'French',
        institution: 'NYT01'
    },
    {
        value: 'GEOG',
        label: 'Geography',
        institution: 'NYT01'
    },
    {
        value: 'GOV',
        label: 'Government',
        institution: 'NYT01'
    },
    {
        value: 'HEA',
        label: 'Health Education',
        institution: 'NYT01'
    },
    {
        value: 'HIS',
        label: 'History',
        institution: 'NYT01'
    },
    {
        value: 'HMGT',
        label: 'Hospitality Management',
        institution: 'NYT01'
    },
    {
        value: 'HSA',
        label: 'Health services Admin',
        institution: 'NYT01'
    },
    {
        value: 'HSCI',
        label: 'Health Science',
        institution: 'NYT01'
    },
    {
        value: 'HUS',
        label: 'Human Services',
        institution: 'NYT01'
    },
    {
        value: 'IND',
        label: 'Independent Study',
        institution: 'NYT01'
    },
    {
        value: 'IS',
        label: 'Independent Study',
        institution: 'NYT01'
    },
    {
        value: 'LATS',
        label: 'Latin American Studies',
        institution: 'NYT01'
    },
    {
        value: 'LAW',
        label: 'Law',
        institution: 'NYT01'
    },
    {
        value: 'LIB',
        label: 'Library',
        institution: 'NYT01'
    },
    {
        value: 'LNG',
        label: 'Language',
        institution: 'NYT01'
    },
    {
        value: 'MAT',
        label: 'Mathematics',
        institution: 'NYT01'
    },
    {
        value: 'MECH',
        label: 'Mechanical Eng Tech',
        institution: 'NYT01'
    },
    {
        value: 'MED',
        label: 'Biomedical Education',
        institution: 'NYT01'
    },
    {
        value: 'MEDU',
        label: 'Mathematics Education',
        institution: 'NYT01'
    },
    {
        value: 'MKT',
        label: 'Marketing',
        institution: 'NYT01'
    },
    {
        value: 'MM',
        label: 'Maint of Matric',
        institution: 'NYT01'
    },
    {
        value: 'MTEC',
        label: 'Emerging Media Tech',
        institution: 'NYT01'
    },
    {
        value: 'MUS',
        label: 'Music',
        institution: 'NYT01'
    },
    {
        value: 'NUR',
        label: 'Nursing RN',
        institution: 'NYT01'
    },
    {
        value: 'ONFIL',
        label: 'On File',
        institution: 'NYT01'
    },
    {
        value: 'PERF',
        label: 'Performing Arts',
        institution: 'NYT01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'NYT01'
    },
    {
        value: 'PHIL',
        label: 'Philosophy',
        institution: 'NYT01'
    },
    {
        value: 'PHYS',
        label: 'Physics',
        institution: 'NYT01'
    },
    {
        value: 'PSY',
        label: 'Psychology',
        institution: 'NYT01'
    },
    {
        value: 'RAD',
        label: 'Radiologic Technology',
        institution: 'NYT01'
    },
    {
        value: 'RESD',
        label: 'Dental Laboratory Tech',
        institution: 'NYT01'
    },
    {
        value: 'SBS',
        label: 'Social & Behavioral Sci',
        institution: 'NYT01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'NYT01'
    },
    {
        value: 'SPA',
        label: 'Spanish',
        institution: 'NYT01'
    },
    {
        value: 'TCET',
        label: 'Telecommunications',
        institution: 'NYT01'
    },
    {
        value: 'THE',
        label: 'Theatre',
        institution: 'NYT01'
    },
    {
        value: 'VCT',
        label: 'Ophthalmic Dispensing',
        institution: 'NYT01'
    },
    {
        value: 'WKSHP',
        label: 'Educational Workshop',
        institution: 'NYT01'
    },
    {
        value: 'ANTH',
        label: 'Anthropology',
        institution: 'QCC01'
    },
    {
        value: 'ARCH',
        label: 'Architecture',
        institution: 'QCC01'
    },
    {
        value: 'ARTH',
        label: 'Art Theory & History',
        institution: 'QCC01'
    },
    {
        value: 'ARTS',
        label: 'Studio Art',
        institution: 'QCC01'
    },
    {
        value: 'BE',
        label: 'Basic Education Skills',
        institution: 'QCC01'
    },
    {
        value: 'BI',
        label: 'Biology',
        institution: 'QCC01'
    },
    {
        value: 'BU',
        label: 'Business',
        institution: 'QCC01'
    },
    {
        value: 'CH',
        label: 'Chemistry',
        institution: 'QCC01'
    },
    {
        value: 'CIS',
        label: 'Computer Information Sys',
        institution: 'QCC01'
    },
    {
        value: 'CN',
        label: 'College Now',
        institution: 'QCC01'
    },
    {
        value: 'CRIM',
        label: 'Criminal Justice',
        institution: 'QCC01'
    },
    {
        value: 'CS',
        label: 'Computer Science',
        institution: 'QCC01'
    },
    {
        value: 'CST',
        label: 'CUNY Start',
        institution: 'QCC01'
    },
    {
        value: 'DAN',
        label: 'Dance',
        institution: 'QCC01'
    },
    {
        value: 'ECON',
        label: 'Economics',
        institution: 'QCC01'
    },
    {
        value: 'EDUC',
        label: 'Education',
        institution: 'QCC01'
    },
    {
        value: 'EE',
        label: 'Electrical Engineering',
        institution: 'QCC01'
    },
    {
        value: 'ENGL',
        label: 'English',
        institution: 'QCC01'
    },
    {
        value: 'ET',
        label: 'Elect & Computer Eng Tech',
        institution: 'QCC01'
    },
    {
        value: 'FMP',
        label: 'FIlm Media Production',
        institution: 'QCC01'
    },
    {
        value: 'GE',
        label: 'Geology',
        institution: 'QCC01'
    },
    {
        value: 'HE',
        label: 'Health Education',
        institution: 'QCC01'
    },
    {
        value: 'HIST',
        label: 'History',
        institution: 'QCC01'
    },
    {
        value: 'IS',
        label: 'Independent Study',
        institution: 'QCC01'
    },
    {
        value: 'LS',
        label: 'Spanish',
        institution: 'QCC01'
    },
    {
        value: 'MA',
        label: 'Math Workshop',
        institution: 'QCC01'
    },
    {
        value: 'MT',
        label: 'Maritime Technology',
        institution: 'QCC01'
    },
    {
        value: 'MUS',
        label: 'Music',
        institution: 'QCC01'
    },
    {
        value: 'NU',
        label: 'Nursing',
        institution: 'QCC01'
    },
    {
        value: 'PE',
        label: 'Physical Edu & Dance',
        institution: 'QCC01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'QCC01'
    },
    {
        value: 'PH',
        label: 'Physics',
        institution: 'QCC01'
    },
    {
        value: 'PHIL',
        label: 'Philosophy',
        institution: 'QCC01'
    },
    {
        value: 'PLSC',
        label: 'Political Science',
        institution: 'QCC01'
    },
    {
        value: 'PSYC',
        label: 'Psychology',
        institution: 'QCC01'
    },
    {
        value: 'SOCY',
        label: 'Sociology',
        institution: 'QCC01'
    },
    {
        value: 'SP',
        label: 'Speech Communication',
        institution: 'QCC01'
    },
    {
        value: 'ST',
        label: 'Surgical Technology',
        institution: 'QCC01'
    },
    {
        value: 'TH',
        label: 'Theatre',
        institution: 'QCC01'
    },
    {
        value: 'UBST',
        label: 'Urban Studies',
        institution: 'QCC01'
    },
    {
        value: 'ACCT',
        label: 'Accounting',
        institution: 'QNS01'
    },
    {
        value: 'AFST',
        label: 'Africana Studies',
        institution: 'QNS01'
    },
    {
        value: 'AMST',
        label: 'American Studies',
        institution: 'QNS01'
    },
    {
        value: 'ANTH',
        label: 'Anthropology',
        institution: 'QNS01'
    },
    {
        value: 'ARAB',
        label: 'Arabic',
        institution: 'QNS01'
    },
    {
        value: 'ARTH',
        label: 'Art Theory & History',
        institution: 'QNS01'
    },
    {
        value: 'ARTS',
        label: 'Studio Art',
        institution: 'QNS01'
    },
    {
        value: 'ASTR',
        label: 'Astronomy',
        institution: 'QNS01'
    },
    {
        value: 'BALA',
        label: 'Business & Liberal Arts',
        institution: 'QNS01'
    },
    {
        value: 'BIOCH',
        label: 'Biochemistry',
        institution: 'QNS01'
    },
    {
        value: 'BIOL',
        label: 'Biology',
        institution: 'QNS01'
    },
    {
        value: 'BUS',
        label: 'Business Administration',
        institution: 'QNS01'
    },
    {
        value: 'CERT',
        label: 'Certified Full-Time',
        institution: 'QNS01'
    },
    {
        value: 'CHEM',
        label: 'Chemistry',
        institution: 'QNS01'
    },
    {
        value: 'CHIN',
        label: 'Chinese',
        institution: 'QNS01'
    },
    {
        value: 'CLAS',
        label: 'Classics',
        institution: 'QNS01'
    },
    {
        value: 'CLUBS',
        label: 'Clubs',
        institution: 'QNS01'
    },
    {
        value: 'CMLIT',
        label: 'Comparative Literature',
        institution: 'QNS01'
    },
    {
        value: 'CO',
        label: 'Cooperative Education',
        institution: 'QNS01'
    },
    {
        value: 'CSCI',
        label: 'Computer Sci',
        institution: 'QNS01'
    },
    {
        value: 'CUNBA',
        label: 'CUNY BA',
        institution: 'QNS01'
    },
    {
        value: 'DANCE',
        label: 'Dance',
        institution: 'QNS01'
    },
    {
        value: 'DATA',
        label: 'Data Analysis',
        institution: 'QNS01'
    },
    {
        value: 'DRAM',
        label: 'Drama and Theatre',
        institution: 'QNS01'
    },
    {
        value: 'ECON',
        label: 'Economics',
        institution: 'QNS01'
    },
    {
        value: 'ECP',
        label: 'Edual & Community Progs',
        institution: 'QNS01'
    },
    {
        value: 'ECPCE',
        label: 'Counselor Education',
        institution: 'QNS01'
    },
    {
        value: 'ECPEL',
        label: 'Educ Comm Prog-Leader',
        institution: 'QNS01'
    },
    {
        value: 'ECPSE',
        label: 'Special Education',
        institution: 'QNS01'
    },
    {
        value: 'ECPSP',
        label: 'School Psychology',
        institution: 'QNS01'
    },
    {
        value: 'EECE',
        label: 'Elem & Early Childhood',
        institution: 'QNS01'
    },
    {
        value: 'ELI',
        label: 'ELI',
        institution: 'QNS01'
    },
    {
        value: 'ENGL',
        label: 'English',
        institution: 'QNS01'
    },
    {
        value: 'ENSCI',
        label: 'Environmental Science',
        institution: 'QNS01'
    },
    {
        value: 'EURO',
        label: 'European Studies',
        institution: 'QNS01'
    },
    {
        value: 'FNES',
        label: 'Family, Nut & Exercis',
        institution: 'QNS01'
    },
    {
        value: 'FREN',
        label: 'French',
        institution: 'QNS01'
    },
    {
        value: 'GEOL',
        label: 'Geology',
        institution: 'QNS01'
    },
    {
        value: 'GERM',
        label: 'German',
        institution: 'QNS01'
    },
    {
        value: 'GRKMD',
        label: 'Modern Greek',
        institution: 'QNS01'
    },
    {
        value: 'HIST',
        label: 'History',
        institution: 'QNS01'
    },
    {
        value: 'HMNS',
        label: 'Honors in Math & Sci',
        institution: 'QNS01'
    },
    {
        value: 'HSS',
        label: 'Honors: Hum/Soc Sci',
        institution: 'QNS01'
    },
    {
        value: 'ITAL',
        label: 'Italian',
        institution: 'QNS01'
    },
    {
        value: 'JPNS',
        label: 'Japanese',
        institution: 'QNS01'
    },
    {
        value: 'KOR',
        label: 'Korean',
        institution: 'QNS01'
    },
    {
        value: 'LBSCI',
        label: 'Library Science',
        institution: 'QNS01'
    },
    {
        value: 'LCD',
        label: 'Ling & Commun Disorders',
        institution: 'QNS01'
    },
    {
        value: 'LIBR',
        label: 'Library',
        institution: 'QNS01'
    },
    {
        value: 'MAM',
        label: 'Maintain Matriculation',
        institution: 'QNS01'
    },
    {
        value: 'MATH',
        label: 'Mathematics',
        institution: 'QNS01'
    },
    {
        value: 'MEDST',
        label: 'Media Studies',
        institution: 'QNS01'
    },
    {
        value: 'MES',
        label: 'Media Studies',
        institution: 'QNS01'
    },
    {
        value: 'MUSIC',
        label: 'Music',
        institution: 'QNS01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'QNS01'
    },
    {
        value: 'PHIL',
        label: 'Philosophy',
        institution: 'QNS01'
    },
    {
        value: 'PHOTO',
        label: 'Photography',
        institution: 'QNS01'
    },
    {
        value: 'PHYS',
        label: 'Physics',
        institution: 'QNS01'
    },
    {
        value: 'PORT',
        label: 'Portuguese',
        institution: 'QNS01'
    },
    {
        value: 'PSCI',
        label: 'Political Science',
        institution: 'QNS01'
    },
    {
        value: 'PSYCH',
        label: 'Psychology',
        institution: 'QNS01'
    },
    {
        value: 'QNS',
        label: 'Queens',
        institution: 'QNS01'
    },
    {
        value: 'RM',
        label: 'Risk Management',
        institution: 'QNS01'
    },
    {
        value: 'RUSS',
        label: 'Russian',
        institution: 'QNS01'
    },
    {
        value: 'SEEK',
        label: 'SEEK Academic Program',
        institution: 'QNS01'
    },
    {
        value: 'SEYS',
        label: 'Secondary Edu & Youth',
        institution: 'QNS01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'QNS01'
    },
    {
        value: 'SPAN',
        label: 'Spanish',
        institution: 'QNS01'
    },
    {
        value: 'STABD',
        label: 'Study Abroad',
        institution: 'QNS01'
    },
    {
        value: 'URBST',
        label: 'Urban Studies',
        institution: 'QNS01'
    },
    {
        value: 'WGS',
        label: 'Women\'s, Gender & Sex St',
        institution: 'QNS01'
    },
    {
        value: 'LABR',
        label: 'Labor Studies',
        institution: 'SLU01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'SLU01'
    },
    {
        value: 'URB',
        label: 'Urban Studies',
        institution: 'SLU01'
    },
    {
        value: 'JOUR',
        label: 'Journalism',
        institution: 'SOJ01'
    },
    {
        value: 'M',
        label: 'Maintenance of Matriculation',
        institution: 'SOJ01'
    },
    {
        value: 'STAB',
        label: 'Study Abroad',
        institution: 'SOJ01'
    },
    {
        value: 'BIOS',
        label: 'Biostatistics',
        institution: 'SPH01'
    },
    {
        value: 'CHSS',
        label: 'Community Health Soc Sc',
        institution: 'SPH01'
    },
    {
        value: 'EOHS',
        label: 'Env Occupational Hlth',
        institution: 'SPH01'
    },
    {
        value: 'EPID',
        label: 'Epidemiology',
        institution: 'SPH01'
    },
    {
        value: 'FNPH',
        label: 'Food Nutri & Public Hlt',
        institution: 'SPH01'
    },
    {
        value: 'HPAM',
        label: 'Health Policy & Mgmt',
        institution: 'SPH01'
    },
    {
        value: 'MAM',
        label: 'Maintain Matriculation',
        institution: 'SPH01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'SPH01'
    },
    {
        value: 'PUBH',
        label: 'Public Health Education',
        institution: 'SPH01'
    },
    {
        value: 'ANTH',
        label: 'Anthropology',
        institution: 'SPS01'
    },
    {
        value: 'ART',
        label: 'Art',
        institution: 'SPS01'
    },
    {
        value: 'AST',
        label: 'Astronomy',
        institution: 'SPS01'
    },
    {
        value: 'BIO',
        label: 'Biology',
        institution: 'SPS01'
    },
    {
        value: 'BUS',
        label: 'Business Administration',
        institution: 'SPS01'
    },
    {
        value: 'CHEM',
        label: 'Chemistry',
        institution: 'SPS01'
    },
    {
        value: 'CIS',
        label: 'Computer Information Sys',
        institution: 'SPS01'
    },
    {
        value: 'COM',
        label: 'Communication Studies',
        institution: 'SPS01'
    },
    {
        value: 'DATA',
        label: 'Data Analysis',
        institution: 'SPS01'
    },
    {
        value: 'EAS',
        label: 'Earth & Atmospheric Sci',
        institution: 'SPS01'
    },
    {
        value: 'ECE',
        label: 'Early Childhood Educ',
        institution: 'SPS01'
    },
    {
        value: 'ECO',
        label: 'Economics',
        institution: 'SPS01'
    },
    {
        value: 'EDUC',
        label: 'Education',
        institution: 'SPS01'
    },
    {
        value: 'ENG',
        label: 'English',
        institution: 'SPS01'
    },
    {
        value: 'FIN',
        label: 'Finance',
        institution: 'SPS01'
    },
    {
        value: 'FLM',
        label: 'Film',
        institution: 'SPS01'
    },
    {
        value: 'GEOG',
        label: 'Geography',
        institution: 'SPS01'
    },
    {
        value: 'HIST',
        label: 'History',
        institution: 'SPS01'
    },
    {
        value: 'INT',
        label: 'Interdiscip Ind Study I',
        institution: 'SPS01'
    },
    {
        value: 'IS',
        label: 'Independent Study',
        institution: 'SPS01'
    },
    {
        value: 'MAM',
        label: 'Maintain Matriculation',
        institution: 'SPS01'
    },
    {
        value: 'MATH',
        label: 'Mathematics',
        institution: 'SPS01'
    },
    {
        value: 'MST',
        label: 'Music Theory',
        institution: 'SPS01'
    },
    {
        value: 'MUS',
        label: 'Music',
        institution: 'SPS01'
    },
    {
        value: 'NURS',
        label: 'Nursing',
        institution: 'SPS01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'SPS01'
    },
    {
        value: 'PHE',
        label: 'Public Health',
        institution: 'SPS01'
    },
    {
        value: 'PHIL',
        label: 'Philosophy',
        institution: 'SPS01'
    },
    {
        value: 'PHYS',
        label: 'Physics',
        institution: 'SPS01'
    },
    {
        value: 'POL',
        label: 'Political Science',
        institution: 'SPS01'
    },
    {
        value: 'PSY',
        label: 'Psychology',
        institution: 'SPS01'
    },
    {
        value: 'RM',
        label: 'Risk Management',
        institution: 'SPS01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'SPS01'
    },
    {
        value: 'SPAN',
        label: 'Spanish',
        institution: 'SPS01'
    },
    {
        value: 'ACC',
        label: 'Accounting',
        institution: 'YRK01'
    },
    {
        value: 'ANTH',
        label: 'Anthropology',
        institution: 'YRK01'
    },
    {
        value: 'ARAB',
        label: 'Arabic',
        institution: 'YRK01'
    },
    {
        value: 'ASTR',
        label: 'Astronomy',
        institution: 'YRK01'
    },
    {
        value: 'AVIA',
        label: 'Aviation Management',
        institution: 'YRK01'
    },
    {
        value: 'BENG',
        label: 'Bengali',
        institution: 'YRK01'
    },
    {
        value: 'BIO',
        label: 'Biology',
        institution: 'YRK01'
    },
    {
        value: 'BLST',
        label: 'Black Studies',
        institution: 'YRK01'
    },
    {
        value: 'BTEC',
        label: 'Biotechnology',
        institution: 'YRK01'
    },
    {
        value: 'BUS',
        label: 'Business Administration',
        institution: 'YRK01'
    },
    {
        value: 'CHEM',
        label: 'Chemistry',
        institution: 'YRK01'
    },
    {
        value: 'CHIN',
        label: 'Chinese',
        institution: 'YRK01'
    },
    {
        value: 'CLDV',
        label: 'Cultural Diversity',
        institution: 'YRK01'
    },
    {
        value: 'CLS',
        label: 'Clinical Laboratory Sci',
        institution: 'YRK01'
    },
    {
        value: 'CRE',
        label: 'Creole',
        institution: 'YRK01'
    },
    {
        value: 'CS',
        label: 'Computer Science',
        institution: 'YRK01'
    },
    {
        value: 'CT',
        label: 'Communications Technology',
        institution: 'YRK01'
    },
    {
        value: 'CTM',
        label: 'Clinical Trial Mgmt',
        institution: 'YRK01'
    },
    {
        value: 'ECON',
        label: 'Economics',
        institution: 'YRK01'
    },
    {
        value: 'EDUC',
        label: 'Education',
        institution: 'YRK01'
    },
    {
        value: 'EHS',
        label: 'Environmental Health Sci',
        institution: 'YRK01'
    },
    {
        value: 'ENG',
        label: 'English',
        institution: 'YRK01'
    },
    {
        value: 'ESL',
        label: 'English as a Second Lang',
        institution: 'YRK01'
    },
    {
        value: 'FA',
        label: 'Fine Arts',
        institution: 'YRK01'
    },
    {
        value: 'FINC',
        label: 'Finance',
        institution: 'YRK01'
    },
    {
        value: 'FREN',
        label: 'French',
        institution: 'YRK01'
    },
    {
        value: 'GEOL',
        label: 'Geology',
        institution: 'YRK01'
    },
    {
        value: 'GERO',
        label: 'Gerontology',
        institution: 'YRK01'
    },
    {
        value: 'HE',
        label: 'Health Education',
        institution: 'YRK01'
    },
    {
        value: 'HIST',
        label: 'History',
        institution: 'YRK01'
    },
    {
        value: 'HPGC',
        label: 'Health Prof General Cor',
        institution: 'YRK01'
    },
    {
        value: 'HPPA',
        label: 'Physician Assistant',
        institution: 'YRK01'
    },
    {
        value: 'HS',
        label: 'Health Science',
        institution: 'YRK01'
    },
    {
        value: 'HUM',
        label: 'Human Services',
        institution: 'YRK01'
    },
    {
        value: 'IS',
        label: 'Independent Study',
        institution: 'YRK01'
    },
    {
        value: 'ITAL',
        label: 'Italian',
        institution: 'YRK01'
    },
    {
        value: 'JOUR',
        label: 'Journalism',
        institution: 'YRK01'
    },
    {
        value: 'MATH',
        label: 'Mathematics',
        institution: 'YRK01'
    },
    {
        value: 'MKT',
        label: 'Marketing',
        institution: 'YRK01'
    },
    {
        value: 'MS',
        label: 'Movement Science',
        institution: 'YRK01'
    },
    {
        value: 'MSCI',
        label: 'Military Sci',
        institution: 'YRK01'
    },
    {
        value: 'MUS',
        label: 'Music',
        institution: 'YRK01'
    },
    {
        value: 'NESS',
        label: 'New Student Seminars',
        institution: 'YRK01'
    },
    {
        value: 'NURS',
        label: 'Nursing',
        institution: 'YRK01'
    },
    {
        value: 'OT',
        label: 'Office Technology',
        institution: 'YRK01'
    },
    {
        value: 'PE',
        label: 'Physical Edu & Dance',
        institution: 'YRK01'
    },
    {
        value: 'PERM',
        label: 'Permit Course',
        institution: 'YRK01'
    },
    {
        value: 'PH',
        label: 'Physics',
        institution: 'YRK01'
    },
    {
        value: 'PHIL',
        label: 'Philosophy',
        institution: 'YRK01'
    },
    {
        value: 'PHS',
        label: 'Pharmaceutical Science',
        institution: 'YRK01'
    },
    {
        value: 'PHYS',
        label: 'Physics',
        institution: 'YRK01'
    },
    {
        value: 'POL',
        label: 'Political Science',
        institution: 'YRK01'
    },
    {
        value: 'PRST',
        label: 'Puerto Rican Studies',
        institution: 'YRK01'
    },
    {
        value: 'PSY',
        label: 'Psychology',
        institution: 'YRK01'
    },
    {
        value: 'SCWK',
        label: 'Social Work',
        institution: 'YRK01'
    },
    {
        value: 'SD',
        label: 'Student Development',
        institution: 'YRK01'
    },
    {
        value: 'SKCS',
        label: 'SEEK Counseling Seminar',
        institution: 'YRK01'
    },
    {
        value: 'SOC',
        label: 'Sociology',
        institution: 'YRK01'
    },
    {
        value: 'SPAN',
        label: 'Spanish',
        institution: 'YRK01'
    },
    {
        value: 'SPCH',
        label: 'Speech',
        institution: 'YRK01'
    },
    {
        value: 'TA',
        label: 'Theatre Arts',
        institution: 'YRK01'
    },
    {
        value: 'USIP',
        label: 'Univ Skills Prog.',
        institution: 'YRK01'
    },
    {
        value: 'WLIT',
        label: 'World Literature',
        institution: 'YRK01'
    },
    {
        value: 'WRIT',
        label: 'Writing',
        institution: 'YRK01'
    }
];

export default allSubjects;
