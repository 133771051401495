import React, { useEffect, useMemo, useState } from 'react';
import { QuestionParams } from '@/constants/questions';
import {
    getNegativeExpectationQuestions,
    NegativeExpectationQuestion, NegativeExpectationsResponse
} from '@/constants/questions/negativeExpectations';
import tw from 'twin.macro';
import { Button, TextArea } from '@/components/common';
import styled from 'styled-components/macro';
import ScheduleDiff from '@/components/simulation/ScheduleDiff';
import CheckboxQuestion from '@/components/survey/CheckboxQuestion';
import { disableSubmitOnEnter } from '@/components/common/inputUtils';

interface Props {
    params: QuestionParams
    onSubmitResponse: (response: NegativeExpectationsResponse) => void;
}

const FormContainerStyle = styled.form`
`;

const EMPTY_STATE = {
    q3a1: {}, q3a2: {}, q3a3: {}, q3a4: {}, q3a5: {}, q3a6: {}, q3a7: '', q3a8: 'N', submittedTs: ''
};
export default ({ params, onSubmitResponse }: Props) => {

    const [ formInput, setFormInput ] = useState<NegativeExpectationsResponse>({
        questionsShown: [], q3a1: {}, q3a2: {}, q3a3: {}, q3a4: {}, q3a5: {}, q3a6: {}, q3a7: '', q3a8: 'N', submittedTs: ''
    });

    const questions = useMemo(() => {
        const filteredQuestions = getNegativeExpectationQuestions(params);
        return filteredQuestions;
    }, [ params ]);

    useEffect(() => {
        const ids = questions.map(q => {
            return q.id;
        });
        setFormInput({
            ...formInput,
            questionsShown: ids
        });

    }, [ questions ]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmitResponse(formInput);
    };

    const renderRadioQuestion = (q: NegativeExpectationQuestion) => {
        return (
            <div css={tw`my-6`} key={q.id}>
                <p css={tw`mb-2`} dangerouslySetInnerHTML={{__html: q.label}}/>

                <div css={tw`flex flex-row mb-1`}>
                    {
                        q.choices.map((choice) => {
                            return (
                                <label css={tw`mx-2`} key={choice.value}>
                                    <input
                                        defaultChecked={choice.value === q.defaultValue}
                                        value={choice.value}
                                        type="radio"
                                        name={q.id}
                                        onKeyPress={disableSubmitOnEnter}
                                        onChange={(e) => {
                                            setFormInput({
                                                ...formInput,
                                                [e.target.name]: e.target.value
                                            });
                                        }}
                                    />
                                    <span css={tw`pl-1`} dangerouslySetInnerHTML={{__html: choice.label}}/>
                                </label>
                            );
                        })
                    }
                </div>
            </div>
        );
    };

    const renderCheckboxQuestion = (q: NegativeExpectationQuestion) => {

        return (
            <CheckboxQuestion
                requireAtLeastOne={true}
                key={q.id}
                label={q.label}
                choices={q.choices}
                noneKey={'None'}
                otherKey={'Other'}
                otherValueKey={'OtherValue'}
                // @ts-ignore
                response={formInput[q.id] || {}}
                onResponse={response => {
                    setFormInput({
                        ...formInput,
                        [q.id]: response,
                    });
                }}
            />
        );
    };

    const renderFreeTextQuestion = (q: NegativeExpectationQuestion) => {

        return (
            <div css={tw`my-6`} key={q.id}>
                <p css={tw`mb-2`} dangerouslySetInnerHTML={{__html: q.label}}/>
                <TextArea
                    css={tw`text-base`}
                    placeholder={'Optional'}
                    name={q.id}
                    // @ts-ignore
                    value={formInput[q.id]}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setFormInput({
                            ...formInput,
                            [event.target.name]: event.target.value
                        });
                    }}
                    rows={4}
                />
            </div>
        );
    };

    const renderQuestion = (question: NegativeExpectationQuestion) => {
        if (question.type === 'checkbox') {
            return renderCheckboxQuestion(question);
        } else if (question.type === 'text') {
            return renderFreeTextQuestion(question);
        } else if (question.type === 'radio') {
            return renderRadioQuestion(question);
        }
        return null;
    };

    return (
        <FormContainerStyle onSubmit={handleSubmit}>
            <ScheduleDiff params={params}/>
            {questions.map(renderQuestion)}

            <div css={tw`text-right pr-3`}>
                <Button type={'submit'}>Next</Button>
            </div>
        </FormContainerStyle>
    );
};
