import React, { CSSProperties } from 'react';
import tw from 'twin.macro';
import { Option } from '@/api/types';
import styled, { StyledComponent } from 'styled-components/macro';

interface Props {
    label?: string;
    name: string;
    choices: Option[];
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    required?: boolean;
    selected: string;
    containerStyle?: CSSProperties
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    container?: StyledComponent<any, any>;
}

const ContainerStyle = styled.div`
    ${tw`py-3 flex items-center`}
    &:not(:last-child) {
        ${tw`border-0 border-solid border-b border-gray-300`}
    }
   
`;

export default ({ label = '', name, choices, onChange, required, selected, containerStyle = {}, inputStyle = {}, labelStyle = {}, container }: Props) => {

    const ContainerComponent: StyledComponent<any, any> = container || ContainerStyle;

    return (
        <ContainerComponent style={containerStyle}>
            <p css={tw`font-normal mr-2`} style={labelStyle}>{label}</p>
            <select style={inputStyle} css={tw`block mx-2`} name={name} required={required} onChange={onChange} value={selected}>
                <option value=""/>
                {choices.map(choice => {
                    return (
                        <option key={choice.value} value={choice.value}>
                            {choice.label}
                        </option>
                    );
                })}
            </select>
        </ContainerComponent>
    );
};
