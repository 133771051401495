import {
    CourseInfo,
    SelectedCourse
} from '@/api/types';
import store from '@/store';
import { CourseExtract } from '@/api/graphql/queries/getCourses';
import { getTransferRules } from '@/utils/transferUtils';
import { RequirementsValidationResult, validateRequirements } from '@/utils/enrollmentUtils';
import { compareClassTimes } from '@/utils/scheduleUtils';
import { getCourseInfo } from '@/api/graphql/queries/getCourseInfo';

export const checkForConflictingTimes = (selectedCourse: SelectedCourse, shoppingCart: SelectedCourse[]): SelectedCourse | null => {
    if (selectedCourse.sectionMode === 'OA') return null;

    for (const existingCourse of shoppingCart) {

        for (const existingSection of existingCourse.selectedSections) {

            for (const sectionToAdd of selectedCourse.selectedSections) {
                if (!compareClassTimes(sectionToAdd, existingSection)) {

                    return existingCourse;
                }
            }
        }
    }
    return null;
};

export const validateAddRequirements = async (course: CourseInfo): Promise<RequirementsValidationResult> => {
    const { student } = store.getActions();
    const studentDetails = await student.getStudentDetails();

    const transferCourses: CourseExtract[] = await getTransferRules(course, studentDetails);

    const courseInfoWithRequirements = await getCourseInfo({
        courseId: course.courseId,
        institution: course.institution,
        subject: course.subject,
    });

    const result = validateRequirements(courseInfoWithRequirements!, {
        ...studentDetails,
        transferCourses,
    });
    console.log('validateRequirements result: ', result);

    return result;
};
