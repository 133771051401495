import React, { useEffect, useState } from 'react';
import getAccounts, { AccountRaw } from '@/api/getAccounts';
import { Spinner } from '../common/Spinner';
import tw from 'twin.macro';
import { ResultsTableStyle, TableContainerStyle } from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import updateAccount from '@/api/updateAccount';

export default () => {
    const [ loading, setLoading ] = useState<boolean>(false);

    const [ accounts, setAccounts ] = useState<AccountRaw[]>([]);

    const [ selectedId, setSelectedId ] = useState<string>('');
    const [ editPassword, setEditPassword ] = useState<string>('');

    const fetchAccounts = () => {
        setLoading(true);
        getAccounts()
            .then(results => {
                setAccounts(results);
            })
            .catch(err => console.error(err))
            .then(() => setLoading(false));
    };

    useEffect(() => {
        fetchAccounts();
    }, []);


    const onEdit = (studentId: string) => {
        setSelectedId(studentId);
    };

    // quick and dirty implementation. if we need to add more functionality or fields, then create standalone UserEditForm component
    const onUpdate = () => {
        if (!editPassword || !selectedId) return;

        setLoading(true);
        updateAccount({emplid: selectedId, password: editPassword})
            .catch(err => console.error(err))
            .then(() => {
                clearEditForm();
                setLoading(false);
            });
    };

    const clearEditForm = () => {
        setSelectedId('');
        setEditPassword('');
    };

    const renderUserRow = (o: AccountRaw, i: number) => {
        return (
            <tr key={o.emplid}>
                <td><span css={tw`text-right`}>{i + 1}.</span></td>
                <td>
                    <span>{o.emplid}</span>
                </td>
                <td>
                    <span>•••••••••••</span>
                </td>
                <td>
                    <a onClick={() => onEdit(o.emplid)} css={tw`mx-3 cursor-pointer text-gray-900 hover:opacity-75`}>
                        <FontAwesomeIcon icon={faPencilAlt}/>
                    </a>
                </td>
            </tr>
        );
    };

    const renderUserEditRow = (o: AccountRaw, i: number) => {
        return (
            <tr key={o.emplid}>
                <td><span css={tw`text-right`}>{i + 1}.</span></td>
                <td>
                    <span>{o.emplid}</span>
                </td>
                <td>
                    <input type='password' value={editPassword} onChange={e => setEditPassword(e.target.value)}/>
                </td>
                <td>
                    <a
                        css={tw`mx-3 hover:opacity-75 hover:underline cursor-pointer text-gray-700`}
                        onClick={() => clearEditForm()}
                    >
                        Cancel
                    </a>

                    <a
                        css={tw`mx-3 hover:opacity-75 hover:underline cursor-pointer text-link`}
                        onClick={() => {
                            onUpdate();
                        }}
                    >
                        Update
                    </a>
                </td>
            </tr>
        );
    };

    return (
        <div>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />

            <TableContainerStyle>
                <ResultsTableStyle css={tw`flex-shrink-0`}>
                    <thead>
                        <tr>
                            <th><span>#</span></th>
                            <th>
                                <span>Student Id</span>
                            </th>
                            <th style={{width: '180px'}}>
                                <span>Password</span>
                            </th>

                            <th style={{width: '155px'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            accounts.map((o, i) => {
                                if (o.emplid === selectedId) {
                                    return renderUserEditRow(o, i);
                                } else {
                                    return renderUserRow(o, i);
                                }
                            })
                        }
                    </tbody>
                </ResultsTableStyle>
            </TableContainerStyle>
        </div>
    );
};
