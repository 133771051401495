import React, { useMemo } from 'react';
import { RequirementGroupDetails, RequirementLine, Condition } from '@/api/types';
import {
    formatCumulativeGradeAverageCondition,
    formatMilestoneCondition,
    formatStudentGroupCondition,
    formatTestScoreCondition, parseDynamicConditions
} from '@/utils/catalogUtils';
import tw from 'twin.macro';

interface Props {
    requirementLine: RequirementLine | Exclude<RequirementGroupDetails, null>
}

export default ({ requirementLine }: Props) => {

    const connectorMessage = useMemo<string>(() => {
        let s = '';
        if (requirementLine.conditions.length > 1) {

            if (requirementLine.conditionSpec.connect === 'AND') {
                s = 'All of the following:';
            } else if (requirementLine.conditionSpec.connect === 'OR') {
                s = 'Any of the following:';
            }
        }

        return s;
    }, [ requirementLine ]);

    const conditions = useMemo<Condition[]>(() => parseDynamicConditions(requirementLine), [ requirementLine ]);

    const renderCondition = (condition: Condition) => {
        let title = '';
        let info = '';
        if ('conditionCode' in condition) {
            switch (condition.conditionCode) {
                case 'TST':
                    title = 'Test Score';
                    info = formatTestScoreCondition(condition);
                    break;

                case 'GRP':
                    title = 'Student Group';
                    info = formatStudentGroupCondition(condition);
                    break;
                case 'GRS':
                    title = 'Student Group';
                    info = formatStudentGroupCondition(condition);
                    break;

                case 'CGA':
                    title = 'Cumulative Grade Average';
                    info = formatCumulativeGradeAverageCondition(condition);
                    break;
                default:
                    break;
            }
        } else {
            if (condition.milestone) {
                title = 'Milestone';
                info = formatMilestoneCondition(condition);
            }
        }

        return (
            <div key={condition.lineSequence}>
                <p css={tw`font-medium`}>Condition: {title}</p>
                <p>{info}</p>
            </div>
        );
    };

    return (
        <div>
            {
                connectorMessage ?
                    <p>{connectorMessage}</p>
                    : null
            }

            <div css={tw`ml-4`}>
                {conditions.map(renderCondition)}
            </div>

        </div>
    );
};
