import http from '@/api/http';
import { QueryParams } from '@/components/dashboard/components/filter-bar/filterParams';

export interface ResultsAggregateResponse {
    byDay: AggregateStats[];
    byInteraction: AggregateStats[];
    byCurrentCartSizeStartedOnly: AggregateStats[];
    bySubmittedCartSizeStartedOnly: AggregateStats[];
    counts: AggregateStats[];
}

export interface AggregateStats {
    id: string; // can be day YYYY-MM-DD or other key type
    count: number;
    percentTotal?: number;
}

export default async (params: QueryParams): Promise<ResultsAggregateResponse> => {
    const endpoint = '/results-aggregate';
    const { data } = await http.post(endpoint, params);
    return data;
};

export const generateEmptyAggregateStats = (): ResultsAggregateResponse => {
    return {
        byDay: [],
        byInteraction: [],
        byCurrentCartSizeStartedOnly: [],
        bySubmittedCartSizeStartedOnly: [],
        counts: [],
    };
};
