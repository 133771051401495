import { useCallback } from 'react';
import { useGetSimResults, useUpdateSimResults } from '@/api/simulation/SimulationAPI';

// Go back to before submitting cart. Similar to restarting, we definitely need to use originalSubmittedCart here
// However it's possible this option is used before actually starting the simulation. In that case originalSubmittedCart
// would be empty so we would just reuse the current shopping cart.
export default (): () => Promise<any> => {
    const simResults = useGetSimResults();
    const updateSimResults = useUpdateSimResults();

    return useCallback<() => Promise<any>>(() => {
        return updateSimResults({
            // @ts-ignore
            data: {
                startedTs: '',
                availableTimes: [],
                originalSubmittedCart: [],
            },
            shoppingCart: simResults.data?.originalSubmittedCart?.length ? simResults.data.originalSubmittedCart : simResults.shoppingCart,
        });
    }, [ simResults ]);
};
