import React from 'react';
import CourseGroupItem from './CourseGroupItem';
import { CourseGroup } from '@/api/types';
import { AddCourseProps } from '@/components/search-results/SearchResultsContainer';

interface Props {
    items: CourseGroup[];
    onAddClass: (props: AddCourseProps) => void;
}

export default ({ onAddClass, items }: Props) => {
    return (
        <div>
            {items.map(item => {
                return (
                    <CourseGroupItem onAddClass={onAddClass} key={`${item.subject}-${item.courseId}-${item.courseNumber}`} courseGroup={item}/>
                );
            })}
        </div>
    );
};
