import tw from 'twin.macro';
import config from '@/config';
import React from 'react';
import { useMe } from '@/api/AuthService';

export default () => {
    const account = useMe();

    return (
        <div
            style={{
                marginTop: '-12px',
                position: 'absolute',
                right: '20px'
            }}
            css={tw`text-right text-xs text-gray-200`}
        >
            <span css={tw`text-right text-xs text-gray-200 mr-3`}>{account?.studentId}</span>
            <span css={tw`text-right text-xs text-gray-200`}>{config.version}</span>
        </div>
    );
};
