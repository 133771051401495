import React from 'react';
import TimestampInput from '@/components/dashboard/components/filter-bar/TimestampInput';
import { Option } from '@/api/types';
import TextColumnInput from '@/components/dashboard/components/filter-bar/TextColumnValueInput';

interface Props {
    column: Option | null;
    onChange: (value: string) => void;
    value: string;
}

export default ({ column, onChange, value }: Props) => {
    if (!column) return null;

    if (column.type === 'timestamp') {
        return <TimestampInput value={value} column={column} onChange={onChange}/>;
    }
    if (column.type === 'text') {
        return <TextColumnInput value={value} column={column} onChange={onChange}/>;
    }

    return null;

};
