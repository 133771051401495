import React from 'react';
import tw from 'twin.macro';
import styled, { CSSObject } from 'styled-components/macro';

interface Props {
    label: string;
    value: string;
    containerStyle?: CSSObject;
}

const ValueStyle = styled.p`
    ${tw`font-normal text-gray-900 text-5xl`}
    line-height: 3rem;
`;

const MetricSummary = ({ label, value, containerStyle = {} }: Props) => {
    return (
        <div css={tw`text-left my-4`} style={{ ...containerStyle }}>
            <ValueStyle>{value}</ValueStyle>
            <p css={tw`font-normal text-base text-gray-900`}>{label}</p>
        </div>
    );
};

export default MetricSummary;
