import { CourseGroup } from '@/api/types';
import { useStoreState } from '@/store';
import { useGetSimResults } from '@/api/simulation/SimulationAPI';
import {
    simulateSearchResults,
    SimulatedResults
} from '@/store/simulation/simulationUtils';

// this hook will take the raw search results and then filter out any sections based on the search filters
// as well as any unavailable sections due to encountered simulated events.
// It also handles parsing the instruction mode to render the separate synchronous choices
export default (rawCourseResults: CourseGroup[]): SimulatedResults => {
    const filters = useStoreState(state => state.search.filters);
    const { data } = useGetSimResults();

    return simulateSearchResults(rawCourseResults, filters, data);
};
