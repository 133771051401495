import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const PreferencesContainerStyle = styled.div`
    ${tw`w-full lg:px-12 select-none max-w-screen-xl`}
    
    > h3 {
        font-size: 16px;
        color: rgb(74,89,140);
        margin-bottom: 17px;
        padding-right: 6px;

        ${tw`font-normal text-lg`};
    }
`;

export const CalendarWrapperStyle = styled.div`
    width: 100%;
    overflow: auto;
`;

export const CalendarContainerStyle = styled.div`
    min-width: 400px;
`;

export const PageControlsStyle = styled.div`
    background-color: white;
    width: 100%;
    padding: 0;
    
    .content-container {
        display: flex;
        justify-content: space-between;
        
        box-shadow: 0 -5px 6px -6px #333;
        position: relative;
        padding-top: 10px;
        
        ${tw`max-w-screen-xl`}        
    }
`;
