import { LoginResponse } from '@/api/types';
import config from '@/config';
import http from '@/api/http';

export default async (studentId: string, password = ''): Promise<LoginResponse> => {
    const endpoint = `${config.apiUrl}/admin-login`;
    const { data } = await http.post(endpoint, { studentId, password });
    console.log('adminLogin data: ', data);
    return data;
};
