import { ClassSearchCriteriaValues } from '@/components/class-search/ClassSearchForm';
import { CriteriaType, DayCriteriaType } from '@/components/class-search/SearchCriteria';
import { currentTerm, defaultTerm } from '@/constants/terms';
import { convertTimeSlotToTimestamp } from '@/utils/dateUtils';
import {
    AcademicPlanInfo,
    ClassNameDetail,
    CourseClass,
    CourseClassExtract,
    CourseClassMeeting,
    CourseClassQueryResult,
    CourseClassWithGroupData,
    CourseGroup,
    CourseListItem,
    Day,
    MeetingDay,
    RequirementDesignation,
    RequirementGroupLineType,
    RequisiteCourse,
    RequisiteType,
    ScribeQualifier,
    ScribeRule,
    SectionMode,
    SelectedCourse,
    SelectedCourseExtract,
    SelectedSectionExtract,
    SingleOrArray,
    StudentEnrollment,
    StudentInfo
} from './types';
import { addMinutes, format, parseISO, roundToNearestMinutes, startOfToday } from 'date-fns';
import _ from 'lodash';
import requirementDesignations from '@/constants/requirementDesignations';
import { NoReplacement, UnavailableCourseClass } from '@/store/simulation/types';
import { InstructionModeName, InstructionModeSurveyFormat } from '@/constants/instructionModes';
import { AvailableTimesSelection } from '@/components/preferences/AllAvailableTimesPicker';
import { CourseComponentName } from '@/constants/courseComponents';
import { DecimalFormatter } from '@/utils/utilities';

const vowels = 'aeiouAEIOU';
export const prependArticle = (word: string): string => {
    if (!word) return '';

    if (vowels.indexOf(word[0]) !== -1) {
        return 'an';
    } else {
        return 'a';
    }
};

export const convertReqDesignationCriteriaToFilter = (requirementDesignation: RequirementDesignation | '') => {
    if (!requirementDesignation) return {};

    const values: string[] = _.find(requirementDesignations, {value: requirementDesignation})?.queryValues as string[];

    if (!values) {
        throw new Error(`invalid requirementDesignation: ${requirementDesignation}`);
    }

    const filter = {
        rqmnt_designtn: {
            _in: values
        }
    };
    return filter;
};

export const convertExactStringCriteriaToFilter = (propertyName: string, value: string) => {
    if (!propertyName || !value) return {};
    return {
        [propertyName]: {
            _eq: value
        }
    };
};

export const convertFuzzyTextCriteriaToFilter = (propertyName: string, keywords: string) => {
    if (!propertyName || !keywords) return {};
    return {
        [propertyName]: {
            _ilike: `%${keywords}%`
        }
    };
};

// CriteriaType.Between should be handled manually
const convertCriteriaTypeToOperator = (criteriaType: Exclude<CriteriaType, CriteriaType.Between>): string => {
    switch (criteriaType) {
        case CriteriaType.GreaterThan:
            return '_gt';
        case CriteriaType.GreaterThanOrEqualTo:
            return '_gte';
        case CriteriaType.IsExactly:
            return '_eq';
        case CriteriaType.LessThanOrEqualTo:
            return '_lte';
        case CriteriaType.LessThan:
            return '_lt';
        case CriteriaType.NotEqualTo:
            return '_neq';
        case CriteriaType.Contains:
            return '_ilike';
        default:
            throw new Error(`Invalid criteriaType: ${criteriaType}`);
    }
};

export const convertNumberCriteriaToFilter = (propertyName: string, value: string, criteriaType: Exclude<CriteriaType, CriteriaType.Between> | '') => {

    if (!criteriaType ||
        // catalogNumber can have letters in it.
        (isNaN(parseFloat(value)) && propertyName !== 'catalog_nbr')
    ) {
        return {};
    }

    let transformedPropertyName, transformedValue;
    switch (propertyName) {
        case 'catalog_nbr':
            if (criteriaType === CriteriaType.IsExactly) {
                transformedPropertyName = propertyName;
                transformedValue = value;
            } else if (criteriaType === CriteriaType.Contains) {
                transformedPropertyName = propertyName;
                transformedValue = `%${value}%`;
            } else {
                // see ordering tip in searchClasses
                transformedPropertyName = 'catalog_nbr_int';
                transformedValue = value.replace(/[^0-9.]/g, '');
                console.log('transformedPropertyName: ', transformedPropertyName);
                console.log('transformedValue: ', parseFloat(transformedValue));
            }

            break;
        default:
            transformedPropertyName = propertyName;
            transformedValue = value;
    }

    const operator = convertCriteriaTypeToOperator(criteriaType);
    const numberFilter: any = {
        [transformedPropertyName]: {
            [operator]: transformedValue
        }
    };
    return numberFilter;
};

export const convertTimesCriteriaToFilter = (propertyName: string, times: [string ?, string ?], criteriaType: CriteriaType | '') => {
    if (!times || !times[0] || !criteriaType ||
        (criteriaType === CriteriaType.Between && !times[1])) {
        return {};
    }
    let timesFilter: any = {};

    if (criteriaType === CriteriaType.Between) {
        if (!times[1]) return {};
        timesFilter = {
            _and: [
                {
                    [propertyName]: {
                        _gte: convertTimeSlotToTimestamp(times[0])
                    }
                },
                {
                    [propertyName]: {
                        _lte: convertTimeSlotToTimestamp(times[1])
                    }
                },
            ]
        };
        return timesFilter;
    }

    const operator: string = convertCriteriaTypeToOperator(criteriaType);

    timesFilter[propertyName] = {
        [operator]: convertTimeSlotToTimestamp(times[0])
    };
    return timesFilter;
};

export const convertDaysCriteriaToFilter = (days: Day[], criteriaType: DayCriteriaType | '') => {
    if (!days || !days.length) return {};

    if (!criteriaType) return {};
    let dayFlag: 'N' | 'Y';
    let setOperator: string;
    switch (criteriaType) {
        // If excludeAny then results will be wrapped in a _not clause at the end of the method
        case DayCriteriaType.ExcludeAnyOfThese:
            setOperator = '_or';
            dayFlag = 'Y';
            break;
        case DayCriteriaType.ExcludeOnlyThese:
            setOperator = '_and';
            dayFlag = 'N';
            break;
        case DayCriteriaType.IncludeAnyOfThese:
            setOperator = '_or';
            dayFlag = 'Y';
            break;
        case DayCriteriaType.IncludeOnlyThese:
            setOperator = '_and';
            dayFlag = 'Y';
            break;
        default:
            throw new Error(`Invalid criteriaType: ${criteriaType}`);

    }
    const daysFilter = {
        [setOperator]: [] as any[]
    };

    days.forEach(day => {
        const dayFilter = {
            [day]: {
                _eq: dayFlag
            }
        };
        daysFilter[setOperator].push(dayFilter);
    });

    if (criteriaType === DayCriteriaType.ExcludeAnyOfThese) {
        return {
            _not: {...daysFilter }
        };
    } else {
        return daysFilter;
    }
};

// hack so that we can just have a single property to check "<stage>Replacement" in order to parse the correct reason question
export const generateNoReplacementClassFiller = (): SelectedCourse => {
    return {
        academicGroup: '',
        courseComponent: 'CLN',
        courseId: 'NoReplacement',
        courseNumber: '',
        courseTitle: '',
        institution: '',
        requirementDesignation: 'FCE',
        requirementGroup: '',
        requirementGroupDetails: [],
        sectionMode: 'OA',
        selectedSections: [],
        subject: '',
        units: 0
    };
};

// Sets the default search operators ie. courseNumber "contains" ...
export const generateEmptyClassSearchForm = (): ClassSearchCriteriaValues => {
    return {
        institution: '',
        termId: defaultTerm.id,
        subjectId: '',
        courseNumberCriteria: CriteriaType.Contains,
        courseNumber: '',
        academicCareer: '',
        courseAttribute: '',
        courseAttributeValue: '',
        requirementDesignation: '',
        showOpenClassesOnly: true,
        sessionType: '1', // default to Regular Academic Session
        instructionMode: '',
        startTimeCriteria: CriteriaType.GreaterThanOrEqualTo,
        startTime: [],
        endTimeCriteria: CriteriaType.LessThanOrEqualTo,
        endTime: [],
        daysOfWeekCriteria: DayCriteriaType.IncludeOnlyThese,
        daysOfWeek: [],
        classNumber: '',
        courseKeyword: '',
        maxUnitsCriteria: CriteriaType.LessThanOrEqualTo,
        maxUnits: '',
        minUnitsCriteria: CriteriaType.GreaterThanOrEqualTo,
        minUnits: '',
        courseComponent: '',
        campus: '',
        location: '',
        _courseId: '',
        _courseIdCriteria: CriteriaType.NotEqualTo,
    };
};

export const parseMeetingPattern = (pattern: any): string => {
    if (!pattern.endTime || !pattern.startTime) return 'TBA';
    let s = '';

    if (pattern.mon === 'Y') {
        s += 'Mo';
    }
    if (pattern.tue === 'Y') {
        s += 'Tu';
    }
    if (pattern.wed === 'Y') {
        s += 'We';
    }
    if (pattern.thu === 'Y') {
        s += 'Th';
    }
    if (pattern.fri === 'Y') {
        s += 'Fr';
    }
    if (pattern.sat === 'Y') {
        s += 'Sa';
    }
    if (pattern.sun === 'Y') {
        s += 'Su';
    }

    const startDate = parseISO(pattern.startTime);
    const startTime = format(startDate, 'h:mm aa');

    const endDate = parseISO(pattern.endTime);
    const endTime = format(endDate, 'h:mm aa');

    s = `${s} ${startTime} - ${endTime}`;
    return s;
};

export const parseUnits = (courseClass: SelectedCourse): string => {
    return DecimalFormatter.format(courseClass.units);
};

export const parseRoom = (courseClass: CourseClassWithGroupData | CourseClassExtract): string => {
    switch (courseClass.instructionMode) {
        case 'OA':
            return 'N/A';
        case 'O':
            return 'TBA';
        case 'P':
            return 'On Campus';
        default:
            return '';
    }
};

export const parseClassNumber = (courseGroup: CourseGroup, courseClass: CourseClass): string => {
    return courseClass.classId.toString().slice(-7);
    // if (courseClass.instructionMode === 'P') return courseClass.classId;
    //
    // if (courseClass.instructionMode === 'OA') return `${courseGroup.courseNumber}-OA`;
    //
    // if (courseClass.instructionMode === 'O') return `${courseClass.classSection}-OS`;
    // return '';
};

export const formatClassSection = (classSection = ''): string => {
    return classSection.replace('SIM', '').replace('O!', '').replace('OA_', 'A');
};

export const formatClassType = (classType = ''): string => {
    if (classType === 'N') {
        return 'auto-enrolled';
    }
    return '';
};

export const parseClassSection = (courseClass: {
    subject: string,
    classId: string,
    courseNumber: string,
    classSection: string,
    courseComponent: string,
    sectionMode?: string,
} | NoReplacement): string => {
    if (courseClass === 'NoReplacement' || courseClass.classId === 'NoReplacement') return 'No Replacement';

    const s = `${courseClass.subject} ${courseClass.courseNumber} - ${formatClassSection(courseClass.classSection)} ${courseClass.courseComponent} (${courseClass.sectionMode})`;
    return s.trim();
};

export const parseClassSectionWithInstitution = (courseClass: CourseClassExtract | NoReplacement): string => {
    if (courseClass === 'NoReplacement' || courseClass.classId === 'NoReplacement') return 'No Replacement';

    return `[${courseClass.institution.slice(0, 3)}] ${parseClassSection(courseClass)}`;
};

export const formatSelectedSectionComponent = (selectedSectionExtract: SelectedSectionExtract): string => {
    return `${formatClassSection(selectedSectionExtract.classSection)} ${selectedSectionExtract.courseComponent}`;
};

export const formatSelectedCourseAndSections = (selectedCourseExtract: SelectedCourseExtract | NoReplacement): string => {
    if (selectedCourseExtract === 'NoReplacement' || selectedCourseExtract.courseId === 'NoReplacement') return 'No Replacement';

    const sections = selectedCourseExtract.selectedSections.map(o => formatSelectedSectionComponent(o)).join(', ');
    const s = `${selectedCourseExtract.subject} ${selectedCourseExtract.courseNumber} (${selectedCourseExtract.sectionMode}) - ${sections}`;
    return s.trim();
};

export const formatSelectedCourseAndSectionsWithInstitution = (selectedCourseExtract: SelectedCourseExtract | NoReplacement): string => {
    if (selectedCourseExtract === 'NoReplacement' || selectedCourseExtract.courseId === 'NoReplacement') return 'No Replacement';
    return `[${selectedCourseExtract.institution.slice(0, 3)}] ${formatSelectedCourseAndSections(selectedCourseExtract)}`;
};

// institution_subject_catalogNumber_classSection_sectionMode_classId
// e.g 123_BAR_AAS_3540_OS_sel_classId1_classId2_class_Id3
const IDS_MARKER = '*ids*';
const DELIMITER = '_';
export const generateClassNameDetail = (courseClass: SelectedCourse): ClassNameDetail => {
    const fields: string[] = [
        courseClass.courseId,
        courseClass.institution.slice(0, 3),
        courseClass.subject,
        courseClass.courseNumber,
        courseClass.sectionMode,
        IDS_MARKER,
        ...courseClass.selectedSections.map(o => o.classId),
    ];
    return fields.join('_');
};

export interface SelectedCourseCompositeKeyProps {
    courseId: string;
    institution: string;
    subject: string;
    courseNumber: string;
    sectionMode: SectionMode;
    selectedSectionIds: string[]
}

export const parseSelectedCourseCompositeKey = (nameDetail: ClassNameDetail): SelectedCourseCompositeKeyProps => {
    const [ courseKey, sectionIdsKey ] = nameDetail.split(IDS_MARKER);
    const courseFields = courseKey.split(DELIMITER);
    return {
        courseId: courseFields[0],
        institution: courseFields[2],
        subject: courseFields[3],
        courseNumber: courseFields[4],
        sectionMode: courseFields[5] as SectionMode,
        selectedSectionIds: sectionIdsKey.split(DELIMITER),
    };
};

export const parseClassIdsFromNameDetail = (nameDetail: ClassNameDetail): string[] => {
    const ids = nameDetail.split(IDS_MARKER)[1].split(DELIMITER);
    return ids;
};

export const parseCourseShortName = (courseGroup: CourseGroup | CourseClassWithGroupData | CourseClassExtract | {subject: string; courseNumber: string, sectionMode?: SectionMode}): string => {
    return `${courseGroup.subject} ${courseGroup.courseNumber}`;
};

export const parseCourseTitle = (courseGroup: {subject: string, courseNumber: string, courseTitle: string}): string => {
    return `${courseGroup.subject} ${courseGroup.courseNumber} - ${courseGroup.courseTitle}`;
};

export const parseClassTitle = (c: CourseClassWithGroupData): string => {
    return `${c.subject} ${c.courseNumber} - ${c.classSection}   ${c.courseTitle}`;
};

export const parseLatestDeclaredPlanFromStudentInfo = (studentInfo: any | StudentInfo | null): AcademicPlanInfo | null => {
    return _.chain<AcademicPlanInfo>(studentInfo?.academicPlans)
        .orderBy([ 'declaredDate', 'academicPlan' ], [ 'desc', 'asc' ])
        .first()
        .value();
};

export const parseInstitutionFromStudentInfo = (studentInfo: any | StudentInfo | null): string => {
    const latestDeclaredPlan = parseLatestDeclaredPlanFromStudentInfo(studentInfo);
    return _.get(latestDeclaredPlan, 'plan.institution', '');
};

export const parseMajorFromStudentInfo = (studentInfo: StudentInfo | null): string => {
    const latestDeclaredPlan = parseLatestDeclaredPlanFromStudentInfo(studentInfo);
    return _.get(latestDeclaredPlan, 'plan.transcriptDescription', 'N/A') || 'N/A';
};

// dirty patch to use courseNumber, dont know why we're calling it catalogId above, but probably good reason
export const parseClassFromStudentEnrollmentHistory = (enrollment: any): string => {
    // @ts-ignore
    /* eslint-disable camelcase */
    const { subject, courseNumber, descr } = enrollment.class;
    // @ts-ignore
    /* eslint-disable camelcase */
    // NOTE: two spaces after course number, which will require setting white-space: pre
    return `${subject} ${courseNumber}:  ${descr}`;
};

export const parseClassWithNonLectureComponent = (enrollment: StudentEnrollment): string => {
    const component = enrollment.class.courseComponent;
    let componentName = '';
    if (component && component !== 'LEC') {
        componentName = ` (${CourseComponentName[component]})`;
    }
    return `${parseClassFromStudentEnrollmentHistory(enrollment)}${componentName}`;
};

export const parseCourseClassShortNameWithMode = (courseClass: CourseClassExtract | 'NoReplacement'): string => {
    if (courseClass === 'NoReplacement' || courseClass.courseId === 'NoReplacement') return 'No Replacement';
    return `${parseClassSection(courseClass)}, ${(InstructionModeName[courseClass.sectionMode!] || 'n/a').replace(/([()])/g, '')}`;
};

export const parseCourseClassShortNameWithModeAndInstitution = (courseClass: CourseClassWithGroupData | UnavailableCourseClass | 'NoReplacement'): string => {
    if (courseClass === 'NoReplacement' || courseClass.classId === 'NoReplacement') return 'No Replacement';
    return `[${courseClass.institution.substr(0, 3)}] ${courseClass.subject} ${courseClass.courseNumber}, ${(InstructionModeName[courseClass.sectionMode!] || 'n/a').replace(/([()])/g, '')}`;
};

export const parseClassMeetingTimes = (courseClass: CourseClassExtract | 'NoReplacement'): string => {
    if (courseClass === 'NoReplacement' || courseClass.courseId === 'NoReplacement') return '';
    const meetingTimes = courseClass.sectionMode === 'OA' ? '' : courseClass.meetings.map((m: any) => parseMeetingPattern(m)).join(' ');
    return meetingTimes;
};

export const parseClassSchedule = (courseClass: CourseClassExtract | 'NoReplacement'): string => {
    if (courseClass === 'NoReplacement' || courseClass.courseId === 'NoReplacement') return '';

    const classSection = parseClassSection(courseClass);
    const sectionModeName = InstructionModeSurveyFormat[courseClass.sectionMode as any];
    const meetingTimes = parseClassMeetingTimes(courseClass);

    return [ classSection, sectionModeName, meetingTimes ].filter(o => o).join(', ');
};

export const convertQueryResultToClassWithGroupData = (data: CourseClassQueryResult): CourseClassWithGroupData => {
    // separate out courseOffer from the rest of the properties so that we can then flatten it in the return.
    const { courseOffer, ...otherProps } = data;
    console.log('convertQueryResultToClassWithGroupData data: ', data);
    return {
        ...courseOffer,
        ...otherProps,
    };
};

export const generateCourseClassExtract = (courseGroup: CourseGroup, courseClass: CourseClass): CourseClassExtract => {
    return {
        attributes: courseClass.attributes,

        courseTitle: courseGroup.courseTitle,
        requirementDesignation: courseGroup.requirementDesignation,
        units: courseGroup.units,

        classId: courseClass.classId,
        classSection: courseClass.classSection,
        courseId: courseGroup.courseId,
        courseNumber: courseGroup.courseNumber,
        endDate: courseClass.endDate,
        institution: courseGroup.institution,
        instructionMode: courseClass.instructionMode,
        meetings: courseClass.meetings,
        requirementGroupDetails: courseGroup.requirementGroupDetails,
        requirementGroup: courseGroup.requirementGroup,

        sectionMode: courseClass.sectionMode,
        sessionCode: courseClass.sessionCode,
        startDate: courseClass.startDate,
        subject: courseGroup.subject,
        academicGroup: courseGroup.academicGroup,
        modifiers: courseClass.modifiers,

        courseComponent: courseClass.courseComponent,
    };
};

export const generateSelectedSectionExtract = (courseClass: CourseClassExtract): SelectedSectionExtract => {
    return {
        attributes: courseClass.attributes,
        classId: courseClass.classId,
        classSection: courseClass.classSection,
        courseComponent: courseClass.courseComponent,
        endDate: courseClass.endDate,
        meetings: courseClass.meetings,
        startDate: courseClass.startDate,
    };
};

export const generateSelectedCourseExtract = (selectedCourse: SelectedCourse): SelectedCourseExtract => {
    return {
        tsAdded: selectedCourse.tsAdded,
        disabledBy: selectedCourse.disabledBy,
        courseId: selectedCourse.courseId,
        courseNumber: selectedCourse.courseNumber,
        courseTitle: selectedCourse.courseTitle,
        institution: selectedCourse.institution,
        sectionMode: selectedCourse.sectionMode,
        selectedSections: selectedCourse.selectedSections.map((o) => generateSelectedSectionExtract(o)),
        subject: selectedCourse.subject,
    };
};

// hashSelectedCourse concats the courseId and all selected section Ids, so that we can distinguish when
// a student's cart changed if they chose a different section
export const hashSelectedCourse = (selectedCourse: SelectedCourseExtract): string => {
    const sectionIds = selectedCourse.selectedSections.map(o => o.classId);
    const sortedIds = _.orderBy(sectionIds);
    return `${selectedCourse.courseId}_${sortedIds.join('_')}`;
};

const DAY_IN_MINUTES = 1440;

export const convertAvailableTimesToMap = (availableTimes: AvailableTimesSelection[]): any => {
    const days: {[key:string]: string[]} = {};
    availableTimes.forEach(times => {
        // add 1 for Monday start week
        const day = Math.floor(times.start / DAY_IN_MINUTES) + 1;

        const hourStart = (times.start / DAY_IN_MINUTES % 1) * 24;
        const hourEnd = (times.end / DAY_IN_MINUTES % 1) * 24;

        const hourStartDate = roundToNearestMinutes(addMinutes(startOfToday(), hourStart * 60), {nearestTo: 15});
        const hourEndDate = roundToNearestMinutes(addMinutes(startOfToday(), hourEnd * 60), {nearestTo: 15});
        const formattedSlot = `${format(hourStartDate, 'h:mma')} - ${format(hourEndDate, 'h:mma')}`;

        if (days[day]) {
            days[day].push(formattedSlot);
        } else {
            days[day] = [ formattedSlot ];
        }
    });

    return days;
};

export const DayName: any = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
    7: 'Sun',
};

export const convertDayToInt = (day: MeetingDay): number => {
    switch (day) {
        case 'mon':
            return 1;
        case 'tue':
            return 2;
        case 'wed':
            return 3;
        case 'thu':
            return 4;
        case 'fri':
            return 5;
        case 'sat':
            return 6;
        case 'sun':
            return 7;
        default:
            throw new Error(`Invalid Day: ${day}`);
    }
};

export const parseMeetingDay = (meetingPattern: CourseClassMeeting): MeetingDay | null => {
    const days: MeetingDay[] = [
        'mon',
        'tue',
        'wed',
        'thu',
        'fri',
        'sat',
        'sun',
    ];
    for (let i = 0; i < days.length; i++) {
        if (meetingPattern[days[i]] === 'Y') {
            return days[i];
        }
    }
    return null;
};

export const parseAvailableSeats = (courseClass: CourseClass): number => {
    const diff = (courseClass.enrollmentCap || 0) - (courseClass.enrollmentTotal || 0);
    return diff <= 0 ? 1 : diff;
};

export const parseInProgress = (enrollment: StudentEnrollment): boolean => enrollment.strm === currentTerm.id && enrollment.statusReason === 'ENRL';

export const parseCurrentEnrollments = (enrollments: StudentEnrollment[]): StudentEnrollment[] => {
    return _.filter(enrollments, parseInProgress);
};

export const parseGradeOrInProgress = (enrollment: StudentEnrollment): string => {
    if (enrollment.grade) return enrollment.grade;

    if (parseInProgress(enrollment)) return 'In-Progress';

    return '';
};

export const parseRequisiteType = (type: RequisiteType): string => {
    switch (type) {
        case 'CO':
            return 'Co-Requisite';
        case 'PRE':
            return 'Pre-Requisite';
        case '':
            return '';
    }
};

export const convertToRequisiteCourse = (raw: CourseListItem): RequisiteCourse => {
    if (raw.courseOffer) {
        return {
            courseId: raw.courseId,
            subject: raw.courseOffer.subject,
            courseNumber: raw.courseOffer.courseNumber,
            requisiteType: ''
        };
    } else {
        return {
            courseId: raw.courseId,
            subject: raw.subject,
            courseNumber: raw.courseNumber,
            requisiteType: ''
        };
    }
};

export const formatRequirementGroupLineType = (type: RequirementGroupLineType): string => {
    switch (type) {
        case 'CRSE':
            return 'Course';
        case 'COND':
            return 'Condition';
        case 'CRSW':
            return 'Wild Card';
        case 'RQ':
            return 'Requirement';

    }
};

export const formatGradePoints = (gradePoints = 0): string => {
    if (gradePoints < 1 || gradePoints > 4) return '';

    switch (gradePoints) {
        case 1.00:
            return 'D';
        case 1.70:
            return 'C-';
        case 1.75:
            return 'C-';
        case 2.00:
            return 'C';
        case 2.20:
            return 'C+';
        case 2.30:
            return 'C+';
        case 2.33:
            return 'C+';
        case 2.50:
            return 'B-';
        case 2.60:
            return 'B-';
        case 2.70:
            return 'B-';
        case 2.75:
            return 'B-';
        case 3.00:
            return 'B';
        case 3.20:
            return 'B+';
        case 3.30:
            return 'B+';
        case 3.70:
            return 'A-';
        case 4.00:
            return 'A';
        default:
            return '';
    }
};

export const formatGPA = (gpa = 0): string => {
    return gpa.toFixed(2);
};

export const formatConstraint = (c: SingleOrArray<any> = ''): string => {
    return Array.isArray(c) ? c.join(', ') : c;
};

export const ScribeQualifierLabels: Record<ScribeQualifier, string> = {
    minCredits: 'Minimum Credits',
    minResCredits: 'Minimum Credits in Residence ',
    maxTransferCredits: 'Max Transfer Credits',
    minGpa: 'Minimum GPA',
    maxClasses: 'Max Classes',
    classes: 'Classes',
    blockTypeMajor: 'Major Block',
    blockId: 'Block Id',
    maxCredits: 'Max Credits',
    group: 'Group',
    subBlock: 'Subblock',
    credits: 'Credits',
    undefined: 'Undefined',
};

export const formatQualifierLabel = (rule: ScribeRule): string => {
    if (rule.qualifier === 'classes' && rule.range === 1) return 'Class';
    return ScribeQualifierLabels[rule.qualifier];
};

export const formatRange = (rule: ScribeRule): string => {
    if (rule.qualifier === 'minGpa') {
        return Array.isArray(rule.range) ? `${rule.range.map(r => r.toString(2))}` : rule.range.toFixed(2);
    } else {
        return `${rule.range}`;
    }
};
export const formatRuleExtract = (rule: ScribeRule): string => {
    const label = formatQualifierLabel(rule);
    let inConstraint = rule.constraint || rule.qualifier === 'group'
        ? ` in: ${formatConstraint(rule.constraint)}` : '';
    inConstraint = inConstraint.trimEnd();

    const withAttributes = rule.attributes
        ? ` with: ${formatConstraint(rule.attributes)}` : '';
    return `${formatRange(rule)} ${label}${inConstraint}${withAttributes}`;
};
