import React, { useMemo, useState } from 'react';
import FilterRow from '@/components/dashboard/components/filter-bar/FilterRow';
import {
    AddQueryButtonStyle,
    RemoveQueryButtonStyle,
    ResultsFilterBarContainerStyle,
    SubmitFilterButtonStyle
} from '@/components/dashboard/styled';
import tw from 'twin.macro';
import ExportResultsButton from '@/components/dashboard/ExportResultsButton';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
    ColumnArg,
    ColumnFilter, FiltersParams,
    generateEmptyColumnFilter,
    GroupOpEnum,
    groupOperators
} from '@/components/dashboard/components/filter-bar/filterParams';

interface Props {
    onSubmit: (params: FiltersParams) => void;
}

export default ({ onSubmit }: Props) => {

    const [ rows, setRows ] = useState<ColumnFilter[]>([
        generateEmptyColumnFilter()
    ]);

    const [ grouping, setGrouping ] = useState<GroupOpEnum>(GroupOpEnum.AND);

    const addQuery = () => {
        const updated = _.cloneDeep(rows);
        updated.push(generateEmptyColumnFilter());
        setRows(updated);
    };
    const removeQuery = (filterId: string) => {
        const updated = _.cloneDeep(rows);
        _.remove(updated, o => o._uid === filterId);
        setRows(updated);
    };

    const updateFilter = (filterId: string, props: Omit<ColumnFilter, '_uid'>) => {
        const updated = _.cloneDeep(rows);
        const i = updated.findIndex(o => o._uid === filterId);
        if (i !== -1) {
            updated[i] = {
                _uid: updated[i]._uid,
                ...props
            };
            console.log('updateFilter updated[i]: ', updated[i]);
        }
        setRows(updated);
    };

    const filters = useMemo<ColumnArg[]>(() => {
        const updatedRows: ColumnArg[] = [];
        rows.forEach(o => {
            if (o.column) {
                updatedRows.push({
                    key: o.column.value,
                    value: o.value
                });
            }
        });
        return updatedRows;
    }, [ rows ]);

    const params = useMemo<FiltersParams>(() => {
        return {
            filters,
            grouping
        };
    }, [ filters, grouping ]);


    const handleSubmit = () => {
        console.log('handleSubmit params: ', params);
        onSubmit(params);
    };

    return (
        <ResultsFilterBarContainerStyle>
            <div css={tw`w-full`}>
                <h4 css={tw`text-gray-900 font-normal text-xl mb-2`}>Filter</h4>

                <div css={tw`mb-3`}>
                    <select value={grouping} onChange={e => setGrouping(e.target.value as GroupOpEnum)}>
                        {groupOperators.map(o => {
                            return (
                                <option value={o.value} key={o.value}>{o.label}</option>
                            );
                        })}
                    </select>
                </div>


                {
                    rows.map((o, i) => {
                        return (
                            <div css={tw`flex items-center mb-3`} key={o._uid}>
                                <FilterRow filter={o} onChange={(field, value) => {
                                    updateFilter(o._uid, { column: field, value });
                                }}
                                />
                                { i !== 0 ?
                                    <RemoveQueryButtonStyle onClick={() => removeQuery(o._uid)}>
                                        <FontAwesomeIcon css={tw`pointer-events-none`} icon={faTimes}/>
                                    </RemoveQueryButtonStyle>
                                    : null
                                }
                            </div>
                        );
                    })
                }

                <div css={tw`mt-2 mb-3`}>
                    <AddQueryButtonStyle onClick={addQuery}>
                        <FontAwesomeIcon css={tw`pointer-events-none`} icon={faPlusCircle}/>
                    </AddQueryButtonStyle>
                </div>

                {/* <FilterRow onChange={onFilterChange}/> */}

                <div css={tw`mt-3 flex flex-row justify-between`}>
                    <SubmitFilterButtonStyle
                        // disabled={!allowedToSubmit}
                        type={'button'}
                        onClick={handleSubmit}
                    >
                        Submit
                    </SubmitFilterButtonStyle>

                    <ExportResultsButton params={params}/>
                </div>
            </div>




        </ResultsFilterBarContainerStyle>
    );
};
