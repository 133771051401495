import React from 'react';
import styled, { css, FlattenInterpolation, ThemedStyledProps } from 'styled-components/macro';
import tw from 'twin.macro';

type ButtonColor = 'green' | 'red' | 'secondary';
type ButtonSize = 'small' | 'base' | 'large';

export interface ButtonProps {
    size?: ButtonSize;
    color?: ButtonColor;
}

const getColorStyles = (color?: ButtonColor) => {
    switch (color) {
        case 'red':
            return css`
                ${tw`bg-red-500 border-red-500`};
                
                &:not(:disabled):hover, &:not(:disabled):active {
                    ${tw`bg-red-600 border-red-700 text-white`};
                }
            `;
        case 'secondary':
            return css`
                ${tw`text-gray-900 bg-gray-100`};
                
                &:not(:disabled):hover, &:not(:disabled):active {
                    ${tw`border-gray-500 text-gray-900 shadow-sm`};
                }
            `;
        case 'green':
        default:
            return css`
                color: rgb(64,111,53);
                background-color: rgb(222,235,181);
            `;
    }
};

const getSizeStyles = (size?: ButtonSize) => {
    switch (size) {
        case 'small':
            return tw`px-2 py-1 text-sm`;
        case 'large':
            return tw`px-6 py-3`;
        case 'base':
        default:
            return tw`px-5 py-2`;
    }
};


const ButtonStyle: FlattenInterpolation<ThemedStyledProps<ButtonProps, any>> = css<ButtonProps>`
    // ${tw`transition-colors duration-150 text-white border rounded`};
    
    ${tw`rounded border-none cursor-pointer hover:opacity-75 bg-accentgray text-light shadow`}

    ${props => getColorStyles(props.color)}
    ${props => getSizeStyles(props.size)}
    
    &:disabled {
        ${tw`opacity-75`};
        cursor: not-allowed;
    }
`;

const ButtonComponent = styled('button') <ButtonProps>`
    ${ButtonStyle}
`;

type ButtonType = React.FC<ButtonProps & React.ButtonHTMLAttributes<any>>;

const Button: ButtonType = props => <ButtonComponent {...props}>{props.children}</ButtonComponent>;

export { Button, ButtonStyle };


// const Button = styled.button`
//     ${tw`rounded border-none cursor-pointer hover:opacity-75 px-5 py-2 bg-accentgray text-light shadow`}

//     color: rgb(64,111,53);
//     background-color: rgb(222,235,181);
// `;

// export { Button };
