import React from 'react';
import moment from 'moment';
import { CalendarWrapperStyle, CalendarContainerStyle } from './styled';
import AvailableTimes from 'react-available-times';

import './timepicker.css';

export const MONDAY = moment.utc('2020-11-16T00:00:00Z');

export type AvailableTimesSelection = {
    start: number;
    end: number;
};

interface Props {
    onChange: (selections: AvailableTimesSelection[]) => void;
    selections: AvailableTimesSelection[];
}

export default ({ onChange, selections }: Props) => {
    return (
        <div className="all-available">
            <CalendarWrapperStyle>
                <CalendarContainerStyle>
                    <AvailableTimes
                        timeConvention="12h"
                        weekStartsOn="monday"
                        onChange={(selections: AvailableTimesSelection[]) => {
                            onChange(selections);
                        }}
                        initialSelections={selections}
                        // height={'500px'}
                        // height={'calc(100vh - 400px'}
                        height={'60vh'}
                        recurring
                        availableHourRange={{ start: 7, end: 22 }}
                    />
                </CalendarContainerStyle>
            </CalendarWrapperStyle>
        </div>
    );
};
