import React from 'react';
import { ShoppingCartTableStyle } from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { hashSelectedCourse, parseRoom, parseUnits } from '@/api/transformers';
import ClassLink from '@/components/shopping-cart/ClassLink';
import ClassTimes from '@/components/shopping-cart/ClassTimes';
import { SelectedCourse } from '@/api/types';

interface Props {
    items: SelectedCourse[];
    requiredClassIds?: string[];
    onRemoveClass: (classId: string) => void;
}

export default ({ items, requiredClassIds = [], onRemoveClass }: Props) => {
    const handleRemove = (courseIdHash: string) => {
        onRemoveClass(courseIdHash);
    };

    return (
        <ShoppingCartTableStyle>
            <thead>
                <tr>
                    <th><span>Delete</span></th>
                    <th><span>Class</span></th>
                    <th><span>Days/Times</span></th>
                    <th><span>Location</span></th>
                    <th><span>Units</span></th>
                </tr>
            </thead>
            <tbody>
                {items.flatMap(selectedCourse => {
                    const hashId = hashSelectedCourse(selectedCourse);
                    return selectedCourse.selectedSections.map((section, sectionIndex) => {
                        return (
                            <tr key={section.classId}>
                                <td>
                                    {
                                        (requiredClassIds.indexOf(hashId) === -1) ?
                                            <a onClick={() => handleRemove(hashId)}>
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </a>
                                            : null
                                    }
                                </td>
                                <td>
                                    <ClassLink item={section}/>
                                </td>
                                <td>
                                    <ClassTimes item={section}/>
                                </td>
                                <td><span>{selectedCourse.sectionMode === 'P' ? parseRoom(section) : 'Online'}</span></td>
                                <td><span>{sectionIndex === 0 ? parseUnits(selectedCourse) : ''}</span></td>
                            </tr>
                        );
                    });
                })}
            </tbody>
        </ShoppingCartTableStyle>
    );
};
