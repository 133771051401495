import React from 'react';
import Pagination, { ReactJsPaginationProps } from 'react-js-pagination';
import styled from 'styled-components/macro';
import tw from 'twin.macro';

const Container = styled.div`
    & .pagination {
        ${tw`flex items-center list-none`};

        & > li {
            ${tw`text-gray-500 border border-solid border-gray-100 px-3 py-1 rounded text-sm transition-colors duration-150`};
            
            a {
                ${tw`no-underline text-blue-600`}
            }

            &.disabled, &.disabled > a {
                ${tw`opacity-50 cursor-not-allowed`};
            }

            &:not(.disabled):hover {
                ${tw`border-gray-300 bg-gray-100 text-gray-900 cursor-pointer`};
            }

            &.active, &.active:hover {
                ${tw`border-blue-600 bg-blue-600 text-white!`};
                a {
                    ${tw`text-white!`};
                }
            }
        }
    }
`;

const Paginator = (props: ReactJsPaginationProps) => (
    <Container>
        <Pagination {...props}/>
    </Container>
);

export default Paginator;
