import React, { useMemo, useState } from 'react';
import { PreferencesContainerStyle, PageControlsStyle } from './styled';
import AllAvailableTimesPicker, { AvailableTimesSelection } from './AllAvailableTimesPicker';
import { Button, Spinner } from '../common';
import tw from 'twin.macro';
import { useHistory } from 'react-router-dom';
import { useGetSimResults, useUpdateSimResults } from '@/api/simulation/SimulationAPI';
import { formatISO } from 'date-fns';
import { activeTerm, termOverrideName } from '@/constants/terms';
import { mergeContinuousTimes } from '@/store/simulation/simulationUtils';

export default () => {
    const history = useHistory();

    const [ loading, setLoading ] = useState<boolean>(false);
    const simResults = useGetSimResults();
    const updateSimResults = useUpdateSimResults();

    const [ _availableTimes, _setAvailableTimes ] = useState<AvailableTimesSelection[]>(simResults.availableTimes);

    const onBack = () => {
        history.push('/consent');
    };

    const onSubmit = () => {
        setLoading(true);
        updateSimResults({
            availableTimes: mergeContinuousTimes(_availableTimes),
            availableTimesSubmittedTs: formatISO(new Date())
        })
            .then(() => {
                setTimeout(() => {
                    history.push('/simulation-notice');
                }, 200);
            })
            .catch(err => console.error(err))
            .then(() => {
                setLoading(false);
            });
    };

    const allowSubmit = useMemo<boolean>(() => {
        return !!(_availableTimes.length);
    }, [ _availableTimes ]);

    return (
        <PreferencesContainerStyle>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />
            <p>For the purpose of this simulation, please pretend it is right before the {activeTerm.season} {activeTerm.year} semester has begun and answer all questions as though you were about to register for {activeTerm.season} {activeTerm.year} courses.</p>
            <p css={tw`mb-3`}>Please select all days/times (Eastern time) during which you could attend scheduled classes if necessary during the {termOverrideName}.</p>

            <AllAvailableTimesPicker
                onChange={(selections: AvailableTimesSelection[]) => {
                    setTimeout(() => {
                        _setAvailableTimes(selections);
                    }, 0);
                }}
                selections={simResults.availableTimes}
            />

            <PageControlsStyle>
                <div className="content-container">
                    <Button type="button" onClick={onBack}>Back</Button>
                    <Button disabled={!allowSubmit} type="button" onClick={onSubmit}>Next</Button>
                </div>
            </PageControlsStyle>

        </PreferencesContainerStyle>
    );
};
