import React, { useEffect, useRef, useState } from 'react';
import tw from 'twin.macro';
import { Field, FieldArray, Formik, FormikHelpers, FormikProps } from 'formik';
import {
    AccordionStyle, ClassSearchContainerStyle,
    ClassSearchFormStyle,
    FormButtonStyle,
    InputContainerStyle
} from '@/components/class-search/styled';
import SelectInput from '@/components/class-search/SelectInput';
import institutions from '@/constants/institutions';
import { defaultTerm, termOverrideName } from '@/constants/terms';
import {
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading, AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import NumberSearchInput from '@/components/class-search/NumberSearchInput';
import requirementDesignations from '@/constants/requirementDesignations';
import instructionModes from '@/constants/instructionModes';
import TimeSearchInput from '@/components/class-search/TimeSearchInput';
import { CriteriaType, DayCriteriaOptions, DayCriteriaType } from '@/components/class-search/SearchCriteria';
import daysOfWeek from '@/constants/daysOfWeek';
import {
    AcademicCareer,
    CourseAttribute, CourseComponent,
    Day,
    InstitutionType, InstructionMode,
    Option,
    RequirementDesignation,
    SessionType
} from '@/api/types';
import TextInput from '@/components/class-search/TextInput';
import { Spinner } from '@/components/common';
import _ from 'lodash';
import graphClient from '@/api/graph';
import getSubjectsByInstitutionAndTerm, { rawDataToSubjectOptions } from '@/api/graphql/queries/getSubjectsByInstitutionAndTerm';
import { useStoreActions, useStoreState } from '@/store';
import { formatISO } from 'date-fns';
import CourseNumberSearchInput from '@/components/class-search/CourseNumberSearchInput';

export interface ClassSearchCriteriaValues {
    // internal ref used to detect presence of search results when handling "Back to Search Results".
    // This is exclusively set by Search store reducer.
    // If none exists (likely due to direct visit to class details url page), then we will navigate to Class Search Form
    ts?: string;
    institution: InstitutionType | '';
    termId: string;
    subjectId: string;
    courseNumberCriteria: CriteriaType | '';
    courseNumber: string;
    academicCareer: AcademicCareer | '';
    courseAttribute: CourseAttribute | '';
    courseAttributeValue: string;
    requirementDesignation: RequirementDesignation | '';
    showOpenClassesOnly?: boolean;
    sessionType: SessionType | '';
    instructionMode: InstructionMode | '';
    startTimeCriteria: CriteriaType | '';
    startTime: [string?, string?];
    endTimeCriteria: CriteriaType | '';
    endTime: [string?, string?];
    daysOfWeekCriteria: DayCriteriaType | '';
    daysOfWeek: Day[];
    classNumber: string;
    courseKeyword: string;
    maxUnitsCriteria: CriteriaType | '';
    maxUnits: string;
    minUnitsCriteria: CriteriaType | '';
    minUnits: string;
    courseComponent: CourseComponent | '';
    campus: string;
    location: string;
    // instructorLastName: string;

    // internal filter not exposed in the form but used to hide specific courses when simulation deems it unavailable.
    _courseId?: string;
    _courseIdCriteria: CriteriaType | '';
}

interface Props {
    onSubmit: (filters: ClassSearchCriteriaValues) => void;
}
export default ({ onSubmit }: Props) => {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>('');

    const formikRef = useRef<FormikProps<ClassSearchCriteriaValues>>(null);

    const filters = useStoreState(state => state.search.filters);
    const subjects = useStoreState(state => state.search.subjects);
    const { setFilters, setSubjects } = useStoreActions(actions => actions.search);

    useEffect(() => {
        setTimeout(() => {
            if (filters.subjectId) {
                formikRef?.current?.setFieldValue('subjectId', filters.subjectId);
                // setFilters(null);
            }

            // there isn't actually any SelectOption for 'Synchronous', but we allow setting it for one of the simulation responses
            if (filters.instructionMode === 'S') {
                formikRef?.current?.setFieldValue('instructionMode', '');
                setFilters({
                    ...filters,
                    instructionMode: ''
                });
            }
        }, 50);
    }, []);

    useEffect(() => {
        if (filters.instructionMode === 'S') {
            formikRef?.current?.setFieldValue('instructionMode', '');
            setFilters({
                ...filters,
                instructionMode: ''
            });
        }
    }, [ filters.instructionMode ]);

    const handleSubmit = (values: ClassSearchCriteriaValues, { setSubmitting }: FormikHelpers<ClassSearchCriteriaValues>) => {
        setError('');
        const updatedFilters = _.pickBy(values, (v, k) => {
            if (Array.isArray(v)) {
                return v.length;
            }
            return _.identity(v);
        }) as ClassSearchCriteriaValues;

        if (!updatedFilters.institution) {
            setError('Please specify an institution.');
            return;
        } else if (!updatedFilters.termId) {
            setError('Please specify a term.');
            return;
        }

        // require at least 2 filters BEYOND the institution and term check
        if (_.size(updatedFilters) < 4) {
            setError('Please specify additional selection criteria to narrow your search.');
            return;
        }

        // TODO: validation to ensure endRange is greater than startRange. May want to set this up
        // on the input component level but whatever works
        if (updatedFilters.startTimeCriteria === CriteriaType.Between && updatedFilters.startTime) {

        }
        // TODO: also validation between the actual meeting times: startTime and endTime

        updatedFilters.ts = formatISO(new Date());

        setFilters(updatedFilters);

        onSubmit(updatedFilters);
    };

    const onInstitutionChange = (institution: string) => {
        setLoading(true);
        setSubjects([]);

        formikRef?.current?.setFieldValue('subjectId', '');

        graphClient.query({
            query: getSubjectsByInstitutionAndTerm,
            variables: {
                institution,
                term: defaultTerm.id
            }
        })
            .then(results => {
                console.log('subjects results: ', results);
                const updatedSubjects = rawDataToSubjectOptions(results.data.items);
                setSubjects(updatedSubjects);

                setTimeout(() => {
                    setLoading(false);
                }, 50);
            })
            .catch(err => {
                console.error('err: ', err);
                setTimeout(() => {
                    setLoading(false);
                }, 50);
            });
    };

    const onSubjectChange = () => {
        formikRef?.current?.setFieldValue('courseNumber', '');
    };

    return (
        <ClassSearchContainerStyle>
            {/* <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} /> */}

            {error &&
            <div css={[ tw`w-full mt-4 bg-red-200 p-2 text-red-600 rounded mb-3` ]}>
                <span css={[ tw`font-medium` ]}>Error:</span> {error}
            </div>
            }

            <div css={tw`bg-blue-600 py-1 px-1 md:px-4`}>
                <h3 css={tw`text-lg font-normal text-white`}>Search for Classes</h3>
            </div>

            <Formik
                innerRef={formikRef}
                initialValues={filters}
                onSubmit={handleSubmit}
            >
                {({ setValues, values, isSubmitting, handleReset, submitForm }) => (
                    <ClassSearchFormStyle>

                        <SelectInput
                            label="Institution"
                            fieldName="institution"
                            options={institutions}
                            onInputChange={onInstitutionChange}
                        />

                        <InputContainerStyle className={'hardcoded'}>
                            <label>Term</label>
                            {/* <p>{defaultTerm.name}</p> */}
                            <Field name="term" as="select">
                                <option value={defaultTerm.id}>{termOverrideName}</option>
                            </Field>
                        </InputContainerStyle>

                        <p css={tw`my-3`}>Select at least 2 search criteria. Select Search to view your search results.</p>

                        <AccordionStyle
                            allowMultipleExpanded
                            allowZeroExpanded
                            // DEV: ensure group2 is removed from preExpanded
                            preExpanded={[ 'group1', ]}
                        >
                            <AccordionItem uuid="group1">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {
                                                ({ expanded }) => (expanded ?
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                    : <FontAwesomeIcon icon={faAngleRight} />
                                                )
                                            }

                                        </AccordionItemState>
                                        <h3>Class Search</h3>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div css={tw`px-2`}>

                                        <SelectInput
                                            disabled={loading}
                                            label="Subject"
                                            fieldName="subjectId"
                                            options={subjects}
                                            onInputChange={onSubjectChange}
                                        />

                                        <CourseNumberSearchInput
                                            label="Course Number"
                                            fieldName="courseNumber"
                                            criteriaFieldName="courseNumberCriteria"
                                        />

                                        {/* <SelectInput
                                                    label="Course Career"
                                                    fieldName="academicCareer"
                                                    options={getCareersByInstitution(values.institution)}
                                                    dependentOn="institution"
                                                /> */}

                                        {/* <SelectInput
                                                    label="Course Attribute"
                                                    fieldName="courseAttribute"
                                                    options={courseAttributes}
                                                />

                                                <SelectInput
                                                    hideIfNoOptions={true}
                                                    label="Course Attribute Value"
                                                    fieldName="courseAttributeValue"
                                                    dependentOn="courseAttribute"
                                                    options={getAttributeValuesByGroup(values.courseAttribute)}
                                                /> */}

                                        <SelectInput
                                            label="Requirement Designation"
                                            fieldName="requirementDesignation"
                                            options={requirementDesignations}
                                        />

                                        {/* <CheckboxInput
                                                    label="Show Open Classes Only"
                                                    fieldName="showOpenClassesOnly"
                                                /> */}
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>


                            <AccordionItem uuid="group2">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {
                                                ({ expanded }) => (expanded ?
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                    : <FontAwesomeIcon icon={faAngleRight} />
                                                )
                                            }

                                        </AccordionItemState>
                                        <h3>Additional Search Criteria</h3>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div css={tw`px-2`}>
                                        {/* <SelectInput
                                                    label="Session"
                                                    fieldName="sessionType"
                                                    options={sessionTypes}
                                                /> */}

                                        <SelectInput
                                            label="Mode of Instruction"
                                            fieldName="instructionMode"
                                            options={instructionModes}
                                            tooltip={'On campus courses have required course meetings on campus\nOnline asynchronous courses have no scheduled class meetings.\nOnline synchronous courses have required scheduled online class meetings on a platform like Zoom.\nOnline includes both synchronous and asynchronous courses.'}
                                        />

                                        <TimeSearchInput
                                            label="Meeting Start Time"
                                            fieldName="startTime"
                                            criteriaFieldName="startTimeCriteria"
                                        />

                                        <TimeSearchInput
                                            label="Meeting End Time"
                                            fieldName="endTime"
                                            criteriaFieldName="endTimeCriteria"
                                        />

                                        <SelectInput
                                            label="Days of Week"
                                            fieldName="daysOfWeekCriteria"
                                            options={DayCriteriaOptions}
                                        />

                                        <InputContainerStyle>
                                            <FieldArray
                                                name="daysOfWeek"
                                                render={(arrayHelpers) => {
                                                    return (
                                                        <div css={tw`flex flex-wrap`}>
                                                            {daysOfWeek.map((dayOption: Option<Day>) => {
                                                                return (
                                                                    <label css={tw`mr-2 mb-2 w-auto!`} key={dayOption.value}>
                                                                        <input
                                                                            css={tw`mr-1`}
                                                                            name="daysOfWeek"
                                                                            type="checkbox"
                                                                            value={dayOption.value}
                                                                            checked={values.daysOfWeek?.includes(dayOption.value)}
                                                                            onChange={e => {
                                                                                if (e.target.checked) {
                                                                                    arrayHelpers.push(dayOption.value);
                                                                                } else {
                                                                                    const idx = values.daysOfWeek.indexOf(dayOption.value);
                                                                                    arrayHelpers.remove(idx);
                                                                                }
                                                                            }}
                                                                        />
                                                                        <span>{dayOption.label}</span>
                                                                    </label>
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </InputContainerStyle>

                                        {/* <TextInput
                                                    label="Class Nbr"
                                                    fieldName="classNumber"
                                                    tooltip="example: 1136"
                                                /> */}
                                        <TextInput
                                            label="Course Keyword"
                                            fieldName="courseKeyword"
                                            tooltip="example: statistics"
                                        />

                                        <NumberSearchInput
                                            label="Minimum Units"
                                            fieldName="minUnits"
                                            criteriaFieldName="minUnitsCriteria"
                                        />

                                        <NumberSearchInput
                                            label="Maximum Units"
                                            fieldName="maxUnits"
                                            criteriaFieldName="maxUnitsCriteria"
                                        />

                                        {/* <SelectInput
                                                    label="Course Component"
                                                    fieldName="courseComponent"
                                                    options={courseComponents}
                                                /> */}

                                        {/* Hacky not implemented, we're just mimicing institution */}
                                        {/* <SelectInput */}
                                        {/*    label="Campus" */}
                                        {/*    fieldName="campus" */}
                                        {/*    options={values.institution ? [ { value: values.institution, label: values.institution } ] : []} */}
                                        {/* /> */}
                                    </div>

                                </AccordionItemPanel>
                            </AccordionItem>
                        </AccordionStyle>

                        {error &&
                        <div css={[ tw`w-full mt-4 bg-red-200 p-2 text-red-600 rounded mb-2` ]}>
                            <span css={[ tw`font-medium` ]}>Error:</span> {error}
                        </div>
                        }

                        <div css={tw`flex justify-end mt-4`}>
                            <FormButtonStyle type="button" onClick={() => {
                                setFilters(null);
                                setValues(filters);
                            }} backgroundColor="#eee"
                            >Clear
                            </FormButtonStyle>

                            <div style={{
                                width: '100px'
                            }}
                            >
                                {
                                    loading ?

                                        <Spinner size={'base'} />
                                        : <FormButtonStyle type="button" onClick={submitForm} color="white" backgroundColor="rgba(49,130,206)">Search</FormButtonStyle>
                                }
                            </div>

                        </div>

                    </ClassSearchFormStyle>
                )}
            </Formik>
        </ClassSearchContainerStyle>
    );
};
