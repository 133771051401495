import { Option, InstructionMode } from '@/api/types';
// Original
// export const InstructionModeName: any = {
//     H: 'Hybrid',
//     p: 'In Person',
//     O: 'Online',
// };

export const InstructionModeName: any = {
    P: 'On Campus',
    OA: 'Online (Asynchronous)',
    OS: 'Online (Synchronous)',
    O: 'Online',
    S: 'Synchronous',
};

export const InstructionModeSurveyFormat: any = {
    P: 'on campus',
    OA: 'online asynchronous',
    OS: 'online synchronous',
    O: 'Online',
    S: 'Synchronous',
};

const instructionModes: Option<InstructionMode>[] = [
    {
        value: 'P',
        label: 'On Campus',
        description: 'No course content or assignments delivered online.'
    },
    {
        value: 'OA',
        label: 'Online (Asynchronous)',

    },
    {
        value: 'OS',
        label: 'Online (Synchronous)',
    },
    {
        value: 'O',
        label: 'Online (Asynchronous or Synchronous)',
    }
];

export default instructionModes;
