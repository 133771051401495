import { SimulationResults } from '@/api/simulation/SimulationAPI';
import _ from 'lodash';
import { formatISO } from 'date-fns';
import { formatShortTimestamp } from '@/components/simulation/SimulationDebugger';

const minDate = formatISO(new Date(-8640000000000000));

const timeRelatedFields = [
    {
        name: 'Available Times',
        key: 'availableTimesSubmittedTs'
    },
    {
        name: 'Consent',
        key: 'consentTs'
    },
    {
        name: 'Payment',
        key: 'paymentInfoSubmittedTs'
    },
    {
        name: 'Screener',
        key: 'screener.registerIntentTs',

    },
    {
        name: 'Summer Register',
        key: 'screener.summerRegisterIntentTs',
    },
    {
        name: 'Survey - Work',
        key: 'survey.work.submittedTs',
    },
    {
        name: 'Survey - Family',
        key: 'survey.family.submittedTs',
    },
    {
        name: 'Survey - Time1',
        key: 'survey.timeQuality.submittedTs',
    },
    {
        name: 'Survey - Time2',
        key: 'survey.timeQuantity.submittedTs',
    },
    {
        name: 'Survey - Life1',
        key: 'survey.lifeEvents.submittedTs',
    },
    {
        name: 'Survey - Life2',
        key: 'survey.lifeRatings.submittedTs',
    },
    {
        name: 'Survey - Major',
        key: 'survey.major.submittedTs',
    },
    {
        name: 'Survey - Demographics',
        key: 'survey.demographics.submittedTs',
    },
    {
        name: 'Simulation - Started',
        key: 'data.startedTs',
    },
    {
        name: 'Simulation - Completed',
        key: 'data.completedTs',
    },
];


export const formatLastInteractionRaw = (s = ''): any => {
    if (!s) return '';
    const split = s.split('__');
    if (split.length === 2) {

        const ts = formatShortTimestamp(split[0]);
        const key = `__${split[1]}`;
        const name = InteractionName[key];
        if (name && ts) {
            return `${ts} (${name})`;
        }
    }
    return '';
};

export const getLastInteraction = (results: SimulationResults): any => {

    const parsedTimes = timeRelatedFields.map(field => {

        return {
            ...field,
            ts: _.get(results, field.key, minDate) || minDate,
        };
    });

    const ordered = _.orderBy(parsedTimes, [ 'ts' ], [ 'desc' ]);

    const lastInteraction = ordered[0];
    if (lastInteraction && lastInteraction.ts !== minDate) {
        return `${formatShortTimestamp(lastInteraction.ts)} (${lastInteraction.name})`;
    }
    return '';
};

export const InteractionName: {[key in string]: string} = {
    __available_times_submitted_ts: 'Available Times',
    __consent_ts: 'Consent',
    __payment_info_submitted_ts: 'Payment',
    __screener_registerIntentTs: 'Screener',
    __screener_summerRegisterIntentTs: 'Summer Register',
    __survey_work_submittedTs: 'Survey - Work',
    __survey_family_submittedTs: 'Survey - Family',
    __survey_timeQuality_submittedTs: 'Survey - Time1',
    __survey_timeQuantity_submittedTs: 'Survey - Time2',
    __survey_lifeEvents_submittedTs: 'Survey - Life1',
    __survey_lifeRatings_submittedTs: 'Survey - Life2',
    __survey_major_submittedTs: 'Survey - Major',
    __survey_demographics_submittedTs: 'Survey - Demographics',
    __data_startedTs: 'Simulation - Started',
    __data_completedTs: 'Simulation - Completed',
};
