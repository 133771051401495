import { useMemo } from 'react';
import { CourseClassExtract, SelectedCourse } from '@/api/types';
import { useGetSimResults } from '@/api/simulation/SimulationAPI';
import { secondChanceOfferVar } from '@/api/graph';
import { useReactiveVar } from '@apollo/client';

export default (): SelectedCourse | null => {
    const simulationResults = useGetSimResults();
    const secondChanceOffer = useReactiveVar(secondChanceOfferVar);

    const currentlyReplacing = useMemo<SelectedCourse | null>(() => {
        if (secondChanceOffer) {
            return secondChanceOffer;

        } else if (simulationResults?.data?.currentStage?.unavailableClass && !simulationResults.data.currentStage.replacementResponse) {
            return simulationResults.data.currentStage.unavailableClass;

        } else {
            return null;
        }
    }, [ simulationResults, simulationResults?.data?.currentStage ]);

    return currentlyReplacing;
};
