import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { CardBodyStyle, CardHeaderStyle, CardStyle } from '../../styled';
import { IntegerFormatter } from '@/utils/utilities';
import MetricSummary from '../../styled/MetricSummary';
import { AggregateStats } from '@/api/simulation/getResultsAggregate';
import SizeDistributionsChart from './SizeDistributionsChart';
import LegendLabel from '@/components/dashboard/styled/LegendLabel';
import { chartColors } from '@/components/dashboard/chartUtils';

interface Props {
    currentCartSizes: AggregateStats[];
    submittedCartSizes: AggregateStats[];
    counts: AggregateStats[];
}

export default ({ currentCartSizes, submittedCartSizes, counts: rawCounts }: Props) => {
    const parsedCounts = useMemo<{total: number; submittedCart: number}>(() => {

        return {
            total: rawCounts.find(o => o.id === 'countTotal')?.count || 0,
            submittedCart: rawCounts.find(o => o.id === 'countSubmittedCart')?.count || 0
        };
    }, [ rawCounts ]);

    return (
        <CardStyle>
            <CardHeaderStyle>Cart Size Distribution</CardHeaderStyle>
            <CardBodyStyle>
                <div css={tw`flex`}>
                    <SizeDistributionsChart
                        currentCartSizes={currentCartSizes}
                        submittedCartSizes={submittedCartSizes}
                    />
                    <div css={tw`flex justify-center w-full`}>


                        <div css={tw`flex flex-col justify-between`}>
                            <div>
                                <MetricSummary
                                    label="Total Carts*"
                                    value={IntegerFormatter.format(parsedCounts.submittedCart)}
                                />
                                <div>
                                    <div css={tw`mb-2`}>
                                        <LegendLabel
                                            label="Originally Submitted Size"
                                            shape="rect"
                                            color={chartColors[0].faded}
                                            fontStyle={tw`text-base whitespace-no-wrap`}
                                        />
                                    </div>
                                    <div>
                                        <LegendLabel
                                            label="Current Size"
                                            shape="rect"
                                            color={chartColors[0].normal}
                                            fontStyle={tw`text-base whitespace-no-wrap`}
                                        />
                                    </div>
                                </div>

                            </div>




                            <p>*Excludes {parsedCounts.total - parsedCounts.submittedCart} carts that have not started the simulation.</p>
                        </div>
                    </div>
                </div>
            </CardBodyStyle>
        </CardStyle>
    );
};
