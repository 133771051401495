import React, { useEffect, useMemo, useState } from 'react';
import tw, { TwStyle } from 'twin.macro';
import { parseCourseClassShortNameWithMode } from '@/api/transformers';
import { ShoppingCartWidgetStyle } from './styled';
import { SelectedCourse } from '@/api/types';
import { CSSObject } from 'styled-components';
import _ from 'lodash';

interface Props {
    unavailable: SelectedCourse | null;
    items: SelectedCourse[];
}

interface TruncatableTextProps {
    text: string;
    textStyle: TwStyle;
}
export const TruncatableText = ({ text, textStyle }: TruncatableTextProps) => {
    const [ shouldTruncate, setShouldTruncate ] = useState<boolean>(true);

    const style = useMemo<CSSObject>(() => {
        const truncate: CSSObject = shouldTruncate ? {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        } : {};

        const merged = {
            ...textStyle,
            ...truncate
        };
        return merged;

    }, [ textStyle, shouldTruncate ]);

    return (
        <p onClick={() => setShouldTruncate(!shouldTruncate)} style={style}>{text}</p>
    );
};

export default ({ items, unavailable }: Props) => {

    const [ shoppingCartDiff, setShoppingCartDiff ] = useState<{
        unavailableItems: SelectedCourse[],
        remaining: SelectedCourse[],
    }>({
        unavailableItems: [],
        remaining: [],
    });

    useEffect(() => {
        const updatedCart: SelectedCourse[] = _.cloneDeep(items);
        let removedItems: SelectedCourse[] = [];

        console.log('unavailable: ', unavailable);
        if (unavailable) {
            removedItems = _.remove(updatedCart, o => {

                for (const unavailableSection of unavailable.selectedSections) {
                    for (const section of o.selectedSections) {

                        if (unavailableSection.classId === section.classId) {
                            return true;
                        }
                    }
                }
                return false;
            });

            console.log('removedItems: ', removedItems);
        }
        setShoppingCartDiff({
            unavailableItems: removedItems,
            remaining: updatedCart
        });
    }, [ items, unavailable ]);

    return (
        <ShoppingCartWidgetStyle>
            <p css={tw`underline mb-1`}>Shopping Cart</p>
            <div css={tw`flex flex-col`}>
                {/* { */}
                {/*    shoppingCartDiff.unavailableItems.map(o => { */}
                {/*        return ( */}
                {/*            <div key={o.classId}> */}
                {/*                <TruncatableText textStyle={tw`text-sm text-red-500 line-through`} text={`${parseCourseClassShortNameWithMode(o)} (${o.courseTitle})`}/> */}
                {/*            </div> */}
                {/*        ); */}
                {/*    }) */}
                {/* } */}

                {
                    unavailable ?
                        unavailable.selectedSections.map(o => (
                            <div key={o.classId}>
                                <TruncatableText textStyle={tw`text-sm text-red-500 line-through`} text={`${parseCourseClassShortNameWithMode(o)} (${unavailable.courseTitle})`}/>
                            </div>
                        ))
                        : null
                }
                {
                    shoppingCartDiff.remaining.length ?
                        (shoppingCartDiff.remaining as Array<SelectedCourse>).map(course => {
                            return (
                                <div key={course.courseId}>
                                    {
                                        course.selectedSections.map(section => (
                                            <TruncatableText key={section.classId} textStyle={tw`text-sm`} text={`${parseCourseClassShortNameWithMode(section)} (${course.courseTitle})`}/>
                                        ))
                                    }

                                </div>
                            );
                        })
                        : <p>No items in shopping cart.</p>

                }
            </div>
        </ShoppingCartWidgetStyle>


    );
};
