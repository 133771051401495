import React, { useState } from 'react';
import { Button, Modal, Spinner, TextArea, Input } from '@/components/common';
import tw from 'twin.macro';
import styled from 'styled-components/macro';
import submitFeedback from '@/api/submitFeedback';

interface Props {
    isVisible: boolean;
    onClose: () => void;
}

const MessageTextAreaStyle = styled(TextArea)`
    font-family: 'Brandon';
`;

const MIN_MESSAGE_LENGTH = 10;

export default ({ isVisible, onClose }: Props) => {

    const [ message, setMessage ] = useState<string>('');
    const [ preferredContact, setPreferredContact ] = useState<string>('');
    const [ errorMsg, setErrorMsg ] = useState<string>('');
    const [ loading, setLoading ] = useState<boolean>(false);

    const [ sent, setSent ] = useState<boolean>(false);

    const resetState = () => {
        setMessage('');
        setPreferredContact('');
        setErrorMsg('');
        setSent(false);
        setLoading(false);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setErrorMsg('');
        console.log('handleSubmit');
        if (message.length < MIN_MESSAGE_LENGTH) {
            setErrorMsg('Please provide more details.');
            return;
        }

        setLoading(true);

        setTimeout(() => {
            submitFeedback(message, preferredContact)
                .then(() => setSent(true))
                .catch(() => setErrorMsg('Unable to submit feedback.'))
                .then(() => setLoading(false))
            ;

        }, 1800);
    };

    const handleClose = () => {
        onClose();
        resetState();
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    };

    return (
        <Modal visible={isVisible} onDismissed={handleClose} dismissable={!loading}>

            {
                !sent ?
                    <>
                        {/* <h3 css={tw`font-normal text-xl text-cunyblue mb-3`}>Report Technical Problems</h3> */}
                        <h3 css={tw`font-normal text-xl text-cunyblue mb-3`}>Submit Feedback</h3>

                        <form onSubmit={handleSubmit}>
                            <p css={tw`text-left mt-4 mb-2`}>Please briefly describe the technical problem you’re running into with the simulation.</p>
                            <MessageTextAreaStyle
                                placeholder={'Please describe the issue.'}
                                value={message}
                                onChange={handleChange}
                                required
                                minLength={10}
                                rows={10}
                            />

                            <p css={tw`text-left mt-4 mb-2`}>To follow up regarding this issue, we will contact you at the email where we originally contacted you about the survey. If you have a different email or phone number you would prefer for us to use to reach you regarding this issue, let us know here.</p>
                            <Input
                                type={'text'}
                                placeholder={'Optional Contact'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPreferredContact(e.target.value)}
                                value={preferredContact}
                                maxLength={70}
                                css={tw`w-1/2 block`}
                            />

                            <p css={tw`text-red-500 mt-4 mb-4`}>{errorMsg}</p>

                            <div css={tw`mt-4 flex justify-center`} style={{height: '35px'}}>
                                {
                                    loading ?
                                        <Spinner size={'base'} />
                                        : <Button css={tw`w-full text-base`} type="submit">Submit</Button>
                                }
                            </div>
                        </form>
                    </>
                    :
                    <div>
                        <h3 css={tw`font-normal text-lg text-dark mt-4 mb-3`}>Message Sent.</h3>
                        <Button css={tw`w-full text-base`} type="button" onClick={handleClose}>Close</Button>
                    </div>
            }


        </Modal>
    );
};
