import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { CardBodyStyle, CardHeaderStyle, CardStyle } from '../../styled';
import { IntegerFormatter } from '@/utils/utilities';
import DailyVisitsChart from './DailyResponsesChart';
import MetricSummary from '../../styled/MetricSummary';
import { AggregateStats, ResultsAggregateResponse } from '@/api/simulation/getResultsAggregate';
import _ from 'lodash';

interface Summary {
    totalCount: number;
    weightedAverage: number;
}

interface Props {
    data: AggregateStats[];
}

export default ({ data }: Props) => {

    const [ summary, setSummary ] = useState<{cleaned: AggregateStats[], withoutDates: number, totalCount: number}>({
        cleaned: [],
        withoutDates: 0,
        totalCount: 0
    });

    useEffect(() => {
        const cleaned = _.cloneDeep(data);
        // remove null days (before we implemented firstLoggedIn)
        const removed = _.remove(cleaned, o => !o.id);

        const totalCount = _.sumBy(cleaned, 'count');
        const withoutDates = _.sumBy(removed, 'count');

        setSummary({
            cleaned,
            withoutDates,
            totalCount
        });

    }, [ data ]);

    return (
        <CardStyle>
            <CardHeaderStyle>Daily New Responses</CardHeaderStyle>
            <CardBodyStyle>
                <div css={tw`flex`}>
                    <DailyVisitsChart
                        data={data}
                    />
                    <div css={tw`flex justify-center w-full`}>
                        <div css={tw`flex flex-col`}>
                            <MetricSummary
                                label="Total Responses*"
                                value={IntegerFormatter.format(summary.totalCount)}
                            />

                            <p>*Excludes {summary.withoutDates} responses without First Logged In Date</p>
                        </div>

                    </div>
                </div>
            </CardBodyStyle>
        </CardStyle>
    );
};
