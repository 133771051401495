import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { AgreeChoices } from '@/components/survey/surveyQuestions';

interface Props {
    statement: {
        id: string;
        label: string;
    };
    onChange: (e: React.ChangeEvent<HTMLElement>) => void;
    selected: string;
}

const RowStyle = styled.tr`
    @media (max-width: 649px) {
        td:not(:first-child) {
            display: none;
        }
    }
    @media (min-width: 650px) {
        select {
            display: none;
        }
    }
`;

export default ({ statement, onChange, selected }: Props) => {

    return (
        <RowStyle>
            <td>
                <span css={tw`block`}>{statement.label}</span>

                <select css={tw`block mx-2 mt-2 mb-4`} name={statement.id} required={true} onChange={onChange} value={selected}>
                    <option value=""/>
                    {
                        AgreeChoices.map(choice => (
                            <option key={choice.value} value={choice.value}>{choice.label}</option>
                        ))
                    }
                </select>
            </td>
            <td><input required type="radio" name={statement.id} checked={selected === '0'} value="0" onChange={onChange}/></td>
            <td><input required type="radio" name={statement.id} checked={selected === '1'} value="1" onChange={onChange}/></td>
            <td><input required type="radio" name={statement.id} checked={selected === '2'} value="2" onChange={onChange}/></td>
            <td><input required type="radio" name={statement.id} checked={selected === '3'} value="3" onChange={onChange}/></td>
            <td><input required type="radio" name={statement.id} checked={selected === '4'} value="4" onChange={onChange}/></td>
            <td><input required type="radio" name={statement.id} checked={selected === '5'} value="5" onChange={onChange}/></td>
        </RowStyle>
    );
};
