import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { Option } from '@/api/types';
import { OperatorEnum, textOperators } from '@/components/dashboard/components/filter-bar/filterParams';
import { TextColumnValueInputStyle } from '@/components/dashboard/components/filter-bar/styled';

interface Props {
    value: string;
    column: Option | null;
    onChange: (op_value: string) => void;
}
export default ({ onChange, column, value }: Props) => {

    const [ operator, setOperator ] = useState<OperatorEnum>(OperatorEnum.Equal);
    const [ _value, _setValue ] = useState<string>(value);

    useEffect(() => {
        let s = '';
        if (_value) {
            s = `${operator}__${_value}`;
        }
        onChange(s);
    }, [ operator, _value ]);

    useEffect(() => {
        setOperator(OperatorEnum.Equal);
        _setValue('');
    }, [ column ]);

    return (
        <TextColumnValueInputStyle>
            <div>
                <select css={tw`text-sm`} value={operator} onChange={e => setOperator(e.target.value as OperatorEnum)}>
                    {textOperators.map(o => (<option key={o.value} value={o.value}>{o.label}</option>))}
                </select>
            </div>

            <div css={tw`ml-4`}>
                <input css={tw`text-sm`} type={'text'} value={_value} onChange={e => _setValue(e.target.value)}/>
            </div>

        </TextColumnValueInputStyle>
    );
};
