import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import axios, { AxiosError } from 'axios';

const FrameworkUpdateWatcher = () => {
    const [ update, setUpdate ] = useState(false);

    useEffect(() => {
        const tag = Array.from(document.getElementsByTagName('script')).find(tag => {
            return tag.src.match(/main\.(\w{8})\.js$/);
        });

        // This shouldn't happen, but protect against it anyways.
        if (!tag) return;

        // Every 5 minutes check to see if that resource is available. If not, show the banner
        // and stop trying to check for its presense. The scripts are deleted from the server
        // when a new deplay is performed.
        const interval = setInterval(() => {
            axios.head(tag.src + '?timestamp=' + (new Date()).getTime(), {
                timeout: 10 * 1000,
            })
                .catch((error: AxiosError) => {
                    if (error.response?.status === 404) {
                        setUpdate(true);
                        clearInterval(interval);
                    }
                });
        }, 5 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        update ?
            <div
                onClick={() => {
                    window.location.reload();
                }}
                css={tw`cursor-pointer transition-colors text-xl bg-yellow-500 hover:bg-yellow-600 text-white border-b-2 border-yellow-600 p-3 text-center`}
            >
                An update is available, click to reload.
            </div>
            :
            null
    );
};

export default FrameworkUpdateWatcher;
