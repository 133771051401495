import { parse, format, formatISO, setYear } from 'date-fns';
import { activeTerm } from '@/constants/terms';

export const formatCourseDate = (s: string): string => {
    let date = parse(s, 'yyyy-MM-dd', new Date());
    date = setYear(date, parseInt(activeTerm.year));
    return format(date, 'MM/dd/yyyy');
};

export const convertTimeSlotToTimestamp = (s: string): string => {
    const date = parse(s, 'h:mm aa', new Date(1900, 0, 1));
    return formatISO(date);
};
