import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { InputContainerStyle } from './styled';
import { Option } from '@/api/types';
import TooltipIcon from '@/components/common/TooltipIcon';

interface Props {
    label: string;
    fieldName: string;
    options: Option[];
    dependentOn?: string;
    labelColor?: string;
    labelFontWeight?: number;
    allowEmptyOption?: boolean;
    disabled?: boolean;
    onInputChange?: (value: string) => void;
    // if true, returns null if options is empty
    hideIfNoOptions?: boolean;
    tooltip?: string;
}

export default ({ disabled = false, label, fieldName, options, dependentOn = 'not_in_use', labelColor, labelFontWeight, allowEmptyOption = true, onInputChange, hideIfNoOptions = false, tooltip }: Props) => {

    const {
        values: { [dependentOn]: dependentFieldValue, [fieldName]: inputValue },
        setFieldValue
    } = useFormikContext<any>();

    // // Clear input value if options change for whatever reason.
    useEffect(() => {
        // Hack to prevent dev autofill from clearing
        if (dependentOn !== 'not_in_use') {
            // console.log(`re-rendering ${fieldName}`);
            setFieldValue(fieldName, '');
        }
    }, [ dependentFieldValue ]);

    useEffect(() => {
        if (onInputChange) {
            onInputChange(inputValue);
        }
    }, [ inputValue ]);

    if (hideIfNoOptions && !options.length) return null;

    return (
        <InputContainerStyle labelColor={labelColor} labelFontWeight={labelFontWeight}>

            <label>
                {label}
                {
                    tooltip ?
                        // <InputTooltipStyle/>
                        <TooltipIcon message={tooltip}/>
                        : null
                }
            </label>


            <Field disabled={disabled} name={fieldName} as="select">
                {
                    allowEmptyOption ?
                        <option value=""/>
                        : null
                }
                {
                    options.map((o: Option) => (
                        <option key={o.value} value={o.value}>
                            {o.label}
                        </option>
                    ))
                }
            </Field>

        </InputContainerStyle>
    );
};
