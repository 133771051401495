import React, { useState } from 'react';
import _ from 'lodash';
import tw from 'twin.macro';
import { useStoreActions } from '@/store';
import styled from 'styled-components/macro';
import {
    formatSelectedCourseAndSectionsWithInstitution,
    parseClassSectionWithInstitution,
} from '@/api/transformers';
import { StageData, TreeData } from '@/store/simulation/types';
import { ConditionNameByStage } from '@/constants/simulationConditions';
import { useHistory } from 'react-router-dom';
import AvailableTimesDebugger from '@/components/simulation/AvailableTimesDebugger';
import { format } from 'date-fns';
import { CourseClassExtract, Option, SelectedCourseExtract } from '@/api/types';
import { SimulationResults, useGetSimResults } from '@/api/simulation/SimulationAPI';
import { Spinner } from '../common/Spinner';
import SurveyDebugger from '@/components/simulation/SurveyDebugger';
import { useRole } from '@/api/AuthService';
import useRestartSimulation from '@/components/simulation/useRestartSimulation';
import useClearSimulationData from '@/components/simulation/useClearSimulationData';
import useClearAllData from '@/components/simulation/useClearAllData';
import useUndoLastResponse from '@/components/simulation/useUndoLastResponse';
import ScreenerDebugger from '@/components/simulation/ScreenerDebugger';

export const DividerStyle = styled.div`
    border-top: 1px solid #ccc;
    ${tw`mb-3 mt-1`}
`;
export const SimulationDebuggerStyle = styled.div`
    border-top: 1px solid #ddd;
    ${tw`mt-12 pt-4`}
    h4 {
        ${tw`font-normal text-xl mb-1 underline`}
    }
    h5 {
        ${tw`font-normal text-xl`}
    }
`;

export const TimestampStyle = styled.span`
    ${tw`text-sm italic mr-3`}
`;

export const formatShortTimestamp = (ts = ''): string => {
    if (!ts) return '';
    try {
        return format(new Date(ts), 'M/d/yy h:mm:ssaaa');
    } catch (err) {
        return '';
    }
};

export const formatTimestamp = (ts = ''): string => {
    if (!ts) return '';
    try {
        return format(new Date(ts), 'MM-dd-yy HH:mm:ss');
    } catch (err) {
        return '';
    }
};

export const formatSkippableDict = (o: any) => {
    if (!o) return 'undefined';

    if (o === 'SKIPPED') {
        return <p>Skipped</p>;
    }
    return _.map(_.pickBy(o, (v, k) => _.identity(v)), (value, key) => {
        return (
            <p key={key}>{_.capitalize(key)}: {value}</p>
        );
    });
};

export const formatSelectedCheckboxes = (o:any, choices: Option[]) => {
    return _.map(_.pickBy(o, (v, k) => _.identity(v)), (value, key) => {

        const choice: Option | undefined = _.find(choices, {value: key});
        const label = choice?.label || key;

        return <p key={key}>•{label}</p>;
    });
};

const renderEncounter = (stage: StageData) => {
    if (!stage.enteredTs) return null;
    try {
        return (
            <div key={stage.id}>
                <p><TimestampStyle>{formatTimestamp(stage.enteredTs)}</TimestampStyle>({stage.id}) - {ConditionNameByStage[stage.id]}</p>
                <div css={tw`px-4`}>
                    {
                        stage.initialResponse ?
                            <>
                                <p><TimestampStyle>{formatTimestamp(stage.initialResponse.submittedTs)}</TimestampStyle>Response: {_.words(stage.initialResponse.response!).join(' ')}</p>
                                {/* <p>Require Multiple Changes: {stage.initialResponse.requireMultipleChanges.toString()}</p> */}
                            </>
                            : null
                    }

                    {/* { */}
                    {/*    stage.initialResponse?.requireMultipleChanges && stage.replacementResponse?.resubmittedCart ? */}
                    {/*        <> */}
                    {/*            <p><TimestampStyle>{formatTimestamp(stage.replacementResponse.submittedTs)}</TimestampStyle>Resubmitted Cart:</p> */}
                    {/*            {renderCartItems(stage.replacementResponse.resubmittedCart)} */}
                    {/*        </> */}
                    {/*        : null */}
                    {/* } */}

                    {
                        stage.replacementResponse ?
                            <p><TimestampStyle>{formatTimestamp(stage.replacementResponse.submittedTs)}</TimestampStyle>Replaced With: {formatSelectedCourseAndSectionsWithInstitution(stage.replacementResponse.replacementClass)}</p>
                            : null
                    }
                    {
                        stage.reasonResponse ?
                            <p><TimestampStyle>{formatTimestamp(stage.reasonResponse.submittedTs)}</TimestampStyle>Reasons for choosing: {stage.reasonResponse.answer.map(s => _.words(s).join(' ')).join(', ')}</p>
                            : null
                    }
                    {
                        stage.negativeExpectations ?
                            <div>
                                <p><TimestampStyle>{formatTimestamp(stage.negativeExpectations.submittedTs)}</TimestampStyle>Negative Expectations: </p>
                                <div css={tw`ml-3`}>
                                    {
                                        stage.negativeExpectations.questionsShown?.map(id => {

                                            // @ts-ignore
                                            const q = stage.negativeExpectations[id];
                                            if (q) {
                                                return (
                                                    <p key={id}>{id}: {`${JSON.stringify(q)}`}</p>
                                                );
                                            }
                                            return null;
                                            // if (typeof q === 'string') {
                                            //     <p>{id}: {q}</p>
                                            // }

                                        })
                                    }
                                </div>
                            </div>
                            : null
                    }

                    {
                        stage.additionalChangesResponse ?
                            <p><TimestampStyle>{formatTimestamp(stage.additionalChangesResponse.submittedTs)}</TimestampStyle>Requested Additional Changes: {stage.additionalChangesResponse.answer === 'Y' ? 'Yes' : 'No'}</p>
                            : null
                    }
                    {
                        stage.replacementMissingCorequisites ?
                            <p>Replacement submitted without corequisites</p>
                            : null
                    }
                </div>

            </div>
        );
    } catch (err) {
        console.error(err);
        return null;
    }

};

const renderTreeInfo = (treeProps: TreeData) => {
    if (treeProps.status === 'Skipped') {
        return (
            <DividerStyle>
                <h4>Tree {treeProps.id}</h4>
                <p css={tw`italic`}>Skipped</p>
            </DividerStyle>
        );
    } else {
        // if not skipped and originalCourse hasn't been picked, then we can assume
        // student hasn't reached this tree yet
        if (!treeProps.originalCourse) return null;

        return (
            <DividerStyle>
                <h4>Tree {treeProps.id}</h4>
                <p>Original Course: <span css={tw`ml-2`}>{formatSelectedCourseAndSectionsWithInstitution(treeProps.originalCourse)}</span></p>
                {
                    treeProps.originalReason ?
                        <p><TimestampStyle>{formatTimestamp(treeProps.originalReason.submittedTs)}</TimestampStyle>Reasons for choosing: {treeProps.originalReason.answer.map(s => _.words(s).join(' ')).join(', ')}</p>
                        : null
                }

                <p>
                    <span css={tw``}>Unavailable Classes:</span>
                    {
                        treeProps.unavailableClasses.map((c, i) => {
                            return (
                                <span key={(c.courseId + i)} css={tw`mx-2`}>• {`${formatSelectedCourseAndSectionsWithInstitution(c)}`}</span>
                            );
                        })
                    }
                </p>

                {
                    treeProps.completedStages.map(encountered => {
                        return renderEncounter(encountered);
                    })
                }
            </DividerStyle>
        );
    }
};

const renderCartItems = (items: SelectedCourseExtract[] = []) => {
    return items.map(c => {
        return (
            <div key={c.courseId}>
                <span css={tw`mx-2`}>{formatSelectedCourseAndSectionsWithInstitution(c)}</span>
                <TimestampStyle>Added {formatTimestamp(c.tsAdded || '')}</TimestampStyle>
            </div>
        );
    });
};

// const renderPaymentInfo = (props: any) => {
//
// };

const parseCurrentStatus = (simResults: SimulationResults) => {
    let status;
    if (simResults.data.completedTs) {
        status = `Completed ${formatTimestamp(simResults.data.completedTs)}`;
    } else {
        status = simResults.data.startedTs && simResults.data.currentStage ? `In-Progress (${simResults.data.currentStage.id})` : 'Not Started';
    }
    return status;
};

export default () => {
    const history = useHistory();
    const [ loading, setLoading ] = useState<boolean>(false);

    const role = useRole();

    const simResults = useGetSimResults();
    const restartSimulation = useRestartSimulation();
    const clearSimulationData = useClearSimulationData();
    const clearAllData = useClearAllData();
    const undoLastResponse = useUndoLastResponse();

    const { setFilters } = useStoreActions(actions => actions.search);

    if (role !== 'dev') {
        return null;
    }

    // if (!config.debug) {
    //     return null;
    // }

    if (!simResults.data) return null;

    return (
        <SimulationDebuggerStyle>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />

            <div css={tw`flex justify-between items-center mb-3`}>
                <h3 css={tw`font-normal text-2xl`}>Simulation Debugger</h3>

                <div css={tw`flex items-center`}>
                    <button css={tw`cursor-pointer mx-1`} onClick={() => {
                        setLoading(true);

                        restartSimulation()
                            .then(() => {
                                setLoading(false);
                            })
                            .catch(err => {
                                setLoading(false);
                                console.error(err);
                            });
                    }}
                    >Restart Simulation
                    </button>

                    <button css={tw`cursor-pointer mx-1`} onClick={() => {
                        setLoading(true);

                        // wipe existing filters
                        setFilters(null);

                        clearSimulationData()
                            .then(() => {
                                setLoading(false);
                                history.push('/cart');
                            })
                            .catch(err => {
                                setLoading(false);
                                console.error(err);
                            });
                    }}
                    >Clear Simulation (before submitting cart)
                    </button>

                    <button css={tw`cursor-pointer mx-1`} onClick={() => {
                        setLoading(true);

                        // wipe existing filters
                        setFilters(null);

                        clearAllData()
                            .then(() => {
                                setLoading(false);

                            })
                            .catch(err => {
                                setLoading(false);
                                console.error(err);
                            })
                            .then(() => {
                                history.push('/screener');
                            });
                    }}
                    >Clear All Data (back to screener)
                    </button>
                </div>
            </div>


            <div css={tw`flex items-center mb-3`}>
                <h5 css={tw`mr-2`}>Status:</h5>
                <p css={tw`italic`}>{parseCurrentStatus(simResults)}</p>
            </div>

            {
                simResults.data.currentStage ?
                    <>
                        {renderEncounter(simResults.data.currentStage)}
                        {
                            simResults.data.currentStage.initialResponse ?
                                <a css={tw`text-link cursor-pointer`} onClick={() => undoLastResponse()}>Undo last response</a>
                                :
                                null
                        }
                    </>

                    : null
            }

            {/* <DividerStyle> */}
            {/*    <h4>Unavailable Classes (across all Trees)</h4> */}
            {/*    { */}
            {/*        unavailableClasses.map(c => { */}
            {/*            return ( */}
            {/*                <span key={c.classId} css={tw`mx-2`}>{`${parseClassSectionWithInstitution(c)} (${c.sectionMode})`}</span> */}
            {/*            ); */}
            {/*        }) */}
            {/*    } */}
            {/* </DividerStyle> */}

            <ScreenerDebugger screener={simResults.screener}/>

            <AvailableTimesDebugger/>

            <DividerStyle>
                <h4>Current Cart</h4>
                {renderCartItems(simResults.shoppingCart)}
            </DividerStyle>

            <DividerStyle>
                <div css={tw`flex items-center`}>
                    <h4 css={tw`mr-2`}>Original Cart</h4>
                    <TimestampStyle>Submitted: {formatTimestamp(simResults.data.startedTs)}</TimestampStyle>
                </div>


                {renderCartItems(simResults.data.originalSubmittedCart)}
            </DividerStyle>

            {
                simResults.data.startedTs ?
                    <>
                        {renderTreeInfo(simResults.data.tree1)}
                        {renderTreeInfo(simResults.data.tree2)}
                        {renderTreeInfo(simResults.data.tree3)}
                    </>
                    : null
            }

            <SurveyDebugger/>

            {
                simResults.paymentInfo ?
                    <DividerStyle>
                        <div css={tw`flex items-center`}>
                            <h4 css={tw`mr-2`}>Payment Info</h4>
                            <TimestampStyle>Submitted: {formatTimestamp(simResults.paymentInfo.submitted)}</TimestampStyle>
                        </div>

                        {
                            _.map(_.pickBy(simResults.paymentInfo, (v, k) => k !== 'submitted' && _.identity(v)), (value, key) => {
                                return (
                                    <p key={key}>{_.capitalize(key)}: {value}</p>
                                );
                            })
                        }
                    </DividerStyle>
                    : null
            }

        </SimulationDebuggerStyle>
    );
};
