import { SimulationResults } from '@/api/simulation/SimulationAPI';
import { atLeastOneLifeEvent, IsNo, IsYes } from '@/components/survey/surveyQuestions';
import { isCohortAllowed, isInProgress } from '@/store/simulation/simulationUtils';

export const getCurrentScreenForSimulation = (simResults: SimulationResults): string => {
    if (!isCohortAllowed(simResults.cohort)) {
        return '/screener';
    }

    if (IsNo(simResults.screener?.registerIntent)) {
        return '/screener';
    }

    if (IsYes(simResults.screener?.hasExternalTransfer)) {
        return '/screener-transfer';
    }

    if (!simResults.data?.completedTs) {
        if (!simResults.screener?.registerIntent) {
            return '/screener';
        }
        if (!simResults.screener?.hasExternalTransfer) {
            return '/screener-transfer';
        }
        if (!simResults.consentTs) {
            return '/consent';
        }
        if (IsYes(simResults.screener.registerIntent) && !simResults.screener.summerRegisterIntentTs) {
            return '/summer';
        }
        if (!simResults.availableTimesSubmittedTs) {
            return '/availability';
        }


        if (!isInProgress(simResults)) {
            return '/search';
        } else {

            if (simResults.data?.currentStage?.initialResponse &&
                !simResults.data.currentStage.replacementResponse
            ) {
                return '/replacement-search';
            }

            return '/sim';
        }


    // after completing simulation
    } else {
        if (!simResults.survey?.work?.submittedTs) {
            return '/survey/work';
        }
        if (!simResults.survey?.family?.submittedTs) {
            return '/survey/family';
        }
        if (!simResults.survey?.timeQuality?.submittedTs) {
            return '/survey/time1';
        }
        if (!simResults.survey?.timeQuantity?.submittedTs) {
            return '/survey/time2';
        }
        if (!simResults.survey?.lifeEvents?.submittedTs) {
            return '/survey/life1';
        }
        if (
            (simResults.survey?.lifeEvents && atLeastOneLifeEvent(simResults.survey.lifeEvents)) &&
            !simResults.survey?.lifeRatings?.submittedTs) {
            return '/survey/life2';
        }
        if (!simResults.survey?.major?.submittedTs) {
            return '/survey/major';
        }
        if (!simResults.survey?.demographics?.submittedTs) {
            return '/survey/demographics';
        }

        if (!simResults.paymentInfoSubmittedTs) {
            return '/payment';
        }

        return '/completed';
    }





};
