export const studentGroups: {[key: string]: string} = {
    HTR01_SPH4: '1 Yr DI/IP Prog Only',
    CSI01_2YLM: '2 Yr Deg and Cred Earned < Lim',
    BCC01_AD24: '24 CR OPTION FOR ADMISSIONS',
    QCC01_AD24: '24 CR OPTION FOR ADMISSIONS',
    BMC01_C224: '24CC Passed CUNY Test',
    BMC01_CC24: '24CC Student',
    BMC01_24L6: '24CC less than 6 Cr.',
    BMC01_24M6: '24CC more than 6 Cr.',
    BKL01_2UPC: '2nd Degree, 2 Upper Cc Reqd',
    BKL01_2NCO: '2nd Degree, No Core Required',
    BKL01_TRN3: '2nd sem. Transfers 60 creds or',
    BKL01_TRN2: '2nd term TRNS under 45 credits',
    CSI01_30KD: '30,000 Degree',
    BCC01_243D: '3D Direct Loan',
    LEH01_243D: '3D Direct Loan',
    SLU01_243D: '3D Direct Loan',
    HTR01_4TEP: '4 Track Teacher Education Prog',
    CSI01_4YLM: '4 Yr Deg and Cred Earned < Lim',
    BAR01_BM41: '4+1 Bachelor/Master Program',
    BKL01_BM41: '4+1 Bachelor/Master Program',
    CSI01_BM41: '4+1 Bachelor/Master Program',
    CTY01_BM41: '4+1 Bachelor/Master Program',
    HTR01_BM41: '4+1 Bachelor/Master Program',
    JJC01_BM41: '4+1 Bachelor/Master Program',
    LEH01_BM41: '4+1 Bachelor/Master Program',
    MED01_BM41: '4+1 Bachelor/Master Program',
    QNS01_BM41: '4+1 Bachelor/Master Program',
    SLU01_BM41: '4+1 Bachelor/Master Program',
    SOJ01_BM41: '4+1 Bachelor/Master Program',
    SPH01_BM41: '4+1 Bachelor/Master Program',
    SPS01_BM41: '4+1 Bachelor/Master Program',
    YRK01_BM41: '4+1 Bachelor/Master Program',
    HTR01_5TEP: '5 Track Teacher Education Prog',
    HTR01_6TEP: '6 Track Teacher Education Prog',
    LAG01_JSTA: '6-wk Jump Start',
    CTY01_HSRH: 'A P Randolph High School',
    JJC01_ACE0: 'ACE Eligible',
    LEH01_ACE0: 'ACE Eligible',
    NYT01_ACE0: 'ACE Eligible',
    LAG01_AFWG: 'ACE First in the World Grant',
    LAG01_AHSE: 'ACE High School Equivalency',
    SPS01_ACEM: 'ACE Mentee',
    LAG01_ASG: 'ACE SEAMOS Grant',
    SPS01_ACES: 'ACE Scholar',
    MEC01_ACE0: 'ACE Students',
    LAG01_TELC: 'ACE TELC Non Crd to Crd',
    LAG01_AHCP: 'ACE at Home in College Program',
    LAG01_ACTC: 'ACE to Credit',
    BMC01_ACR1: 'ACR 94',
    BMC01_ACR: 'ACR 95',
    BAR01_STRE: 'AD',
    LEH01_ADPM: 'ADP Student Who failed Math',
    HTR01_ALLP: 'ALL-PERMIT IN-PI01-PI23',
    QNS01_ALLP: 'ALL-PERMIT IN-PI01-PI23',
    BMC01_APC2: 'APPLE Corps Accepted',
    HOS01_APC2: 'APPLE Corps Accepted',
    JJC01_APC2: 'APPLE Corps Accepted',
    BMC01_APC1: 'APPLE Corps Applied',
    JJC01_APC1: 'APPLE Corps Applied',
    BMC01_APC4: 'APPLE Corps Declined',
    JJC01_APC4: 'APPLE Corps Declined',
    BMC01_APC3: 'APPLE Corps Denied',
    JJC01_APC3: 'APPLE Corps Denied',
    BMC01_APC0: 'APPLE Corps Invited',
    JJC01_APC0: 'APPLE Corps Invited',
    JJC01_APLA: 'APPLE Corps Stdnt - LaGuardia',
    LAG01_APLA: 'APPLE Corps Stdnt - LaGuardia',
    BMC01_APBM: 'APPLE Corps Student - BMCC',
    JJC01_APBM: 'APPLE Corps Student - BMCC',
    JJC01_APBX: 'APPLE Corps Student - Bronx',
    HOS01_APHO: 'APPLE Corps Student - Hostos',
    JJC01_APHO: 'APPLE Corps Student - Hostos',
    JJC01_APJC: 'APPLE Corps Student - John Jay',
    JJC01_APKC: 'APPLE Corps Student - KCC',
    JJC01_APQC: 'APPLE Corps Student - QCC',
    UAPC1_FARP: 'APR CUNY Comeback',
    CSI01_ARCP: 'ARC',
    HOS01_HASP: 'ASAP - HERO Student',
    QCC01_V0: 'ASAP Approved',
    BAR01_ASP1: 'ASAP Eligible - Tested',
    BCC01_ASP1: 'ASAP Eligible - Tested',
    BKL01_ASP1: 'ASAP Eligible - Tested',
    BMC01_ASP1: 'ASAP Eligible - Tested',
    CSI01_ASP1: 'ASAP Eligible - Tested',
    CTY01_ASP1: 'ASAP Eligible - Tested',
    HOS01_ASP1: 'ASAP Eligible - Tested',
    HTR01_ASP1: 'ASAP Eligible - Tested',
    JJC01_ASP1: 'ASAP Eligible - Tested',
    KCC01_ASP1: 'ASAP Eligible - Tested',
    LAG01_ASP1: 'ASAP Eligible - Tested',
    LAW01_ASP1: 'ASAP Eligible - Tested',
    LEH01_ASP1: 'ASAP Eligible - Tested',
    MEC01_ASP1: 'ASAP Eligible - Tested',
    NCC01_ASP1: 'ASAP Eligible - Tested',
    NYT01_ASP1: 'ASAP Eligible - Tested',
    QCC01_ASP1: 'ASAP Eligible - Tested',
    QNS01_ASP1: 'ASAP Eligible - Tested',
    YRK01_ASP1: 'ASAP Eligible - Tested',
    BAR01_ASP0: 'ASAP Predicted Eligible',
    BCC01_ASP0: 'ASAP Predicted Eligible',
    BKL01_ASP0: 'ASAP Predicted Eligible',
    BMC01_ASP0: 'ASAP Predicted Eligible',
    CSI01_ASP0: 'ASAP Predicted Eligible',
    CTY01_ASP0: 'ASAP Predicted Eligible',
    HOS01_ASP0: 'ASAP Predicted Eligible',
    HTR01_ASP0: 'ASAP Predicted Eligible',
    JJC01_ASP0: 'ASAP Predicted Eligible',
    KCC01_ASP0: 'ASAP Predicted Eligible',
    LAG01_ASP0: 'ASAP Predicted Eligible',
    LAW01_ASP0: 'ASAP Predicted Eligible',
    LEH01_ASP0: 'ASAP Predicted Eligible',
    MEC01_ASP0: 'ASAP Predicted Eligible',
    NCC01_ASP0: 'ASAP Predicted Eligible',
    NYT01_ASP0: 'ASAP Predicted Eligible',
    QCC01_ASP0: 'ASAP Predicted Eligible',
    QNS01_ASP0: 'ASAP Predicted Eligible',
    YRK01_ASP0: 'ASAP Predicted Eligible',
    BMC01_ASL5: 'ASL 105',
    BMC01_ASL6: 'ASL 106',
    BCC01_ASM1: 'ASMS Eligible',
    BMC01_ASM1: 'ASMS Eligible',
    CSI01_ASM1: 'ASMS Eligible',
    HOS01_ASM1: 'ASMS Eligible',
    KCC01_ASM1: 'ASMS Eligible',
    LAG01_ASM1: 'ASMS Eligible',
    MEC01_ASM1: 'ASMS Eligible',
    NCC01_ASM1: 'ASMS Eligible',
    NYT01_ASM1: 'ASMS Eligible',
    QCC01_ASM1: 'ASMS Eligible',
    BAR01_ATBX: 'Ability To Benefit Met-Exempt',
    BAR01_ATBN: 'Ability To Benefit-Didn\'t Pass',
    JJC01_ATBF: 'Ability to Benefit Failed Test',
    CTY01_ATBN: 'Ability to Benefit Not Passed',
    BAR01_ATBP: 'Ability to Benefit Passed Test',
    BCC01_ATBP: 'Ability to Benefit Passed Test',
    BKL01_ATBP: 'Ability to Benefit Passed Test',
    BMC01_ATBP: 'Ability to Benefit Passed Test',
    CSI01_ATBP: 'Ability to Benefit Passed Test',
    CTY01_ATBP: 'Ability to Benefit Passed Test',
    HOS01_ATBP: 'Ability to Benefit Passed Test',
    HTR01_ATBP: 'Ability to Benefit Passed Test',
    JJC01_ATBP: 'Ability to Benefit Passed Test',
    KCC01_ATBP: 'Ability to Benefit Passed Test',
    LAG01_ATBP: 'Ability to Benefit Passed Test',
    LAW01_ATBP: 'Ability to Benefit Passed Test',
    LEH01_ATBP: 'Ability to Benefit Passed Test',
    MEC01_ATBP: 'Ability to Benefit Passed Test',
    NCC01_ATBP: 'Ability to Benefit Passed Test',
    NYT01_ATBP: 'Ability to Benefit Passed Test',
    QCC01_ATBP: 'Ability to Benefit Passed Test',
    QNS01_ATBP: 'Ability to Benefit Passed Test',
    YRK01_ATBP: 'Ability to Benefit Passed Test',
    BAR01_ATBQ: 'Ability to Benefit Qualified',
    BCC01_ATBQ: 'Ability to Benefit Qualified',
    BKL01_ATBQ: 'Ability to Benefit Qualified',
    BMC01_ATBQ: 'Ability to Benefit Qualified',
    CSI01_ATBQ: 'Ability to Benefit Qualified',
    CTY01_ATBQ: 'Ability to Benefit Qualified',
    HOS01_ATBQ: 'Ability to Benefit Qualified',
    HTR01_ATBQ: 'Ability to Benefit Qualified',
    JJC01_ATBQ: 'Ability to Benefit Qualified',
    KCC01_ATBQ: 'Ability to Benefit Qualified',
    LAG01_ATBQ: 'Ability to Benefit Qualified',
    LAW01_ATBQ: 'Ability to Benefit Qualified',
    LEH01_ATBQ: 'Ability to Benefit Qualified',
    MEC01_ATBQ: 'Ability to Benefit Qualified',
    NCC01_ATBQ: 'Ability to Benefit Qualified',
    NYT01_ATBQ: 'Ability to Benefit Qualified',
    QCC01_ATBQ: 'Ability to Benefit Qualified',
    QNS01_ATBQ: 'Ability to Benefit Qualified',
    YRK01_ATBQ: 'Ability to Benefit Qualified',
    BAR01_ATB: 'Ability to Benefit Student',
    BCC01_ATB: 'Ability to Benefit Student',
    BKL01_ATB: 'Ability to Benefit Student',
    BMC01_ATB: 'Ability to Benefit Student',
    CSI01_ATB: 'Ability to Benefit Student',
    CTY01_ATB: 'Ability to Benefit Student',
    HOS01_ATB: 'Ability to Benefit Student',
    HTR01_ATB: 'Ability to Benefit Student',
    JJC01_ATB: 'Ability to Benefit Student',
    KCC01_ATB: 'Ability to Benefit Student',
    LAG01_ATB: 'Ability to Benefit Student',
    LAW01_ATB: 'Ability to Benefit Student',
    LEH01_ATB: 'Ability to Benefit Student',
    MEC01_ATB: 'Ability to Benefit Student',
    NCC01_ATB: 'Ability to Benefit Student',
    NYT01_ATB: 'Ability to Benefit Student',
    QCC01_ATB: 'Ability to Benefit Student',
    QNS01_ATB: 'Ability to Benefit Student',
    YRK01_ATB: 'Ability to Benefit Student',
    BKL01_ATBU: 'Ability to Benefit UnderReview',
    BMC01_ATBU: 'Ability to Benefit UnderReview',
    CSI01_ATBU: 'Ability to Benefit UnderReview',
    HTR01_ATBU: 'Ability to Benefit UnderReview',
    MEC01_ATBU: 'Ability to Benefit UnderReview',
    NYT01_ATBU: 'Ability to Benefit UnderReview',
    YRK01_ATBU: 'Ability to Benefit UnderReview',
    HOS01_ACAD: 'Academic Advisement',
    QCC01_AFS: 'Academic Fresh Start',
    QCC01_AFSE: 'Academic Fresh Start Eligible',
    QCC01_AFSB: 'Academic FreshStart Balance',
    QCC01_AFSI: 'Academic FreshStart Ineligible',
    BCC01_2443: 'Academic Progress Waiver-Fed',
    BCC01_2444: 'Academic Pursuit Waiver-Fed',
    BKL01_ARES: 'Academic Restriction 12 Cr',
    JJC01_WARN: 'Academic Warning',
    CSI01_AATC: 'Accelerated Arts Teachers Cert',
    JJC01_ADAP: 'Accelerated Dual-Admission Prg',
    KCC01_ALP: 'Accelerated ENG Program',
    QNS01_ACCG: 'Accelerated Graduate',
    QCC01_ALPM: 'Accelerated Learning Prog Math',
    QCC01_ALP: 'Accelerated Learning Program',
    CTY01_ACLM: 'Accelerated Master\'s Student',
    BAR01_OAPT: 'Accelerated Part Time MBA(Old)',
    BKL01_ASFW: 'Accelerated Study Fee Waived',
    CSI01_ACFE: 'Accelerated Study Fee Waived',
    KCC01_3A: 'Accelerated Study Fee waived',
    JJC01_ACCL: 'Acceleration Project',
    JJC01_ACWV: 'Acceleration Waiver',
    YRK01_BSMS: 'Acceptance into BS/MS OT Prog',
    LAG01_PAR2: 'Accepted Adv & Reg',
    QCC01_PAR2: 'Accepted Adv & Reg',
    KCC01_IPRB: 'Accepted Probation Students',
    BKL01_ACON: 'Accepted With Conditions-Adms',
    BKL01_DCON: 'Accepted With Conditions-Dept',
    BAR01_WEIS: 'Accepted to Weissman School',
    BAR01_ASP3: 'Accepts ASAP Invitation',
    BCC01_ASP3: 'Accepts ASAP Invitation',
    BKL01_ASP3: 'Accepts ASAP Invitation',
    BMC01_ASP3: 'Accepts ASAP Invitation',
    CSI01_ASP3: 'Accepts ASAP Invitation',
    CTY01_ASP3: 'Accepts ASAP Invitation',
    HOS01_ASP3: 'Accepts ASAP Invitation',
    HTR01_ASP3: 'Accepts ASAP Invitation',
    KCC01_ASP3: 'Accepts ASAP Invitation',
    LAG01_ASP3: 'Accepts ASAP Invitation',
    LAW01_ASP3: 'Accepts ASAP Invitation',
    LEH01_ASP3: 'Accepts ASAP Invitation',
    MEC01_ASP3: 'Accepts ASAP Invitation',
    NCC01_ASP3: 'Accepts ASAP Invitation',
    NYT01_ASP3: 'Accepts ASAP Invitation',
    QCC01_ASP3: 'Accepts ASAP Invitation',
    QNS01_ASP3: 'Accepts ASAP Invitation',
    YRK01_ASP3: 'Accepts ASAP Invitation',
    BCC01_ASM3: 'Accepts ASMS Invitation',
    BMC01_ASM3: 'Accepts ASMS Invitation',
    CSI01_ASM3: 'Accepts ASMS Invitation',
    HOS01_ASM3: 'Accepts ASMS Invitation',
    KCC01_ASM3: 'Accepts ASMS Invitation',
    LAG01_ASM3: 'Accepts ASMS Invitation',
    MEC01_ASM3: 'Accepts ASMS Invitation',
    NCC01_ASM3: 'Accepts ASMS Invitation',
    NYT01_ASM3: 'Accepts ASMS Invitation',
    QCC01_ASM3: 'Accepts ASMS Invitation',
    BCC01_PTI3: 'Accepts PTI invitation',
    LAG01_PTI3: 'Accepts PTI invitation',
    KCC01_AC: 'Accessibility Center',
    KCC01_ACNT: 'Accessibility Center Non-Trio',
    KCC01_ACT: 'Accessibility Center Trio',
    JJC01_ACCS: 'Accessibility Services',
    BMC01_ACHV: 'Achievers',
    LAG01_PAR4: 'Active PAR participant',
    QCC01_PAR4: 'Active PAR participant',
    JJC01_ASPR: 'Addiction Studies Courses',
    BAR01_AREQ: 'Additional Requirements',
    HTR01_ADTE: 'Adelante',
    JJC01_ADTE: 'Adelante',
    QCC01_ADMW: 'Adm Visiting Stdnts Winter',
    YRK01_ADDE: 'Admission Declined',
    CSI01_ADST: 'Admission to Student Teaching',
    YRK01_TEE3: 'Admission to Student Teaching',
    QNS01_AAMB: 'Admissions Ambassador',
    CTY01_ADAP: 'Admissions Appeals',
    SOJ01_ADAP: 'Admissions Appeals',
    LEH01_ADEC: 'Admissions Declined',
    JJC01_ADDF: 'Admissions Deferred',
    LEH01_ADMM: 'Admissions Direct Admit',
    KCC01_ADMR: 'Admissions Ready Students',
    LAW01_ADSC: 'Admissions Scholarship Recip',
    LEH01_ADMT: 'Admissions Tran Evalution Comp',
    LEH01_ADMA: 'Admisssions Accepted',
    LEH01_ADRE: 'Admitted By Review',
    CTY01_ADRE: 'Admitted by Review',
    SOJ01_ADRE: 'Admitted by Review',
    BAR01_ZICK: 'Admitted into the Zicklin',
    YRK01_PRPR: 'Admitted to Prof Phase-PA Prog',
    BAR01_SPAF: 'Admitted to SPA',
    BKL01_ASBI: 'Adolescence Sci. Edu. Biology',
    BKL01_ASES: 'Adolescence Sci. Edu. EarthSci',
    BKL01_ASPH: 'Adolescence Sci. Edu. Physics',
    BKL01_ASCH: 'Adolescence Sci. Edu.Chemistry',
    HTR01_ASPO: 'Adolescent Special Educ Online',
    QCC01_ACE: 'Adult Collegiate Education',
    QNS01_ACE: 'Adult Collegiate Education',
    SLU01_ADLS: 'Adult Learner Scholarship',
    SLU01_AUFT: 'Adv Cert UFT Student',
    HTR01_ADSW: 'Adv Stand Social Work',
    SPH01_ACRT: 'Advance Cert Student',
    BKL01_PLAY: 'Advance Cert in Play Therapy',
    LAG01_A200: 'Advanced Arabic',
    LAG01_V200: 'Advanced Bengali',
    CTY01_EDAP: 'Advanced Cert Program',
    LAG01_C200: 'Advanced Chinese',
    LAG01_F200: 'Advanced French',
    LAG01_I200: 'Advanced Italian',
    LAG01_J200: 'Advanced Japanese',
    LAG01_K201: 'Advanced Korean',
    KCC01_ADLC: 'Advanced Learning Communities',
    LAG01_S200: 'Advanced Spanish',
    HTR01_SPH3: 'Advisement Req\'d - See Advisor',
    LAG01_ABPR: 'Advocacy to Bridge Program',
    LAG01_ASC: 'After School Corp',
    LEH01_A4AL: 'Algebra for All',
    HTR01_AMPS: 'Alliance Min Part Stem',
    KCC01_ALHT: 'Allied Health',
    LAG01_AHAS: 'Allied Health/Science Academy',
    BKL01_ALSL: 'Alpha Sigma Lambda',
    LEH01_D75B: 'Alt cert teachers in D75 schls',
    NYT01_ALTF: 'Alternate Format',
    LAG01_M102: 'American Sign Language 2',
    LAG01_M103: 'American Sign Language 3',
    LAG01_M104: 'American Sign Language 4',
    CSI01_AUR: 'American University of Rome',
    SPS01_AMNH: 'AmericanNatural History Museum',
    LEH01_ROTH: 'Anne Rothstein\'s Program',
    HTR01_APPD: 'Appeal Denied',
    BAR01_HOAP: 'Applicant to Honors Program',
    YRK01_REAC: 'Application Reactivated',
    YRK01_RELL: 'Application Reallocated',
    JJC01_ACE1: 'Applied to ACE',
    LEH01_ACE1: 'Applied to ACE',
    NYT01_ACE1: 'Applied to ACE',
    BAR01_ASP2: 'Applied to ASAP',
    BCC01_ASP2: 'Applied to ASAP',
    BKL01_ASP2: 'Applied to ASAP',
    BMC01_ASP2: 'Applied to ASAP',
    CSI01_ASP2: 'Applied to ASAP',
    CTY01_ASP2: 'Applied to ASAP',
    HOS01_ASP2: 'Applied to ASAP',
    HTR01_ASP2: 'Applied to ASAP',
    JJC01_ASP2: 'Applied to ASAP',
    KCC01_ASP2: 'Applied to ASAP',
    LAG01_ASP2: 'Applied to ASAP',
    LAW01_ASP2: 'Applied to ASAP',
    LEH01_ASP2: 'Applied to ASAP',
    MEC01_ASP2: 'Applied to ASAP',
    NCC01_ASP2: 'Applied to ASAP',
    NYT01_ASP2: 'Applied to ASAP',
    QCC01_ASP2: 'Applied to ASAP',
    QNS01_ASP2: 'Applied to ASAP',
    YRK01_ASP2: 'Applied to ASAP',
    QCC01_CST1: 'Applied to CUNYStart',
    LAG01_FEP1: 'Applied to FEP',
    BAR01_REGA: 'Appointment Groups',
    QCC01_AFSA: 'Approved Academic Fresh Start',
    BMC01_A105: 'Arabic 105',
    BMC01_A106: 'Arabic 106',
    BMC01_A107: 'Arabic 107',
    BMC01_A200: 'Arabic 200',
    BMC01_A210: 'Arabic 210',
    LAG01_A105: 'Arabic Heritage',
    JJC01_ARA1: 'Arabic Placement 101',
    JJC01_ARA2: 'Arabic Placement 102',
    BAR01_AWSC: 'Arnold Work-Study Control',
    BCC01_AWSC: 'Arnold Work-Study Control',
    BKL01_AWSC: 'Arnold Work-Study Control',
    BMC01_AWSC: 'Arnold Work-Study Control',
    CSI01_AWSC: 'Arnold Work-Study Control',
    CTY01_AWSC: 'Arnold Work-Study Control',
    HOS01_AWSC: 'Arnold Work-Study Control',
    HTR01_AWSC: 'Arnold Work-Study Control',
    JJC01_AWSC: 'Arnold Work-Study Control',
    KCC01_AWSC: 'Arnold Work-Study Control',
    LAG01_AWSC: 'Arnold Work-Study Control',
    LEH01_AWSC: 'Arnold Work-Study Control',
    MEC01_AWSC: 'Arnold Work-Study Control',
    NCC01_AWSC: 'Arnold Work-Study Control',
    NYT01_AWSC: 'Arnold Work-Study Control',
    QCC01_AWSC: 'Arnold Work-Study Control',
    QNS01_AWSC: 'Arnold Work-Study Control',
    SLU01_AWSC: 'Arnold Work-Study Control',
    SPS01_AWSC: 'Arnold Work-Study Control',
    YRK01_AWSC: 'Arnold Work-Study Control',
    BAR01_AWSN: 'Arnold Work-Study Not Included',
    BCC01_AWSN: 'Arnold Work-Study Not Included',
    BKL01_AWSN: 'Arnold Work-Study Not Included',
    BMC01_AWSN: 'Arnold Work-Study Not Included',
    CSI01_AWSN: 'Arnold Work-Study Not Included',
    CTY01_AWSN: 'Arnold Work-Study Not Included',
    HOS01_AWSN: 'Arnold Work-Study Not Included',
    HTR01_AWSN: 'Arnold Work-Study Not Included',
    JJC01_AWSN: 'Arnold Work-Study Not Included',
    KCC01_AWSN: 'Arnold Work-Study Not Included',
    LAG01_AWSN: 'Arnold Work-Study Not Included',
    LEH01_AWSN: 'Arnold Work-Study Not Included',
    MEC01_AWSN: 'Arnold Work-Study Not Included',
    NCC01_AWSN: 'Arnold Work-Study Not Included',
    NYT01_AWSN: 'Arnold Work-Study Not Included',
    QCC01_AWSN: 'Arnold Work-Study Not Included',
    QNS01_AWSN: 'Arnold Work-Study Not Included',
    SLU01_AWSN: 'Arnold Work-Study Not Included',
    SPS01_AWSN: 'Arnold Work-Study Not Included',
    YRK01_AWSN: 'Arnold Work-Study Not Included',
    BAR01_AWST: 'Arnold Work-Study Treatment',
    BCC01_AWST: 'Arnold Work-Study Treatment',
    BKL01_AWST: 'Arnold Work-Study Treatment',
    BMC01_AWST: 'Arnold Work-Study Treatment',
    CSI01_AWST: 'Arnold Work-Study Treatment',
    CTY01_AWST: 'Arnold Work-Study Treatment',
    HOS01_AWST: 'Arnold Work-Study Treatment',
    HTR01_AWST: 'Arnold Work-Study Treatment',
    JJC01_AWST: 'Arnold Work-Study Treatment',
    KCC01_AWST: 'Arnold Work-Study Treatment',
    LAG01_AWST: 'Arnold Work-Study Treatment',
    LEH01_AWST: 'Arnold Work-Study Treatment',
    MEC01_AWST: 'Arnold Work-Study Treatment',
    NCC01_AWST: 'Arnold Work-Study Treatment',
    NYT01_AWST: 'Arnold Work-Study Treatment',
    QCC01_AWST: 'Arnold Work-Study Treatment',
    QNS01_AWST: 'Arnold Work-Study Treatment',
    SLU01_AWST: 'Arnold Work-Study Treatment',
    SPS01_AWST: 'Arnold Work-Study Treatment',
    YRK01_AWST: 'Arnold Work-Study Treatment',
    HTR01_ASTR: 'AstroCom NYC',
    BMC01_AHIC: 'At Home In College',
    BAR01_AHIC: 'At Home in College',
    BCC01_AHIC: 'At Home in College',
    BKL01_AHIC: 'At Home in College',
    CSI01_AHIC: 'At Home in College',
    CTY01_AHIC: 'At Home in College',
    HOS01_AHIC: 'At Home in College',
    HTR01_AHIC: 'At Home in College',
    JJC01_AHIC: 'At Home in College',
    KCC01_AHIC: 'At Home in College',
    LAG01_AHIC: 'At Home in College',
    LAW01_AHIC: 'At Home in College',
    LEH01_AHIC: 'At Home in College',
    MEC01_AHIC: 'At Home in College',
    NCC01_AHIC: 'At Home in College',
    NYT01_AHIC: 'At Home in College',
    QCC01_AHIC: 'At Home in College',
    QNS01_AHIC: 'At Home in College',
    YRK01_AHIC: 'At Home in College',
    HTR01_AHCP: 'Athena Honors Cohort Prgm',
    HTR01_AHCS: 'Athena Scholars',
    LEH01_AFFR: 'Athlete Student Fall Freshman',
    LEH01_AFJR: 'Athlete Student Fall Junior',
    LEH01_AFSR: 'Athlete Student Fall Senior',
    LEH01_AFSO: 'Athlete Student Fall Soph',
    BAR01_ATFA: 'Athlete Student Fall Sport',
    BCC01_ATFA: 'Athlete Student Fall Sport',
    BKL01_ATFA: 'Athlete Student Fall Sport',
    BMC01_ATFA: 'Athlete Student Fall Sport',
    CSI01_ATFA: 'Athlete Student Fall Sport',
    CTY01_ATFA: 'Athlete Student Fall Sport',
    HOS01_ATFA: 'Athlete Student Fall Sport',
    HTR01_ATFA: 'Athlete Student Fall Sport',
    JJC01_ATFA: 'Athlete Student Fall Sport',
    KCC01_ATFA: 'Athlete Student Fall Sport',
    LAG01_ATFA: 'Athlete Student Fall Sport',
    LAW01_ATFA: 'Athlete Student Fall Sport',
    LEH01_ATFA: 'Athlete Student Fall Sport',
    MEC01_ATFA: 'Athlete Student Fall Sport',
    NCC01_ATFA: 'Athlete Student Fall Sport',
    NYT01_ATFA: 'Athlete Student Fall Sport',
    QCC01_ATFA: 'Athlete Student Fall Sport',
    QNS01_ATFA: 'Athlete Student Fall Sport',
    YRK01_ATFA: 'Athlete Student Fall Sport',
    LEH01_ASFR: 'Athlete Student Spring Fresh',
    LEH01_ASJR: 'Athlete Student Spring Junior',
    LEH01_ASSR: 'Athlete Student Spring Senior',
    LEH01_ASSO: 'Athlete Student Spring Soph',
    BAR01_ATSP: 'Athlete Student Spring Sport',
    BCC01_ATSP: 'Athlete Student Spring Sport',
    BKL01_ATSP: 'Athlete Student Spring Sport',
    BMC01_ATSP: 'Athlete Student Spring Sport',
    CSI01_ATSP: 'Athlete Student Spring Sport',
    CTY01_ATSP: 'Athlete Student Spring Sport',
    HOS01_ATSP: 'Athlete Student Spring Sport',
    HTR01_ATSP: 'Athlete Student Spring Sport',
    JJC01_ATSP: 'Athlete Student Spring Sport',
    KCC01_ATSP: 'Athlete Student Spring Sport',
    LAG01_ATSP: 'Athlete Student Spring Sport',
    LAW01_ATSP: 'Athlete Student Spring Sport',
    LEH01_ATSP: 'Athlete Student Spring Sport',
    MEC01_ATSP: 'Athlete Student Spring Sport',
    NCC01_ATSP: 'Athlete Student Spring Sport',
    NYT01_ATSP: 'Athlete Student Spring Sport',
    QCC01_ATSP: 'Athlete Student Spring Sport',
    QNS01_ATSP: 'Athlete Student Spring Sport',
    YRK01_ATSP: 'Athlete Student Spring Sport',
    CTY01_AUST: 'Austrian Education Program',
    BKL01_AUTS: 'Autism Spectrum Disorders',
    LAG01_BAMA: 'BA/MA US Educated',
    BKL01_BAMD: 'BA/MD Program',
    BKL01_FOOT: 'BA/Podiatry Program',
    CSI01_BAF2: 'BACC Ind Fail 2 or 3',
    CSI01_BAF1: 'BACC Ind Fail <= 1 Exam',
    CSI01_BANL: 'BACC Stud w/Nursing Lic',
    BKL01_HSEC: 'BC Academy Early College',
    BKL01_BCSA: 'BC-BC Study Abroad',
    BKL01_CUSA: 'BC-CUNY Study Abroad',
    BKL01_NCSA: 'BC-NON-CUNY Study Abroad',
    BKL01_BCRR: 'BC-Readmit Reinstate',
    CSI01_B150: 'BIO 150',
    BMC01_FLAE: 'BLA Eligible',
    BMC01_BLIS: 'BLIIS Student',
    BKL01_BMCA: 'BMCC ACP Program Student at BC',
    BMC01_FLA: 'BMCC Learning Academy',
    BMC01_STAR: 'BMCC STARS',
    LEH01_BOCE: 'BOCES Program',
    LEH01_BTAG: 'BTAG Agreement',
    MEC01_BACE: 'Baccalaureate Eligible',
    CSI01_BACC: 'Baccalaureate Students',
    LAG01_BLPP: 'Barnard LaGuardia Partnership',
    BAR01_BSCH: 'Baruch Scholar',
    BAR01_BSCO: 'Baruch Scholar Offer',
    NYT01_BIT: 'Behavioral Intervention Team',
    LAG01_V105: 'Bengali Heritage',
    BKL01_BILX: 'Bilingual Extension',
    CTY01_EDBE: 'Bilingual Extension',
    HTR01_BIEX: 'Bilingual Extension',
    BKL01_BIPG: 'Bilingual Program',
    BAR01_BLNG: 'Bilingual Student',
    BCC01_BLNG: 'Bilingual Student',
    BKL01_BLNG: 'Bilingual Student',
    BMC01_BLNG: 'Bilingual Student',
    CSI01_BLNG: 'Bilingual Student',
    CTY01_BLNG: 'Bilingual Student',
    HOS01_BLNG: 'Bilingual Student',
    HTR01_BLNG: 'Bilingual Student',
    JJC01_BLNG: 'Bilingual Student',
    KCC01_BLNG: 'Bilingual Student',
    LAG01_BLNG: 'Bilingual Student',
    LAW01_BLNG: 'Bilingual Student',
    LEH01_BLNG: 'Bilingual Student',
    MEC01_BLNG: 'Bilingual Student',
    NCC01_BLNG: 'Bilingual Student',
    NYT01_BLNG: 'Bilingual Student',
    QCC01_BLNG: 'Bilingual Student',
    QNS01_BLNG: 'Bilingual Student',
    YRK01_BLNG: 'Bilingual Student',
    HTR01_QBCP: 'Bio Concen Prog - STEM',
    CTY01_BMED: 'Biomedical Science',
    SPH01_BIOS: 'Biostatistics',
    BKL01_HSAM: 'Bklyn Amity Sch - Bridge To BC',
    BMC01_BMLA: 'Black/Latino Males BUS Major',
    BMC01_BOLT: 'Bldg Outstanding Leaders Tom',
    KCC01_B1: 'Block Institute Fall 05',
    HTR01_BLKP: 'Block Programming',
    CSI01_BOEJ: 'Board of Ed - Jewish',
    NYT01_BKAD: 'Book Advance Issued',
    HTR01_BOOK: 'Book Voucher',
    BCC01_BKOU: 'Bookvoucher  Opt-Out',
    BCC01_BVIN: 'Bookvoucher Opt-In',
    LEH01_BOTL: 'Bottom Line Program',
    LEH01_BRAV: 'Braven',
    NYT01_BRNU: 'Bridge to Nursing',
    KCC01_BBAP: 'Bridges to the BA Program',
    BCC01_BASE: 'Bronx Acad Software & Engineer',
    LEH01_BRON: 'Bronx Center',
    LEH01_BECA: 'Bronx Early College Academy',
    BKL01_BCB: 'Brooklyn College Bound Program',
    MEC01_BPDC: 'Brooklyn Pipeline Dual Credit',
    BKL01_HSBT: 'Brooklyn Tech High School',
    LEH01_EW30: 'Business Writing',
    LAG01_BTA: 'Business and Tech Academy',
    BMC01_C210: 'C210 -  Chinese 210',
    NYT01_HSCS: 'CASAC Program',
    CSI01_QM6: 'CAT Exempt and M6 Score 30+',
    BAR01_CATW: 'CATW Field Test Participant',
    BCC01_CATW: 'CATW Field Test Participant',
    BKL01_CATW: 'CATW Field Test Participant',
    BMC01_CATW: 'CATW Field Test Participant',
    CSI01_CATW: 'CATW Field Test Participant',
    CTY01_CATW: 'CATW Field Test Participant',
    HOS01_CATW: 'CATW Field Test Participant',
    HTR01_CATW: 'CATW Field Test Participant',
    JJC01_CATW: 'CATW Field Test Participant',
    KCC01_CATW: 'CATW Field Test Participant',
    LAG01_CATW: 'CATW Field Test Participant',
    LAW01_CATW: 'CATW Field Test Participant',
    LEH01_CATW: 'CATW Field Test Participant',
    MEC01_CATW: 'CATW Field Test Participant',
    NCC01_CATW: 'CATW Field Test Participant',
    NYT01_CATW: 'CATW Field Test Participant',
    QCC01_CATW: 'CATW Field Test Participant',
    QNS01_CATW: 'CATW Field Test Participant',
    SLU01_CATW: 'CATW Field Test Participant',
    SPS01_CATW: 'CATW Field Test Participant',
    YRK01_CATW: 'CATW Field Test Participant',
    BAR01_CO02: 'CC Lwr Trsf - 4 College Opt',
    BAR01_CO03: 'CC Uppr Trsf - 3 College Opt',
    CTY01_HSPP: 'CCNY Academy-Professional Prep',
    BCC01_244A: 'CD 1 Failed Progress (Temp)',
    HOS01_244A: 'CD 1 Failed Progress (Temp)',
    KCC01_244A: 'CD 1 Failed Progress (Temp)',
    LAG01_244A: 'CD 1 Failed Progress (Temp)',
    LEH01_244A: 'CD 1 Failed Progress (Temp)',
    QCC01_244A: 'CD 1 Failed Progress (Temp)',
    QNS01_244A: 'CD 1 Failed Progress (Temp)',
    BCC01_244B: 'CD 2 Failed Pursuit (Temp)',
    HOS01_244B: 'CD 2 Failed Pursuit (Temp)',
    KCC01_244B: 'CD 2 Failed Pursuit (Temp)',
    LAG01_244B: 'CD 2 Failed Pursuit (Temp)',
    LEH01_244B: 'CD 2 Failed Pursuit (Temp)',
    QCC01_244B: 'CD 2 Failed Pursuit (Temp)',
    QNS01_244B: 'CD 2 Failed Pursuit (Temp)',
    BCC01_244C: 'CD 3 Failed Progress & Pursuit',
    HOS01_244C: 'CD 3 Failed Progress & Pursuit',
    KCC01_244C: 'CD 3 Failed Progress & Pursuit',
    LAG01_244C: 'CD 3 Failed Progress & Pursuit',
    LEH01_244C: 'CD 3 Failed Progress & Pursuit',
    QCC01_244C: 'CD 3 Failed Progress & Pursuit',
    QNS01_244C: 'CD 3 Failed Progress & Pursuit',
    BCC01_244D: 'CD 4 Failed STAP Requirements',
    HOS01_244D: 'CD 4 Failed STAP Requirements',
    KCC01_244D: 'CD 4 Failed STAP Requirements',
    LAG01_244D: 'CD 4 Failed STAP Requirements',
    LEH01_244D: 'CD 4 Failed STAP Requirements',
    QCC01_244D: 'CD 4 Failed STAP Requirements',
    QNS01_244D: 'CD 4 Failed STAP Requirements',
    BCC01_244E: 'CD 5 Failed APTS Prgrs (Temp)',
    HOS01_244E: 'CD 5 Failed APTS Prgrs (Temp)',
    KCC01_244E: 'CD 5 Failed APTS Prgrs (Temp)',
    LAG01_244E: 'CD 5 Failed APTS Prgrs (Temp)',
    LEH01_244E: 'CD 5 Failed APTS Prgrs (Temp)',
    QCC01_244E: 'CD 5 Failed APTS Prgrs (Temp)',
    QNS01_244E: 'CD 5 Failed APTS Prgrs (Temp)',
    BCC01_244F: 'CD 6 Failed APTS Purst (Temp)',
    HOS01_244F: 'CD 6 Failed APTS Purst (Temp)',
    KCC01_244F: 'CD 6 Failed APTS Purst (Temp)',
    LAG01_244F: 'CD 6 Failed APTS Purst (Temp)',
    LEH01_244F: 'CD 6 Failed APTS Purst (Temp)',
    QCC01_244F: 'CD 6 Failed APTS Purst (Temp)',
    QNS01_244F: 'CD 6 Failed APTS Purst (Temp)',
    BCC01_244G: 'CD 7 Failed APTS Prog &Pursuit',
    HOS01_244G: 'CD 7 Failed APTS Prog &Pursuit',
    KCC01_244G: 'CD 7 Failed APTS Prog &Pursuit',
    LAG01_244G: 'CD 7 Failed APTS Prog &Pursuit',
    LEH01_244G: 'CD 7 Failed APTS Prog &Pursuit',
    QCC01_244G: 'CD 7 Failed APTS Prog &Pursuit',
    QNS01_244G: 'CD 7 Failed APTS Prog &Pursuit',
    BCC01_2441: 'CD A Academic Progress Waiver',
    HOS01_2441: 'CD A Academic Progress Waiver',
    KCC01_2441: 'CD A Academic Progress Waiver',
    LAG01_2441: 'CD A Academic Progress Waiver',
    LEH01_2441: 'CD A Academic Progress Waiver',
    QCC01_2441: 'CD A Academic Progress Waiver',
    QNS01_2441: 'CD A Academic Progress Waiver',
    BCC01_2442: 'CD B Academic Program Pursuit',
    HOS01_2442: 'CD B Academic Program Pursuit',
    KCC01_2442: 'CD B Academic Program Pursuit',
    LAG01_2442: 'CD B Academic Program Pursuit',
    LEH01_2442: 'CD B Academic Program Pursuit',
    QCC01_2442: 'CD B Academic Program Pursuit',
    QNS01_2442: 'CD B Academic Program Pursuit',
    BCC01_244P: 'CD P Program Pursuit Waiver',
    HOS01_244P: 'CD P Program Pursuit Waiver',
    KCC01_244P: 'CD P Program Pursuit Waiver',
    LAG01_244P: 'CD P Program Pursuit Waiver',
    LEH01_244P: 'CD P Program Pursuit Waiver',
    QCC01_244P: 'CD P Program Pursuit Waiver',
    QNS01_244P: 'CD P Program Pursuit Waiver',
    BCC01_244S: 'CD S STAP Waiver',
    HOS01_244S: 'CD S STAP Waiver',
    KCC01_244S: 'CD S STAP Waiver',
    LAG01_244S: 'CD S STAP Waiver',
    LEH01_244S: 'CD S STAP Waiver',
    QCC01_244S: 'CD S STAP Waiver',
    QNS01_244S: 'CD S STAP Waiver',
    BCC01_244W: 'CD S TAP Waiver',
    HOS01_244W: 'CD S TAP Waiver',
    KCC01_244W: 'CD S TAP Waiver',
    LAG01_244W: 'CD S TAP Waiver',
    LEH01_244W: 'CD S TAP Waiver',
    QCC01_244W: 'CD S TAP Waiver',
    QNS01_244W: 'CD S TAP Waiver',
    BCC01_CDIE: 'CD Student - Ineligible',
    BCC01_CDLI: 'CD Student Enrolled in CLIP',
    BCC01_CDPT: 'CD Student Part Time/Acad Reas',
    HOS01_CDPT: 'CD Student Part Time/Acad Reas',
    HOS01_CEWD: 'CEWD Student',
    HOS01_CECH: 'CEWD-Community Health Worker',
    HOS01_CECM: 'CEWD-Construction Management',
    HOS01_CECA: 'CEWD-Culinary Arts',
    HOS01_CEFP: 'CEWD-Food Protection',
    HOS01_CEMB: 'CEWD-Medical Billing',
    HTR01_CFCV: 'CF Conversion POP \'03 - P',
    BMC01_CHLI: 'CHLI -  Chinese Literature',
    BKL01_TCPP: 'CISC Majors C++ Track',
    CTY01_CLA: 'CLA Lumina Program',
    HTR01_CLAS: 'CLAS',
    HTR01_CLSS: 'CLASS Educational Leadership',
    CSI01_CLPA: 'CLIP - CA',
    CSI01_CLPB: 'CLIP - CB',
    CSI01_CLPC: 'CLIP - CC',
    CSI01_CLPD: 'CLIP - CD',
    QCC01_CLPA: 'CLIP A',
    QCC01_CLPB: 'CLIP B',
    QCC01_CLPC: 'CLIP C',
    QCC01_CLPD: 'CLIP D',
    BCC01_CLIP: 'CLIP Student',
    BMC01_CLIP: 'CLIP Student',
    CSI01_CLIP: 'CLIP Student',
    CTY01_CLIP: 'CLIP Student',
    HOS01_CLIP: 'CLIP Student',
    KCC01_CLIP: 'CLIP Student',
    LAG01_CLIP: 'CLIP Student',
    LEH01_CLIP: 'CLIP Student',
    NCC01_CLIP: 'CLIP Student',
    NYT01_CLIP: 'CLIP Student',
    QCC01_CLIP: 'CLIP Student',
    QNS01_CLIP: 'CLIP Student',
    YRK01_CLIP: 'CLIP Student',
    LAG01_CLWG: 'CLIP Walmart Grant',
    HTR01_CNSI: 'CN Summer Sci Inst',
    NYT01_COPF: '"COPE Federal Percent  "',
    BCC01_COPE: 'COPE Student',
    BKL01_COPE: 'COPE Student',
    BMC01_COPE: 'COPE Student',
    HOS01_COPE: 'COPE Student',
    JJC01_COPE: 'COPE Student',
    KCC01_COPE: 'COPE Student',
    LAG01_COPE: 'COPE Student',
    LEH01_COPE: 'COPE Student',
    MEC01_COPE: 'COPE Student',
    NCC01_COPE: 'COPE Student',
    NYT01_COPE: 'COPE Student',
    QCC01_COPE: 'COPE Student',
    YRK01_COPE: 'COPE Student',
    CSI01_C100: 'COR Exempt',
    LAG01_RE42: 'CSE095 Placement',
    LAG01_RE44: 'CSE099 Placement',
    CSI01_CSTP: 'CSTEP',
    QCC01_CSTP: 'CSTEP',
    BAR01_M120: 'CSTM 0120 Placement',
    NYT01_CUEB: 'CUE Block Program',
    CSI01_C2XT: 'CUNY 2X Tech',
    SPS01_CBA: 'CUNY BA Student',
    BAR01_CPTH: 'CUNY CareerPATH',
    BCC01_CPTH: 'CUNY CareerPATH',
    BKL01_CPTH: 'CUNY CareerPATH',
    BMC01_CPTH: 'CUNY CareerPATH',
    CSI01_CPTH: 'CUNY CareerPATH',
    CTY01_CPTH: 'CUNY CareerPATH',
    HOS01_CPTH: 'CUNY CareerPATH',
    HTR01_CPTH: 'CUNY CareerPATH',
    JJC01_CPTH: 'CUNY CareerPATH',
    KCC01_CPTH: 'CUNY CareerPATH',
    LAG01_CPTH: 'CUNY CareerPATH',
    LAW01_CPTH: 'CUNY CareerPATH',
    LEH01_CPTH: 'CUNY CareerPATH',
    MEC01_CPTH: 'CUNY CareerPATH',
    NCC01_CPTH: 'CUNY CareerPATH',
    NYT01_CPTH: 'CUNY CareerPATH',
    QCC01_CPTH: 'CUNY CareerPATH',
    QNS01_CPTH: 'CUNY CareerPATH',
    YRK01_CPTH: 'CUNY CareerPATH',
    LAG01_CTCH: 'CUNY Catch',
    BAR01_CDRE: 'CUNY Disaster Relief Efforts',
    BCC01_CDRE: 'CUNY Disaster Relief Efforts',
    BKL01_CDRE: 'CUNY Disaster Relief Efforts',
    BMC01_CDRE: 'CUNY Disaster Relief Efforts',
    CSI01_CDRE: 'CUNY Disaster Relief Efforts',
    CTY01_CDRE: 'CUNY Disaster Relief Efforts',
    GRD01_CDRE: 'CUNY Disaster Relief Efforts',
    HOS01_CDRE: 'CUNY Disaster Relief Efforts',
    HTR01_CDRE: 'CUNY Disaster Relief Efforts',
    JJC01_CDRE: 'CUNY Disaster Relief Efforts',
    KCC01_CDRE: 'CUNY Disaster Relief Efforts',
    LAG01_CDRE: 'CUNY Disaster Relief Efforts',
    LAW01_CDRE: 'CUNY Disaster Relief Efforts',
    LEH01_CDRE: 'CUNY Disaster Relief Efforts',
    MEC01_CDRE: 'CUNY Disaster Relief Efforts',
    NCC01_CDRE: 'CUNY Disaster Relief Efforts',
    NYT01_CDRE: 'CUNY Disaster Relief Efforts',
    QCC01_CDRE: 'CUNY Disaster Relief Efforts',
    QNS01_CDRE: 'CUNY Disaster Relief Efforts',
    SLU01_CDRE: 'CUNY Disaster Relief Efforts',
    SOJ01_CDRE: 'CUNY Disaster Relief Efforts',
    SPH01_CDRE: 'CUNY Disaster Relief Efforts',
    SPS01_CDRE: 'CUNY Disaster Relief Efforts',
    YRK01_CDRE: 'CUNY Disaster Relief Efforts',
    QNS01_HMHC: 'CUNY HONORS HOME COLLEGE',
    BCC01_JABX: 'CUNY Justice Academy',
    BMC01_JABM: 'CUNY Justice Academy',
    HOS01_JAHC: 'CUNY Justice Academy',
    KCC01_JAKC: 'CUNY Justice Academy',
    LAG01_JALC: 'CUNY Justice Academy',
    QCC01_JAQC: 'CUNY Justice Academy',
    HOS01_COTC: 'CUNY On The Concourse',
    KCC01_COTC: 'CUNY On The Concourse',
    BAR01_CPIN: 'CUNY Permit In Student',
    BCC01_CPIN: 'CUNY Permit In Student',
    BKL01_CPIN: 'CUNY Permit In Student',
    BMC01_CPIN: 'CUNY Permit In Student',
    CSI01_CPIN: 'CUNY Permit In Student',
    CTY01_CPIN: 'CUNY Permit In Student',
    GRD01_CPIN: 'CUNY Permit In Student',
    HOS01_CPIN: 'CUNY Permit In Student',
    HTR01_CPIN: 'CUNY Permit In Student',
    JJC01_CPIN: 'CUNY Permit In Student',
    KCC01_CPIN: 'CUNY Permit In Student',
    LAG01_CPIN: 'CUNY Permit In Student',
    LAW01_CPIN: 'CUNY Permit In Student',
    LEH01_CPIN: 'CUNY Permit In Student',
    MEC01_CPIN: 'CUNY Permit In Student',
    NCC01_CPIN: 'CUNY Permit In Student',
    NYT01_CPIN: 'CUNY Permit In Student',
    QCC01_CPIN: 'CUNY Permit In Student',
    QNS01_CPIN: 'CUNY Permit In Student',
    SLU01_CPIN: 'CUNY Permit In Student',
    SPH01_CPIN: 'CUNY Permit In Student',
    SPS01_CPIN: 'CUNY Permit In Student',
    YRK01_CPIN: 'CUNY Permit In Student',
    MED01_CPIN: 'CUNY Permit in Student',
    MHC01_CPIN: 'CUNY Permit in Student',
    SOJ01_CPIN: 'CUNY Permit in Student',
    BKL01_PIPE: 'CUNY Pipeline Program',
    LAW01_PIPE: 'CUNY Pipeline Program',
    MEC01_PIPE: 'CUNY Pipeline Program',
    SPS01_PIPE: 'CUNY Pipeline Program',
    HOS01_PREP: 'CUNY Prep',
    KCC01_CRSP: 'CUNY Research Scholars Program',
    BKL01_CSPH: 'CUNY School of Public Health',
    HTR01_CSPH: 'CUNY School of Public Health',
    GRD01_FCSC: 'CUNY Science Scholarship',
    BAR01_CSCS: 'CUNY Service Corps Student',
    BCC01_CSCS: 'CUNY Service Corps Student',
    BKL01_CSCS: 'CUNY Service Corps Student',
    BMC01_CSCS: 'CUNY Service Corps Student',
    CSI01_CSCS: 'CUNY Service Corps Student',
    CTY01_CSCS: 'CUNY Service Corps Student',
    GRD01_CSCS: 'CUNY Service Corps Student',
    HOS01_CSCS: 'CUNY Service Corps Student',
    HTR01_CSCS: 'CUNY Service Corps Student',
    JJC01_CSCS: 'CUNY Service Corps Student',
    KCC01_CSCS: 'CUNY Service Corps Student',
    LAG01_CSCS: 'CUNY Service Corps Student',
    LAW01_CSCS: 'CUNY Service Corps Student',
    LEH01_CSCS: 'CUNY Service Corps Student',
    MEC01_CSCS: 'CUNY Service Corps Student',
    MED01_CSCS: 'CUNY Service Corps Student',
    MHC01_CSCS: 'CUNY Service Corps Student',
    NCC01_CSCS: 'CUNY Service Corps Student',
    NYT01_CSCS: 'CUNY Service Corps Student',
    QCC01_CSCS: 'CUNY Service Corps Student',
    QNS01_CSCS: 'CUNY Service Corps Student',
    SOJ01_CSCS: 'CUNY Service Corps Student',
    SPH01_CSCS: 'CUNY Service Corps Student',
    SPS01_CSCS: 'CUNY Service Corps Student',
    YRK01_CSCS: 'CUNY Service Corps Student',
    BKL01_FELL: 'CUNY Teaching Fellow',
    CSI01_FELL: 'CUNY Teaching Fellow',
    CTY01_FELL: 'CUNY Teaching Fellow',
    HTR01_FELL: 'CUNY Teaching Fellow',
    LEH01_FELL: 'CUNY Teaching Fellow',
    QCC01_FELL: 'CUNY Teaching Fellow',
    QNS01_FELL: 'CUNY Teaching Fellow',
    BKL01_CBSA: 'CUNY-BC Study Abroad',
    BKL01_CUBI: 'CUNYBA - Permitted In',
    BKL01_CUBH: 'CUNYBA - at Home College',
    GRD01_LEX: 'CUNYBA Language Exemption',
    GRD01_MHC: 'CUNYBA Macaulay Honors College',
    GRD01_PROV: 'CUNYBA Provisional Admit',
    BAR01_STRT: 'CUNYStart Enrolled',
    BCC01_STRT: 'CUNYStart Enrolled',
    BKL01_STRT: 'CUNYStart Enrolled',
    BMC01_STRT: 'CUNYStart Enrolled',
    CSI01_STRT: 'CUNYStart Enrolled',
    CTY01_STRT: 'CUNYStart Enrolled',
    HOS01_STRT: 'CUNYStart Enrolled',
    HTR01_STRT: 'CUNYStart Enrolled',
    JJC01_STRT: 'CUNYStart Enrolled',
    KCC01_STRT: 'CUNYStart Enrolled',
    LAG01_STRT: 'CUNYStart Enrolled',
    LAW01_STRT: 'CUNYStart Enrolled',
    LEH01_STRT: 'CUNYStart Enrolled',
    MEC01_STRT: 'CUNYStart Enrolled',
    NCC01_STRT: 'CUNYStart Enrolled',
    NYT01_STRT: 'CUNYStart Enrolled',
    QCC01_STRT: 'CUNYStart Enrolled',
    QNS01_STRT: 'CUNYStart Enrolled',
    SPS01_STRT: 'CUNYStart Enrolled',
    YRK01_STRT: 'CUNYStart Enrolled',
    BCC01_STRE: 'CUNYStart Exited',
    BKL01_STRE: 'CUNYStart Exited',
    BMC01_STRE: 'CUNYStart Exited',
    CSI01_STRE: 'CUNYStart Exited',
    CTY01_STRE: 'CUNYStart Exited',
    HOS01_STRE: 'CUNYStart Exited',
    HTR01_STRE: 'CUNYStart Exited',
    JJC01_STRE: 'CUNYStart Exited',
    KCC01_STRE: 'CUNYStart Exited',
    LAG01_STRE: 'CUNYStart Exited',
    LAW01_STRE: 'CUNYStart Exited',
    LEH01_STRE: 'CUNYStart Exited',
    MEC01_STRE: 'CUNYStart Exited',
    MED01_STRE: 'CUNYStart Exited',
    NCC01_STRE: 'CUNYStart Exited',
    NYT01_STRE: 'CUNYStart Exited',
    QCC01_STRE: 'CUNYStart Exited',
    QNS01_STRE: 'CUNYStart Exited',
    SPH01_STRE: 'CUNYStart Exited',
    SPS01_STRE: 'CUNYStart Exited',
    YRK01_STRE: 'CUNYStart Exited',
    BAR01_STRP: 'CUNYStart Potential',
    BCC01_STRP: 'CUNYStart Potential',
    BKL01_STRP: 'CUNYStart Potential',
    BMC01_STRP: 'CUNYStart Potential',
    CSI01_STRP: 'CUNYStart Potential',
    CTY01_STRP: 'CUNYStart Potential',
    HOS01_STRP: 'CUNYStart Potential',
    HTR01_STRP: 'CUNYStart Potential',
    JJC01_STRP: 'CUNYStart Potential',
    KCC01_STRP: 'CUNYStart Potential',
    LAG01_STRP: 'CUNYStart Potential',
    LAW01_STRP: 'CUNYStart Potential',
    LEH01_STRP: 'CUNYStart Potential',
    MEC01_STRP: 'CUNYStart Potential',
    MED01_STRP: 'CUNYStart Potential',
    NCC01_STRP: 'CUNYStart Potential',
    NYT01_STRP: 'CUNYStart Potential',
    QCC01_STRP: 'CUNYStart Potential',
    QNS01_STRP: 'CUNYStart Potential',
    SPH01_STRP: 'CUNYStart Potential',
    SPS01_STRP: 'CUNYStart Potential',
    YRK01_STRP: 'CUNYStart Potential',
    JJC01_CUSP: 'CUSP Studnet',
    JJC01_CEED: 'Cadet Edu Empowerment & Dev',
    LEH01_CA01: 'Cadre 1 Teaching Fellows',
    LEH01_CA10: 'Cadre 10 Teaching Fellows',
    LEH01_CA12: 'Cadre 12 Teaching Fellows',
    LEH01_CA14: 'Cadre 14 Teaching Fellows',
    LEH01_CA18: 'Cadre 18 Teaching Fellows',
    LEH01_CA20: 'Cadre 20 Teaching Fellows',
    LEH01_CA22: 'Cadre 22 Teaching Fellows',
    LEH01_CA03: 'Cadre 3 Teaching Fellows',
    LEH01_CA05: 'Cadre 5 Teaching Fellows',
    LEH01_CA06: 'Cadre 6 Teaching Fellows',
    LEH01_CA08: 'Cadre 8 Teaching Fellows',
    HTR01_SPHA: 'Capstone - INC Grade',
    BMC01_C3IM: 'Career & College Comp Inst Men',
    BKL01_CRLD: 'Career Ladder',
    NYT01_CPDL: 'Career Path-Dept of Labor',
    LEH01_HEAL: 'Careers in Health',
    LEH01_CIT: 'Careers in Training',
    BKL01_CARR: 'Carroll Gardens',
    HTR01_CTSC: 'Catalyst Scholars',
    BKL01_CD1: 'Cd Prong I',
    BKL01_CD2: 'Cd Prong Ii',
    BKL01_CASE: 'Center for Achievement in Sci',
    BCC01_RESC: 'Certificate of Residency',
    HOS01_CHEN: 'Chemical Engineering Science',
    LEH01_CARE: 'Child Care Certificate',
    NYT01_CWEL: 'Child Welfare-Human Resrcs Adm',
    SPS01_CPAC: 'Childrens ProgAdmin Credential',
    HTR01_XCHI: 'China Flagship Ex Program',
    BMC01_C102: 'Chineese 102',
    BMC01_C105: 'Chinese 105',
    BMC01_C106: 'Chinese 106',
    BMC01_C107: 'Chinese 107',
    BMC01_CHI2: 'Chinese 200',
    LAG01_C105: 'Chinese Heritage 1',
    LAG01_C106: 'Chinese Heritage 2',
    JJC01_CHI1: 'Chinese Placement 101',
    JJC01_CHI2: 'Chinese Placement 102',
    CSI01_CITZ: 'Citizenship Proof',
    JJC01_CITP: 'Citizenship Residency Status',
    CTY01_CELI: 'City College ELI Student',
    CSI01_HCPH: 'City Poly High School Program',
    NYT01_HSCP: 'City Poly High School Program',
    HOS01_CIEN: 'Civil Engineering Science',
    CTY01_CPFL: 'Colin Powell Fellows',
    BAR01_CMAS: 'Coll Management Academic Stud',
    UAPC1_CCBA: 'College & Career Bridge 4 All',
    KCC01_CAAP: 'College Acad AdvisementProgram',
    LAG01_CLAC: 'College Access and Success',
    LAG01_CNCT: 'College Connect',
    BKL01_CCPR: 'College Connect Project',
    BCC01_CDGR: 'College Discovery Graduate',
    BCC01_CD: 'College Discovery Student',
    BMC01_CD: 'College Discovery Student',
    HOS01_CD: 'College Discovery Student',
    KCC01_CD: 'College Discovery Student',
    LAG01_CD: 'College Discovery Student',
    NCC01_CD: 'College Discovery Student',
    QCC01_CD: 'College Discovery Student',
    QNS01_CD: 'College Discovery Student',
    BCC01_COFO: 'College Focus High School Prog',
    HOS01_COFO: 'College Focus High School Prog',
    KCC01_COFO: 'College Focus High School Prog',
    LAG01_COFO: 'College Focus High School Prog',
    NCC01_COFO: 'College Focus High School Prog',
    QCC01_COFO: 'College Focus High School Prog',
    LEH01_LINK: 'College Link',
    NYT01_CNCO: 'College Now Cohort',
    KCC01_CNOC: 'College Now On Campus',
    KCC01_CNPP: 'College Now President\'s Prep',
    BAR01_CNOW: 'College Now Student',
    BCC01_CNOW: 'College Now Student',
    BKL01_CNOW: 'College Now Student',
    BMC01_CNOW: 'College Now Student',
    CSI01_CNOW: 'College Now Student',
    CTY01_CNOW: 'College Now Student',
    HOS01_CNOW: 'College Now Student',
    HTR01_CNOW: 'College Now Student',
    JJC01_CNOW: 'College Now Student',
    KCC01_CNOW: 'College Now Student',
    LAG01_CNOW: 'College Now Student',
    LAW01_CNOW: 'College Now Student',
    LEH01_CNOW: 'College Now Student',
    MEC01_CNOW: 'College Now Student',
    NCC01_CNOW: 'College Now Student',
    NYT01_CNOW: 'College Now Student',
    QCC01_CNOW: 'College Now Student',
    QNS01_CNOW: 'College Now Student',
    SPS01_CNOW: 'College Now Student',
    YRK01_CNOW: 'College Now Student',
    NYT01_CNMT: 'College Now-METEAS Ed Program',
    BKL01_CNTP: 'College Now-Tuition Payment',
    NYT01_CO01: 'College Option - 9 CR Required',
    CSI01_CO01: 'College Option - 9 Cr Required',
    LEH01_CO01: 'College Option - 9 Credits Req',
    HTR01_CO04: 'College Option Sequence  4',
    HTR01_CO00: 'College Option Sequence 0',
    QNS01_CO00: 'College Option Sequence 0',
    YRK01_CO00: 'College Option Sequence 0',
    HTR01_CO01: 'College Option Sequence 1',
    QNS01_CO01: 'College Option Sequence 1',
    HTR01_CO02: 'College Option Sequence 2',
    QNS01_CO02: 'College Option Sequence 2',
    HTR01_CO03: 'College Option Sequence 3',
    QNS01_CO03: 'College Option Sequence 3',
    QNS01_CO04: 'College Option Sequence 4',
    LEH01_CO12: 'College Option-12 Credits Req',
    CSI01_CSIP: 'College Success Initiative',
    CSI01_COSU: 'College Success Int Learn Comm',
    KCC01_CWST: 'College Work Study Student',
    KCC01_FRGV: 'Comeback Campaign candidate',
    HTR01_COMC: 'Commitment Confirmed',
    HTR01_COMX: 'Commitment Deposit Ex',
    HTR01_COME: 'Commitment Deposit Expired',
    HTR01_COMP: 'Commitment Deposit Paid',
    BCC01_COMW: 'Commitment Deposit Waiver',
    BMC01_COMW: 'Commitment Deposit Waiver',
    HTR01_COMW: 'Commitment Deposit Waiver',
    JJC01_CDME: 'Common Dept Math Exam',
    SLU01_1180: 'Communications Workers-America',
    BMC01_CBOS: 'Community Based Org Student',
    HTR01_CCPS: 'Community College Partnership',
    SLU01_COML: 'Community Leadership',
    SPS01_COML: 'Community Leadership',
    HTR01_CMSC: 'Community Scholars Cohort',
    SLU01_CMST: 'Community Semester',
    HTR01_FREN: 'Completing French Lang Seq',
    HTR01_ITAL: 'Completing Ital Language Seq',
    HTR01_SPAN: 'Completing Span Language Seq',
    YRK01_TEE6: 'Completion of NY State Cert.',
    CSI01_CPST: 'Completion of Student Teaching',
    YRK01_TEE4: 'Completion of Student Teaching',
    YRK01_TEE5: 'Completion of TE Program',
    CSI01_DEVC: 'Computer Device',
    LEH01_C106: 'Computer Info Systems 106',
    LEH01_C166: 'Computer Info Systems 166',
    BMC01_CIS2: 'Computer Info Systems 200',
    LEH01_C211: 'Computer Info Systems 211',
    LEH01_C212: 'Computer Info Systems 212',
    LEH01_C228: 'Computer Info Systems 228',
    LEH01_C234: 'Computer Info Systems 234',
    LEH01_C244: 'Computer Info Systems 244',
    LEH01_C331: 'Computer Info Systems 331',
    LEH01_C344: 'Computer Info Systems 344',
    LEH01_C230: 'Computer Programming 230',
    LEH01_C326: 'Computer Programming 326',
    HTR01_CSHC: 'Computer Science Honors Cohort',
    BKL01_CONA: 'Conditional Admit',
    SLU01_COND: 'Conditional Admit',
    SPS01_COND: 'Conditional Admit',
    YRK01_COAD: 'Conditional Admit  Student',
    BCC01_243H: 'Conditional Admit Student',
    HOS01_243H: 'Conditional Admit Student',
    KCC01_243H: 'Conditional Admit Student',
    LAG01_243H: 'Conditional Admit Student',
    LEH01_243H: 'Conditional Admit Student',
    QCC01_243H: 'Conditional Admit Student',
    QNS01_243H: 'Conditional Admit Student',
    LEH01_COAD: 'Conditional Admit Student.',
    JJC01_CNDT: 'Conditional Admit Undergrad',
    JJC01_COAD: 'Conditional Admit Undergrad',
    YRK01_TEE1: 'Conditional Admit to TE Prog',
    CSI01_CBAC: 'Conditional Baccalaureate',
    HTR01_CNDM: 'Conditional Majors',
    JJC01_CMGR: 'Conditional Matric Graduate',
    HTR01_CNDN: 'Conditional Nursing',
    JJC01_CNDP: 'Conditional Passed Exams',
    LAG01_CRTS: 'Conditional Road to Success',
    BAR01_SCD2: 'Conditional SEEK/CD',
    BCC01_SCD2: 'Conditional SEEK/CD',
    BKL01_SCD2: 'Conditional SEEK/CD',
    BMC01_SCD2: 'Conditional SEEK/CD',
    CSI01_SCD2: 'Conditional SEEK/CD',
    CTY01_SCD2: 'Conditional SEEK/CD',
    HOS01_SCD2: 'Conditional SEEK/CD',
    HTR01_SCD2: 'Conditional SEEK/CD',
    JJC01_SCD2: 'Conditional SEEK/CD',
    KCC01_SCD2: 'Conditional SEEK/CD',
    LAG01_SCD2: 'Conditional SEEK/CD',
    LAW01_SCD2: 'Conditional SEEK/CD',
    LEH01_SCD2: 'Conditional SEEK/CD',
    MEC01_SCD2: 'Conditional SEEK/CD',
    NCC01_SCD2: 'Conditional SEEK/CD',
    NYT01_SCD2: 'Conditional SEEK/CD',
    QCC01_SCD2: 'Conditional SEEK/CD',
    QNS01_SCD2: 'Conditional SEEK/CD',
    SPS01_SCD2: 'Conditional SEEK/CD',
    YRK01_SCD2: 'Conditional SEEK/CD',
    CSI01_ATLC: 'Conditional Student Athletes',
    BKL01_CMET: 'Conditions Met',
    BKL01_COND: 'Conditions Set By Dept',
    LAG01_SC2C: 'Connect to Complete',
    QCC01_CP: 'Connect2College',
    BMC01_CONS: 'Consortium Agreement',
    LEH01_CONS: 'Consortium Agreement',
    HTR01_RCOB: 'Continued Off Probation',
    HTR01_RCPB: 'Continued On Probation',
    CSI01_CADV: 'Continuing Advisement',
    LAG01_ACEL: 'Continuing Educ Student',
    CSI01_CSIU: 'Continuing Education/SIUH',
    KCC01_PRB2: 'Continuing Probation Students',
    CSI01_CONT: 'Continuing Student',
    QCC01_CST2: 'Control Group Student',
    KCC01_N1: 'Control Opening Doors Fa03',
    KCC01_N3: 'Control Opening Doors Fa04',
    KCC01_N5: 'Control Opening Doors Fa07',
    KCC01_N7: 'Control Opening Doors Fa08',
    KCC01_N9: 'Control Opening Doors Fa09',
    KCC01_N2: 'Control Opening Doors Sp04',
    KCC01_N4: 'Control Opening Doors Sp05',
    KCC01_N6: 'Control Opening Doors Sp08',
    KCC01_N8: 'Control Opening Doors Sp09',
    SPH01_CONV: 'Converted Records',
    BAR01_COOP: 'Cooperating Teacher',
    BCC01_COOP: 'Cooperating Teacher',
    BKL01_COOP: 'Cooperating Teacher',
    BMC01_COOP: 'Cooperating Teacher',
    CSI01_COOP: 'Cooperating Teacher',
    CTY01_COOP: 'Cooperating Teacher',
    HOS01_COOP: 'Cooperating Teacher',
    HTR01_COOP: 'Cooperating Teacher',
    JJC01_COOP: 'Cooperating Teacher',
    KCC01_COOP: 'Cooperating Teacher',
    LAG01_COOP: 'Cooperating Teacher',
    LAW01_COOP: 'Cooperating Teacher',
    LEH01_COOP: 'Cooperating Teacher',
    MEC01_COOP: 'Cooperating Teacher',
    NCC01_COOP: 'Cooperating Teacher',
    NYT01_COOP: 'Cooperating Teacher',
    QCC01_COOP: 'Cooperating Teacher',
    QNS01_COOP: 'Cooperating Teacher',
    SPS01_COOP: 'Cooperating Teacher',
    YRK01_COOP: 'Cooperating Teacher',
    HTR01_CPMN: 'Cooperman Scholars',
    BKL01_ENGN: 'Coordinated Engineering Prgm',
    CTY01_XREG: 'Course Reg incentive',
    BAR01_EMAL: 'Create Baruch Email',
    SLU01_CDMP: 'Credible Messenger Program',
    BCC01_CRDM: 'Credit Momentum',
    BMC01_CRDM: 'Credit Momentum',
    LAG01_CDRM: 'Credit Momentum',
    QCC01_CRDM: 'Credit Momentum',
    CSI01_CROS: 'Cross Country',
    BKL01_CSTP: 'Cstep Program',
    NYT01_CYHL: 'Cypress Hills',
    LEH01_RECV: 'DB Rec Verified VS Hard Copy',
    LAG01_DC37: 'DC37 - Excluding Schfd Mng',
    SLU01_DC37: 'DC37 Certificate Students',
    HOS01_DNTL: 'DENTAL HYG. (CLINICAL PHASE)',
    HOS01_DEN3: 'DENTAL-3YR (CLINICAL PHASE)',
    KCC01_3D: 'DLS Selected Exit Interview',
    HTR01_STAC: 'DOE-Subsidized TESOL Addl Cert',
    QCC01_DOL2: 'DOL - No Tuition Waiver',
    QNS01_DQ: 'DOMINICAN EXCHANGE PROGRAM',
    BAR01_DAY: 'Day',
    BCC01_DAY: 'Day',
    BKL01_DAY: 'Day',
    BMC01_DAY: 'Day',
    CSI01_DAY: 'Day',
    CTY01_DAY: 'Day',
    GRD01_DAY: 'Day',
    HOS01_DAY: 'Day',
    HTR01_DAY: 'Day',
    JJC01_DAY: 'Day',
    KCC01_DAY: 'Day',
    LAG01_DAY: 'Day',
    LAW01_DAY: 'Day',
    LEH01_DAY: 'Day',
    MEC01_DAY: 'Day',
    NCC01_DAY: 'Day',
    NYT01_DAY: 'Day',
    QCC01_DAY: 'Day',
    QNS01_DAY: 'Day',
    SOJ01_DAY: 'Day',
    SPS01_DAY: 'Day',
    YRK01_DAY: 'Day',
    KCC01_DCP: 'Day Care Parent',
    LAG01_DFAD: 'Deaf Adult Program',
    BAR01_DSCO: 'Dean Scholar Offer',
    BAR01_DSCH: 'Dean\'s Scholar',
    KCC01_V4: 'Decision Pendg Descr 10/29/07',
    LAG01_DFER: 'Deferral of Admission',
    CTY01_DACA: 'Deferred Action Child Arrival',
    BMC01_DGU3: 'Degree Under Three',
    KCC01_DGWD: 'Degrees When Due Students',
    QCC01_AFSD: 'Denied Academic Fresh Start',
    HTR01_DADM: 'Denied Readmission',
    SPS01_SCD3: 'Denied/ Declined SEEK/CD',
    YRK01_SCD3: 'Denied/ Declined SEEK/CD',
    JJC01_ACE3: 'Denied/Declined ACE',
    LEH01_ACE3: 'Denied/Declined ACE',
    NYT01_ACE3: 'Denied/Declined ACE',
    BAR01_ASP4: 'Denied/Declined ASAP',
    BCC01_ASP4: 'Denied/Declined ASAP',
    BKL01_ASP4: 'Denied/Declined ASAP',
    BMC01_ASP4: 'Denied/Declined ASAP',
    CSI01_ASP4: 'Denied/Declined ASAP',
    CTY01_ASP4: 'Denied/Declined ASAP',
    HOS01_ASP4: 'Denied/Declined ASAP',
    HTR01_ASP4: 'Denied/Declined ASAP',
    JJC01_ASP4: 'Denied/Declined ASAP',
    KCC01_ASP4: 'Denied/Declined ASAP',
    LAG01_ASP4: 'Denied/Declined ASAP',
    LAW01_ASP4: 'Denied/Declined ASAP',
    LEH01_ASP4: 'Denied/Declined ASAP',
    MEC01_ASP4: 'Denied/Declined ASAP',
    NCC01_ASP4: 'Denied/Declined ASAP',
    NYT01_ASP4: 'Denied/Declined ASAP',
    QCC01_ASP4: 'Denied/Declined ASAP',
    QNS01_ASP4: 'Denied/Declined ASAP',
    YRK01_ASP4: 'Denied/Declined ASAP',
    QCC01_CST3: 'Denied/Declined CUNYStart',
    BAR01_SCD3: 'Denied/Declined SEEK/CD',
    BCC01_SCD3: 'Denied/Declined SEEK/CD',
    BKL01_SCD3: 'Denied/Declined SEEK/CD',
    BMC01_SCD3: 'Denied/Declined SEEK/CD',
    CSI01_SCD3: 'Denied/Declined SEEK/CD',
    CTY01_SCD3: 'Denied/Declined SEEK/CD',
    HOS01_SCD3: 'Denied/Declined SEEK/CD',
    HTR01_SCD3: 'Denied/Declined SEEK/CD',
    JJC01_SCD3: 'Denied/Declined SEEK/CD',
    KCC01_SCD3: 'Denied/Declined SEEK/CD',
    LAG01_SCD3: 'Denied/Declined SEEK/CD',
    LAW01_SCD3: 'Denied/Declined SEEK/CD',
    LEH01_SCD3: 'Denied/Declined SEEK/CD',
    MEC01_SCD3: 'Denied/Declined SEEK/CD',
    NCC01_SCD3: 'Denied/Declined SEEK/CD',
    NYT01_SCD3: 'Denied/Declined SEEK/CD',
    QCC01_SCD3: 'Denied/Declined SEEK/CD',
    QNS01_SCD3: 'Denied/Declined SEEK/CD',
    BCC01_DOH: 'Department of Health',
    BAR01_TRDE: 'Dept Evaluation Pending',
    SPH01_DHMH: 'Dept Mntl Hlth Hygn',
    JJC01_DOCL: 'Dept of Corrections Leadership',
    BCC01_DOL: 'Dept. of Labor CUNY Consortium',
    HOS01_DOL: 'Dept. of Labor CUNY Consortium',
    KCC01_DOL: 'Dept. of Labor CUNY Consortium',
    LAG01_DOL: 'Dept. of Labor CUNY Consortium',
    LAW01_DOL: 'Dept. of Labor CUNY Consortium',
    LEH01_DOL: 'Dept. of Labor CUNY Consortium',
    NCC01_DOL: 'Dept. of Labor CUNY Consortium',
    QCC01_DOL: 'Dept. of Labor CUNY Consortium',
    QNS01_DOL: 'Dept. of Labor CUNY Consortium',
    BKL01_DEWW: 'Dewitt-Wallace Gradute Progm',
    QNS01_DIPI: 'Diamond Fellow / CUNY Pipeline',
    SPH01_DIPH: 'Dietetic Intern PH Nutrition',
    NYT01_DIUN: 'Diplomat/Dependent of UN Empl',
    LEH01_DCGN: 'Dir Care Gen Cert-Mult Agns',
    BAR01_ZK01: 'Direct Admissions to Zicklin',
    BCC01_ADMD: 'Direct Admit',
    CSI01_DADM: 'Direct Admit',
    HOS01_ADMD: 'Direct Admit',
    HTR01_DRCT: 'Direct Admit',
    KCC01_ADMD: 'Direct Admit',
    LAG01_ADMD: 'Direct Admit',
    QCC01_ADMD: 'Direct Admit',
    BAR01_DAFR: 'Direct Admit Freshman',
    CTY01_DAFR: 'Direct Admit Freshman',
    JJC01_DAFR: 'Direct Admit Freshman',
    MEC01_DAFR: 'Direct Admit Freshman',
    JJC01_DADI: 'Direct Admit In Service',
    BAR01_DATR: 'Direct Admit Transfer',
    CTY01_DATR: 'Direct Admit Transfer',
    JJC01_DATR: 'Direct Admit Transfer',
    MEC01_DATR: 'Direct Admit Transfer',
    BKL01_DIRC: 'Direct Admits',
    LAG01_DADM: 'Direct Admits',
    LEH01_DIRC: 'Direct Care Generalist-1199',
    LEH01_FEGS: 'Direct Care Generalist-Fegs',
    LEH01_UCPN: 'Direct Care Generalist-UCP Nas',
    NYT01_FDIR: 'Direct Freshman',
    JJC01_DLIN: 'Direct Loan Interview Done',
    BKL01_DLS1: 'Direct Loan Stdnt Req Exit Int',
    BKL01_DLS2: 'Direct Loan Stu Req. Exit Int2',
    BCC01_243E: 'Direct LoanStdn Exit Interview',
    HOS01_243E: 'Direct LoanStdn Exit Interview',
    LEH01_243E: 'Direct LoanStdn Exit Interview',
    NYT01_243E: 'Direct LoanStdn Exit Interview',
    QCC01_243E: 'Direct LoanStdn Exit Interview',
    SLU01_243E: 'Direct LoanStdn Exit Interview',
    BAR01_243E: 'Direct LoanStud Exit Interview',
    KCC01_243E: 'Direct LoanStud Exit Interview',
    LAG01_243E: 'Direct LoanStud Exit Interview',
    LAW01_243E: 'Direct LoanStud Exit Interview',
    QNS01_243E: 'Direct LoanStud Exit Interview',
    NYT01_TDIR: 'Direct Transfer',
    QCC01_DSPL: 'Directed Self Placement',
    CSI01_DISC: 'Discovery',
    CSI01_DTOC: 'Discovery/TOC',
    BKL01_DISM: 'Dismissal - Graduate',
    YRK01_DSOT: 'Dismissal from BS/MS OT Prog',
    YRK01_DSMN: 'Do not send mail to student',
    CSI01_COVE: 'Dolphin Cove',
    CSI01_PCOV: 'Dolphin Cove',
    CSI01_WCOV: 'Dolphin Cove',
    CSI01_ACOV: 'Dolphin Cove Applicants',
    BAR01_DEXP: 'Domestic Executive Program',
    LEH01_DRSS: 'Dominican Rep Scholarship Stud',
    BCC01_XDOM: 'DominicanRep Exchange Program',
    BKL01_XDOM: 'DominicanRep Exchange Program',
    CTY01_XDOM: 'DominicanRep Exchange Program',
    HOS01_XDOM: 'DominicanRep Exchange Program',
    HTR01_XDOM: 'DominicanRep Exchange Program',
    KCC01_XDOM: 'DominicanRep Exchange Program',
    LEH01_XDOM: 'DominicanRep Exchange Program',
    NYT01_XDOM: 'DominicanRep Exchange Program',
    YRK01_XDOM: 'DominicanRep Exchange Program',
    KCC01_V2: 'Double Exempt Summer Imm ASAP',
    BAR01_DMSH: 'Dreamer Scholars',
    CSI01_DMSH: 'Dreamer Scholars',
    HTR01_DMSH: 'Dreamer Scholars',
    BKL01_DROP: 'Dropped All Courses',
    LEH01_DDEG: 'Dual Degree Students',
    BAR01_DUPU: 'Duplicate Ready to Purge',
    BCC01_DUPU: 'Duplicate Ready to Purge',
    BKL01_DUPU: 'Duplicate Ready to Purge',
    BMC01_DUPU: 'Duplicate Ready to Purge',
    CSI01_DUPU: 'Duplicate Ready to Purge',
    CTY01_DUPU: 'Duplicate Ready to Purge',
    GRD01_DUPU: 'Duplicate Ready to Purge',
    HOS01_DUPU: 'Duplicate Ready to Purge',
    HTR01_DUPU: 'Duplicate Ready to Purge',
    JJC01_DUPU: 'Duplicate Ready to Purge',
    KCC01_DUPU: 'Duplicate Ready to Purge',
    LAG01_DUPU: 'Duplicate Ready to Purge',
    LAW01_DUPU: 'Duplicate Ready to Purge',
    LEH01_DUPU: 'Duplicate Ready to Purge',
    MEC01_DUPU: 'Duplicate Ready to Purge',
    MED01_DUPU: 'Duplicate Ready to Purge',
    NCC01_DUPU: 'Duplicate Ready to Purge',
    NYT01_DUPU: 'Duplicate Ready to Purge',
    QCC01_DUPU: 'Duplicate Ready to Purge',
    QNS01_DUPU: 'Duplicate Ready to Purge',
    SLU01_DUPU: 'Duplicate Ready to Purge',
    SOJ01_DUPU: 'Duplicate Ready to Purge',
    SPH01_DUPU: 'Duplicate Ready to Purge',
    SPS01_DUPU: 'Duplicate Ready to Purge',
    YRK01_DUPU: 'Duplicate Ready to Purge',
    BAR01_EDGE: 'EDGE Student',
    BCC01_EDGE: 'EDGE Student',
    BKL01_EDGE: 'EDGE Student',
    BMC01_EDGE: 'EDGE Student',
    CSI01_EDGE: 'EDGE Student',
    CTY01_EDGE: 'EDGE Student',
    HOS01_EDGE: 'EDGE Student',
    HTR01_EDGE: 'EDGE Student',
    JJC01_EDGE: 'EDGE Student',
    KCC01_EDGE: 'EDGE Student',
    LAG01_EDGE: 'EDGE Student',
    LEH01_EDGE: 'EDGE Student',
    MEC01_EDGE: 'EDGE Student',
    NCC01_EDGE: 'EDGE Student',
    NYT01_EDGE: 'EDGE Student',
    QCC01_EDGE: 'EDGE Student',
    QNS01_EDGE: 'EDGE Student',
    SPS01_EDGE: 'EDGE Student',
    YRK01_EDGE: 'EDGE Student',
    LAG01_C103: 'ELC103: Intermediate Chinese 1',
    BMC01_C200: 'ELC200: Advanced Chinese',
    LAG01_G103: 'ELG103: Intermediate Greek',
    LAG01_H102: 'ELH102: Elementary Hebrew 2',
    LAG01_H200: 'ELH201: Contemp Hebrew Lit',
    LAG01_P102: 'ELP102: Elementary Polish 2',
    LAG01_P103: 'ELP103: Intermediate Polish',
    LAG01_P105: 'ELP105: Polish for Heritage Sp',
    LAG01_P200: 'ELP201: Polish Literature',
    LAG01_R102: 'ELR102: Elementary Russian 2',
    LAG01_R103: 'ELR103: Intermediate Russian',
    LAG01_R105: 'ELR105:Russian for Heritage Sp',
    LAG01_R200: 'ELR201:Russian Lit of 20th Cen',
    LAG01_S102: 'ELS102: Elementary Spanish 2',
    LAG01_S103: 'ELS103: Intermediate Spanish 1',
    LAG01_S104: 'ELS104: Intermediate Spanish 2',
    LAG01_T102: 'ELT102:  Elementary Tibetan 2',
    LAG01_V102: 'ELV102: Elementary Bengali 2',
    LAG01_V103: 'ELV103: Intermediate Bengali',
    LAG01_Z102: 'ELZ102:Elementary Portuguese 2',
    BKL01_EMS: 'EMS',
    LAG01_WR55: 'ENA 101 (Continuing)',
    LAG01_WR56: 'ENA 101 (NEW)',
    LAG01_WR58: 'ENA099 Placement Local Code993',
    LAG01_WR60: 'ENC101 Placement Local Code995',
    HTR01_ENRS: 'ENDURE Neuroscience Res Schl',
    BAR01_E132: 'ENG 0132 Placement',
    BMC01_ESL6: 'ENG 100.6',
    BAR01_E210: 'ENG 2100 Placement',
    BAR01_E21T: 'ENG 2100T Placement',
    BAR01_E215: 'ENG 2150 Placement',
    BAR01_E25T: 'ENG 2150T Placement',
    BMC01_ENG1: 'ENG 88',
    BMC01_ENG2: 'ENG 95',
    LAG01_WR59: 'ENG099 Placement Local Code994',
    LAG01_WR51: 'ENG101 Placement Local Code996',
    HTR01_SPH6: 'EPI/BIOS Stud - EPI Focus',
    LAG01_WR57: 'ESA 099 Placement',
    LAG01_WR80: 'ESC99 Placement',
    CTY01_ES20: 'ESL 12000',
    CTY01_EINT: 'ESL 12000 & ESL 12100',
    CTY01_ES21: 'ESL 12100',
    CTY01_ES30: 'ESL 13000',
    CTY01_EHIG: 'ESL 13000 & ESL 19900',
    CTY01_ES99: 'ESL 19900',
    BMC01_ESL1: 'ESL 54',
    BMC01_ESL2: 'ESL 62',
    BMC01_ESL3: 'ESL 94',
    BMC01_ESL4: 'ESL 95',
    BMC01_ESL5: 'ESL 96',
    BAR01_ESLR: 'ESL CATW Readers',
    BCC01_ESLR: 'ESL CATW Readers',
    BKL01_ESLR: 'ESL CATW Readers',
    BMC01_ESLR: 'ESL CATW Readers',
    CSI01_ESLR: 'ESL CATW Readers',
    CTY01_ESLR: 'ESL CATW Readers',
    HOS01_ESLR: 'ESL CATW Readers',
    HTR01_ESLR: 'ESL CATW Readers',
    JJC01_ESLR: 'ESL CATW Readers',
    KCC01_ESLR: 'ESL CATW Readers',
    LAG01_ESLR: 'ESL CATW Readers',
    LAW01_ESLR: 'ESL CATW Readers',
    LEH01_ESLR: 'ESL CATW Readers',
    MEC01_ESLR: 'ESL CATW Readers',
    NCC01_ESLR: 'ESL CATW Readers',
    NYT01_ESLR: 'ESL CATW Readers',
    QCC01_ESLR: 'ESL CATW Readers',
    QNS01_ESLR: 'ESL CATW Readers',
    YRK01_ESLR: 'ESL CATW Readers',
    BAR01_ESLD: 'ESL Diagnostic Assessment',
    BCC01_ESLD: 'ESL Diagnostic Assessment',
    BKL01_ESLD: 'ESL Diagnostic Assessment',
    BMC01_ESLD: 'ESL Diagnostic Assessment',
    CSI01_ESLD: 'ESL Diagnostic Assessment',
    CTY01_ESLD: 'ESL Diagnostic Assessment',
    GRD01_ESLD: 'ESL Diagnostic Assessment',
    HOS01_ESLD: 'ESL Diagnostic Assessment',
    HTR01_ESLD: 'ESL Diagnostic Assessment',
    JJC01_ESLD: 'ESL Diagnostic Assessment',
    KCC01_ESLD: 'ESL Diagnostic Assessment',
    LAG01_ESLD: 'ESL Diagnostic Assessment',
    LAW01_ESLD: 'ESL Diagnostic Assessment',
    LEH01_ESLD: 'ESL Diagnostic Assessment',
    MEC01_ESLD: 'ESL Diagnostic Assessment',
    MED01_ESLD: 'ESL Diagnostic Assessment',
    MHC01_ESLD: 'ESL Diagnostic Assessment',
    NCC01_ESLD: 'ESL Diagnostic Assessment',
    NYT01_ESLD: 'ESL Diagnostic Assessment',
    QCC01_ESLD: 'ESL Diagnostic Assessment',
    QNS01_ESLD: 'ESL Diagnostic Assessment',
    SLU01_ESLD: 'ESL Diagnostic Assessment',
    SOJ01_ESLD: 'ESL Diagnostic Assessment',
    SPH01_ESLD: 'ESL Diagnostic Assessment',
    SPS01_ESLD: 'ESL Diagnostic Assessment',
    UAPC1_ESLD: 'ESL Diagnostic Assessment',
    YRK01_ESLD: 'ESL Diagnostic Assessment',
    BCC01_ESL1: 'ESL Level 1',
    HOS01_ESL1: 'ESL Level 1',
    BCC01_ESL2: 'ESL Level 2',
    HOS01_ESL2: 'ESL Level 2',
    BCC01_ESL3: 'ESL Level 3',
    HOS01_ESL3: 'ESL Level 3',
    HOS01_ESL4: 'ESL Level 4',
    HOS01_ESL5: 'ESL Level 5',
    HOS01_ESL6: 'ESL Level 6',
    CSI01_ESLA: 'ESL Placement Level Advanced',
    CSI01_ESLH: 'ESL Placement Level High',
    CSI01_ESLL: 'ESL Placement Level Low/Interm',
    BAR01_ESL: 'ESL Student',
    BCC01_ESL: 'ESL Student',
    BKL01_ESL: 'ESL Student',
    BMC01_ESL: 'ESL Student',
    CSI01_ESL: 'ESL Student',
    CTY01_ESL: 'ESL Student',
    HOS01_ESL: 'ESL Student',
    HTR01_ESL: 'ESL Student',
    JJC01_ESL: 'ESL Student',
    KCC01_ESL: 'ESL Student',
    LAG01_ESL: 'ESL Student',
    LAW01_ESL: 'ESL Student',
    LEH01_ESL: 'ESL Student',
    MEC01_ESL: 'ESL Student',
    NCC01_ESL: 'ESL Student',
    NYT01_ESL: 'ESL Student',
    QCC01_ESL: 'ESL Student',
    QNS01_ESL: 'ESL Student',
    SLU01_ESL: 'ESL Student',
    SPS01_ESL: 'ESL Student',
    YRK01_ESL: 'ESL Student',
    HOS01_ESLI: 'ESL Student - Intensive',
    BCC01_ESLI: 'ESL Student - Intenstive',
    KCC01_ESLI: 'ESL Student - Intenstive',
    LAG01_TFLW: 'ESL/TOEFL Waived',
    LAG01_WR66: 'ESL096 Placement Code 960',
    LAG01_WR67: 'ESL097 Placement Code 970',
    LAG01_WR68: 'ESL098 Placement Code 980',
    LAG01_WR69: 'ESL099 Placement Code 990',
    LAG01_WR77: 'ESR097 Placement Code 971',
    LAG01_WR78: 'ESR098 Placement Code 981',
    LAG01_WR79: 'ESR099 Placement Code 991',
    QNS01_EX: 'EXTENSION CENTER STUDENTS',
    BAR01_ZK02: 'Early Admissions to Zicklin',
    BCC01_24EA: 'Early Admits w/o HS Diploma',
    BKL01_24EA: 'Early Admits w/o HS Diploma',
    BMC01_24EA: 'Early Admits w/o HS Diploma',
    CSI01_24EA: 'Early Admits w/o HS Diploma',
    HOS01_24EA: 'Early Admits w/o HS Diploma',
    HTR01_24EA: 'Early Admits w/o HS Diploma',
    KCC01_24EA: 'Early Admits w/o HS Diploma',
    LAG01_24EA: 'Early Admits w/o HS Diploma',
    LEH01_24EA: 'Early Admits w/o HS Diploma',
    MEC01_24EA: 'Early Admits w/o HS Diploma',
    NCC01_24EA: 'Early Admits w/o HS Diploma',
    NYT01_24EA: 'Early Admits w/o HS Diploma',
    YRK01_24EA: 'Early Admits w/o HS Diploma',
    QCC01_24EA: 'Early Admits w/o HS diploma',
    QNS01_24EA: 'Early Admits w/o HS diploma',
    LAG01_EAP: 'Early Alert Program',
    BCC01_ECCG: 'Early Childhood Center Grant',
    SPS01_ECE: 'Early Childhood Education',
    LAG01_ECLC: 'Early Childhood Learning Cntr',
    LAG01_ECAC: 'Early College AdvIsIngCaseload',
    BAR01_ECI: 'Early College Initiative',
    BCC01_ECI: 'Early College Initiative',
    BKL01_ECI: 'Early College Initiative',
    BMC01_ECI: 'Early College Initiative',
    CSI01_ECI: 'Early College Initiative',
    CTY01_ECI: 'Early College Initiative',
    HOS01_ECI: 'Early College Initiative',
    HTR01_ECI: 'Early College Initiative',
    JJC01_ECI: 'Early College Initiative',
    KCC01_ECI: 'Early College Initiative',
    LAG01_ECI: 'Early College Initiative',
    LAW01_ECI: 'Early College Initiative',
    LEH01_ECI: 'Early College Initiative',
    MEC01_ECI: 'Early College Initiative',
    NCC01_ECI: 'Early College Initiative',
    NYT01_ECI: 'Early College Initiative',
    QCC01_ECI: 'Early College Initiative',
    QNS01_ECI: 'Early College Initiative',
    SPS01_ECI: 'Early College Initiative',
    YRK01_ECI: 'Early College Initiative',
    HTR01_DEGR: 'Early Degree Review',
    JJC01_ERLY: 'Early Start',
    BCC01_AS2: 'Early Warning Standing',
    CSI01_SENG: 'Earned S in Remedial ENG Cours',
    CSI01_SMTH: 'Earned S in Remedial Math',
    BKL01_ETAC: 'East -Teachers Academy',
    HTR01_EHTP: 'East Harlem Tutorial Program',
    BKL01_EDLD: 'Ed Ldr Msed-Dual Curr:723&724',
    BKL01_EA19: 'Educ Adol/Spec Sub 19 Crd',
    BKL01_EA30: 'Educ Adol/Spec Subj 30-32 Crd',
    BKL01_EA32: 'Educ Adol/Spec Subj 32-48 Crd',
    BCC01_EOP: 'Educ Opportunity Program',
    BKL01_EOP: 'Educ Opportunity Program',
    BMC01_EOP: 'Educ Opportunity Program',
    CSI01_EOP: 'Educ Opportunity Program',
    HOS01_EOP: 'Educ Opportunity Program',
    HTR01_EOP: 'Educ Opportunity Program',
    KCC01_EOP: 'Educ Opportunity Program',
    LAG01_EOP: 'Educ Opportunity Program',
    LAW01_EOP: 'Educ Opportunity Program',
    LEH01_EOP: 'Educ Opportunity Program',
    NCC01_EOP: 'Educ Opportunity Program',
    NYT01_EOP: 'Educ Opportunity Program',
    QCC01_EOP: 'Educ Opportunity Program',
    QNS01_EOP: 'Educ Opportunity Program',
    YRK01_EOP: 'Educ Opportunity Program',
    CTY01_EDCA: 'Education Conditional Admit',
    CTY01_ED3P: 'Education Partnership 3rd Prty',
    HTR01_EDQT: 'Education Quest',
    HTR01_EDSC: 'Education Sec Ed Cert',
    CTY01_EDSP: 'Education Special Programs',
    BCC01_EOC: 'Educational Opportunity Center',
    BAR01_EOP: 'Educational OpprtunityProgram',
    CTY01_EOP: 'Educational OpprtunityProgram',
    JJC01_EOP: 'Educational OpprtunityProgram',
    MEC01_EOP: 'Educational OpprtunityProgram',
    SPS01_EOP: 'Educational OpprtunityProgram',
    BKL01_EISN: 'Eisenhower Scholarship',
    BMC01_ELNX: 'Elearning Exempt',
    HOS01_ELEN: 'Electrical Engineering Science',
    LAG01_J102: 'Element Japanese',
    LAG01_K102: 'Element Korean 2',
    LAG01_A102: 'Elementary Arabic 2',
    LAG01_C102: 'Elementary Chinese 2',
    LAG01_F102: 'Elementary French 2',
    LAG01_I102: 'Elementary Italian 2',
    LEH01_ELEM: 'Elementary Sequence',
    BKL01_ELEV: 'Elevator Assist',
    LEH01_TCHG: 'Elig for Tch Grnt Even if Fail',
    BAR01_BPL: 'Eligible For BPL',
    BAR01_ESPA: 'Eligible for Advanced Spanish',
    MEC01_ELTS: 'Elite Scholars',
    LEH01_EL01: 'Emergency Loan Book Check',
    NYT01_EMPS: 'Employment Services',
    BKL01_ENRT: 'En Route',
    GRD01_FECF: 'Enchanced Chancellor\'s Fellow',
    LAG01_ETHS: 'Energy Tech High School',
    BKL01_ENHN: 'Engineering Honors',
    CSI01_CORQ: 'English Co-remediation',
    BAR01_ENGC: 'English Completed',
    BCC01_ENG9: 'English for ESL Students',
    KCC01_EWNA: 'English/Writing Not Available',
    KCC01_V3: 'Enrolled ASAP Student',
    BKL01_EMAD: 'Enrollment Advocacy',
    BKL01_EMGN: 'Enrollment Management',
    LEH01_ADDG: 'Entering Adult Degree',
    BAR01_FRSH: 'Entering Freshman',
    BCC01_FRSH: 'Entering Freshman',
    BKL01_FRSH: 'Entering Freshman',
    BMC01_FRSH: 'Entering Freshman',
    CSI01_FRSH: 'Entering Freshman',
    CTY01_FRSH: 'Entering Freshman',
    HOS01_FRSH: 'Entering Freshman',
    HTR01_FRSH: 'Entering Freshman',
    JJC01_FRSH: 'Entering Freshman',
    KCC01_FRSH: 'Entering Freshman',
    LAG01_FRSH: 'Entering Freshman',
    LAW01_FRSH: 'Entering Freshman',
    LEH01_FRSH: 'Entering Freshman',
    MEC01_FRSH: 'Entering Freshman',
    NCC01_FRSH: 'Entering Freshman',
    NYT01_FRSH: 'Entering Freshman',
    QCC01_FRSH: 'Entering Freshman',
    QNS01_FRSH: 'Entering Freshman',
    SLU01_FRSH: 'Entering Freshman',
    SPS01_FRSH: 'Entering Freshman',
    YRK01_FRSH: 'Entering Freshman',
    BAR01_GRAD: 'Entering Graduate Student',
    BKL01_GRAD: 'Entering Graduate Student',
    CSI01_GRAD: 'Entering Graduate Student',
    CTY01_GRAD: 'Entering Graduate Student',
    HTR01_GRAD: 'Entering Graduate Student',
    JJC01_GRAD: 'Entering Graduate Student',
    LEH01_GRAD: 'Entering Graduate Student',
    QNS01_GRAD: 'Entering Graduate Student',
    SOJ01_GRAD: 'Entering Graduate Student',
    SPS01_GRAD: 'Entering Graduate Student',
    YRK01_GRAD: 'Entering Graduate Student',
    LEH01_LSP: 'Entering Lehman Scholar',
    QNS01_NDGG: 'Entering Nondegree GRAD',
    QCC01_NDGU: 'Entering Nondegree UGRD',
    QNS01_NDGU: 'Entering Nondegree UGRD',
    BAR01_TRNS: 'Entering Transfer Student',
    BCC01_RENS: 'Entering Transfer Student',
    BCC01_TRNS: 'Entering Transfer Student',
    BKL01_TRNS: 'Entering Transfer Student',
    BMC01_TRNS: 'Entering Transfer Student',
    CSI01_TRNS: 'Entering Transfer Student',
    CTY01_TRNS: 'Entering Transfer Student',
    HOS01_TRNS: 'Entering Transfer Student',
    HTR01_TRNS: 'Entering Transfer Student',
    JJC01_TRNS: 'Entering Transfer Student',
    KCC01_TRNS: 'Entering Transfer Student',
    LAG01_TRNS: 'Entering Transfer Student',
    LAW01_TRNS: 'Entering Transfer Student',
    LEH01_TRNS: 'Entering Transfer Student',
    MEC01_TRNS: 'Entering Transfer Student',
    NCC01_TRNS: 'Entering Transfer Student',
    NYT01_TRNS: 'Entering Transfer Student',
    QCC01_TRNS: 'Entering Transfer Student',
    QNS01_TRNS: 'Entering Transfer Student',
    SLU01_TRNS: 'Entering Transfer Student',
    SPH01_TRNS: 'Entering Transfer Student',
    SPS01_TRNS: 'Entering Transfer Student',
    YRK01_TRNS: 'Entering Transfer Student',
    SPH01_EPID: 'Epidemiology',
    HTR01_EKGS: 'Eva Kastan Grove Scholarship',
    BAR01_TRCM: 'Evaluation Completed',
    BAR01_TRIN: 'Evaluation Incomplete',
    BAR01_TREV: 'Evaluation Processed',
    BMC01_ENUR: 'Eve/Wkend Nursing',
    BAR01_EVE: 'Evening',
    BCC01_EVE: 'Evening',
    BKL01_EVE: 'Evening',
    BMC01_EVE: 'Evening',
    CSI01_EVE: 'Evening',
    CTY01_EVE: 'Evening',
    HOS01_EVE: 'Evening',
    HTR01_EVE: 'Evening',
    JJC01_EVE: 'Evening',
    KCC01_EVE: 'Evening',
    LAG01_EVE: 'Evening',
    LAW01_EVE: 'Evening',
    LEH01_EVE: 'Evening',
    MEC01_EVE: 'Evening',
    NCC01_EVE: 'Evening',
    NYT01_EVE: 'Evening',
    QCC01_EVE: 'Evening',
    QNS01_EVE: 'Evening',
    SPS01_EVE: 'Evening',
    YRK01_EVE: 'Evening',
    MEC01_EWAC: 'Evening & Weekend Acct Student',
    MEC01_EWBI: 'Evening & Weekend Biol Student',
    BAR01_EXCD: 'Excelsior Ineligible',
    GRD01_EXDR: 'Excl Doctoral Research Grant',
    GRD01_EXFC: 'Excl Fellowship Competition',
    LAG01_MEX: 'Exempted Math',
    LAG01_REX: 'Exempted Reading',
    LAG01_WEX: 'Exempted Writing',
    LAG01_EXCA: 'Extra-curricular activities',
    KCC01_F1: 'F1 Candidate',
    LAG01_F1S: 'F1 Int\'l Students',
    QCC01_OPT: 'F1 Status Student Post OPT',
    QNS01_OPT: 'F1 Status Student Post OPT',
    NYT01_F1VI: 'F1 Visa',
    QNS01_ETWS: 'FA Enrollment Track/Work Study',
    BMC01_FAND: 'FA Non Degree',
    CSI01_FAND: 'FA Non Degree',
    HOS01_FAND: 'FA Non Degree',
    KCC01_FAND: 'FA Non Degree',
    LAG01_FEP2: 'FEP Denied/Declined',
    CTY01_FWQS: 'FIQWS or ENGL 11000',
    CTY01_FQUN: 'FQUAN',
    QNS01_FS: 'FRESH START',
    BMC01_FRLI: 'FRLI - French Literature',
    BAR01_E017: 'FSPE 0017 Reading Placement',
    BAR01_S100: 'FSPM 0100 Math Placement',
    BAR01_S121: 'FSPM 0121 Placement',
    BAR01_S131: 'FSPM 1031 Placement',
    BKL01_FAPP: 'Fail Apts P&P(Tap-..Inelig-Cd7',
    BKL01_FAPG: 'Fail Apts Prog(Sac-NOTAP-Cd5)',
    BKL01_FAPU: 'Fail Apts Purs(Sac-NOTAP-Cd6)',
    BKL01_FPRO: 'Fail P&P(Sac-Tap.Inelig-Cd3)',
    BKL01_FPRG: 'Fail Prog(Sac-Tap-.Inelig-Cd1)',
    BKL01_FPUR: 'Fail Pur(Sac-Tap.Inelig-Cd2)',
    BKL01_STAP: 'Fail Stap(Sac-Tap.Inelig-Cd4)',
    CSI01_FCRD: 'Failed CUNY Reading Exam',
    CSI01_FCWR: 'Failed CUNY Writing Exam',
    CSI01_FIMM: 'Failed Immersion',
    CSI01_M1M2: 'Failed M1 and M2 Exams',
    KCC01_FCOL: 'Family College',
    LAG01_FCOL: 'Family College',
    CTY01_FDEM: 'Federal Employee',
    NYT01_FSPP: 'Federal SAP Probation',
    HTR01_SPH9: 'Fieldwork - INC Grade',
    BKL01_FATM: 'Fin Aid Transcript Missing',
    LAG01_FEAI: 'Fin Health Early Alert',
    LAG01_M112: 'Fin Spelling, Num and Gloss 2',
    BKL01_TRAN: 'Final Transcript Needed',
    BCC01_FANK: 'Financial Aid Filed - Not OK',
    BCC01_FAOP: 'Financial Aid Filed OK to Pay',
    BCC01_FANF: 'Financial Aid Not Filed',
    BKL01_FINP: 'Financial Aid Probation',
    KCC01_2G: 'Financial Aid Transc Missing',
    BKL01_INFR: 'Finish In Four',
    JJC01_FDNY: 'Fire Department',
    CTY01_HSLG: 'Firello LaGuadria High School',
    CSI01_FGEN: 'First Generation College Stdnt',
    CSI01_FRST: 'First Program Student',
    BKL01_FRST: 'First Semester Student',
    QNS01_FYEX: 'First Year Experience Students',
    LAW01_1L: 'First Year Law',
    NYT01_FYLC: 'First Year Learning Community',
    HOS01_FYMP: 'First Year Matters Program',
    MED01_MDM1: 'First Year Medical Student',
    JJC01_FYS: 'First Year Seminars',
    CTY01_BSU1: 'First Yr Sophie Davis Student',
    BKL01_1SKP: 'First term SEEK probation',
    BMC01_FLXS: 'Flex Session Pilot Group',
    LAG01_FLXS: 'Flex Session Pilot Group',
    BKL01_FORD: 'Ford Colloquium',
    LAG01_FBMA: 'Foreign Adv., not WR/RE exempt',
    CSI01_FLEX: 'Foreign Language Exempt',
    YRK01_FOHH: 'Forest Hills Hospital Program',
    YRK01_TEE2: 'Formal Admission to TE Program',
    BMC01_CK: 'Former CD Student',
    KCC01_FTHP: 'Fort Hamilton Program',
    KCC01_FTTP: 'Fort Totten Program',
    BAR01_FOSY: 'Foster Care Initiative',
    BCC01_FOSY: 'Foster Care Initiative',
    BKL01_FOSY: 'Foster Care Initiative',
    BMC01_FOSY: 'Foster Care Initiative',
    CSI01_FOSY: 'Foster Care Initiative',
    CTY01_FOSY: 'Foster Care Initiative',
    GRD01_FOSY: 'Foster Care Initiative',
    HOS01_FOSY: 'Foster Care Initiative',
    HTR01_FOSY: 'Foster Care Initiative',
    JJC01_FOSY: 'Foster Care Initiative',
    KCC01_FOSY: 'Foster Care Initiative',
    LAG01_FOSY: 'Foster Care Initiative',
    LEH01_FOSY: 'Foster Care Initiative',
    MEC01_FOSY: 'Foster Care Initiative',
    MHC01_FOSY: 'Foster Care Initiative',
    NCC01_FOSY: 'Foster Care Initiative',
    NYT01_FOSY: 'Foster Care Initiative',
    QCC01_FOSY: 'Foster Care Initiative',
    QNS01_FOSY: 'Foster Care Initiative',
    SLU01_FOSY: 'Foster Care Initiative',
    SOJ01_FOSY: 'Foster Care Initiative',
    SPH01_FOSY: 'Foster Care Initiative',
    SPS01_FOSY: 'Foster Care Initiative',
    YRK01_FOSY: 'Foster Care Initiative',
    BAR01_AFCI: 'Foster Care Initiative Youth',
    BCC01_AFCI: 'Foster Care Initiative Youth',
    BKL01_AFCI: 'Foster Care Initiative Youth',
    BMC01_AFCI: 'Foster Care Initiative Youth',
    CSI01_AFCI: 'Foster Care Initiative Youth',
    CTY01_AFCI: 'Foster Care Initiative Youth',
    GRD01_AFCI: 'Foster Care Initiative Youth',
    HOS01_AFCI: 'Foster Care Initiative Youth',
    HTR01_AFCI: 'Foster Care Initiative Youth',
    JJC01_AFCI: 'Foster Care Initiative Youth',
    KCC01_AFCI: 'Foster Care Initiative Youth',
    LAG01_AFCI: 'Foster Care Initiative Youth',
    LAW01_AFCI: 'Foster Care Initiative Youth',
    LEH01_AFCI: 'Foster Care Initiative Youth',
    MEC01_AFCI: 'Foster Care Initiative Youth',
    MED01_AFCI: 'Foster Care Initiative Youth',
    MHC01_AFCI: 'Foster Care Initiative Youth',
    NCC01_AFCI: 'Foster Care Initiative Youth',
    NYT01_AFCI: 'Foster Care Initiative Youth',
    QCC01_AFCI: 'Foster Care Initiative Youth',
    QNS01_AFCI: 'Foster Care Initiative Youth',
    SLU01_AFCI: 'Foster Care Initiative Youth',
    SOJ01_AFCI: 'Foster Care Initiative Youth',
    SPH01_AFCI: 'Foster Care Initiative Youth',
    SPS01_AFCI: 'Foster Care Initiative Youth',
    YRK01_AFCI: 'Foster Care Initiative Youth',
    BMC01_BMFS: 'Foundation Scholar',
    MED01_MDM4: 'Fourth Year Medical Student',
    BMC01_F102: 'French 102',
    BMC01_F105: 'French 105',
    BMC01_F106: 'French 106',
    BMC01_F121: 'French 121',
    BMC01_F160: 'French 160',
    BMC01_F200: 'French 200',
    BMC01_F204: 'French 204',
    BMC01_F210: 'French 210',
    BMC01_F300: 'French 300',
    NYT01_XFRN: 'French Exchange Program',
    LAG01_F105: 'French Heritage',
    JJC01_FRN1: 'French Placement 101',
    JJC01_FRN2: 'French Placement 102',
    BKL01_BCFS: 'Fresh Start Participant',
    LEH01_FRST: 'Fresh Start Program',
    BAR01_CO01: 'Freshman - 4 College Opt',
    HOS01_FRAC: 'Freshman Academy',
    HOS01_FRAB: 'Freshman Block',
    LEH01_FCFC: 'Freshman College',
    BAR01_FRDF: 'Freshman Deferral',
    CTY01_HNRS: 'Freshman Lib Arts Core Honors',
    JJC01_FRRG: 'Freshman Registered',
    LEH01_FRRA: 'Freshman Regular Admits',
    QNS01_FSSP: 'Freshman Scholars Program',
    CSI01_SCRT: 'Freshman Selected for CART',
    CSI01_SPCI: 'Freshman Selected for Pre-Coll',
    QNS01_FYI: 'Freshman Year Initiative',
    BKL01_FSHB: 'Freshmen Block',
    LEH01_FRYI: 'Freshmen Year Initiative',
    KCC01_FLEX: 'Fully-Online Program Students',
    BCC01_FTNW: 'Future Now',
    GRD01_HOUS: 'GC Residence Leasing',
    BMC01_GED: 'GED  Diploma',
    HTR01_GERM: 'GERM',
    QNS01_GK: 'GOLDEN KEY',
    BMC01_GPS: 'GPS Program',
    CSI01_G150: 'GRAD Cred Attm > 150% of Pgm',
    CSI01_GLIM: 'GRAD Cred Earned < Limit',
    LEH01_GRCT: 'GRANT/CONTRACT',
    JJC01_GGRE: 'GRE Scores Missing',
    BAR01_GSLD: 'GSL Default',
    BCC01_GSL: 'GSL Default',
    BKL01_GSL: 'GSL Default',
    KCC01_2A: 'GSL Default',
    LEH01_GSLD: 'GSL Default',
    SLU01_GSLD: 'GSL Default',
    SPS01_GSLD: 'GSL Default',
    KCC01_GFP: 'Gates Foundation Participant',
    KCC01_GWP: 'Gateway Program',
    LEH01_GEAR: 'Gear-Up',
    JJC01_GEL0: 'GenEd Lang Required: 101 & 102',
    JJC01_GEL1: 'GenEd Lang Required: 102 Only',
    JJC01_GEL2: 'GenEd Language Exempt',
    LAG01_GADM: 'General Admission',
    HTR01_GENS: 'General Scholarships',
    JJC01_GER1: 'German Placement 101',
    JJC01_GER2: 'German Placement 102',
    BKL01_GIFT: 'Gifted Extension',
    QNS01_GBNY: 'Give Back New York',
    HOS01_GLSC: 'Global Scholar',
    QNS01_GSEL: 'Global Student English Lang',
    QCC01_GSGA: 'Global Student General Adm',
    QNS01_GSGA: 'Global Student General Admin',
    QNS01_GSSP: 'Global Student Success Program',
    BKL01_KEYS: 'Golden Key',
    BAR01_GOLD: 'Golden Key Society',
    LEH01_GOLD: 'Golden Key Society',
    BCC01_AS1: 'Good Academic Standing',
    BKL01_SEAM: 'Grad Ed-Sci In Seamless Way',
    LEH01_GTSP: 'Grad Education Program',
    BKL01_HLTH: 'Grad H&Ntr-Staying Healthy NYC',
    BKL01_GMAT: 'Grad Matric',
    BKL01_GMAP: 'Grad Matric/Prob Bar After 9Cr',
    LEH01_GBRL: 'Grad Nursing Bronx Lebanon',
    BKL01_ENVR: 'Grad Off-Campus Envir Sci Prog',
    BKL01_GNSC: 'Grad Off-Campus Gen Sci Prog',
    BKL01_FRNT: 'Grad Program - New Frontiers',
    BKL01_PROA: 'Grad Provisionl Accept Student',
    HTR01_RESP: 'Grad Research - STEM',
    JJC01_GCRJ: 'Graduate CRJ Condition',
    GRD01_FGRC: 'Graduate Center Fellowship',
    LEH01_GRDC: 'Graduate Center-Lehman Home',
    BKL01_GCHC: 'Graduate Change of Curriculum',
    HTR01_GCHC: 'Graduate Change of Curriculum',
    JJC01_GR12: 'Graduate Condition 12 Cr B',
    LAW01_GF: 'Graduate Fellows',
    BKL01_ITAL: 'Graduate Italian Program',
    JJC01_GMPA: 'Graduate MPA Condition',
    LEH01_MTHU: 'Graduate Math Up Program',
    HTR01_GDND: 'Graduate Non Degree',
    BKL01_MATH: 'Graduate Off-Campus Math Prog',
    BKL01_NYAN: 'Graduate Program - Nyana',
    JJC01_GSTA: 'Graduate STAT Missing',
    LEH01_ADPG: 'Graduate from the ADP Program',
    BKL01_GHNR: 'Graduated Cuny Honors',
    KCC01_GRSI: 'Graduation Success Initiative',
    BKL01_RSCH: 'Grant/Contract Resch Foundtn',
    BKL01_CNST: 'Grant/Contract-Const Fund',
    JJC01_GFOS: 'Grduate Forensic Science',
    CTY01_GRHN: 'Grove Honor\'s Program',
    BAR01_GUTT: 'Guttman Transfer Scholarship',
    BKL01_GUTT: 'Guttman Transfer Scholarship',
    CSI01_GUTT: 'Guttman Transfer Scholarship',
    CTY01_GUTT: 'Guttman Transfer Scholarship',
    HTR01_GUTT: 'Guttman Transfer Scholarship',
    JJC01_GUTT: 'Guttman Transfer Scholarship',
    LEH01_GUTT: 'Guttman Transfer Scholarship',
    MEC01_GUTT: 'Guttman Transfer Scholarship',
    NYT01_GUTT: 'Guttman Transfer Scholarship',
    QNS01_GUTT: 'Guttman Transfer Scholarship',
    YRK01_GUTT: 'Guttman Transfer Scholarship',
    QNS01_ALLH: 'H-S studs-Cnow,High,Jump,TWHS',
    HTR01_HEBR: 'HEBR',
    HOS01_HERO: 'HERO High School Student',
    LEH01_HETS: 'HETS Student',
    QNS01_HS: 'HONORS SOCIAL SCIENCE',
    KCC01_CCAP: 'HS Advancement Program',
    BCC01_HSA: 'HSA Student',
    BCC01_HSA2: 'HSA Student - Conditional',
    BCC01_HSA1: 'HSA Student - Invited',
    BCC01_HSA3: 'HSA Student- Invitation Accept',
    BCC01_HSIB: 'HSI BCC Transfer',
    LEH01_HSIB: 'HSI BCC Transfer',
    LEH01_HSFR: 'HSI Freshman',
    HOS01_HSIH: 'HSI HCC Transfer',
    LEH01_HSIH: 'HSI HCC Transfer',
    LEH01_HSJR: 'HSI Junior',
    LEH01_HSI: 'HSI Lehman Freshmen',
    LEH01_HSSR: 'HSI Senior',
    LEH01_HSSO: 'HSI Sophomore',
    LAG01_Y105: 'Haitian Creole Heritage',
    GRD01_FHSF: 'Half Science Fellowship',
    HTR01_HRCF: 'Harcourt Fnd Scholars',
    BAR01_HRCF: 'Harcourt Foundation Scholars',
    CTY01_AHSP: 'Harcourt Scholars Program',
    BAR01_HARN: 'Harnisch Scholar',
    YRK01_NURS: 'Has Nursing Medical',
    NYT01_CHH: 'Health And Hospital Corp',
    CSI01_HCWP: 'Health Care Workers Program',
    LEH01_HEWC: 'Health Edu Works Advanced Cert',
    HOS01_CDHT: 'Health Info Technology',
    NYT01_HINT: 'Health Intent',
    CSI01_HSCI: 'Health Sci Medical on File',
    LEH01_HBID: 'HealthBridge In-Depth Year',
    HOS01_HFM2: 'High Fail M2 Exam',
    HOS01_HFRA: 'High Fail Reading Exam',
    HOS01_HFWR: 'High Fail Writing Exam',
    HOS01_HESL: 'High Placement ESL Student',
    NYT01_DIPL: 'High School Diploma Recv\'d',
    BAR01_HSE: 'High School Enrichment Student',
    BKL01_HSE: 'High School Enrichment Student',
    CTY01_HSE: 'High School Enrichment Student',
    HTR01_HSE: 'High School Enrichment Student',
    MEC01_HSE: 'High School Enrichment Student',
    YRK01_HSE: 'High School Enrichment Student',
    BKL01_HSM: 'High School Midwood Program',
    BAR01_HIGH: 'High School Student',
    BCC01_HIGH: 'High School Student',
    BKL01_HIGH: 'High School Student',
    BMC01_HIGH: 'High School Student',
    CSI01_HIGH: 'High School Student',
    CTY01_HIGH: 'High School Student',
    HOS01_HIGH: 'High School Student',
    HTR01_HIGH: 'High School Student',
    JJC01_HIGH: 'High School Student',
    KCC01_HIGH: 'High School Student',
    LAG01_HIGH: 'High School Student',
    LAW01_HIGH: 'High School Student',
    LEH01_HIGH: 'High School Student',
    MEC01_HIGH: 'High School Student',
    NCC01_HIGH: 'High School Student',
    NYT01_HIGH: 'High School Student',
    QCC01_HIGH: 'High School Student',
    QNS01_HIGH: 'High School Student',
    YRK01_HIGH: 'High School Student',
    HTR01_HS1S: 'High School Students',
    BKL01_HSV: 'High School Virtual Core',
    LEH01_HSWH: 'High School Wave Hill Program',
    HOS01_HFM1: 'High-Fail M1 Exam',
    LEH01_244Z: 'Higher Rate For Xfer',
    NYT01_CHHN: 'Hlth & Hospital Corp:Pre-Nurs',
    LEH01_HOME: 'Home Care Super Cert Prg',
    LEH01_HCWK: 'Home Care Worker',
    KCC01_HC: 'Honors College KBCC',
    LAG01_HONR: 'Honors Program',
    BCC01_HONR: 'Honors Program - BronxCC',
    BKL01_HONR: 'Honors Program - Brooklyn',
    CTY01_HONR: 'Honors Program - City College',
    HOS01_HON: 'Honors Program - HostosCC',
    JJC01_HONR: 'Honors Program - John Jay',
    KCC01_HONR: 'Honors Program - Kingsborough',
    YRK01_HONR: 'Honors Program - York',
    BAR01_HPNS: 'Honors Program, No Scholarship',
    BMC01_HONS: 'Honors Scholar',
    HTR01_HNRS: 'Honors Scholars',
    NYT01_HONR: 'Honors Scholars Program',
    LAG01_PKTH: 'Honors Society - LaGuardiaCC',
    BAR01_HREG: 'Honors Student (Scholars)',
    HOS01_HLA: 'Hostos Lincoln Academy Student',
    HOS01_HSA1: 'Hostos Success Academy Level 1',
    HOS01_HSA2: 'Hostos Success Academy Level 2',
    CSI01_DEV2: 'Hotspot Device',
    NCC01_H001: 'House 1',
    NCC01_H002: 'House 2',
    NCC01_H003: 'House 3',
    NCC01_H004: 'House 4',
    NCC01_H005: 'House 5',
    NCC01_H006: 'House 6',
    NCC01_H007: 'House 7',
    NCC01_H008: 'House 8',
    HTR01_HHMI: 'Howard Hughes Med Inst',
    BKL01_HUDN: 'Hudson St Program',
    GRD01_FHUM: 'Humanities Fellowship',
    HTR01_HCCS: 'Hunter Col Comm Sch',
    HTR01_HCSH: 'Hunter Col Schlorship Studs',
    HTR01_HCAP: 'Hunter College AANAPISI Proj',
    HTR01_HCSA: 'Hunter College Study Abroad',
    HTR01_PROM: 'Hunter Promise Transfer Schola',
    HTR01_HREG: 'Hunter Registrar',
    HTR01_HBNS: 'Hunter-Bellevue Honors Scholar',
    BAR01_I017: 'IBSIP 0017 Reading Placement',
    BAR01_M100: 'IBSIP 0100 Math Placement',
    CSI01_M015: 'Imm Prog Taken - Ok for MTH015',
    BAR01_IMRS: 'Immersion Program',
    BCC01_IMRS: 'Immersion Program',
    BKL01_IMRS: 'Immersion Program',
    BMC01_IMRS: 'Immersion Program',
    KCC01_IMRS: 'Immersion Program',
    LEH01_IMRS: 'Immersion Program',
    NYT01_IMRS: 'Immersion Program',
    QCC01_IMRS: 'Immersion Program',
    QNS01_IMRS: 'Immersion Program',
    JJC01_IMRS: 'Immersion program',
    BKL01_IMMP: 'Immigration Problem',
    KCC01_2F: 'Immigration Problem',
    LEH01_IMMG: 'Immigration Problem',
    SPS01_IMME: 'Immunization Exempt',
    BKL01_IMMN: 'Immunized',
    HTR01_SPH8: 'In Fieldwork & Capstone',
    JJC01_ISGE: 'In Service Gen Ed Exemption',
    JJC01_ISLE: 'In Service Law Enf - not NYC',
    LAG01_MEDI: 'In from Medical',
    BAR01_NARB: 'Ineligible for ARB 1001, 1002',
    BAR01_NCHI: 'Ineligible for CHI 1001, 1002',
    BAR01_NFRE: 'Ineligible for FRE 1001, 1002',
    BAR01_NHEB: 'Ineligible for HEB 1001, 1002',
    BAR01_NITL: 'Ineligible for ITL 1001, 1002',
    BAR01_NJPN: 'Ineligible for JPN 1001, 1002',
    BAR01_NPOR: 'Ineligible for POR 1001, 1002',
    BAR01_NSPA: 'Ineligible for SPA 1001,  1002',
    CTY01_EDIE: 'Initial Education Program',
    BAR01_IMAJ: 'Intended Major',
    BKL01_IMAJ: 'Intended Major',
    YRK01_IMCL: 'Intended Major: Clinical Lab',
    YRK01_IMGN: 'Intended Major: Generic Nrs',
    YRK01_IMHE: 'Intended Major: Health Educ',
    YRK01_IMOT: 'Intended Major: Occuptnl Therp',
    YRK01_IMPE: 'Intended Major: Physical Educ',
    YRK01_IMPA: 'Intended Major: Physician Asst',
    YRK01_IMSW: 'Intended Major: Social Work',
    YRK01_IMTE: 'Intended Major: Teacher Educ',
    QNS01_IMAJ: 'Intended major',
    HTR01_INTI: 'Intensive Training Institute',
    YRK01_TEIN: 'Interested in Teacher Ed',
    LAG01_J103: 'Intermed Japanese 1',
    LAG01_J104: 'Intermed Japanese 2',
    LAG01_A103: 'Intermediate Arabic 1',
    LAG01_A104: 'Intermediate Arabic 2',
    LAG01_F103: 'Intermediate French 1',
    LAG01_F104: 'Intermediate French 2',
    LAG01_I103: 'Intermediate Italian',
    LAG01_I104: 'Intermediate Italian 2',
    LAG01_K103: 'Intermediate Korean 1',
    LAG01_K104: 'Intermediate Korean 2',
    SLU01_IATS: 'International Alliance The Emp',
    LEH01_XINT: 'International Exchange Program',
    LAG01_HSIN: 'International High School',
    BKL01_F1VS: 'International Stdnts w/F1 Visa',
    MEC01_F1VS: 'International Stdnts w/F1 Visa',
    BAR01_XISE: 'International Student Exchange',
    BKL01_XISE: 'International Student Exchange',
    QNS01_ISE: 'International Student Exchange',
    BAR01_ISS: 'International Student Service',
    BCC01_ISS: 'International Student Service',
    BKL01_ISS: 'International Student Service',
    BMC01_ISS: 'International Student Service',
    CSI01_ISS: 'International Student Service',
    CTY01_ISS: 'International Student Service',
    GRD01_ISS: 'International Student Service',
    HOS01_ISS: 'International Student Service',
    HTR01_ISS: 'International Student Service',
    JJC01_ISS: 'International Student Service',
    KCC01_ISS: 'International Student Service',
    LAG01_ISS: 'International Student Service',
    LAW01_ISS: 'International Student Service',
    LEH01_ISS: 'International Student Service',
    MEC01_ISS: 'International Student Service',
    MED01_ISS: 'International Student Service',
    NCC01_ISS: 'International Student Service',
    NYT01_ISS: 'International Student Service',
    QCC01_ISS: 'International Student Service',
    QNS01_ISS: 'International Student Service',
    SLU01_ISS: 'International Student Service',
    SOJ01_ISS: 'International Student Service',
    SPH01_ISS: 'International Student Service',
    SPS01_ISS: 'International Student Service',
    YRK01_ISS: 'International Student Service',
    JJC01_INTR: 'Internship Program',
    JJC01_IHSD: 'Interntl High School Diploma',
    JJC01_EMAU: 'Interntl Student Employment',
    BAR01_IEHK: 'Intl Exec Prog Hong Kong',
    BAR01_IEIS: 'Intl Exec Prog Israel',
    BAR01_IEPI: 'Intl Exec Prog Paris',
    BAR01_IERS: 'Intl Exec Prog Russia',
    BAR01_IESH: 'Intl Exec Prog Shanghai',
    BAR01_IESZ: 'Intl Exec Prog Shenzhen',
    BAR01_IESN: 'Intl Exec Prog Singapore',
    BAR01_IETI: 'Intl Exec Prog Taipei',
    BAR01_IEXP: 'Intl Executive Program',
    GRD01_ISPT: 'Intl Stdnt Practical Training',
    SLU01_ISPT: 'Intl Stdnt Practical Training',
    SOJ01_ISPT: 'Intl Stdnt Practical Training',
    BAR01_FROR: 'Invited Freshman Orientation',
    BAR01_TROR: 'Invited Transfer Orientation',
    BCC01_INEC: 'Inwood Early College',
    BMC01_I102: 'Italian 102',
    BMC01_I105: 'Italian 105',
    BMC01_I106: 'Italian 106',
    BMC01_I121: 'Italian 121',
    BMC01_I200: 'Italian 200',
    BMC01_I204: 'Italian 204',
    BMC01_I210: 'Italian 210',
    BMC01_I300: 'Italian 300',
    BMC01_ITLL: 'Italian Literature',
    JJC01_ITA1: 'Italian Placement 101',
    JJC01_ITA2: 'Italian Placement 102',
    SPS01_JMIS: 'J Murphy Scholarship Recipient',
    SLU01_JSKC: 'JSK Credible Messenger',
    HTR01_JNSH: 'Janovic Scholars',
    LAG01_J105: 'Jap for Heritage Spkrs',
    LAG01_J150: 'Jap for Heritage Spkrs',
    JJC01_JPN1: 'Japanese Placement 101',
    JJC01_JPN2: 'Japanese Placement 102',
    HTR01_JSC1: 'Jenny Hunter Scholar',
    HTR01_JSCH: 'Jenny Hunter Scholar',
    BKL01_JEP: 'Jewish Education Project',
    KCC01_ECID: 'John Dewey ECI Students',
    CTY01_HSJK: 'John F. Kennedy High School',
    HTR01_HSCH: 'John Hopkins Scholar',
    HTR01_JMSH: 'John P. McNulty - STEM',
    CTY01_JPRG: 'Joint CUNY Programs',
    SOJ01_JPRG: 'Joint CUNY Programs',
    SPS01_JUMP: 'Jump Start',
    JJC01_JALC: 'Justice Academic LaGuardia',
    JJC01_JAAP: 'Justice Academy Applicant',
    JJC01_JABM: 'Justice Academy BMCC',
    JJC01_JABX: 'Justice Academy Bronx',
    JJC01_JACF: 'Justice Academy Confirmed',
    JJC01_JAHC: 'Justice Academy Hostos',
    JJC01_JAKC: 'Justice Academy Kings',
    JJC01_JAQC: 'Justice Academy QCC',
    SPS01_FLEX: 'KCC01 - FLEX Students',
    HTR01_KNSH: 'Kahan Scholarship',
    BAR01_KTRN: 'Katrina Relief Student',
    BCC01_KTRN: 'Katrina Relief Student',
    BKL01_KTRN: 'Katrina Relief Student',
    BMC01_KTRN: 'Katrina Relief Student',
    CSI01_KTRN: 'Katrina Relief Student',
    CTY01_KTRN: 'Katrina Relief Student',
    HOS01_KTRN: 'Katrina Relief Student',
    HTR01_KTRN: 'Katrina Relief Student',
    JJC01_KTRN: 'Katrina Relief Student',
    KCC01_KTRN: 'Katrina Relief Student',
    LAG01_KTRN: 'Katrina Relief Student',
    LAW01_KTRN: 'Katrina Relief Student',
    LEH01_KTRN: 'Katrina Relief Student',
    MEC01_KTRN: 'Katrina Relief Student',
    NCC01_KTRN: 'Katrina Relief Student',
    NYT01_KTRN: 'Katrina Relief Student',
    QCC01_KTRN: 'Katrina Relief Student',
    QNS01_KTRN: 'Katrina Relief Student',
    YRK01_KTRN: 'Katrina Relief Student',
    QNS01_KPSP: 'Kessler Presidential Scholar',
    LAG01_K105: 'Korean Heritage',
    BKL01_LAMP: 'L. Stokes All. Minority Partcp',
    BMC01_LAMP: 'L. Stokes All. Minority Partcp',
    KCC01_L5: 'LC Career Tech Pilot Fall 07',
    KCC01_L4: 'LC Career Tech Pilot Spring 07',
    BMC01_LTTP: 'LEAP to Teach Program',
    LEH01_LTTP: 'LEAP to Teach Program',
    LEH01_LIEP: 'LIEP Candidates',
    HOS01_LPN: 'LPN (CLINICAL PHASE)',
    LEH01_LSFR: 'LSP Freshman',
    LEH01_LSJR: 'LSP Junior',
    LEH01_LSSR: 'LSP Senior',
    LEH01_LSSO: 'LSP Sophomore',
    BKL01_LEAP: 'Labor Ed & Advancement Project',
    CSI01_LEAP: 'Labor Ed & Advancement Project',
    MEC01_LEAP: 'Labor Ed & Advancement Project',
    QCC01_LEAP: 'Labor Ed & Advancement Project',
    QNS01_LEAP: 'Labor Ed & Advancement Project',
    QCC01_LANH: 'Language Heritage Speaker',
    QCC01_LANG: 'Language Placement',
    BKL01_LATN: 'Latin/Greek Institute',
    KCC01_L7: 'Learning Comm Career Fall 08',
    KCC01_L9: 'Learning Comm Career Fall 09',
    KCC01_C2: 'Learning Comm Career Fall 10',
    KCC01_C4: 'Learning Comm Career Fall 11',
    KCC01_L6: 'Learning Comm Career Spring 08',
    KCC01_L8: 'Learning Comm Career Spring 09',
    KCC01_C1: 'Learning Comm Career Spring 10',
    KCC01_C3: 'Learning Comm Career Spring 11',
    KCC01_C5: 'Learning Comm Career Spring 12',
    KCC01_LCOD: 'Learning Comm Opening Doors',
    JJC01_LCOM: 'Learning Communities',
    KCC01_LCA: 'Learning Communities Advanced',
    KCC01_L: 'Learning Communities Fall 03',
    KCC01_L3: 'Learning Communities Fall 04',
    KCC01_L2: 'Learning Communities Spring 04',
    LEH01_LEHG: 'Lehman Grant',
    LEH01_LEHM: 'Lehman Merit Scholarship',
    LEH01_XJPN: 'Lehman at Hiroshima Program',
    LAG01_L30C: 'Less than 30 Credits',
    LAG01_LAA: 'Liberal Arts Academy',
    BKL01_LIBR: 'Library Cafe',
    HTR01_LICS: 'Lincoln Center Scholars',
    JJC01_LEAP: 'Linking Exp Acad & Practice',
    LEH01_LIT: 'Literacy Program',
    LEH01_LNWA: 'Loan Waivers',
    CTY01_1707: 'Local 1707 Union',
    KCC01_L802: 'Local 802 Program',
    BKL01_SPHL: 'Lower Sophmore',
    LAG01_HSLY: 'Lycee High School',
    CTY01_MSSQ: 'M.S. Squared',
    GRD01_FMGN: 'MAGNET Fellowship',
    BAR01_MALE: 'MALES Program',
    BCC01_MALE: 'MALES Program',
    BKL01_MALE: 'MALES Program',
    BMC01_MALE: 'MALES Program',
    CSI01_MALE: 'MALES Program',
    CTY01_MALE: 'MALES Program',
    HOS01_MALE: 'MALES Program',
    HTR01_MALE: 'MALES Program',
    JJC01_MALE: 'MALES Program',
    KCC01_MALE: 'MALES Program',
    LAG01_MALE: 'MALES Program',
    LAW01_MALE: 'MALES Program',
    LEH01_MALE: 'MALES Program',
    MEC01_MALE: 'MALES Program',
    MED01_MALE: 'MALES Program',
    NCC01_MALE: 'MALES Program',
    NYT01_MALE: 'MALES Program',
    QCC01_MALE: 'MALES Program',
    QNS01_MALE: 'MALES Program',
    SPH01_MALE: 'MALES Program',
    SPS01_MALE: 'MALES Program',
    YRK01_MALE: 'MALES Program',
    BMC01_MA14: 'MAT 14',
    LAG01_MA40: 'MAT095 Placement',
    LAG01_MA41: 'MAT095 Placement',
    LAG01_MA42: 'MAT096 Placement',
    LAG01_MA45: 'MAT117/119/123 Placement',
    LAG01_MA44: 'MAT99 Placement',
    BAR01_ZKNC: 'MBA - New Core',
    BAR01_ZKNP: 'MBA - New Program',
    SPH01_MCRS: 'MCRSH Specialization',
    MEC01_HONR: 'MEC Honors Program',
    MEC01_MEPH: 'MEC Pipeline Healthcare',
    HTR01_SPH5: 'MHC Concentration',
    LEH01_MHFR: 'MHC Freshman',
    LEH01_MHJR: 'MHC Junior',
    BCC01_MHCA: 'MHC Provisional acceptance',
    LEH01_MHSR: 'MHC Senior',
    LEH01_MHSO: 'MHC Sophomore',
    LEH01_MHCT: 'MHC transfer student at Lehman',
    HTR01_MARP: 'MIND Alliance Res Prog - STEM',
    BAR01_BMSF: 'MSF Student ',
    BAR01_ALI: 'MSPIA Ed-L ALI Program',
    BAR01_APP: 'MSPIA Ed-L APP',
    BAR01_LEAP: 'MSPIA Ed-L Ed Apprntshp Prog',
    BAR01_NL: 'MSPIA Ed-L New Leaders',
    BAR01_SAM: 'MSPIA Ed-L SAM Citywide',
    BAR01_SAMA: 'MSPIA Ed-L Single Model',
    BAR01_XMPA: 'MSPIA Executive MPA Students',
    BAR01_SPIN: 'MSPIA Internship Required',
    BAR01_NUF: 'MSPIA NUF Program',
    YRK01_MSW: 'MSW Scholar',
    BAR01_M123: 'MTH 1023 Placement',
    BAR01_M103: 'MTH 1030 Placement',
    BAR01_M203: 'MTH 2003 Placement',
    BAR01_M209: 'MTH 2009 Placement',
    BAR01_M214: 'MTH 2140 Placement',
    BAR01_M215: 'MTH 2150 Placement',
    BAR01_M216: 'MTH 2160 Placement',
    BAR01_M205: 'MTH 2205 Placement',
    BAR01_M227: 'MTH 2207 Placement',
    BAR01_M261: 'MTH 2610 Placement',
    BAR01_NTMT: 'MTH Placement TBD',
    BAR01_MHBS: 'Macaulay Honors Bridge Scholar',
    BCC01_MHBS: 'Macaulay Honors Bridge Scholar',
    BKL01_MHBS: 'Macaulay Honors Bridge Scholar',
    BMC01_MHBS: 'Macaulay Honors Bridge Scholar',
    CSI01_MHBS: 'Macaulay Honors Bridge Scholar',
    CTY01_MHBS: 'Macaulay Honors Bridge Scholar',
    HOS01_MHBS: 'Macaulay Honors Bridge Scholar',
    HTR01_MHBS: 'Macaulay Honors Bridge Scholar',
    JJC01_MHBS: 'Macaulay Honors Bridge Scholar',
    KCC01_MHBS: 'Macaulay Honors Bridge Scholar',
    LAG01_MHBS: 'Macaulay Honors Bridge Scholar',
    LEH01_MHBS: 'Macaulay Honors Bridge Scholar',
    MEC01_MHBS: 'Macaulay Honors Bridge Scholar',
    NCC01_MHBS: 'Macaulay Honors Bridge Scholar',
    NYT01_MHBS: 'Macaulay Honors Bridge Scholar',
    QCC01_MHBS: 'Macaulay Honors Bridge Scholar',
    QNS01_MHBS: 'Macaulay Honors Bridge Scholar',
    SLU01_MHBS: 'Macaulay Honors Bridge Scholar',
    SOJ01_MHBS: 'Macaulay Honors Bridge Scholar',
    SPS01_MHBS: 'Macaulay Honors Bridge Scholar',
    YRK01_MHBS: 'Macaulay Honors Bridge Scholar',
    BAR01_MHCO: 'Macaulay Offer',
    BKL01_HSMD: 'Magen David Yeshiva',
    JJC01_MJU: 'Major Updated',
    HTR01_MHSS: 'Manhattan/Hunter Science Stud',
    BMC01_MAPL: 'Maple T Test',
    BKL01_MARC: 'Marc Program',
    KCC01_MTAP: 'Maritime Tech Apprentice Prog',
    KCC01_MTLA: 'Maritime Tech Liberal Arts',
    QCC01_MVBH: 'Martin Van Buren H.S. Students',
    HTR01_MYNT: 'Marymount Permit in',
    LEH01_M104: 'Math 104',
    BMC01_MA12: 'Math 12',
    LEH01_M123: 'Math 123',
    LEH01_M132: 'Math 132',
    LEH01_M135: 'Math 135',
    CTY01_M150: 'Math 15000: Math for Cnt World',
    LEH01_M155: 'Math 155',
    LEH01_M156: 'Math 156',
    LEH01_M171: 'Math 171',
    LEH01_M172: 'Math 172',
    CTY01_M173: 'Math 17300: STATS See Advisor',
    LEH01_M174: 'Math 174',
    LEH01_M175: 'Math 175',
    LEH01_M176: 'Math 176',
    CTY01_M180: 'Math 18000: Education Majors',
    CTY01_M185: 'Math 18500: Education Majors',
    CTY01_M190: 'Math 19000: College Alg. & Tri',
    CTY01_M195: 'Math 19500: Precalculus',
    BMC01_M206: 'Math 206',
    BMC01_MA41: 'Math 41',
    BMC01_MA51: 'Math 51',
    BMC01_MA56: 'Math 56',
    CTY01_MA71: 'Math 71: Basic Arith. & Algebr',
    CTY01_MA80: 'Math 80: Fundamental Algebra',
    CTY01_MCRT: 'Math Certified',
    BKL01_MCRT: 'Math Certified Yes',
    QCC01_MTDG: 'Math Diagnostics Test Needed',
    QCC01_MEX: 'Math Exempt',
    QNS01_MEX: 'Math Exempt',
    CTY01_FMSE: 'Math Fellows - Secondary Ed',
    GRD01_FMTH: 'Math Fellowship',
    BKL01_MIMM: 'Math Immersion',
    HTR01_MTHP: 'Math Pass To Math 101',
    LEH01_MPL4: 'Math Placement Level 4',
    CTY01_HSMS: 'Math Science & Engineering HS',
    HTR01_MSPP: 'Math Science Prtnrshp - STEM',
    BCC01_MSAL: 'Math Start Adult Learners',
    BMC01_MSAL: 'Math Start Adult Learners',
    CSI01_MSAL: 'Math Start Adult Learners',
    HOS01_MSAL: 'Math Start Adult Learners',
    KCC01_MSAL: 'Math Start Adult Learners',
    LAG01_MSAL: 'Math Start Adult Learners',
    MEC01_MSAL: 'Math Start Adult Learners',
    NCC01_MSAL: 'Math Start Adult Learners',
    NYT01_MSAL: 'Math Start Adult Learners',
    QCC01_MSAL: 'Math Start Adult Learners',
    CSI01_MSND: 'Math Start High Sch Initiative',
    MEC01_MSND: 'Math Start High Sch Initiative',
    BCC01_MSTS: 'Math Start Student',
    BMC01_MSTS: 'Math Start Student',
    CSI01_MSTS: 'Math Start Student',
    HOS01_MSTS: 'Math Start Student',
    KCC01_MSTS: 'Math Start Student',
    LAG01_MSTS: 'Math Start Student',
    MEC01_MSTS: 'Math Start Student',
    NCC01_MSTS: 'Math Start Student',
    NYT01_MSTS: 'Math Start Student',
    QCC01_MSTS: 'Math Start Student',
    JJC01_MSUP: 'Math Supplemental Instruction',
    HTR01_MSTR: 'Math/Science Teacher Residency',
    CTY01_M201: 'Math20100:CALCfor STEM(notBIO)',
    CTY01_M205: 'Math20500:CALCforEco,BioMajors',
    BKL01_MTCH: 'Mathematics Teachers',
    HTR01_ARTX: 'Max Reached 30 Cr Studio Art',
    HTR01_MARC: 'Maximizing Access to Research',
    HTR01_MYRS: 'Mayor\'s Scholarship',
    HOS01_MEEN: 'Mechanical Engineering Science',
    MEC01_MEPI: 'Medgar Pipeline Initiative',
    CSI01_MEXM: 'Medical Exemption',
    HOS01_RHEP: 'Mellisa Riggio Program',
    BKL01_MELN: 'Mellon',
    HTR01_MELN: 'Mellon',
    BAR01_BGHS: 'Member Beta Gamma Sigma',
    KCC01_MCTR: 'Men\'s Center',
    KCC01_MCMP: 'Men\'s Center Mentoring Program',
    HTR01_MHRS: 'Mental Health Research - STEM',
    QCC01_MS: 'Merit Scholarship For Freshman',
    NYT01_TCHG: 'Met TEACH Grant\'s 75 Percent',
    LEH01_TCHA: 'Met Tch Grant\'s 75%+ Admit Std',
    CTY01_MSSC: 'Mid-School Science Program',
    BAR01_MDYR: 'Mid-Year Program',
    LEH01_T1TG: 'Mid/HS-NYC TCHRS Grant T1',
    LEH01_T2TG: 'Mid/HS-NYC TCHRS Grant T2',
    LEH01_T3TG: 'Mid/HS-NYC TCHRS Grant T3',
    LEH01_T4TG: 'Mid/HS-NYC TCHRS Grant T4',
    BKL01_MCHX: 'Middle Childhd Extensn Prgm',
    LAG01_MDCL: 'Middle College Student',
    HTR01_MDPS: 'Min Priority Prog - STEM',
    HTR01_MBRP: 'Minority Bio Res Prog - STEM',
    HTR01_MDAP: 'Minority D Abuse Prog - STEM',
    HTR01_MRSP: 'Minority Res Summer Prgrm',
    BKL01_SRGE: 'Minority STEM Program',
    MEC01_MFSI: 'Miracle on Fulton Street',
    BMC01_BMMW: 'MoneyWorks Ambassador',
    BKL01_60SR: 'More Than 60 Cr Cuny Sr Col',
    BKL01_60NS: 'More Than 60 Cr Non-Cuny Sr Co',
    BKL01_60CM: 'More Than 60 Crd Combo Sr Col',
    LAG01_M30C: 'More than 30 Credits',
    HTR01_MDSH: 'Mother\'s Day Scholarship',
    LEH01_MTVN: 'Mt. Vernon School District',
    QCC01_REP2: 'Multiple Repeats Policy',
    HTR01_MUSE: 'Muse Honors Scholars',
    BMC01_PAUD: 'Music Audition Passed',
    BMC01_RAUD: 'Music Audition Repeat/Failed',
    BMC01_PMUS: 'Music Placement Test Passed',
    BKL01_MUDM: 'Must Declare Major',
    BAR01_TRAP: 'Must Submit Official AP Scores',
    BAR01_NSLD: 'NDSL (Perkins Loan) Default',
    BAR01_NDSL: 'NDSL Default',
    BCC01_NDSL: 'NDSL Default',
    BKL01_NDSL: 'NDSL Default',
    KCC01_2B: 'NDSL Default',
    LEH01_NDSL: 'NDSL Default',
    SLU01_NDSL: 'NDSL Default',
    SPS01_NDSL: 'NDSL Default',
    BAR01_NSLX: 'NDSL Exit Interview Required',
    BAR01_NDPN: 'NDSL Promissory Note Signed',
    BKL01_PROM: 'NDSL Promissory Note Signed',
    KCC01_2D: 'NDSL Promissory Note Signed',
    LEH01_NDPN: 'NDSL Promissory Note Signed',
    KCC01_FRS1: 'NEW FRESHMAN GROUP 1',
    KCC01_FRS2: 'NEW FRESHMAN GROUP 2',
    KCC01_FRS3: 'NEW FRESHMAN GROUP 3',
    KCC01_FRS4: 'NEW FRESHMAN GROUP 4',
    KCC01_FRS5: 'NEW FRESHMAN GROUP 5',
    KCC01_FRS6: 'NEW FRESHMAN GROUP 6',
    KCC01_TRS1: 'NEW TRANSFER GROUP 1',
    KCC01_TRS2: 'NEW TRANSFER GROUP 2',
    KCC01_TRS3: 'NEW TRANSFER GROUP 3',
    KCC01_TRS4: 'NEW TRANSFER GROUP 4',
    KCC01_TRS5: 'NEW TRANSFER GROUP 5',
    KCC01_TRS6: 'NEW TRANSFER GROUP 6',
    JJC01_NCSA: 'NON-CUNY Study Abroad',
    GRD01_NRAS: 'NRA Students',
    QNS01_NSEP: 'NSE: In & pays QC',
    QNS01_NSEW: 'NSE: In & pays home school',
    QNS01_NSEH: 'NSE: Out & pays QC',
    QNS01_NSEA: 'NSE: Out & pays host',
    CSI01_NSOA: 'NSO Appointment',
    QCC01_N1: 'NU-101 Approved',
    QCC01_N2: 'NU-102 Approved',
    QCC01_N3: 'NU-201 Approved',
    QCC01_N4: 'NU-202 Approved',
    HOS01_NURS: 'NURS (CLINICAL PHASE)',
    BCC01_NYFP: 'NY Food Program Prospective',
    BMC01_NYFP: 'NY Food Program Prospective',
    CSI01_NYFP: 'NY Food Program Prospective',
    HOS01_NYFP: 'NY Food Program Prospective',
    KCC01_NYFP: 'NY Food Program Prospective',
    LAG01_NYFP: 'NY Food Program Prospective',
    MEC01_NYFP: 'NY Food Program Prospective',
    NCC01_NYFP: 'NY Food Program Prospective',
    NYT01_NYFP: 'NY Food Program Prospective',
    QCC01_NYFP: 'NY Food Program Prospective',
    BCC01_NYFR: 'NY Food Program Recipient',
    BMC01_NYFR: 'NY Food Program Recipient',
    CSI01_NYFR: 'NY Food Program Recipient',
    HOS01_NYFR: 'NY Food Program Recipient',
    KCC01_NYFR: 'NY Food Program Recipient',
    LAG01_NYFR: 'NY Food Program Recipient',
    MEC01_NYFR: 'NY Food Program Recipient',
    NCC01_NYFR: 'NY Food Program Recipient',
    NYT01_NYFR: 'NY Food Program Recipient',
    QCC01_NYFR: 'NY Food Program Recipient',
    BCC01_NX: 'NY Phone Company Employees',
    CSI01_RNLC: 'NY State RN License',
    BAR01_NYCS: 'NYC Council Scholarship',
    BCC01_NYCS: 'NYC Council Scholarship',
    BKL01_NYCS: 'NYC Council Scholarship',
    BMC01_NYCS: 'NYC Council Scholarship',
    CSI01_NYCS: 'NYC Council Scholarship',
    CTY01_NYCS: 'NYC Council Scholarship',
    HOS01_NYCS: 'NYC Council Scholarship',
    HTR01_NYCS: 'NYC Council Scholarship',
    JJC01_NYCS: 'NYC Council Scholarship',
    KCC01_NYCS: 'NYC Council Scholarship',
    LAG01_NYCS: 'NYC Council Scholarship',
    LAW01_NYCS: 'NYC Council Scholarship',
    LEH01_NYCS: 'NYC Council Scholarship',
    MEC01_NYCS: 'NYC Council Scholarship',
    NCC01_NYCS: 'NYC Council Scholarship',
    NYT01_NYCS: 'NYC Council Scholarship',
    QCC01_NYCS: 'NYC Council Scholarship',
    QNS01_NYCS: 'NYC Council Scholarship',
    YRK01_NYCS: 'NYC Council Scholarship',
    BKL01_NYSC: 'NYC Dept Ed Scholarship Recip',
    BAR01_NYMT: 'NYC Men Teach',
    BCC01_NYMT: 'NYC Men Teach',
    BKL01_NYMT: 'NYC Men Teach',
    BMC01_NYMT: 'NYC Men Teach',
    CSI01_NYMT: 'NYC Men Teach',
    CTY01_NYMT: 'NYC Men Teach',
    HOS01_NYMT: 'NYC Men Teach',
    HTR01_NYMT: 'NYC Men Teach',
    JJC01_NYMT: 'NYC Men Teach',
    KCC01_NYMT: 'NYC Men Teach',
    LAG01_NYMT: 'NYC Men Teach',
    LEH01_NYMT: 'NYC Men Teach',
    MEC01_NYMT: 'NYC Men Teach',
    NCC01_NYMT: 'NYC Men Teach',
    NYT01_NYMT: 'NYC Men Teach',
    QCC01_NYMT: 'NYC Men Teach',
    QNS01_NYMT: 'NYC Men Teach',
    SPS01_NYMT: 'NYC Men Teach',
    YRK01_NYMT: 'NYC Men Teach',
    JJC01_NYPD: 'NYC Police Department',
    LEH01_TER: 'NYC Teach R',
    BKL01_NYCO: 'NYC Tech Collab Pre-College',
    BCC01_WF1: 'NYC Workforce1 Program',
    LEH01_PARB: 'NYCDOE Para Bridge Program',
    JJC01_PDEX: 'NYPD Leadership Exec Cohort',
    JJC01_PDLP: 'NYPD Leadership Program',
    BAR01_EXCL: 'NYS Excelsior Scholar',
    BCC01_EXCL: 'NYS Excelsior Scholar',
    BKL01_EXCL: 'NYS Excelsior Scholar',
    BMC01_EXCL: 'NYS Excelsior Scholar',
    CSI01_EXCL: 'NYS Excelsior Scholar',
    CTY01_EXCL: 'NYS Excelsior Scholar',
    HOS01_EXCL: 'NYS Excelsior Scholar',
    HTR01_EXCL: 'NYS Excelsior Scholar',
    JJC01_EXCL: 'NYS Excelsior Scholar',
    KCC01_EXCL: 'NYS Excelsior Scholar',
    LAG01_EXCL: 'NYS Excelsior Scholar',
    LAW01_EXCL: 'NYS Excelsior Scholar',
    LEH01_EXCL: 'NYS Excelsior Scholar',
    MEC01_EXCL: 'NYS Excelsior Scholar',
    MED01_EXCL: 'NYS Excelsior Scholar',
    NCC01_EXCL: 'NYS Excelsior Scholar',
    NYT01_EXCL: 'NYS Excelsior Scholar',
    QCC01_EXCL: 'NYS Excelsior Scholar',
    QNS01_EXCL: 'NYS Excelsior Scholar',
    SLU01_EXCL: 'NYS Excelsior Scholar',
    SPH01_EXCL: 'NYS Excelsior Scholar',
    SPS01_EXCL: 'NYS Excelsior Scholar',
    YRK01_EXCL: 'NYS Excelsior Scholar',
    BAR01_NASH: 'Nash Honors Grad MBA',
    BMC01_NSLS: 'National Soc of Ldrshp and Suc',
    HTR01_XNSE: 'National Student Exchange',
    BAR01_TRCT: 'Needs College Transcript',
    BAR01_TRCD: 'Needs Course Descr or Syllabus',
    BAR01_TRHS: 'Needs HS Transcript',
    HTR01_NGHD: 'Neighborhood Scholarship',
    HTR01_NSED: 'Neuroscience Ed',
    GRD01_FNSC: 'Neuroscience Fellowship',
    BKL01_NWAD: 'New & Upgraded By Admission',
    CTY01_NRES: 'New Approved NYS Residents',
    SOJ01_NRES: 'New Approved NYS Residents',
    KCC01_M1: 'New Course-MAT M1',
    KCC01_M1X1: 'New Course-MAT M1 OR X1',
    KCC01_M2: 'New Course-MAT M2',
    KCC01_M21X: 'New Course-MAT M2 OR X2',
    KCC01_MR3: 'New Course-MAT MR3',
    BKL01_NISS: 'New International Students',
    QNS01_NISS: 'New International Students',
    QNS01_MUSC: 'New Music Students',
    HTR01_WNTR: 'New ND or Readmit Winter Ses',
    CSI01_N110: 'New Nursing 110',
    KCC01_NSP: 'New Start Program',
    SPH01_NWST: 'New Student',
    LAG01_NSAR: 'New Student Adv and Reg',
    JJC01_TSTD: 'New Student Tested',
    HTR01_NEWV: 'New Vision Scholar',
    QNS01_NVIS: 'New Visions',
    HTR01_NVCP: 'New Visions Charter Schools',
    BMC01_NEWV: 'New Visions Student',
    JJC01_NYCC: 'New York City Corrections',
    JJC01_NYST: 'New York State Law Enforcemnt',
    KCC01_XNZL: 'New Zealand Exchange Program',
    KCC01_PRB1: 'Newly-Placed Probation Student',
    KCC01_CP11: 'Next Crse is CP 10 or CP 11',
    KCC01_CP5: 'Next Crse is CP 500',
    KCC01_M10: 'Next Crse is MAT 1000',
    KCC01_M114: 'Next Crse is MAT 11, 14, or 91',
    KCC01_M15: 'Next Crse is MAT 15',
    KCC01_M16: 'Next Crse is MAT 16',
    KCC01_M19: 'Next Crse is MAT 19A0',
    KCC01_M20: 'Next Crse is MAT 2000',
    KCC01_M215: 'Next Crse is MAT 21, or 55',
    KCC01_M22: 'Next Crse is MAT 2200',
    KCC01_M478: 'Next Crse is MAT 4, 7, or 8',
    KCC01_M56: 'Next Crse is MAT 5600',
    KCC01_M913: 'Next Crse is MAT 9, or 13',
    KCC01_M91: 'Next Crse is MAT or BIO 9100',
    KCC01_EN12: 'Next English Crse is ENG 1200',
    KCC01_ENAL: 'Next English Crse is ENG 1220',
    KCC01_ENA0: 'Next English Crse is ENG 12A0',
    KCC01_EN24: 'Next English Crse is ENG 2400',
    KCC01_EN88: 'Next English Crse is ENG 88A6',
    KCC01_EN4: 'Next English course is EN 04',
    KCC01_EN91: 'Next English course is ENG 91',
    KCC01_EN92: 'Next English course is ENG 92',
    KCC01_EN93: 'Next English course is ENG 93',
    KCC01_ENR: 'Next English course is ENG R',
    KCC01_ENW: 'Next English course is ENG W',
    KCC01_ES7: 'Next English course is ESL 07',
    KCC01_ES9: 'Next English course is ESL 09',
    KCC01_ES91: 'Next English course is ESL 91',
    KCC01_EN82: 'Next English crse is ENG 8211',
    KCC01_ES82: 'Next course is ESL 8201',
    BAR01_CO00: 'No College Option Needed',
    LEH01_NDEF: 'No Deferment',
    BAR01_NOEN: 'No ENG-Placed Below Offer ENG',
    BAR01_NMRE: 'No MTH-Placed Below Offer MTH',
    KCC01_MX: 'No Math/Must pass Reading Exam',
    KCC01_MT: 'No Math/See Testing Department',
    HTR01_NTPO: 'No Tuition Payment Plan',
    BAR01_NZMT: 'No Zicklin Mth Courses-3Repeat',
    BCC01_NES2: 'NoESL',
    SPH01_NDEG: 'Non Degree',
    QCC01_NM: 'Non Degree Student',
    GRD01_NOND: 'Non Degree Students',
    JJC01_NDEG: 'Non Degree Students',
    HTR01_NMTR: 'Non Matriculated Student',
    JJC01_NNEN: 'Non Native English Speak',
    HOS01_NASP: 'Non-ASAP Student Permission',
    BAR01_NCPO: 'Non-CUNY Outgoing Permit',
    BKL01_NCBS: 'Non-Cuny Bc Study Abroad',
    HTR01_SPHB: 'Non-Degree Stud - UPH Courses',
    BKL01_NOND: 'Non-Degree Student',
    HOS01_NDEG: 'Non-Degree Student',
    HTR01_NOND: 'Non-Degree Student',
    LEH01_NOND: 'Non-Degree Student',
    YRK01_NDST: 'Non-Degree Student (Regular)',
    BKL01_NPSF: 'Non-Pub Sch Stdnt Fund By NYS',
    QCC01_ALPQ: 'Non-STEM mathematics course',
    HTR01_NPE: 'Non-proficient English',
    HTR01_NPM: 'Non-proficient Math',
    HTR01_NCHT: 'Not Committed To Hunter',
    BAR01_NZK2: 'Not eligible - Zicklin Path 2',
    BAR01_NZK3: 'Not eligible - Zicklin Path 3',
    BAR01_NEAC: 'Not eligible for Accounting',
    BAR01_NEFN: 'Not eligible for Finance',
    QNS01_NEFN: 'Not eligible for Finance',
    HTR01_NYFS: 'Noyce Fellowship - STEM',
    LEH01_NOYC: 'Noyce Scholars',
    CSI01_NRS3: 'Nursing 300-Level',
    BKL01_NRSG: 'Nursing Students-Downstate',
    CSI01_NSIU: 'Nursing/SIUH',
    QNS01_FNES: 'Nutr & Diet Prog inter Fee',
    QNS01_NDIP: 'Nutrition & Diet Inter Pgm',
    HTR01_OOPP: 'Office of Partnership Programs',
    JJC01_ACE: 'Official ACE Student',
    JJC01_ACE2: 'Official ACE Student',
    LEH01_ACE2: 'Official ACE Student',
    NYT01_ACE2: 'Official ACE Student',
    BAR01_ASAP: 'Official ASAP Student',
    BCC01_ASAP: 'Official ASAP Student',
    BKL01_ASAP: 'Official ASAP Student',
    BMC01_ASAP: 'Official ASAP Student',
    CSI01_ASAP: 'Official ASAP Student',
    CTY01_ASAP: 'Official ASAP Student',
    HOS01_ASAP: 'Official ASAP Student',
    HTR01_ASAP: 'Official ASAP Student',
    JJC01_ASAP: 'Official ASAP Student',
    KCC01_ASAP: 'Official ASAP Student',
    LAG01_ASAP: 'Official ASAP Student',
    LAW01_ASAP: 'Official ASAP Student',
    LEH01_ASAP: 'Official ASAP Student',
    MEC01_ASAP: 'Official ASAP Student',
    NCC01_ASAP: 'Official ASAP Student',
    NYT01_ASAP: 'Official ASAP Student',
    QCC01_ASAP: 'Official ASAP Student',
    QNS01_ASAP: 'Official ASAP Student',
    SPS01_ASAP: 'Official ASAP Student',
    YRK01_ASAP: 'Official ASAP Student',
    BCC01_ASMS: 'Official ASMS Student',
    BMC01_ASMS: 'Official ASMS Student',
    CSI01_ASMS: 'Official ASMS Student',
    HOS01_ASMS: 'Official ASMS Student',
    KCC01_ASMS: 'Official ASMS Student',
    LAG01_ASMS: 'Official ASMS Student',
    MEC01_ASMS: 'Official ASMS Student',
    NCC01_ASMS: 'Official ASMS Student',
    NYT01_ASMS: 'Official ASMS Student',
    QCC01_ASMS: 'Official ASMS Student',
    LEH01_ACFR: 'Official Ace Student Freshman',
    NYT01_ACFR: 'Official Ace Student Freshman',
    LEH01_ACJR: 'Official Ace Student Junior',
    NYT01_ACJR: 'Official Ace Student Junior',
    LEH01_ACSR: 'Official Ace Student Senior',
    NYT01_ACSR: 'Official Ace Student Senior',
    LEH01_ACSO: 'Official Ace Student Soph',
    NYT01_ACSO: 'Official Ace Student Soph',
    LAG01_FEP3: 'Official FEP Student',
    BCC01_PTI4: 'Official PTI student',
    LAG01_PTI4: 'Official PTI student',
    BKL01_OLYM: 'Olympic Hopefuls',
    BKL01_REST: 'On Prob Restrict Registration',
    JJC01_EX12: 'One or Two Exams Failed',
    CTY01_ONLN: 'Online',
    JJC01_ONLN: 'Online',
    SPH01_ONLN: 'Online (Immunization waived)',
    SPS01_ONLN: 'Online (Immunization waived)',
    LEH01_ONRN: 'Online Degree Opt for Nurse BS',
    JJC01_ONIM: 'Online Student Immunized',
    KCC01_D1: 'Opening Doors Fa 03',
    KCC01_D3: 'Opening Doors Fall 04',
    KCC01_D5: 'Opening Doors Fall 05',
    KCC01_D7: 'Opening Doors Fall 06',
    KCC01_D9: 'Opening Doors Fall 07',
    KCC01_O2: 'Opening Doors Fall 08',
    KCC01_O4: 'Opening Doors Fall 09',
    KCC01_U1: 'Opening Doors Fall 10',
    KCC01_U3: 'Opening Doors Fall 11',
    KCC01_D2: 'Opening Doors Spring 04',
    KCC01_D4: 'Opening Doors Spring 05',
    KCC01_D6: 'Opening Doors Spring 06',
    KCC01_D8: 'Opening Doors Spring 07',
    KCC01_O1: 'Opening Doors Spring 08',
    KCC01_O3: 'Opening Doors Spring 09',
    KCC01_O5: 'Opening Doors Spring 10',
    KCC01_U2: 'Opening Doors Spring 11',
    KCC01_U4: 'Opening Doors Spring 12',
    QNS01_JUMP: 'Operation High Jump',
    JJC01_OPIN: 'Opt in New General Education',
    LAG01_CST2: 'Optional CS students 2',
    LAG01_RE47: 'Out Of Remedial Reading High',
    BMC01_OUT2: 'Out in Two Program',
    LAG01_ES99: 'Out of RemRead/Placed in ESL99',
    LAG01_MA46: 'Out of Remedial Math High',
    LAG01_MA43: 'Out of Remedial Math Low',
    LAG01_MPAS: 'Out of Remedial Mathematics',
    LAG01_RPAS: 'Out of Remedial Reading',
    LAG01_RE46: 'Out of Remedial Reading Low',
    LAG01_WPAS: 'Out of Remedial Wrting',
    LAG01_MEDL: 'Out on Medical',
    NYT01_HSPT: 'P-Tech High School Program',
    LAG01_PARX: 'PAR Excluded',
    QCC01_PARX: 'PAR Excluded',
    BCC01_PASS: 'PASS Center',
    MEC01_PBIG: 'PBI Graduate Programs',
    MEC01_PBIP: 'PBI PREP Program',
    MEC01_PBIS: 'PBI Supplemental Instruction',
    CSI01_MEDE: 'PED 190 Medical Eligible',
    HTR01_PLLI: 'PELL Ineligible',
    KCC01_PFLX: 'PFLX students',
    CSI01_PITL: 'PIT Learning Community',
    LAG01_PNRN: 'PN to RN Program',
    BCC01_PTI0: 'PTI Denied/Declined',
    LAG01_PTI0: 'PTI Denied/Declined',
    BCC01_PTI2: 'PTI Eligible - Continuing Stud',
    LAG01_PTI2: 'PTI Eligible - Continuing Stud',
    BCC01_PTI1: 'PTI Eligible - New Students',
    LAG01_PTI1: 'PTI Eligible - New Students',
    BKL01_VETC: 'Paid To School (Vet)',
    BKL01_VETS: 'Paid To Student (Vet)',
    BMC01_1GEN: 'Panther Partners',
    BCC01_PARA: 'Paraprofessional',
    HOS01_PARA: 'Paraprofessional',
    HTR01_PARA: 'Paraprofessional',
    KCC01_PARA: 'Paraprofessional',
    LAG01_PARA: 'Paraprofessional',
    LEH01_PARA: 'Paraprofessional',
    MEC01_PARA: 'Paraprofessional',
    NCC01_PARA: 'Paraprofessional',
    QCC01_PARA: 'Paraprofessional',
    QNS01_PARA: 'Paraprofessional',
    SPS01_PARA: 'Paraprofessional',
    YRK01_PARA: 'Paraprofessional',
    BAR01_XPAR: 'Paris Exchange Program',
    BKL01_XPAR: 'Paris Exchange Program',
    CTY01_PARI: 'Paris Exchange Program',
    LEH01_XPAR: 'Paris Exchange Program',
    MEC01_XPAR: 'Paris Exchange Program',
    QNS01_PARI: 'Paris Exchange Program',
    HTR01_XPAR: '"Paris Exchange Program    "',
    NYT01_PTAP: 'Part Time TAP',
    SPH01_AEMC: 'Partnership - Einstein Med',
    SPH01_MFMC: 'Partnership – Montefiore',
    SPH01_MTSI: 'Partnership – Mt. Sanai',
    SPH01_PECP: 'Partnership – Peace Corp',
    HTR01_LAST: 'Passed Last',
    BAR01_P_MA: 'Passed Math',
    BCC01_P_MA: 'Passed Math',
    BKL01_P_MA: 'Passed Math',
    BMC01_P_MA: 'Passed Math',
    CSI01_P_MA: 'Passed Math',
    CTY01_P_MA: 'Passed Math',
    HOS01_P_MA: 'Passed Math',
    HTR01_P_MA: 'Passed Math',
    JJC01_P_MA: 'Passed Math',
    KCC01_P_MA: 'Passed Math',
    LAG01_P_MA: 'Passed Math',
    LAW01_P_MA: 'Passed Math',
    LEH01_P_MA: 'Passed Math',
    MEC01_P_MA: 'Passed Math',
    NCC01_P_MA: 'Passed Math',
    NYT01_P_MA: 'Passed Math',
    QCC01_P_MA: 'Passed Math',
    QNS01_P_MA: 'Passed Math',
    SLU01_P_MA: 'Passed Math',
    SPS01_P_MA: 'Passed Math',
    YRK01_P_MA: 'Passed Math',
    BAR01_P_M1: 'Passed Math Part 1',
    BCC01_P_M1: 'Passed Math Part 1',
    BKL01_P_M1: 'Passed Math Part 1',
    BMC01_P_M1: 'Passed Math Part 1',
    CSI01_P_M1: 'Passed Math Part 1',
    CTY01_P_M1: 'Passed Math Part 1',
    HOS01_P_M1: 'Passed Math Part 1',
    HTR01_P_M1: 'Passed Math Part 1',
    JJC01_P_M1: 'Passed Math Part 1',
    KCC01_P_M1: 'Passed Math Part 1',
    LAG01_P_M1: 'Passed Math Part 1',
    LAW01_P_M1: 'Passed Math Part 1',
    LEH01_P_M1: 'Passed Math Part 1',
    MEC01_P_M1: 'Passed Math Part 1',
    NCC01_P_M1: 'Passed Math Part 1',
    NYT01_P_M1: 'Passed Math Part 1',
    QCC01_P_M1: 'Passed Math Part 1',
    QNS01_P_M1: 'Passed Math Part 1',
    SLU01_P_M1: 'Passed Math Part 1',
    SPS01_P_M1: 'Passed Math Part 1',
    YRK01_P_M1: 'Passed Math Part 1',
    BAR01_P_M2: 'Passed Math Part 2',
    BCC01_P_M2: 'Passed Math Part 2',
    BKL01_P_M2: 'Passed Math Part 2',
    BMC01_P_M2: 'Passed Math Part 2',
    CSI01_P_M2: 'Passed Math Part 2',
    CTY01_P_M2: 'Passed Math Part 2',
    HOS01_P_M2: 'Passed Math Part 2',
    HTR01_P_M2: 'Passed Math Part 2',
    JJC01_P_M2: 'Passed Math Part 2',
    KCC01_P_M2: 'Passed Math Part 2',
    LAG01_P_M2: 'Passed Math Part 2',
    LAW01_P_M2: 'Passed Math Part 2',
    LEH01_P_M2: 'Passed Math Part 2',
    MEC01_P_M2: 'Passed Math Part 2',
    NCC01_P_M2: 'Passed Math Part 2',
    NYT01_P_M2: 'Passed Math Part 2',
    QCC01_P_M2: 'Passed Math Part 2',
    QNS01_P_M2: 'Passed Math Part 2',
    SLU01_P_M2: 'Passed Math Part 2',
    SPS01_P_M2: 'Passed Math Part 2',
    YRK01_P_M2: 'Passed Math Part 2',
    BAR01_P_RA: 'Passed Reading',
    BCC01_P_RA: 'Passed Reading',
    BKL01_P_RA: 'Passed Reading',
    BMC01_P_RA: 'Passed Reading',
    CSI01_P_RA: 'Passed Reading',
    CTY01_P_RA: 'Passed Reading',
    HOS01_P_RA: 'Passed Reading',
    HTR01_P_RA: 'Passed Reading',
    JJC01_P_RA: 'Passed Reading',
    KCC01_P_RA: 'Passed Reading',
    LAG01_P_RA: 'Passed Reading',
    LAW01_P_RA: 'Passed Reading',
    LEH01_P_RA: 'Passed Reading',
    MEC01_P_RA: 'Passed Reading',
    NCC01_P_RA: 'Passed Reading',
    NYT01_P_RA: 'Passed Reading',
    QCC01_P_RA: 'Passed Reading',
    QNS01_P_RA: 'Passed Reading',
    YRK01_P_RA: 'Passed Reading',
    BMC01_P_WA: 'Passed Writing',
    HOS01_P_WR: 'Passed Writing',
    LAG01_WR52: 'Passed Writing, Failed Reading',
    BKL01_P2PE: 'Pathway to Physical Education',
    QNS01_P2PE: 'Pathway to Physical Education',
    SLU01_PODV: 'Pathways ADVISED',
    SPS01_PODV: 'Pathways ADVISED',
    BAR01_PATH: 'Pathways Gen Ed',
    MEC01_PTH: 'Pathways OPT-IN',
    SLU01_POIN: 'Pathways OPT/IN',
    SPS01_POIN: 'Pathways OPT/IN',
    SLU01_POUT: 'Pathways OPT/OUT',
    SPS01_POUT: 'Pathways OPT/OUT',
    BAR01_PWOP: 'Pathways Opt In',
    YRK01_HSPP: 'Pathways Prep High School',
    NYT01_PTHH: 'Pathways-Hybrid STEM',
    NYT01_PTHN: 'Pathways-Non STEM',
    NYT01_PTHS: 'Pathways-STEM',
    LEH01_PCPR: 'Peace Corps Prep',
    BMC01_MTEE: 'Peer Mentee',
    BMC01_MTOR: 'Peer Mentor',
    BAR01_PHBS: 'Peking University HSBC Bus Sch',
    LEH01_CO06: 'Pending AA or AS Degree',
    LEH01_COAX: 'Pending AAS Degree',
    CSI01_ENAA: 'Pending Enroute Associates',
    NYT01_ISSP: 'Pending International Student',
    LEH01_PMHC: 'Pending Macaulay Honors Colleg',
    JJC01_PMHC: 'Pending Macauley Honors Colleg',
    QCC01_PMHC: 'Pending Macauley Honors Colleg',
    QNS01_PMHC: 'Pending Macauley Honors Colleg',
    BKL01_TEAP: 'Pending Teachers Academy',
    CSI01_TEAP: 'Pending Teachers Academy',
    HOS01_TEAP: 'Pending Teachers Academy',
    HTR01_TEAP: 'Pending Teachers Academy',
    KCC01_TEAP: 'Pending Teachers Academy',
    LAG01_TEAP: 'Pending Teachers Academy',
    LEH01_TEAP: 'Pending Teachers Academy',
    NCC01_TEAP: 'Pending Teachers Academy',
    NYT01_TEAP: 'Pending Teachers Academy',
    QCC01_PGTA: 'Pending Teachers Academy',
    QNS01_PGTA: 'Pending Teachers Academy',
    YRK01_TEAP: 'Pending Teachers Academy',
    BCC01_243F: 'Perkins Loan Student Selected',
    HOS01_243F: 'Perkins Loan Student Selected',
    KCC01_243F: 'Perkins Loan Student Selected',
    LAG01_243F: 'Perkins Loan Student Selected',
    LAW01_243F: 'Perkins Loan Student Selected',
    LEH01_243F: 'Perkins Loan Student Selected',
    QCC01_243F: 'Perkins Loan Student Selected',
    SOJ01_243F: 'Perkins Loan Student Selected',
    BCC01_243G: 'Perkins LoanStdn Requires Exit',
    CTY01_EXIT: 'Perkins LoanStdn Requires Exit',
    HOS01_243G: 'Perkins LoanStdn Requires Exit',
    KCC01_243G: 'Perkins LoanStdn Requires Exit',
    LAG01_243G: 'Perkins LoanStdn Requires Exit',
    LAW01_243G: 'Perkins LoanStdn Requires Exit',
    LEH01_243G: 'Perkins LoanStdn Requires Exit',
    NYT01_243G: 'Perkins LoanStdn Requires Exit',
    QCC01_243G: 'Perkins LoanStdn Requires Exit',
    QNS01_243F: 'Perkins LoanStdn Requires Exit',
    QNS01_243G: 'Perkins LoanStdn Requires Exit',
    SLU01_243G: 'Perkins LoanStdn Requires Exit',
    BKL01_PLFI: 'Perkins Receip Future Exit Int',
    BKL01_PLSI: 'Perkins Stdnt Reqs Exit Int',
    MEC01_RESD: 'Permanent Resd Pending',
    NYT01_EMGR: 'Permission To Readmit per VP',
    GRD01_PICS: 'Permit In Consortium Students',
    BAR01_PI12: 'Permit In from BMCC',
    BCC01_PI12: 'Permit In from BMCC',
    BKL01_PI12: 'Permit In from BMCC',
    BMC01_PI12: 'Permit In from BMCC',
    CSI01_PI12: 'Permit In from BMCC',
    CTY01_PI12: 'Permit In from BMCC',
    HOS01_PI12: 'Permit In from BMCC',
    HTR01_PI12: 'Permit In from BMCC',
    JJC01_PI12: 'Permit In from BMCC',
    KCC01_PI12: 'Permit In from BMCC',
    LAG01_PI12: 'Permit In from BMCC',
    LAW01_PI12: 'Permit In from BMCC',
    LEH01_PI12: 'Permit In from BMCC',
    MEC01_PI12: 'Permit In from BMCC',
    NCC01_PI12: 'Permit In from BMCC',
    NYT01_PI12: 'Permit In from BMCC',
    QCC01_PI12: 'Permit In from BMCC',
    QNS01_PI12: 'Permit In from BMCC',
    SPH01_PI12: 'Permit In from BMCC',
    SPS01_PI12: 'Permit In from BMCC',
    YRK01_PI12: 'Permit In from BMCC',
    BAR01_PI02: 'Permit In from Baruch College',
    BCC01_PI02: 'Permit In from Baruch College',
    BKL01_PI02: 'Permit In from Baruch College',
    BMC01_PI02: 'Permit In from Baruch College',
    CSI01_PI02: 'Permit In from Baruch College',
    CTY01_PI02: 'Permit In from Baruch College',
    HOS01_PI02: 'Permit In from Baruch College',
    HTR01_PI02: 'Permit In from Baruch College',
    JJC01_PI02: 'Permit In from Baruch College',
    KCC01_PI02: 'Permit In from Baruch College',
    LAG01_PI02: 'Permit In from Baruch College',
    LAW01_PI02: 'Permit In from Baruch College',
    LEH01_PI02: 'Permit In from Baruch College',
    MEC01_PI02: 'Permit In from Baruch College',
    NCC01_PI02: 'Permit In from Baruch College',
    NYT01_PI02: 'Permit In from Baruch College',
    QCC01_PI02: 'Permit In from Baruch College',
    QNS01_PI02: 'Permit In from Baruch College',
    SPH01_PI02: 'Permit In from Baruch College',
    SPS01_PI02: 'Permit In from Baruch College',
    YRK01_PI02: 'Permit In from Baruch College',
    BAR01_PI08: 'Permit In from Bronx CC',
    BCC01_PI08: 'Permit In from Bronx CC',
    BKL01_PI08: 'Permit In from Bronx CC',
    BMC01_PI08: 'Permit In from Bronx CC',
    CSI01_PI08: 'Permit In from Bronx CC',
    CTY01_PI08: 'Permit In from Bronx CC',
    HOS01_PI08: 'Permit In from Bronx CC',
    HTR01_PI08: 'Permit In from Bronx CC',
    JJC01_PI08: 'Permit In from Bronx CC',
    KCC01_PI08: 'Permit In from Bronx CC',
    LAG01_PI08: 'Permit In from Bronx CC',
    LAW01_PI08: 'Permit In from Bronx CC',
    LEH01_PI08: 'Permit In from Bronx CC',
    MEC01_PI08: 'Permit In from Bronx CC',
    NCC01_PI08: 'Permit In from Bronx CC',
    NYT01_PI08: 'Permit In from Bronx CC',
    QCC01_PI08: 'Permit In from Bronx CC',
    QNS01_PI08: 'Permit In from Bronx CC',
    SPH01_PI08: 'Permit In from Bronx CC',
    SPS01_PI08: 'Permit In from Bronx CC',
    YRK01_PI08: 'Permit In from Bronx CC',
    BAR01_PI05: 'Permit In from BrooklynCollege',
    BCC01_PI05: 'Permit In from BrooklynCollege',
    BKL01_PI05: 'Permit In from BrooklynCollege',
    BMC01_PI05: 'Permit In from BrooklynCollege',
    CSI01_PI05: 'Permit In from BrooklynCollege',
    CTY01_PI05: 'Permit In from BrooklynCollege',
    HOS01_PI05: 'Permit In from BrooklynCollege',
    HTR01_PI05: 'Permit In from BrooklynCollege',
    JJC01_PI05: 'Permit In from BrooklynCollege',
    KCC01_PI05: 'Permit In from BrooklynCollege',
    LAG01_PI05: 'Permit In from BrooklynCollege',
    LEH01_PI05: 'Permit In from BrooklynCollege',
    MEC01_PI05: 'Permit In from BrooklynCollege',
    NCC01_PI05: 'Permit In from BrooklynCollege',
    NYT01_PI05: 'Permit In from BrooklynCollege',
    QCC01_PI05: 'Permit In from BrooklynCollege',
    QNS01_PI05: 'Permit In from BrooklynCollege',
    SPH01_PI05: 'Permit In from BrooklynCollege',
    SPS01_PI05: 'Permit In from BrooklynCollege',
    YRK01_PI05: 'Permit In from BrooklynCollege',
    BAR01_PI20: 'Permit In from CSI',
    BCC01_PI20: 'Permit In from CSI',
    BKL01_PI20: 'Permit In from CSI',
    BMC01_PI20: 'Permit In from CSI',
    CSI01_PI20: 'Permit In from CSI',
    CTY01_PI20: 'Permit In from CSI',
    HOS01_PI20: 'Permit In from CSI',
    HTR01_PI20: 'Permit In from CSI',
    JJC01_PI20: 'Permit In from CSI',
    KCC01_PI20: 'Permit In from CSI',
    LAG01_PI20: 'Permit In from CSI',
    LAW01_PI20: 'Permit In from CSI',
    LEH01_PI20: 'Permit In from CSI',
    MEC01_PI20: 'Permit In from CSI',
    NCC01_PI20: 'Permit In from CSI',
    NYT01_PI20: 'Permit In from CSI',
    QCC01_PI20: 'Permit In from CSI',
    QNS01_PI20: 'Permit In from CSI',
    SPH01_PI20: 'Permit In from CSI',
    SPS01_PI20: 'Permit In from CSI',
    YRK01_PI20: 'Permit In from CSI',
    BAR01_PI21: 'Permit In from CUNY Law',
    BCC01_PI21: 'Permit In from CUNY Law',
    BKL01_PI21: 'Permit In from CUNY Law',
    BMC01_PI21: 'Permit In from CUNY Law',
    CSI01_PI21: 'Permit In from CUNY Law',
    CTY01_PI21: 'Permit In from CUNY Law',
    HOS01_PI21: 'Permit In from CUNY Law',
    HTR01_PI21: 'Permit In from CUNY Law',
    JJC01_PI21: 'Permit In from CUNY Law',
    KCC01_PI21: 'Permit In from CUNY Law',
    LAG01_PI21: 'Permit In from CUNY Law',
    LEH01_PI21: 'Permit In from CUNY Law',
    MEC01_PI21: 'Permit In from CUNY Law',
    NCC01_PI21: 'Permit In from CUNY Law',
    NYT01_PI21: 'Permit In from CUNY Law',
    QCC01_PI21: 'Permit In from CUNY Law',
    QNS01_PI21: 'Permit In from CUNY Law',
    SPH01_PI21: 'Permit In from CUNY Law',
    SPS01_PI21: 'Permit In from CUNY Law',
    YRK01_PI21: 'Permit In from CUNY Law',
    BAR01_PI01: 'Permit In from City College',
    BCC01_PI01: 'Permit In from City College',
    BKL01_PI01: 'Permit In from City College',
    BMC01_PI01: 'Permit In from City College',
    CSI01_PI01: 'Permit In from City College',
    CTY01_PI01: 'Permit In from City College',
    HOS01_PI01: 'Permit In from City College',
    HTR01_PI01: 'Permit In from City College',
    JJC01_PI01: 'Permit In from City College',
    LAG01_PI01: 'Permit In from City College',
    LAW01_PI01: 'Permit In from City College',
    LEH01_PI01: 'Permit In from City College',
    MEC01_PI01: 'Permit In from City College',
    NCC01_PI01: 'Permit In from City College',
    NYT01_PI01: 'Permit In from City College',
    QCC01_PI01: 'Permit In from City College',
    QNS01_PI01: 'Permit In from City College',
    SPH01_PI01: 'Permit In from City College',
    SPS01_PI01: 'Permit In from City College',
    YRK01_PI01: 'Permit In from City College',
    BAR01_PI23: 'Permit In from Grad Center',
    BCC01_PI23: 'Permit In from Grad Center',
    BKL01_PI23: 'Permit In from Grad Center',
    BMC01_PI23: 'Permit In from Grad Center',
    CSI01_PI23: 'Permit In from Grad Center',
    CTY01_PI23: 'Permit In from Grad Center',
    HOS01_PI23: 'Permit In from Grad Center',
    HTR01_PI23: 'Permit In from Grad Center',
    JJC01_PI23: 'Permit In from Grad Center',
    KCC01_PI23: 'Permit In from Grad Center',
    LAG01_PI23: 'Permit In from Grad Center',
    LEH01_PI23: 'Permit In from Grad Center',
    MEC01_PI23: 'Permit In from Grad Center',
    NCC01_PI23: 'Permit In from Grad Center',
    NYT01_PI23: 'Permit In from Grad Center',
    SPH01_PI23: 'Permit In from Grad Center',
    SPS01_PI23: 'Permit In from Grad Center',
    YRK01_PI23: 'Permit In from Grad Center',
    QCC01_PI23: 'Permit In from Grad School',
    QNS01_PI23: 'Permit In from Grad School',
    BAR01_PI17: 'Permit In from Guttman CC',
    BCC01_PI17: 'Permit In from Guttman CC',
    BKL01_PI17: 'Permit In from Guttman CC',
    BMC01_PI17: 'Permit In from Guttman CC',
    CSI01_PI17: 'Permit In from Guttman CC',
    CTY01_PI17: 'Permit In from Guttman CC',
    HOS01_PI17: 'Permit In from Guttman CC',
    HTR01_PI17: 'Permit In from Guttman CC',
    JJC01_PI17: 'Permit In from Guttman CC',
    KCC01_PI17: 'Permit In from Guttman CC',
    LAG01_PI17: 'Permit In from Guttman CC',
    LAW01_PI17: 'Permit In from Guttman CC',
    LEH01_PI17: 'Permit In from Guttman CC',
    MEC01_PI17: 'Permit In from Guttman CC',
    NYT01_PI17: 'Permit In from Guttman CC',
    QCC01_PI17: 'Permit In from Guttman CC',
    QNS01_PI17: 'Permit In from Guttman CC',
    SPH01_PI17: 'Permit In from Guttman CC',
    SPS01_PI17: 'Permit In from Guttman CC',
    YRK01_PI17: 'Permit In from Guttman CC',
    BAR01_PI14: 'Permit In from Hostos CC',
    BCC01_PI14: 'Permit In from Hostos CC',
    BKL01_PI14: 'Permit In from Hostos CC',
    BMC01_PI14: 'Permit In from Hostos CC',
    CSI01_PI14: 'Permit In from Hostos CC',
    CTY01_PI14: 'Permit In from Hostos CC',
    HTR01_PI14: 'Permit In from Hostos CC',
    JJC01_PI14: 'Permit In from Hostos CC',
    KCC01_PI14: 'Permit In from Hostos CC',
    LAG01_PI14: 'Permit In from Hostos CC',
    LAW01_PI14: 'Permit In from Hostos CC',
    LEH01_PI14: 'Permit In from Hostos CC',
    MEC01_PI14: 'Permit In from Hostos CC',
    NCC01_PI14: 'Permit In from Hostos CC',
    NYT01_PI14: 'Permit In from Hostos CC',
    QCC01_PI14: 'Permit In from Hostos CC',
    QNS01_PI14: 'Permit In from Hostos CC',
    SPH01_PI14: 'Permit In from Hostos CC',
    SPS01_PI14: 'Permit In from Hostos CC',
    YRK01_PI14: 'Permit In from Hostos CC',
    BAR01_PI03: 'Permit In from Hunter College',
    BCC01_PI03: 'Permit In from Hunter College',
    BKL01_PI03: 'Permit In from Hunter College',
    BMC01_PI03: 'Permit In from Hunter College',
    CSI01_PI03: 'Permit In from Hunter College',
    CTY01_PI03: 'Permit In from Hunter College',
    HOS01_PI03: 'Permit In from Hunter College',
    HTR01_PI03: 'Permit In from Hunter College',
    JJC01_PI03: 'Permit In from Hunter College',
    KCC01_PI03: 'Permit In from Hunter College',
    LAG01_PI03: 'Permit In from Hunter College',
    LAW01_PI03: 'Permit In from Hunter College',
    LEH01_PI03: 'Permit In from Hunter College',
    MEC01_PI03: 'Permit In from Hunter College',
    NCC01_PI03: 'Permit In from Hunter College',
    NYT01_PI03: 'Permit In from Hunter College',
    QCC01_PI03: 'Permit In from Hunter College',
    QNS01_PI03: 'Permit In from Hunter College',
    SPH01_PI03: 'Permit In from Hunter College',
    SPS01_PI03: 'Permit In from Hunter College',
    YRK01_PI03: 'Permit In from Hunter College',
    JJC01_PI16: 'Permit In from John Jay',
    BAR01_PI16: 'Permit In from John JayCollege',
    BCC01_PI16: 'Permit In from John JayCollege',
    BKL01_PI16: 'Permit In from John JayCollege',
    BMC01_PI16: 'Permit In from John JayCollege',
    CSI01_PI16: 'Permit In from John JayCollege',
    CTY01_PI16: 'Permit In from John JayCollege',
    HOS01_PI16: 'Permit In from John JayCollege',
    HTR01_PI16: 'Permit In from John JayCollege',
    KCC01_PI16: 'Permit In from John JayCollege',
    LAG01_PI16: 'Permit In from John JayCollege',
    LAW01_PI16: 'Permit In from John JayCollege',
    LEH01_PI16: 'Permit In from John JayCollege',
    MEC01_PI16: 'Permit In from John JayCollege',
    NCC01_PI16: 'Permit In from John JayCollege',
    NYT01_PI16: 'Permit In from John JayCollege',
    QCC01_PI16: 'Permit In from John JayCollege',
    QNS01_PI16: 'Permit In from John JayCollege',
    SPH01_PI16: 'Permit In from John JayCollege',
    SPS01_PI16: 'Permit In from John JayCollege',
    YRK01_PI16: 'Permit In from John JayCollege',
    BCC01_PI10: 'Permit In from Kingsborough CC',
    BMC01_PI10: 'Permit In from Kingsborough CC',
    LAG01_PI10: 'Permit In from Kingsborough CC',
    LAW01_PI10: 'Permit In from Kingsborough CC',
    QCC01_PI10: 'Permit In from Kingsborough CC',
    QNS01_PI10: 'Permit In from Kingsborough CC',
    BAR01_PI10: 'Permit In from KingsboroughCC',
    BKL01_PI10: 'Permit In from KingsboroughCC',
    CSI01_PI10: 'Permit In from KingsboroughCC',
    CTY01_PI10: 'Permit In from KingsboroughCC',
    HOS01_PI10: 'Permit In from KingsboroughCC',
    HTR01_PI10: 'Permit In from KingsboroughCC',
    JJC01_PI10: 'Permit In from KingsboroughCC',
    LEH01_PI10: 'Permit In from KingsboroughCC',
    MEC01_PI10: 'Permit In from KingsboroughCC',
    NCC01_PI10: 'Permit In from KingsboroughCC',
    NYT01_PI10: 'Permit In from KingsboroughCC',
    SPH01_PI10: 'Permit In from KingsboroughCC',
    SPS01_PI10: 'Permit In from KingsboroughCC',
    YRK01_PI10: 'Permit In from KingsboroughCC',
    BAR01_PI18: 'Permit In from LaGuardia CC',
    BCC01_PI18: 'Permit In from LaGuardia CC',
    BKL01_PI18: 'Permit In from LaGuardia CC',
    BMC01_PI18: 'Permit In from LaGuardia CC',
    CSI01_PI18: 'Permit In from LaGuardia CC',
    CTY01_PI18: 'Permit In from LaGuardia CC',
    HOS01_PI18: 'Permit In from LaGuardia CC',
    HTR01_PI18: 'Permit In from LaGuardia CC',
    JJC01_PI18: 'Permit In from LaGuardia CC',
    KCC01_PI18: 'Permit In from LaGuardia CC',
    LAW01_PI18: 'Permit In from LaGuardia CC',
    LEH01_PI18: 'Permit In from LaGuardia CC',
    MEC01_PI18: 'Permit In from LaGuardia CC',
    NCC01_PI18: 'Permit In from LaGuardia CC',
    NYT01_PI18: 'Permit In from LaGuardia CC',
    QCC01_PI18: 'Permit In from LaGuardia CC',
    QNS01_PI18: 'Permit In from LaGuardia CC',
    SPH01_PI18: 'Permit In from LaGuardia CC',
    SPS01_PI18: 'Permit In from LaGuardia CC',
    YRK01_PI18: 'Permit In from LaGuardia CC',
    BAR01_PI04: 'Permit In from Lehman College',
    BCC01_PI04: 'Permit In from Lehman College',
    BKL01_PI04: 'Permit In from Lehman College',
    BMC01_PI04: 'Permit In from Lehman College',
    CSI01_PI04: 'Permit In from Lehman College',
    CTY01_PI04: 'Permit In from Lehman College',
    HOS01_PI04: 'Permit In from Lehman College',
    HTR01_PI04: 'Permit In from Lehman College',
    JJC01_PI04: 'Permit In from Lehman College',
    KCC01_PI04: 'Permit In from Lehman College',
    LAG01_PI04: 'Permit In from Lehman College',
    LAW01_PI04: 'Permit In from Lehman College',
    MEC01_PI04: 'Permit In from Lehman College',
    NCC01_PI04: 'Permit In from Lehman College',
    NYT01_PI04: 'Permit In from Lehman College',
    QCC01_PI04: 'Permit In from Lehman College',
    QNS01_PI04: 'Permit In from Lehman College',
    SPH01_PI04: 'Permit In from Lehman College',
    SPS01_PI04: 'Permit In from Lehman College',
    YRK01_PI04: 'Permit In from Lehman College',
    BAR01_PI19: 'Permit In from Medgar Evers',
    BCC01_PI19: 'Permit In from Medgar Evers',
    BKL01_PI19: 'Permit In from Medgar Evers',
    BMC01_PI19: 'Permit In from Medgar Evers',
    CSI01_PI19: 'Permit In from Medgar Evers',
    CTY01_PI19: 'Permit In from Medgar Evers',
    HOS01_PI19: 'Permit In from Medgar Evers',
    HTR01_PI19: 'Permit In from Medgar Evers',
    JJC01_PI19: 'Permit In from Medgar Evers',
    KCC01_PI19: 'Permit In from Medgar Evers',
    LAG01_PI19: 'Permit In from Medgar Evers',
    LAW01_PI19: 'Permit In from Medgar Evers',
    LEH01_PI19: 'Permit In from Medgar Evers',
    NCC01_PI19: 'Permit In from Medgar Evers',
    NYT01_PI19: 'Permit In from Medgar Evers',
    QCC01_PI19: 'Permit In from Medgar Evers',
    QNS01_PI19: 'Permit In from Medgar Evers',
    SPH01_PI19: 'Permit In from Medgar Evers',
    SPS01_PI19: 'Permit In from Medgar Evers',
    YRK01_PI19: 'Permit In from Medgar Evers',
    BAR01_PI13: 'Permit In from NYCCT',
    BCC01_PI13: 'Permit In from NYCCT',
    BKL01_PI13: 'Permit In from NYCCT',
    BMC01_PI13: 'Permit In from NYCCT',
    CSI01_PI13: 'Permit In from NYCCT',
    CTY01_PI13: 'Permit In from NYCCT',
    HOS01_PI13: 'Permit In from NYCCT',
    HTR01_PI13: 'Permit In from NYCCT',
    JJC01_PI13: 'Permit In from NYCCT',
    KCC01_PI13: 'Permit In from NYCCT',
    LAG01_PI13: 'Permit In from NYCCT',
    LAW01_PI13: 'Permit In from NYCCT',
    LEH01_PI13: 'Permit In from NYCCT',
    MEC01_PI13: 'Permit In from NYCCT',
    NCC01_PI13: 'Permit In from NYCCT',
    QCC01_PI13: 'Permit In from NYCCT',
    QNS01_PI13: 'Permit In from NYCCT',
    SPH01_PI13: 'Permit In from NYCCT',
    SPS01_PI13: 'Permit In from NYCCT',
    YRK01_PI13: 'Permit In from NYCCT',
    BAR01_PI06: 'Permit In from Queens College',
    BCC01_PI06: 'Permit In from Queens College',
    BKL01_PI06: 'Permit In from Queens College',
    BMC01_PI06: 'Permit In from Queens College',
    CSI01_PI06: 'Permit In from Queens College',
    CTY01_PI06: 'Permit In from Queens College',
    HOS01_PI06: 'Permit In from Queens College',
    HTR01_PI06: 'Permit In from Queens College',
    JJC01_PI06: 'Permit In from Queens College',
    KCC01_PI06: 'Permit In from Queens College',
    LAG01_PI06: 'Permit In from Queens College',
    LAW01_PI06: 'Permit In from Queens College',
    LEH01_PI06: 'Permit In from Queens College',
    MEC01_PI06: 'Permit In from Queens College',
    NCC01_PI06: 'Permit In from Queens College',
    NYT01_PI06: 'Permit In from Queens College',
    QCC01_PI06: 'Permit In from Queens College',
    QNS01_PI06: 'Permit In from Queens College',
    SPH01_PI06: 'Permit In from Queens College',
    SPS01_PI06: 'Permit In from Queens College',
    YRK01_PI06: 'Permit In from Queens College',
    BAR01_PI09: 'Permit In from QueensboroughCC',
    BCC01_PI09: 'Permit In from QueensboroughCC',
    BKL01_PI09: 'Permit In from QueensboroughCC',
    BMC01_PI09: 'Permit In from QueensboroughCC',
    CSI01_PI09: 'Permit In from QueensboroughCC',
    CTY01_PI09: 'Permit In from QueensboroughCC',
    HOS01_PI09: 'Permit In from QueensboroughCC',
    HTR01_PI09: 'Permit In from QueensboroughCC',
    JJC01_PI09: 'Permit In from QueensboroughCC',
    KCC01_PI09: 'Permit In from QueensboroughCC',
    LAG01_PI09: 'Permit In from QueensboroughCC',
    LAW01_PI09: 'Permit In from QueensboroughCC',
    LEH01_PI09: 'Permit In from QueensboroughCC',
    MEC01_PI09: 'Permit In from QueensboroughCC',
    NCC01_PI09: 'Permit In from QueensboroughCC',
    NYT01_PI09: 'Permit In from QueensboroughCC',
    QCC01_PI09: 'Permit In from QueensboroughCC',
    QNS01_PI09: 'Permit In from QueensboroughCC',
    SPH01_PI09: 'Permit In from QueensboroughCC',
    SPS01_PI09: 'Permit In from QueensboroughCC',
    YRK01_PI09: 'Permit In from QueensboroughCC',
    SPS01_PI25: 'Permit In from SOJ',
    BAR01_PI33: 'Permit In from SPH',
    BCC01_PI33: 'Permit In from SPH',
    BKL01_PI33: 'Permit In from SPH',
    BMC01_PI33: 'Permit In from SPH',
    CSI01_PI33: 'Permit In from SPH',
    CTY01_PI33: 'Permit In from SPH',
    HOS01_PI33: 'Permit In from SPH',
    HTR01_PI33: 'Permit In from SPH',
    JJC01_PI33: 'Permit In from SPH',
    KCC01_PI33: 'Permit In from SPH',
    LAG01_PI33: 'Permit In from SPH',
    LAW01_PI33: 'Permit In from SPH',
    LEH01_PI33: 'Permit In from SPH',
    MEC01_PI33: 'Permit In from SPH',
    NCC01_PI33: 'Permit In from SPH',
    NYT01_PI33: 'Permit In from SPH',
    QCC01_PI33: 'Permit In from SPH',
    QNS01_PI33: 'Permit In from SPH',
    SPH01_PI33: 'Permit In from SPH',
    SPS01_PI33: 'Permit In from SPH',
    YRK01_PI33: 'Permit In from SPH',
    BAR01_PI73: 'Permit In from SPS',
    BCC01_PI73: 'Permit In from SPS',
    BKL01_PI73: 'Permit In from SPS',
    BMC01_PI73: 'Permit In from SPS',
    CSI01_PI73: 'Permit In from SPS',
    CTY01_PI73: 'Permit In from SPS',
    HOS01_PI73: 'Permit In from SPS',
    HTR01_PI73: 'Permit In from SPS',
    JJC01_PI73: 'Permit In from SPS',
    KCC01_PI73: 'Permit In from SPS',
    LAG01_PI73: 'Permit In from SPS',
    LEH01_PI73: 'Permit In from SPS',
    MEC01_PI73: 'Permit In from SPS',
    NCC01_PI73: 'Permit In from SPS',
    NYT01_PI73: 'Permit In from SPS',
    QCC01_PI73: 'Permit In from SPS',
    QNS01_PI73: 'Permit In from SPS',
    SPH01_PI73: 'Permit In from SPS',
    YRK01_PI73: 'Permit In from SPS',
    BAR01_PI15: 'Permit In from York College',
    BCC01_PI15: 'Permit In from York College',
    BKL01_PI15: 'Permit In from York College',
    BMC01_PI15: 'Permit In from York College',
    CSI01_PI15: 'Permit In from York College',
    CTY01_PI15: 'Permit In from York College',
    HOS01_PI15: 'Permit In from York College',
    HTR01_PI15: 'Permit In from York College',
    JJC01_PI15: 'Permit In from York College',
    KCC01_PI15: 'Permit In from York College',
    LAG01_PI15: 'Permit In from York College',
    LAW01_PI15: 'Permit In from York College',
    LEH01_PI15: 'Permit In from York College',
    MEC01_PI15: 'Permit In from York College',
    NCC01_PI15: 'Permit In from York College',
    NYT01_PI15: 'Permit In from York College',
    QCC01_PI15: 'Permit In from York College',
    QNS01_PI15: 'Permit In from York College',
    SPH01_PI15: 'Permit In from York College',
    SPS01_PI15: 'Permit In from York College',
    BAR01_PO12: 'Permit Out to BMCC',
    BCC01_PO12: 'Permit Out to BMCC',
    BKL01_PO12: 'Permit Out to BMCC',
    BMC01_PO12: 'Permit Out to BMCC',
    CSI01_PO12: 'Permit Out to BMCC',
    CTY01_PO12: 'Permit Out to BMCC',
    HOS01_PO12: 'Permit Out to BMCC',
    HTR01_PO12: 'Permit Out to BMCC',
    JJC01_PO12: 'Permit Out to BMCC',
    KCC01_PO12: 'Permit Out to BMCC',
    LAG01_PO12: 'Permit Out to BMCC',
    LAW01_PO12: 'Permit Out to BMCC',
    LEH01_PO12: 'Permit Out to BMCC',
    MEC01_PO12: 'Permit Out to BMCC',
    NCC01_PO12: 'Permit Out to BMCC',
    NYT01_PO12: 'Permit Out to BMCC',
    QCC01_PO12: 'Permit Out to BMCC',
    QNS01_PO12: 'Permit Out to BMCC',
    SPH01_PO12: 'Permit Out to BMCC',
    SPS01_PO12: 'Permit Out to BMCC',
    YRK01_PO12: 'Permit Out to BMCC',
    BAR01_PO02: 'Permit Out to Baruch College',
    BCC01_PO02: 'Permit Out to Baruch College',
    BKL01_PO02: 'Permit Out to Baruch College',
    BMC01_PO02: 'Permit Out to Baruch College',
    CSI01_PO02: 'Permit Out to Baruch College',
    CTY01_PO02: 'Permit Out to Baruch College',
    HOS01_PO02: 'Permit Out to Baruch College',
    HTR01_PO02: 'Permit Out to Baruch College',
    JJC01_PO02: 'Permit Out to Baruch College',
    KCC01_PO02: 'Permit Out to Baruch College',
    LAG01_PO02: 'Permit Out to Baruch College',
    LAW01_PO02: 'Permit Out to Baruch College',
    LEH01_PO02: 'Permit Out to Baruch College',
    MEC01_PO02: 'Permit Out to Baruch College',
    NCC01_PO02: 'Permit Out to Baruch College',
    NYT01_PO02: 'Permit Out to Baruch College',
    QCC01_PO02: 'Permit Out to Baruch College',
    QNS01_PO02: 'Permit Out to Baruch College',
    SPH01_PO02: 'Permit Out to Baruch College',
    SPS01_PO02: 'Permit Out to Baruch College',
    YRK01_PO02: 'Permit Out to Baruch College',
    BAR01_PO08: 'Permit Out to Bronx CC',
    BCC01_PO08: 'Permit Out to Bronx CC',
    BKL01_PO08: 'Permit Out to Bronx CC',
    BMC01_PO08: 'Permit Out to Bronx CC',
    CSI01_PO08: 'Permit Out to Bronx CC',
    CTY01_PO08: 'Permit Out to Bronx CC',
    HOS01_PO08: 'Permit Out to Bronx CC',
    HTR01_PO08: 'Permit Out to Bronx CC',
    JJC01_PO08: 'Permit Out to Bronx CC',
    KCC01_PO08: 'Permit Out to Bronx CC',
    LAG01_PO08: 'Permit Out to Bronx CC',
    LAW01_PO08: 'Permit Out to Bronx CC',
    LEH01_PO08: 'Permit Out to Bronx CC',
    MEC01_PO08: 'Permit Out to Bronx CC',
    NCC01_PO08: 'Permit Out to Bronx CC',
    NYT01_PO08: 'Permit Out to Bronx CC',
    QCC01_PO08: 'Permit Out to Bronx CC',
    QNS01_PO08: 'Permit Out to Bronx CC',
    SPH01_PO08: 'Permit Out to Bronx CC',
    SPS01_PO08: 'Permit Out to Bronx CC',
    YRK01_PO08: 'Permit Out to Bronx CC',
    BAR01_PO05: 'Permit Out to Brooklyn College',
    BCC01_PO05: 'Permit Out to Brooklyn College',
    BKL01_PO05: 'Permit Out to Brooklyn College',
    BMC01_PO05: 'Permit Out to Brooklyn College',
    CSI01_PO05: 'Permit Out to Brooklyn College',
    CTY01_PO05: 'Permit Out to Brooklyn College',
    HOS01_PO05: 'Permit Out to Brooklyn College',
    HTR01_PO05: 'Permit Out to Brooklyn College',
    JJC01_PO05: 'Permit Out to Brooklyn College',
    KCC01_PO05: 'Permit Out to Brooklyn College',
    LAG01_PO05: 'Permit Out to Brooklyn College',
    LAW01_PO05: 'Permit Out to Brooklyn College',
    LEH01_PO05: 'Permit Out to Brooklyn College',
    MEC01_PO05: 'Permit Out to Brooklyn College',
    NCC01_PO05: 'Permit Out to Brooklyn College',
    NYT01_PO05: 'Permit Out to Brooklyn College',
    QCC01_PO05: 'Permit Out to Brooklyn College',
    QNS01_PO05: 'Permit Out to Brooklyn College',
    SPH01_PO05: 'Permit Out to Brooklyn College',
    SPS01_PO05: 'Permit Out to Brooklyn College',
    YRK01_PO05: 'Permit Out to Brooklyn College',
    BAR01_PO20: 'Permit Out to CSI',
    BCC01_PO20: 'Permit Out to CSI',
    BKL01_PO20: 'Permit Out to CSI',
    BMC01_PO20: 'Permit Out to CSI',
    CSI01_PO20: 'Permit Out to CSI',
    CTY01_PO20: 'Permit Out to CSI',
    HOS01_PO20: 'Permit Out to CSI',
    HTR01_PO20: 'Permit Out to CSI',
    JJC01_PO20: 'Permit Out to CSI',
    KCC01_PO20: 'Permit Out to CSI',
    LAG01_PO20: 'Permit Out to CSI',
    LAW01_PO20: 'Permit Out to CSI',
    LEH01_PO20: 'Permit Out to CSI',
    MEC01_PO20: 'Permit Out to CSI',
    NCC01_PO20: 'Permit Out to CSI',
    NYT01_PO20: 'Permit Out to CSI',
    QCC01_PO20: 'Permit Out to CSI',
    QNS01_PO20: 'Permit Out to CSI',
    SPH01_PO20: 'Permit Out to CSI',
    SPS01_PO20: 'Permit Out to CSI',
    YRK01_PO20: 'Permit Out to CSI',
    BAR01_PO21: 'Permit Out to CUNY Law',
    BCC01_PO21: 'Permit Out to CUNY Law',
    BKL01_PO21: 'Permit Out to CUNY Law',
    BMC01_PO21: 'Permit Out to CUNY Law',
    CSI01_PO21: 'Permit Out to CUNY Law',
    CTY01_PO21: 'Permit Out to CUNY Law',
    HOS01_PO21: 'Permit Out to CUNY Law',
    HTR01_PO21: 'Permit Out to CUNY Law',
    JJC01_PO21: 'Permit Out to CUNY Law',
    KCC01_PO21: 'Permit Out to CUNY Law',
    LAG01_PO21: 'Permit Out to CUNY Law',
    LEH01_PO21: 'Permit Out to CUNY Law',
    MEC01_PO21: 'Permit Out to CUNY Law',
    NCC01_PO21: 'Permit Out to CUNY Law',
    NYT01_PO21: 'Permit Out to CUNY Law',
    QCC01_PO21: 'Permit Out to CUNY Law',
    QNS01_PO21: 'Permit Out to CUNY Law',
    SPH01_PO21: 'Permit Out to CUNY Law',
    SPS01_PO21: 'Permit Out to CUNY Law',
    YRK01_PO21: 'Permit Out to CUNY Law',
    BAR01_PO01: 'Permit Out to City College',
    BCC01_PO01: 'Permit Out to City College',
    BKL01_PO01: 'Permit Out to City College',
    BMC01_PO01: 'Permit Out to City College',
    CSI01_PO01: 'Permit Out to City College',
    CTY01_PO01: 'Permit Out to City College',
    HOS01_PO01: 'Permit Out to City College',
    HTR01_PO01: 'Permit Out to City College',
    JJC01_PO01: 'Permit Out to City College',
    KCC01_PO01: 'Permit Out to City College',
    LAG01_PO01: 'Permit Out to City College',
    LAW01_PO01: 'Permit Out to City College',
    LEH01_PO01: 'Permit Out to City College',
    MEC01_PO01: 'Permit Out to City College',
    NCC01_PO01: 'Permit Out to City College',
    NYT01_PO01: 'Permit Out to City College',
    QCC01_PO01: 'Permit Out to City College',
    QNS01_PO01: 'Permit Out to City College',
    SPH01_PO01: 'Permit Out to City College',
    SPS01_PO01: 'Permit Out to City College',
    YRK01_PO01: 'Permit Out to City College',
    BAR01_PO23: 'Permit Out to Grad Center',
    BCC01_PO23: 'Permit Out to Grad Center',
    BKL01_PO23: 'Permit Out to Grad Center',
    BMC01_PO23: 'Permit Out to Grad Center',
    CSI01_PO23: 'Permit Out to Grad Center',
    CTY01_PO23: 'Permit Out to Grad Center',
    HOS01_PO23: 'Permit Out to Grad Center',
    HTR01_PO23: 'Permit Out to Grad Center',
    JJC01_PO23: 'Permit Out to Grad Center',
    KCC01_PO23: 'Permit Out to Grad Center',
    LAG01_PO23: 'Permit Out to Grad Center',
    LAW01_PO23: 'Permit Out to Grad Center',
    LEH01_PO23: 'Permit Out to Grad Center',
    MEC01_PO23: 'Permit Out to Grad Center',
    NCC01_PO23: 'Permit Out to Grad Center',
    NYT01_PO23: 'Permit Out to Grad Center',
    SPH01_PO23: 'Permit Out to Grad Center',
    SPS01_PO23: 'Permit Out to Grad Center',
    YRK01_PO23: 'Permit Out to Grad Center',
    QCC01_PO23: 'Permit Out to Grad School',
    QNS01_PO23: 'Permit Out to Grad School',
    NCC01_PO14: 'Permit Out to HOSTOS CC',
    BAR01_PO14: 'Permit Out to Hostos CC',
    BCC01_PO14: 'Permit Out to Hostos CC',
    BKL01_PO14: 'Permit Out to Hostos CC',
    BMC01_PO14: 'Permit Out to Hostos CC',
    CSI01_PO14: 'Permit Out to Hostos CC',
    CTY01_PO14: 'Permit Out to Hostos CC',
    HOS01_PO14: 'Permit Out to Hostos CC',
    HTR01_PO14: 'Permit Out to Hostos CC',
    JJC01_PO14: 'Permit Out to Hostos CC',
    KCC01_PO14: 'Permit Out to Hostos CC',
    LAG01_PO14: 'Permit Out to Hostos CC',
    LAW01_PO14: 'Permit Out to Hostos CC',
    LEH01_PO14: 'Permit Out to Hostos CC',
    MEC01_PO14: 'Permit Out to Hostos CC',
    NYT01_PO14: 'Permit Out to Hostos CC',
    QCC01_PO14: 'Permit Out to Hostos CC',
    QNS01_PO14: 'Permit Out to Hostos CC',
    SPH01_PO14: 'Permit Out to Hostos CC',
    SPS01_PO14: 'Permit Out to Hostos CC',
    YRK01_PO14: 'Permit Out to Hostos CC',
    BAR01_PO03: 'Permit Out to Hunter College',
    BCC01_PO03: 'Permit Out to Hunter College',
    BKL01_PO03: 'Permit Out to Hunter College',
    BMC01_PO03: 'Permit Out to Hunter College',
    CSI01_PO03: 'Permit Out to Hunter College',
    CTY01_PO03: 'Permit Out to Hunter College',
    HOS01_PO03: 'Permit Out to Hunter College',
    HTR01_PO03: 'Permit Out to Hunter College',
    JJC01_PO03: 'Permit Out to Hunter College',
    KCC01_PO03: 'Permit Out to Hunter College',
    LAG01_PO03: 'Permit Out to Hunter College',
    LAW01_PO03: 'Permit Out to Hunter College',
    LEH01_PO03: 'Permit Out to Hunter College',
    MEC01_PO03: 'Permit Out to Hunter College',
    NCC01_PO03: 'Permit Out to Hunter College',
    NYT01_PO03: 'Permit Out to Hunter College',
    QCC01_PO03: 'Permit Out to Hunter College',
    QNS01_PO03: 'Permit Out to Hunter College',
    SPH01_PO03: 'Permit Out to Hunter College',
    SPS01_PO03: 'Permit Out to Hunter College',
    YRK01_PO03: 'Permit Out to Hunter College',
    JJC01_PO16: 'Permit Out to John Jay',
    BAR01_PO16: 'Permit Out to John Jay College',
    BCC01_PO16: 'Permit Out to John Jay College',
    BKL01_PO16: 'Permit Out to John Jay College',
    BMC01_PO16: 'Permit Out to John Jay College',
    CSI01_PO16: 'Permit Out to John Jay College',
    CTY01_PO16: 'Permit Out to John Jay College',
    HOS01_PO16: 'Permit Out to John Jay College',
    HTR01_PO16: 'Permit Out to John Jay College',
    KCC01_PO16: 'Permit Out to John Jay College',
    LAG01_PO16: 'Permit Out to John Jay College',
    LAW01_PO16: 'Permit Out to John Jay College',
    LEH01_PO16: 'Permit Out to John Jay College',
    MEC01_PO16: 'Permit Out to John Jay College',
    NCC01_PO16: 'Permit Out to John Jay College',
    NYT01_PO16: 'Permit Out to John Jay College',
    QCC01_PO16: 'Permit Out to John Jay College',
    QNS01_PO16: 'Permit Out to John Jay College',
    SPH01_PO16: 'Permit Out to John Jay College',
    SPS01_PO16: 'Permit Out to John Jay College',
    YRK01_PO16: 'Permit Out to John Jay College',
    LAW01_PO10: 'Permit Out to Kingsborough C',
    QNS01_PO10: 'Permit Out to Kingsborough C',
    BAR01_PO10: 'Permit Out to Kingsborough CC',
    BCC01_PO10: 'Permit Out to Kingsborough CC',
    BKL01_PO10: 'Permit Out to Kingsborough CC',
    BMC01_PO10: 'Permit Out to Kingsborough CC',
    CSI01_PO10: 'Permit Out to Kingsborough CC',
    CTY01_PO10: 'Permit Out to Kingsborough CC',
    HOS01_PO10: 'Permit Out to Kingsborough CC',
    HTR01_PO10: 'Permit Out to Kingsborough CC',
    JJC01_PO10: 'Permit Out to Kingsborough CC',
    LAG01_PO10: 'Permit Out to Kingsborough CC',
    LEH01_PO10: 'Permit Out to Kingsborough CC',
    MEC01_PO10: 'Permit Out to Kingsborough CC',
    NCC01_PO10: 'Permit Out to Kingsborough CC',
    NYT01_PO10: 'Permit Out to Kingsborough CC',
    QCC01_PO10: 'Permit Out to Kingsborough CC',
    SPH01_PO10: 'Permit Out to Kingsborough CC',
    SPS01_PO10: 'Permit Out to Kingsborough CC',
    YRK01_PO10: 'Permit Out to Kingsborough CC',
    BAR01_PO18: 'Permit Out to LaGuardia CC',
    BCC01_PO18: 'Permit Out to LaGuardia CC',
    BKL01_PO18: 'Permit Out to LaGuardia CC',
    BMC01_PO18: 'Permit Out to LaGuardia CC',
    CSI01_PO18: 'Permit Out to LaGuardia CC',
    CTY01_PO18: 'Permit Out to LaGuardia CC',
    HOS01_PO18: 'Permit Out to LaGuardia CC',
    HTR01_PO18: 'Permit Out to LaGuardia CC',
    JJC01_PO18: 'Permit Out to LaGuardia CC',
    KCC01_PO18: 'Permit Out to LaGuardia CC',
    LAW01_PO18: 'Permit Out to LaGuardia CC',
    LEH01_PO18: 'Permit Out to LaGuardia CC',
    MEC01_PO18: 'Permit Out to LaGuardia CC',
    NCC01_PO18: 'Permit Out to LaGuardia CC',
    NYT01_PO18: 'Permit Out to LaGuardia CC',
    QCC01_PO18: 'Permit Out to LaGuardia CC',
    QNS01_PO18: 'Permit Out to LaGuardia CC',
    SPH01_PO18: 'Permit Out to LaGuardia CC',
    SPS01_PO18: 'Permit Out to LaGuardia CC',
    YRK01_PO18: 'Permit Out to LaGuardia CC',
    BAR01_PO04: 'Permit Out to Lehman College',
    BCC01_PO04: 'Permit Out to Lehman College',
    BKL01_PO04: 'Permit Out to Lehman College',
    BMC01_PO04: 'Permit Out to Lehman College',
    CSI01_PO04: 'Permit Out to Lehman College',
    CTY01_PO04: 'Permit Out to Lehman College',
    HOS01_PO04: 'Permit Out to Lehman College',
    HTR01_PO04: 'Permit Out to Lehman College',
    JJC01_PO04: 'Permit Out to Lehman College',
    KCC01_PO04: 'Permit Out to Lehman College',
    LAG01_PO04: 'Permit Out to Lehman College',
    LAW01_PO04: 'Permit Out to Lehman College',
    MEC01_PO04: 'Permit Out to Lehman College',
    NCC01_PO04: 'Permit Out to Lehman College',
    NYT01_PO04: 'Permit Out to Lehman College',
    QCC01_PO04: 'Permit Out to Lehman College',
    QNS01_PO04: 'Permit Out to Lehman College',
    SPH01_PO04: 'Permit Out to Lehman College',
    SPS01_PO04: 'Permit Out to Lehman College',
    YRK01_PO04: 'Permit Out to Lehman College',
    BAR01_PO19: 'Permit Out to Medgar Evers',
    BCC01_PO19: 'Permit Out to Medgar Evers',
    BKL01_PO19: 'Permit Out to Medgar Evers',
    BMC01_PO19: 'Permit Out to Medgar Evers',
    CSI01_PO19: 'Permit Out to Medgar Evers',
    CTY01_PO19: 'Permit Out to Medgar Evers',
    HOS01_PO19: 'Permit Out to Medgar Evers',
    HTR01_PO19: 'Permit Out to Medgar Evers',
    JJC01_PO19: 'Permit Out to Medgar Evers',
    KCC01_PO19: 'Permit Out to Medgar Evers',
    LAG01_PO19: 'Permit Out to Medgar Evers',
    LAW01_PO19: 'Permit Out to Medgar Evers',
    LEH01_PO19: 'Permit Out to Medgar Evers',
    NCC01_PO19: 'Permit Out to Medgar Evers',
    NYT01_PO19: 'Permit Out to Medgar Evers',
    QCC01_PO19: 'Permit Out to Medgar Evers',
    QNS01_PO19: 'Permit Out to Medgar Evers',
    SPH01_PO19: 'Permit Out to Medgar Evers',
    SPS01_PO19: 'Permit Out to Medgar Evers',
    YRK01_PO19: 'Permit Out to Medgar Evers',
    QCC01_PO13: 'Permit Out to NYC Coll of Tech',
    QNS01_PO13: 'Permit Out to NYC Coll of Tech',
    BAR01_PO13: 'Permit Out to NYCCT',
    BCC01_PO13: 'Permit Out to NYCCT',
    BKL01_PO13: 'Permit Out to NYCCT',
    BMC01_PO13: 'Permit Out to NYCCT',
    CSI01_PO13: 'Permit Out to NYCCT',
    CTY01_PO13: 'Permit Out to NYCCT',
    HOS01_PO13: 'Permit Out to NYCCT',
    HTR01_PO13: 'Permit Out to NYCCT',
    JJC01_PO13: 'Permit Out to NYCCT',
    KCC01_PO13: 'Permit Out to NYCCT',
    LAG01_PO13: 'Permit Out to NYCCT',
    LAW01_PO13: 'Permit Out to NYCCT',
    LEH01_PO13: 'Permit Out to NYCCT',
    MEC01_PO13: 'Permit Out to NYCCT',
    NCC01_PO13: 'Permit Out to NYCCT',
    SPH01_PO13: 'Permit Out to NYCCT',
    SPS01_PO13: 'Permit Out to NYCCT',
    YRK01_PO13: 'Permit Out to NYCCT',
    BAR01_PO06: 'Permit Out to Queens College',
    BCC01_PO06: 'Permit Out to Queens College',
    BKL01_PO06: 'Permit Out to Queens College',
    BMC01_PO06: 'Permit Out to Queens College',
    CSI01_PO06: 'Permit Out to Queens College',
    CTY01_PO06: 'Permit Out to Queens College',
    HOS01_PO06: 'Permit Out to Queens College',
    HTR01_PO06: 'Permit Out to Queens College',
    JJC01_PO06: 'Permit Out to Queens College',
    KCC01_PO06: 'Permit Out to Queens College',
    LAG01_PO06: 'Permit Out to Queens College',
    LAW01_PO06: 'Permit Out to Queens College',
    LEH01_PO06: 'Permit Out to Queens College',
    MEC01_PO06: 'Permit Out to Queens College',
    NCC01_PO06: 'Permit Out to Queens College',
    NYT01_PO06: 'Permit Out to Queens College',
    QCC01_PO06: 'Permit Out to Queens College',
    QNS01_PO06: 'Permit Out to Queens College',
    SPH01_PO06: 'Permit Out to Queens College',
    SPS01_PO06: 'Permit Out to Queens College',
    YRK01_PO06: 'Permit Out to Queens College',
    BAR01_PO09: 'Permit Out to Queensborough CC',
    BCC01_PO09: 'Permit Out to Queensborough CC',
    BKL01_PO09: 'Permit Out to Queensborough CC',
    BMC01_PO09: 'Permit Out to Queensborough CC',
    CSI01_PO09: 'Permit Out to Queensborough CC',
    CTY01_PO09: 'Permit Out to Queensborough CC',
    HOS01_PO09: 'Permit Out to Queensborough CC',
    HTR01_PO09: 'Permit Out to Queensborough CC',
    JJC01_PO09: 'Permit Out to Queensborough CC',
    KCC01_PO09: 'Permit Out to Queensborough CC',
    LAG01_PO09: 'Permit Out to Queensborough CC',
    LAW01_PO09: 'Permit Out to Queensborough CC',
    LEH01_PO09: 'Permit Out to Queensborough CC',
    MEC01_PO09: 'Permit Out to Queensborough CC',
    NCC01_PO09: 'Permit Out to Queensborough CC',
    NYT01_PO09: 'Permit Out to Queensborough CC',
    QCC01_PO09: 'Permit Out to Queensborough CC',
    QNS01_PO09: 'Permit Out to Queensborough CC',
    SPH01_PO09: 'Permit Out to Queensborough CC',
    SPS01_PO09: 'Permit Out to Queensborough CC',
    YRK01_PO09: 'Permit Out to Queensborough CC',
    SPS01_PO25: 'Permit Out to SOJ',
    BAR01_PO33: 'Permit Out to SPH',
    BCC01_PO33: 'Permit Out to SPH',
    BKL01_PO33: 'Permit Out to SPH',
    BMC01_PO33: 'Permit Out to SPH',
    CSI01_PO33: 'Permit Out to SPH',
    CTY01_PO33: 'Permit Out to SPH',
    HOS01_PO33: 'Permit Out to SPH',
    HTR01_PO33: 'Permit Out to SPH',
    JJC01_PO33: 'Permit Out to SPH',
    KCC01_PO33: 'Permit Out to SPH',
    LAG01_PO33: 'Permit Out to SPH',
    LAW01_PO33: 'Permit Out to SPH',
    LEH01_PO33: 'Permit Out to SPH',
    MEC01_PO33: 'Permit Out to SPH',
    NCC01_PO33: 'Permit Out to SPH',
    BAR01_PO73: 'Permit Out to SPS',
    BCC01_PO73: 'Permit Out to SPS',
    BKL01_PO73: 'Permit Out to SPS',
    BMC01_PO73: 'Permit Out to SPS',
    CSI01_PO73: 'Permit Out to SPS',
    CTY01_PO73: 'Permit Out to SPS',
    HOS01_PO73: 'Permit Out to SPS',
    HTR01_PO73: 'Permit Out to SPS',
    JJC01_PO73: 'Permit Out to SPS',
    KCC01_PO73: 'Permit Out to SPS',
    LAG01_PO73: 'Permit Out to SPS',
    LAW01_PO73: 'Permit Out to SPS',
    LEH01_PO73: 'Permit Out to SPS',
    MEC01_PO73: 'Permit Out to SPS',
    NCC01_PO73: 'Permit Out to SPS',
    NYT01_PO73: 'Permit Out to SPS',
    QCC01_PO73: 'Permit Out to SPS',
    QNS01_PO73: 'Permit Out to SPS',
    SPH01_PO73: 'Permit Out to SPS',
    YRK01_PO73: 'Permit Out to SPS',
    BAR01_PO15: 'Permit Out to York College',
    BCC01_PO15: 'Permit Out to York College',
    BKL01_PO15: 'Permit Out to York College',
    BMC01_PO15: 'Permit Out to York College',
    CSI01_PO15: 'Permit Out to York College',
    CTY01_PO15: 'Permit Out to York College',
    HOS01_PO15: 'Permit Out to York College',
    HTR01_PO15: 'Permit Out to York College',
    JJC01_PO15: 'Permit Out to York College',
    KCC01_PO15: 'Permit Out to York College',
    LAG01_PO15: 'Permit Out to York College',
    LAW01_PO15: 'Permit Out to York College',
    LEH01_PO15: 'Permit Out to York College',
    MEC01_PO15: 'Permit Out to York College',
    NCC01_PO15: 'Permit Out to York College',
    NYT01_PO15: 'Permit Out to York College',
    QCC01_PO15: 'Permit Out to York College',
    QNS01_PO15: 'Permit Out to York College',
    SPH01_PO15: 'Permit Out to York College',
    SPS01_PO15: 'Permit Out to York College',
    KCC01_2I: 'Permit Student',
    LAW01_PI05: 'Permit in from BrooklynCollege',
    KCC01_PI01: 'Permit in from City College',
    LAW01_PI23: 'Permit in from Grad Center',
    LAW01_PI73: 'Permit in from SPS',
    MEC01_PETL: 'Petrie Grant Long',
    MEC01_PETS: 'Petrie Grant Short',
    BKL01_PHIB: 'Phi Beta Kappa',
    BKL01_PHIC: 'Phi Chi Honor Society',
    BAR01_PHIE: 'Phi Eta Sigma Honor Society',
    BMC01_PTK: 'Phi Theta Kappa',
    HTR01_PTKA: 'Phi Theta Kappa Award',
    BKL01_PHIL: 'Philosophy Non Degree',
    BCC01_PHYE: 'Physical Exam Docs Submitted',
    CSI01_PTC1: 'Physical Therapy Cohort 1',
    CSI01_PTC2: 'Physical Therapy Cohort 2',
    CSI01_PTC3: 'Physical Therapy Cohort 3',
    CTY01_BMPA: 'Physician\'s Assistant Program',
    MEC01_PMBK: 'Pipeline My Brother\'s Keeper',
    JJC01_CHIN: 'Placed CHI 101',
    QCC01_POE: 'Port of Entry Program',
    JJC01_POR1: 'Portuguese Placement 101',
    JJC01_POR2: 'Portuguese Placement 102',
    BKL01_TBPG: 'Possible Tb Program Candidate',
    BKL01_PSTB: 'Post Baccalaureate',
    CTY01_PBAC: 'Post Baccalaureate',
    HTR01_PBAP: 'Post Baccalaureate - STEM',
    LEH01_POST: 'Post Master\'s in Nursing Admin',
    HTR01_PBHC: 'Post-Bacc Health Careers',
    YRK01_TEPO: 'Potential Admiss to Teacher ED',
    NYT01_COPP: '"Potential COPE Student    "',
    LAG01_FEP0: 'Potential FEP Student',
    BMC01_HONP: 'Potential Honors Scholar',
    LEH01_HONP: 'Potential Honors Scholar',
    YRK01_PORA: 'Potential Remedial Failure',
    BAR01_SCD1: 'Potential SEEK/CD',
    BCC01_SCD1: 'Potential SEEK/CD',
    BKL01_SCD1: 'Potential SEEK/CD',
    BMC01_SCD1: 'Potential SEEK/CD',
    CSI01_SCD1: 'Potential SEEK/CD',
    CTY01_SCD1: 'Potential SEEK/CD',
    HOS01_SCD1: 'Potential SEEK/CD',
    HTR01_SCD1: 'Potential SEEK/CD',
    JJC01_SCD1: 'Potential SEEK/CD',
    KCC01_SCD1: 'Potential SEEK/CD',
    LAG01_SCD1: 'Potential SEEK/CD',
    LAW01_SCD1: 'Potential SEEK/CD',
    LEH01_SCD1: 'Potential SEEK/CD',
    MEC01_SCD1: 'Potential SEEK/CD',
    NCC01_SCD1: 'Potential SEEK/CD',
    NYT01_SCD1: 'Potential SEEK/CD',
    QCC01_SCD1: 'Potential SEEK/CD',
    QNS01_SCD1: 'Potential SEEK/CD',
    SPS01_SCD1: 'Potential SEEK/CD',
    YRK01_SCD1: 'Potential SEEK/CD',
    CSI01_PSA: 'Potential Student Athlete',
    BKL01_POAT: 'Power Of Attorney On File',
    HTR01_PHSC: 'Pre Health Sci',
    HTR01_PHON: 'Pre Honors',
    HTR01_PRNS: 'Pre Nursing',
    BAR01_ZKTP: 'Pre Zicklin Temporary Perm',
    KCC01_PRCL: 'Pre-Clinical Nursing',
    CSI01_PACD: 'Pre-College Academy',
    BKL01_PREF: 'Pre-Freshmen',
    LEH01_PREH: 'Pre-Health Professions',
    JJC01_BOOT: 'Pre-Law Boot Camp Participant',
    HTR01_PLAW: 'Pre-Law Program',
    CSI01_PRNS: 'Pre-Nursing Students',
    LEH01_PNUR: 'Pre-Nursing Students',
    CSI01_PRNT: 'Pre-Nursing Transcripts',
    HTR01_PHCD: 'Prehealth Careers Cert Dis',
    HTR01_PHCP: 'Prehealth Careers Cert Prob',
    BKL01_PREL: 'Prelude KbCC',
    LAG01_P02: 'Prelude to Baruch Program',
    LEH01_243P: 'Prelude to Success',
    SPS01_RACC: 'Prereq - Accounting (Missing)',
    SPS01_RCMP: 'Prereq - Computers (Missing)',
    SPS01_RECO: 'Prereq - Economics (Missing)',
    SPS01_RSTA: 'Prereq - Statistics (Missing)',
    LAG01_ARTS: 'President ARTS Society',
    KCC01_PP: 'President\'s Prep',
    HTR01_PLSH: 'Presidential Leadership',
    BMC01_BMPS: 'Presidential Scholar',
    BAR01_PRES: 'Presidential Scholars',
    BKL01_PRES: 'Presidential Scholars',
    CSI01_PSEK: 'Previous Seek',
    LEH01_E110: 'Prin Effective Writing 1',
    LEH01_E120: 'Prin Effective Writing 2',
    BCC01_PDEG: 'Prior Degree',
    BKL01_PDEG: 'Prior Degree',
    BMC01_PDEG: 'Prior Degree',
    CSI01_PDEG: 'Prior Degree',
    HOS01_PDEG: 'Prior Degree',
    HTR01_PDEG: 'Prior Degree',
    KCC01_PDEG: 'Prior Degree',
    LAG01_PDEG: 'Prior Degree',
    LEH01_PDEG: 'Prior Degree',
    NCC01_PDEG: 'Prior Degree',
    NYT01_PDEG: 'Prior Degree',
    QCC01_PDEG: 'Prior Degree',
    QNS01_PDEG: 'Prior Degree',
    YRK01_PDEG: 'Prior Degree',
    BAR01_PDAA: 'Prior Degree AA',
    BCC01_PDAA: 'Prior Degree AA',
    BKL01_PDAA: 'Prior Degree AA',
    BMC01_PDAA: 'Prior Degree AA',
    CSI01_PDAA: 'Prior Degree AA',
    GRD01_PDAA: 'Prior Degree AA',
    HOS01_PDAA: 'Prior Degree AA',
    HTR01_PDAA: 'Prior Degree AA',
    JJC01_PDAA: 'Prior Degree AA',
    KCC01_PDAA: 'Prior Degree AA',
    LAG01_PDAA: 'Prior Degree AA',
    LEH01_PDAA: 'Prior Degree AA',
    NCC01_PDAA: 'Prior Degree AA',
    NYT01_PDAA: 'Prior Degree AA',
    QCC01_PDAA: 'Prior Degree AA',
    QNS01_PDAA: 'Prior Degree AA',
    YRK01_PDAA: 'Prior Degree AA',
    BAR01_PDAX: 'Prior Degree AAS',
    BCC01_PDAX: 'Prior Degree AAS',
    BKL01_PDAX: 'Prior Degree AAS',
    BMC01_PDAX: 'Prior Degree AAS',
    CSI01_PDAX: 'Prior Degree AAS',
    GRD01_PDAX: 'Prior Degree AAS',
    HOS01_PDAX: 'Prior Degree AAS',
    HTR01_PDAX: 'Prior Degree AAS',
    JJC01_PDAX: 'Prior Degree AAS',
    KCC01_PDAX: 'Prior Degree AAS',
    LAG01_PDAX: 'Prior Degree AAS',
    LEH01_PDAX: 'Prior Degree AAS',
    NCC01_PDAX: 'Prior Degree AAS',
    NYT01_PDAX: 'Prior Degree AAS',
    QCC01_PDAX: 'Prior Degree AAS',
    QNS01_PDAX: 'Prior Degree AAS',
    YRK01_PDAX: 'Prior Degree AAS',
    BAR01_PDAS: 'Prior Degree AS',
    BCC01_PDAS: 'Prior Degree AS',
    BKL01_PDAS: 'Prior Degree AS',
    BMC01_PDAS: 'Prior Degree AS',
    CSI01_PDAS: 'Prior Degree AS',
    GRD01_PDAS: 'Prior Degree AS',
    HOS01_PDAS: 'Prior Degree AS',
    HTR01_PDAS: 'Prior Degree AS',
    KCC01_PDAS: 'Prior Degree AS',
    LAG01_PDAS: 'Prior Degree AS',
    LEH01_PDAS: 'Prior Degree AS',
    NCC01_PDAS: 'Prior Degree AS',
    NYT01_PDAS: 'Prior Degree AS',
    QCC01_PDAS: 'Prior Degree AS',
    QNS01_PDAS: 'Prior Degree AS',
    YRK01_PDAS: 'Prior Degree AS',
    JJC01_PDAS: 'Prior Degree As',
    BCC01_PDBA: 'Prior Degree BA',
    BKL01_PDBA: 'Prior Degree BA',
    BMC01_PDBA: 'Prior Degree BA',
    CSI01_PDBA: 'Prior Degree BA',
    GRD01_PDBA: 'Prior Degree BA',
    HOS01_PDBA: 'Prior Degree BA',
    HTR01_PDBA: 'Prior Degree BA',
    KCC01_PDBA: 'Prior Degree BA',
    LAG01_PDBA: 'Prior Degree BA',
    LEH01_PDBA: 'Prior Degree BA',
    NCC01_PDBA: 'Prior Degree BA',
    NYT01_PDBA: 'Prior Degree BA',
    QCC01_PDBA: 'Prior Degree BA',
    QNS01_PDBA: 'Prior Degree BA',
    YRK01_PDBA: 'Prior Degree BA',
    BCC01_PDBS: 'Prior Degree BS',
    BKL01_PDBS: 'Prior Degree BS',
    BMC01_PDBS: 'Prior Degree BS',
    CSI01_PDBS: 'Prior Degree BS',
    GRD01_PDBS: 'Prior Degree BS',
    HOS01_PDBS: 'Prior Degree BS',
    HTR01_PDBS: 'Prior Degree BS',
    KCC01_PDBS: 'Prior Degree BS',
    LAG01_PDBS: 'Prior Degree BS',
    LEH01_PDBS: 'Prior Degree BS',
    NCC01_PDBS: 'Prior Degree BS',
    NYT01_PDBS: 'Prior Degree BS',
    QCC01_PDBS: 'Prior Degree BS',
    QNS01_PDBS: 'Prior Degree BS',
    YRK01_PDBS: 'Prior Degree BS',
    BCC01_PDMA: 'Prior Degree MA',
    BKL01_PDMA: 'Prior Degree MA',
    BMC01_PDMA: 'Prior Degree MA',
    CSI01_PDMA: 'Prior Degree MA',
    HOS01_PDMA: 'Prior Degree MA',
    HTR01_PDMA: 'Prior Degree MA',
    KCC01_PDMA: 'Prior Degree MA',
    LAG01_PDMA: 'Prior Degree MA',
    LEH01_PDMA: 'Prior Degree MA',
    NCC01_PDMA: 'Prior Degree MA',
    NYT01_PDMA: 'Prior Degree MA',
    QCC01_PDMA: 'Prior Degree MA',
    QNS01_PDMA: 'Prior Degree MA',
    YRK01_PDMA: 'Prior Degree MA',
    BCC01_PDMS: 'Prior Degree MS',
    BKL01_PDMS: 'Prior Degree MS',
    BMC01_PDMS: 'Prior Degree MS',
    CSI01_PDMS: 'Prior Degree MS',
    HOS01_PDMS: 'Prior Degree MS',
    HTR01_PDMS: 'Prior Degree MS',
    KCC01_PDMS: 'Prior Degree MS',
    LAG01_PDMS: 'Prior Degree MS',
    LEH01_PDMS: 'Prior Degree MS',
    NCC01_PDMS: 'Prior Degree MS',
    NYT01_PDMS: 'Prior Degree MS',
    QCC01_PDMS: 'Prior Degree MS',
    QNS01_PDMS: 'Prior Degree MS',
    YRK01_PDMS: 'Prior Degree MS',
    BCC01_PDME: 'Prior Degree MS Ed',
    BKL01_PDME: 'Prior Degree MS Ed',
    BMC01_PDME: 'Prior Degree MS Ed',
    CSI01_PDME: 'Prior Degree MS Ed',
    HOS01_PDME: 'Prior Degree MS Ed',
    HTR01_PDME: 'Prior Degree MS Ed',
    KCC01_PDME: 'Prior Degree MS Ed',
    LAG01_PDME: 'Prior Degree MS Ed',
    LEH01_PDME: 'Prior Degree MS Ed',
    NCC01_PDME: 'Prior Degree MS Ed',
    NYT01_PDME: 'Prior Degree MS Ed',
    QCC01_PDME: 'Prior Degree MS Ed',
    QNS01_PDME: 'Prior Degree MS Ed',
    YRK01_PDME: 'Prior Degree MS Ed',
    QNS01_PDGD: 'Prior Graduate Degree',
    BAR01_NZK0: 'Prior to 1219 No Zicklin',
    BAR01_ZK00: 'Prior to Fall 2021 Admissions',
    BAR01_PREG: 'Priority Registration',
    BCC01_PREG: 'Priority Registration',
    BKL01_PREG: 'Priority Registration',
    BMC01_PREG: 'Priority Registration',
    CSI01_PREG: 'Priority Registration',
    CTY01_PREG: 'Priority Registration',
    GRD01_PREG: 'Priority Registration',
    HOS01_PREG: 'Priority Registration',
    HTR01_PREG: 'Priority Registration',
    JJC01_PREG: 'Priority Registration',
    JJC01_PRTY: 'Priority Registration',
    KCC01_PREG: 'Priority Registration',
    LAG01_PREG: 'Priority Registration',
    LAW01_PREG: 'Priority Registration',
    LEH01_PREG: 'Priority Registration',
    MEC01_PREG: 'Priority Registration',
    MED01_PREG: 'Priority Registration',
    NCC01_PREG: 'Priority Registration',
    NYT01_PREG: 'Priority Registration',
    QCC01_PREG: 'Priority Registration',
    QNS01_PREG: 'Priority Registration',
    SLU01_PREG: 'Priority Registration',
    SOJ01_PREG: 'Priority Registration',
    SPH01_PREG: 'Priority Registration',
    SPS01_PREG: 'Priority Registration',
    YRK01_PREG: 'Priority Registration',
    LEH01_PRFR: 'Priority Registration Freshman',
    LEH01_PRJR: 'Priority Registration Junior',
    LEH01_PRSR: 'Priority Registration Senior',
    LEH01_PRSO: 'Priority Registration Soph',
    HTR01_PRTY: 'Priority Student',
    QCC01_PRTY: 'Priority Student',
    LAW01_PBSP: 'Pro Bono Scholars Program',
    LAG01_PAR0: 'Proactive Adv & Reg',
    LAG01_PAR1: 'Proactively Reg',
    LAG01_PROB: 'Probation',
    HOS01_PR03: 'Probation - 3.0 Credit Limit',
    HOS01_PR06: 'Probation - 6.0 Credit Limit',
    HOS01_PR09: 'Probation - 9.0 Credit Limit',
    LEH01_PROB: 'Probation Contract',
    LEH01_PRRC: 'Probation Readmit Contract',
    BKL01_PROB: 'Probation Workshop',
    CTY01_EDPE: 'Professional Education Program',
    QNS01_PEXC: 'Project EXCEL Student',
    BAR01_PROV: 'Provost Scholar',
    CTY01_ADPR: 'Provost\'s Admissions Committee',
    GRD01_FPRV: 'Provost’s Enhancement Fellow',
    JJC01_GPSY: 'Psych Graduate Research Mthd',
    SPH01_PHNT: 'Public Health Nutrition',
    SPH01_PUHN: 'Public Health Nutrition',
    QNS01_QNS4: 'QC in 4',
    QNS01_QCNA: 'QCN4 Advance Standing',
    QNS01_RISE: 'Queens College Fund',
    QNS01_QS: 'Queens Scholar',
    NYT01_QRES: 'Questionable Residency',
    HTR01_RUSS: 'RUSS',
    LAG01_REAL: 'Re-Allocate',
    LAW01_REEN: 'Re-entry Student',
    LAW01_REST: 'Re-start Student',
    HTR01_ESLX: 'Reached ESL Limit',
    LAG01_RCTV: 'Reactivate',
    BKL01_REAC: 'Reactivates Previous Semester',
    QCC01_REX: 'Reading Exempt',
    QNS01_REX: 'Reading Exempt',
    CSI01_RADM: 'Readmit',
    LAG01_RADM: 'Readmit',
    KCC01_RADF: 'Readmit - Forgiveness',
    CSI01_RADG: 'Readmit Graduate Student',
    HOS01_RADM: 'Readmit Student',
    KCC01_RDMT: 'Readmit Student',
    HTR01_RADM: 'Readmitted Student',
    NYT01_RADM: 'Readmitted Student',
    JJC01_RADR: 'Readmitted by Registrar',
    QCC01_RAPB: 'Readmitted on Probation',
    CTY01_RWMA: 'Real World Math Program',
    BCC01_REAL: 'Reallocate',
    JJC01_REAL: 'Reallocated Admission',
    NYT01_REAL: 'Reallocated New Student',
    BKL01_REAL: 'Reallocates From Other CUNY',
    LEH01_XREC: 'Reciprocal for Exchange Prog',
    LEH01_RECD: 'Record Created on Database',
    QNS01_TERY: 'Recycle groups for batch updat',
    BCC01_REFL: 'Referral Letter',
    BKL01_RFND: 'Refund Balance',
    GRD01_RCMN: 'Registrar - Commencement',
    QNS01_GENR: 'Registrar Office Processes',
    BAR01_HESN: 'Registrar Review Denied',
    LAG01_PAR3: 'Registration Cancelled',
    QCC01_PAR3: 'Registration Cancelled',
    LAG01_R100: 'Registration1 Grp 30 Plus Cr',
    LAG01_R2LW: 'Registration2 Grp L30 0-24 Cr',
    LAG01_R2HG: 'Registration2 Grp L30 24-29 Cr',
    LAG01_R3ES: 'Registration3 Group for BS ES',
    LAG01_R3BS: 'Registration3 Group for BS RE',
    LAG01_R4ND: 'Registration4 Non-Degrees',
    BAR01_ZK03: 'Regular Admission to Zicklin',
    LEH01_REHS: 'Rehabilitation Services',
    JJC01_EXFD: 'Reject Failed 3 or 4 Exams',
    YRK01_YCRA: 'Remedial Fail & Academic Dism',
    YRK01_REAB: 'Remedial Fail - Above 2.0',
    YRK01_REBE: 'Remedial Fail - Below 2.0',
    LAG01_REM: 'Remedial Group',
    BMC01_MATX: 'Remedial Math Exempt',
    BCC01_REME: 'Remedial Workshop',
    BMC01_REMX: 'Remediation in Excess of 24hrs',
    BAR01_RUBS: 'Renmin University Bus Schl',
    BAR01_RPFM: 'Repeat For Mastery',
    LAG01_MST1: 'Req MS Students MAT95/96',
    LAG01_CST1: 'Required CS students CSE95/3+',
    HOS01_RA: 'Res Doc-Located in Adm Folder',
    BCC01_RA: 'Res doc-Located in Adm Folder',
    QCC01_RA: 'Res doc-Located in Adm Folder',
    BCC01_REAP: 'Research Enrichment Act Prog',
    BAR01_RESH: 'Residence Hall',
    HTR01_RESH: 'Residence Hall',
    QNS01_BRH: 'Residence Hall',
    HTR01_RESL: 'Residence Life',
    JJC01_RESL: 'Residence Life',
    SLU01_RLAP: 'Resident Leadership Acad Prog',
    KCC01_RA: 'Returning Adults',
    KCC01_RHEP: 'Riggio Higher Education Prog',
    BMC01_RICS: 'Rikers Island to CUNY Student',
    BKL01_RISE: 'Rise Program',
    LAG01_RTS: 'Road to Success Student',
    HTR01_RNSP: 'Robert Noyce Scholars',
    LAG01_RWIS: 'Robert Wagner Institute Stdnt',
    LAG01_RHFG: 'Robin Hood Foundation Grant',
    BCC01_RHHM: 'Robin Hood at Home in College',
    MEC01_RHHM: 'Robin Hood at Home in College',
    CTY01_ROCK: 'Rockland Teacher\'s Center',
    HTR01_RSSH: 'Roosevelt Scholars',
    HTR01_RISE: 'Rsrch Initiative for Sci Enhn',
    JJC01_RUS1: 'Russian Placement 101',
    JJC01_RUS2: 'Russian Placement 102',
    HTR01_SWSH: 'S&W Scholar',
    HTR01_RSEW: 'SCI Resource For Women - STEM',
    BAR01_SEKA: 'SEEK Applicants',
    BKL01_SEKA: 'SEEK Applicants',
    CTY01_SEKA: 'SEEK Applicants',
    JJC01_SEKA: 'SEEK Applicants',
    KCC01_3B: 'SEEK Applicants',
    MEC01_SEKA: 'SEEK Applicants',
    NYT01_SEKA: 'SEEK Applicants',
    JJC01_SEKD: 'SEEK Denied admission',
    MEC01_SEKC: 'SEEK Funds Cancelled',
    NYT01_SEKC: 'SEEK Funds Cancelled',
    NYT01_SEKU: 'SEEK Funds Used Up',
    BKL01_SEKG: 'SEEK Graduated',
    LEH01_SEKH: 'SEEK History',
    JJC01_XAEP: 'SEEK Honors Society',
    NYT01_SEKI: 'SEEK Incoming UAPC',
    BAR01_SEKN: 'SEEK Ineligible for SEEK FA',
    JJC01_SEKN: 'SEEK Non Compliant',
    BKL01_SEKN: 'SEEK Non-Compliant',
    BKL01_SEKO: 'SEEK Opt Out',
    CSI01_SEKR: 'SEEK Rejected',
    JJC01_SEKR: 'SEEK Rejected',
    BAR01_SEKV: 'SEEK Review SEEK Aid',
    CTY01_SEKS: 'SEEK Scholar Appointment',
    BAR01_SEEK: 'SEEK Student',
    BKL01_SEEK: 'SEEK Student',
    CSI01_SEEK: 'SEEK Student',
    CTY01_SEEK: 'SEEK Student',
    HTR01_SEEK: 'SEEK Student',
    JJC01_SEEK: 'SEEK Student',
    LEH01_SEEK: 'SEEK Student',
    MEC01_SEEK: 'SEEK Student',
    NYT01_SEEK: 'SEEK Student',
    QCC01_SEEK: 'SEEK Student',
    QNS01_SEEK: 'SEEK Student',
    SPS01_SEEK: 'SEEK Student',
    YRK01_SEEK: 'SEEK Student',
    JJC01_SKSA: 'SEEK Summer Appoint',
    JJC01_SKSE: 'SEEK Summer Enrolled',
    JJC01_SKSU: 'SEEK Summer Program',
    JJC01_SEKT: 'SEEK Test Results',
    KCC01_2E: 'SEP Not Signed',
    BKL01_SOE: 'SOE Special Cert',
    JJC01_SPN3: 'SPA 201 or Above',
    BMC01_SPLI: 'SPLI -  Spanish Literature',
    HTR01_SSWR: 'SSW Scholarship Recipient',
    CSI01_SSRS: 'STEAM Schol or Res Stipend',
    JJC01_STEM: 'STEM',
    NYT01_SINT: 'STEM Intent',
    LEH01_SYIS: 'SYI Student',
    HTR01_SAGE: 'Sage Honors Scholars',
    BMC01_SST: 'Saturday Success Track',
    QCC01_PAR0: 'ScheduleBuilder Recommended',
    QCC01_PAR1: 'ScheduleBuilder Registered',
    BKL01_SCPS: 'Schl Psych Pre-200709 Cohort',
    HTR01_SCHP: 'Scholar Program',
    BKL01_SCHL: 'Scholars Program',
    NCC01_CRSP: 'Scholars of GCC CRISP Program',
    NCC01_GLSS: 'Scholars of GCC GLASS',
    NCC01_LSMP: 'Scholars of GCC LSAMP Program',
    HTR01_SWFS: 'Scholarship & Welfare Fund Sch',
    SLU01_DIVS: 'Scholarship for Diversity',
    CTY01_EGRU: 'School of ENGR UGRD student',
    CTY01_MITC: 'School of Ed Math City Program',
    CTY01_EDUS: 'School of Ed UGRD student',
    CTY01_SCDU: 'Science Division UGRD student',
    GRD01_FSCI: 'Science Fellowship',
    CSI01_SMTF: 'Science Master Teaching Fellow',
    BKL01_SURG: 'Science UGRD Research Gateway',
    CTY01_BSU2: 'Sec Yr Sophie Davies Student',
    BAR01_SCPP: 'Second Chance Pell Program',
    BCC01_SCPP: 'Second Chance Pell Program',
    BKL01_SCPP: 'Second Chance Pell Program',
    BMC01_SCPP: 'Second Chance Pell Program',
    CSI01_SCPP: 'Second Chance Pell Program',
    CTY01_SCPP: 'Second Chance Pell Program',
    HOS01_SCPP: 'Second Chance Pell Program',
    HTR01_SCPP: 'Second Chance Pell Program',
    JJC01_SCPP: 'Second Chance Pell Program',
    KCC01_SCPP: 'Second Chance Pell Program',
    LAG01_SCPP: 'Second Chance Pell Program',
    LAW01_SCPP: 'Second Chance Pell Program',
    LEH01_SCPP: 'Second Chance Pell Program',
    MEC01_SCPP: 'Second Chance Pell Program',
    MED01_SCPP: 'Second Chance Pell Program',
    NCC01_SCPP: 'Second Chance Pell Program',
    NYT01_SCPP: 'Second Chance Pell Program',
    QCC01_SCPP: 'Second Chance Pell Program',
    QNS01_SCPP: 'Second Chance Pell Program',
    SLU01_SCPP: 'Second Chance Pell Program',
    SPH01_SCPP: 'Second Chance Pell Program',
    SPS01_SCPP: 'Second Chance Pell Program',
    YRK01_SCPP: 'Second Chance Pell Program',
    BAR01_SDEG: 'Second Deg Student - No ACC',
    BAR01_SDGO: 'Second Degree Student - Old',
    BKL01_2SEM: 'Second Semester',
    LEH01_SSFC: 'Second Semester Freshman Comm',
    LAW01_2L: 'Second Year Law',
    MED01_MDM2: 'Second Year Medical Student',
    BKL01_2SKP: 'Second term SEEK probation',
    LEH01_SECQ: 'Secondary Sequence',
    HTR01_SKHN: 'Seek Honors',
    CSI01_SNSO: 'Selected for NSO Orientation',
    BKL01_SSPS: 'Selective Serv Prob-Not Signed',
    BKL01_SSVP: 'Selective Service Problem',
    KCC01_2H: 'Selective Service Problem',
    NYT01_SRL: 'Self Regulated Learning',
    BAR01_SCIG: 'Senior Citizen Student',
    BAR01_SCIT: 'Senior Citizen Student',
    BCC01_SCIT: 'Senior Citizen Student',
    BKL01_SCIG: 'Senior Citizen Student',
    BKL01_SCIT: 'Senior Citizen Student',
    BMC01_SCIT: 'Senior Citizen Student',
    CSI01_SCIG: 'Senior Citizen Student',
    CSI01_SCIT: 'Senior Citizen Student',
    CTY01_SCIG: 'Senior Citizen Student',
    CTY01_SCIT: 'Senior Citizen Student',
    GRD01_SCIT: 'Senior Citizen Student',
    HOS01_SCIG: 'Senior Citizen Student',
    HOS01_SCIT: 'Senior Citizen Student',
    HTR01_SCIG: 'Senior Citizen Student',
    HTR01_SCIT: 'Senior Citizen Student',
    JJC01_SCIG: 'Senior Citizen Student',
    JJC01_SCIT: 'Senior Citizen Student',
    KCC01_SCIG: 'Senior Citizen Student',
    KCC01_SCIT: 'Senior Citizen Student',
    LAG01_SCIG: 'Senior Citizen Student',
    LAG01_SCIT: 'Senior Citizen Student',
    LAW01_SCIT: 'Senior Citizen Student',
    LEH01_SCIT: 'Senior Citizen Student',
    MEC01_SCIG: 'Senior Citizen Student',
    MEC01_SCIT: 'Senior Citizen Student',
    NCC01_SCIT: 'Senior Citizen Student',
    NYT01_SCIT: 'Senior Citizen Student',
    QCC01_SCIG: 'Senior Citizen Student',
    QCC01_SCIT: 'Senior Citizen Student',
    QNS01_SCIT: 'Senior Citizen Student',
    SLU01_SCIT: 'Senior Citizen Student',
    SOJ01_SCIT: 'Senior Citizen Student',
    SPS01_SCIT: 'Senior Citizen Student',
    YRK01_SCIG: 'Senior Citizen Student',
    YRK01_SCIT: 'Senior Citizen Student',
    QNS01_SNR: 'Senior Citizens - Not Used',
    QCC01_SNR: 'Senior Citizens - Not used',
    BMC01_COP1: 'Serviced COPE Student',
    BAR01_SISU: 'Shanghai Intl Studies Univ Bus',
    BKL01_SIGX: 'Sigma Xi',
    QNS01_SLCX: 'Silcox Scholarship',
    QCC01_SFL3: 'Sillcox Foundation ASAP',
    QCC01_SFL1: 'Sillcox Foundation CLIP',
    QCC01_SFL2: 'Sillcox Foundation CUNYStart',
    KCC01_SPST: 'Single Parent Students',
    HTR01_SBLM: 'Solomon Bluhm Scholars Program',
    BKL01_SOPH: 'SophoMORE Academy Participant',
    BMC01_S102: 'Spanish 102',
    BMC01_S103: 'Spanish 103',
    BMC01_S105: 'Spanish 105',
    BMC01_S106: 'Spanish 106',
    BMC01_S107: 'Spanish 107',
    BMC01_S108: 'Spanish 108',
    BMC01_S121: 'Spanish 121',
    BMC01_S200: 'Spanish 200',
    BMC01_S204: 'Spanish 204',
    BMC01_S207: 'Spanish 207',
    BMC01_S210: 'Spanish 210',
    BMC01_S211: 'Spanish 211',
    BMC01_S300: 'Spanish 300',
    LAG01_S105: 'Spanish Heritage 1',
    LAG01_S106: 'Spanish Heritage 2',
    HOS01_SPA1: 'Spanish Level 1',
    HOS01_SPA2: 'Spanish Level 2',
    HOS01_SPA3: 'Spanish Level 3',
    HOS01_SPA4: 'Spanish Level 4',
    HOS01_SPA5: 'Spanish Level 5',
    HOS01_SPA6: 'Spanish Level 6',
    HOS01_SPA7: 'Spanish Level 7',
    JJC01_SPN1: 'Spanish Placement 101',
    JJC01_SPN2: 'Spanish Placement 102',
    JJC01_SP11: 'Spanish Placement 111',
    JJC01_SP12: 'Spanish Placement 112',
    BKL01_SEAL: 'Spec Educ Alternate',
    QCC01_SPCH: 'Spech Placement Required',
    BKL01_SPBA: 'Special Baccalaureate',
    BKL01_SPED: 'Special Educ',
    CTY01_SPED: 'Special Education Teachers',
    HTR01_SHON: 'Special Honors',
    BKL01_SPND: 'Special NonDegree Grad Prgm',
    BKL01_NONS: 'Special NonDegree Grad Program',
    QNS01_SPCR: 'Special Registration',
    BAR01_SPST: 'Special Transfer',
    BAR01_SPEC: 'Special Transfer Student',
    LEH01_SPEC: 'Specialized Services',
    BAR01_CO04: 'Sr Coll Trsf - 4 College Opt',
    BKL01_PACE: 'St. Albans Veterans (Pace)',
    CSI01_STGR: 'St. George',
    BKL01_WVA: 'Sta-Waiver-Cd=A',
    BKL01_WVP: 'Sta-Waiver-Cd=P',
    BKL01_WVS: 'Sta-Waiver-Cd=S',
    BKL01_WVW: 'Sta-Waiver-Cd=W',
    BKL01_WVB: 'Sta-Wavier-Cd=B',
    BKL01_HSS: 'Star Program',
    KCC01_STRF: 'StarFish Student Population',
    CTY01_SSSP: 'Stdnt Support Services Program',
    QCC01_S4SP: 'Strive for Success',
    HTR01_SPH7: 'Stud W/3.8 Or Above GPA',
    HTR01_SPH1: 'Stud W/An Official LOA',
    HTR01_SPH2: 'Stud W/Inc Grd - Sem Info',
    KCC01_STAM: 'Student Ambassadors',
    KCC01_A1: 'Student Athlete - Fall 07',
    KCC01_A3: 'Student Athlete - Fall 08',
    KCC01_A0: 'Student Athlete - Spring 07',
    KCC01_A2: 'Student Athlete - Spring 08',
    KCC01_ATSI: 'Student Athlete Initiative',
    MEC01_SCC: 'Student Concern Committee',
    BAR01_243C: 'Student DS TAP',
    BCC01_243C: 'Student DS TAP',
    BMC01_243C: 'Student DS TAP',
    CSI01_243C: 'Student DS TAP',
    CTY01_243C: 'Student DS TAP',
    HOS01_243C: 'Student DS TAP',
    HTR01_243C: 'Student DS TAP',
    JJC01_243C: 'Student DS TAP',
    KCC01_243C: 'Student DS TAP',
    LAG01_243C: 'Student DS TAP',
    LEH01_243C: 'Student DS TAP',
    MEC01_243C: 'Student DS TAP',
    NCC01_243C: 'Student DS TAP',
    QCC01_243C: 'Student DS TAP',
    QNS01_243C: 'Student DS TAP',
    SLU01_243C: 'Student DS TAP',
    SPS01_243C: 'Student DS TAP',
    BKL01_243C: '"Student DS TAP    "',
    NYT01_243C: '"Student DS TAP    "',
    YRK01_243C: '"Student DS TAP    "',
    JJC01_STZZ: 'Student Declines Admission',
    BAR01_SGOV: 'Student Government',
    CSI01_SGOV: 'Student Government',
    HTR01_SGOV: 'Student Government',
    BCC01_SGM: 'Student Government Member',
    KCC01_STGV: 'Student Government Members',
    QNS01_SLSP: 'Student Leadership Group',
    BKL01_LIFE: 'Student Life',
    MEC01_STLF: 'Student Life',
    NYT01_STMP: 'Student Receiving Food Stamps',
    BAR01_STSU: 'Student Success',
    HOS01_SSCU: 'Student Success Coaching Unit',
    BMC01_SS4U: 'Student Success For You',
    BKL01_CTLP: 'Student Success Initiative',
    YRK01_STSS: 'Student Support Servc Program',
    CSI01_STMD: 'Student Teaching Med on File',
    HTR01_STCH: 'Student Teaching or Practicum',
    CSI01_M123: 'Student can take MTH 123',
    LAG01_ADNL: 'Students Admitted notAttending',
    BCC01_S4SP: 'Students for Success Program',
    BMC01_S4SP: 'Students for Success Program',
    HOS01_S4SP: 'Students for Success Program',
    LAG01_S4SP: 'Students for Success Program',
    NYT01_S4SP: 'Students for Success Program',
    BAR01_SOP: 'Students of Promise Program',
    BCC01_SOP: 'Students of Promise Program',
    BKL01_SOP: 'Students of Promise Program',
    BMC01_SOP: 'Students of Promise Program',
    CSI01_SOP: 'Students of Promise Program',
    CTY01_SOP: 'Students of Promise Program',
    HOS01_SOP: 'Students of Promise Program',
    HTR01_SOP: 'Students of Promise Program',
    JJC01_SOP: 'Students of Promise Program',
    KCC01_SOP: 'Students of Promise Program',
    LAG01_SOP: 'Students of Promise Program',
    LAW01_SOP: 'Students of Promise Program',
    LEH01_SOP: 'Students of Promise Program',
    MEC01_SOP: 'Students of Promise Program',
    NCC01_SOP: 'Students of Promise Program',
    NYT01_SOP: 'Students of Promise Program',
    QCC01_SOP: 'Students of Promise Program',
    QNS01_SOP: 'Students of Promise Program',
    YRK01_SOP: 'Students of Promise Program',
    HTR01_SOS: 'Students of Substance',
    KCC01_ESLP: 'Students placed in ESL',
    CSI01_QMTH: 'Students that are Math Exempt',
    BKL01_STBD: 'Study Abroad',
    CSI01_STBD: 'Study Abroad',
    CTY01_STBD: 'Study Abroad',
    HTR01_STBF: 'Study Abroad Fall',
    HTR01_STBS: 'Study Abroad Summer',
    CTY01_STGP: 'Study Group',
    BAR01_HESR: 'Subject to Registrar Review',
    LEH01_SBEP: 'Subsidized Bil Ext Program',
    BKL01_SBEP: 'Subsidized Bilingual Extn Prog',
    BAR01_SNET: 'Success Network',
    HTR01_SACD: 'Sucess Academy',
    JJC01_SAGR: 'Summer Academy Graduate',
    HTR01_SMED: 'Summer Edge',
    HTR01_SUME: 'Summer\'s Edge',
    QNS01_SPPE: 'Supp Cert for Phys Ed',
    LAG01_SUSP: 'Suspension',
    BAR01_SWUF: 'Swufe Student',
    CSI01_TPEL: 'TAP Eligible Term',
    BKL01_TAPP: 'TAP Progress & Pursuit Waiver',
    BKL01_TAPW: 'TAP Progress Waiver',
    BKL01_TPW: 'TAP Progress Waiver',
    CSI01_TAPR: 'TAP Removed - 12 Cred Lim',
    QCC01_TCHG: 'TEACH Grant - GPA Req. Met',
    QNS01_TCHG: 'TEACH Grant - GPA Req. Met',
    QCC01_TCHA: 'TEACH Grant - Met Adm Standard',
    QNS01_TCHA: 'TEACH Grant - Met Adm Standard',
    QCC01_TCHR: 'TEACH Grant - Ret/Curr Teacher',
    QNS01_TCHR: 'TEACH Grant - Ret/Curr Teacher',
    LEH01_TELL: 'TELL Student',
    LEH01_ALPH: 'TESOL Alpha Project',
    QNS01_T2K: 'TIME 2000 PGM',
    NYT01_TLQP: 'TLQP Education Program',
    LEH01_TOC: 'TOC Program',
    BMC01_TOPS: 'TOPS Program',
    BCC01_TRNU: 'TR Stdnts Interested in NURS',
    LAG01_TRNU: 'TR Stdnts Interested in NURS',
    QCC01_TRNU: 'TR Stdnts Interested in NURS',
    LEH01_TCIT: 'TRIO Careers in Teaching',
    LEH01_TPTS: 'TRIO Pathways to Success',
    HTR01_TAS1: 'TRN Achievement Scholar Tier 1',
    HTR01_TAS2: 'TRN Achievement Scholar Tier 2',
    HTR01_TAS3: 'TRN Achievement Scholar Tier 3',
    HTR01_TAS4: 'TRN Achievement Scholar Tier 4',
    LAG01_TSAE: 'TSA Employee',
    LEH01_TSLD: 'TSSLD Certification',
    CSI01_MW35: 'Take MTH 123 only w/MTH 35',
    CTY01_TCHX: 'Tch Grant Qualfd via 3.25 GPA',
    CTY01_TCHQ: 'Tch Grant Qulfd via natnl test',
    BKL01_T325: 'Teach (3.25 GPA)',
    BKL01_75HG: 'Teach (75% or Higher)',
    CSI01_THAA: 'Teach Honors Acad Adm at CSI',
    HTR01_TYOU: 'Teach You',
    BKL01_TEAM: 'Teach for America',
    LEH01_AMER: 'Teach for America',
    HTR01_TASE: 'Teach for America Special Ed',
    LEH01_TCHR: 'Teach grant Program',
    CSI01_TOSD: 'Teach on Sabbatical/Discovery',
    BCC01_TEAC: 'Teacher Academy',
    BKL01_TEAC: 'Teacher Academy',
    BMC01_TEAC: 'Teacher Academy',
    CSI01_TEAC: 'Teacher Academy',
    CTY01_TEAC: 'Teacher Academy',
    HOS01_TEAC: 'Teacher Academy',
    HTR01_TEAC: 'Teacher Academy',
    KCC01_TEAC: 'Teacher Academy',
    LAG01_TEAC: 'Teacher Academy',
    LEH01_TEAC: 'Teacher Academy',
    NCC01_TEAC: 'Teacher Academy',
    NYT01_TEAC: 'Teacher Academy',
    QCC01_TEAC: 'Teacher Academy',
    QNS01_TEAC: 'Teacher Academy',
    YRK01_TEAC: 'Teacher Academy',
    LEH01_SCIP: 'Teacher Ed Adv Sci Prep',
    LEH01_TCOC: 'Teacher Opportunity Corps',
    LAG01_TEOP: 'Teacher Opportunity Program',
    LEH01_TEOP: 'Teacher Opportunity Program',
    QNS01_TEOP: 'Teacher Opportunity Program',
    LAG01_TSND: 'Teacher on Sabbatical Non Degr',
    KCC01_TSAO: 'Teachers off Sabbatical',
    BCC01_TSAB: 'Teachers on Sabbatical',
    BMC01_TSAB: 'Teachers on Sabbatical',
    HOS01_TSAB: 'Teachers on Sabbatical',
    KCC01_TSAB: 'Teachers on Sabbatical',
    LAG01_TSAB: 'Teachers on Sabbatical',
    LEH01_TSAB: 'Teachers on Sabbatical',
    NCC01_TSAB: 'Teachers on Sabbatical',
    QCC01_TSAB: 'Teachers on Sabbatical',
    QNS01_TSAB: 'Teachers on Sabbatical',
    CTY01_TEOP: 'Teaching Opportunity Program',
    HTR01_TEOP: 'Teaching Opportunity Program',
    BCC01_TECH: 'Technology Prep Program',
    JJC01_THSP: 'Tematic Studies Program',
    CSI01_TMMR: 'Temporary Medical Restirction',
    MEC01_TRIO: 'The Federal TRIO Programs',
    LEH01_GPSP: 'The GPS Program',
    BKL01_TRPP: 'Third Party Payment',
    LAW01_3L: 'Third Year Law',
    MED01_MDM3: 'Third Year Medical Student',
    CTY01_BSU3: 'Third Yr Sophie Davies Student',
    LAG01_T105: 'Tibetan Heritage',
    BAR01_24TA: 'Title IV Academic Standing',
    BCC01_24TA: 'Title IV Academic Standing',
    BKL01_24TA: 'Title IV Academic Standing',
    BMC01_24TA: 'Title IV Academic Standing',
    CSI01_24TA: 'Title IV Academic Standing',
    CTY01_24TA: 'Title IV Academic Standing',
    HOS01_24TA: 'Title IV Academic Standing',
    HTR01_24TA: 'Title IV Academic Standing',
    JJC01_24TA: 'Title IV Academic Standing',
    KCC01_24TA: 'Title IV Academic Standing',
    LAG01_24TA: 'Title IV Academic Standing',
    LEH01_24TA: 'Title IV Academic Standing',
    MEC01_24TA: 'Title IV Academic Standing',
    NYT01_24TA: 'Title IV Academic Standing',
    QCC01_24TA: 'Title IV Academic Standing',
    QNS01_24TA: 'Title IV Academic Standing',
    SLU01_24TA: 'Title IV Academic Standing',
    SPS01_24TA: 'Title IV Academic Standing',
    YRK01_24TA: 'Title IV Academic Standing',
    BAR01_24T4: 'Title IV Decertified',
    BCC01_24T4: 'Title IV Decertified',
    BKL01_24T4: 'Title IV Decertified',
    BMC01_24T4: 'Title IV Decertified',
    CSI01_24T4: 'Title IV Decertified',
    CTY01_24T4: 'Title IV Decertified',
    HOS01_24T4: 'Title IV Decertified',
    HTR01_24T4: 'Title IV Decertified',
    JJC01_24T4: 'Title IV Decertified',
    LAG01_24T4: 'Title IV Decertified',
    LEH01_24T4: 'Title IV Decertified',
    MEC01_24T4: 'Title IV Decertified',
    NYT01_24T4: 'Title IV Decertified',
    QCC01_24T4: 'Title IV Decertified',
    QNS01_24T4: 'Title IV Decertified',
    SLU01_24T4: 'Title IV Decertified',
    SPS01_24T4: 'Title IV Decertified',
    YRK01_24T4: 'Title IV Decertified',
    BKL01_T4OP: 'Title IV Overpayment',
    KCC01_2C: 'Title IV Overpayment',
    LEH01_TIVO: 'Title IV Overpayment',
    CSI01_T4UP: 'Title IV Unsat Program Pursuit',
    BAR01_24TB: 'Title IV Unsatisf Prog Pursuit',
    BCC01_24TB: 'Title IV Unsatisf Prog Pursuit',
    BKL01_24TB: 'Title IV Unsatisf Prog Pursuit',
    BMC01_24TB: 'Title IV Unsatisf Prog Pursuit',
    CSI01_24TB: 'Title IV Unsatisf Prog Pursuit',
    CTY01_24TB: 'Title IV Unsatisf Prog Pursuit',
    HOS01_24TB: 'Title IV Unsatisf Prog Pursuit',
    HTR01_24TB: 'Title IV Unsatisf Prog Pursuit',
    JJC01_24TB: 'Title IV Unsatisf Prog Pursuit',
    KCC01_24TB: 'Title IV Unsatisf Prog Pursuit',
    LAG01_24TB: 'Title IV Unsatisf Prog Pursuit',
    LAW01_24TB: 'Title IV Unsatisf Prog Pursuit',
    LEH01_24TB: 'Title IV Unsatisf Prog Pursuit',
    MEC01_24TB: 'Title IV Unsatisf Prog Pursuit',
    NYT01_24TB: 'Title IV Unsatisf Prog Pursuit',
    QCC01_24TB: 'Title IV Unsatisf Prog Pursuit',
    QNS01_24TB: 'Title IV Unsatisf Prog Pursuit',
    SLU01_24TB: 'Title IV Unsatisf Prog Pursuit',
    SOJ01_24TB: 'Title IV Unsatisf Prog Pursuit',
    SPS01_24TB: 'Title IV Unsatisf Prog Pursuit',
    YRK01_24TB: 'Title IV Unsatisf Prog Pursuit',
    YRK01_T4UP: 'Title Iv Unsat Program Pursuit',
    LEH01_TILV: 'Title V',
    NYT01_TL5: 'Title V Participant',
    LEH01_STEL: 'Title VI - Stellar',
    LEH01_TITL: 'Title VII - Intell',
    KCC01_24T4: 'Title lV Decertified',
    NYT01_TMTP: 'Too Many TAP Points',
    CTY01_DORM: 'Towers Residents',
    MED01_DORM: 'Towers Residents',
    QNS01_TWHS: 'Townsend Harris HS Senior',
    LEH01_280T: 'Transf Studs Advised in SH 280',
    LAG01_TPAR: 'Transfer - Attended CUNY Only',
    BKL01_TSBA: 'Transfer 2 BA',
    HTR01_TASA: 'Transfer Achieve Schlrsh Audit',
    HTR01_ACHV: 'Transfer Achievement Scholarsh',
    BCC01_TRPB: 'Transfer Admitted on Probation',
    LAG01_TRPB: 'Transfer Admitted on Probation',
    QCC01_TRPB: 'Transfer Admitted on Probation',
    YRK01_TRCM: 'Transfer Commitment',
    BAR01_24GV: 'Transfer Courses 3.0 GPA & up',
    BCC01_24GV: 'Transfer Courses 3.0 GPA & up',
    BKL01_24GV: 'Transfer Courses 3.0 GPA & up',
    BMC01_24GV: 'Transfer Courses 3.0 GPA & up',
    CSI01_24GV: 'Transfer Courses 3.0 GPA & up',
    CTY01_24GV: 'Transfer Courses 3.0 GPA & up',
    HOS01_24GV: 'Transfer Courses 3.0 GPA & up',
    HTR01_24GV: 'Transfer Courses 3.0 GPA & up',
    JJC01_24GV: 'Transfer Courses 3.0 GPA & up',
    KCC01_24GV: 'Transfer Courses 3.0 GPA & up',
    LAG01_24GV: 'Transfer Courses 3.0 GPA & up',
    LEH01_24GV: 'Transfer Courses 3.0 GPA & up',
    MEC01_24GV: 'Transfer Courses 3.0 GPA & up',
    NCC01_24GV: 'Transfer Courses 3.0 GPA & up',
    NYT01_24GV: 'Transfer Courses 3.0 GPA & up',
    QCC01_24GV: 'Transfer Courses 3.0 GPA & up',
    QNS01_24GV: 'Transfer Courses 3.0 GPA & up',
    SPS01_24GV: 'Transfer Courses 3.0 GPA & up',
    YRK01_24GV: 'Transfer Courses 3.0 GPA & up',
    LAG01_TRCI: 'Transfer Credit Ineligible',
    BAR01_TRDA: 'Transfer Direct Admit',
    LAG01_TREC: 'Transfer Evaluation Complete',
    JJC01_TRCM: 'Transfer Evaluation Completed',
    BMC01_HS48: 'Transfer HS Diploma or 48 Cr.',
    LAG01_TPRE: 'Transfer Pre-acceptance',
    QNS01_TSP: 'Transfer Scholars Program',
    BCC01_NOTC: 'Transfer Stdnts WO Transfer Cr',
    QCC01_NOTC: 'Transfer Stdnts WO Transfer Cr',
    SLU01_TRAN: 'Transit Worker',
    SPS01_TRAN: 'Transit Worker',
    NYT01_TWRK: 'Transit Workers',
    MEC01_TACS: 'Transition Academy Student',
    CSI01_TRAN: 'Transition to Teaching',
    HOS01_TENG: 'Transitional English',
    BMC01_T231: 'Translation 231',
    BMC01_T232: 'Translation 232',
    BMC01_T233: 'Translation 233',
    KCC01_DH: 'Travel/Hospitality Link Sp07',
    KCC01_ACTN: 'Trio Non Accessibility',
    KCC01_SN: 'Trio Non Accessibility',
    KCC01_V1: 'Triple Exempt ASAP Student',
    GRD01_FTUT: 'Tuition Fellowship',
    HTR01_AGNT: 'Tuition Pay - Third Party',
    BAR01_TUTO: 'Tutorial No Business Courses',
    BKL01_UFTP: 'UFT NYC Private School Tchrs',
    BKL01_UTPS: 'UFT NYC Public School Teachers',
    BKL01_UGAP: 'UG Reallocate In On Appeal',
    CSI01_U150: 'UGRD Cred Attm > 150% of Pgm',
    BAR01_UNE: 'UN or Embassy Staff/Dependent',
    BCC01_UNE: 'UN or Embassy Staff/Dependent',
    BKL01_UNE: 'UN or Embassy Staff/Dependent',
    BMC01_UNE: 'UN or Embassy Staff/Dependent',
    CSI01_UNE: 'UN or Embassy Staff/Dependent',
    CTY01_UNE: 'UN or Embassy Staff/Dependent',
    GRD01_UNE: 'UN or Embassy Staff/Dependent',
    HOS01_UNE: 'UN or Embassy Staff/Dependent',
    HTR01_UNE: 'UN or Embassy Staff/Dependent',
    JJC01_UNE: 'UN or Embassy Staff/Dependent',
    KCC01_UNE: 'UN or Embassy Staff/Dependent',
    LAG01_UNE: 'UN or Embassy Staff/Dependent',
    LAW01_UNE: 'UN or Embassy Staff/Dependent',
    LEH01_UNE: 'UN or Embassy Staff/Dependent',
    MEC01_UNE: 'UN or Embassy Staff/Dependent',
    NCC01_UNE: 'UN or Embassy Staff/Dependent',
    NYT01_UNE: 'UN or Embassy Staff/Dependent',
    QCC01_UNE: 'UN or Embassy Staff/Dependent',
    QNS01_UNE: 'UN or Embassy Staff/Dependent',
    SOJ01_UNE: 'UN or Embassy Staff/Dependent',
    YRK01_UNE: 'UN or Embassy Staff/Dependent',
    QNS01_GRPB: 'URBST, SOC, PSYCH, BIOL FrSo',
    QNS01_GRPA: 'URBST, SOC, PSYCH, BIOL JrSr',
    BCC01_USIP: 'USIP Program',
    CSI01_USIP: 'USIP Program',
    LAG01_USIP: 'USIP Program',
    YRK01_USIP: 'USIP Program',
    LAG01_UNAL: 'Un-Allocated',
    BKL01_UNAL: 'Unallocated & Hand Allocate In',
    NYT01_UNAL: 'Unallocated New Student',
    BKL01_100B: 'Under 100 Balance',
    HOS01_ULPC: 'Undergrad Loans Packaging CTL',
    JJC01_ULPC: 'Undergrad Loans Packaging CTL',
    KCC01_ULPC: 'Undergrad Loans Packaging CTL',
    QNS01_ULPC: 'Undergrad Loans Packaging CTL',
    HOS01_ULPE: 'Undergrad Loans Packaging EXP',
    JJC01_ULPE: 'Undergrad Loans Packaging EXP',
    KCC01_ULPE: 'Undergrad Loans Packaging EXP',
    QNS01_ULPE: 'Undergrad Loans Packaging EXP',
    HOS01_ULP2: 'Undergrad Loans Packaging EXP2',
    JJC01_ULP2: 'Undergrad Loans Packaging EXP2',
    KCC01_ULP2: 'Undergrad Loans Packaging EXP2',
    QNS01_ULP2: 'Undergrad Loans Packaging EXP2',
    HTR01_UGND: 'Undergrad Non Degree',
    JJC01_GTRN: 'Undergrad Transcript Missing',
    BKL01_UGRE: 'Undergraduate Reallocate Out',
    NYT01_UNDA: 'Undocumented Alien',
    HTR01_UDOC: 'Undocumented Aliens',
    BKL01_USBP: 'Unified Science Bridge Program',
    BCC01_1199: 'Union Healthcare Workers',
    BMC01_1199: 'Union Healthcare Workers',
    CSI01_1199: 'Union Healthcare Workers',
    GRD01_1199: 'Union Healthcare Workers',
    HOS01_1199: 'Union Healthcare Workers',
    KCC01_1199: 'Union Healthcare Workers',
    LEH01_1199: 'Union Healthcare Workers',
    NYT01_1199: 'Union Healthcare Workers',
    SLU01_1199: 'Union Healthcare Workers',
    YRK01_1199: 'Union Healthcare Workers',
    SLU01_UNIN: 'Union Semester Student',
    SPS01_UNIN: 'Union Semester Student',
    BAR01_UFED: 'United Federation Of Teachers',
    BKL01_UFED: 'United Federation Of Teachers',
    BAR01_USTB: 'Univ Science & Tech Bei Jing',
    BCC01_HSUH: 'University Heights High School',
    BKL01_REGS: 'Updated By Registrar',
    BKL01_SPHU: 'Upper Sophomore',
    SLU01_URBA: 'Urban Academy',
    SLU01_UE99: 'Urban Experience Cert/1199',
    BMC01_UMLA: 'Urban Male Leadership Academy',
    BKL01_URBN: 'Urban Systemic Initiative',
    HTR01_URES: 'Urban Teacher Residency',
    QNS01_UTRF: 'Urban Teacher Residency Fellow',
    LAG01_U105: 'Uzbek Heritage',
    LEH01_VASC: 'VA Benefit SpouseorChld of Vet',
    SPH01_VASC: 'VA Benefit SpouseorChld of Vet',
    GRD01_VTUT: 'VA In-State Tuition',
    SOJ01_VTUT: 'VA In-State Tuition',
    BAR01_VAST: 'VA Student',
    BCC01_VAST: 'VA Student',
    BKL01_VAST: 'VA Student',
    BMC01_VAST: 'VA Student',
    CSI01_VAST: 'VA Student',
    CTY01_VAST: 'VA Student',
    GRD01_VAST: 'VA Student',
    HOS01_VAST: 'VA Student',
    HTR01_VAST: 'VA Student',
    JJC01_VAST: 'VA Student',
    KCC01_VAST: 'VA Student',
    LAG01_VAST: 'VA Student',
    LAW01_VAST: 'VA Student',
    LEH01_VAST: 'VA Student',
    MEC01_VAST: 'VA Student',
    MED01_VAST: 'VA Student',
    NCC01_VAST: 'VA Student',
    NYT01_VAST: 'VA Student',
    QCC01_VAST: 'VA Student',
    QNS01_VAST: 'VA Student',
    SLU01_VAST: 'VA Student',
    SOJ01_VAST: 'VA Student',
    SPH01_VAST: 'VA Student',
    SPS01_VAST: 'VA Student',
    YRK01_VAST: 'VA Student',
    BAR01_VASC: 'VABenefit SpouseorChild of Vet',
    BCC01_VASC: 'VABenefit SpouseorChild of Vet',
    BKL01_VASC: 'VABenefit SpouseorChild of Vet',
    BMC01_VASC: 'VABenefit SpouseorChild of Vet',
    CSI01_VASC: 'VABenefit SpouseorChild of Vet',
    CTY01_VASC: 'VABenefit SpouseorChild of Vet',
    GRD01_VASC: 'VABenefit SpouseorChild of Vet',
    HOS01_VASC: 'VABenefit SpouseorChild of Vet',
    HTR01_VASC: 'VABenefit SpouseorChild of Vet',
    JJC01_VASC: 'VABenefit SpouseorChild of Vet',
    KCC01_VASC: 'VABenefit SpouseorChild of Vet',
    LAG01_VASC: 'VABenefit SpouseorChild of Vet',
    LAW01_VASC: 'VABenefit SpouseorChild of Vet',
    MEC01_VASC: 'VABenefit SpouseorChild of Vet',
    MED01_VASC: 'VABenefit SpouseorChild of Vet',
    NCC01_VASC: 'VABenefit SpouseorChild of Vet',
    NYT01_VASC: 'VABenefit SpouseorChild of Vet',
    QCC01_VASC: 'VABenefit SpouseorChild of Vet',
    QNS01_VASC: 'VABenefit SpouseorChild of Vet',
    SLU01_VASC: 'VABenefit SpouseorChild of Vet',
    SOJ01_VASC: 'VABenefit SpouseorChild of Vet',
    SPS01_VASC: 'VABenefit SpouseorChild of Vet',
    YRK01_VASC: 'VABenefit SpouseorChild of Vet',
    CSI01_VSSC: 'VAL/SAL Schoarship',
    CSI01_VSSP: 'VAL/SAL Schoarship - Partial',
    BAR01_ATHL: 'Varsity Athlete',
    BCC01_ATHL: 'Varsity Athlete',
    BKL01_ATHL: 'Varsity Athlete',
    BMC01_ATHL: 'Varsity Athlete',
    CSI01_ATHL: 'Varsity Athlete',
    CTY01_ATHL: 'Varsity Athlete',
    HOS01_ATHL: 'Varsity Athlete',
    HTR01_ATHL: 'Varsity Athlete',
    JJC01_ATHL: 'Varsity Athlete',
    KCC01_ATHL: 'Varsity Athlete',
    LAG01_ATHL: 'Varsity Athlete',
    LAW01_ATHL: 'Varsity Athlete',
    LEH01_ATHL: 'Varsity Athlete',
    MEC01_ATHL: 'Varsity Athlete',
    NCC01_ATHL: 'Varsity Athlete',
    NYT01_ATHL: 'Varsity Athlete',
    QCC01_ATHL: 'Varsity Athlete',
    QNS01_ATHL: 'Varsity Athlete',
    YRK01_ATHL: 'Varsity Athlete',
    QCC01_TX2: 'Verizon Next Step',
    BCC01_VZNS: 'Verizon Next Step Program',
    HOS01_VZNS: 'Verizon Next Step Program',
    NYT01_VZNS: 'Verizon Next Step Student',
    HTR01_VSCH: 'Verizon Scholars - STEM',
    CSI01_VERZ: 'Verrazano Honors Program',
    LEH01_VEFR: 'Veteran Freshman',
    JJC01_VAIS: 'Veteran Instate Tuition',
    LEH01_VEJR: 'Veteran Junior',
    LEH01_VETO: 'Veteran Other',
    LEH01_VESR: 'Veteran Senior',
    LEH01_VESO: 'Veteran Sophomore',
    LEH01_VETT: 'Veteran Tuition',
    LAG01_VADV: 'Virtual Advisement and Reg',
    CTY01_H1H4: 'Visa Holder',
    YRK01_VISF: 'Visiting Non-Deg Fall Sess',
    YRK01_VISS: 'Visiting Non-Deg Spring Sess',
    YRK01_VISW: 'Visiting Non-Deg Winter Sess',
    BAR01_VISP: 'Visiting Stdnt (nonCUNY) PostM',
    BAR01_VIS: 'Visiting Student (non CUNY)',
    BCC01_VIS: 'Visiting Student (non CUNY)',
    BKL01_VIS: 'Visiting Student (non CUNY)',
    BMC01_VIS: 'Visiting Student (non CUNY)',
    CSI01_VIS: 'Visiting Student (non CUNY)',
    CTY01_VIS: 'Visiting Student (non CUNY)',
    GRD01_VIS: 'Visiting Student (non CUNY)',
    HOS01_VIS: 'Visiting Student (non CUNY)',
    HTR01_VIS: 'Visiting Student (non CUNY)',
    JJC01_VIS: 'Visiting Student (non CUNY)',
    KCC01_VIS: 'Visiting Student (non CUNY)',
    LAG01_VIS: 'Visiting Student (non CUNY)',
    LAW01_VIS: 'Visiting Student (non CUNY)',
    LEH01_VIS: 'Visiting Student (non CUNY)',
    MEC01_VIS: 'Visiting Student (non CUNY)',
    NCC01_VIS: 'Visiting Student (non CUNY)',
    NYT01_VIS: 'Visiting Student (non CUNY)',
    QCC01_VIS: 'Visiting Student (non CUNY)',
    QNS01_VIS: 'Visiting Student (non CUNY)',
    SLU01_VIS: 'Visiting Student (non CUNY)',
    SOJ01_VIS: 'Visiting Student (non CUNY)',
    SPH01_VIS: 'Visiting Student (non CUNY)',
    SPS01_VIS: 'Visiting Student (non CUNY)',
    YRK01_VIS: 'Visiting Student (non CUNY)',
    MEC01_VOTB: 'Vote at Bedford',
    MEC01_VOTC: 'Vote at Carroll',
    LEH01_VOPM: 'Voucher Permit',
    BAR01_ZKWP: 'WSAS Maj with Zicklin Perm',
    HTR01_APPF: 'Waive App Fee',
    BKL01_WAIV: 'Waive Application Fee',
    NYT01_APPW: 'Waive Application Fee',
    BKL01_CNWV: 'Waiver College Now',
    LAG01_WAV1: 'Wave 1 for Tier 1',
    LAG01_WAV2: 'Wave 2 for Tier 2',
    LAG01_WAV3: 'Wave 3 for Tier 3',
    LAG01_WAV4: 'Wave 4 for Tier 4',
    BAR01_WKND: 'Weekend College',
    BCC01_WKND: 'Weekend College',
    BKL01_WKND: 'Weekend College',
    BMC01_WKND: 'Weekend College',
    CSI01_WKND: 'Weekend College',
    CTY01_WKND: 'Weekend College',
    HOS01_WKND: 'Weekend College',
    HTR01_WKND: 'Weekend College',
    JJC01_WKND: 'Weekend College',
    KCC01_WKND: 'Weekend College',
    LAG01_WKND: 'Weekend College',
    LAW01_WKND: 'Weekend College',
    LEH01_WKND: 'Weekend College',
    MEC01_WKND: 'Weekend College',
    NCC01_WKND: 'Weekend College',
    NYT01_WKND: 'Weekend College',
    QCC01_WKND: 'Weekend College',
    QNS01_WKND: 'Weekend College',
    YRK01_WKND: 'Weekend College',
    JJC01_WCRS: 'Weekend Courses',
    HTR01_WCMS: 'Weill Cornell Med Sch Perm In',
    JJC01_WSPT: 'West Point Program',
    KCC01_WHSE: 'Westinghouse',
    BAR01_MHC: 'William E Macaulay Honors Coll',
    BKL01_MHC: 'William E Macaulay Honors Coll',
    CSI01_MHC: 'William E Macaulay Honors Coll',
    CTY01_MHC: 'William E Macaulay Honors Coll',
    HTR01_MHC: 'William E Macaulay Honors Coll',
    JJC01_MHC: 'William E Macaulay Honors Coll',
    LEH01_MHC: 'William E Macaulay Honors Coll',
    NYT01_MHC: 'William E Macaulay Honors Coll',
    QCC01_MHC: 'William E Macaulay Honors Coll',
    QNS01_MHC: 'William E Macaulay Honors Coll',
    YRK01_MHC: 'William E Macaulay Honors Coll',
    LEH01_WINT: 'Winter Session Paid',
    LAG01_CST3: 'With CST indicator, not in CS',
    LAG01_MST3: 'With MST Indicator, Not in MAT',
    CTY01_WRED: 'Worker Education',
    LAG01_WRED: 'Worker Education',
    MEC01_WRED: 'Worker Education',
    QNS01_WRED: 'Worker Education',
    SPS01_WRED: 'Worker Education',
    QCC01_WEX: 'Writing Exempt',
    QNS01_WEX: 'Writing Exempt',
    BCC01_WINR: 'Writing Intensive Not Required',
    MEC01_WCRT: 'Writing/Learning Center',
    HOS01_XRAY: 'X-RAY (CLINICAL PHASE)',
    HTR01_YLWH: 'Yalow Honors Scholars',
    BMC01_YRUP: 'YearUp Program',
    BKL01_ZVID: 'Yeshiva Zvi Dov R Pace HS Prg',
    BKL01_YESH: 'Yeshivat Shaare Torah',
    HTR01_YKTA: 'Yonkers Teachers Academy',
    YRK01_ROTC: 'York College ROTC Program',
    YRK01_YSCH: 'York College Scholar',
    YRK01_YESS: 'York Enrichment Program',
    LEH01_YOSC: 'Youth Studies Certificate',
    BAR01_ZAPT: 'ZSB Accelerated Part Time Prog',
    BAR01_MBFT: 'Zicklin Full-Time MBA',
    BAR01_ZHON: 'Zicklin Honors Minor',
    BAR01_NOTZ: 'Zicklin Ineligible',
    BAR01_ZPTF: 'Zicklin Part Time Flex',
    BAR01_ZK4P: 'Zicklin Temp Perm 4000 level',
    BAR01_ZK4L: 'Zicklin eligible 4000 level',
    BMC01_ZUAP: 'Zurich Apprenticeship Program',
    BMC01_ELRN: 'eLearning',
};

export const uniqueGroupsWithoutInstitution: {[key: string]: string} = {
    SPH4: '1 Yr DI/IP Prog Only',
    '2YLM': '2 Yr Deg and Cred Earned < Lim',
    AD24: '24 CR OPTION FOR ADMISSIONS',
    C224: '24CC Passed CUNY Test',
    CC24: '24CC Student',
    '24L6': '24CC less than 6 Cr.',
    '24M6': '24CC more than 6 Cr.',
    '2UPC': '2nd Degree, 2 Upper Cc Reqd',
    '2NCO': '2nd Degree, No Core Required',
    TRN3: '2nd sem. Transfers 60 creds or',
    TRN2: '2nd term TRNS under 45 credits',
    '30KD': '30,000 Degree',
    '243D': '3D Direct Loan',
    '4TEP': '4 Track Teacher Education Prog',
    '4YLM': '4 Yr Deg and Cred Earned < Lim',
    BM41: '4+1 Bachelor/Master Program',
    '5TEP': '5 Track Teacher Education Prog',
    '6TEP': '6 Track Teacher Education Prog',
    JSTA: '6-wk Jump Start',
    HSRH: 'A P Randolph High School',
    ACE0: 'ACE Eligible',
    AFWG: 'ACE First in the World Grant',
    AHSE: 'ACE High School Equivalency',
    ACEM: 'ACE Mentee',
    ASG: 'ACE SEAMOS Grant',
    ACES: 'ACE Scholar',
    TELC: 'ACE TELC Non Crd to Crd',
    AHCP: 'ACE at Home in College Program',
    ACTC: 'ACE to Credit',
    ACR1: 'ACR 94',
    ACR: 'ACR 95',
    STRE: 'AD',
    ADPM: 'ADP Student Who failed Math',
    ALLP: 'ALL-PERMIT IN-PI01-PI23',
    APC2: 'APPLE Corps Accepted',
    APC1: 'APPLE Corps Applied',
    APC4: 'APPLE Corps Declined',
    APC3: 'APPLE Corps Denied',
    APC0: 'APPLE Corps Invited',
    APLA: 'APPLE Corps Stdnt - LaGuardia',
    APBM: 'APPLE Corps Student - BMCC',
    APBX: 'APPLE Corps Student - Bronx',
    APHO: 'APPLE Corps Student - Hostos',
    APJC: 'APPLE Corps Student - John Jay',
    APKC: 'APPLE Corps Student - KCC',
    APQC: 'APPLE Corps Student - QCC',
    FARP: 'APR CUNY Comeback',
    ARCP: 'ARC',
    HASP: 'ASAP - HERO Student',
    V0: 'ASAP Approved',
    ASP1: 'ASAP Eligible - Tested',
    ASP0: 'ASAP Predicted Eligible',
    ASL5: 'ASL 105',
    ASL6: 'ASL 106',
    ASM1: 'ASMS Eligible',
    ATBX: 'Ability To Benefit Met-Exempt',
    ATBN: 'Ability To Benefit-Didn\'t Pass',
    ATBF: 'Ability to Benefit Failed Test',
    ATBP: 'Ability to Benefit Passed Test',
    ATBQ: 'Ability to Benefit Qualified',
    ATB: 'Ability to Benefit Student',
    ATBU: 'Ability to Benefit UnderReview',
    ACAD: 'Academic Advisement',
    AFS: 'Academic Fresh Start',
    AFSE: 'Academic Fresh Start Eligible',
    AFSB: 'Academic FreshStart Balance',
    AFSI: 'Academic FreshStart Ineligible',
    2443: 'Academic Progress Waiver-Fed',
    2444: 'Academic Pursuit Waiver-Fed',
    ARES: 'Academic Restriction 12 Cr',
    WARN: 'Academic Warning',
    AATC: 'Accelerated Arts Teachers Cert',
    ADAP: 'Accelerated Dual-Admission Prg',
    ALP: 'Accelerated ENG Program',
    ACCG: 'Accelerated Graduate',
    ALPM: 'Accelerated Learning Prog Math',
    ACLM: 'Accelerated Master\'s Student',
    OAPT: 'Accelerated Part Time MBA(Old)',
    ASFW: 'Accelerated Study Fee Waived',
    ACFE: 'Accelerated Study Fee Waived',
    '3A': 'Accelerated Study Fee waived',
    ACCL: 'Acceleration Project',
    ACWV: 'Acceleration Waiver',
    BSMS: 'Acceptance into BS/MS OT Prog',
    PAR2: 'Accepted Adv & Reg',
    IPRB: 'Accepted Probation Students',
    ACON: 'Accepted With Conditions-Adms',
    DCON: 'Accepted With Conditions-Dept',
    WEIS: 'Accepted to Weissman School',
    ASP3: 'Accepts ASAP Invitation',
    ASM3: 'Accepts ASMS Invitation',
    PTI3: 'Accepts PTI invitation',
    AC: 'Accessibility Center',
    ACNT: 'Accessibility Center Non-Trio',
    ACT: 'Accessibility Center Trio',
    ACCS: 'Accessibility Services',
    ACHV: 'Achievers',
    PAR4: 'Active PAR participant',
    ASPR: 'Addiction Studies Courses',
    AREQ: 'Additional Requirements',
    ADTE: 'Adelante',
    ADMW: 'Adm Visiting Stdnts Winter',
    ADDE: 'Admission Declined',
    ADST: 'Admission to Student Teaching',
    TEE3: 'Admission to Student Teaching',
    AAMB: 'Admissions Ambassador',
    ADEC: 'Admissions Declined',
    ADDF: 'Admissions Deferred',
    ADMM: 'Admissions Direct Admit',
    ADMR: 'Admissions Ready Students',
    ADSC: 'Admissions Scholarship Recip',
    ADMT: 'Admissions Tran Evalution Comp',
    ADMA: 'Admisssions Accepted',
    ADRE: 'Admitted By Review',
    ZICK: 'Admitted into the Zicklin',
    PRPR: 'Admitted to Prof Phase-PA Prog',
    SPAF: 'Admitted to SPA',
    ASBI: 'Adolescence Sci. Edu. Biology',
    ASES: 'Adolescence Sci. Edu. EarthSci',
    ASPH: 'Adolescence Sci. Edu. Physics',
    ASCH: 'Adolescence Sci. Edu.Chemistry',
    ASPO: 'Adolescent Special Educ Online',
    ACE: 'Adult Collegiate Education',
    ADLS: 'Adult Learner Scholarship',
    AUFT: 'Adv Cert UFT Student',
    ADSW: 'Adv Stand Social Work',
    ACRT: 'Advance Cert Student',
    PLAY: 'Advance Cert in Play Therapy',
    A200: 'Advanced Arabic',
    V200: 'Advanced Bengali',
    EDAP: 'Advanced Cert Program',
    C200: 'Advanced Chinese',
    F200: 'Advanced French',
    I200: 'Advanced Italian',
    J200: 'Advanced Japanese',
    K201: 'Advanced Korean',
    ADLC: 'Advanced Learning Communities',
    S200: 'Advanced Spanish',
    SPH3: 'Advisement Req\'d - See Advisor',
    ABPR: 'Advocacy to Bridge Program',
    ASC: 'After School Corp',
    A4AL: 'Algebra for All',
    AMPS: 'Alliance Min Part Stem',
    ALHT: 'Allied Health',
    AHAS: 'Allied Health/Science Academy',
    ALSL: 'Alpha Sigma Lambda',
    D75B: 'Alt cert teachers in D75 schls',
    ALTF: 'Alternate Format',
    M102: 'American Sign Language 2',
    M103: 'American Sign Language 3',
    M104: 'American Sign Language 4',
    AUR: 'American University of Rome',
    AMNH: 'AmericanNatural History Museum',
    ROTH: 'Anne Rothstein\'s Program',
    APPD: 'Appeal Denied',
    HOAP: 'Applicant to Honors Program',
    REAC: 'Application Reactivated',
    RELL: 'Application Reallocated',
    ACE1: 'Applied to ACE',
    ASP2: 'Applied to ASAP',
    CST1: 'Applied to CUNYStart',
    FEP1: 'Applied to FEP',
    REGA: 'Appointment Groups',
    AFSA: 'Approved Academic Fresh Start',
    A105: 'Arabic 105',
    A106: 'Arabic 106',
    A107: 'Arabic 107',
    A210: 'Arabic 210',
    ARA1: 'Arabic Placement 101',
    ARA2: 'Arabic Placement 102',
    AWSC: 'Arnold Work-Study Control',
    AWSN: 'Arnold Work-Study Not Included',
    AWST: 'Arnold Work-Study Treatment',
    ASTR: 'AstroCom NYC',
    AHIC: 'At Home In College',
    AHCS: 'Athena Scholars',
    AFFR: 'Athlete Student Fall Freshman',
    AFJR: 'Athlete Student Fall Junior',
    AFSR: 'Athlete Student Fall Senior',
    AFSO: 'Athlete Student Fall Soph',
    ATFA: 'Athlete Student Fall Sport',
    ASFR: 'Athlete Student Spring Fresh',
    ASJR: 'Athlete Student Spring Junior',
    ASSR: 'Athlete Student Spring Senior',
    ASSO: 'Athlete Student Spring Soph',
    ATSP: 'Athlete Student Spring Sport',
    AUST: 'Austrian Education Program',
    AUTS: 'Autism Spectrum Disorders',
    BAMA: 'BA/MA US Educated',
    BAMD: 'BA/MD Program',
    FOOT: 'BA/Podiatry Program',
    BAF2: 'BACC Ind Fail 2 or 3',
    BAF1: 'BACC Ind Fail <= 1 Exam',
    BANL: 'BACC Stud w/Nursing Lic',
    HSEC: 'BC Academy Early College',
    BCSA: 'BC-BC Study Abroad',
    CUSA: 'BC-CUNY Study Abroad',
    NCSA: 'BC-NON-CUNY Study Abroad',
    BCRR: 'BC-Readmit Reinstate',
    B150: 'BIO 150',
    FLAE: 'BLA Eligible',
    BLIS: 'BLIIS Student',
    BMCA: 'BMCC ACP Program Student at BC',
    FLA: 'BMCC Learning Academy',
    STAR: 'BMCC STARS',
    BOCE: 'BOCES Program',
    BTAG: 'BTAG Agreement',
    BACE: 'Baccalaureate Eligible',
    BACC: 'Baccalaureate Students',
    BLPP: 'Barnard LaGuardia Partnership',
    BSCH: 'Baruch Scholar',
    BSCO: 'Baruch Scholar Offer',
    BIT: 'Behavioral Intervention Team',
    V105: 'Bengali Heritage',
    BILX: 'Bilingual Extension',
    EDBE: 'Bilingual Extension',
    BIEX: 'Bilingual Extension',
    BIPG: 'Bilingual Program',
    BLNG: 'Bilingual Student',
    QBCP: 'Bio Concen Prog - STEM',
    BMED: 'Biomedical Science',
    BIOS: 'Biostatistics',
    HSAM: 'Bklyn Amity Sch - Bridge To BC',
    BMLA: 'Black/Latino Males BUS Major',
    BOLT: 'Bldg Outstanding Leaders Tom',
    B1: 'Block Institute Fall 05',
    BLKP: 'Block Programming',
    BOEJ: 'Board of Ed - Jewish',
    BKAD: 'Book Advance Issued',
    BOOK: 'Book Voucher',
    BKOU: 'Bookvoucher  Opt-Out',
    BVIN: 'Bookvoucher Opt-In',
    BOTL: 'Bottom Line Program',
    BRAV: 'Braven',
    BRNU: 'Bridge to Nursing',
    BBAP: 'Bridges to the BA Program',
    BASE: 'Bronx Acad Software & Engineer',
    BRON: 'Bronx Center',
    BECA: 'Bronx Early College Academy',
    BCB: 'Brooklyn College Bound Program',
    BPDC: 'Brooklyn Pipeline Dual Credit',
    HSBT: 'Brooklyn Tech High School',
    EW30: 'Business Writing',
    BTA: 'Business and Tech Academy',
    C210: 'C210 -  Chinese 210',
    HSCS: 'CASAC Program',
    QM6: 'CAT Exempt and M6 Score 30+',
    CATW: 'CATW Field Test Participant',
    CO02: 'CC Lwr Trsf - 4 College Opt',
    CO03: 'CC Uppr Trsf - 3 College Opt',
    HSPP: 'CCNY Academy-Professional Prep',
    '244A': 'CD 1 Failed Progress (Temp)',
    '244B': 'CD 2 Failed Pursuit (Temp)',
    '244C': 'CD 3 Failed Progress & Pursuit',
    '244D': 'CD 4 Failed STAP Requirements',
    '244E': 'CD 5 Failed APTS Prgrs (Temp)',
    '244F': 'CD 6 Failed APTS Purst (Temp)',
    '244G': 'CD 7 Failed APTS Prog &Pursuit',
    2441: 'CD A Academic Progress Waiver',
    2442: 'CD B Academic Program Pursuit',
    '244P': 'CD P Program Pursuit Waiver',
    '244S': 'CD S STAP Waiver',
    '244W': 'CD S TAP Waiver',
    CDIE: 'CD Student - Ineligible',
    CDLI: 'CD Student Enrolled in CLIP',
    CDPT: 'CD Student Part Time/Acad Reas',
    CEWD: 'CEWD Student',
    CECH: 'CEWD-Community Health Worker',
    CECM: 'CEWD-Construction Management',
    CECA: 'CEWD-Culinary Arts',
    CEFP: 'CEWD-Food Protection',
    CEMB: 'CEWD-Medical Billing',
    CFCV: 'CF Conversion POP \'03 - P',
    CHLI: 'CHLI -  Chinese Literature',
    TCPP: 'CISC Majors C++ Track',
    CLA: 'CLA Lumina Program',
    CLAS: 'CLAS',
    CLSS: 'CLASS Educational Leadership',
    CLPA: 'CLIP - CA',
    CLPB: 'CLIP - CB',
    CLPC: 'CLIP - CC',
    CLPD: 'CLIP - CD',
    CLIP: 'CLIP Student',
    CLWG: 'CLIP Walmart Grant',
    CNSI: 'CN Summer Sci Inst',
    COPF: '"COPE Federal Percent  "',
    COPE: 'COPE Student',
    C100: 'COR Exempt',
    RE42: 'CSE095 Placement',
    RE44: 'CSE099 Placement',
    CSTP: 'CSTEP',
    M120: 'CSTM 0120 Placement',
    CUEB: 'CUE Block Program',
    C2XT: 'CUNY 2X Tech',
    CBA: 'CUNY BA Student',
    CPTH: 'CUNY CareerPATH',
    CTCH: 'CUNY Catch',
    CDRE: 'CUNY Disaster Relief Efforts',
    HMHC: 'CUNY HONORS HOME COLLEGE',
    JABX: 'CUNY Justice Academy',
    JABM: 'CUNY Justice Academy',
    JAHC: 'CUNY Justice Academy',
    JAKC: 'CUNY Justice Academy',
    JALC: 'CUNY Justice Academy',
    JAQC: 'CUNY Justice Academy',
    COTC: 'CUNY On The Concourse',
    CPIN: 'CUNY Permit In Student',
    PIPE: 'CUNY Pipeline Program',
    PREP: 'CUNY Prep',
    CRSP: 'CUNY Research Scholars Program',
    CSPH: 'CUNY School of Public Health',
    FCSC: 'CUNY Science Scholarship',
    CSCS: 'CUNY Service Corps Student',
    FELL: 'CUNY Teaching Fellow',
    CBSA: 'CUNY-BC Study Abroad',
    CUBI: 'CUNYBA - Permitted In',
    CUBH: 'CUNYBA - at Home College',
    LEX: 'CUNYBA Language Exemption',
    MHC: 'CUNYBA Macaulay Honors College',
    PROV: 'CUNYBA Provisional Admit',
    STRT: 'CUNYStart Enrolled',
    STRP: 'CUNYStart Potential',
    CUSP: 'CUSP Studnet',
    CEED: 'Cadet Edu Empowerment & Dev',
    CA01: 'Cadre 1 Teaching Fellows',
    CA10: 'Cadre 10 Teaching Fellows',
    CA12: 'Cadre 12 Teaching Fellows',
    CA14: 'Cadre 14 Teaching Fellows',
    CA18: 'Cadre 18 Teaching Fellows',
    CA20: 'Cadre 20 Teaching Fellows',
    CA22: 'Cadre 22 Teaching Fellows',
    CA03: 'Cadre 3 Teaching Fellows',
    CA05: 'Cadre 5 Teaching Fellows',
    CA06: 'Cadre 6 Teaching Fellows',
    CA08: 'Cadre 8 Teaching Fellows',
    SPHA: 'Capstone - INC Grade',
    C3IM: 'Career & College Comp Inst Men',
    CRLD: 'Career Ladder',
    CPDL: 'Career Path-Dept of Labor',
    HEAL: 'Careers in Health',
    CIT: 'Careers in Training',
    CARR: 'Carroll Gardens',
    CTSC: 'Catalyst Scholars',
    CD1: 'Cd Prong I',
    CD2: 'Cd Prong Ii',
    CASE: 'Center for Achievement in Sci',
    RESC: 'Certificate of Residency',
    CHEN: 'Chemical Engineering Science',
    CARE: 'Child Care Certificate',
    CWEL: 'Child Welfare-Human Resrcs Adm',
    CPAC: 'Childrens ProgAdmin Credential',
    XCHI: 'China Flagship Ex Program',
    C102: 'Chineese 102',
    C105: 'Chinese 105',
    C106: 'Chinese 106',
    C107: 'Chinese 107',
    CHI2: 'Chinese 200',
    CHI1: 'Chinese Placement 101',
    CITZ: 'Citizenship Proof',
    CITP: 'Citizenship Residency Status',
    CELI: 'City College ELI Student',
    HCPH: 'City Poly High School Program',
    HSCP: 'City Poly High School Program',
    CIEN: 'Civil Engineering Science',
    CPFL: 'Colin Powell Fellows',
    CMAS: 'Coll Management Academic Stud',
    CCBA: 'College & Career Bridge 4 All',
    CAAP: 'College Acad AdvisementProgram',
    CLAC: 'College Access and Success',
    CNCT: 'College Connect',
    CCPR: 'College Connect Project',
    CDGR: 'College Discovery Graduate',
    CD: 'College Discovery Student',
    COFO: 'College Focus High School Prog',
    LINK: 'College Link',
    CNCO: 'College Now Cohort',
    CNOC: 'College Now On Campus',
    CNPP: 'College Now President\'s Prep',
    CNOW: 'College Now Student',
    CNMT: 'College Now-METEAS Ed Program',
    CNTP: 'College Now-Tuition Payment',
    CO01: 'College Option - 9 CR Required',
    CO04: 'College Option Sequence  4',
    CO00: 'College Option Sequence 0',
    CO12: 'College Option-12 Credits Req',
    CSIP: 'College Success Initiative',
    COSU: 'College Success Int Learn Comm',
    CWST: 'College Work Study Student',
    FRGV: 'Comeback Campaign candidate',
    COMC: 'Commitment Confirmed',
    COMX: 'Commitment Deposit Ex',
    COME: 'Commitment Deposit Expired',
    COMP: 'Commitment Deposit Paid',
    COMW: 'Commitment Deposit Waiver',
    CDME: 'Common Dept Math Exam',
    1180: 'Communications Workers-America',
    CBOS: 'Community Based Org Student',
    CCPS: 'Community College Partnership',
    COML: 'Community Leadership',
    CMSC: 'Community Scholars Cohort',
    CMST: 'Community Semester',
    FREN: 'Completing French Lang Seq',
    ITAL: 'Completing Ital Language Seq',
    SPAN: 'Completing Span Language Seq',
    TEE6: 'Completion of NY State Cert.',
    CPST: 'Completion of Student Teaching',
    TEE4: 'Completion of Student Teaching',
    TEE5: 'Completion of TE Program',
    DEVC: 'Computer Device',
    C166: 'Computer Info Systems 166',
    CIS2: 'Computer Info Systems 200',
    C211: 'Computer Info Systems 211',
    C212: 'Computer Info Systems 212',
    C228: 'Computer Info Systems 228',
    C234: 'Computer Info Systems 234',
    C244: 'Computer Info Systems 244',
    C331: 'Computer Info Systems 331',
    C344: 'Computer Info Systems 344',
    C230: 'Computer Programming 230',
    C326: 'Computer Programming 326',
    CSHC: 'Computer Science Honors Cohort',
    CONA: 'Conditional Admit',
    COND: 'Conditional Admit',
    COAD: 'Conditional Admit  Student',
    '243H': 'Conditional Admit Student',
    CNDT: 'Conditional Admit Undergrad',
    TEE1: 'Conditional Admit to TE Prog',
    CBAC: 'Conditional Baccalaureate',
    CNDM: 'Conditional Majors',
    CMGR: 'Conditional Matric Graduate',
    CNDN: 'Conditional Nursing',
    CNDP: 'Conditional Passed Exams',
    CRTS: 'Conditional Road to Success',
    SCD2: 'Conditional SEEK/CD',
    ATLC: 'Conditional Student Athletes',
    CMET: 'Conditions Met',
    SC2C: 'Connect to Complete',
    CP: 'Connect2College',
    CONS: 'Consortium Agreement',
    RCOB: 'Continued Off Probation',
    RCPB: 'Continued On Probation',
    CADV: 'Continuing Advisement',
    ACEL: 'Continuing Educ Student',
    CSIU: 'Continuing Education/SIUH',
    PRB2: 'Continuing Probation Students',
    CONT: 'Continuing Student',
    CST2: 'Control Group Student',
    N1: 'Control Opening Doors Fa03',
    N3: 'Control Opening Doors Fa04',
    N5: 'Control Opening Doors Fa07',
    N7: 'Control Opening Doors Fa08',
    N9: 'Control Opening Doors Fa09',
    N2: 'Control Opening Doors Sp04',
    N4: 'Control Opening Doors Sp05',
    N6: 'Control Opening Doors Sp08',
    N8: 'Control Opening Doors Sp09',
    CONV: 'Converted Records',
    COOP: 'Cooperating Teacher',
    CPMN: 'Cooperman Scholars',
    ENGN: 'Coordinated Engineering Prgm',
    XREG: 'Course Reg incentive',
    EMAL: 'Create Baruch Email',
    CDMP: 'Credible Messenger Program',
    CRDM: 'Credit Momentum',
    CDRM: 'Credit Momentum',
    CROS: 'Cross Country',
    CYHL: 'Cypress Hills',
    RECV: 'DB Rec Verified VS Hard Copy',
    DC37: 'DC37 - Excluding Schfd Mng',
    DNTL: 'DENTAL HYG. (CLINICAL PHASE)',
    DEN3: 'DENTAL-3YR (CLINICAL PHASE)',
    '3D': 'DLS Selected Exit Interview',
    STAC: 'DOE-Subsidized TESOL Addl Cert',
    DOL2: 'DOL - No Tuition Waiver',
    DQ: 'DOMINICAN EXCHANGE PROGRAM',
    DAY: 'Day',
    DCP: 'Day Care Parent',
    DFAD: 'Deaf Adult Program',
    DSCO: 'Dean Scholar Offer',
    DSCH: 'Dean\'s Scholar',
    V4: 'Decision Pendg Descr 10/29/07',
    DFER: 'Deferral of Admission',
    DACA: 'Deferred Action Child Arrival',
    DGU3: 'Degree Under Three',
    DGWD: 'Degrees When Due Students',
    AFSD: 'Denied Academic Fresh Start',
    DADM: 'Denied Readmission',
    SCD3: 'Denied/ Declined SEEK/CD',
    ACE3: 'Denied/Declined ACE',
    ASP4: 'Denied/Declined ASAP',
    CST3: 'Denied/Declined CUNYStart',
    DOH: 'Department of Health',
    TRDE: 'Dept Evaluation Pending',
    DHMH: 'Dept Mntl Hlth Hygn',
    DOCL: 'Dept of Corrections Leadership',
    DOL: 'Dept. of Labor CUNY Consortium',
    DEWW: 'Dewitt-Wallace Gradute Progm',
    DIPI: 'Diamond Fellow / CUNY Pipeline',
    DIPH: 'Dietetic Intern PH Nutrition',
    DIUN: 'Diplomat/Dependent of UN Empl',
    DCGN: 'Dir Care Gen Cert-Mult Agns',
    ZK01: 'Direct Admissions to Zicklin',
    ADMD: 'Direct Admit',
    DRCT: 'Direct Admit',
    DAFR: 'Direct Admit Freshman',
    DADI: 'Direct Admit In Service',
    DATR: 'Direct Admit Transfer',
    DIRC: 'Direct Admits',
    FEGS: 'Direct Care Generalist-Fegs',
    UCPN: 'Direct Care Generalist-UCP Nas',
    FDIR: 'Direct Freshman',
    DLIN: 'Direct Loan Interview Done',
    DLS1: 'Direct Loan Stdnt Req Exit Int',
    DLS2: 'Direct Loan Stu Req. Exit Int2',
    '243E': 'Direct LoanStdn Exit Interview',
    TDIR: 'Direct Transfer',
    DSPL: 'Directed Self Placement',
    DISC: 'Discovery',
    DTOC: 'Discovery/TOC',
    DISM: 'Dismissal - Graduate',
    DSOT: 'Dismissal from BS/MS OT Prog',
    DSMN: 'Do not send mail to student',
    COVE: 'Dolphin Cove',
    PCOV: 'Dolphin Cove',
    WCOV: 'Dolphin Cove',
    ACOV: 'Dolphin Cove Applicants',
    DEXP: 'Domestic Executive Program',
    DRSS: 'Dominican Rep Scholarship Stud',
    XDOM: 'DominicanRep Exchange Program',
    V2: 'Double Exempt Summer Imm ASAP',
    DMSH: 'Dreamer Scholars',
    DROP: 'Dropped All Courses',
    DDEG: 'Dual Degree Students',
    DUPU: 'Duplicate Ready to Purge',
    EDGE: 'EDGE Student',
    C103: 'ELC103: Intermediate Chinese 1',
    G103: 'ELG103: Intermediate Greek',
    H102: 'ELH102: Elementary Hebrew 2',
    H200: 'ELH201: Contemp Hebrew Lit',
    P102: 'ELP102: Elementary Polish 2',
    P103: 'ELP103: Intermediate Polish',
    P105: 'ELP105: Polish for Heritage Sp',
    P200: 'ELP201: Polish Literature',
    R102: 'ELR102: Elementary Russian 2',
    R103: 'ELR103: Intermediate Russian',
    R105: 'ELR105:Russian for Heritage Sp',
    R200: 'ELR201:Russian Lit of 20th Cen',
    S102: 'ELS102: Elementary Spanish 2',
    S103: 'ELS103: Intermediate Spanish 1',
    S104: 'ELS104: Intermediate Spanish 2',
    T102: 'ELT102:  Elementary Tibetan 2',
    V102: 'ELV102: Elementary Bengali 2',
    V103: 'ELV103: Intermediate Bengali',
    Z102: 'ELZ102:Elementary Portuguese 2',
    EMS: 'EMS',
    WR55: 'ENA 101 (Continuing)',
    WR56: 'ENA 101 (NEW)',
    WR58: 'ENA099 Placement Local Code993',
    WR60: 'ENC101 Placement Local Code995',
    ENRS: 'ENDURE Neuroscience Res Schl',
    E132: 'ENG 0132 Placement',
    ESL6: 'ENG 100.6',
    E210: 'ENG 2100 Placement',
    E21T: 'ENG 2100T Placement',
    E215: 'ENG 2150 Placement',
    E25T: 'ENG 2150T Placement',
    ENG1: 'ENG 88',
    ENG2: 'ENG 95',
    WR59: 'ENG099 Placement Local Code994',
    WR51: 'ENG101 Placement Local Code996',
    SPH6: 'EPI/BIOS Stud - EPI Focus',
    WR57: 'ESA 099 Placement',
    WR80: 'ESC99 Placement',
    ES20: 'ESL 12000',
    EINT: 'ESL 12000 & ESL 12100',
    ES21: 'ESL 12100',
    ES30: 'ESL 13000',
    EHIG: 'ESL 13000 & ESL 19900',
    ES99: 'ESL 19900',
    ESL1: 'ESL 54',
    ESL2: 'ESL 62',
    ESL3: 'ESL 94',
    ESL4: 'ESL 95',
    ESL5: 'ESL 96',
    ESLR: 'ESL CATW Readers',
    ESLD: 'ESL Diagnostic Assessment',
    ESLA: 'ESL Placement Level Advanced',
    ESLH: 'ESL Placement Level High',
    ESLL: 'ESL Placement Level Low/Interm',
    ESL: 'ESL Student',
    ESLI: 'ESL Student - Intensive',
    TFLW: 'ESL/TOEFL Waived',
    WR66: 'ESL096 Placement Code 960',
    WR67: 'ESL097 Placement Code 970',
    WR68: 'ESL098 Placement Code 980',
    WR69: 'ESL099 Placement Code 990',
    WR77: 'ESR097 Placement Code 971',
    WR78: 'ESR098 Placement Code 981',
    WR79: 'ESR099 Placement Code 991',
    EX: 'EXTENSION CENTER STUDENTS',
    ZK02: 'Early Admissions to Zicklin',
    '24EA': 'Early Admits w/o HS Diploma',
    EAP: 'Early Alert Program',
    ECCG: 'Early Childhood Center Grant',
    ECE: 'Early Childhood Education',
    ECLC: 'Early Childhood Learning Cntr',
    ECAC: 'Early College AdvIsIngCaseload',
    ECI: 'Early College Initiative',
    DEGR: 'Early Degree Review',
    ERLY: 'Early Start',
    AS2: 'Early Warning Standing',
    SENG: 'Earned S in Remedial ENG Cours',
    SMTH: 'Earned S in Remedial Math',
    ETAC: 'East -Teachers Academy',
    EHTP: 'East Harlem Tutorial Program',
    EDLD: 'Ed Ldr Msed-Dual Curr:723&724',
    EA19: 'Educ Adol/Spec Sub 19 Crd',
    EA30: 'Educ Adol/Spec Subj 30-32 Crd',
    EA32: 'Educ Adol/Spec Subj 32-48 Crd',
    EOP: 'Educ Opportunity Program',
    EDCA: 'Education Conditional Admit',
    ED3P: 'Education Partnership 3rd Prty',
    EDQT: 'Education Quest',
    EDSC: 'Education Sec Ed Cert',
    EDSP: 'Education Special Programs',
    EOC: 'Educational Opportunity Center',
    EISN: 'Eisenhower Scholarship',
    ELNX: 'Elearning Exempt',
    ELEN: 'Electrical Engineering Science',
    J102: 'Element Japanese',
    K102: 'Element Korean 2',
    A102: 'Elementary Arabic 2',
    F102: 'Elementary French 2',
    I102: 'Elementary Italian 2',
    ELEM: 'Elementary Sequence',
    ELEV: 'Elevator Assist',
    TCHG: 'Elig for Tch Grnt Even if Fail',
    BPL: 'Eligible For BPL',
    ESPA: 'Eligible for Advanced Spanish',
    ELTS: 'Elite Scholars',
    EL01: 'Emergency Loan Book Check',
    EMPS: 'Employment Services',
    ENRT: 'En Route',
    FECF: 'Enchanced Chancellor\'s Fellow',
    ETHS: 'Energy Tech High School',
    ENHN: 'Engineering Honors',
    CORQ: 'English Co-remediation',
    ENGC: 'English Completed',
    ENG9: 'English for ESL Students',
    EWNA: 'English/Writing Not Available',
    V3: 'Enrolled ASAP Student',
    EMAD: 'Enrollment Advocacy',
    EMGN: 'Enrollment Management',
    ADDG: 'Entering Adult Degree',
    FRSH: 'Entering Freshman',
    GRAD: 'Entering Graduate Student',
    LSP: 'Entering Lehman Scholar',
    NDGG: 'Entering Nondegree GRAD',
    NDGU: 'Entering Nondegree UGRD',
    TRNS: 'Entering Transfer Student',
    RENS: 'Entering Transfer Student',
    EPID: 'Epidemiology',
    EKGS: 'Eva Kastan Grove Scholarship',
    TRCM: 'Evaluation Completed',
    TRIN: 'Evaluation Incomplete',
    TREV: 'Evaluation Processed',
    ENUR: 'Eve/Wkend Nursing',
    EVE: 'Evening',
    EWAC: 'Evening & Weekend Acct Student',
    EWBI: 'Evening & Weekend Biol Student',
    EXCD: 'Excelsior Ineligible',
    EXDR: 'Excl Doctoral Research Grant',
    EXFC: 'Excl Fellowship Competition',
    MEX: 'Exempted Math',
    REX: 'Exempted Reading',
    WEX: 'Exempted Writing',
    EXCA: 'Extra-curricular activities',
    F1: 'F1 Candidate',
    F1S: 'F1 Int\'l Students',
    OPT: 'F1 Status Student Post OPT',
    F1VI: 'F1 Visa',
    ETWS: 'FA Enrollment Track/Work Study',
    FAND: 'FA Non Degree',
    FEP2: 'FEP Denied/Declined',
    FWQS: 'FIQWS or ENGL 11000',
    FQUN: 'FQUAN',
    FS: 'FRESH START',
    FRLI: 'FRLI - French Literature',
    E017: 'FSPE 0017 Reading Placement',
    S100: 'FSPM 0100 Math Placement',
    S121: 'FSPM 0121 Placement',
    S131: 'FSPM 1031 Placement',
    FAPP: 'Fail Apts P&P(Tap-..Inelig-Cd7',
    FAPG: 'Fail Apts Prog(Sac-NOTAP-Cd5)',
    FAPU: 'Fail Apts Purs(Sac-NOTAP-Cd6)',
    FPRO: 'Fail P&P(Sac-Tap.Inelig-Cd3)',
    FPRG: 'Fail Prog(Sac-Tap-.Inelig-Cd1)',
    FPUR: 'Fail Pur(Sac-Tap.Inelig-Cd2)',
    STAP: 'Fail Stap(Sac-Tap.Inelig-Cd4)',
    FCRD: 'Failed CUNY Reading Exam',
    FCWR: 'Failed CUNY Writing Exam',
    FIMM: 'Failed Immersion',
    M1M2: 'Failed M1 and M2 Exams',
    FCOL: 'Family College',
    FDEM: 'Federal Employee',
    FSPP: 'Federal SAP Probation',
    SPH9: 'Fieldwork - INC Grade',
    FATM: 'Fin Aid Transcript Missing',
    FEAI: 'Fin Health Early Alert',
    M112: 'Fin Spelling, Num and Gloss 2',
    TRAN: 'Final Transcript Needed',
    FANK: 'Financial Aid Filed - Not OK',
    FAOP: 'Financial Aid Filed OK to Pay',
    FANF: 'Financial Aid Not Filed',
    FINP: 'Financial Aid Probation',
    '2G': 'Financial Aid Transc Missing',
    INFR: 'Finish In Four',
    FDNY: 'Fire Department',
    HSLG: 'Firello LaGuadria High School',
    FGEN: 'First Generation College Stdnt',
    FRST: 'First Program Student',
    FYEX: 'First Year Experience Students',
    '1L': 'First Year Law',
    FYLC: 'First Year Learning Community',
    FYMP: 'First Year Matters Program',
    MDM1: 'First Year Medical Student',
    FYS: 'First Year Seminars',
    BSU1: 'First Yr Sophie Davis Student',
    '1SKP': 'First term SEEK probation',
    FLXS: 'Flex Session Pilot Group',
    FORD: 'Ford Colloquium',
    FBMA: 'Foreign Adv., not WR/RE exempt',
    FLEX: 'Foreign Language Exempt',
    FOHH: 'Forest Hills Hospital Program',
    TEE2: 'Formal Admission to TE Program',
    CK: 'Former CD Student',
    FTHP: 'Fort Hamilton Program',
    FTTP: 'Fort Totten Program',
    FOSY: 'Foster Care Initiative',
    AFCI: 'Foster Care Initiative Youth',
    BMFS: 'Foundation Scholar',
    MDM4: 'Fourth Year Medical Student',
    F105: 'French 105',
    F106: 'French 106',
    F121: 'French 121',
    F160: 'French 160',
    F204: 'French 204',
    F210: 'French 210',
    F300: 'French 300',
    XFRN: 'French Exchange Program',
    FRN1: 'French Placement 101',
    FRN2: 'French Placement 102',
    BCFS: 'Fresh Start Participant',
    FRAC: 'Freshman Academy',
    FRAB: 'Freshman Block',
    FCFC: 'Freshman College',
    FRDF: 'Freshman Deferral',
    HNRS: 'Freshman Lib Arts Core Honors',
    FRRG: 'Freshman Registered',
    FRRA: 'Freshman Regular Admits',
    FSSP: 'Freshman Scholars Program',
    SCRT: 'Freshman Selected for CART',
    SPCI: 'Freshman Selected for Pre-Coll',
    FYI: 'Freshman Year Initiative',
    FSHB: 'Freshmen Block',
    FRYI: 'Freshmen Year Initiative',
    FTNW: 'Future Now',
    HOUS: 'GC Residence Leasing',
    GED: 'GED  Diploma',
    GERM: 'GERM',
    GK: 'GOLDEN KEY',
    GPS: 'GPS Program',
    G150: 'GRAD Cred Attm > 150% of Pgm',
    GLIM: 'GRAD Cred Earned < Limit',
    GRCT: 'GRANT/CONTRACT',
    GGRE: 'GRE Scores Missing',
    GSLD: 'GSL Default',
    GSL: 'GSL Default',
    '2A': 'GSL Default',
    GFP: 'Gates Foundation Participant',
    GWP: 'Gateway Program',
    GEAR: 'Gear-Up',
    GEL0: 'GenEd Lang Required: 101 & 102',
    GEL1: 'GenEd Lang Required: 102 Only',
    GEL2: 'GenEd Language Exempt',
    GADM: 'General Admission',
    GENS: 'General Scholarships',
    GER1: 'German Placement 101',
    GER2: 'German Placement 102',
    GIFT: 'Gifted Extension',
    GBNY: 'Give Back New York',
    GLSC: 'Global Scholar',
    GSEL: 'Global Student English Lang',
    GSGA: 'Global Student General Adm',
    GSSP: 'Global Student Success Program',
    KEYS: 'Golden Key',
    GOLD: 'Golden Key Society',
    AS1: 'Good Academic Standing',
    SEAM: 'Grad Ed-Sci In Seamless Way',
    GTSP: 'Grad Education Program',
    HLTH: 'Grad H&Ntr-Staying Healthy NYC',
    GMAT: 'Grad Matric',
    GMAP: 'Grad Matric/Prob Bar After 9Cr',
    GBRL: 'Grad Nursing Bronx Lebanon',
    ENVR: 'Grad Off-Campus Envir Sci Prog',
    GNSC: 'Grad Off-Campus Gen Sci Prog',
    FRNT: 'Grad Program - New Frontiers',
    PROA: 'Grad Provisionl Accept Student',
    RESP: 'Grad Research - STEM',
    GCRJ: 'Graduate CRJ Condition',
    FGRC: 'Graduate Center Fellowship',
    GRDC: 'Graduate Center-Lehman Home',
    GCHC: 'Graduate Change of Curriculum',
    GR12: 'Graduate Condition 12 Cr B',
    GF: 'Graduate Fellows',
    GMPA: 'Graduate MPA Condition',
    MTHU: 'Graduate Math Up Program',
    GDND: 'Graduate Non Degree',
    MATH: 'Graduate Off-Campus Math Prog',
    NYAN: 'Graduate Program - Nyana',
    GSTA: 'Graduate STAT Missing',
    ADPG: 'Graduate from the ADP Program',
    GHNR: 'Graduated Cuny Honors',
    GRSI: 'Graduation Success Initiative',
    RSCH: 'Grant/Contract Resch Foundtn',
    CNST: 'Grant/Contract-Const Fund',
    GFOS: 'Grduate Forensic Science',
    GRHN: 'Grove Honor\'s Program',
    GUTT: 'Guttman Transfer Scholarship',
    ALLH: 'H-S studs-Cnow,High,Jump,TWHS',
    HEBR: 'HEBR',
    HERO: 'HERO High School Student',
    HETS: 'HETS Student',
    HS: 'HONORS SOCIAL SCIENCE',
    CCAP: 'HS Advancement Program',
    HSA: 'HSA Student',
    HSA2: 'HSA Student - Conditional',
    HSA1: 'HSA Student - Invited',
    HSA3: 'HSA Student- Invitation Accept',
    HSIB: 'HSI BCC Transfer',
    HSFR: 'HSI Freshman',
    HSIH: 'HSI HCC Transfer',
    HSJR: 'HSI Junior',
    HSI: 'HSI Lehman Freshmen',
    HSSR: 'HSI Senior',
    HSSO: 'HSI Sophomore',
    Y105: 'Haitian Creole Heritage',
    FHSF: 'Half Science Fellowship',
    HRCF: 'Harcourt Fnd Scholars',
    AHSP: 'Harcourt Scholars Program',
    HARN: 'Harnisch Scholar',
    NURS: 'Has Nursing Medical',
    CHH: 'Health And Hospital Corp',
    HCWP: 'Health Care Workers Program',
    HEWC: 'Health Edu Works Advanced Cert',
    CDHT: 'Health Info Technology',
    HINT: 'Health Intent',
    HSCI: 'Health Sci Medical on File',
    HBID: 'HealthBridge In-Depth Year',
    HFM2: 'High Fail M2 Exam',
    HFRA: 'High Fail Reading Exam',
    HFWR: 'High Fail Writing Exam',
    HESL: 'High Placement ESL Student',
    DIPL: 'High School Diploma Recv\'d',
    HSE: 'High School Enrichment Student',
    HSM: 'High School Midwood Program',
    HIGH: 'High School Student',
    HS1S: 'High School Students',
    HSV: 'High School Virtual Core',
    HSWH: 'High School Wave Hill Program',
    HFM1: 'High-Fail M1 Exam',
    '244Z': 'Higher Rate For Xfer',
    CHHN: 'Hlth & Hospital Corp:Pre-Nurs',
    HOME: 'Home Care Super Cert Prg',
    HCWK: 'Home Care Worker',
    HC: 'Honors College KBCC',
    HONR: 'Honors Program',
    HON: 'Honors Program - HostosCC',
    HPNS: 'Honors Program, No Scholarship',
    HONS: 'Honors Scholar',
    PKTH: 'Honors Society - LaGuardiaCC',
    HREG: 'Honors Student (Scholars)',
    HLA: 'Hostos Lincoln Academy Student',
    DEV2: 'Hotspot Device',
    H001: 'House 1',
    H002: 'House 2',
    H003: 'House 3',
    H004: 'House 4',
    H005: 'House 5',
    H006: 'House 6',
    H007: 'House 7',
    H008: 'House 8',
    HHMI: 'Howard Hughes Med Inst',
    HUDN: 'Hudson St Program',
    FHUM: 'Humanities Fellowship',
    HCCS: 'Hunter Col Comm Sch',
    HCSH: 'Hunter Col Schlorship Studs',
    HCAP: 'Hunter College AANAPISI Proj',
    HCSA: 'Hunter College Study Abroad',
    PROM: 'Hunter Promise Transfer Schola',
    HBNS: 'Hunter-Bellevue Honors Scholar',
    I017: 'IBSIP 0017 Reading Placement',
    M100: 'IBSIP 0100 Math Placement',
    M015: 'Imm Prog Taken - Ok for MTH015',
    IMRS: 'Immersion Program',
    IMMP: 'Immigration Problem',
    '2F': 'Immigration Problem',
    IMMG: 'Immigration Problem',
    IMME: 'Immunization Exempt',
    IMMN: 'Immunized',
    SPH8: 'In Fieldwork & Capstone',
    ISGE: 'In Service Gen Ed Exemption',
    ISLE: 'In Service Law Enf - not NYC',
    MEDI: 'In from Medical',
    NARB: 'Ineligible for ARB 1001, 1002',
    NCHI: 'Ineligible for CHI 1001, 1002',
    NFRE: 'Ineligible for FRE 1001, 1002',
    NHEB: 'Ineligible for HEB 1001, 1002',
    NITL: 'Ineligible for ITL 1001, 1002',
    NJPN: 'Ineligible for JPN 1001, 1002',
    NPOR: 'Ineligible for POR 1001, 1002',
    NSPA: 'Ineligible for SPA 1001,  1002',
    EDIE: 'Initial Education Program',
    IMAJ: 'Intended Major',
    IMCL: 'Intended Major: Clinical Lab',
    IMGN: 'Intended Major: Generic Nrs',
    IMHE: 'Intended Major: Health Educ',
    IMOT: 'Intended Major: Occuptnl Therp',
    IMPE: 'Intended Major: Physical Educ',
    IMPA: 'Intended Major: Physician Asst',
    IMSW: 'Intended Major: Social Work',
    IMTE: 'Intended Major: Teacher Educ',
    INTI: 'Intensive Training Institute',
    TEIN: 'Interested in Teacher Ed',
    J103: 'Intermed Japanese 1',
    J104: 'Intermed Japanese 2',
    A103: 'Intermediate Arabic 1',
    A104: 'Intermediate Arabic 2',
    F103: 'Intermediate French 1',
    F104: 'Intermediate French 2',
    I103: 'Intermediate Italian',
    I104: 'Intermediate Italian 2',
    K103: 'Intermediate Korean 1',
    K104: 'Intermediate Korean 2',
    IATS: 'International Alliance The Emp',
    XINT: 'International Exchange Program',
    HSIN: 'International High School',
    F1VS: 'International Stdnts w/F1 Visa',
    XISE: 'International Student Exchange',
    ISE: 'International Student Exchange',
    ISS: 'International Student Service',
    INTR: 'Internship Program',
    IHSD: 'Interntl High School Diploma',
    EMAU: 'Interntl Student Employment',
    IEHK: 'Intl Exec Prog Hong Kong',
    IEIS: 'Intl Exec Prog Israel',
    IEPI: 'Intl Exec Prog Paris',
    IERS: 'Intl Exec Prog Russia',
    IESH: 'Intl Exec Prog Shanghai',
    IESZ: 'Intl Exec Prog Shenzhen',
    IESN: 'Intl Exec Prog Singapore',
    IETI: 'Intl Exec Prog Taipei',
    IEXP: 'Intl Executive Program',
    ISPT: 'Intl Stdnt Practical Training',
    FROR: 'Invited Freshman Orientation',
    TROR: 'Invited Transfer Orientation',
    INEC: 'Inwood Early College',
    I105: 'Italian 105',
    I106: 'Italian 106',
    I121: 'Italian 121',
    I204: 'Italian 204',
    I210: 'Italian 210',
    I300: 'Italian 300',
    ITLL: 'Italian Literature',
    ITA1: 'Italian Placement 101',
    ITA2: 'Italian Placement 102',
    JMIS: 'J Murphy Scholarship Recipient',
    JSKC: 'JSK Credible Messenger',
    JNSH: 'Janovic Scholars',
    J105: 'Jap for Heritage Spkrs',
    J150: 'Jap for Heritage Spkrs',
    JPN1: 'Japanese Placement 101',
    JPN2: 'Japanese Placement 102',
    JSC1: 'Jenny Hunter Scholar',
    JSCH: 'Jenny Hunter Scholar',
    JEP: 'Jewish Education Project',
    ECID: 'John Dewey ECI Students',
    HSJK: 'John F. Kennedy High School',
    HSCH: 'John Hopkins Scholar',
    JMSH: 'John P. McNulty - STEM',
    JPRG: 'Joint CUNY Programs',
    JUMP: 'Jump Start',
    JAAP: 'Justice Academy Applicant',
    JACF: 'Justice Academy Confirmed',
    KNSH: 'Kahan Scholarship',
    KTRN: 'Katrina Relief Student',
    KPSP: 'Kessler Presidential Scholar',
    K105: 'Korean Heritage',
    LAMP: 'L. Stokes All. Minority Partcp',
    L5: 'LC Career Tech Pilot Fall 07',
    L4: 'LC Career Tech Pilot Spring 07',
    LTTP: 'LEAP to Teach Program',
    LIEP: 'LIEP Candidates',
    LPN: 'LPN (CLINICAL PHASE)',
    LSFR: 'LSP Freshman',
    LSJR: 'LSP Junior',
    LSSR: 'LSP Senior',
    LSSO: 'LSP Sophomore',
    LEAP: 'Labor Ed & Advancement Project',
    LANH: 'Language Heritage Speaker',
    LANG: 'Language Placement',
    LATN: 'Latin/Greek Institute',
    L7: 'Learning Comm Career Fall 08',
    L9: 'Learning Comm Career Fall 09',
    C2: 'Learning Comm Career Fall 10',
    C4: 'Learning Comm Career Fall 11',
    L6: 'Learning Comm Career Spring 08',
    L8: 'Learning Comm Career Spring 09',
    C1: 'Learning Comm Career Spring 10',
    C3: 'Learning Comm Career Spring 11',
    C5: 'Learning Comm Career Spring 12',
    LCOD: 'Learning Comm Opening Doors',
    LCOM: 'Learning Communities',
    LCA: 'Learning Communities Advanced',
    L: 'Learning Communities Fall 03',
    L3: 'Learning Communities Fall 04',
    L2: 'Learning Communities Spring 04',
    LEHG: 'Lehman Grant',
    LEHM: 'Lehman Merit Scholarship',
    XJPN: 'Lehman at Hiroshima Program',
    L30C: 'Less than 30 Credits',
    LAA: 'Liberal Arts Academy',
    LIBR: 'Library Cafe',
    LICS: 'Lincoln Center Scholars',
    LIT: 'Literacy Program',
    LNWA: 'Loan Waivers',
    1707: 'Local 1707 Union',
    L802: 'Local 802 Program',
    SPHL: 'Lower Sophmore',
    HSLY: 'Lycee High School',
    MSSQ: 'M.S. Squared',
    FMGN: 'MAGNET Fellowship',
    MALE: 'MALES Program',
    MA14: 'MAT 14',
    MA40: 'MAT095 Placement',
    MA41: 'MAT095 Placement',
    MA42: 'MAT096 Placement',
    MA45: 'MAT117/119/123 Placement',
    MA44: 'MAT99 Placement',
    ZKNC: 'MBA - New Core',
    ZKNP: 'MBA - New Program',
    MCRS: 'MCRSH Specialization',
    MEPH: 'MEC Pipeline Healthcare',
    SPH5: 'MHC Concentration',
    MHFR: 'MHC Freshman',
    MHJR: 'MHC Junior',
    MHCA: 'MHC Provisional acceptance',
    MHSR: 'MHC Senior',
    MHSO: 'MHC Sophomore',
    MHCT: 'MHC transfer student at Lehman',
    MARP: 'MIND Alliance Res Prog - STEM',
    BMSF: 'MSF Student ',
    ALI: 'MSPIA Ed-L ALI Program',
    APP: 'MSPIA Ed-L APP',
    NL: 'MSPIA Ed-L New Leaders',
    SAM: 'MSPIA Ed-L SAM Citywide',
    SAMA: 'MSPIA Ed-L Single Model',
    XMPA: 'MSPIA Executive MPA Students',
    SPIN: 'MSPIA Internship Required',
    NUF: 'MSPIA NUF Program',
    MSW: 'MSW Scholar',
    M123: 'MTH 1023 Placement',
    M203: 'MTH 2003 Placement',
    M209: 'MTH 2009 Placement',
    M214: 'MTH 2140 Placement',
    M215: 'MTH 2150 Placement',
    M216: 'MTH 2160 Placement',
    M205: 'MTH 2205 Placement',
    M227: 'MTH 2207 Placement',
    M261: 'MTH 2610 Placement',
    NTMT: 'MTH Placement TBD',
    MHBS: 'Macaulay Honors Bridge Scholar',
    MHCO: 'Macaulay Offer',
    HSMD: 'Magen David Yeshiva',
    MJU: 'Major Updated',
    MHSS: 'Manhattan/Hunter Science Stud',
    MAPL: 'Maple T Test',
    MARC: 'Marc Program',
    MTAP: 'Maritime Tech Apprentice Prog',
    MTLA: 'Maritime Tech Liberal Arts',
    MVBH: 'Martin Van Buren H.S. Students',
    MYNT: 'Marymount Permit in',
    MA12: 'Math 12',
    M132: 'Math 132',
    M135: 'Math 135',
    M150: 'Math 15000: Math for Cnt World',
    M155: 'Math 155',
    M156: 'Math 156',
    M171: 'Math 171',
    M172: 'Math 172',
    M173: 'Math 17300: STATS See Advisor',
    M174: 'Math 174',
    M175: 'Math 175',
    M176: 'Math 176',
    M180: 'Math 18000: Education Majors',
    M185: 'Math 18500: Education Majors',
    M190: 'Math 19000: College Alg. & Tri',
    M195: 'Math 19500: Precalculus',
    M206: 'Math 206',
    MA51: 'Math 51',
    MA56: 'Math 56',
    MA71: 'Math 71: Basic Arith. & Algebr',
    MA80: 'Math 80: Fundamental Algebra',
    MCRT: 'Math Certified',
    MTDG: 'Math Diagnostics Test Needed',
    FMSE: 'Math Fellows - Secondary Ed',
    FMTH: 'Math Fellowship',
    MIMM: 'Math Immersion',
    MTHP: 'Math Pass To Math 101',
    MPL4: 'Math Placement Level 4',
    HSMS: 'Math Science & Engineering HS',
    MSPP: 'Math Science Prtnrshp - STEM',
    MSAL: 'Math Start Adult Learners',
    MSND: 'Math Start High Sch Initiative',
    MSTS: 'Math Start Student',
    MSUP: 'Math Supplemental Instruction',
    MSTR: 'Math/Science Teacher Residency',
    M201: 'Math20100:CALCfor STEM(notBIO)',
    MTCH: 'Mathematics Teachers',
    ARTX: 'Max Reached 30 Cr Studio Art',
    MYRS: 'Mayor\'s Scholarship',
    MEEN: 'Mechanical Engineering Science',
    MEPI: 'Medgar Pipeline Initiative',
    MEXM: 'Medical Exemption',
    RHEP: 'Mellisa Riggio Program',
    MELN: 'Mellon',
    BGHS: 'Member Beta Gamma Sigma',
    MCTR: 'Men\'s Center',
    MCMP: 'Men\'s Center Mentoring Program',
    MHRS: 'Mental Health Research - STEM',
    MS: 'Merit Scholarship For Freshman',
    TCHA: 'Met Tch Grant\'s 75%+ Admit Std',
    MSSC: 'Mid-School Science Program',
    MDYR: 'Mid-Year Program',
    T1TG: 'Mid/HS-NYC TCHRS Grant T1',
    T2TG: 'Mid/HS-NYC TCHRS Grant T2',
    T3TG: 'Mid/HS-NYC TCHRS Grant T3',
    T4TG: 'Mid/HS-NYC TCHRS Grant T4',
    MCHX: 'Middle Childhd Extensn Prgm',
    MDCL: 'Middle College Student',
    MDPS: 'Min Priority Prog - STEM',
    MBRP: 'Minority Bio Res Prog - STEM',
    MDAP: 'Minority D Abuse Prog - STEM',
    MRSP: 'Minority Res Summer Prgrm',
    SRGE: 'Minority STEM Program',
    MFSI: 'Miracle on Fulton Street',
    BMMW: 'MoneyWorks Ambassador',
    '60SR': 'More Than 60 Cr Cuny Sr Col',
    '60NS': 'More Than 60 Cr Non-Cuny Sr Co',
    '60CM': 'More Than 60 Crd Combo Sr Col',
    M30C: 'More than 30 Credits',
    MDSH: 'Mother\'s Day Scholarship',
    MTVN: 'Mt. Vernon School District',
    REP2: 'Multiple Repeats Policy',
    MUSE: 'Muse Honors Scholars',
    PAUD: 'Music Audition Passed',
    RAUD: 'Music Audition Repeat/Failed',
    PMUS: 'Music Placement Test Passed',
    MUDM: 'Must Declare Major',
    TRAP: 'Must Submit Official AP Scores',
    NSLD: 'NDSL (Perkins Loan) Default',
    NDSL: 'NDSL Default',
    '2B': 'NDSL Default',
    NSLX: 'NDSL Exit Interview Required',
    NDPN: 'NDSL Promissory Note Signed',
    '2D': 'NDSL Promissory Note Signed',
    FRS1: 'NEW FRESHMAN GROUP 1',
    FRS2: 'NEW FRESHMAN GROUP 2',
    FRS3: 'NEW FRESHMAN GROUP 3',
    FRS4: 'NEW FRESHMAN GROUP 4',
    FRS5: 'NEW FRESHMAN GROUP 5',
    FRS6: 'NEW FRESHMAN GROUP 6',
    TRS1: 'NEW TRANSFER GROUP 1',
    TRS2: 'NEW TRANSFER GROUP 2',
    TRS3: 'NEW TRANSFER GROUP 3',
    TRS4: 'NEW TRANSFER GROUP 4',
    TRS5: 'NEW TRANSFER GROUP 5',
    TRS6: 'NEW TRANSFER GROUP 6',
    NRAS: 'NRA Students',
    NSEP: 'NSE: In & pays QC',
    NSEW: 'NSE: In & pays home school',
    NSEH: 'NSE: Out & pays QC',
    NSEA: 'NSE: Out & pays host',
    NSOA: 'NSO Appointment',
    NYFP: 'NY Food Program Prospective',
    NYFR: 'NY Food Program Recipient',
    NX: 'NY Phone Company Employees',
    RNLC: 'NY State RN License',
    NYCS: 'NYC Council Scholarship',
    NYSC: 'NYC Dept Ed Scholarship Recip',
    NYMT: 'NYC Men Teach',
    NYPD: 'NYC Police Department',
    TER: 'NYC Teach R',
    NYCO: 'NYC Tech Collab Pre-College',
    WF1: 'NYC Workforce1 Program',
    PARB: 'NYCDOE Para Bridge Program',
    PDEX: 'NYPD Leadership Exec Cohort',
    PDLP: 'NYPD Leadership Program',
    EXCL: 'NYS Excelsior Scholar',
    NASH: 'Nash Honors Grad MBA',
    NSLS: 'National Soc of Ldrshp and Suc',
    XNSE: 'National Student Exchange',
    TRCT: 'Needs College Transcript',
    TRCD: 'Needs Course Descr or Syllabus',
    TRHS: 'Needs HS Transcript',
    NGHD: 'Neighborhood Scholarship',
    NSED: 'Neuroscience Ed',
    FNSC: 'Neuroscience Fellowship',
    NWAD: 'New & Upgraded By Admission',
    NRES: 'New Approved NYS Residents',
    M1: 'New Course-MAT M1',
    M1X1: 'New Course-MAT M1 OR X1',
    M2: 'New Course-MAT M2',
    M21X: 'New Course-MAT M2 OR X2',
    MR3: 'New Course-MAT MR3',
    NISS: 'New International Students',
    MUSC: 'New Music Students',
    WNTR: 'New ND or Readmit Winter Ses',
    N110: 'New Nursing 110',
    NSP: 'New Start Program',
    NWST: 'New Student',
    NSAR: 'New Student Adv and Reg',
    TSTD: 'New Student Tested',
    NEWV: 'New Vision Scholar',
    NVIS: 'New Visions',
    NVCP: 'New Visions Charter Schools',
    NYCC: 'New York City Corrections',
    NYST: 'New York State Law Enforcemnt',
    XNZL: 'New Zealand Exchange Program',
    PRB1: 'Newly-Placed Probation Student',
    CP11: 'Next Crse is CP 10 or CP 11',
    CP5: 'Next Crse is CP 500',
    M10: 'Next Crse is MAT 1000',
    M114: 'Next Crse is MAT 11, 14, or 91',
    M15: 'Next Crse is MAT 15',
    M16: 'Next Crse is MAT 16',
    M19: 'Next Crse is MAT 19A0',
    M20: 'Next Crse is MAT 2000',
    M22: 'Next Crse is MAT 2200',
    M478: 'Next Crse is MAT 4, 7, or 8',
    M56: 'Next Crse is MAT 5600',
    M913: 'Next Crse is MAT 9, or 13',
    M91: 'Next Crse is MAT or BIO 9100',
    EN12: 'Next English Crse is ENG 1200',
    ENAL: 'Next English Crse is ENG 1220',
    ENA0: 'Next English Crse is ENG 12A0',
    EN24: 'Next English Crse is ENG 2400',
    EN88: 'Next English Crse is ENG 88A6',
    EN4: 'Next English course is EN 04',
    EN91: 'Next English course is ENG 91',
    EN92: 'Next English course is ENG 92',
    EN93: 'Next English course is ENG 93',
    ENR: 'Next English course is ENG R',
    ENW: 'Next English course is ENG W',
    ES7: 'Next English course is ESL 07',
    ES9: 'Next English course is ESL 09',
    ES91: 'Next English course is ESL 91',
    EN82: 'Next English crse is ENG 8211',
    ES82: 'Next course is ESL 8201',
    NDEF: 'No Deferment',
    NOEN: 'No ENG-Placed Below Offer ENG',
    NMRE: 'No MTH-Placed Below Offer MTH',
    MX: 'No Math/Must pass Reading Exam',
    MT: 'No Math/See Testing Department',
    NTPO: 'No Tuition Payment Plan',
    NZMT: 'No Zicklin Mth Courses-3Repeat',
    NES2: 'NoESL',
    NDEG: 'Non Degree',
    NM: 'Non Degree Student',
    NOND: 'Non Degree Students',
    NMTR: 'Non Matriculated Student',
    NNEN: 'Non Native English Speak',
    NASP: 'Non-ASAP Student Permission',
    NCPO: 'Non-CUNY Outgoing Permit',
    NCBS: 'Non-Cuny Bc Study Abroad',
    SPHB: 'Non-Degree Stud - UPH Courses',
    NDST: 'Non-Degree Student (Regular)',
    NPSF: 'Non-Pub Sch Stdnt Fund By NYS',
    ALPQ: 'Non-STEM mathematics course',
    NPE: 'Non-proficient English',
    NPM: 'Non-proficient Math',
    NCHT: 'Not Committed To Hunter',
    NZK2: 'Not eligible - Zicklin Path 2',
    NZK3: 'Not eligible - Zicklin Path 3',
    NEAC: 'Not eligible for Accounting',
    NEFN: 'Not eligible for Finance',
    NYFS: 'Noyce Fellowship - STEM',
    NOYC: 'Noyce Scholars',
    NRS3: 'Nursing 300-Level',
    NRSG: 'Nursing Students-Downstate',
    NSIU: 'Nursing/SIUH',
    FNES: 'Nutr & Diet Prog inter Fee',
    NDIP: 'Nutrition & Diet Inter Pgm',
    OOPP: 'Office of Partnership Programs',
    ACE2: 'Official ACE Student',
    ASAP: 'Official ASAP Student',
    ASMS: 'Official ASMS Student',
    ACFR: 'Official Ace Student Freshman',
    ACJR: 'Official Ace Student Junior',
    ACSR: 'Official Ace Student Senior',
    ACSO: 'Official Ace Student Soph',
    FEP3: 'Official FEP Student',
    PTI4: 'Official PTI student',
    OLYM: 'Olympic Hopefuls',
    REST: 'On Prob Restrict Registration',
    EX12: 'One or Two Exams Failed',
    ONLN: 'Online',
    ONRN: 'Online Degree Opt for Nurse BS',
    ONIM: 'Online Student Immunized',
    D1: 'Opening Doors Fa 03',
    D3: 'Opening Doors Fall 04',
    D5: 'Opening Doors Fall 05',
    D7: 'Opening Doors Fall 06',
    D9: 'Opening Doors Fall 07',
    O2: 'Opening Doors Fall 08',
    O4: 'Opening Doors Fall 09',
    U1: 'Opening Doors Fall 10',
    U3: 'Opening Doors Fall 11',
    D2: 'Opening Doors Spring 04',
    D4: 'Opening Doors Spring 05',
    D6: 'Opening Doors Spring 06',
    D8: 'Opening Doors Spring 07',
    O1: 'Opening Doors Spring 08',
    O3: 'Opening Doors Spring 09',
    O5: 'Opening Doors Spring 10',
    U2: 'Opening Doors Spring 11',
    U4: 'Opening Doors Spring 12',
    OPIN: 'Opt in New General Education',
    RE47: 'Out Of Remedial Reading High',
    OUT2: 'Out in Two Program',
    MA46: 'Out of Remedial Math High',
    MA43: 'Out of Remedial Math Low',
    MPAS: 'Out of Remedial Mathematics',
    RPAS: 'Out of Remedial Reading',
    RE46: 'Out of Remedial Reading Low',
    WPAS: 'Out of Remedial Wrting',
    MEDL: 'Out on Medical',
    HSPT: 'P-Tech High School Program',
    PARX: 'PAR Excluded',
    PASS: 'PASS Center',
    PBIG: 'PBI Graduate Programs',
    PBIP: 'PBI PREP Program',
    PBIS: 'PBI Supplemental Instruction',
    MEDE: 'PED 190 Medical Eligible',
    PLLI: 'PELL Ineligible',
    PFLX: 'PFLX students',
    PITL: 'PIT Learning Community',
    PNRN: 'PN to RN Program',
    PTI0: 'PTI Denied/Declined',
    PTI2: 'PTI Eligible - Continuing Stud',
    PTI1: 'PTI Eligible - New Students',
    VETC: 'Paid To School (Vet)',
    VETS: 'Paid To Student (Vet)',
    '1GEN': 'Panther Partners',
    PARA: 'Paraprofessional',
    XPAR: 'Paris Exchange Program',
    PARI: 'Paris Exchange Program',
    PTAP: 'Part Time TAP',
    AEMC: 'Partnership - Einstein Med',
    MFMC: 'Partnership – Montefiore',
    MTSI: 'Partnership – Mt. Sanai',
    PECP: 'Partnership – Peace Corp',
    LAST: 'Passed Last',
    P_MA: 'Passed Math',
    P_M1: 'Passed Math Part 1',
    P_M2: 'Passed Math Part 2',
    P_RA: 'Passed Reading',
    P_WA: 'Passed Writing',
    P_WR: 'Passed Writing',
    WR52: 'Passed Writing, Failed Reading',
    P2PE: 'Pathway to Physical Education',
    PODV: 'Pathways ADVISED',
    PATH: 'Pathways Gen Ed',
    PTH: 'Pathways OPT-IN',
    POIN: 'Pathways OPT/IN',
    POUT: 'Pathways OPT/OUT',
    PWOP: 'Pathways Opt In',
    PTHH: 'Pathways-Hybrid STEM',
    PTHN: 'Pathways-Non STEM',
    PTHS: 'Pathways-STEM',
    PCPR: 'Peace Corps Prep',
    MTEE: 'Peer Mentee',
    MTOR: 'Peer Mentor',
    PHBS: 'Peking University HSBC Bus Sch',
    CO06: 'Pending AA or AS Degree',
    COAX: 'Pending AAS Degree',
    ENAA: 'Pending Enroute Associates',
    ISSP: 'Pending International Student',
    PMHC: 'Pending Macaulay Honors Colleg',
    TEAP: 'Pending Teachers Academy',
    PGTA: 'Pending Teachers Academy',
    '243F': 'Perkins Loan Student Selected',
    '243G': 'Perkins LoanStdn Requires Exit',
    EXIT: 'Perkins LoanStdn Requires Exit',
    PLFI: 'Perkins Receip Future Exit Int',
    PLSI: 'Perkins Stdnt Reqs Exit Int',
    RESD: 'Permanent Resd Pending',
    EMGR: 'Permission To Readmit per VP',
    PICS: 'Permit In Consortium Students',
    PI12: 'Permit In from BMCC',
    PI02: 'Permit In from Baruch College',
    PI08: 'Permit In from Bronx CC',
    PI05: 'Permit In from BrooklynCollege',
    PI20: 'Permit In from CSI',
    PI21: 'Permit In from CUNY Law',
    PI01: 'Permit In from City College',
    PI23: 'Permit In from Grad Center',
    PI17: 'Permit In from Guttman CC',
    PI14: 'Permit In from Hostos CC',
    PI03: 'Permit In from Hunter College',
    PI16: 'Permit In from John Jay',
    PI10: 'Permit In from Kingsborough CC',
    PI18: 'Permit In from LaGuardia CC',
    PI04: 'Permit In from Lehman College',
    PI19: 'Permit In from Medgar Evers',
    PI13: 'Permit In from NYCCT',
    PI06: 'Permit In from Queens College',
    PI09: 'Permit In from QueensboroughCC',
    PI25: 'Permit In from SOJ',
    PI33: 'Permit In from SPH',
    PI73: 'Permit In from SPS',
    PI15: 'Permit In from York College',
    PO12: 'Permit Out to BMCC',
    PO02: 'Permit Out to Baruch College',
    PO08: 'Permit Out to Bronx CC',
    PO05: 'Permit Out to Brooklyn College',
    PO20: 'Permit Out to CSI',
    PO21: 'Permit Out to CUNY Law',
    PO01: 'Permit Out to City College',
    PO23: 'Permit Out to Grad Center',
    PO14: 'Permit Out to HOSTOS CC',
    PO03: 'Permit Out to Hunter College',
    PO16: 'Permit Out to John Jay',
    PO10: 'Permit Out to Kingsborough C',
    PO18: 'Permit Out to LaGuardia CC',
    PO04: 'Permit Out to Lehman College',
    PO19: 'Permit Out to Medgar Evers',
    PO13: 'Permit Out to NYC Coll of Tech',
    PO06: 'Permit Out to Queens College',
    PO09: 'Permit Out to Queensborough CC',
    PO25: 'Permit Out to SOJ',
    PO33: 'Permit Out to SPH',
    PO73: 'Permit Out to SPS',
    PO15: 'Permit Out to York College',
    '2I': 'Permit Student',
    PETL: 'Petrie Grant Long',
    PETS: 'Petrie Grant Short',
    PHIB: 'Phi Beta Kappa',
    PHIC: 'Phi Chi Honor Society',
    PHIE: 'Phi Eta Sigma Honor Society',
    PTK: 'Phi Theta Kappa',
    PTKA: 'Phi Theta Kappa Award',
    PHIL: 'Philosophy Non Degree',
    PHYE: 'Physical Exam Docs Submitted',
    PTC1: 'Physical Therapy Cohort 1',
    PTC2: 'Physical Therapy Cohort 2',
    PTC3: 'Physical Therapy Cohort 3',
    BMPA: 'Physician\'s Assistant Program',
    PMBK: 'Pipeline My Brother\'s Keeper',
    CHIN: 'Placed CHI 101',
    POE: 'Port of Entry Program',
    POR1: 'Portuguese Placement 101',
    POR2: 'Portuguese Placement 102',
    TBPG: 'Possible Tb Program Candidate',
    PSTB: 'Post Baccalaureate',
    PBAC: 'Post Baccalaureate',
    PBAP: 'Post Baccalaureate - STEM',
    POST: 'Post Master\'s in Nursing Admin',
    PBHC: 'Post-Bacc Health Careers',
    TEPO: 'Potential Admiss to Teacher ED',
    COPP: '"Potential COPE Student    "',
    FEP0: 'Potential FEP Student',
    HONP: 'Potential Honors Scholar',
    PORA: 'Potential Remedial Failure',
    SCD1: 'Potential SEEK/CD',
    PSA: 'Potential Student Athlete',
    POAT: 'Power Of Attorney On File',
    PHSC: 'Pre Health Sci',
    PHON: 'Pre Honors',
    PRNS: 'Pre Nursing',
    ZKTP: 'Pre Zicklin Temporary Perm',
    PRCL: 'Pre-Clinical Nursing',
    PACD: 'Pre-College Academy',
    PREF: 'Pre-Freshmen',
    PREH: 'Pre-Health Professions',
    BOOT: 'Pre-Law Boot Camp Participant',
    PLAW: 'Pre-Law Program',
    PNUR: 'Pre-Nursing Students',
    PRNT: 'Pre-Nursing Transcripts',
    PHCD: 'Prehealth Careers Cert Dis',
    PHCP: 'Prehealth Careers Cert Prob',
    PREL: 'Prelude KbCC',
    P02: 'Prelude to Baruch Program',
    '243P': 'Prelude to Success',
    RACC: 'Prereq - Accounting (Missing)',
    RCMP: 'Prereq - Computers (Missing)',
    RECO: 'Prereq - Economics (Missing)',
    RSTA: 'Prereq - Statistics (Missing)',
    ARTS: 'President ARTS Society',
    PP: 'President\'s Prep',
    PLSH: 'Presidential Leadership',
    BMPS: 'Presidential Scholar',
    PRES: 'Presidential Scholars',
    PSEK: 'Previous Seek',
    E110: 'Prin Effective Writing 1',
    E120: 'Prin Effective Writing 2',
    PDEG: 'Prior Degree',
    PDAA: 'Prior Degree AA',
    PDAX: 'Prior Degree AAS',
    PDAS: 'Prior Degree AS',
    PDBA: 'Prior Degree BA',
    PDBS: 'Prior Degree BS',
    PDMA: 'Prior Degree MA',
    PDMS: 'Prior Degree MS',
    PDME: 'Prior Degree MS Ed',
    PDGD: 'Prior Graduate Degree',
    NZK0: 'Prior to 1219 No Zicklin',
    ZK00: 'Prior to Fall 2021 Admissions',
    PREG: 'Priority Registration',
    PRTY: 'Priority Registration',
    PRFR: 'Priority Registration Freshman',
    PRJR: 'Priority Registration Junior',
    PRSR: 'Priority Registration Senior',
    PRSO: 'Priority Registration Soph',
    PBSP: 'Pro Bono Scholars Program',
    PAR0: 'Proactive Adv & Reg',
    PAR1: 'Proactively Reg',
    PROB: 'Probation',
    PR03: 'Probation - 3.0 Credit Limit',
    PR06: 'Probation - 6.0 Credit Limit',
    PR09: 'Probation - 9.0 Credit Limit',
    PRRC: 'Probation Readmit Contract',
    EDPE: 'Professional Education Program',
    PEXC: 'Project EXCEL Student',
    ADPR: 'Provost\'s Admissions Committee',
    FPRV: 'Provost’s Enhancement Fellow',
    GPSY: 'Psych Graduate Research Mthd',
    PHNT: 'Public Health Nutrition',
    PUHN: 'Public Health Nutrition',
    QNS4: 'QC in 4',
    QCNA: 'QCN4 Advance Standing',
    RISE: 'Queens College Fund',
    QS: 'Queens Scholar',
    QRES: 'Questionable Residency',
    RUSS: 'RUSS',
    REAL: 'Re-Allocate',
    REEN: 'Re-entry Student',
    ESLX: 'Reached ESL Limit',
    RCTV: 'Reactivate',
    RADM: 'Readmit',
    RADF: 'Readmit - Forgiveness',
    RADG: 'Readmit Graduate Student',
    RDMT: 'Readmit Student',
    RADR: 'Readmitted by Registrar',
    RAPB: 'Readmitted on Probation',
    RWMA: 'Real World Math Program',
    XREC: 'Reciprocal for Exchange Prog',
    RECD: 'Record Created on Database',
    TERY: 'Recycle groups for batch updat',
    REFL: 'Referral Letter',
    RFND: 'Refund Balance',
    RCMN: 'Registrar - Commencement',
    GENR: 'Registrar Office Processes',
    HESN: 'Registrar Review Denied',
    PAR3: 'Registration Cancelled',
    R100: 'Registration1 Grp 30 Plus Cr',
    R2LW: 'Registration2 Grp L30 0-24 Cr',
    R2HG: 'Registration2 Grp L30 24-29 Cr',
    R3ES: 'Registration3 Group for BS ES',
    R3BS: 'Registration3 Group for BS RE',
    R4ND: 'Registration4 Non-Degrees',
    ZK03: 'Regular Admission to Zicklin',
    REHS: 'Rehabilitation Services',
    EXFD: 'Reject Failed 3 or 4 Exams',
    YCRA: 'Remedial Fail & Academic Dism',
    REAB: 'Remedial Fail - Above 2.0',
    REBE: 'Remedial Fail - Below 2.0',
    REM: 'Remedial Group',
    MATX: 'Remedial Math Exempt',
    REME: 'Remedial Workshop',
    REMX: 'Remediation in Excess of 24hrs',
    RUBS: 'Renmin University Bus Schl',
    RPFM: 'Repeat For Mastery',
    MST1: 'Req MS Students MAT95/96',
    RA: 'Res Doc-Located in Adm Folder',
    REAP: 'Research Enrichment Act Prog',
    RESH: 'Residence Hall',
    BRH: 'Residence Hall',
    RESL: 'Residence Life',
    RLAP: 'Resident Leadership Acad Prog',
    RICS: 'Rikers Island to CUNY Student',
    RTS: 'Road to Success Student',
    RNSP: 'Robert Noyce Scholars',
    RWIS: 'Robert Wagner Institute Stdnt',
    RHFG: 'Robin Hood Foundation Grant',
    RHHM: 'Robin Hood at Home in College',
    ROCK: 'Rockland Teacher\'s Center',
    RSSH: 'Roosevelt Scholars',
    RUS1: 'Russian Placement 101',
    RUS2: 'Russian Placement 102',
    SWSH: 'S&W Scholar',
    RSEW: 'SCI Resource For Women - STEM',
    SEKA: 'SEEK Applicants',
    '3B': 'SEEK Applicants',
    SEKD: 'SEEK Denied admission',
    SEKC: 'SEEK Funds Cancelled',
    SEKU: 'SEEK Funds Used Up',
    SEKG: 'SEEK Graduated',
    SEKH: 'SEEK History',
    XAEP: 'SEEK Honors Society',
    SEKI: 'SEEK Incoming UAPC',
    SEKN: 'SEEK Ineligible for SEEK FA',
    SEKO: 'SEEK Opt Out',
    SEKR: 'SEEK Rejected',
    SEKV: 'SEEK Review SEEK Aid',
    SEKS: 'SEEK Scholar Appointment',
    SEEK: 'SEEK Student',
    SKSA: 'SEEK Summer Appoint',
    SKSE: 'SEEK Summer Enrolled',
    SKSU: 'SEEK Summer Program',
    SEKT: 'SEEK Test Results',
    '2E': 'SEP Not Signed',
    SOE: 'SOE Special Cert',
    SPN3: 'SPA 201 or Above',
    SPLI: 'SPLI -  Spanish Literature',
    SSWR: 'SSW Scholarship Recipient',
    SSRS: 'STEAM Schol or Res Stipend',
    STEM: 'STEM',
    SINT: 'STEM Intent',
    SYIS: 'SYI Student',
    SAGE: 'Sage Honors Scholars',
    SST: 'Saturday Success Track',
    SCPS: 'Schl Psych Pre-200709 Cohort',
    SCHP: 'Scholar Program',
    SCHL: 'Scholars Program',
    GLSS: 'Scholars of GCC GLASS',
    LSMP: 'Scholars of GCC LSAMP Program',
    SWFS: 'Scholarship & Welfare Fund Sch',
    DIVS: 'Scholarship for Diversity',
    EGRU: 'School of ENGR UGRD student',
    MITC: 'School of Ed Math City Program',
    EDUS: 'School of Ed UGRD student',
    SCDU: 'Science Division UGRD student',
    FSCI: 'Science Fellowship',
    SMTF: 'Science Master Teaching Fellow',
    SURG: 'Science UGRD Research Gateway',
    BSU2: 'Sec Yr Sophie Davies Student',
    SCPP: 'Second Chance Pell Program',
    SDEG: 'Second Deg Student - No ACC',
    SDGO: 'Second Degree Student - Old',
    '2SEM': 'Second Semester',
    SSFC: 'Second Semester Freshman Comm',
    '2L': 'Second Year Law',
    MDM2: 'Second Year Medical Student',
    '2SKP': 'Second term SEEK probation',
    SECQ: 'Secondary Sequence',
    SKHN: 'Seek Honors',
    SNSO: 'Selected for NSO Orientation',
    SSPS: 'Selective Serv Prob-Not Signed',
    SSVP: 'Selective Service Problem',
    '2H': 'Selective Service Problem',
    SRL: 'Self Regulated Learning',
    SCIG: 'Senior Citizen Student',
    SCIT: 'Senior Citizen Student',
    SNR: 'Senior Citizens - Not Used',
    COP1: 'Serviced COPE Student',
    SISU: 'Shanghai Intl Studies Univ Bus',
    SIGX: 'Sigma Xi',
    SLCX: 'Silcox Scholarship',
    SFL3: 'Sillcox Foundation ASAP',
    SFL1: 'Sillcox Foundation CLIP',
    SFL2: 'Sillcox Foundation CUNYStart',
    SPST: 'Single Parent Students',
    SBLM: 'Solomon Bluhm Scholars Program',
    SOPH: 'SophoMORE Academy Participant',
    S105: 'Spanish 105',
    S106: 'Spanish 106',
    S107: 'Spanish 107',
    S108: 'Spanish 108',
    S204: 'Spanish 204',
    S207: 'Spanish 207',
    S210: 'Spanish 210',
    S211: 'Spanish 211',
    S300: 'Spanish 300',
    SPA1: 'Spanish Level 1',
    SPA2: 'Spanish Level 2',
    SPA3: 'Spanish Level 3',
    SPA4: 'Spanish Level 4',
    SPA5: 'Spanish Level 5',
    SPA6: 'Spanish Level 6',
    SPA7: 'Spanish Level 7',
    SPN1: 'Spanish Placement 101',
    SPN2: 'Spanish Placement 102',
    SP11: 'Spanish Placement 111',
    SP12: 'Spanish Placement 112',
    SEAL: 'Spec Educ Alternate',
    SPCH: 'Spech Placement Required',
    SPBA: 'Special Baccalaureate',
    SPED: 'Special Educ',
    SHON: 'Special Honors',
    SPND: 'Special NonDegree Grad Prgm',
    NONS: 'Special NonDegree Grad Program',
    SPCR: 'Special Registration',
    SPEC: 'Special Transfer Student',
    PACE: 'St. Albans Veterans (Pace)',
    STGR: 'St. George',
    WVA: 'Sta-Waiver-Cd=A',
    WVP: 'Sta-Waiver-Cd=P',
    WVS: 'Sta-Waiver-Cd=S',
    WVW: 'Sta-Waiver-Cd=W',
    WVB: 'Sta-Wavier-Cd=B',
    HSS: 'Star Program',
    STRF: 'StarFish Student Population',
    SSSP: 'Stdnt Support Services Program',
    S4SP: 'Strive for Success',
    SPH7: 'Stud W/3.8 Or Above GPA',
    SPH1: 'Stud W/An Official LOA',
    SPH2: 'Stud W/Inc Grd - Sem Info',
    STAM: 'Student Ambassadors',
    A1: 'Student Athlete - Fall 07',
    A3: 'Student Athlete - Fall 08',
    A0: 'Student Athlete - Spring 07',
    A2: 'Student Athlete - Spring 08',
    ATSI: 'Student Athlete Initiative',
    SCC: 'Student Concern Committee',
    '243C': 'Student DS TAP',
    STZZ: 'Student Declines Admission',
    SGOV: 'Student Government',
    SGM: 'Student Government Member',
    STGV: 'Student Government Members',
    SLSP: 'Student Leadership Group',
    LIFE: 'Student Life',
    STLF: 'Student Life',
    STMP: 'Student Receiving Food Stamps',
    STSU: 'Student Success',
    SSCU: 'Student Success Coaching Unit',
    SS4U: 'Student Success For You',
    CTLP: 'Student Success Initiative',
    STSS: 'Student Support Servc Program',
    STMD: 'Student Teaching Med on File',
    STCH: 'Student Teaching or Practicum',
    ADNL: 'Students Admitted notAttending',
    SOP: 'Students of Promise Program',
    SOS: 'Students of Substance',
    ESLP: 'Students placed in ESL',
    QMTH: 'Students that are Math Exempt',
    STBD: 'Study Abroad',
    STBF: 'Study Abroad Fall',
    STBS: 'Study Abroad Summer',
    STGP: 'Study Group',
    HESR: 'Subject to Registrar Review',
    SBEP: 'Subsidized Bil Ext Program',
    SNET: 'Success Network',
    SACD: 'Sucess Academy',
    SAGR: 'Summer Academy Graduate',
    SMED: 'Summer Edge',
    SUME: 'Summer\'s Edge',
    SPPE: 'Supp Cert for Phys Ed',
    SUSP: 'Suspension',
    SWUF: 'Swufe Student',
    TPEL: 'TAP Eligible Term',
    TAPP: 'TAP Progress & Pursuit Waiver',
    TAPW: 'TAP Progress Waiver',
    TPW: 'TAP Progress Waiver',
    TAPR: 'TAP Removed - 12 Cred Lim',
    TCHR: 'TEACH Grant - Ret/Curr Teacher',
    TELL: 'TELL Student',
    ALPH: 'TESOL Alpha Project',
    T2K: 'TIME 2000 PGM',
    TLQP: 'TLQP Education Program',
    TOC: 'TOC Program',
    TOPS: 'TOPS Program',
    TRNU: 'TR Stdnts Interested in NURS',
    TCIT: 'TRIO Careers in Teaching',
    TPTS: 'TRIO Pathways to Success',
    TAS1: 'TRN Achievement Scholar Tier 1',
    TAS2: 'TRN Achievement Scholar Tier 2',
    TAS3: 'TRN Achievement Scholar Tier 3',
    TAS4: 'TRN Achievement Scholar Tier 4',
    TSAE: 'TSA Employee',
    TSLD: 'TSSLD Certification',
    MW35: 'Take MTH 123 only w/MTH 35',
    TCHX: 'Tch Grant Qualfd via 3.25 GPA',
    TCHQ: 'Tch Grant Qulfd via natnl test',
    T325: 'Teach (3.25 GPA)',
    '75HG': 'Teach (75% or Higher)',
    THAA: 'Teach Honors Acad Adm at CSI',
    TYOU: 'Teach You',
    TEAM: 'Teach for America',
    AMER: 'Teach for America',
    TASE: 'Teach for America Special Ed',
    TOSD: 'Teach on Sabbatical/Discovery',
    TEAC: 'Teacher Academy',
    SCIP: 'Teacher Ed Adv Sci Prep',
    TCOC: 'Teacher Opportunity Corps',
    TEOP: 'Teacher Opportunity Program',
    TSND: 'Teacher on Sabbatical Non Degr',
    TSAO: 'Teachers off Sabbatical',
    TSAB: 'Teachers on Sabbatical',
    TECH: 'Technology Prep Program',
    THSP: 'Tematic Studies Program',
    TMMR: 'Temporary Medical Restirction',
    TRIO: 'The Federal TRIO Programs',
    GPSP: 'The GPS Program',
    TRPP: 'Third Party Payment',
    '3L': 'Third Year Law',
    MDM3: 'Third Year Medical Student',
    BSU3: 'Third Yr Sophie Davies Student',
    T105: 'Tibetan Heritage',
    '24TA': 'Title IV Academic Standing',
    '24T4': 'Title IV Decertified',
    T4OP: 'Title IV Overpayment',
    '2C': 'Title IV Overpayment',
    TIVO: 'Title IV Overpayment',
    T4UP: 'Title IV Unsat Program Pursuit',
    '24TB': 'Title IV Unsatisf Prog Pursuit',
    TILV: 'Title V',
    TL5: 'Title V Participant',
    STEL: 'Title VI - Stellar',
    TITL: 'Title VII - Intell',
    TMTP: 'Too Many TAP Points',
    DORM: 'Towers Residents',
    TWHS: 'Townsend Harris HS Senior',
    '280T': 'Transf Studs Advised in SH 280',
    TPAR: 'Transfer - Attended CUNY Only',
    TSBA: 'Transfer 2 BA',
    TASA: 'Transfer Achieve Schlrsh Audit',
    TRPB: 'Transfer Admitted on Probation',
    '24GV': 'Transfer Courses 3.0 GPA & up',
    TRCI: 'Transfer Credit Ineligible',
    TRDA: 'Transfer Direct Admit',
    TREC: 'Transfer Evaluation Complete',
    HS48: 'Transfer HS Diploma or 48 Cr.',
    TPRE: 'Transfer Pre-acceptance',
    TSP: 'Transfer Scholars Program',
    NOTC: 'Transfer Stdnts WO Transfer Cr',
    TWRK: 'Transit Workers',
    TACS: 'Transition Academy Student',
    TENG: 'Transitional English',
    T231: 'Translation 231',
    T232: 'Translation 232',
    T233: 'Translation 233',
    DH: 'Travel/Hospitality Link Sp07',
    ACTN: 'Trio Non Accessibility',
    SN: 'Trio Non Accessibility',
    V1: 'Triple Exempt ASAP Student',
    FTUT: 'Tuition Fellowship',
    AGNT: 'Tuition Pay - Third Party',
    TUTO: 'Tutorial No Business Courses',
    UFTP: 'UFT NYC Private School Tchrs',
    UTPS: 'UFT NYC Public School Teachers',
    UGAP: 'UG Reallocate In On Appeal',
    U150: 'UGRD Cred Attm > 150% of Pgm',
    UNE: 'UN or Embassy Staff/Dependent',
    GRPB: 'URBST, SOC, PSYCH, BIOL FrSo',
    GRPA: 'URBST, SOC, PSYCH, BIOL JrSr',
    USIP: 'USIP Program',
    UNAL: 'Un-Allocated',
    '100B': 'Under 100 Balance',
    ULPC: 'Undergrad Loans Packaging CTL',
    ULPE: 'Undergrad Loans Packaging EXP',
    ULP2: 'Undergrad Loans Packaging EXP2',
    UGND: 'Undergrad Non Degree',
    GTRN: 'Undergrad Transcript Missing',
    UGRE: 'Undergraduate Reallocate Out',
    UNDA: 'Undocumented Alien',
    UDOC: 'Undocumented Aliens',
    USBP: 'Unified Science Bridge Program',
    1199: 'Union Healthcare Workers',
    UNIN: 'Union Semester Student',
    UFED: 'United Federation Of Teachers',
    USTB: 'Univ Science & Tech Bei Jing',
    HSUH: 'University Heights High School',
    REGS: 'Updated By Registrar',
    SPHU: 'Upper Sophomore',
    URBA: 'Urban Academy',
    UE99: 'Urban Experience Cert/1199',
    UMLA: 'Urban Male Leadership Academy',
    URBN: 'Urban Systemic Initiative',
    URES: 'Urban Teacher Residency',
    UTRF: 'Urban Teacher Residency Fellow',
    U105: 'Uzbek Heritage',
    VASC: 'VA Benefit SpouseorChld of Vet',
    VTUT: 'VA In-State Tuition',
    VAST: 'VA Student',
    VSSC: 'VAL/SAL Schoarship',
    VSSP: 'VAL/SAL Schoarship - Partial',
    ATHL: 'Varsity Athlete',
    TX2: 'Verizon Next Step',
    VZNS: 'Verizon Next Step Program',
    VSCH: 'Verizon Scholars - STEM',
    VERZ: 'Verrazano Honors Program',
    VEFR: 'Veteran Freshman',
    VAIS: 'Veteran Instate Tuition',
    VEJR: 'Veteran Junior',
    VETO: 'Veteran Other',
    VESR: 'Veteran Senior',
    VESO: 'Veteran Sophomore',
    VETT: 'Veteran Tuition',
    VADV: 'Virtual Advisement and Reg',
    H1H4: 'Visa Holder',
    VISF: 'Visiting Non-Deg Fall Sess',
    VISS: 'Visiting Non-Deg Spring Sess',
    VISW: 'Visiting Non-Deg Winter Sess',
    VISP: 'Visiting Stdnt (nonCUNY) PostM',
    VIS: 'Visiting Student (non CUNY)',
    VOTB: 'Vote at Bedford',
    VOTC: 'Vote at Carroll',
    VOPM: 'Voucher Permit',
    ZKWP: 'WSAS Maj with Zicklin Perm',
    APPF: 'Waive App Fee',
    WAIV: 'Waive Application Fee',
    APPW: 'Waive Application Fee',
    CNWV: 'Waiver College Now',
    WAV1: 'Wave 1 for Tier 1',
    WAV2: 'Wave 2 for Tier 2',
    WAV3: 'Wave 3 for Tier 3',
    WAV4: 'Wave 4 for Tier 4',
    WKND: 'Weekend College',
    WCRS: 'Weekend Courses',
    WCMS: 'Weill Cornell Med Sch Perm In',
    WSPT: 'West Point Program',
    WHSE: 'Westinghouse',
    WINT: 'Winter Session Paid',
    MST3: 'With MST Indicator, Not in MAT',
    WRED: 'Worker Education',
    WINR: 'Writing Intensive Not Required',
    WCRT: 'Writing/Learning Center',
    XRAY: 'X-RAY (CLINICAL PHASE)',
    YLWH: 'Yalow Honors Scholars',
    YRUP: 'YearUp Program',
    ZVID: 'Yeshiva Zvi Dov R Pace HS Prg',
    YESH: 'Yeshivat Shaare Torah',
    YKTA: 'Yonkers Teachers Academy',
    ROTC: 'York College ROTC Program',
    YSCH: 'York College Scholar',
    YESS: 'York Enrichment Program',
    YOSC: 'Youth Studies Certificate',
    ZAPT: 'ZSB Accelerated Part Time Prog',
    MBFT: 'Zicklin Full-Time MBA',
    ZHON: 'Zicklin Honors Minor',
    NOTZ: 'Zicklin Ineligible',
    ZPTF: 'Zicklin Part Time Flex',
    ZK4P: 'Zicklin Temp Perm 4000 level',
    ZK4L: 'Zicklin eligible 4000 level',
    ZUAP: 'Zurich Apprenticeship Program',
    ELRN: 'eLearning',
};

export const parseStudentGroupDescription = (instutition: string, groupName: string): string => {
    const key = `${instutition}_${groupName}`;
    return studentGroups[key] || '';
};

export const parseStudentGroupDescrptionWithoutInstitution = (groupName: string): string => {
    return uniqueGroupsWithoutInstitution[groupName] || '';
};
