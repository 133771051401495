import tw from 'twin.macro';
import { parseCourseShortName } from '@/api/transformers';
import { Button, Modal } from '@/components/common';
import React, { useEffect, useState } from 'react';
import { useStoreActions } from '@/store';
import { SelectedCourse } from '@/api/types';
import { secondChanceOfferVar } from '@/api/graph';

interface Props {
    isVisible: boolean;
    secondChanceOffer: SelectedCourse | null;
    onAccept: () => void;
    onDecline: () => void;
}

export default ({ isVisible, secondChanceOffer, onAccept, onDecline }: Props) => {
    const setReplacementFiltersForConflictingSchedule = useStoreActions(actions => actions.search.setReplacementFiltersForConflictingSchedule);

    const [ showModal, setShowModal ] = useState<boolean>(isVisible);

    const handleClose = () => {
        setShowModal(false);
    };

    const handleDecline = () => {
        handleClose();
        secondChanceOfferVar(null);
        onDecline();
    };

    useEffect(() => {
        setShowModal(isVisible);
    }, [ isVisible ]);

    return (
        <Modal visible={showModal} onDismissed={handleClose}>
            {
                secondChanceOffer ?
                    <div>
                        <p>Would you like to choose a different section of {parseCourseShortName(secondChanceOffer)}?</p>

                        <div css={tw`mt-3 text-right`}>
                            <Button
                                onClick={() => {
                                    setReplacementFiltersForConflictingSchedule(secondChanceOffer);
                                    handleClose();
                                    onAccept();
                                }}
                            >
                                Yes
                            </Button>
                            <Button
                                css={tw`mx-4 bg-gray-200 text-gray-900`}
                                onClick={() => {
                                    handleClose();
                                    handleDecline();
                                }}
                            >
                                No
                            </Button>
                        </div>
                    </div>
                    : null
            }
        </Modal>
    );

};
