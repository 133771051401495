import React, { useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components/macro';
import { Button, Spinner } from '@/components/common';
import { formatISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useGetSimResults, useUpdateSimResults } from '@/api/simulation/SimulationAPI';
import { Redirect } from 'react-router';
import { activeTerm } from '@/constants/terms';

const ContainerStyle = styled.div`
    ${tw`w-full h-full min-h-screen`}
    
    ${tw`pt-4 pb-4 px-4 md:px-12 max-w-screen-xl`}
`;

export default () => {
    const history = useHistory();

    const updateSimResults = useUpdateSimResults();
    const { consentTs } = useGetSimResults();

    const [ loading, setLoading ] = useState<boolean>(false);

    const onConsent = () => {
        setLoading(true);
        updateSimResults({consentTs: formatISO(new Date())})
            .then(() => {
                history.push('/summer');


            })
            .catch(err => {
                setLoading(false);
                console.error(err);
            });
    };

    if (consentTs === undefined) return null;
    if (consentTs) return <Redirect to={'/summer'}/>;

    return (
        <ContainerStyle>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />

            <p css={tw`mb-4 text-lg`}>
                Welcome to the Registration Preferences Survey! This survey is part of a National Science Foundation funded project at CUNY aiming to better understand how course availability impacts students. You were randomly chosen to participate because you were enrolled in a course at CUNY last year. You will be paid $40 for your time if you complete the survey.
            </p>
            <p css={tw`mb-4 text-lg`}>
                The survey should take about 15-20 minutes to complete. At the end of the survey, to receive the $40 payment, you can enter an email address to be paid through PayPal, or a phone number to be paid by Venmo, or a mailing address to receive a paper check.
            </p>
            <p css={tw`mb-4 text-lg`}>
                Your survey answers will be stored anonymously and will not be linked to your name. Publications or presentations that result from this study will not identify you by name. Your participation is entirely voluntary. If you decide not to participate, there will be no negative impact on your course grades, on your academic standing, or on other benefits at your campus or CUNY. Your instructors will not have access to your responses. You can withdraw your consent and stop participating in the research at any time, without any penalty. Should you have any further questions about the study or your participation in it, feel free to contact Prof. Claire Wladis at elearningresearch@bmcc.cuny.edu, or 212-220-1363.
            </p>
            <p css={tw`mb-4 text-lg`}>
                Thank you for participating in this study! With your help, we can improve support for CUNY students in pursuit of their degree, especially during these challenging times. By clicking on the “Next” button below, you are confirming that you have read this information, are at least 18 years old, and that you agree to participate in this research.
            </p>

            <div css={tw`mt-4 flex justify-end`}>
                <Button css={tw`text-lg`} onClick={() => onConsent()} type="button">Next</Button>
            </div>
        </ContainerStyle>
    );
};

