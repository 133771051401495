import { activeTerm, summerTerm } from '@/constants/terms';
import { YesNoCertaintyChoices, YesNoChoices } from '@/components/survey/surveyQuestions';
import { CourseOffer } from '@/api/graphql/queries/getCoursesOffered';

export type ScreenerAnswers = {
    registerIntent: string;
    registerIntentTs: string;
    hasExternalTransfer: string; // 'Y' | 'N' | 'U'
    hasExternalTransferTs: string;

    summerRegisterIntent: string;
    summerRegisterIntentCourses: CourseOffer[];
    summerRegisterIntentTs: string;
};


export const registerIntentQuestion = {
    id: 'registerIntent',
    label: `Are you taking at least one CUNY course in ${activeTerm.season} ${activeTerm.year}?`,
    choices: YesNoCertaintyChoices
};

export const unqualifiedScreenerMessage = `Thank you so much for taking the time to fill this out. The questions we will be asking on the survey are based on your course selections at CUNY in ${activeTerm.season} ${activeTerm.year}. Since you did not take courses at CUNY in ${activeTerm.season} ${activeTerm.year}, the questions will not be relevant to you. But we want to thank you for taking the time to volunteer to answer our survey questions! You can now close this browser window.`;

export const unqualifiedTransferMessage = 'Thank you so much for taking the time to fill this out. We are unable to process courses transferred in from another institution at this time. Since you transferred course credit into CUNY from a non-CUNY institution, you are not eligible for the study. However, we want to thank you for taking the time to volunteer to answer our survey questions!  You can now close this browser window.';


export const cohortNotAllowedMessage = 'Thank you for your interest in taking the CUNY registration preferences survey! Unfortunately, our system indicates that you have not been selected to participate at this time. Please feel free to contact Prof. Claire Wladis with any questions at elearningresearch@bmcc.cuny.edu, or 212-220-1363.';

export const summerRegisterIntentQuestion = {
    id: 'summerRegisterIntent',
    label: `Did you register for at least one CUNY course in Summer ${summerTerm.year}?`,
    choices: YesNoChoices
};

export const screenerTransferQuestion = {
    id: 'hasExternalTransfer',
    label: 'Have you ever transferred course credit into CUNY from a college outside the CUNY system?',
    choices: [ ...YesNoChoices, {label: 'Unsure', value: 'U'} ]
};
