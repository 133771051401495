import React, { useState } from 'react';
import tw from 'twin.macro';
import { Button, Spinner } from '@/components/common';
import styled from 'styled-components/macro';
import { useStoreActions } from '@/store';
import { useHistory } from 'react-router-dom';

const ContainerStyle = styled.div`
    ${tw`w-full h-full min-h-screen`}
    
    ${tw`pt-4 pb-4 px-4 md:px-12`}
`;

const LoginScreen = () => {
    const history = useHistory();

    const [ cunyId, setCunyId ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');

    const [ error, setError ] = useState<string>('');
    const [ submitting, setSubmitting ] = useState<boolean>(false);

    const adminLogin = useStoreActions(actions => actions.adminLogin);

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (cunyId === 'error') {
            throw new Error('testing error capturing');
        }

        if (submitting || !cunyId || cunyId.length < 8) return;

        setError('');
        setSubmitting(true);

        adminLogin({ studentId: cunyId, password }).then(errMsg => {
            if (!errMsg) {
                history.replace('/');
            } else {
                setSubmitting(false);
                setError(errMsg);
            }
        });
    };

    return (
        <ContainerStyle>
            <form onSubmit={(e) => onSubmit(e)}>
                {/* <h3 css={tw`mb-8 font-medium underline text-xl`}>Registration Simulation Entry</h3> */}

                <div css={tw`flex flex-col items-center`}>
                    <div css={tw`mb-3`}>
                        <p css={tw`font-medium pr-2 text-lg`}>ID</p>
                        <input css={tw`align-middle py-2 px-3`} value={cunyId} onChange={(e: any) => setCunyId(e.target.value)}/>
                    </div>
                    <div css={tw`mb-3`}>
                        <p css={tw`font-medium pr-2 text-lg`}>Password</p>
                        <input css={tw`align-middle py-2 px-3`} type="password" value={password} onChange={(e: any) => setPassword(e.target.value)}/>
                    </div>
                </div>

                {
                    error ?
                        <div css={[ tw`w-full mt-4 bg-red-200 p-2 text-red-600 rounded mb-2 text-center` ]}>
                            <span css={[ tw`font-medium` ]}>Error:</span> {error}
                        </div>
                        : null
                }

                <div css={tw`mt-4 flex justify-center`}>
                    <div>
                        {
                            submitting ?

                                <Spinner size={'base'} />
                                : <Button disabled={!cunyId} css={tw`text-lg`} type="submit">Login</Button>
                        }
                    </div>
                </div>

            </form>


        </ContainerStyle>
    );
};

export default LoginScreen;
