import config from '@/config';
import { ApolloClient, createHttpLink, InMemoryCache, makeVar } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { SelectedCourse } from '@/api/types';
import store from '@/store';

const httpLink = createHttpLink({
    uri: config.graphUrl,
});

const authLink = setContext((_, { headers }) => {
    const { user } = store.getState();
    const cx = {
        headers: {
            ...headers,
            authorization: `Bearer ${user.token}`
        }
    };
    return cx;
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {

    const actions = store.getActions();

    if (networkError) console.error(`[Network error]: ${networkError}`);

    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        );

        for (const err of graphQLErrors) {
            switch (err.extensions?.code) {
                case 'invalid-jwt':
                    // noinspection JSIgnoredPromiseFromCall
                    actions.logout();
                    return;
                default:
                    console.error('Received unhandled graphQLError code: ', err.extensions?.code);
            }
        }
    }
});

export const secondChanceOfferVar = makeVar<SelectedCourse | null>(null);
// making sure to avoid stale data
secondChanceOfferVar(null);

export type CoreqBlocking = {
    classId: string;
    attempted?: boolean;
}
export const coreqBlockingVar = makeVar<CoreqBlocking | null>(null);
coreqBlockingVar(null);

const graphClient = new ApolloClient({
    link: onErrorLink.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache({
        // @ts-ignore
        Query: {
            fields: {
                secondChanceOffer: {
                    read() {
                        return secondChanceOfferVar();
                    }
                }
            }
        }
    })
});

export default graphClient;
