import React, { CSSProperties, ReactEventHandler, useEffect } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components/macro';
import _ from 'lodash';

interface Props {
    label: string;
    htmlLabel?: boolean;
    name: string;
    required?: boolean;
    value: string;
    defaultValue?: string;
    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    onChange: (newValue: string) => void;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const ContainerStyle = styled.div`
    ${tw`flex items-center py-3`}
    
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
`;

export default ({ label, htmlLabel = false, name, onChange, required, value = '', defaultValue, containerStyle = {}, labelStyle = {}, inputStyle = {}, onKeyPress }: Props) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    useEffect(() => {
        if (defaultValue) {
            onChange(defaultValue);
        }
    }, [ defaultValue ]);

    return (
        <ContainerStyle style={containerStyle}>
            {
                htmlLabel ?
                    <p css={tw`font-medium mr-2`} style={labelStyle} dangerouslySetInnerHTML={{__html: label}}/>
                    :
                    <p css={tw`font-medium mr-2`} style={labelStyle}>{label}</p>
            }

            <input
                style={inputStyle}
                required={required}
                css={tw`mt-0 mr-2 py-2 px-2`}
                name={name}
                value={value}
                onChange={handleChange}
                onKeyPress={onKeyPress}
            />
        </ContainerStyle>
    );
};
