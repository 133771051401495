export enum EnrollmentStatusEnum {
    O = 'Open',
    C = 'Closed',
    W = 'Wait List'
}

export enum AcademicCareerEnum {
    UGRD = 'Undergraduate',
    GRAD = 'Graduate',
    CBAC = 'Undergraduate CUNYBA',
    LAW = 'Law',
    MEDS = 'Medical',
    GGRD = 'Graduate School Graduate',
    ULAG = 'Undergraduate LaGuardia CC',
    UKCC = 'Undergraduate',
    DOCT = 'Undergraduate Kingsborough CC',
}

