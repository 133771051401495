import React from 'react';
import {
    DividerStyle, formatSelectedCheckboxes,
    formatSkippableDict,
    formatTimestamp,
    TimestampStyle
} from '@/components/simulation/SimulationDebugger';
import { useGetSurveyAnswers } from '@/api/simulation/SimulationAPI';
import tw from 'twin.macro';
import _ from 'lodash';
import {
    AgreeScore,
    disabilityChoices, DisabilityInput,
    LifeEventsInput,
    LifeRatingsInput
} from '@/components/survey/surveyQuestions';
import { lifeEvents } from '@/components/survey/life/lifeQuestions';
import {
    demo10,
    demo1a,
    demo1b,
    demo2,
    demo3,
    demo4,
    demo5,
    demo6, demo7, demo8, demo9a, demo9b, demoDescribe, demoZip
} from '@/components/survey/demographics/demographicsQuestions';
import styled from 'styled-components/macro';
import {
    major1a,
    major1b,
    major2a,
    major2b,
    major3a,
    major3b,
    major4,
    major5,
} from '@/components/survey/major/majorQuestions';

export const DebuggerTableStyle = styled.table`
    position: relative;
    border: 1px solid #ccc;
    
    th,td {
        border: 1px solid #ccc;
    }
    th {
        ${tw`font-medium px-2 py-1 text-left`}
          background: white;
    }
    
    td:nth-child(2) {
        max-width: 340px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    td {
        ${tw`px-2 py-1`}
    }

    * {
        ${tw`text-sm`}
    }
    tr:nth-child(even) {background: #eaeaea}
`;

export default () => {
    const survey = useGetSurveyAnswers();


    return (

        <DividerStyle>

            {
                survey.work.submittedTs ?
                    <>
                        <div css={tw`flex items-center`}>
                            <h4 css={tw`mr-2`}>Work</h4>
                            <TimestampStyle>Submitted: {formatTimestamp(survey.work.submittedTs)}</TimestampStyle>
                        </div>
                        <p>q13: {survey.work.q13}</p>
                        <p>q14a: {AgreeScore[survey.work.q14a]}</p>
                        <p>q14b: {AgreeScore[survey.work.q14b]}</p>
                        <p>q14c: {AgreeScore[survey.work.q14c]}</p>
                    </> : null
            }
            {
                survey.family.submittedTs ?
                    <>
                        <div css={tw`flex items-center`}>
                            <h4 css={tw`mr-2`}>Family</h4>
                            <TimestampStyle>Submitted: {formatTimestamp(survey.family.submittedTs)}</TimestampStyle>
                        </div>
                        <p>q15a: {survey.family.q15a}</p>
                        <p>q15b: {survey.family.q15b}</p>
                        <p>q16: {survey.family.q16}</p>
                        <p>q17: {_.map(survey.family.q17, (v, k) => `${k} - ${v}`).join(', ')}</p>
                        <p>q18a: {AgreeScore[survey.family.q18a]}</p>
                        <p>q18b: {AgreeScore[survey.family.q18b]}</p>
                        <p>q18c: {AgreeScore[survey.family.q18c]}</p>
                    </> : null
            }
            {
                survey.timeQuality.submittedTs ?
                    <>
                        <div css={tw`flex items-center`}>
                            <h4 css={tw`mr-2`}>Time Quality</h4>
                            <TimestampStyle>Submitted: {formatTimestamp(survey.timeQuality.submittedTs)}</TimestampStyle>
                        </div>
                        <p>q19a: {AgreeScore[survey.timeQuality.q19a]}</p>
                        <p>q19b: {AgreeScore[survey.timeQuality.q19b]}</p>
                        <p>q19c: {AgreeScore[survey.timeQuality.q19c]}</p>
                        <p>q19d: {AgreeScore[survey.timeQuality.q19d]}</p>
                        <p>q19e: {AgreeScore[survey.timeQuality.q19e]}</p>
                        <p>q19f: {AgreeScore[survey.timeQuality.q19f]}</p>
                        <p>q19g: {AgreeScore[survey.timeQuality.q19g]}</p>
                        <p>q19h: {AgreeScore[survey.timeQuality.q19h]}</p>
                        <p>q19i: {AgreeScore[survey.timeQuality.q19i]}</p>
                    </> : null
            }
            {
                survey.timeQuantity.submittedTs ?
                    <>
                        <div css={tw`flex items-center`}>
                            <h4 css={tw`mr-2`}>Time Quantity</h4>
                            <TimestampStyle>Submitted: {formatTimestamp(survey.timeQuantity.submittedTs)}</TimestampStyle>
                        </div>
                        <p>q20a: {survey.timeQuantity.q20a || 0}</p>
                        <p>q20b: {survey.timeQuantity.q20b || 0}</p>
                        <p>q20c: {survey.timeQuantity.q20c || 0}</p>
                        <p>q20d: {survey.timeQuantity.q20d || 0}</p>
                        <p>q20e: {survey.timeQuantity.q20e || 0}</p>
                        <p>q20f: {survey.timeQuantity.q20f || 0}</p>
                        <p>q20g: {survey.timeQuantity.q20g || 0}</p>
                        <p>q20h: {survey.timeQuantity.q20h || 0}</p>
                        <p>q20i: {survey.timeQuantity.q20i || 0}</p>
                        <p>q20j: {survey.timeQuantity.q20j || 0}</p>
                    </> : null
            }
            {
                survey.lifeEvents?.submittedTs ?
                    <>
                        <div css={tw`flex items-center`}>
                            <h4 css={tw`mr-2`}>Life Events</h4>
                            <TimestampStyle>Submitted: {formatTimestamp(survey.lifeEvents.submittedTs)}</TimestampStyle>
                        </div>
                        {
                            lifeEvents.map((option) => {
                                if (survey.lifeEvents[option.value as keyof LifeEventsInput]) {
                                    return <p key={option.value}>{option.label}</p>;
                                }
                                return null;
                            })
                        }
                        {
                            survey.lifeEvents.lifeOther ?
                                <p>Other: {survey.lifeEvents.lifeOther}</p>
                                : null
                        }
                    </> : null
            }
            {
                survey.lifeRatings?.submittedTs ?
                    <>
                        <div css={tw`flex items-center`}>
                            <h4 css={tw`mr-2`}>Life Ratings</h4>
                            <TimestampStyle>Submitted: {formatTimestamp(survey.lifeRatings.submittedTs)}</TimestampStyle>
                        </div>
                        {
                            lifeEvents.map((option) => {
                                if (survey.lifeRatings[option.value as keyof LifeRatingsInput]) {
                                    return <p key={option.value}>{option.label}: {survey.lifeRatings[option.value as keyof LifeRatingsInput]}</p>;
                                }
                                return null;
                            })
                        }
                        {
                            survey.lifeRatings.lifeOther ?
                                <p>Other: {survey.lifeEvents.lifeOther}: {survey.lifeRatings.lifeOther}</p>
                                : null
                        }

                        <p>describeImpact: {survey.lifeRatings.describeImpact || 'n/a'}</p>
                    </> : null
            }
            {
                survey.major.submittedTs ?
                    <>
                        <div css={tw`flex items-center`}>
                            <h4 css={tw`mr-2`}>Major</h4>
                            <TimestampStyle>Submitted: {formatTimestamp(survey.major.submittedTs)}</TimestampStyle>
                        </div>

                        <DebuggerTableStyle>
                            <thead>
                                <tr>
                                    <th>Key</th>
                                    <th>Label</th>
                                    <th>Response</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>q1a</td>
                                    <td>{major1a.label}</td>
                                    <td>{survey.major.q1a}</td>
                                </tr>
                                <tr>
                                    <td>q1b</td>
                                    <td>{major1b.label}</td>
                                    <td>{formatSkippableDict(survey.major.q1b)}</td>
                                </tr>
                                <tr>
                                    <td>q2a</td>
                                    <td>{major2a.label}</td>
                                    <td>{survey.major.q2a}</td>
                                </tr>
                                <tr>
                                    <td>q2b</td>
                                    <td>{major2b.label}</td>
                                    <td>{formatSkippableDict(survey.major.q2b)}</td>
                                </tr>
                                <tr>
                                    <td>q3a</td>
                                    <td>{major3a.label}</td>
                                    <td>{survey.major.q3a}</td>
                                </tr>
                                <tr>
                                    <td>q3b</td>
                                    <td>{major3b.label}</td>
                                    <td>{formatSkippableDict(survey.major.q3b)}</td>
                                </tr>
                                <tr>
                                    <td>q4</td>
                                    <td>{major4.label}</td>
                                    <td>
                                        <p>{survey.major.q4 !== 'SKIPPED' ? survey.major.q4?.map(o => o.courseShortName).join(', ') : 'Skipped'}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>q5</td>
                                    <td>{major5.label}</td>
                                    <td>{survey.major.q5}</td>
                                </tr>
                            </tbody>
                        </DebuggerTableStyle>
                    </> : null
            }
            {
                survey.demographics?.submittedTs ?
                    <>
                        <div css={tw`flex items-center`}>
                            <h4 css={tw`mr-2`}>Demographics</h4>
                            <TimestampStyle>Submitted: {formatTimestamp(survey.demographics.submittedTs)}</TimestampStyle>
                        </div>

                        <DebuggerTableStyle>
                            <thead>
                                <tr>
                                    <th>Key</th>
                                    <th>Label</th>
                                    <th>Response</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>demo1a</td>
                                    <td>{demo1a.label}</td>
                                    <td>{survey.demographics.demo1a}</td>
                                </tr>
                                <tr>
                                    <td>demo1b</td>
                                    <td>{demo1b.label}</td>
                                    <td>
                                        <div css={tw``}>
                                            {
                                                disabilityChoices.map((option) => {
                                                    if (survey.demographics.demo1b?.[option.value as keyof DisabilityInput]) {
                                                        if (option.value === 'other') {
                                                            return <p key={option.value}>•{option.label} {survey.demographics.demo1b.otherValue}</p>;
                                                        } else {
                                                            return <p key={option.value}>•{option.label}</p>;
                                                        }

                                                    }
                                                    return null;
                                                })
                                            }
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>demo2</td>
                                    <td>{demo2.label}</td>
                                    <td>{survey.demographics.demo2}</td>
                                </tr>
                                <tr>
                                    <td>demoZip</td>
                                    <td>{demoZip.label}</td>
                                    <td><p>{survey.demographics.demoZip}</p></td>
                                </tr>
                                <tr>
                                    <td>demoDescribe</td>
                                    <td>{demoDescribe.label}</td>
                                    <td>{formatSelectedCheckboxes(survey.demographics.demoDescribe, demoDescribe.choices)}</td>
                                </tr>
                                <tr>
                                    <td>demo3</td>
                                    <td>{demo3.label}</td>
                                    <td><p>{survey.demographics.demo3}</p></td>
                                </tr>
                                <tr>
                                    <td>demo4</td>
                                    <td>{demo4.label}</td>
                                    <td><p>{survey.demographics.demo4}</p></td>
                                </tr>
                                <tr>
                                    <td>demo5</td>
                                    <td>{demo5.label}</td>
                                    <td><p>{survey.demographics.demo5}</p></td>
                                </tr>
                                <tr>
                                    <td>demo6</td>
                                    <td>{demo6.label}</td>
                                    <td><p>{survey.demographics.demo6}</p></td>
                                </tr>
                                <tr>
                                    <td>demo7</td>
                                    <td>{demo7.label}</td>
                                    <td><p>{survey.demographics.demo7}</p></td>
                                </tr>
                                <tr>
                                    <td>demo8</td>
                                    <td>{demo8.label}</td>
                                    <td><p>{survey.demographics.demo8}</p></td>
                                </tr>
                                <tr>
                                    <td>demo9a</td>
                                    <td>{demo9a.label}</td>
                                    <td><p>{survey.demographics.demo9a}</p></td>
                                </tr>
                                <tr>
                                    <td>demo9b</td>
                                    <td>{demo9b.label}</td>
                                    <td>
                                        <div css={tw``}>
                                            {
                                                disabilityChoices.map((option) => {
                                                    if (survey.demographics.demo9b?.[option.value as keyof DisabilityInput]) {
                                                        if (option.value === 'other') {
                                                            return <p key={option.value}>•{option.label} {survey.demographics.demo9b.otherValue}</p>;
                                                        } else {
                                                            return <p key={option.value}>•{option.label}</p>;
                                                        }

                                                    }
                                                    return null;
                                                })
                                            }
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>demo10</td>
                                    <td>{demo10.label}</td>
                                    <td><p>{survey.demographics.demo10}</p></td>
                                </tr>
                            </tbody>
                        </DebuggerTableStyle>
                    </> : null
            }



        </DividerStyle>
    );
};
