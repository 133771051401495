import { gql } from '@apollo/client';

export default gql`
fragment courseSearchResults on custom_crse_offer {
    courseId: crse_id
    subject
    courseNumber: catalog_nbr
    academicCareer: acad_career
    requirementGroup: rqrmnt_group

    requirement {
        description: descrlong
    }
    catalog {
        requirementDesignation: rqmnt_designtn
        courseTitle: course_title_long
        courseComponent: ssr_component
        description: descrlong
        units: units_acad_prog
    }

    classes {
        classId: class_nbr
        classSection: class_section
        startDate: start_dt
        endDate: end_dt
        instructionMode: instruction_mode,
        enrollmentStatus: enrl_stat,
        sessionCode: session_code
    }
}
`;

