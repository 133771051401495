import React from 'react';
import { Field } from 'formik';
import { InputWithCriteriaContainerStyle } from './styled';
import { CourseNumberCriteriaOptions, SearchCriteria } from './SearchCriteria';

interface Props {
    label: string;
    fieldName: string;
    criteriaFieldName: string;
    allowLetters?: boolean
}

export default ({ label, fieldName, criteriaFieldName }: Props) => {
    return (
        <InputWithCriteriaContainerStyle>
            <label>{label}</label>

            <Field className="criteria-select" name={criteriaFieldName} as="select">
                <option value=""></option>
                {
                    CourseNumberCriteriaOptions.map((o: SearchCriteria) => (
                        <option key={o.value} value={o.value}>
                            {o.label}
                        </option>
                    ))
                }
            </Field>

            <Field className="trailingInput" name={fieldName} as="input"/>

        </InputWithCriteriaContainerStyle>
    );
};
