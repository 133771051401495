import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { Overlay } from './Overlay';

interface SpinnerProps {
    size?: 'small' | 'base' | 'large';
    white?: boolean;
    spinnerColor?: string;
}

// noinspection CssOverwrittenProperties
const SpinnerElement = styled.div<SpinnerProps>`
    margin: 0 auto;
    width: 45px;
    height: 45px;
    
    border-top: 2px solid transparent;
        
    border-bottom: 2px solid ${props => props.spinnerColor || '#a4a4a4'};
    border-left: 2px solid ${props => props.spinnerColor || '#a4a4a4'};
    border-right: 2px solid transparent;
    
    border-radius: 60px;

    ${props => props.size === 'small' ? tw`w-4 h-4 border-2` : (
        props.size === 'large' ? tw`w-16 h-16` : tw`w-8 h-8`
    )};
    
    border-width: ${props => props.size === 'small' ? '2px' : (props.size === 'large' ? '6px' : '3px')};
    
    animation: spin 0.666s linear 0.2s infinite;
    
    box-shadow: none;
    
    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;

const Spinner = (props: SpinnerProps) => <SpinnerElement {...props}/>;

const SpinnerTextStyle = styled.p`
    ${tw`text-light text-center mt-3`}
`;

type Props = { visible: boolean; appear?: boolean; message?: string, overlayMode?: 'light' | 'dark' } & SpinnerProps;
const SpinnerOverlay = ({ visible, appear, message, overlayMode, ...props }: Props) => {
    return (
        <Overlay overlayMode={overlayMode} visible={visible} appear={appear}>
            <Spinner {...props}/>

            {
                message ?
                    <SpinnerTextStyle>{message}</SpinnerTextStyle>
                    : null
            }
        </Overlay>
    );
};

Spinner.displayName = 'Spinner';
SpinnerOverlay.displayName = 'Spinner.Overlay';

Spinner.Overlay = SpinnerOverlay;
export { Spinner };
