import { gql } from '@apollo/client';

export default gql`
fragment conditionLine on ps_rq_cond_line {
    conditionSpec: condition_spec
    lineSequence: cond_line_seq
    conditionCode: condition_code
    conditionOperator: condition_operator
    conditionData: condition_data
    testId: test_id
    testComponent: test_component
    score
    processType: cond_process_type
}
`;
