import { gql } from '@apollo/client';
import { Option } from '@/api/types';

export default gql`
query getSubjectsByInstitutionAndTerm($institution: String!, $term: String!) {
    items: custom_class_tbl(
        where: {
            institution: {
                _eq: $institution
            },
            acad_career: {
                _in: [ "UGRD", "UKCC", "ULAG" ]
            },
            strm: {
                _eq: $term
            },
            subject_info: {
                eff_status: {
                    _neq: "I"
                }
            }
        },
        distinct_on: subject
    ) {
        value: subject,
        subjectInfo: subject_info {
            label: descr
        }
    }
}
`;

export const getSubjectsByInstitutionAndTermQuery_Original = gql`
query getSubjectsByInstitutionAndTerm($institution: String!, $terms: [String!]) {
    items: ps_class_tbl(
        where: {
            institution: {
                _eq: $institution
            },
            acad_career: {
                _in: [ "UGRD", "UKCC", "ULAG" ]
            },
            strm: {
                _in: $terms
            },
            subject_info: {
                eff_status: {
                    _neq: "I"
                }
            }
        },
        distinct_on: subject
    ) {
        value: subject,
        subjectInfo: subject_info {
            label: descr
        }
    }
}
`;

export const rawDataToSubjectOptions = (items: any[]): Option[] => {
    const options = items.map(o => {
        return {
            value: o.value,
            label: o.subjectInfo.label
        };
    });
    return options;
};
