import { CourseClassExtract, StudentEnrollment } from '@/api/types';
import { StudentDetails } from '@/utils/enrollmentUtils';
import _ from 'lodash';
import getTransferCourses from '@/api/getTransferCourses';
import { CourseExtract } from '@/api/graphql/queries/getCourses';
import * as Sentry from '@sentry/react';
import transferError from '@/utils/transferError';

export const getTransferRules = async (destinationCourse: Pick<CourseClassExtract, 'institution'>, student: StudentDetails): Promise<CourseExtract[]> => {
    const targetInstitution = destinationCourse.institution;

    const outsideCourses: StudentEnrollment[] = student.enrollments.filter(o => {
        return o.institution !== targetInstitution && o.gradePoints;
    });

    const courseIds: string[] = outsideCourses.map(o => o.class.courseId);
    const uniqueCourseIds = _.uniq(courseIds);
    if (!uniqueCourseIds.length) return [];

    try {
        const transferredCourses = await getTransferCourses(uniqueCourseIds, targetInstitution);

        transferError.lastEncountered = null;
        return transferredCourses;

    } catch (err) {
        Sentry.captureException(err);
        transferError.lastEncountered = new Date();

        return [];
    }
};
