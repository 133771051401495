import React, { useState } from 'react';
import { formatClassSection, parseClassMeetingTimes, parseClassNumber } from '@/api/transformers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { CourseClass, CourseComponent, SectionMode } from '@/api/types';
import { CourseComponentName } from '@/constants/courseComponents';
import { CourseGroupItemStyle } from '@/components/search-results/styled';
import { parseSessionCode } from '@/constants/sessionTypes';
import tw from 'twin.macro';
import { disableSubmitOnEnter } from '@/components/common/inputUtils';
import { ComponentItemStyle } from '@/components/course-components/styled';

interface Props {
    courseGroup: any;
    sections: CourseClass[];
    courseComponent: CourseComponent;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    selectedClassId: string;
    startCollapsed?: boolean;
    // this component accepts original courses from the catalog, so the property "sectionMode" may not be set on the section level. therefore this
    // component prop allows us to explicitly pass in the desired mode.
    sectionMode: SectionMode;
}

export default ({ courseGroup, sectionMode, sections, courseComponent, onChange, selectedClassId, startCollapsed = false }: Props) => {

    const [ showSections, setShowSections ] = useState<boolean>(!startCollapsed);

    const onRowClick = (e: React.MouseEvent) => {
        const radio = e.currentTarget.querySelector('input[type="radio"]') as HTMLElement;
        if (radio) {
            radio.click();
        }
    };

    return (
        <CourseGroupItemStyle>

            <div css={tw`cursor-pointer select-none`} className="header" onClick={() => setShowSections(!showSections)}>
                <a>
                    <FontAwesomeIcon icon={showSections ? faAngleDown : faAngleRight } />
                </a>


                <h3>{CourseComponentName[courseComponent]}</h3>
            </div>

            <div
                style={{
                    display: showSections ? 'block' : 'none',
                }}
            >

                <ComponentItemStyle>
                    <thead>
                        <tr>
                            <th></th>
                            <th><span>Class</span></th>
                            <th><span>Section</span></th>
                            <th><span>Days & Times</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {sections.map((section: any) => {
                            return (
                                <tr css={tw`cursor-pointer`} key={section.classId} onClick={onRowClick}>
                                    <td>
                                        <input
                                            required={true}
                                            css={tw`align-middle mt-0 mr-2`}
                                            type="radio"
                                            name={courseComponent}
                                            checked={section.classId.toString() === selectedClassId}
                                            value={section.classId}
                                            onChange={onChange}
                                            onKeyPress={disableSubmitOnEnter}
                                        />
                                    </td>
                                    <td>
                                        <span>{parseClassNumber(courseGroup, section)}</span>
                                    </td>
                                    <td>
                                        <span>{formatClassSection(section.classSection)}-{section.courseComponent} {parseSessionCode(section.sessionCode)}</span>
                                    </td>

                                    <td>
                                        {/* <ClassTimes item={section}/> */}
                                        <span>{sectionMode === 'OA' ? 'N/A' : parseClassMeetingTimes(section)}</span>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </ComponentItemStyle>


                {/* {sections.map((section: any) => { */}
                {/*    return ( */}
                {/*        <CourseClassItemStyle> */}
                {/*            <thead> */}
                {/*                <tr> */}
                {/*                    <th></th> */}
                {/*                    <th><span>Class</span></th> */}
                {/*                    <th><span>Section</span></th> */}
                {/*                    <th><span>Days & Times</span></th> */}
                {/*                </tr> */}
                {/*            </thead> */}
                {/*            <tbody> */}
                {/*            <tr> */}
                {/*                <td> */}
                {/*                    <input */}
                {/*                        required={true} */}
                {/*                        css={tw`align-middle mt-0 mr-2`} */}
                {/*                        type="radio" */}
                {/*                        name={courseComponent} */}
                {/*                        checked={section.classId.toString() === selectedClassId} */}
                {/*                        value={section.classId} */}
                {/*                        onChange={onChange} */}
                {/*                        onKeyPress={disableSubmitOnEnter} */}
                {/*                    /> */}
                {/*                </td> */}
                {/*                <td> */}
                {/*                    <span>{parseClassNumber(courseGroup, section)}</span> */}
                {/*                </td> */}
                {/*                <td> */}
                {/*                    <span>{formatClassSection(section.classSection)}-{section.courseComponent} {parseSessionCode(section.sessionCode)}</span> */}
                {/*                </td> */}

                {/*                <td> */}
                {/*                    <ClassTimes item={section}/> */}
                {/*                </td> */}
                {/*            </tr> */}
                {/*            </tbody> */}
                {/*        </CourseClassItemStyle> */}
                {/*    ); */}
                {/*  })}  */}
            </div>

        </CourseGroupItemStyle>
    );
};
