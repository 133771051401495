import React from 'react';
import { Field, useFormikContext } from 'formik';
import { InputWithCriteriaContainerStyle } from './styled';
import { NumberCriteriaOptions, SearchCriteria } from './SearchCriteria';

interface Props {
    label: string;
    fieldName: string;
    criteriaFieldName: string;
    allowLetters?: boolean
}

export default ({ label, fieldName, criteriaFieldName, allowLetters = false }: Props) => {

    const {
        values: { [fieldName]: fieldValue },
        setFieldValue
    } = useFormikContext<any>();

    const onBlur = () => {
        if (allowLetters) {

        } else {
            const num = parseFloat(fieldValue);
            if (num) {
                setFieldValue(fieldName, num.toString());
            } else {
                setFieldValue(fieldName, '');
            }
        }
    };

    return (
        <InputWithCriteriaContainerStyle>
            <label>{label}</label>

            <Field className="criteria-select" name={criteriaFieldName} as="select">
                <option value=""></option>
                {
                    NumberCriteriaOptions.map((o: SearchCriteria) => (
                        <option key={o.value} value={o.value}>
                            {o.label}
                        </option>
                    ))
                }
            </Field>

            <Field onBlur={onBlur} className="trailingInput" name={fieldName} as="input"/>

        </InputWithCriteriaContainerStyle>
    );
};
