import React, { useEffect, useRef, useState } from 'react';
import tw from 'twin.macro';
import { ColumnItemGroupStyle, ColumnItemStyle, ColumnSelectDropdownStyle, ColumnSelectStyle } from './styled';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { textColumns, timestampColumns } from '@/components/dashboard/components/filter-bar/filterParams';
import { Option } from '@/api/types';

interface Props {
    selected: Option | null;
    onSelect: (selected: Option | null) => void;
    onCancel?: () => void;
}

const ColumnSelect = ({ selected = null, onSelect, onCancel }: Props) => {
    const [ isOpen, setIsOpen ] = useState<boolean>(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const onOutsideClickListener = (event: Event) => {
        const eventTarget = event.target as HTMLElement;
        // Can't always rely on ref.current.contains(target) in cases where we are dynamically showing/hiding
        // nested elements due to the way React removes them from the DOM.
        if (containerRef.current &&
            !containerRef.current.contains(eventTarget) &&
            (!eventTarget.className.includes('column-dropdown'))) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', onOutsideClickListener, false);
        return () => {
            document.removeEventListener('click', onOutsideClickListener, false);
        };
    }, []);

    const handleSelect = (column: Option | null) => {
        onSelect(column);
        setIsOpen(false);
    };

    return (
        <ColumnSelectStyle
            ref={containerRef}
        >
            <a css={tw`block cursor-pointer block hover:bg-gray-100 px-2 py-2 rounded border border-solid border-gray-700`} onClick={() => setIsOpen(!isOpen)}>
                <div css={tw`px-1`}>
                    <div css={tw`block flex justify-between whitespace-no-wrap items-center`}>

                        <p css={tw`text-gray-800 uppercase text-xs font-medium`}>{selected?.label}</p>

                        <FontAwesomeIcon
                            css={tw`ml-1 text-gray-800`}
                            icon={faCaretDown}
                        />
                    </div>
                </div>
            </a>


            {
                isOpen ?
                    <ColumnSelectDropdownStyle className={'column-dropdown'}>

                        <ColumnItemStyle onClick={() => handleSelect(null)} selected={!selected?.value}>&nbsp;</ColumnItemStyle>

                        {textColumns.map(c => {
                            return (
                                <ColumnItemStyle
                                    key={c.value}
                                    selected={c.value === selected?.value}
                                    onClick={() => handleSelect(c)}
                                >
                                    <label>
                                        <span>{c.label}</span>
                                    </label>
                                </ColumnItemStyle>
                            );
                        })}

                        <ColumnItemGroupStyle>
                            <label>--- Submitted Timestamps ---</label>
                        </ColumnItemGroupStyle>

                        {timestampColumns.map(c => {
                            return (
                                <ColumnItemStyle
                                    key={c.value}
                                    selected={c.value === selected?.value}
                                    onClick={() => handleSelect(c)}
                                >
                                    <label>
                                        <span>{c.label}</span>
                                    </label>
                                </ColumnItemStyle>
                            );
                        })}
                    </ColumnSelectDropdownStyle>
                    :
                    null
            }

        </ColumnSelectStyle>
    );
};

export default ColumnSelect;
