import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';

// ${tw`w-full box-border focus:outline-none appearance-none border-0 border-b border-gray-500 focus:border-blue-500 text-dark bg-transparent px-2 py-2`}
const StyledInput = styled.input`
    ${tw`w-full text-dark text-base`}
    padding: 0.5rem;
    font-family: 'Brandon';
`;

const Input = (props: any) => {
    return (
        <StyledInput {...props} />
    );
};

export { Input };
