import React from 'react';
import { SurveyContainerStyle } from './styled';
import WorkForm from '@/components/survey/WorkForm';
import { Redirect, Route, Switch } from 'react-router';
import MajorForm from '@/components/survey/major/MajorForm';
import FamilyForm from '@/components/survey/family/FamilyForm';
import TimeForm from '@/components/survey/TimeForm';
import DemographicsForm from '@/components/survey/demographics/DemographicsForm';
import SimulationDebugger from '@/components/simulation/SimulationDebugger';
import LifeEventsForm from '@/components/survey/life/LifeEventsForm';
import LifeRatingsForm from '@/components/survey/life/LifeRatingsForm';

export default () => {
    return (
        <SurveyContainerStyle>
            <div>
                <Switch>
                    <Route path="/survey/work" component={WorkForm}/>
                    <Route path="/survey/family" component={FamilyForm}/>
                    <Route path="/survey/time1" component={TimeForm}/>
                    <Route path="/survey/time2" component={TimeForm}/>
                    <Route path="/survey/life1" component={LifeEventsForm}/>
                    <Route path="/survey/life2" component={LifeRatingsForm}/>
                    <Route path="/survey/major" component={MajorForm}/>
                    <Route path="/survey/demographics" component={DemographicsForm}/>

                    <Redirect to={'/survey/work'}/>
                </Switch>
            </div>

            <SimulationDebugger/>

        </SurveyContainerStyle>
    );
};
