import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const TableStyle = styled.table`
    position: relative;
    th {
        ${tw`font-medium px-2 py-1 text-left`}
        
          background: white;
    }
    td {
        ${tw`px-2 py-1`}
    }

    * {
        ${tw`text-sm`}
    }
    // tr:nth-child(even) {background: #eaeaea}
    tbody {
        tr:not(:last-child) {
            border-bottom: 1px solid #ccc;
        }
    }
`;

export const StickyTableStyle = styled(TableStyle)`
    th {
        position: sticky;
        top: 0; /* Don't forget this, required for the stickiness */
        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    }
`;

