import { RequirementGroupDetails, RequirementLine, Requisite, RequisiteCourse } from '@/api/types';
import { generateUUID } from '@/utils/utilities';
import _ from 'lodash';
import { convertToRequisiteCourse } from '@/api/transformers';

export const getRequisiteCourses = (detail: NonNullable<RequirementGroupDetails>): RequisiteCourse[] => {
    const courses: RequisiteCourse[] = [];
    if ((detail.groupLineType === 'CRSE' || detail.groupLineType === 'CRSW') && detail.course) {
        courses.push({
            courseId: detail.course.courseId,
            subject: detail.course.subject,
            courseNumber: detail.course.courseNumber,
            requisiteType: detail.requisiteType,
        });

    } else if (detail.groupLineType === 'RQ') {
        const lineCourseCoReqs: RequirementLine[] = _.filter(detail.requirements, {lineDetailType: 'CLST'});

        lineCourseCoReqs.forEach((o) => {
            o.courses.forEach((r, index) => {
                const course = convertToRequisiteCourse(r);
                courses.push(course);
            });
        });
    }
    return courses;
};

export const isCourseRelatedCorequisite = (detail: NonNullable<RequirementGroupDetails>): boolean => {
    if (detail.requisiteType === 'CO') {
        if (detail.groupLineType === 'CRSE' || detail.groupLineType === 'CRSW') {
            return true;

        } else if (detail.groupLineType === 'RQ') {
            const lineCourseCoReqs = _.filter(detail.requirements, {lineDetailType: 'CLST'});

            if (lineCourseCoReqs.length) {
                return true;
            }
        }
    }
    return false;
};

export const parseCourseCorequisiteGroups = (details:RequirementGroupDetails[]): NonNullable<RequirementGroupDetails>[] => {
    const groups: NonNullable<RequirementGroupDetails>[] = [];

    for (let i = 0; i < details.length; i++) {
        const currentDetail: RequirementGroupDetails = _.cloneDeep(details[i]);
        if (!currentDetail) continue;

        if (isCourseRelatedCorequisite(currentDetail)) {
            groups.push(currentDetail);
        }
    }
    return groups;
};

export const parseParentheses = (details: RequirementGroupDetails[] | RequirementLine[]): Requisite[] => {
    const groups: Requisite[] = [];

    let depth = 0;

    let groupId = '';
    const parentGroups: string[] = [ 'root' ];

    for (let i = 0; i < details.length; i++) {
        if (!details[i]) continue;
        const currentDetail: Requisite = _.cloneDeep<Requisite>(details[i] as Requisite);

        if ('requirements' in currentDetail) {
            currentDetail.requirements = parseParentheses(currentDetail.requirements) as RequirementLine[];
        }

        switch (currentDetail.parenthesis) {
            case '(':
                depth += 1;
                groupId = generateUUID();
                groups.push({
                    ...currentDetail,
                    depth,
                    groupId,
                    parentGroupId: _.last(parentGroups) || ''
                });

                parentGroups.push(groupId);

                break;

            case ')':
                groups.push({
                    ...currentDetail,
                    depth,
                    groupId,
                    parentGroupId: _.nth(parentGroups, parentGroups.length - 1 - depth) || ''
                });
                depth -= 1;
                groupId = '';
                parentGroups.pop();
                break;

            case '':
                groups.push({
                    ...currentDetail,
                    depth,
                    groupId,
                    parentGroupId: _.nth(parentGroups, parentGroups.length - 1 - depth) || ''
                });
                break;
        }
    }
    return groups;
};
