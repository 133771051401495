import { useState } from 'react';
import { useGetSimResults, useUpdateSimulationData } from '@/api/simulation/SimulationAPI';
import { StageData } from '@/store/simulation/types';
import { useHistory } from 'react-router-dom';

// debugger function
export default (): () => Promise<any> => {
    const history = useHistory();
    const updateSimulationData = useUpdateSimulationData();

    const simResults = useGetSimResults();

    const [ loading, setLoading ] = useState<boolean>(false);

    return async (): Promise<any> => {
        if (loading) return;
        setLoading(true);

        const { data: simulationData } = simResults;
        if (!simulationData || !simulationData.currentStage) return Promise.reject(new Error('simulation not in progress'));
        const { currentStage } = simulationData;

        const updateProps: Partial<StageData> = {};
        if (currentStage.additionalChangesResponse) {
            updateProps.additionalChangesResponse = null;
        } else if (currentStage.negativeExpectations) {
            updateProps.negativeExpectations = null;
        } else if (currentStage.reasonResponse) {
            updateProps.reasonResponse = null;
        } else if (currentStage.replacementResponse) {
            updateProps.replacementResponse = null;
        } else if (currentStage.initialResponse) {
            updateProps.initialResponse = null;
        }

        return updateSimulationData({
            currentStage: {
                ...simulationData.currentStage,
                ...updateProps,
            }
        }).then(() => {
            history.push('/sim');
            setLoading(false);
        });
    };
};
