import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { BaseTableStyle } from '@/components/common/styled';

export const SimulationContainerStyle = styled.div`
    ${tw`px-3 w-full lg:px-12 max-w-screen-xl`}
`;

export const ShoppingCartWidgetStyle = styled.div`
    ${tw`border border-solid border-gray-500 px-1 py-3`}
    margin-left: -5px;
    margin-right: -5px;
    @media (min-width: 650px) {
        ${tw`px-3`}
        margin: 0;
    }

    a {
        ${tw`cursor-pointer text-blue-500 mb-3 hover:underline`}
    }
`;

export const CartModifierStyle = styled(BaseTableStyle)`
    table-layout: auto;

    th:first-child {
        // width: 40px;
    }
    td:first-child {
        ${tw`text-center`}
        input {
            ${tw`align-top`}
        }
    }

    td {
        ${tw`py-1 align-middle`}
    }
`;

export const CartResolverStyle = styled(BaseTableStyle)`
    table-layout: auto;
    
    td {
        ${tw`py-1 align-middle`}
    }
`;
