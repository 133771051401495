import { Option } from '@/api/types';
import { MajorResponse } from '@/components/survey/major/majorQuestions';

export type Skipped = 'SKIPPED';

export type Statement = {
    id: string;
    label: string
}

export interface WorkInput {
    q13: string;
    q14a: string;
    q14b: string;
    q14c: string;
    submittedTs?: string;
}

export interface FamilyInput {
    q15a: string;
    q15b: number;
    q16: string;
    q17: ChildrenInput;
    q18a: string;
    q18b: string;
    q18c: string;
    submittedTs?: string;
}
export interface ChildrenInput {
    [key: string]: string;
}

export interface TimeQualityInput {
    q19a: string;
    q19b: string;
    q19c: string;
    q19d: string;
    q19e: string;
    q19f: string;
    q19g: string;
    q19h: string;
    q19i: string;
    submittedTs?: string;
}

export interface TimeQuantityInput {
    q20a: number;
    q20b: number;
    q20c: number;
    q20d: number;
    q20e: number;
    q20f: number;
    q20g: number;
    q20h: number;
    q20i: number;
    q20j: number;
    q20k: number;
    submittedTs?: string;
}

export const generateEmptyTimeQuantityInput = (): TimeQuantityInput => {
    return {
        q20a: 0, q20b: 0, q20c: 0, q20d: 0, q20e: 0, q20f: 0, q20g: 0, q20h: 0, q20i: 0, q20j: 0, q20k: 0
    };
};

export const atLeastOneLifeEvent = (li: LifeEventsInput): boolean => {
    return !!(li.life1 || li.life2 || li.life3 || li.life4 || li.life5 || li.life6 || li.life7 || li.life8 || li.life9 || li.life10 || li.life11 || li.life12 || li.life13 || li.life14 || li.life15 || li.life16 || li.life17 || li.life18 || li.life19 || li.life20 || li.lifeOther);
};

export interface LifeEventsInput {
    life1: boolean;
    life2: boolean;
    life3: boolean;
    life4: boolean;
    life5: boolean;
    life6: boolean;
    life7: boolean;
    life8: boolean;
    life9: boolean;
    life10: boolean;
    life11: boolean;
    life12: boolean;
    life13: boolean;
    life14: boolean;
    life15: boolean;
    life16: boolean;
    life17: boolean;
    life18: boolean;
    life19: boolean;
    life20: boolean;
    lifeOther?: string;
    submittedTs?: string;
}

export interface LifeRatingsInput {
    life1: number;
    life2: number;
    life3: number;
    life4: number;
    life5: number;
    life6: number;
    life7: number;
    life8: number;
    life9: number;
    life10: number;
    life11: number;
    life12: number;
    life13: number;
    life14: number;
    life15: number;
    life16: number;
    life17: number;
    life18: number;
    life19: number;
    life20: number;
    lifeOther?: number;
    describeImpact?: string;
    submittedTs?: string;
}
//
// export type LifeEventFields = Omit<LifeInput, 'lifeOther' | 'describeImpact' | 'submittedTs'>;

export interface MajorInput {
    major1: string;
    major2: string;
    major3: {
        classId: string;
        courseShortName: string;
    }[] | Skipped;
    major4: string;
    submittedTs?: string;
}

export interface DisabilityInput {
    sensoryDisability?: boolean;
    physicalDisability?: boolean;
    chronicIllness?: boolean;
    shortIllness?: boolean;
    mentalHealth?: boolean;
    learningDisability?: boolean;
    other?: boolean;
    otherValue?: string;
    noResponse?: boolean;
}

export interface DemographicsInput {
    demo1a: string;
    demo1b?: DisabilityInput;
    demo2: string;
    demoZip: string;
    demoDescribe: string;
    demo3: string;
    demo4: string;
    demo5: string;
    demo6: string;
    demo7: string;
    demo8: number;
    demo9a: string;
    demo9b?: DisabilityInput;
    demo10?: string;
    submittedTs?: string;
}

export const generateEmptyDemographicsInput = (): Partial<DemographicsInput> => ({
    demo1a: '',
    demo1b: {},
    demo2: '',
    demoZip: '',
    demoDescribe: '',
    demo3: '',
    demo4: '',
    demo5: '',
    demo6: '',
    demo7: '',
    // demo8: '',
    demo9a: '',
    demo9b: {},
    demo10: '',
    submittedTs: '',
});

export interface SurveyAnswers {
    work: WorkInput;
    family: FamilyInput;
    timeQuality: TimeQualityInput;
    timeQuantity: TimeQuantityInput;
    lifeEvents: LifeEventsInput;
    lifeRatings: LifeRatingsInput;
    major: MajorResponse;
    demographics: DemographicsInput;
}

export const IsYes = (value: string): boolean => {
    return value === 'Y' || value === 'YY';
};

export const IsNo = (value: string): boolean => {
    return value === 'N' || value === 'NN';
};

export const YesNoCertaintyChoices = [ {label: 'Definitely Yes', value: 'YY', }, {label: 'Probably Yes', value: 'Y', }, {label: 'Probably No', value: 'N', }, {label: 'Definitely No', value: 'NN', }, ];

export const YesNoChoices = [ {label: 'Yes', value: 'Y'}, {label: 'No', value: 'N'} ];

export const YesNoChoicesWithDontKnowAndOptOut = [
    ...YesNoChoices,
    {label: 'Do not know', value: 'unknown'},
    {label: 'Prefer not to respond', value: 'noResponse'}
];

export const YesNoChoicesWithOptOut = YesNoChoices.concat({label: 'Prefer not to respond', value: 'noResponse'});

export const AgreeScore: any = {
    5: 'strongly agree',
    4: 'agree',
    3: 'somewhat agree',
    2: 'neither agree nor disagree',
    1: 'disagree',
    0: 'strongly disagree',
};
export const AgreeChoices = [
    {
        label: 'strongly agree',
        value: '5'
    },
    {
        label: 'agree',
        value: '4'
    },
    {
        label: 'somewhat agree',
        value: '3'
    },
    {
        label: 'neither agree nor disagree',
        value: '2'
    },
    {
        label: 'disagree',
        value: '1'
    },
    {
        label: 'strongly disagree',
        value: '0'
    }
];

export const AgeChoices = [
    {
        label: 'under 1 yr',
        value: 'under 1 yr',
    },
    {
        label: '1 yr',
        value: '1 yr',
    },
    {
        label: '2 yrs',
        value: '2 yrs',
    },
    {
        label: '3 yrs',
        value: '3 yrs',
    },
    {
        label: '4 yrs',
        value: '4 yrs',
    },
    {
        label: '5 yrs',
        value: '5 yrs',
    },
    {
        label: '6 yrs',
        value: '6 yrs',
    },
    {
        label: '7 yrs',
        value: '7 yrs',
    },
    {
        label: '8 yrs',
        value: '8 yrs',
    },
    {
        label: '9 yrs',
        value: '9 yrs',
    },
    {
        label: '10 yrs',
        value: '10 yrs',
    },
    {
        label: '11 yrs',
        value: '11 yrs',
    },
    {
        label: '12 yrs',
        value: '12 yrs',
    },
    {
        label: '13 yrs',
        value: '13 yrs',
    },
    {
        label: '14 yrs',
        value: '14 yrs',
    },
    {
        label: '15 yrs',
        value: '15 yrs',
    },
    {
        label: '16 yrs',
        value: '16 yrs',
    },
    {
        label: '17 yrs',
        value: '17 yrs',
    },
    {
        label: '18 yrs or older',
        value: '18 yrs or older',
    }
];

export const disabilityChoices = [
    {
        label: 'Sensory disability (e.g., vision, hearing)',
        value: 'sensoryDisability'
    },
    {
        label: 'Physical disability',
        value: 'physicalDisability'
    },
    {
        label: 'Chronic illness or medical condition',
        value: 'chronicIllness'
    },
    {
        label: 'Short-term illness, injury or condition',
        value: 'shortIllness'
    },
    {
        label: 'Mental health condition',
        value: 'mentalHealth'
    },
    {
        label: 'Learning disability',
        value: 'learningDisability'
    },
    {
        label: 'Disability or condition not listed:',
        value: 'other'
    },
    {
        label: 'Prefer not to respond',
        value: 'noResponse'
    },
];

export const generateNumericalDropdownChoices = (count: number, start = 0): Option[] => {
    const choices: Option[] = [];
    for (let i = start; i < count; i++) {
        choices.push({
            label: `${i}`,
            value: `${i}`,
        });
    }
    return choices;
};
