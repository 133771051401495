import { QuestionParams, SimulationQuestion } from '@/constants/questions';
import { areClassTimesEqual } from '@/utils/scheduleUtils';
import { YesNoChoices } from '@/components/survey/surveyQuestions';
import _ from 'lodash';
import { interpolateQuestion } from '@/constants/questions/questionUtils';

enum QuestionConditionEnum {
    AsyncToSync= 'AsyncToSync',
    SyncToAsync= 'SyncToAsync',
    ToDifferentTime= 'ToDifferentTime',
    OnlineToInPerson= 'OnlineToInPerson',
    InPersonToOnline= 'InPersonToOnline',
    ChangedCourse= 'ChangedCourse',
    AlwaysShow= 'AlwaysShow',
}

type ValidatorFunc = (params: QuestionParams) => boolean;

const ConditionValidator: {[key in QuestionConditionEnum]: ValidatorFunc} = {
    AsyncToSync: (params: QuestionParams): boolean => {
        if (params.replacementClass.courseId === 'NoReplacement') {
            return false;
        }
        return params.previousClass.sectionMode === 'OA' &&
            (params.replacementClass.sectionMode === 'OS' || params.replacementClass.sectionMode === 'P');
    },
    SyncToAsync: (params: QuestionParams): boolean => {
        if (params.replacementClass.courseId === 'NoReplacement') {
            return false;
        }
        return (params.previousClass.sectionMode === 'OS' || params.previousClass.sectionMode === 'P') &&
            params.replacementClass.sectionMode === 'OA';
    },
    ToDifferentTime: (params: QuestionParams): boolean => {
        if (params.replacementClass.courseId === 'NoReplacement' || params.replacementClass.sectionMode === 'OA') {
            return false;
        }

        for (const replacementSection of params.replacementClass.selectedSections) {

            for (const previousSection of params.previousClass.selectedSections) {
                const isEqual = areClassTimesEqual(replacementSection, previousSection);
                if (!isEqual) return false;
            }
        }

        return true;
    },
    OnlineToInPerson: (params: QuestionParams): boolean => {
        if (params.replacementClass.courseId === 'NoReplacement') {
            return false;
        }
        return (params.previousClass.sectionMode === 'OA' || params.previousClass.sectionMode === 'OS') &&
            params.replacementClass.sectionMode === 'P';
    },
    InPersonToOnline: (params: QuestionParams): boolean => {
        if (params.replacementClass.courseId === 'NoReplacement') {
            return false;
        }
        return params.previousClass.sectionMode === 'P' &&
            (params.replacementClass.sectionMode === 'OA' || params.replacementClass.sectionMode === 'OS');
    },
    ChangedCourse: (params: QuestionParams): boolean => {
        if (params.replacementClass.courseId === 'NoReplacement') {
            return false;
        }
        return params.previousClass.courseId !== params.replacementClass.courseId;
    },
    AlwaysShow: (params: QuestionParams): boolean => {
        return true;
    },
};

export interface NegativeExpectationQuestion extends SimulationQuestion {
    validator?: ValidatorFunc,
}

export type NegativeExpectationsResponse = {
    questionsShown?: string[];
    q3a1: {
        TimeStudying?: boolean;
        TimeLife?: boolean;
        ReducedFlexibility?: boolean;
        Disability?: boolean;
        Financial?: boolean;
        Commitments?: boolean;
        Stress?: boolean;
        LearningStyle?: boolean;
        Other?: boolean;
        OtherValue?: string;
        None?: boolean;
    },
    q3a2: {
        TimeStudying?: boolean;
        TimeLife?: boolean;
        Disability?: boolean;
        Stress?: boolean;
        LearningStyle?: boolean;
        Other?: boolean;
        OtherValue?: string;
        None ?: boolean;
    },
    q3a3: {
        Financial?: boolean;
        Commitments?: boolean;
        Multitask?: boolean;
        Healthcare?: boolean;
        BasicLifeTasks?: boolean;
        OptimalTime?: boolean;
        UninterruptedBlocks?: boolean;
        Stress?: boolean;
        Other?: boolean;
        OtherValue?: string;
        None?: boolean;
    };
    q3a4: {
        TimeCommuting?: boolean;
        CostCommuting?: boolean;
        Disability?: boolean;
        Stress?: boolean;
        LearningStyle?: boolean;
        Pandemic?: boolean;
        Other?: boolean;
        OtherValue?: string;
        None?: boolean;
    };
    q3a5: {
        TechnologyAccess?: boolean;
        TechnologyExperience?: boolean;
        Disability?: boolean;
        QuietPlace?: boolean;
        Stress?: boolean;
        LearningStyle?: boolean;
        Other?: boolean;
        OtherValue?: string;
        None?: boolean;
    };
    q3a6: {
        DegreeProgress?: boolean;
        CareerPlan?: boolean;
        Difficult?: boolean;
        ScheduleFit?: boolean;
        Other?: boolean;
        None?: boolean;
    };
    q3a7?: string; // optional
    q3a8: 'Y' | 'N'; // default N pre-selected
    submittedTs?: string;
}

const questions: NegativeExpectationQuestion[] = [
    {
        id: 'q3a1',
        validator: ConditionValidator.AsyncToSync,
        label: 'You chose to switch from an <b>asynchronous</b> section (which has no scheduled class meetings) to a <b>synchronous</b> section (<b>which has scheduled class meetings</b>).  In real life, if you had to make this switch, would you expect to experience any negative consequences?',
        type: 'checkbox',
        choices: [
            {
                label: '<b>Yes</b>, this would <b>reduce the time</b> I have for <b>studying</b> or other college work.',
                value: 'TimeStudying',
                order: 1

            },

            {
                label: '<b>Yes</b>, this would <b>reduce the time</b> I have for important <b>life activities</b> (e.g., family, work, healthcare, sleep).',
                value: 'TimeLife',
                order: 2

            },

            {
                label: '<b>Yes</b>, the <b>reduced flexibility</b> would make it harder for me to <b>manage my time<b>.',
                value: 'ReducedFlexibility',
                order: 3

            },

            {
                label: '<b>Yes</b>, this would negatively impact my ability to <b>manage an illness/injury/disability (including mental health)</b>, or to obtain disability ,accommodations.',
                value: 'Disability',
                order: 4

            },

            {
                label: '<b>Yes</b>, this would have a <b>negative financial impact</b> (e.g., I would have to pay for childcare, work fewer hours, etc.).',
                value: 'Financial',
                order: 5

            },

            {
                label: '<b>Yes</b>, this would have a negative impact on my <b>family life or commitments.</b>',
                value: 'Commitments',
                order: 6

            },

            {
                label: '<b>Yes</b>, having to attend scheduled class meetings would <b>increase my stress.</b>',
                value: 'Stress',
                order: 7

            },

            {
                label: '<b>Yes</b>, synchronous courses with scheduled class meetings are a <b>worse fit for my learning style.</b>',
                value: 'LearningStyle',
                order: 8

            },

            {
                label: '<b>Yes</b>, because:',
                value: 'Other',
                order: 9

            },

            {
                label: '<b>No</b>, this switch would not negatively impact me in any way.',
                value: 'None',
                order: 10
            },

        ]
    },
    {
        id: 'q3a2',
        validator: ConditionValidator.SyncToAsync,
        label: 'You chose to switch from a <b>synchronous</b> section (which has scheduled class meetings) to an <b>asynchronous</b> online section (<b>which has no scheduled class meetings</b>).  In real life, if you had to switch modes like this, would you expect to experience any negative consequences?',
        type: 'checkbox',
        choices: [
            {
                label: '<b>Yes</b>, this would <b>reduce the time</b> I have for <b>studying</b> or other college work.',
                value: 'TimeStudying',
                order: 1
            },
            {
                label: '<b>Yes</b>, this would <b>reduce the time</b> I have for important <b>life activities</b> (e.g., family, work, healthcare, sleep).',
                value: 'TimeLife',
                order: 2
            },
            {
                label: '<b>Yes</b>, this would negatively impact my ability to <b>manage an illness/injury/disability (including mental health)</b>, or to obtain disability accommodations.',
                value: 'Disability',
                order: 3
            },
            {
                label: '<b>Yes</b>, the asynchronous course mode would increase my <b>stress</b>.',
                value: 'Stress',
                order: 4
            },
            {
                label: '<b>Yes</b>, the asynchronous course mode is a <b>worse fit for my learning style</b>.',
                value: 'LearningStyle',
                order: 5
            },
            {
                label: '<b>Yes</b>, because:',
                value: 'Other',
                order: 6
            },
            {
                label: '<b>No</b>, this switch would not negatively impact me in any way.',
                value: 'None',
                order: 7
            },
        ]
    },
    {
        id: 'q3a3',
        validator: ConditionValidator.ToDifferentTime,
        label: 'The new section you chose requires you to attend class at <b>times you had not originally planned</b>.  In real life, if you had to make this change, would you expect to experience any negative consequences?',
        type: 'checkbox',
        choices: [
            {
                label: '<b>Yes</b>, the timing of the new section would impact me <b>financially</b> (e.g., I would have to pay for extra childcare, change my work schedule, etc.).',
                value: 'Financial',
                order: 1,
            },
            {
                label: '<b>Yes</b>, the timing of the new section would negatively impact my <b>family life or commitments.</b>',
                value: 'Commitments',
                order: 2,
            },
            {
                label: '<b>Yes</b>, the timing of the new section would require me to <b>multi-task during class</b> because of other responsibilities scheduled at that time (e.g., work, childcare).',
                value: 'Multitask',
                order: 3,
            },
            {
                label: '<b>Yes</b>, the timing of the new section would interfere with <b>health care</b> appointments or the timing of health treatments/medications.',
                value: 'Healthcare',
                order: 4,
            },
            {
                label: '<b>Yes</b>, the timing of the new section conflicts with <b>basic life tasks</b> such as sleeping, eating, etc.',
                value: 'BasicLifeTasks',
                order: 5,
            },
            {
                label: '<b>Yes</b>, the timing of the new section means that I would have to attend class at a time that is <b>not optimal for my learning or concentration.</b>',
                value: 'OptimalTime',
                order: 6,
            },
            {
                label: '<b>Yes</b>, the timing of the new section would make it harder for me to have <b>uninterrupted blocks of time</b> for studying or other tasks.',
                value: 'UninterruptedBlocks',
                order: 7,
            },
            {
                label: '<b>Yes</b>, the timing of the new section would increase my <b>stress</b>.',
                value: 'Stress',
                order: 8,
            },
            {
                label: '<b>Yes</b>, because:',
                value: 'Other',
                order: 9,
            },
            {
                label: '<b>No</b>, this switch would not negatively impact me in any way.',
                value: 'None',
                order: 10,
            },
        ]
    },
    {
        id: 'q3a4',
        validator: ConditionValidator.OnlineToInPerson,
        label: 'The new section you chose is <b>on campus</b>, while the original course you had planned to take was <b>online</b>.  In real life, if you had to make this change, would you expect to experience any negative consequences?',
        type: 'checkbox',
        choices: [
            {
                label: '<b>Yes</b>, I would have to spend <b>more time commuting</b> to the on-campus course.',
                value: 'TimeCommuting',
                order: 1,
            },
            {
                label: '<b>Yes</b>, it would <b>cost me more to commute</b> to the on-campus course.',
                value: 'CostCommuting',
                order: 2,
            },
            {
                label: '<b>Yes</b>, this would negatively impact my ability to <b>manage an illness/injury/disability (including mental health)</b>, or to obtain necessary disability accommodations.',
                value: 'Disability',
                order: 3,
            },
            {
                label: '<b>Yes</b>, having to attend courses in person would <b>increase my stress</b>.',
                value: 'Stress',
                order: 4,
            },
            {
                label: '<b>Yes</b>, the on-campus course mode is a <b>worse fit for my learning style</b>.',
                value: 'LearningStyle',
                order: 5,
            },
            {
                label: '<b>Yes</b>, I would have to attend in-person classes even though <b>I am not comfortable</b> with this because of the pandemic.',
                value: 'Pandemic',
                order: 6,
            },
            {
                label: '<b>Yes</b>, because:',
                value: 'Other',
                order: 7,
            },
            {
                label: '<b>No</b>, this switch would not negatively impact me in any way.',
                value: 'None',
                order: 8,
            },
        ]
    },
    {
        id: 'q3a5',
        validator: ConditionValidator.InPersonToOnline,
        label: 'The new section you chose is <b>online</b>, while the original course you had planned to take was <b>on campus</b>.  In real life, if you had to make this change, would you expect to experience any negative consequences?',
        type: 'checkbox',
        choices: [
            {
                label: '<b>Yes</b>, I would have difficulty <b>getting access</b> to or <b>paying</b> for the necessary <b>technology</b> for the course.',
                value: 'TechnologyAccess',
                order: 1
            },
            {
                label: '<b>Yes</b>, I would have difficulty <b>using</b> the necessary <b>technology</b> for the course, because I am not very comfortable or experienced with it.',
                value: 'TechnologyExperience',
                order: 2
            },
            {
                label: '<b>Yes</b>, this would negatively impact my ability to <b>manage an illness/injury/disability (including mental health)</b>, or to obtain necessary disability accommodations.',
                value: 'Disability',
                order: 3
            },
            {
                label: '<b>Yes</b>, I would have difficulty <b>finding a quiet or private place</b> to do the online course.',
                value: 'QuietPlace',
                order: 4
            },
            {
                label: '<b>Yes</b>, taking a course online would <b>increase my stress</b>.',
                value: 'Stress',
                order: 5
            },
            {
                label: '<b>Yes</b>, the online course mode is a <b>worse fit for my learning style</b>.',
                value: 'LearningStyle',
                order: 6
            },
            {
                label: '<b>Yes</b>, because:',
                value: 'Other',
                order: 7
            },
            {
                label: '<b>No</b>, this switch would not negatively impact me in any way.',
                value: 'None',
                order: 8
            },
        ]
    },
    {
        id: 'q3a6',
        validator: ConditionValidator.ChangedCourse,
        label: 'You chose to switch from {{originalCourseName}} to take {{replacementCourseName}} instead.  In real life, if you had to make this change, would you expect to experience any negative consequences?',
        type: 'checkbox',
        choices: [
            {
                label: '<b>Yes</b>, switching from {{originalCourseName}} to {{replacementCourseName}} would delay my degree progress.',
                value: 'DegreeProgress',
                order: 1,
            },
            {
                label: '<b>Yes</b>, {{replacementCourseName}} is less in line with my interests or long-term academic and career plans.',
                value: 'CareerPlan',
                order: 2,
            },
            {
                label: '<b>Yes</b>, {{replacementCourseName}} is more difficult or time-consuming than {{originalCourseName}}.',
                value: 'Difficult',
                order: 3,
            },
            {
                label: '<b>Yes</b>, {{replacementCourseName}} fits less well with the rest of the courses in my schedule than {{originalCourseName}}.',
                value: 'ScheduleFit',
                order: 4,
            },
            {
                label: '<b>Yes</b>, because:',
                value: 'Other',
                order: 5,
            },
            {
                label: '<b>No</b>, this switch would not negatively impact me in any way.',
                value: 'None',
                order: 6,
            },
        ]
    },
    {
        id: 'q3a7',
        validator: ConditionValidator.AlwaysShow,
        label: 'Is there anything you would like to share about how it would affect you, if in real life you had to switch from <b>{{originalCourseName}} ({{originalCourseModeWithTimes}})</b> to <b>{{replacementCourseName}} ({{replacementCourseModeWithTimes}})</b>?',
        type: 'text',
        choices: [],
    },
    {
        id: 'q3a8',
        validator: ConditionValidator.AlwaysShow,
        label: 'Given this course switch, do you also need to change other course selections (e.g., due to linked lecture/labs or co-requisite courses)?',
        type: 'radio',
        choices: YesNoChoices,
        defaultValue: 'N',
    }
];

export const getNegativeExpectationQuestions = (params: QuestionParams): NegativeExpectationQuestion[] => {
    const filteredQuestions = _.chain(questions)
        .filter(q => q.validator!(params))
        .map(q => interpolateQuestion(q, params))
        .value();

    return filteredQuestions;
};
