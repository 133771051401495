import React, { useEffect, useMemo } from 'react';
import tw from 'twin.macro';
import { useGetSimResults } from '@/api/simulation/SimulationAPI';
import { Button } from '@/components/common';
import useShoppingCartHooks from '@/components/shopping-cart/useShoppingCartHooks';
import ShoppingCartTable from '@/components/shopping-cart/ShoppingCartTable';
import { useHistory } from 'react-router';
import CoReqErrorLinkable from '@/components/shopping-cart/CoReqErrorLinkable';
import { useStoreActions } from '@/store';
import removeClass from '@/components/shopping-cart/api/removeClass';
import { useReactiveVar } from '@apollo/client';
import { coreqBlockingVar } from '@/api/graph';
import { hashSelectedCourse } from '@/api/transformers';

interface Props {
    onSubmit: () => void;
}

export default ({ onSubmit }: Props) => {
    const history = useHistory();
    const { data: simulationData, shoppingCart } = useGetSimResults();
    const { coReqsErrors } = useShoppingCartHooks();
    const coreqBlocking = useReactiveVar(coreqBlockingVar);

    const { clearAllFiltersExceptInstitution } = useStoreActions(actions => actions.search);

    const requiredClassIds = useMemo<string[]>(() => {
        const replacementClass = simulationData.currentStage?.replacementResponse?.replacementClass;
        if (replacementClass && replacementClass !== 'NoReplacement') {
            return [ hashSelectedCourse(replacementClass) ];
        }
        return [];
    }, [ simulationData ]);

    const goToSearch = () => {
        clearAllFiltersExceptInstitution();
        history.push('/search');
    };

    // only allow if the coreq error belongs to the replacementResponse, which student would be unable to remove themselves.
    const coreqBlockingClass = useMemo<string>(() => {
        const isBlocking = coReqsErrors.length === 1 && hashSelectedCourse(coReqsErrors[0].course) === requiredClassIds[0];
        return isBlocking ? requiredClassIds[0] : '';
    }, [ coReqsErrors, simulationData ]);

    useEffect(() => {
        return () => {
            if (coreqBlockingClass) {
                coreqBlockingVar({
                    classId: coreqBlockingClass
                });
            }
        };
    }, [ coreqBlockingClass ]);

    const allowCoreqOverride = useMemo(() => {
        return coreqBlockingClass && coreqBlocking?.classId === coreqBlockingClass;
    }, [ coreqBlockingClass, coreqBlocking ]);

    return (
        <div>
            <ShoppingCartTable items={shoppingCart} onRemoveClass={removeClass} requiredClassIds={requiredClassIds}/>

            { coReqsErrors.map((err, i) => (<CoReqErrorLinkable key={i} error={err}/>)) }

            <div css={tw`flex justify-between`}>
                <Button onClick={goToSearch}>Choose more classes</Button>
                <div>
                    {
                        coReqsErrors.length && allowCoreqOverride ?
                            <a css={tw`mx-4 text-link cursor-pointer`} onClick={onSubmit}>Continue anyway</a>
                            : null
                    }
                    <Button type="button" disabled={!!coReqsErrors.length} onClick={onSubmit}>Next</Button>
                </div>

            </div>

        </div>
    );
};
