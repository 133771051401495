import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { termNameYearLowercase } from '@/constants/terms';
import {
    AgreeChoices,
    TimeQualityInput,
    TimeQuantityInput
} from '@/components/survey/surveyQuestions';
import tw from 'twin.macro';
import { Button, Spinner } from '@/components/common';
import { useHistory, useLocation } from 'react-router-dom';
import NumberQuestion from '@/components/survey/NumberQuestion';
import AgreeStatementsTable from '@/components/survey/AgreeStatementsTable';
import AgreeStatementRow from '@/components/survey/AgreeStatementRow';
import { useGetSurveyAnswers, useUpdateSurvey } from '@/api/simulation/SimulationAPI';
import { formatISO } from 'date-fns';

const q19 = [
    {
        id: 'q19a',
        label: 'I often struggle to find enough time for my schoolwork.',
        choices: AgreeChoices
    },
    {
        id: 'q19b',
        label: 'The days and times that I have available to do schoolwork are often unpredictable.',
        choices: AgreeChoices
    },
    {
        id: 'q19c',
        label: 'I don’t have much flexibility in choosing the times when I can do my schoolwork.',
        choices: AgreeChoices
    },
    {
        id: 'q19d',
        label: 'I often have to do schoolwork at non-optimal times (e.g., when I am tired or have difficulty concentrating).',
        choices: AgreeChoices
    },
    {
        id: 'q19e',
        label: 'I often have to multitask while working on my schoolwork (e.g. caring for children, eating, answering work emails, etc.).',
        choices: AgreeChoices
    },
    {
        id: 'q19f',
        label: 'I am often interrupted while studying and completing class assignments.',
        choices: AgreeChoices
    },
    {
        id: 'q19g',
        label: 'I find it difficult to find larger uninterrupted blocks of time to work on schoolwork.',
        choices: AgreeChoices
    },
    {
        id: 'q19h',
        label: 'I often have trouble finding a quiet place to work on my schoolwork.',
        choices: AgreeChoices
    },
    {
        id: 'q19i',
        label: 'I often do schoolwork on the bus/subway/train, or at other times when I have a few free moments (e.g., waiting in line, at the doctor’s office, etc.).',
        choices: AgreeChoices
    },
];

const q20Min = 0;
const q20Max = 168;
const q20: {id: Exclude<keyof TimeQuantityInput, 'submittedTs'>, label: string}[] = [
    {
        id: 'q20a',
        label: '<b>Working for pay</b> (including time commuting to/from work).',
    },
    {
        id: 'q20b',
        label: '<b>Caring for at least one child who required supervision or assistance</b>, including time multitasking on other tasks (e.g., cooking, cleaning, running errands, commuting, etc.) while caring for children.',
    },
    {
        id: 'q20c',
        label: '<b>Activities related to your children for which they were NOT present</b> (e.g. teacher conferences, shopping or planning activities for them, reading about parenting, researching health/education options, etc.).',
    },
    {
        id: 'q20d',
        label: '<b>Caring for relatives or close friends</b> (other than your children).',
    },
    {
        id: 'q20e',
        label: '<b>Household tasks unrelated to childcare or eldercare</b> (e.g. cooking, cleaning, errands, chores, household maintenance, paying bills, etc.).',
    },
    {
        id: 'q20f',
        label: '<b>Time spent on your healthcare (mental and physical health)</b> (including time commuting to/from healthcare appointments).',
    },
    {
        id: 'q20g',
        label: '<b>Attending class (in person or Zoom/video meetings).</b>',
    },
    {
        id: 'q20h',
        label: '<b>Doing academic work outside of class</b> (e.g. studying, completing assignments, attending office hours or tutoring, etc.).',
    },
    {
        id: 'q20i',
        label: '<b>Completing administrative tasks related to college</b> (e.g. financial aid, advisement, disability accommodations).',
    },
    {
        id: 'q20j',
        label: '<b>Time spent commuting to/from the college</b> for class or other academic activities.',
    },
    {
        id: 'q20k',
        label: '<b>Unpaid internship, clinical work, etc.</b> that was NOT included in time spent on academic work already reported above.',
    }
];

const quantityContainerStyle: CSSProperties = {
    justifyContent: 'space-between',
};

const quantityLabelStyle: CSSProperties = {
    fontWeight: 'normal',
};
const quantityInputStyle: CSSProperties = {

};

export default () => {
    const history = useHistory();
    const location = useLocation();

    const survey = useGetSurveyAnswers();
    const updateSurvey = useUpdateSurvey();

    const [ loading, setLoading ] = useState<boolean>(false);
    const [ timeQualityInput, setTimeQualityInput ] = useState<TimeQualityInput>(survey.timeQuality);

    const [ timeQuantityInput, setTimeQuantityInput ] = useState<TimeQuantityInput>({
        // ...generateEmptyTimeQuantityInput(),
        ...survey.timeQuantity
    });

    useEffect(() => {
        setTimeQualityInput({
            ...timeQualityInput,
            ...survey.timeQuality,
        });
        setTimeQuantityInput({
            ...timeQuantityInput,
            ...survey.timeQuantity,
        });
    }, [ survey ]);

    const handleTimeQualityInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const field = event.target.name;
        const value: string = event.target.value;
        setTimeQualityInput({
            ...timeQualityInput,
            [field]: value
        });
    };

    const page = useMemo<1 | 2>(() => {
        if (location.pathname.indexOf('time1') !== -1) {
            return 1;
        } else {
            return 2;
        }
    }, [ location ]);

    const onBack = () => {
        if (page === 1) {
            history.push('/survey/family');
        } else {
            history.push('/survey/time1');
        }
    };

    const onSubmit = (e: React.FormEvent) => {
        setLoading(true);
        e.preventDefault();
        e.stopPropagation();

        const submittedTs = formatISO(new Date());

        if (page === 1) {
            updateSurvey({
                timeQuality: {
                    ...timeQualityInput,
                    submittedTs,
                },
            })
                .then(() => {
                    history.push('/survey/time2');
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                });

        } else {
            updateSurvey({
                timeQuantity: {
                    ...timeQuantityInput,
                    submittedTs,
                },
            })
                .then(() => {
                    history.push('/survey/life1');
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                });
        }
    };

    return (
        <div>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />
            <form onSubmit={onSubmit}>
                {
                    page === 1 ?
                        <>
                            <h4 css={tw`mt-8 mb-4`}>Please rate how strongly you agree or disagree with each of the following statements.</h4>
                            <AgreeStatementsTable>
                                {
                                    q19.map(statement => (
                                        <AgreeStatementRow
                                            key={statement.id}
                                            statement={statement}
                                            selected={timeQualityInput[statement.id as keyof TimeQualityInput] || ''}
                                            onChange={handleTimeQualityInputChange}
                                        />
                                    ))
                                }
                            </AgreeStatementsTable>
                        </>
                        :
                        <>
                            <h4 css={tw`mt-8 mb-8`}>During the {termNameYearLowercase}, how many hours per week on average do you spend on the following activities:</h4>

                            <div css={tw`w-1/2`}>
                                {
                                    q20.map(question => (
                                        <NumberQuestion htmlLabel={true} containerStyle={quantityContainerStyle} labelStyle={quantityLabelStyle} inputStyle={quantityInputStyle} key={question.id} min={q20Min} max={q20Max} required={true} label={question.label} name={question.id} value={timeQuantityInput[question.id]} onChange={n => setTimeQuantityInput({
                                            ...timeQuantityInput,
                                            [question.id]: n
                                        })}
                                        />
                                    ))
                                }
                            </div>
                        </>
                }

                <div css={tw`mt-8 flex justify-between`}>
                    <Button type="button" onClick={onBack}>Back</Button>
                    <Button type="submit">Next</Button>
                </div>
            </form>
        </div>
    );
};
