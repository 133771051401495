import React, { useMemo } from 'react';
import { RequirementLine, RequisiteCourse } from '@/api/types';
import { convertToRequisiteCourse } from '@/api/transformers';
import tw from 'twin.macro';
import RequirementCourseLink from '@/components/class-detail/requirements/RequirementCourseLink';
import styled from 'styled-components/macro';

interface Props {
    detail: RequirementLine
    goToClassResults: (course: RequisiteCourse) => void
}

const AntirequisiteTagStyle = styled.span`
    ${tw`text-white bg-red-400 px-1 font-normal mx-1`}
    border-radius: 6px;
`;

export default ({ detail, goToClassResults }: Props) => {

    const title = useMemo<string>(() => {
        const s = `[${detail.courseListId}] ${detail.courseListInfo.description}`;
        return s;
    }, [ detail ]);

    return (
        <>
            <p css={tw`font-medium`}>
                <span>Course List {title}</span>
                {
                    /* As of 11/12/12, only RequirementLineCourseList has been found to have this feature */
                    detail.isAntirequisite ?
                        <AntirequisiteTagStyle>Antirequisite</AntirequisiteTagStyle>
                        : null
                }
            </p>

            {detail.maxUnitsAllowed ? <p>Max Units Allowed: {detail.maxUnitsAllowed}</p> : null}
            {detail.maxCoursesAllowed ? <p>Max Courses Allowed: {detail.maxCoursesAllowed}</p> : null}
            {detail.minCoursesRequired ? <p>Minimum Courses Required: {detail.minCoursesRequired}</p> : null}
            {detail.minUnitsRequired ? <p>Minimum Units Required: {detail.minUnitsRequired}</p> : null}
            {detail.minGradePoints ? <p>Minimum Grade Points: {detail.minGradePoints}</p> : null}
            {detail.gpaRequired ? <p>GPA Required: {detail.gpaRequired}</p> : null}
            {detail.maxGpa ? <p>Max GPA: {detail.maxGpa}</p> : null}

            {/* <p className={'detail-title'}>{detail.courseListInfo.description}</p> */}


            <div css={tw`ml-1`}>
                {detail.courses.map((r, index) => {
                    const course = convertToRequisiteCourse(r);
                    return (
                        <span key={index} css={tw``}>
                            {detail.courses.length > 1 && index === detail.courses.length - 1 ? ' or ' : ''}
                            {
                                r.isWildCard === 'Y' ?
                                    <span css={tw`font-medium`}>Wild Card: {`${r.subject} ${r.courseNumber}`}</span>
                                    :
                                    <RequirementCourseLink course={course} onClick={goToClassResults}/>
                            }

                            {detail.courses.length > 1 && index !== detail.courses.length - 1 ? ', ' : ''}
                        </span>
                    );
                })}
            </div>
        </>
    );
};
