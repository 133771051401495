import { gql } from '@apollo/client';

export default gql`
query getMajorsByInstitution($institution: String!) {
    items: ps_acad_plan_tbl(
        where: {
            institution: {
                _eq: $institution
            },
            acad_plan_type: {
                _eq: "MAJ"
            },
            trnscr_descr: {
                _neq: ""
            }
        },
        distinct_on: [trnscr_descr],
        order_by: [{trnscr_descr: asc}, {effdt:desc}]
    ) {
        value: acad_plan,
        label: trnscr_descr
    }
}
`;
