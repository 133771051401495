import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components/macro';
import { Button, Spinner } from '@/components/common';
import { formatISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { PaymentChoice, PaymentInfo, PaymentInputs } from '@/api/types';
import SimulationDebugger from '@/components/simulation/SimulationDebugger';
import { useUpdateSimResults } from '@/api/simulation/SimulationAPI';

export const generateEmptyPaymentInputs = (): PaymentInputs => {
    return {
        type: '',
        paypalName: '',
        paypalEmail: '',
        paypalConfirmEmail: '',
        paypalPhone: '',
        venmoName: '',
        venmoId: '',
        venmoPhone: '',
        venmoEmail: '',
        venmoConfirmEmail: '',
        checkName: '',
        checkAddress: '',
        checkCity: '',
        checkState: '',
        checkZip: '',
        checkEmail: '',
        checkConfirmEmail: '',
    };
};

const ContainerStyle = styled.div`
    ${tw`w-full h-full min-h-screen`}
    
    ${tw`pt-4 pb-4 px-4 md:px-12 max-w-screen-lg`}
    
    input {
        ${tw`py-1 px-1`}
    }
`;

export default () => {
    const history = useHistory();
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>('');
    const [ selectedChoice, setSelectedChoice ] = useState<PaymentChoice>('');
    const [ paymentInput, setPaymentInput ] = useState<PaymentInputs>(generateEmptyPaymentInputs());

    const updateSimResults = useUpdateSimResults();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentInput({
            ...paymentInput,
            [event.target.name]: event.target.value
        });
    };

    const handleSelectedChoice = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedChoice(e.target.value as PaymentChoice);
    };

    useEffect(() => {
        console.log('selectedChoice: ', selectedChoice);
    }, [ selectedChoice ]);

    const submitForm = () => {
        setLoading(true);
        setError('');
        let errorMsg;
        const data: PaymentInfo = {
            submitted: formatISO(new Date()),
            type: selectedChoice
        };
        console.log('submitForm selectedChoice: ', selectedChoice);

        switch (paymentInput.type) {
            case 'paypal':
                const {paypalName, paypalEmail, paypalConfirmEmail, paypalPhone } = paymentInput;
                if (paypalEmail && !paypalConfirmEmail) {
                    errorMsg = 'Please confirm your email address.';
                } else if (paypalEmail !== paypalConfirmEmail) {
                    errorMsg = 'Email does not match.';
                }
                data.name = paypalName;
                data.phone = paypalPhone;
                data.email = paypalEmail;
                break;
            case 'venmo':
                const { venmoName, venmoId, venmoPhone, venmoEmail, venmoConfirmEmail } = paymentInput;
                if (venmoEmail && !venmoConfirmEmail) {
                    errorMsg = 'Please confirm your email address.';
                } else if (venmoEmail && venmoEmail !== venmoConfirmEmail) {
                    errorMsg = 'Email does not match.';
                }
                data.name = venmoName;
                data.venmoId = venmoId;
                data.phone = venmoPhone;
                data.email = venmoEmail;
                break;

            case 'check':
                const { checkName, checkAddress, checkCity, checkState, checkZip, checkEmail, checkConfirmEmail } = paymentInput;
                if (checkEmail && !checkConfirmEmail) {
                    errorMsg = 'Please confirm your email address.';
                } else if (checkEmail && checkEmail !== checkConfirmEmail) {
                    errorMsg = 'Email does not match.';
                }

                data.name = checkName;
                data.address = checkAddress;
                data.city = checkCity;
                data.state = checkState;
                data.zip = checkZip;
                data.email = checkEmail;

                break;
            case '':
                break;
        }
        if (errorMsg) {
            setError(errorMsg);
            setLoading(false);
        } else {
            updateSimResults({
                paymentInfo: data,
                paymentInfoSubmittedTs: formatISO(new Date())
            })
                .then(() => {
                    setTimeout(() => {
                        window.location.replace('/completed');
                        // history.push('/completed');
                    }, 200);
                })
                .catch(err => {
                    console.error('err: ', err);
                    setLoading(false);
                });
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        submitForm();
    };

    const renderSubmitButton = () => {
        return (
            <div css={tw`mt-4 flex justify-between`}>
                <p css={tw`italic`}>* = required</p>
                <div css={tw`text-right`}>
                    <p css={tw`text-red-500 mb-1`}>{error}</p>
                    <Button css={tw``} type="submit">Submit to finish survey</Button>
                </div>

            </div>
        );
    };

    const renderPaymentInfoFields = () => {
        switch (selectedChoice) {
            case 'paypal':
                return (
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <p css={tw`mb-4 text-lg`}>In order to be paid via PayPal (www.paypal.com), please enter the email below that you have associated with your PayPal account. If you do not have a PayPal account, you can still receive payment by entering any working email address below, and then PayPal will send an email with instructions about how to receive the money by setting up a PayPal account.</p>
                        <label css={tw`block mb-3`}>
                            <p>*Name</p>
                            <input required css={tw`block w-full`} type="text" name="paypalName" value={paymentInput.paypalName} onChange={handleChange}/>
                        </label>

                        <label css={tw`block mb-3`}>
                            <p>*Phone</p>
                            <input required css={tw`block w-full`} type="text" name="paypalPhone" value={paymentInput.paypalPhone} onChange={handleChange}/>
                        </label>

                        <label css={tw`block mb-3`}>
                            <p>*Email</p>
                            <input required css={tw`block w-full`} type="email" name="paypalEmail" value={paymentInput.paypalEmail} onChange={handleChange}/>
                        </label>

                        <label css={tw`block mb-3`}>
                            <p>*Confirm Email</p>
                            <input required css={tw`block w-full`} type="email" name="paypalConfirmEmail" value={paymentInput.paypalConfirmEmail} onChange={handleChange}/>
                        </label>
                        {renderSubmitButton()}
                    </form>
                );
            case 'venmo':
                return (
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <p css={tw`mb-4 text-lg`}>In order to be paid via Venmo (www.venmo.com), please enter the ID and phone number that you have associated with your Venmo account.</p>
                        <label css={tw`block mb-3`}>
                            <p>*Name</p>
                            <input required css={tw`block w-full`} type="text" name="venmoName" value={paymentInput.venmoName} onChange={handleChange}/>
                        </label>

                        <label css={tw`block mb-3`}>
                            <p>*Venmo ID</p>
                            <input required css={tw`block w-full`} type="text" name="venmoId" value={paymentInput.venmoId} onChange={handleChange}/>
                        </label>

                        <label css={tw`block mb-3`}>
                            <p>*Phone</p>
                            <input required css={tw`block w-full`} type="text" name="venmoPhone" value={paymentInput.venmoPhone} onChange={handleChange}/>
                        </label>

                        <label css={tw`block mb-3`}>
                            <p>*Email</p>
                            <input required css={tw`block w-full`} type="email" name="venmoEmail" value={paymentInput.venmoEmail} onChange={handleChange}/>
                        </label>

                        <label css={tw`block mb-3`}>
                            <p>*Confirm Email</p>
                            <input required css={tw`block w-full`} type="email" name="venmoConfirmEmail" value={paymentInput.venmoConfirmEmail} onChange={handleChange}/>
                        </label>
                        {renderSubmitButton()}
                    </form>
                );
            case 'check':
                return (
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <p css={tw`mb-4 text-lg`}>If you prefer to have a paper check mailed to you, please enter the full name to whom the check should be addressed and the full address, including street name and number, apartment number, city, state, and zip code.</p>
                        <label css={tw`block mb-3`}>
                            <p>*Full Name</p>
                            <input required css={tw`block w-full`} type="text" name="checkName" value={paymentInput.checkName} onChange={handleChange}/>
                        </label>

                        <label css={tw`block mb-3`}>
                            <p>*Address</p>
                            <input required css={tw`block w-full`} type="text" name="checkAddress" value={paymentInput.checkAddress} onChange={handleChange}/>
                        </label>

                        <label css={tw`block mb-3`}>
                            <p>*City</p>
                            <input required css={tw`block w-full`} type="text" name="checkCity" value={paymentInput.checkCity} onChange={handleChange}/>
                        </label>

                        <label css={tw`block mb-3`}>
                            <p>*State</p>
                            <input required css={tw`block w-full`} type="text" name="checkState" value={paymentInput.checkState} onChange={handleChange}/>
                        </label>

                        <label css={tw`block mb-3`}>
                            <p>*Zip</p>
                            <input required css={tw`block w-full`} type="text" name="checkZip" value={paymentInput.checkZip} onChange={handleChange}/>
                        </label>

                        <label css={tw`block mb-3`}>
                            <p>Email (for communication only in case of a problem):</p>
                            <input css={tw`block w-full`} type="email" name="checkEmail" value={paymentInput.checkEmail} onChange={handleChange}/>
                        </label>

                        <label css={tw`block mb-3`}>
                            <p>Confirm Email</p>
                            <input css={tw`block w-full`} type="email" name="checkConfirmEmail" value={paymentInput.checkConfirmEmail} onChange={handleChange}/>
                        </label>
                        {renderSubmitButton()}
                    </form>
                );
            default:
                return null;
        }
    };

    return (
        <ContainerStyle>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />
            {
                paymentInput.type ?
                    <div css={tw`max-w-screen-md`}>
                        <div css={tw`mb-3`}>
                            <Button type="button" onClick={() => setPaymentInput({
                                ...paymentInput,
                                type: ''
                            })}
                            >Back
                            </Button>
                        </div>
                        {renderPaymentInfoFields()}
                    </div>

                    :
                    <>
                        <p css={tw`mb-4 text-lg`}>
                            In order to be paid for completing this survey, choose your preferred method below and then enter the required contact information on the next screen:
                        </p>
                        <div css={tw`mb-4 flex`}>
                            <label css={tw`mx-3`}>
                                <input type="radio" name="choice" value="paypal" checked={selectedChoice === 'paypal'} onChange={handleSelectedChoice}/>
                                <span css={tw`mx-1`}>PayPal</span>
                            </label>
                            <label css={tw`mx-3`}>
                                <input type="radio" name="choice" value="venmo" checked={selectedChoice === 'venmo'} onChange={handleSelectedChoice}/>
                                <span css={tw`mx-1`}>Venmo</span>
                            </label>
                            <label css={tw`mx-3`}>
                                <input type="radio" name="choice" value="check" checked={selectedChoice === 'check'} onChange={handleSelectedChoice}/>
                                <span css={tw`mx-1`}>Check</span>
                            </label>

                            <label css={tw`mx-3`}>
                                <input type="radio" name="choice" value="nopay" checked={selectedChoice === 'nopay'} onChange={handleSelectedChoice}/>
                                <span css={tw`mx-1`}>I prefer not to be paid.</span>
                            </label>
                        </div>

                        <div css={tw`mt-4 flex justify-between`}>
                            <Button css={tw``} type="button" onClick={() => history.push('/survey/demographics')}>Back</Button>

                            {/* <a css={tw`mx-4 text-link cursor-pointer`} onClick={() => {}}>I prefer not to be paid.</a> */}

                            <Button
                                disabled={!selectedChoice}
                                css={tw``}
                                onClick={() => {
                                    setPaymentInput({
                                        ...paymentInput,
                                        type: selectedChoice,
                                    });

                                    if (selectedChoice === 'nopay') {
                                        submitForm();
                                    }
                                }}
                                type="button"
                            >Next
                            </Button>
                        </div>
                    </>
            }

            <SimulationDebugger/>

        </ContainerStyle>
    );
};

