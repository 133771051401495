import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { termOverrideName } from '@/constants/terms';
import RadioQuestion from '@/components/survey/RadioQuestion';
import {
    AgeChoices,
    AgreeChoices,
    ChildrenInput,
    FamilyInput,
    YesNoChoices
} from '@/components/survey/surveyQuestions';
import tw from 'twin.macro';
import { Button, Spinner } from '@/components/common';
import { useHistory } from 'react-router-dom';
import SelectQuestion from '@/components/survey/SelectQuestion';
import { useGetSurveyAnswers, useUpdateSurvey } from '@/api/simulation/SimulationAPI';
import { formatISO } from 'date-fns';
import AgreeStatementRow from '@/components/survey/AgreeStatementRow';
import AgreeStatementsTable from '@/components/survey/AgreeStatementsTable';
import _ from 'lodash';
import { numChildrenChoices } from '@/components/survey/family/familyQuestions';

const q15a = {
    id: 'q15a',
    label: 'Do you have children?',
    choices: YesNoChoices,
};
const q15b = {
    id: 'q15b',
    showIf: 'q15a__Y',
    label: 'How many children do you have?',
    min: 1,
    max: 10
};
const q16 = {
    id: 'q16',
    label: `Did you have (or do you expect to have) a new baby sometime between now and the end of the ${termOverrideName} term?`,
    choices: YesNoChoices
};

const q17 = {
    id: 'q17',
    showIf: 'q15b>=1',
    label: 'How old is your child?',
    labelAlt: 'How old are your children?',
    choices: AgeChoices
};

const q18 = [
    {
        id: 'q18a',
        showIf: 'q15a__Y',
        label: 'The childcare available to me (through family, friends, daycare or paid caretakers) provides me with enough time for my schoolwork.',
        choices: AgreeChoices
    },
    {
        id: 'q18b',
        showIf: 'q15a__Y',
        label: 'I would pay for more childcare and spend more time on my studies, if I could afford to financially.',
        choices: AgreeChoices
    },
    {
        id: 'q18c',
        showIf: 'q15a__Y',
        label: 'I would take more classes if I could afford to pay for more childcare.',
        choices: AgreeChoices
    }
];

const childrenSelectInputStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
};

export default () => {
    const history = useHistory();
    const survey = useGetSurveyAnswers();
    const updateSurvey = useUpdateSurvey();

    const [ loading, setLoading ] = useState<boolean>(false);
    const [ familyInput, setFamilyInput ] = useState<FamilyInput>(survey.family);
    const [ childrenInput, setChildrenInput ] = useState<ChildrenInput>(survey.family.q17 || {});

    useEffect(() => {
        setFamilyInput(survey.family);
        setChildrenInput(survey.family.q17 || {});
    }, [ survey ]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const field = event.target.name;
        let value: string | number = event.target.value;

        if (field === 'q15b') {
            value = parseInt(value);
        }

        setFamilyInput({
            ...familyInput,
            [field]: value
        });
    };

    // This is a dedicated listener for question 17 due to nested state objects not always reflecting latest values.
    const handleChildrenInput = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setChildrenInput({
            ...childrenInput,
            [event.target.name]: event.target.value
        });
    };

    const onSubmit = (e: React.FormEvent) => {
        setLoading(true);
        e.preventDefault();
        e.stopPropagation();

        const finalInput: FamilyInput = {
            ...familyInput,
            q17: childrenInput,
            submittedTs: formatISO(new Date())
        };
        // if answered no children then we need to clear all children related answers.
        if (finalInput.q15a === 'N') {
            finalInput.q15b = 0;
            finalInput.q17 = {};
            finalInput.q18a = finalInput.q18b = finalInput.q18c = '';
        }

        updateSurvey({family: finalInput})
            .then(() => {
                history.push('/survey/time1');
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });

    };

    const childrenInputs = useMemo(() => {
        return Array.from({length: familyInput.q15b}, (_, k) => {
            const index = k + 1;
            const key = `child${index}`;
            return (
                <SelectQuestion containerStyle={childrenSelectInputStyle} key={key} required={true} label={`Child #${index}`} name={key} selected={childrenInput[key]} choices={AgeChoices} onChange={handleChildrenInput}/>
            );
        });
    }, [ familyInput.q15b, childrenInput ]);

    const q17Label = useMemo<string>(() => {
        return familyInput.q15b === 1 ? q17.label : q17.labelAlt;
    }, [ familyInput.q15b ]);

    return (
        <div>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />
            <form onSubmit={onSubmit}>
                <RadioQuestion selected={familyInput.q15a} required={true} label={q15a.label} name={q15a.id} choices={q15a.choices} onChange={handleChange} inlineChoices={true}/>
                {
                    familyInput.q15a === 'Y' ?
                        <SelectQuestion containerStyle={{border: 'none', marginBottom: '10px'}} required={true} label={q15b.label} name={q15b.id} selected={`${familyInput.q15b || ''}`} choices={numChildrenChoices} onChange={(e) => {
                            const n = parseInt(e.target.value);
                            setFamilyInput({
                                ...familyInput,
                                q15b: _.clamp(n, q15b.min, q15b.max)
                            });
                        }}
                        />


                        : null
                }

                <RadioQuestion selected={familyInput.q16} required={true} label={q16.label} name={q16.id} choices={q16.choices} onChange={handleChange} inlineChoices={true}/>

                {
                    familyInput.q15a === 'Y' && familyInput.q15b >= 1 ?
                    // Question 17, 18

                        <div>
                            <p css={tw`mb-3`}>{q17Label}</p>

                            <div css={tw`w-1/2 md:w-1/4 pl-2`}>
                                {childrenInputs}
                            </div>

                            {/* <p css={tw`italic mb-2`}>Please rate how strongly you agree or disagree with each of the following statements.</p> */}
                            <h4 css={tw`mt-8 mb-8`}>Please rate how strongly you agree or disagree with each of the following statements.</h4>
                            <AgreeStatementsTable>
                                {
                                    q18.map(statement => (
                                        <AgreeStatementRow
                                            key={statement.id}
                                            statement={statement}
                                            selected={familyInput[statement.id as 'q18a' | 'q18b' | 'q18c'] || ''}
                                            onChange={handleChange}
                                        />
                                    ))
                                }
                            </AgreeStatementsTable>
                        </div>

                        : null
                }

                <div css={tw`mt-8 flex justify-between`}>
                    <Button type="button" onClick={() => history.push('/survey/work')}>Back</Button>
                    <Button type="submit">Next</Button>
                </div>
            </form>
        </div>
    );
};
