import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { setConfig } from 'react-hot-loader';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import { isAxiosError } from '@/api/http';
import axios from 'axios';
import config from '@/config';
import { isJWTExpiredError } from '@/api/graphUtils';

Sentry.init({
    dsn: config.sentry.enabled ? config.sentry.dsn : '',
    enabled: config.sentry.enabled,
    environment: config.sentry.environment,
    release: config.version,

    integrations: [
        new Integrations.BrowserTracing(),
        new CaptureConsole({
            levels: [ 'error' ]
        })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    beforeSend: (event: Sentry.Event, hint?: Sentry.EventHint) => {
        const original = hint?.originalException;

        if (isJWTExpiredError(original)) return null;

        // Ignore any exceptions caught and handled as a result of aborting
        // an axios request. No reason to spam up Sentry with things we don't need
        // to do anything about. Also ignores any axios specific error because there is
        // almost never anything to do about those and any 500 error would be seen on the
        // API side of things.
        if (isAxiosError(original)) {
            if (original.response?.status === 401) {
                return null;
            }
            // // As long as there is a response and the status is not 422 or a 5XX error, ignore
            // // the error since there is likely nothing to be done.
            // if (original.response && (original.response.status < 500 && original.response.status !== 422)) {
            //     return null;
            // }

            // Set the response data on the Sentry report if the status code is 422 or 500 since we
            // want to be able to act upon this mre easily. Without this, the actual response data is
            // lost to the void, making it difficult to debug the issue better.
            if (original.response) {
                Sentry.configureScope(scope => {
                    scope.setExtra('response_data', original.response?.data);
                });
            }
        } else if (axios.isCancel(original)) {
            return null;
        }

        // Catch the before-send hook and throw the error back into the console
        // for the developer. If sentry is enabled, don't do this, just report it
        // to the service.
        if (!config.sentry.enabled) {
            if (original) {
                console.error('Captured Sentry exception event:', original);
            } else {
                console.warn('Captured Sentry event, no original exception found on object.', event.message);
            }
        }

        return config.sentry.enabled ? event : null;
    },
});

// Prevents page reloads while making component changes which
// also avoids triggering constant loading indicators all over
// the place in development.
//
// @see https://github.com/gaearon/react-hot-loader#hook-support
setConfig({ reloadHooks: false });

ReactDOM.render(<App/>, document.getElementById('app'));
