import _ from 'lodash';
import store from '@/store';
import updateSimResults from '@/api/simulation/updateSimResults';
import { hashSelectedCourse } from '@/api/transformers';

export default async (courseIdHash: string): Promise<any> => {
    const { student } = store.getActions();
    const studentDetails = await student.getStudentDetails();

    const updatedCart = _.cloneDeep(studentDetails.shoppingCart);

    const removed = _.remove(updatedCart, o => hashSelectedCourse(o) === courseIdHash);

    return updateSimResults(studentDetails.studentId, {
        shoppingCart: updatedCart
    });
};
