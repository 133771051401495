import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const SurveyContainerStyle = styled.div`
    ${tw`w-full lg:px-12 max-w-screen-xl`}
    h4 {
        ${tw`font-normal`}
    }
    select {
        ${tw`py-1`}
    }
`;

export const Demo8ContainerStyle = styled.div`
    ${tw`py-3 flex items-center`}
    &:not(:last-child) {
        ${tw`border-0 border-solid border-b border-gray-300`}
    }

    @media(max-width: 1024px) {
        margin-bottom: 10px;
        display: block;
    }
    
    border: none !important;

`;
