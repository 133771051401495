import { QuestionParams, SimulationQuestion } from '@/constants/questions';
import { parseCourseShortName, parseMeetingPattern, prependArticle } from '@/api/transformers';
import { InstructionModeSurveyFormat } from '@/constants/instructionModes';
import _ from 'lodash';

export const interpolateQuestion = (rawQuestion: SimulationQuestion, params: QuestionParams): SimulationQuestion => {
    const question = _.cloneDeep(rawQuestion);

    const { originalClass, replacementClass, previousClass, } = params;

    const originalCourseName = parseCourseShortName(originalClass);
    const originalCourseMode = originalClass.sectionMode!;
    let originalCourseMeetingTimes = '';
    if (originalCourseMode !== 'OA') {
        originalCourseMeetingTimes = originalClass.selectedSections.flatMap(section => section.meetings.map((m: any) => parseMeetingPattern(m))).join(' ');
    }

    const replacementCourseName = parseCourseShortName(replacementClass);
    const replacementCourseMode = replacementClass.sectionMode!;
    let replacementCourseMeetingTimes = '';
    if (replacementCourseMode !== 'OA') {
        replacementCourseMeetingTimes = replacementClass.selectedSections.flatMap(section => section.meetings.map((m: any) => parseMeetingPattern(m))).join(' ');
    }

    const previousCourseName = parseCourseShortName(previousClass);
    const previousCourseMode = previousClass.sectionMode!;

    const originalAan = prependArticle(InstructionModeSurveyFormat[originalCourseMode]);
    const replacementAan = prependArticle(InstructionModeSurveyFormat[replacementCourseMode]);
    const previousAan = prependArticle(InstructionModeSurveyFormat[previousCourseMode]);

    const originalCourseModeWithTimes = originalCourseMode === 'OA' ? originalCourseMode : `${originalCourseMode}, ${originalCourseMeetingTimes}`;
    const replacementCourseModeWithTimes = replacementCourseMode === 'OA' ? replacementCourseMode : `${replacementCourseMode}, ${replacementCourseMeetingTimes}`;

    const questionText = _.upperFirst(question.label
        .replace(/{{originalAan}}/g, originalAan)
        .replace(/{{replacementAan}}/g, replacementAan)
        .replace(/{{previousAan}}/g, previousAan)
        .replace(/{{originalCourseName}}/g, originalCourseName)
        .replace(/{{originalCourseMode}}/g, InstructionModeSurveyFormat[originalCourseMode] || '')
        .replace(/{{replacementCourseName}}/g, replacementCourseName)
        .replace(/{{replacementCourseMode}}/g, InstructionModeSurveyFormat[replacementCourseMode] || '')
        .replace(/{{previousCourseMode}}/g, InstructionModeSurveyFormat[previousCourseMode] || '')
        .replace(/{{previousCourseName}}/g, previousCourseName)
        .replace(/{{originalCourseMeetingTimes}}/g, originalCourseMeetingTimes)
        .replace(/{{replacementCourseMeetingTimes}}/g, replacementCourseMeetingTimes)
        .replace(/{{originalCourseModeWithTimes}}/g, originalCourseModeWithTimes)
        .replace(/{{replacementCourseModeWithTimes}}/g, replacementCourseModeWithTimes)
        .replace(/NoReplacement.*\?/g, 'no class at all')
    );

    const choices = question.choices.map(choice => ({
        ...choice,
        label: _.upperFirst(choice.label
            .replace(/{{originalAan}}/g, originalAan)
            .replace(/{{replacementAan}}/g, replacementAan)
            .replace(/{{previousAan}}/g, previousAan)
            .replace(/{{originalCourseName}}/g, originalCourseName)
            .replace(/{{originalCourseMode}}/g, InstructionModeSurveyFormat[originalCourseMode] || '')
            .replace(/{{replacementCourseName}}/g, replacementCourseName)
            .replace(/{{replacementCourseMode}}/g, InstructionModeSurveyFormat[replacementCourseMode] || '')
            .replace(/{{previousCourseMode}}/g, InstructionModeSurveyFormat[previousCourseMode] || '')
            .replace(/{{previousCourseName}}/g, previousCourseName)
            .replace(/{{originalCourseMeetingTimes}}/g, originalCourseMeetingTimes)
            .replace(/{{replacementCourseMeetingTimes}}/g, replacementCourseMeetingTimes)
            .replace(/{{originalCourseModeWithTimes}}/g, originalCourseModeWithTimes)
            .replace(/{{replacementCourseModeWithTimes}}/g, replacementCourseModeWithTimes)
        )
    }));

    return {
        id: question.id,
        type: question.type,
        label: questionText,
        choices,
        defaultValue: question.defaultValue,
    };
};
