import React, { useState } from 'react';
import { StudentInfo } from '@/api/types';
import { StudentInfoContainerStyle } from '@/components/dashboard/styled';
import { parseMajorFromStudentInfo } from '@/api/transformers';
import tw from 'twin.macro';
import AcademicPlanTable from '@/components/enrollment-history/AcademicPlanTable';
import { Modal } from '@/components/common';
import { SimulationResultsRaw } from '@/api/simulation/SimulationAPI';
import EnrollmentHistoryTable from '@/components/enrollment-history/EnrollmentHistoryTable';
import styled, { css } from 'styled-components/macro';

interface Props {
    student: StudentInfo | null;
    results: SimulationResultsRaw | null;
    onClose: () => void;
}

interface TabStyleProps {
    active: boolean;
}
const TabStyle = styled.div<TabStyleProps>`
    ${tw`cursor-pointer mr-3 hover:opacity-75 text-lg`}
    
    ${(props) => props.active ? css`
        ${tw`border-0 border-solid border-b-2 border-blue-500`}
        span {
            ${tw`font-bold text-blue-500`}
        
        }
    ` : ''}
    
`;

export default ({ student, results, onClose }: Props) => {
    if (!student) return null;

    const [ activeTab, setActiveTab ] = useState<'plans' | 'enrollments'>('enrollments');

    return (
        <Modal visible={!!student} onDismissed={onClose}>
            <StudentInfoContainerStyle>

                <div css={tw`flex justify-between items-start`}>
                    <h3 css={tw`mb-4`}>Student: {student.studentId}</h3>
                    {/* <a css={tw`cursor-pointer text-link hover:opacity-75`} onClick={onClose}>Close</a> */}
                </div>

                <p css={tw`mb-3 font-medium`}>Current major: {parseMajorFromStudentInfo(student)}</p>

                <div css={tw`flex flex-row mt-3 mb-4`}>
                    <TabStyle active={activeTab === 'enrollments'} onClick={() => setActiveTab('enrollments')}>
                        <span>Enrollment History</span>
                    </TabStyle>

                    <TabStyle active={activeTab === 'plans'} onClick={() => setActiveTab('plans')}>
                        <span>Academic Plans</span>
                    </TabStyle>

                </div>

                {
                    activeTab === 'enrollments' ?
                        <EnrollmentHistoryTable enrollments={student.enrollments}/>
                        : null
                }

                {
                    activeTab === 'plans' ?
                        <AcademicPlanTable showFormattedColumns={true} plans={student.academicPlans}/>
                        : null
                }


            </StudentInfoContainerStyle>
        </Modal>

    );
};
