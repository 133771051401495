import { _adapters } from 'chart.js';
// import this after chart.js to override
import 'chartjs-adapter-date-fns';

// https://github.com/chartjs/chartjs-adapter-date-fns/blob/master/src/index.js
import {
    format, isValid,
    startOfSecond, startOfMinute, startOfHour, startOfDay,
    startOfWeek, startOfMonth, startOfQuarter, startOfYear,
    addMilliseconds, addSeconds, addMinutes, addHours,
    addDays, addWeeks, addMonths, addQuarters, addYears,
    differenceInMilliseconds, differenceInSeconds, differenceInMinutes,
    differenceInHours, differenceInDays, differenceInWeeks,
    differenceInMonths, differenceInQuarters, differenceInYears,
    endOfSecond, endOfMinute, endOfHour, endOfDay,
    endOfWeek, endOfMonth, endOfQuarter, endOfYear, parse,
} from 'date-fns';

const FORMATS = {
    datetime: 'MMM d, yyyy, h:mm:ss a',
    millisecond: 'h:mm:ss.SSS a',
    second: 'h:mm:ss a',
    minute: 'h:mm a',
    hour: 'ha',
    day: 'MMM d',
    week: 'dd',
    month: 'MMM yyyy',
    quarter: '[Q]Q - yyyy',
    year: 'yyyy',
};

_adapters._date.override({
    formats: function () {
        return FORMATS;
    },

    parse: function (value: any) {
        if (!value) {
            return null;
        }
        if (isValid(value)) return value;

        return parse(value, 'yyyy-MM-dd', new Date());
    },

    format: function (time: any, fmt: any) {
        return format(time, fmt, this.options);
    },

    add: function (time: any, amount: any, unit: any) {
        switch (unit) {
            case 'millisecond': return addMilliseconds(time, amount);
            case 'second': return addSeconds(time, amount);
            case 'minute': return addMinutes(time, amount);
            case 'hour': return addHours(time, amount);
            case 'day': return addDays(time, amount);
            case 'week': return addWeeks(time, amount);
            case 'month': return addMonths(time, amount);
            case 'quarter': return addQuarters(time, amount);
            case 'year': return addYears(time, amount);
            default: return time;
        }
    },

    diff: function (max: any, min: any, unit: any) {
        switch (unit) {
            case 'millisecond': return differenceInMilliseconds(max, min);
            case 'second': return differenceInSeconds(max, min);
            case 'minute': return differenceInMinutes(max, min);
            case 'hour': return differenceInHours(max, min);
            case 'day': return differenceInDays(max, min);
            case 'week': return differenceInWeeks(max, min);
            case 'month': return differenceInMonths(max, min);
            case 'quarter': return differenceInQuarters(max, min);
            case 'year': return differenceInYears(max, min);
            default: return 0;
        }
    },

    startOf: function (time: any, unit: any, weekday: any) {
        switch (unit) {
            case 'second': return startOfSecond(time);
            case 'minute': return startOfMinute(time);
            case 'hour': return startOfHour(time);
            case 'day': return startOfDay(time);
            case 'week': return startOfWeek(time);
            case 'isoWeek': return startOfWeek(time, { weekStartsOn: weekday });
            case 'month': return startOfMonth(time);
            case 'quarter': return startOfQuarter(time);
            case 'year': return startOfYear(time);
            default: return time;
        }
    },

    endOf: function (time: any, unit: any) {
        switch (unit) {
            case 'second': return endOfSecond(time);
            case 'minute': return endOfMinute(time);
            case 'hour': return endOfHour(time);
            case 'day': return endOfDay(time);
            case 'week': return endOfWeek(time);
            case 'month': return endOfMonth(time);
            case 'quarter': return endOfQuarter(time);
            case 'year': return endOfYear(time);
            default: return time;
        }
    },
});
