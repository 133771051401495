import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import searchClasses, { convertSearchCriteriaToFilter } from '@/api/graphql/queries/searchClasses';
import { SearchResultsContainerStyle } from './styled';
import { secondChanceOfferVar } from '@/api/graph';
import { InstitutionName } from '@/constants/institutions';
import { termOverrideName } from '@/constants/terms';
import tw from 'twin.macro';
import { Spinner } from '../common';
import SearchResultsList from './SearchResultsList';
import {
    CourseClass,
    CourseClassExtract,
    CourseGroup,
    SelectedCourse
} from '@/api/types';
import store, { useStoreState } from '@/store';
import ShoppingCartWidget from '../shopping-cart/ShoppingCartWidget';
import AddClassErrorModal from '@/components/search-results/add-class-error/AddClassErrorModal';
import { useGetSimResults } from '@/api/simulation/SimulationAPI';
import useSimulatedResults from '@/components/search-results/useSimulatedResults';
import SecondChanceModal from '@/components/search-results/SecondChanceModal';
import { useQuery, useReactiveVar } from '@apollo/client';
import useSimulationHooks from '@/components/simulation/useSimulationHooks';
import ReplacementInfo from '@/components/replacements/ReplacementInfo';
import SearchControl from '@/components/search-results/SearchControl';
import useCurrentlyReplacing from '@/components/replacements/useCurrentlyReplacing';
import Paginator from '@/components/search-results/Paginator';
import { AddClassError } from '@/components/shopping-cart/api/addClass';
import replaceClass from '@/components/shopping-cart/api/replaceClass';
import { getCourseSectionComponents } from '@/utils/sectionUtils';
import { checkForConflictingTimes, validateAddRequirements } from '@/components/shopping-cart/api/addCourse';
import updateSimResults from '@/api/simulation/updateSimResults';
import SelectCourseComponentsModal from '@/components/course-components/SelectCourseComponentsModal';
import _ from 'lodash';

const limit = 40;

export type AddCourseProps = {
    selectedCourse: SelectedCourse;
    courseGroup: CourseGroup;
    skipValidation?: boolean;
}

export default () => {
    const history = useHistory();
    const { student, search: { setReplacementFiltersForConflictingSchedule, setAcknowledgedCourseRequisitesMap } } = store.getActions();

    const filters = useStoreState(state => state.search.filters);
    const acknowledgedCourseRequisitesMap = useStoreState(state => state.search.acknowledgedCourseRequisitesMap);

    const { shoppingCart, data: simulationData } = useGetSimResults();

    const [ loading, setLoading ] = useState<boolean>(false);
    const [ showSecondChance, setShowSecondChance ] = useState<boolean>(false);

    const [ addClassError, setAddClassError ] = useState<AddClassError | undefined>(undefined);
    const secondChanceOffer = useReactiveVar(secondChanceOfferVar);

    const [ pendingAddCourseProps, setPendingAddCourseProps ] = useState<Partial<AddCourseProps> | null>({});
    const [ showSelectCourseComponentsModal, setShowSelectCourseComponentsModal ] = useState<boolean>(false);

    const [ showAddClassErrorModal, setShowAddClassErrorModal ] = useState<boolean>(false);

    const [ rawCourseResults, setRawCourseResults ] = useState<CourseGroup[]>([]);

    const [ totalCount, setTotalCount ] = useState<number>(0);
    const [ page, setPage ] = useState<number>(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [ page ]);

    const { isSimulationInProgress, submitReplacement } = useSimulationHooks();
    const currentlyReplacing = useCurrentlyReplacing();

    const nextScreen = useMemo<'/sim' | '/cart'>(() => (isSimulationInProgress ? '/sim' : '/cart'),
        [ isSimulationInProgress ]);

    const handleAddCourseClassItem = async ({
        selectedCourse,
        courseGroup,
        skipValidation
    }: AddCourseProps) => {
        setLoading(true);
        if (skipValidation) {
            setAddClassError(undefined);
            setShowAddClassErrorModal(false);
        }

        if (!skipValidation && !acknowledgedCourseRequisitesMap[selectedCourse.courseId]) {
        // if (!skipValidation) {
            const validationResult = await validateAddRequirements(selectedCourse);
            if (!validationResult.result) {
                console.log('cannot add error: ', selectedCourse);
                setAddClassError({
                    unsatisfiedCourseRequisites: {
                        courseGroup: courseGroup,
                        selectedCourse: selectedCourse,
                        unsatisfiedRequisiteGroupings: [],
                        message: validationResult.requirementGroupDescriptionFull
                    },
                });
                setShowAddClassErrorModal(true);

                setAcknowledgedCourseRequisitesMap({
                    ...acknowledgedCourseRequisitesMap,
                    [courseGroup.courseId]: true,
                });

                setLoading(false);
                return;
            }
        }

        // 1. Check if selected course has multiple components
        const componentTypes = getCourseSectionComponents(courseGroup);
        if (componentTypes.length !== selectedCourse.selectedSections.length) {
            setPendingAddCourseProps({
                selectedCourse,
                courseGroup,
            });
            setShowSelectCourseComponentsModal(true);
            setLoading(false);
            return;
        }


        // 2. Check for existing course in cart
        const existingCourse = _.find(shoppingCart, {courseId: selectedCourse.courseId});
        if (existingCourse) {
            setAddClassError({
                conflictingClasses: [ selectedCourse, existingCourse ]
            });
            setShowAddClassErrorModal(true);
            setLoading(false);
            return;
        }

        // 3. Check for conflicting times schedule
        const possibleConflict = checkForConflictingTimes(selectedCourse, shoppingCart);
        if (possibleConflict) {
            setAddClassError({
                conflictingClasses: [ selectedCourse, possibleConflict ]
            });
            setShowAddClassErrorModal(true);
            setLoading(false);
            return;
        }

        // 4. Finally add to the shopping cart
        const studentDetails = await student.getStudentDetails();
        await updateSimResults(studentDetails.studentId, {
            shoppingCart: [ ...shoppingCart, selectedCourse ]
        });

        // 5. Now if they are replacing an existing course then offer chance to find replacement
        const secondChanceOffer = secondChanceOfferVar();
        if (secondChanceOffer?.courseId === selectedCourse.courseId) {
            console.log('clearing second chance offer');
            secondChanceOfferVar(null);
        }

        if (isSimulationInProgress && currentlyReplacing &&
            // last condition is to prevent overriding replacementResponse
            // when adding a class via Additional Changes workflow
            !simulationData.currentStage?.replacementResponse?.submittedTs
        ) {
            await submitReplacement(selectedCourse);
        }
        setAddClassError(undefined);
        setShowAddClassErrorModal(false);
        setLoading(false);
        history.push(nextScreen);
    };

    const cancelCourseSelectionModal = () => {
        setPendingAddCourseProps(null);
        setShowSelectCourseComponentsModal(false);
    };

    // Not to be confused with replacing the unavailable class.
    // handleConflictingReplace is specifically for attempting to add a class that has an overlap
    // with a class that is already in the shopping cart. This is not related to the simulation.
    const handleConflictingReplace = (classToAdd: SelectedCourse, classToRemove: SelectedCourse) => {
        setLoading(true);

        replaceClass(classToAdd, classToRemove)
            .then(async (addedClass) => {
                if (currentlyReplacing) {
                    try {
                        await submitReplacement(addedClass);
                    } catch (err) {
                        console.error(err);
                    }
                }

                setAddClassError(undefined);
                setShowAddClassErrorModal(false);

                // only show secondChanceOffer for replacing different courses
                if (classToAdd.courseId !== classToRemove.courseId) {

                    secondChanceOfferVar(classToRemove);
                    setLoading(false);
                } else {
                    // otherwise continue to next screen
                    history.push(nextScreen);
                }

            })
            .catch(err => console.error(err));
    };

    useEffect(() => {
        if (secondChanceOffer) {
            setShowSecondChance(true);
        }
    }, [ secondChanceOffer ]);

    const handleAcceptSecondChance = () => {
        window.scrollTo(0, 0);

        setReplacementFiltersForConflictingSchedule(secondChanceOffer!);
        setTimeout(() => {
            secondChanceOfferVar(null);
        }, 50);
        setShowSecondChance(false);
    };
    const handleDeclineSecondChance = () => {
        setShowSecondChance(false);
        history.push(nextScreen);
    };

    const handleCancelAddClass = () => {
        setAddClassError(undefined);
        setShowAddClassErrorModal(false);
    };

    const { loading: queryLoading, data, fetchMore } = useQuery(searchClasses, {
        variables: {
            ...convertSearchCriteriaToFilter(filters),
            offset: limit * page,
            limit,
        },
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
    });

    const fetchMoreHandler = (pageProp: number) => {
        fetchMore({
            variables: {
                offset: (pageProp - 1) * limit,
                limit,
                ...convertSearchCriteriaToFilter(filters),
            },
        });
        setPage(pageProp - 1);
    };

    useEffect(() => {
        console.log('filters: ', filters);
        if (data) {
            console.log('data.items_aggregate.aggregate.totalCount: ', data.items_aggregate.aggregate.totalCount);
            console.log('data.items: ', data.items);
            setTotalCount(data.items_aggregate.aggregate.totalCount);
            setRawCourseResults(data.items);
        }
    }, [ data ]);

    const { sectionCount, courses } = useSimulatedResults(rawCourseResults);

    return (
        <SearchResultsContainerStyle>
            <Spinner.Overlay overlayMode="light" visible={loading || queryLoading} size={'large'} />
            <h3>Search Results</h3>

            <div css={tw`lg:w-5/6 mb-5`}>
                <p css={tw`mb-3 text-gray-500`}>{InstitutionName[filters.institution]} | {termOverrideName}</p>

                <div css={tw`flex mb-3`}>
                    <ShoppingCartWidget items={shoppingCart}/>
                </div>

                {
                    isSimulationInProgress ?
                        <ReplacementInfo css={tw`mb-3 `}/>
                        :
                        <SearchControl/>
                }
            </div>

            <div>
                <p css={tw`pl-1 font-medium text-cunyblue border border-solid border-gray-500`}>{sectionCount} class section(s) found</p>

                <SearchResultsList onAddClass={handleAddCourseClassItem} items={courses}/>
                <Paginator
                    totalItemsCount={totalCount}
                    onChange={page => fetchMoreHandler(page)}
                    itemsCountPerPage={limit}
                    activePage={page + 1}
                />
            </div>

            <AddClassErrorModal
                isVisible={showAddClassErrorModal}
                onCancel={handleCancelAddClass}
                onReplace={handleConflictingReplace}
                error={addClassError}
                onAddOverride={handleAddCourseClassItem}
            />
            <SecondChanceModal isVisible={showSecondChance} secondChanceOffer={secondChanceOffer} onAccept={handleAcceptSecondChance} onDecline={handleDeclineSecondChance}/>

            <SelectCourseComponentsModal isVisible={showSelectCourseComponentsModal} onClose={cancelCourseSelectionModal} onAddOverride={handleAddCourseClassItem} addCourseProps={pendingAddCourseProps}/>

        </SearchResultsContainerStyle>
    );
};
