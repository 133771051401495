import { SimulationData } from '@/store/simulation/types';
import { parseEncounteredStages } from '@/store/simulation/simulationUtils';

export interface SimulatedEvent {
    // desc is more for friendly internal identifiers e.g. simulation debugger
    desc: string;
    question: string;
    questionAlt?: string;
    questionValidator?: (simData: SimulationData) => 'question' | 'questionAlt';
    choices: SimulatedEventChoice[];
}

export interface SimulatedEventChoice {
    response: EventResponseEnum;
    value: string;
    label: string;
    hideIf?: string;
}

export enum EventResponseEnum {
    DifferentCourseAsynchronous = 'DifferentCourseAsynchronous',
    SameCourseAsynchronous = 'SameCourseAsynchronous',


    DifferentCourseSynchronous = 'DifferentCourseSynchronous',
    SameCourseSynchronous = 'SameCourseSynchronous',

    SameCourseOnCampus = 'SameCourseOnCampus',

    SameCourseOnlineSynchronous = 'SameCourseOnlineSynchronous',

    OriginalCourseAsynchronous = 'OriginalCourseAsynchronous',
    OriginalCourseSynchronous = 'OriginalCourseSynchronous',
    OriginalCourseOnCampus = 'OriginalCourseOnCampus',
    OriginalCourseOnlineSynchronous = 'OriginalCourseOnlineSynchronous',

    DifferentCourseOnlineSynchronous = 'DifferentCourseOnlineSynchronous',
    DifferentCourseOnCampus = 'DifferentCourseOnCampus',

    NoReplacement = 'NoReplacement',

    DifferentCourseEntirely = 'DifferentCourseEntirely',

    // These are unused, just leaving in case we need to refer to.
    // DifferentCourseOnlineSynchronous = 'DifferentCourseSynchronous',
}

export const ConditionNameByStage: { [key: string]: string } = {
    t1r1: 'Original course no longer has any asynchronous courses available',
    t1r2: 'Replacement course no longer has any sections at available times',
    t1r3: 'Original course no longer has any sections available',
    t1r4: 'Asynchronous sections for all courses no longer available',

    t2r1: 'Original course no longer has any Online Synchronous sections available',
    t2r2: 'Original/Replacement asynchronous course is no longer available',
    t2r3: 'Original/Replacement course no longer has any On Campus sections available',
    t2r4: 'Online Synchronous sections for all courses no longer available',

    t3r1: 'Original course no longer has any On Campus sections available',
    t3r2: 'Original/Replacement asynchronous course is no longer available',
    t3r3: 'Original/Replacement course no longer has any Online Synchronous sections available',
    t3r4: 'On Campus sections for all courses no longer available',
};

const conditions: {[key: string]: SimulatedEvent} = {
    t1r1: {
        desc: 'Original asynchronous course no longer available',
        question: 'New Scenario: The section of {{originalCourse}} that you have selected is no longer available. There are no longer ANY <b>online asynchronous</b> sections of this course available. What would you like to do instead?',
        choices: [
            {
                response: EventResponseEnum.DifferentCourseAsynchronous,
                value: 'a',
                label: 'I want to switch to a <b>different asynchronous online course</b> (with no scheduled meetings online or on campus).'
            },
            {
                response: EventResponseEnum.SameCourseSynchronous,
                value: 'b',
                label: 'I want to switch to a <b>synchronous</b> section of the <b>same course</b> (which requires scheduled meetings online or on campus).'
            },
            {
                response: EventResponseEnum.DifferentCourseSynchronous,
                value: 'c',
                label: 'I would like to switch to a <b>different synchronous course</b> (which requires scheduled meetings online or on campus).'
            },
            {
                response: EventResponseEnum.NoReplacement,
                value: 'd',
                label: 'I don’t want to replace this course with anything else.'
            },
        ]
    },
    t1r2: {
        desc: 'Replacement course no longer has any sections at available times',
        question: 'New Scenario: The section of {{replacementCourse}} that you have selected is no longer available. There are still no <b>online asynchronous</b> sections of this course, and now synchronous sections of this course are ONLY <b>outside your available times</b> (which you marked on the calendar earlier).  What would you like to do instead?',
        choices: [
            {
                response: EventResponseEnum.DifferentCourseAsynchronous,
                value: 'a',
                label: 'I want to switch to a <b>different asynchronous online course</b> (with no scheduled meetings online or on campus).'
            },
            {
                response: EventResponseEnum.SameCourseSynchronous,
                value: 'b',
                label: 'I want to switch to a <b>synchronous</b> section of the <b>same course</b> (which requires scheduled meetings online or on campus), even though it is outside my available times.'
            },
            {
                response: EventResponseEnum.DifferentCourseSynchronous,
                value: 'c',
                label: 'I would like to switch to a <b>different synchronous course</b> (which requires scheduled meetings online or on campus).'
            },
            {
                response: EventResponseEnum.NoReplacement,
                value: 'd',
                label: 'I don’t want to replace this course with anything else.'
            },
        ]
    },
    t1r3: {
        desc: 'Original course no longer has any sections available',
        question: 'New Scenario: The section of {{replacementCourse}} that you have selected is no longer available. There are now <b>no sections</b> of this course available.  What would you like to do instead?',
        choices: [
            {
                response: EventResponseEnum.DifferentCourseAsynchronous,
                value: 'a',
                label: 'I want to switch to a <b>different asynchronous online course</b> (with no scheduled meetings online or on campus).'
            },
            {
                response: EventResponseEnum.DifferentCourseSynchronous,
                value: 'b',
                label: 'I would like to switch to a <b>different synchronous course</b> (which requires scheduled meetings online or on campus).'
            },
            {
                response: EventResponseEnum.NoReplacement,
                value: 'c',
                label: 'I don’t want to replace this course with anything else.'
            },
        ]
    },
    // Do not present choices A or B if student is coming from t1r3
    t1r4: {
        desc: 'Asynchronous sections for all courses no longer available',
        question: 'New Scenario: The section of {{replacementCourse}} that you have selected is no longer available. There are no longer ANY online asynchronous sections of ANY courses available. What would you like to do instead?',
        choices: [
            {
                hideIf: 't1r3',
                response: EventResponseEnum.SameCourseSynchronous,
                value: 'a',
                label: 'I want to switch to a <b>synchronous</b> section of the <b>same course</b> {{replacementCourse}} (which requires scheduled meetings online or on campus).'
            },
            {
                response: EventResponseEnum.OriginalCourseSynchronous,
                value: 'b',
                label: 'I want to switch to a <b>synchronous</b> section of my <b>original course choice</b> {{originalCourse}} (which requires scheduled meetings online or on campus).'
            },
            {
                hideIf: 't1r3',
                response: EventResponseEnum.DifferentCourseSynchronous,
                value: 'c',
                label: 'I would like to switch to a <b>different synchronous course</b> (which requires scheduled meetings online or on campus).'
            },
            {
                response: EventResponseEnum.DifferentCourseEntirely,
                value: 'd',
                label: 'I want to switch to a <b>different course entirely</b>.'
            },

            {
                response: EventResponseEnum.NoReplacement,
                value: 'e',
                label: 'I don’t want to replace this course with anything else.'
            },
        ]
    },

    t2r1: {
        desc: 'Original course no longer has any online synchronous sections available',
        question: 'New Scenario: The section of {{originalCourse}} that you have selected is no longer available.  There are no longer ANY <b>online synchronous</b> sections of this course available.  What would you like to do instead?',
        choices: [
            {
                response: EventResponseEnum.SameCourseAsynchronous,
                value: 'a',
                label: 'I want to switch to an <b>asynchronous online</b> section of the <b>same course</b>, which has no scheduled meetings online or on campus.'
            },
            {
                response: EventResponseEnum.SameCourseOnCampus,
                value: 'b',
                label: 'I want to switch to an <b>on-campus</b> section of the <b>same course</b>, which has scheduled meetings on-campus.'
            },
            {
                response: EventResponseEnum.DifferentCourseEntirely,
                value: 'c',
                label: 'I want to switch to a <b>different course entirely</b>.'
            },
            {
                response: EventResponseEnum.NoReplacement,
                value: 'd',
                label: 'I don’t want to replace this course with anything else.'
            },
        ]
    },
    t2r2: {
        desc: 'Original/Replacement asynchronous course is no longer available',
        question: 'New Scenario: The section of {{replacementCourse}} that you have selected is no longer available.  There are no longer ANY <b>online synchronous or asynchronous</b> sections of this course available.  What would you like to do instead?',
        questionAlt: 'New Scenario: The section of {{replacementCourse}} that you have selected is no longer available.  There are no longer ANY course modes available for this course.  What would you like to do instead?',
        questionValidator: (simData) => {
            const encounteredStages = parseEncounteredStages(simData);
            const encounteredR3 = encounteredStages.find((stage) => stage.id === 't2r3');
            return encounteredR3 ? 'questionAlt' : 'question';
        },
        choices: [
            {
                hideIf: 't2r3',
                response: EventResponseEnum.SameCourseOnCampus,
                value: 'a',
                label: 'I want to switch to an <b>on-campus</b> section of the <b>same course</b> (with scheduled meetings on-campus).'
            },
            {
                response: EventResponseEnum.DifferentCourseAsynchronous,
                value: 'b',
                label: 'I want to switch to a <b>different asynchronous online course</b> (with no scheduled meetings online or on campus).'
            },
            {
                response: EventResponseEnum.DifferentCourseOnCampus,
                value: 'c',
                label: 'I want to switch to a <b>different on-campus course</b> (with scheduled meetings on-campus).'
            },
            {
                response: EventResponseEnum.NoReplacement,
                value: 'd',
                label: 'I don’t want to replace this course with anything else.'
            },
        ]
    },
    t2r3: {
        desc: 'Original/Replacement course no longer has any on-campus sections available',
        question: 'New Scenario: The section of {{replacementCourse}} that you have selected is no longer available.  There are no longer ANY <b>synchronous online or on campus sections</b> of this course available.  What would you like to do instead?',
        choices: [
            {
                hideIf: 't2r2',
                response: EventResponseEnum.SameCourseAsynchronous,
                value: 'a',
                label: 'I want to switch to an <b>asynchronous</b> section of the <b>same course</b> (with no scheduled meetings online or on campus).'
            },
            {
                response: EventResponseEnum.DifferentCourseOnlineSynchronous,
                value: 'b',
                label: 'I want to switch to a <b>different online synchronous course</b> (with scheduled meetings online synchronous).'
            },
            {
                response: EventResponseEnum.DifferentCourseOnCampus,
                value: 'c',
                label: 'I want to switch to a <b>different on-campus course</b> (with scheduled meetings on-campus).'
            },
            {
                response: EventResponseEnum.NoReplacement,
                value: 'd',
                label: 'I don’t want to replace this course with anything else.'
            },
        ]
    },
    t2r4: {
        desc: 'online synchronous sections for all courses no longer available',
        question: 'New Scenario: The section of {{replacementCourse}} that you have selected is no longer available.  There are no longer ANY online synchronous sections of ANY courses available.  What would you like to do instead?',
        choices: [
            {
                response: EventResponseEnum.SameCourseAsynchronous,
                value: 'a',
                label: 'I want to switch to an <b>asynchronous online</b> section of the <b>same course</b> {{replacementCourse}} (with no scheduled meetings online or on campus).'
            },
            {
                hideIf: 't2r2',
                response: EventResponseEnum.OriginalCourseAsynchronous,
                value: 'b',
                label: 'I want to switch to an <b>asynchronous online</b> section of my <b>previous course choice</b> {{originalCourse}} (with no scheduled meetings online or on campus).'
            },
            {
                response: EventResponseEnum.SameCourseOnCampus,
                value: 'c',
                label: 'I want to switch to an <b>on-campus</b> section of the <b>same course</b> {{replacementCourse}} (with scheduled meetings on-campus).'
            },
            {
                hideIf: 't2r3',
                response: EventResponseEnum.OriginalCourseOnCampus,
                value: 'd',
                label: 'I want to switch to an <b>on-campus</b> section of my <b>previous course choice</b> {{originalCourse}} (with scheduled meetings on-campus).'
            },
            {
                response: EventResponseEnum.DifferentCourseEntirely,
                value: 'e',
                label: 'I want to switch to a <b>different course entirely</b>.'
            },
            {
                response: EventResponseEnum.NoReplacement,
                value: 'f',
                label: 'I don’t want to replace this course with anything else.'
            },
        ]
    },

    t3r1: {
        desc: 'Original course no longer has any on-campus sections available',
        question: 'New Scenario: The section of {{originalCourse}} that you have selected is no longer available.  There are no longer ANY <b>on-campus</b> sections of this course available.  What would you like to do instead?',
        choices: [
            {
                response: EventResponseEnum.SameCourseAsynchronous,
                value: 'a',
                label: 'I want to switch to an <b>asynchronous online</b> section of the <b>same course</b>, which has no scheduled meetings online or on campus.'
            },
            {
                response: EventResponseEnum.SameCourseOnlineSynchronous,
                value: 'b',
                label: 'I want to switch to an <b>online synchronous</b> section of the <b>same course</b>, which has scheduled meetings online synchronous.'
            },
            {
                response: EventResponseEnum.DifferentCourseEntirely,
                value: 'c',
                label: 'I want to switch to a <b>different course entirely</b>.'
            },
            {
                response: EventResponseEnum.NoReplacement,
                value: 'd',
                label: 'I don’t want to replace this course with anything else.'
            },
        ]
    },
    t3r2: {
        desc: 'Original/Replacement asynchronous course is no longer available',
        question: 'New Scenario: The section of {{replacementCourse}} that you have selected is no longer available.  There are no longer ANY <b>on-campus or asynchronous</b> sections of this course available.  What would you like to do instead?',
        questionAlt: 'New Scenario: The section of {{replacementCourse}} that you have selected is no longer available.  There are no longer ANY course modes available for this course.  What would you like to do instead?',
        questionValidator: (simData) => {
            const encounteredStages = parseEncounteredStages(simData);
            const encounteredR3 = encounteredStages.find((stage) => stage.id === 't3r3');
            return encounteredR3 ? 'questionAlt' : 'question';
        },
        choices: [
            {
                hideIf: 't3r3',
                response: EventResponseEnum.SameCourseOnlineSynchronous,
                value: 'a',
                label: 'I want to switch to an <b>online synchronous</b> section of the <b>same course</b> (with scheduled meetings online synchronous).'
            },
            {
                response: EventResponseEnum.DifferentCourseAsynchronous,
                value: 'b',
                label: 'I want to switch to a <b>different asynchronous online course</b> (with no scheduled meetings online or on campus).'
            },
            {
                response: EventResponseEnum.DifferentCourseOnlineSynchronous,
                value: 'c',
                label: 'I want to switch to a <b>different online synchronous course</b> (with scheduled meetings online synchronous).'
            },
            {
                response: EventResponseEnum.NoReplacement,
                value: 'd',
                label: 'I don’t want to replace this course with anything else.'
            },
        ]
    },
    t3r3: {
        desc: 'Original/Replacement course no longer has any online synchronous sections available',
        question: 'New Scenario: The section of {{replacementCourse}} that you have selected is no longer available.  There are no longer ANY <b>synchronous online or on campus sections</b> of this course available.  What would you like to do instead?',
        choices: [
            {
                hideIf: 't3r2',
                response: EventResponseEnum.SameCourseAsynchronous,
                value: 'a',
                label: 'I want to switch to an <b>asynchronous</b> section of the <b>same course</b> (with no scheduled meetings online or on campus).'
            },
            {
                response: EventResponseEnum.DifferentCourseOnCampus,
                value: 'b',
                label: 'I want to switch to a <b>different on-campus course</b> (with scheduled meetings on-campus).'
            },
            {
                response: EventResponseEnum.DifferentCourseOnlineSynchronous,
                value: 'c',
                label: 'I want to switch to a <b>different online synchronous course</b> (with scheduled meetings online synchronous).'
            },
            {
                response: EventResponseEnum.NoReplacement,
                value: 'd',
                label: 'I don’t want to replace this course with anything else.'
            },
        ]
    },
    t3r4: {
        desc: 'on-campus sections for all courses no longer available',
        question: 'New Scenario: The section of {{replacementCourse}} that you have selected is no longer available.  There are no longer ANY on-campus sections of ANY courses available.  What would you like to do instead?',
        choices: [
            {
                response: EventResponseEnum.SameCourseAsynchronous,
                value: 'a',
                label: 'I want to switch to an <b>asynchronous online</b> section of the <b>same course</b> {{replacementCourse}} (with no scheduled meetings online or on campus).'
            },
            {
                hideIf: 't3r2',
                response: EventResponseEnum.OriginalCourseAsynchronous,
                value: 'b',
                label: 'I want to switch to an <b>asynchronous online</b> section of my <b>previous course choice</b> {{originalCourse}} (with no scheduled meetings online or on campus).'
            },
            {
                response: EventResponseEnum.SameCourseOnlineSynchronous,
                value: 'c',
                label: 'I want to switch to an <b>online synchronous</b> section of the <b>same course</b> {{replacementCourse}} (with scheduled meetings online synchronous).'
            },
            {
                hideIf: 't3r3',
                response: EventResponseEnum.OriginalCourseOnlineSynchronous,
                value: 'd',
                label: 'I want to switch to an <b>online synchronous</b> section of my <b>previous course choice</b> {{originalCourse}} (with scheduled meetings online synchronous).'
            },
            {
                response: EventResponseEnum.DifferentCourseEntirely,
                value: 'e',
                label: 'I want to switch to a <b>different course entirely</b>.'
            },
            {
                response: EventResponseEnum.NoReplacement,
                value: 'f',
                label: 'I don’t want to replace this course with anything else.'
            },
        ]
    },

};



export default conditions;

