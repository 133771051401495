import { Option } from '@/api/types';

export type SearchCriteria = Option<CriteriaType>;

export enum CriteriaType {
    Between = 'BT',
    GreaterThan = 'GT',
    GreaterThanOrEqualTo = 'GE',
    IsExactly = 'E',
    LessThanOrEqualTo = 'LE',
    LessThan = 'LT',

    NotEqualTo = 'NE',

    Contains = 'ILIKE',
}

export type WithoutBetweenCriteria = Exclude<CriteriaType, CriteriaType.Between>;

export enum DayCriteriaType {
    ExcludeAnyOfThese = 'F',
    ExcludeOnlyThese = 'E',
    IncludeAnyOfThese = 'J',
    IncludeOnlyThese = 'I',
}

export const DayCriteriaOptions: Option<DayCriteriaType>[] = [
    {
        value: DayCriteriaType.ExcludeAnyOfThese,
        label: 'exclude any of these days'
    },
    {
        value: DayCriteriaType.ExcludeOnlyThese,
        label: 'exclude only these days'
    },
    {
        value: DayCriteriaType.IncludeAnyOfThese,
        label: 'include any of these days'
    },
    {
        value: DayCriteriaType.IncludeOnlyThese,
        label: 'include only these days'
    }
];

export const NumberCriteriaOptions: SearchCriteria[] = [
    {
        value: CriteriaType.GreaterThan,
        label: 'greater than'
    },
    {
        value: CriteriaType.GreaterThanOrEqualTo,
        label: 'greater than or equal to'
    },
    {
        value: CriteriaType.IsExactly,
        label: 'is exactly'
    },
    {
        value: CriteriaType.LessThanOrEqualTo,
        label: 'less than or equal to'
    },
    {
        value: CriteriaType.LessThan,
        label: 'less than'
    },
];

// special edge-case to handle a "number-like" string. ie. BIO 100L
export const CourseNumberCriteriaOptions: SearchCriteria[] = [
    {
        value: CriteriaType.GreaterThan,
        label: 'greater than'
    },
    {
        value: CriteriaType.GreaterThanOrEqualTo,
        label: 'greater than or equal to'
    },
    {
        value: CriteriaType.Contains,
        label: 'contains'
    },
    {
        value: CriteriaType.LessThanOrEqualTo,
        label: 'less than or equal to'
    },
    {
        value: CriteriaType.LessThan,
        label: 'less than'
    },
];


export const TimeCriteriaOptions: SearchCriteria[] = [
    {
        value: CriteriaType.GreaterThan,
        label: 'later than'
    },
    {
        value: CriteriaType.GreaterThanOrEqualTo,
        label: 'later than or equal to'
    },
    {
        value: CriteriaType.IsExactly,
        label: 'is exactly'
    },
    {
        value: CriteriaType.LessThanOrEqualTo,
        label: 'earlier than or equal to'
    },
    {
        value: CriteriaType.LessThan,
        label: 'earlier than'
    },
    {
        value: CriteriaType.Between,
        label: 'between'
    },
];
