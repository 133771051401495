import { CourseClassItemStyle } from '@/components/search-results/styled';
import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const ComponentItemStyle = styled(CourseClassItemStyle)`
    thead {
        th {
            ${tw`bg-white border-none underline pb-2`}
        }
    }
    th:nth-child(1) {
        width: 40px;
    }
    th:nth-child(2) {
        width: 60px;
    }
    th:nth-child(3) {
        width: 120px;
    }
    th:nth-child(4) {
        width: 100%;
    }
    
    @media (max-width: 650px) {
        th:nth-child(2) {
            width: 65px;
        }
        th:nth-child(3) {
            width: 90px;
        }
    }
    
    tbody {
        td {
            ${tw`pt-2 py-2 truncate border-0 border-b border-solid border-gray-400`}
            
        }
        td:last-child {
            ${tw`text-left`}
            @media (min-width: 650px) {
                ${tw`text-left`}
            }
            
        }
    }
`;
