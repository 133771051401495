// @ts-ignore
export function getEnumKeyByEnumValue (myEnum, enumValue) {
    const keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : undefined;
}

// http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
export const generateUUID = (): string => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

export const boolToYesNo = (value: any): string => {
    return value ? 'Yes' : 'No';
};

export const DecimalFormatter = new Intl.NumberFormat('en-us', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const IntegerFormatter = new Intl.NumberFormat('en-us', {
    maximumFractionDigits: 0,
});

export const PercentFormatterWithDecimal = new Intl.NumberFormat('en-us', {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
});
