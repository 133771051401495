import { gql } from '@apollo/client';

// the entity_group_details relationship exists as a **computed field** on custom_rq_grp_detl_tbl and custom_rq_ln_detl_tbl
export default gql`
fragment entityGroupDetails on custom_rq_ent_grp_detl {
    entityGroup:rq_entity_group
    itemNumber:ent_grp_item_nbr
    academicPlan:acad_plan
    academicSubplan:acad_sub_plan
    academicProgram:acad_prog
    institution
    studentGroup:stdnt_group
}
`;
