import React, { useState } from 'react';
import { CourseGroupItemStyle } from './styled';
import CourseClassItem from './CourseClassItem';
import { parseCourseTitle } from '@/api/transformers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { AddCourseProps } from '@/components/search-results/SearchResultsContainer';

interface Props {
    courseGroup: any;
    onAddClass: (props: AddCourseProps) => void;
}

export default ({ onAddClass, courseGroup }: Props) => {

    const [ showSections, setShowSections ] = useState<boolean>(true);

    return (
        <CourseGroupItemStyle>

            <div className="header">
                <a onClick={() => setShowSections(!showSections)}>
                    <FontAwesomeIcon icon={showSections ? faAngleDown : faAngleRight } />
                </a>


                <h3>{parseCourseTitle(courseGroup)}</h3>
            </div>

            <div
                style={{
                    display: showSections ? 'block' : 'none',
                }}
            >
                {courseGroup.classes.map((courseClass: any) => {
                    return (
                        <CourseClassItem onAddClass={onAddClass} key={courseClass.classId} courseClass={courseClass} courseGroup={courseGroup} />
                    );
                })}
            </div>

        </CourseGroupItemStyle>
    );
};
