import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { BaseTableStyle } from '../common/styled';

export const ShoppingCartContainerStyle = styled.div`    
    width: 100%;
    ${tw`py-4`}

    @media (min-width: 650px) {
        max-width: 800px;
        width: 600px;
        ${tw`px-4`}
    }

    > h3 {
        font-size: 16px;
        color: rgb(74,89,140);
        margin-bottom: 17px;
        padding-right: 6px;

        ${tw`font-normal text-lg`};
    }
`;

export const ShoppingCartWidgetStyle = styled.div`
    ${tw`border border-solid border-gray-500 px-1 py-3`}
    margin-left: -5px;
    margin-right: -5px;
    @media (min-width: 650px) {
        ${tw`px-3`}
        margin: 0;
    }

    a {
        ${tw`cursor-pointer text-blue-500 mb-3 hover:underline`}
    }

    > div {

    }
`;

export const ShoppingCartWidgetItemStyle = styled.tr`
    ${tw`text-sm`}

    *:first-child { 
        // width: 90px;
    }
    td {
        ${tw`pb-1`}
    }
    @media (min-width: 650px) {
        td {
            ${tw`px-2`}
        }
    }


    p {
        white-space: nowrap
    }
`;

export const ShoppingCartTableStyle = styled(BaseTableStyle)`

    th:first-child, td:first-child {
        ${tw`text-center`}
        width: 60px;
    }
    th:last-child, td:last-child {
        ${tw`pl-2`}
        width: 50px;
    }

    // Units column
    th:nth-child(6), td:nth-child(6) {
        width: 60px;
    }

    tbody {
        td {
            ${tw`py-1 align-middle`}

            a {
                ${tw`cursor-pointer hover:text-blue-500`}
            }
        }
    }
`;

