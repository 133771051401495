import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { BaseTableStyle } from '@/components/common/styled';

export const ClassDetailsContainerStyle = styled.div`
    width: 100%;

    ${tw`py-4 max-w-screen-xl`}
    
    @media (min-width: 650px) {
        width: 600px;
        ${tw`px-4`}
    }

    > h3 {
        font-size: 16px;
        color: rgb(74,89,140);
        margin-bottom: 17px;
        padding-right: 6px;

        ${tw`font-normal text-lg`};
    }
`;

export const InfoSectionContainerStyle = styled.div`
    ${tw`mb-3 border border-solid border-gray-400`}
    > h3 {
        ${tw`text-white bg-blue-600 py-1 px-1 text-sm font-normal`}   
    }
`;

export const MeetingInfoTableStyle = styled(BaseTableStyle)`
    ${tw`mb-0`}
`;
