import { Option, SessionType } from '@/api/types';

import _ from 'lodash';
const sessionTypes: Option<SessionType>[] = [
    {
        value: '8W1',
        label: 'Eight Week - First',
        labelAlt: ''
    },
    {
        value: '8W2',
        label: 'Eight Week - Second',
        labelAlt: ''
    },
    {
        value: '11W',
        label: 'Eleven Week',
        labelAlt: ''
    },
    {
        value: '5W1',
        label: 'Five Week - First',
        labelAlt: ''
    },
    {
        value: '5W2',
        label: 'Five Week - Second',
        labelAlt: ''
    },
    {
        value: '5W3',
        label: 'Five Week - Third',
        labelAlt: ''
    },
    {
        value: '4W',
        label: 'Four Week',
        labelAlt: ''
    },
    {
        value: '4W1',
        label: 'Four Week - First',
        labelAlt: ''
    },
    {
        value: '4W4',
        label: 'Four Week - Fourth',
        labelAlt: ''
    },
    {
        value: '4W2',
        label: 'Four Week - Second',
        labelAlt: ''
    },
    {
        value: '4W3',
        label: 'Four Week - Third',
        labelAlt: ''
    },
    {
        value: 'LT3',
        label: 'Less Than 3 Week',
        labelAlt: ''
    },
    {
        value: 'MB2',
        label: 'Medical, Basic Sci Ses 2',
        labelAlt: ''
    },
    {
        value: 'MBS',
        label: 'Medical, Basic Science',
        labelAlt: ''
    },
    {
        value: 'MC2',
        label: 'Medical, Clinical Sci 2',
        labelAlt: ''
    },
    {
        value: 'MCS',
        label: 'Medical, Clinical Science',
        labelAlt: ''
    },
    {
        value: '9W1',
        label: 'Nine Week - First',
        labelAlt: ''
    },
    {
        value: '9W2',
        label: 'Nine Week - Second',
        labelAlt: ''
    },
    {
        value: 'PCL',
        label: 'Pre-College Programs',
        labelAlt: ''
    },
    {
        value: '1',
        label: 'Regular Academic Session',
        labelAlt: 'Regular'
    },
    {
        value: '2',
        label: 'Second Session',
        labelAlt: ''
    },
    {
        value: '7W1',
        label: 'Seven Week - First',
        labelAlt: ''
    },
    {
        value: '7W2',
        label: 'Seven Week - Second',
        labelAlt: ''
    },
    {
        value: '6W1',
        label: 'Six Week - First',
        labelAlt: ''
    },
    {
        value: '6W2',
        label: 'Six Week - Second',
        labelAlt: ''
    },
    {
        value: '10W',
        label: 'Ten Week',
        labelAlt: ''
    },
    {
        value: '3W1',
        label: 'Three Week - First',
        labelAlt: ''
    },
    {
        value: '3W2',
        label: 'Three Week - Second',
        labelAlt: ''
    },
    {
        value: '3W3',
        label: 'Three Week - Third',
        labelAlt: ''
    },
    {
        value: '12W',
        label: 'Twelve Week',
        labelAlt: ''
    },
    {
        value: 'WIN',
        label: 'Winter',
        labelAlt: ''
    }
];

export const parseSessionCode = (code: SessionType): string => {
    const session = _.find(sessionTypes, { value: code });
    return session?.labelAlt || session?.label || '';
};

export default sessionTypes;
