import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import GlobalStyle from '@css/GlobalStyle';
import HomeScreen from '@/components/HomeScreen';
import { withAuth } from '@/api/AuthService';
import store, { useStoreActions, useStoreState } from '@/store';
import { StoreProvider, useStoreRehydrated } from 'easy-peasy';
import config from '@/config';
import EntryScreen from '@/components/login/EntryScreen';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router';
import FrameworkUpdateWatcher from '@/components/FrameworkUpdateWatcher';
import { Spinner } from '@/components/common';
import getAuthenticatedUser from '@/api/getAuthenticatedUser';
import BroadcastManager from '@/components/BroadcastManager';
import 'chart.js/auto';
import LoginScreen from '@/components/login/LoginScreen';
import 'react-datepicker/dist/react-datepicker.css';

console.log('version: ', config.version);
const AppLoader = () => {
    const [ loading, setLoading ] = useState<boolean>(true);
    const location = useLocation();

    const token = useStoreState(state => state.user.token);

    const login = useStoreActions(actions => actions.login);
    const tokenLogin = useStoreActions(actions => actions.tokenLogin);
    const logout = useStoreActions(actions => actions.logout);

    useEffect(() => {
        console.log('state.user.token: ', token);
        const queryParams = new URLSearchParams(location.search);
        const studentLinkId = queryParams.get('id');
        let req: Promise<void | string> | undefined;
        if (studentLinkId) {
            req = login({ studentId: studentLinkId });
            queryParams.delete('id');
        } else if (token) {
            req = tokenLogin();
        }

        if (req) {
            req
                .catch(_ => {
                    console.warn('should ignore this error?');
                })
                .then((result) => {
                    setLoading(false);
                });

        } else {
            console.log('no reqs, setting loading to false');
            setLoading(false);

        }

        BroadcastManager.init();
        BroadcastManager.onMessage((ev) => {
            console.log('heard message ev: ', ev);
            const { token } = store.getState().user;

            if (token) {
                if (ev.data === 'logout') {
                    logout({emit: false});
                } else {
                    try {
                        const data = JSON.parse(ev.data);
                        const { studentInfo } = store.getState().student;

                        if (data.type === 'login' && data.studentId !== studentInfo?.studentId) {
                            logout({emit: false});
                        }

                    } catch (err) {

                    }
                }
            }

        });
        return () => {
            BroadcastManager.cleanup();
        };

    }, []);

    useEffect(() => {
        if (token) {
            getAuthenticatedUser();
        }
    }, [ token ]);

    if (loading) return null;
    return (
        <>
            <FrameworkUpdateWatcher/>

            <Switch>
                <Route path="/login" component={EntryScreen}/>
                <Route path="/admin-login" component={LoginScreen}/>
                <Route path='/' component={withAuth(HomeScreen)}/>
                <Redirect to={'/'}/>
            </Switch>
        </>

    );
};

const HydratedApp = () => {
    const isHydrated = useStoreRehydrated();

    return (
        isHydrated ?
            <BrowserRouter>
                <AppLoader/>
            </BrowserRouter>
            :
            <Spinner/>
    );
};

const App = () => (
    <Sentry.ErrorBoundary>
        <GlobalStyle/>
        <StoreProvider store={store}>
            <HydratedApp/>
        </StoreProvider>

    </Sentry.ErrorBoundary>
);

export default hot(App);
