import { parseClassSection } from '@/api/transformers';
import { Link } from 'react-router-dom';
import React from 'react';
import { CourseClassExtract } from '@/api/types';

interface Props {
    item: CourseClassExtract;
}
export default ({ item }: Props) => {
    return (
        <Link
            to={{
                pathname: `/class/${item.classId}`,
                state: {
                    from: 'cart',
                    sectionMode: item.sectionMode,
                }
            }}
        >{`${parseClassSection(item)}`}
        </Link>
    );
};
