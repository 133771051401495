import React from 'react';
import CriteriaInfo from '@/components/search-results/CriteriaInfo';
import tw from 'twin.macro';
import { Button } from '@/components/common';
import { generateEmptyClassSearchForm } from '@/api/transformers';
import { useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from '@/store';

export default () => {
    const history = useHistory();

    const setFilters = useStoreActions(actions => actions.search.setFilters);

    const filters = useStoreState(state => state.search.filters);

    return (
        <>
            <CriteriaInfo css={tw`mb-3 `} filters={filters} />

            <div css={tw`flex justify-end mt-3`}>
                <Button
                    onClick={() => {
                        setFilters({
                            ...generateEmptyClassSearchForm(),
                            institution: filters.institution
                        });
                        history.push('/search');
                    }}
                >
                    New Search
                </Button>

                <Button
                    onClick={() => {
                        const updatedFilters = {
                            ...generateEmptyClassSearchForm(),
                            ...filters,
                        };
                        setFilters(updatedFilters);
                        history.push('/search');
                    }}
                    css={tw`ml-4`}
                >
                    Modify Search
                </Button>
            </div>
        </>
    );
};
