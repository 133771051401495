import React from 'react';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';
import { parseClassSection, parseCourseShortName } from '@/api/transformers';
import { InstructionModeName } from '@/constants/instructionModes';
import { ShoppingCartWidgetStyle, ShoppingCartWidgetItemStyle } from './styled';
import { SelectedCourse } from '@/api/types';
import ClassTimes from '@/components/shopping-cart/ClassTimes';

interface Props {
    items: SelectedCourse[];
}

export default ({ items }: Props) => {
    return items.length ?
        (
            <ShoppingCartWidgetStyle>
                <Link to="/cart" css={tw`block`}>Shopping Cart</Link>
                <div css={tw`flex flex-col`}>
                    {
                        items.map(course => {
                            return (
                                <div key={course.courseId}>
                                    <p>{parseCourseShortName(course)}</p>
                                    <table>
                                        <tbody>
                                            {course.selectedSections.map(section => {
                                                return (
                                                    <ShoppingCartWidgetItemStyle key={section.classId}>
                                                        <td>

                                                            <span>{parseClassSection(section)}</span>
                                                        </td>
                                                        <td><ClassTimes item={section}/></td>
                                                        <td>
                                                            <span>{InstructionModeName[section.sectionMode!]}</span>
                                                        </td>
                                                    </ShoppingCartWidgetItemStyle>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            );
                        })
                    }
                </div>
            </ShoppingCartWidgetStyle>
        ) : null;
};
