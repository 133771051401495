import {
    format,
    endOfWeek,
    startOfDay,
    startOfWeek,
    startOfYear,
    sub,
} from 'date-fns';

export type DateRange = [Date, Date];

export enum DateRangePresetEnum {
    Today = 'Today',
    Yesterday = 'Yesterday',
    ThisWeek = 'This week',
    LastWeek = 'Last week',
    Last7Days = 'Last 7 days',
    Last30Days = 'Last 30 days',
    ThisYear = 'This year (Jan - Today)',
    Custom = 'Custom',
}

export const getToday = (): Date => {
    return startOfDay(new Date());
};

const getYesterday = (): Date => {
    return sub(getToday(), { days: 1 });
};

const getStartOfWeek = (): Date => {
    return startOfWeek(getToday());
};

const getStartOfLastWeek = (): Date => {
    return sub(getStartOfWeek(), { weeks: 1 });
};

const getEndOfLastWeek = (): Date => {
    return endOfWeek(getStartOfLastWeek());
};

const getStartOfThisYear = (): Date => {
    return startOfYear(getToday());
};

export const formatWithoutTime = (date: Date): string => {
    return format(new Date(date), 'yyyy-MM-dd');
};

export const generatePresetDates = (preset: DateRangePresetEnum): DateRange => {
    switch (preset) {
        case DateRangePresetEnum.Today:
            return [ getToday(), getToday() ];

        case DateRangePresetEnum.Yesterday:
            return [ getYesterday(), getYesterday() ];

        case DateRangePresetEnum.ThisWeek:
            return [ getStartOfWeek(), getToday() ];

        case DateRangePresetEnum.LastWeek:
            return [ getStartOfLastWeek(), getEndOfLastWeek() ];

        case DateRangePresetEnum.Last7Days:
            return [ sub(getToday(), { days: 7 }), getYesterday() ];

        case DateRangePresetEnum.Last30Days:
            return [ sub(getToday(), { days: 30 }), getYesterday() ];

        case DateRangePresetEnum.ThisYear:
            return [ getStartOfThisYear(), getToday() ];

        default:
            throw new Error('Unhandled preset.');
    }
};

export const generateDateStringRange = (numDates: number, firstRecordDate: string, lastRecordDate: string,): string[] => {
    const generalDateRange: string[] = [];
    let index = numDates;
    while (index) {
        index--;
        generalDateRange.push(format(sub(getToday(), { days: index }), 'MM/dd/yy'));
    }

    const foundIdx = generalDateRange.findIndex((el) => firstRecordDate === el);
    const foundLastIdx = generalDateRange.findIndex((el) => lastRecordDate === el);
    return foundIdx > 0 ? generalDateRange.slice(foundIdx, foundLastIdx + 1) : generalDateRange;
};
