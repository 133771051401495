import { gql } from '@apollo/client';
import { UPDATE_SIM_RESULTS } from '@/api/simulation/SimulationAPI';
import graphClient from '@/api/graph';
import { generateEmptyResults } from '@/components/simulation/simulationResults';
import { convertToSnakeCase } from '@/api/graphUtils';

// export const fetchCompletedStats = gql`
// query {
//     total:simulation_results_aggregate{
//             stats:aggregate {
//                 count
//             }
//         }
//   completed:simulation_results_aggregate(where:{
//
//     data:{
//       _cast:{
//         String:{
//           _nlike: "%\\"completedTs\\": \\"\\"%"
//         }
//       }
//       _has_key: "completedTs"
//     }
//   }) {
//     stats:aggregate {
//       count
//     }
//   }
//
//   notEligible:simulation_results_aggregate(where:{
//     screener:{
//     _cast:{
//       String:{
//         _like:"%\\"registerIntent\\": \\"N%"
//       }
//     }
//   }
//
//   }) {
//     stats:aggregate {
//       count
//     }
//   }
// }
//
// `;

export const fetchSimResults = gql`
query fetchSimResults(
    $filter: simulation_results_bool_exp, 
    $offset: Int,
    $limit: Int,  
    $orderBy: [simulation_results_order_by]
) {
    items_aggregate:simulation_results_aggregate(
        where: $filter,
    ) {
        aggregate {
            totalCount: count
        }
    }
    items:simulation_results(
        where: $filter,
        offset: $offset,
        order_by: $orderBy
        limit: $limit,
    ) {
            studentId: student_id
            firstLoggedIn: created_at
            lastLoggedIn: last_logged_in
            availableTimes: available_times
            availableTimesSubmittedTs: available_times_submitted_ts
            consentTs: consent_ts
            paymentInfo: payment_info
            paymentInfoSubmittedTs: payment_info_submitted_ts
            shoppingCart: shopping_cart
            data: data
            survey: survey
            screener
            isTester: is_tester
            isStaff: is_staff
            stats: stats
    }
}
`;

export const clearResultsById = async (studentId: string) => {
    await graphClient.mutate({
        mutation: UPDATE_SIM_RESULTS,
        variables: {
            id: studentId,
            data: convertToSnakeCase(generateEmptyResults())
        }
    });
};
