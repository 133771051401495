import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from '@/store';
import requirementDesignations from '@/constants/requirementDesignations';
import { Option, RequirementDesignation } from '@/api/types';
import { InputContainerStyle } from '@/components/class-search/styled';
import tw from 'twin.macro';
import graphClient from '@/api/graph';
import getSubjectsByInstitutionAndTerm, { rawDataToSubjectOptions } from '@/api/graphql/queries/getSubjectsByInstitutionAndTerm';
import { defaultTerm } from '@/constants/terms';

interface Props {
    institution: string;
}

export default ({ institution }: Props) => {
    const [ loading, setLoading ] = useState<boolean>(false);

    const filters = useStoreState(state => state.search.filters);
    const subjects = useStoreState(state => state.search.subjects);
    const setFilters = useStoreActions(actions => actions.search.setFilters);

    const [ availableSubjects, setAvailableSubjects ] = useState<Option[]>(subjects);

    const onSelectSubject = (subject: string) => {
        console.log('onSelectSubject subject: ', subject);
        setFilters({
            ...filters,
            subjectId: subject
        });
    };

    const onSelectRequirementDesignation = (requirementDesignation: RequirementDesignation) => {
        setFilters({
            ...filters,
            requirementDesignation
        });
    };

    useEffect(() => {
        if (!subjects.length) {

            graphClient.query({
                query: getSubjectsByInstitutionAndTerm,
                variables: {
                    institution,
                    term: defaultTerm.id
                }
            })
                .then(results => {
                    console.log('subjects results: ', results);
                    const updatedSubjects = rawDataToSubjectOptions(results.data.items);
                    setAvailableSubjects(updatedSubjects);

                    setTimeout(() => {
                        setLoading(false);
                    }, 50);
                })
                .catch(err => {
                    console.error('err: ', err);
                    setTimeout(() => {
                        setLoading(false);
                    }, 50);
                });
        }


    }, [ institution, subjects ]);

    return (
        <div css={tw``}>
            <InputContainerStyle>
                <label htmlFor="subject">Subject</label>
                <select id="subject" value={filters.subjectId} onChange={e => onSelectSubject(e.target.value)}>
                    <option value=""></option>
                    {
                        availableSubjects.map(subjectOption => {
                            return (
                                <option key={subjectOption.value} value={subjectOption.value}>{subjectOption.label}</option>
                            );
                        })
                    }
                </select>
            </InputContainerStyle>

            <InputContainerStyle>
                <label htmlFor="requirementDesignation">Requirement Designation</label>
                <select id="requirementDesignation" value={filters.requirementDesignation} onChange={e => onSelectRequirementDesignation(e.target.value as RequirementDesignation)}>
                    <option value=""></option>
                    {
                        requirementDesignations.map(reqDes => {
                            return (
                                <option key={reqDes.value} value={reqDes.value}>{reqDes.label}</option>
                            );
                        })
                    }
                </select>
            </InputContainerStyle>


        </div>
    );
};
