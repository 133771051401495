import React, { useState } from 'react';
import { FilterRowStyle } from '@/components/dashboard/components/filter-bar/styled';
import ColumnValueInput from '@/components/dashboard/components/filter-bar/ColumnValueInput';
import tw from 'twin.macro';
import ColumnSelect from '@/components/dashboard/components/filter-bar/ColumnSelect';
import { Option } from '@/api/types';
import { ColumnFilter } from '@/components/dashboard/components/filter-bar/filterParams';

interface Props {
    filter: ColumnFilter;
    onChange: (selectedColumn: Option | null, value: string) => void;
}

export default ({ onChange, filter }: Props) => {

    const [ selectedColumn, setSelectedColumn ] = useState<Option | null>(filter.column);

    const handleColumnChange = (column: Option | null) => {
        setSelectedColumn(column);
        onChange(column, '');
    };

    const handleValueChange = (val: string) => {
        if (selectedColumn) {
            onChange(selectedColumn, val);
        } else {
            onChange(null, '');
        }
    };

    return (
        <FilterRowStyle>
            <div css={tw``}>
                <ColumnSelect selected={selectedColumn} onSelect={handleColumnChange}/>
            </div>

            <div css={tw`ml-6 mr-3`}>
                <ColumnValueInput value={filter.value} column={selectedColumn} onChange={handleValueChange}/>
            </div>
        </FilterRowStyle>
    );
};

