import React, { useEffect, useState } from 'react';
import { ClassSearchCriteriaValues } from '../class-search/ClassSearchForm';
import _ from 'lodash';
import { useStoreState } from '@/store';

interface Props {
    filters: ClassSearchCriteriaValues
}

export default ({ filters }: Props) => {

    const subjects = useStoreState(state => state.search.subjects);
    const [ criteriaText, setCriteriaText ] = useState<string>('');
    const [ formattedFilters, setFormattedFilters ] = useState<string[]>([]);

    useEffect(() => {
        const updatedFormattedFilters: string[] = [];

        _.forEach(filters, (value, key) => {
            let s = '';
            switch (key) {
                case 'subjectId':
                    const subject = subjects.find(o => o.value === filters.subjectId);
                    s = subject ? `Course Subject: ${subject?.label}` : '';
                    break;
                case 'courseNumberCriteria':
                    break;
                case 'courseNumber':
                    break;
                case 'academicCareer':
                    break;
                case 'courseAttribute':
                    break;
                case 'courseAttributeValue':
                    break;
                case 'requirementDesignation':
                    break;
                // case 'showOpenClassesOnly':
                //     // TODO: bold the Yes|No
                //     s = `Show Open Classes Only: ${boolToYesNo(filters.showOpenClassesOnly)}`;
                //     break;
                case 'sessionType':
                    break;
                case 'instructionMode':
                    break;
                case 'startTimeCriteria':
                    break;
                case 'startTime':
                    break;
                case 'endTimeCriteria':
                    break;
                case 'endTime':
                    break;
                case 'daysOfWeekCriteria':
                    break;
                case 'daysOfWeek':
                    break;
                case 'classNumber':
                    break;
                case 'courseKeyword':
                    break;
                case 'maxUnitsCriteria':
                    break;
                case 'maxUnits':
                    break;
                case 'minUnitsCriteria':
                    break;
                case 'minUnits':
                    break;
                case 'courseComponent':
                    break;
                case 'campus':
                    break;
                case 'location':
                    break;
                case 'instructorLastName':
                    break;
                default:
                    break;
            }
            if (s) {
                updatedFormattedFilters.push(s);
            }
        });

        setFormattedFilters(updatedFormattedFilters);
    }, [ subjects, filters ]);

    useEffect(() => {
        setCriteriaText(formattedFilters.join(', '));
    }, [ formattedFilters ]);

    return (
        <div>
            <p>The following classes match your search criteria {criteriaText}</p>
        </div>
    );
};
