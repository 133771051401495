import React, { useState } from 'react';
import { StudentInfoContainerStyle } from '@/components/dashboard/styled';
import tw from 'twin.macro';
import { Modal } from '@/components/common';
import { SimulationResultsRaw } from '@/api/simulation/SimulationAPI';
import CopyToClipboard from 'react-copy-to-clipboard';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    results: SimulationResultsRaw | null;
    visible: boolean;
    onClose: () => void;
}

export default ({ results, visible, onClose }: Props) => {
    if (!results) return null;

    const [ copied, setCopied ] = useState<boolean>(false);
    const formatted = JSON.stringify(results, undefined, 4);

    const handleClose = () => {
        setCopied(false);
        onClose();
    };

    return (
        <Modal visible={visible} onDismissed={handleClose}>
            <StudentInfoContainerStyle>

                <div css={tw`flex justify-between items-start`}>
                    <h3 css={tw`mb-4`}>Student: {results.student_id}</h3>
                </div>

                <div>
                    <CopyToClipboard css={tw`text-link cursor-pointer hover:opacity-75`} onCopy={() => { setCopied(true); }} text={formatted}>
                        <FontAwesomeIcon icon={faClipboard} />
                    </CopyToClipboard>
                    { copied ?
                        <span css={tw`ml-2 `}>Copied to clipboard.</span>
                        : null
                    }

                </div>


                <div css={tw`p-4 bg-gray-200`} style={{
                    overflow: 'scroll',
                    maxHeight: '500px'
                }}
                >
                    <pre>
                        {formatted}
                    </pre>
                </div>


            </StudentInfoContainerStyle>
        </Modal>

    );
};
