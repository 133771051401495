import React from 'react';
import { CardBodyStyle, CardHeaderStyle, CardStyle } from '@/components/dashboard/styled';
import InteractionsTable from './InteractionsTable';
import { AggregateStats } from '@/api/simulation/getResultsAggregate';

interface Props {
    data: AggregateStats[];
}

const InteractionCategories = ({ data }: Props) => {
    return (
        <CardStyle>
            <CardHeaderStyle>Last Submitted Interactions</CardHeaderStyle>
            <CardBodyStyle>
                <div style={{ overflow: 'auto', maxHeight: '270px', padding: '0 5px' }}>
                    <InteractionsTable data={data}/>
                </div>
            </CardBodyStyle>
        </CardStyle>
    );
};

export default InteractionCategories;
