import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { generateEmptyClassSearchForm, parseCourseShortName } from '@/api/transformers';
import { InstitutionType } from '@/api/types';
import { CriteriaType } from '@/components/class-search/SearchCriteria';
import { useStoreActions } from '@/store';
import { useHistory } from 'react-router-dom';
import { CartCoreqError } from '@/components/shopping-cart/api/errors';

interface Props {
    error: CartCoreqError
}
export default ({ error }: Props) => {
    const history = useHistory();
    const setFilters = useStoreActions(actions => actions.search.setFilters);

    if (!error) return null;

    const { course: selectedClass, requisiteCourses } = error;

    const courseName = useMemo<string>(() => parseCourseShortName(selectedClass), [ selectedClass ]);

    return (
        <>
            <div css={[ tw`w-full mt-4 bg-red-200 p-2 text-red-600 rounded mb-2` ]}>

                <span>Missing corequisite course for {courseName}: </span>
                {
                    requisiteCourses.map((course, index) => {
                        return (
                            <div key={course.courseId} css={tw`inline`}>
                                <span key={course.courseId} css={tw``}>
                                    {requisiteCourses.length > 1 && index === requisiteCourses.length - 1 ? ' or ' : ''}
                                    <a onClick={() => {
                                        setFilters({
                                            ...generateEmptyClassSearchForm(),
                                            institution: selectedClass.institution as InstitutionType,
                                            subjectId: course.subject,
                                            courseNumber: course.courseNumber,
                                            courseNumberCriteria: CriteriaType.IsExactly,
                                        });
                                        history.push('/results');

                                    }} css={tw`text-link cursor-pointer text-sm font-medium`}
                                    >
                                        {course.subject + ' ' + course.courseNumber}
                                    </a>
                                    {requisiteCourses.length > 1 && index !== requisiteCourses.length - 1 ? ', ' : ''}
                                </span>
                            </div>
                        );
                    })
                }
                <p css={tw`mt-4`}>Please add one of the courses above or remove {courseName} which has a missing corequisite from your schedule.</p>
            </div>


        </>
    );
};
