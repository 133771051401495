import http from '@/api/http';


type UpdateAccountProps = {
    emplid: string;
    password: string;
};

export default async (props: UpdateAccountProps): Promise<void> => {
    const endpoint = '/account';
    await http.put(endpoint, props);
};
