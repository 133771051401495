import { useCallback } from 'react';
import { useUpdateSimResults } from '@/api/simulation/SimulationAPI';
import { generateEmptyResults } from '@/components/simulation/simulationResults';

// besides studentId and displayName
export default (): () => Promise<any> => {
    const updateSimResults = useUpdateSimResults();

    return useCallback<() => Promise<any>>(() => {
        // @ts-ignore
        return updateSimResults(generateEmptyResults());
    }, []);
};
