import { checkboxOrRadio, CourseClassExtract, Option, SelectedCourse } from '@/api/types';
import { AllowedSectionModes } from '@/store/simulation/simulationUtils';

export type SimulationChoice = Option & AllowedSectionModes;

export enum QuestionConditionEnum {
    OriginalCourseSelection = 'OriginalCourseSelection',

    SameCourseDifferentMode = 'SameCourseDifferentMode',
    DifferentCourseSameMode = 'DifferentCourseSameMode',
    DifferentCourseDifferentMode = 'DifferentCourseDifferentMode',

    ReplacedCourse = 'ReplacedCourse',
    DroppedCourse = 'DroppedCourse,',

    Undefined = 'Undefined',
}

export type ValidQuestionConditions = Exclude<QuestionConditionEnum, QuestionConditionEnum.Undefined>;

export const parseReasonCondition = (newClass: CourseClassExtract, oldClass: CourseClassExtract): ValidQuestionConditions => {
    // console.log('newClass: ', newClass, 'oldClass: ', oldClass);
    if (newClass.courseId === 'NoReplacement') {
        return QuestionConditionEnum.DroppedCourse;
    }
    if (newClass.courseId === oldClass.courseId && newClass.sectionMode !== oldClass.sectionMode) {
        return QuestionConditionEnum.SameCourseDifferentMode;
    }

    if (newClass.courseId !== oldClass.courseId) {
        if (newClass.sectionMode === oldClass.sectionMode) {
            return QuestionConditionEnum.DifferentCourseSameMode;
        } else {
            return QuestionConditionEnum.DifferentCourseDifferentMode;
        }
    }

    return QuestionConditionEnum.ReplacedCourse;
};

export interface SimulationQuestion {
    id: string,
    label: string,
    type: checkboxOrRadio | 'text',
    choices: Option[],
    defaultValue?: string,
}

export type QuestionParams = {
    originalClass: SelectedCourse;
    replacementClass: SelectedCourse;
    previousClass: SelectedCourse;
};
