import { SelectedCourse } from '@/api/types';
import { useStoreActions } from '@/store';
import React, { useEffect, useMemo, useState } from 'react';
import { SimulationContainerStyle } from './styled';
import tw from 'twin.macro';
import { Button, Modal, Spinner } from '../common';
import { useHistory } from 'react-router-dom';
import { EventResponseEnum, SimulatedEventChoice } from '@/constants/simulationConditions';
import SimulatedEvent from '@/components/simulation/SimulatedEvent';
import SimulationDebugger from './SimulationDebugger';
import Question from '@/components/simulation/Question';
import { formatISO } from 'date-fns';
import {
    SimulationResults,
    useGetSimResults,
    useUpdateSimResults,
    useUpdateSimulationData
} from '@/api/simulation/SimulationAPI';
import {
    InitialEventResponse,
    QuestionResponse,
    ReplacementResponse,
    SimulationData,
    StageData,
    TreeData
} from '@/store/simulation/types';
import { QuestionConditionEnum } from '@/constants/questions';
import {
    generateNoReplacementClassFiller,
    generateSelectedCourseExtract, hashSelectedCourse,
} from '@/api/transformers';
import { getCurrentTree, getNextStage, getNextTreeStage } from '@/store/simulation/simulationUtils';
import _ from 'lodash';
import useShoppingCartHooks from '@/components/shopping-cart/useShoppingCartHooks';
import CartResolver from '@/components/simulation/CartResolver';
import { getReplacementReasonQuestion } from '@/constants/questions/replacementReasons';
import NegativeExpectationsForm from '@/components/simulation/NegativeExpectationsForm';
import { getOriginalReasonQuestion } from '@/constants/questions/originalCourseReasons';
import { NegativeExpectationsResponse } from '@/constants/questions/negativeExpectations';

export default () => {
    const history = useHistory();
    const [ loading, setLoading ] = useState<boolean>(false);

    const { setReplacementFilters } = useStoreActions(actions => actions.search);

    const [ showConfirmEmptyCartModal, setShowConfirmEmptyCartModal ] = useState<boolean>(false);

    const { data: simulationData, shoppingCart } = useGetSimResults();

    const currentTree = useMemo<TreeData | null>(() => {
        return getCurrentTree(simulationData);
    }, [ simulationData ]);

    const { coReqsErrors } = useShoppingCartHooks();

    const requireCartResolution = useMemo<boolean>(() => {
        return !!(coReqsErrors.length || !simulationData.currentStage?.resolvedCart);
    }, [ coReqsErrors, simulationData ]);

    const updateSimulationData = useUpdateSimulationData();
    const updateSimResults = useUpdateSimResults();

    useEffect(() => {
        if (simulationData) {
            if (simulationData.completedTs) {
                history.push('/survey');
            } else {
                if (simulationData.currentStage) {
                    window.scrollTo(0, 0);
                }
            }
        }
    }, [ simulationData, simulationData?.currentStage ]);

    const onSubmitInitialChoice = (choice: SimulatedEventChoice) => {
        setLoading(true);

        const submittedTs = formatISO(new Date());

        const initialResponse: InitialEventResponse = {
            response: choice.response,
            submittedTs,
        };

        let replacementResponse: ReplacementResponse | null = null;
        let reasonResponse: QuestionResponse | null = null;

        if (choice.response === EventResponseEnum.NoReplacement) {
            replacementResponse = {
                replacementClass: 'NoReplacement',
                submittedTs,
            };

            reasonResponse = {
                questionId: QuestionConditionEnum.DroppedCourse,
                answer: [ QuestionConditionEnum.DroppedCourse ],
                submittedTs: submittedTs,
            };
        }

        const updateProps: Partial<StageData> = {
            initialResponse,
            replacementResponse,
            reasonResponse,
        };

        updateSimulationData({
            currentStage: {
                ...simulationData!.currentStage!,
                ...updateProps
            }
        })
            .then(() => {
                setLoading(false);
                // Handle NoReplacement answers for all Trees
                if (choice.response === EventResponseEnum.NoReplacement) {
                    return;
                }

                const removedClass: SelectedCourse = simulationData!.currentStage!.unavailableClass!;
                setReplacementFilters({
                    response: choice.response,
                    removedClass,
                    originalCourse: currentTree!.originalCourse!
                });

                history.push('/replacement-results');

            })
            .catch(err => console.error(err));
    };

    // submitCompletedStage is after all responses have been submitted, and any multiple changes or issues have been resolved.
    // Requires currentStageProps parameter to ensure getNextStage has the absolute latest props when determining next stage
    const submitCompletedStage = (currentStageProps: StageData): Promise<any> => {
        console.log('submitting completed stage...');

        setLoading(true);

        // We'll work with the parent simulation results so that we can also
        // update the shopping cart as necessary. This means we will need to pass
        // in the entire simulation data object.
        const updatedCart = _.cloneDeep(shoppingCart);

        let completedSimulationTs = '';

        // First mark currentStage as completed and include copy of resolved cart.
        const currentStage: StageData = {
            ...currentStageProps,
            completed: true,
            completedTs: formatISO(new Date()),
            resolvedCart: updatedCart.map(generateSelectedCourseExtract),
        };

        // We also need to add the currentStage to the current tree's list of completed stages
        const updatedCurrentTree: TreeData = {
            ...currentTree!,
            completedStages: [ ...currentTree!.completedStages, currentStage ]
        };
        const updatedTreeKey = `tree${updatedCurrentTree?.id}`;

        // Next we need to calculate the next stage.
        // However since negativeExpectations hasn't been recorded to the backend yet,
        // we need to pass in the updated props into getNextStage
        // TODO: alert! if we're splitting out submitNegativeExpectations in a different function, we need to ensure
        // we have the latest props when calling getNextStage
        let nextStage = getNextStage({
            ...simulationData,
            currentStage,
        });
        console.log('nextStage: ', nextStage);

        const reconciledTreeKeys: Partial<SimulationData> = {};

        // If there is a next stage IN THE CURRENT TREE, then
        // we need to remove the new unavailable course from the shopping cart.
        if (nextStage) {
            const unavailable = nextStage.unavailableClass;
            if (unavailable) {
                _.remove(updatedCart, o => o.courseId === unavailable.courseId);

                // We also need to push it to the tree's list of unavailable courses
                const alreadyExcluded = _.find(updatedCurrentTree.unavailableClasses, o => hashSelectedCourse(o) === hashSelectedCourse(unavailable));
                if (!alreadyExcluded) {
                    updatedCurrentTree.unavailableClasses = [
                        ...updatedCurrentTree.unavailableClasses,
                        {
                            courseId: unavailable.courseId,
                            courseTitle: unavailable.courseTitle,
                            institution: unavailable.institution,
                            subject: unavailable.subject,
                            courseNumber: unavailable.courseNumber,
                            sectionMode: unavailable.sectionMode,
                            selectedSections: unavailable.selectedSections,
                        }
                    ];
                }
            }
        } else {
            // This could also be the last stage of the tree, in that case next stage
            // will be undefined and we would need to make the tree as completed.
            updatedCurrentTree.status = 'Completed';

            // Also in this case, we also need to check if there is a next tree.
            // If so, our new next stage will be the first stage of the next tree.
            const { reconciledTrees, nextTreeStage } = getNextTreeStage(simulationData, updatedCart);
            reconciledTrees.forEach((rt) => {
                reconciledTreeKeys[`tree${rt.id}` as 'tree2' | 'tree3'] = rt;
            });

            // Note: we do not need to push the unavailable class to the tree's list since
            // the tree was instantiated with it during initTreeData()
            if (nextTreeStage) {
                nextStage = nextTreeStage;
                const unavailable = nextTreeStage.unavailableClass;
                _.remove(updatedCart, o => hashSelectedCourse(o) === hashSelectedCourse(unavailable));

            } else {
                // If there is no next stage then the simulation is complete.
                completedSimulationTs = formatISO(new Date());
            }
        }

        // this shouldn't happen but in case, prevent accidentally erasing progress
        // basically we should never be initializing new tree data for the current tree.
        if (reconciledTreeKeys[updatedTreeKey as 'tree2' | 'tree3']) {
            throw new Error('Error in reconciling tree keys');
        }

        // Finally merge all the props back together
        const updatedSimData: SimulationData = {
            ...simulationData,
            currentStage: nextStage,
            [updatedTreeKey]: updatedCurrentTree,
            completedTs: completedSimulationTs,
            ...reconciledTreeKeys,
        };

        const updateProps: Partial<SimulationResults> = {
            data: updatedSimData,
            shoppingCart: updatedCart,
        };

        return updateSimResults(updateProps)
            .catch(err => console.error(err))
            .then(() => setLoading(false));
    };

    const onSubmitReplacementReasonQuestion = (response: QuestionResponse) => {
        if (response.questionId === QuestionConditionEnum.OriginalCourseSelection) {
            throw new Error('not yet implemented');
        }
        setLoading(true);

        const currentStageProps: StageData = {
            ...simulationData!.currentStage!,
        };

        console.log('onSubmitReasons response: ', response);

        currentStageProps.reasonResponse = response;

        updateSimulationData({
            currentStage: currentStageProps,
        })
            .catch(err => console.error(err))
            .then(() => setLoading(false));
    };

    const onSubmitOriginalReason = (response: QuestionResponse) => {
        if (currentTree) {
            setLoading(true);

            const treeKey = `tree${currentTree.id}`;
            const props: TreeData = {
                ...simulationData![treeKey as keyof SimulationData] as TreeData,
                originalReason: response,
            };
            updateSimulationData({
                [treeKey]: props
            })
                .catch(err => console.error(err))
                .then(() => setLoading(false));
        }
    };

    const onSubmitNegativeExpectationsResponse = (response: NegativeExpectationsResponse) => {
        setLoading(true);

        const submittedTs = formatISO(new Date());

        const currentStageProps: StageData = {
            ...simulationData!.currentStage!,
            negativeExpectations: {
                ...response,
                submittedTs
            },
            additionalChangesResponse: {
                answer: response.q3a8,
                submittedTs
            }
        };

        let request;
        // Basically if answer is no additional changes AND no cart issues, then we submitCompletedStage
        // For all other combinations we will always redirect to CartResolver
        if (currentStageProps.additionalChangesResponse!.answer === 'N' && !coReqsErrors.length) {
            request = submitCompletedStage(currentStageProps);
        } else {
            request = updateSimulationData({
                currentStage: currentStageProps
            });
        }
        request.catch(err => console.error(err))
            .then(() => setLoading(false));
    };

    const onSubmitCartResolver = () => {
        setLoading(true);

        const props: Partial<StageData> = {};

        if (coReqsErrors.length) {
            props.replacementMissingCorequisites = true;
        }
        submitCompletedStage({
            ...simulationData!.currentStage!,
            ...props,
        })
            .catch(err => console.error(err))
            .then(() => setLoading(false));
    };

    const renderQuestion = () => {
        if (!simulationData || !currentTree || !simulationData.currentStage) return null;

        if (!currentTree.originalReason) {
            return <Question question={getOriginalReasonQuestion(currentTree.originalCourse!)} onSubmitResponse={onSubmitOriginalReason}/>;
        }

        const { currentStage } = simulationData;

        if (!currentStage.initialResponse) {
            return <SimulatedEvent originalCourse={currentTree.originalCourse!} onSubmitChoice={onSubmitInitialChoice}/>;
        }
        // if no replacementResponse then they should be getting redirected to replacement-search/results or the next stage.
        // we are returning null here so avoid any confusing flickering of components
        if (!currentStage.replacementResponse) {
            return null;
        }

        const { originalCourse } = currentTree;
        const { unavailableClass, replacementResponse: { replacementClass } } = currentStage;

        const questionParams = {
            originalClass: originalCourse!,
            previousClass: unavailableClass,
            replacementClass: replacementClass === 'NoReplacement' ? generateNoReplacementClassFiller() : replacementClass,
        };

        if (!currentStage.reasonResponse) {
            return <Question question={getReplacementReasonQuestion(questionParams)} onSubmitResponse={onSubmitReplacementReasonQuestion}/>;
        }

        if (!currentStage.negativeExpectations) {
            return (
                <NegativeExpectationsForm params={questionParams} onSubmitResponse={onSubmitNegativeExpectationsResponse}/>
            );
        }

        if (requireCartResolution) {
            return (
                <div css={tw`pt-3 pb-1 md:w-1/2`}>
                    <CartResolver onSubmit={onSubmitCartResolver}/>
                </div>
            );
        }
    };

    return (
        <SimulationContainerStyle>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />

            { renderQuestion() }

            <SimulationDebugger/>

            <Modal visible={showConfirmEmptyCartModal} onDismissed={() => setShowConfirmEmptyCartModal(false)}>
                <h4 css={tw`text-gray-900 font-normal mb-4`}>Are you sure you want to empty your shopping cart?</h4>
                <div css={tw`text-right`}>
                    <Button onClick={() => {
                        // TODO: deprecated shoppingCart store. Use apollo instead
                        // setItems([]);
                        history.push('search');
                    }}
                    >
                        Yes
                    </Button>
                    <Button css={tw`mx-4 bg-gray-200 text-gray-900`} onClick={() => history.push('/cart')}>No, just take me back to the shopping cart</Button>
                </div>
            </Modal>

        </SimulationContainerStyle>
    );
};
