import {
    disabilityChoices,
    generateNumericalDropdownChoices,
    YesNoChoicesWithDontKnowAndOptOut,
    YesNoChoicesWithOptOut
} from '@/components/survey/surveyQuestions';

export const demo1a = {
    id: 'demo1a',
    label: 'Do you have any physical or mental condition that impacts your learning, working, or living activities?',
    choices: YesNoChoicesWithOptOut,
};

export const demo1b = {
    id: 'demo1b',
    onlyIf: 'demo1a__Y',
    label: 'Which of the following conditions do you have?',
    type: 'checkbox',
    choices: disabilityChoices,
};

export const demo2 = {
    id: 'demo2',
    label: 'Are you currently married or living with a significant other?',
    choices: [
        {
            label: 'I am married or in a civil union',
            value: 'married',
        },
        {
            label: 'I am not married or in a civil union, but live with a significant other',
            value: 'liveWithSO',
        },
        {
            label: 'I am not married and do not currently live with a significant other ',
            value: 'neither',
        },
        {label: 'Prefer not to respond', value: 'noResponse'},
    ],
};

export const demoZip = {
    id: 'demoZip',
    label: 'What is your current zip code?',
    choices: '',
};
export const demoDescribe = {
    id: 'demoDescribe',
    label: 'How would you describe yourself?',
    choices: [
        {
            label: 'American Indian or Alaska Native',
            value: 'a',
        },
        {
            label: 'Asian',
            value: 'b',
        },
        {
            label: 'Black or African American',
            value: 'c',
        },
        {
            label: 'Hispanic or Latina/o',
            value: 'd',
        },
        {
            label: 'Middle Eastern or North African',
            value: 'e',
        },
        {
            label: 'Native Hawaiian or Other Pacific Islander',
            value: 'f',
        },
        {
            label: 'White',
            value: 'g',
        },
        {
            label: 'Another race or ethnicity',
            value: 'h',
        },
        {
            label: 'Prefer not to respond',
            value: 'noResponse',
        }
    ],
};

export const demo3 = {
    id: 'demo3',
    label: 'Is English your first language?',
    choices: YesNoChoicesWithOptOut,
};

export const demo4 = {
    id: 'demo4',
    label: 'Were you and/or your parents born in the US?',
    choices: [
        {
            label: 'Both I and my parents were born in the US.',
            value: '2',
        },
        {
            label: 'I was born in the US, but one or both of my parents were born abroad.',
            value: '1',
        },
        {
            label: 'I was not born in the US.',
            value: '0',
        },
        {label: 'Do not know', value: 'unknown'},
        {label: 'Prefer not to respond', value: 'noResponse'},
    ],
};

export const demo5 = {
    id: 'demo5',
    label: 'Did either of your parents ever complete a four-year college degree?',
    choices: [
        {
            label: 'Yes, at least one of my parents have a four-year college degree (or a higher degree such as a master’s, professional, or doctoral degree).',
            value: 'Y',
        },
        {
            label: 'No, neither of my parents ever completed a four-year degree.',
            value: 'N',
        },
        {label: 'Do not know', value: 'unknown'},
        {label: 'Prefer not to respond', value: 'noResponse'},
    ]
};

export const demo6 = {
    id: 'demo6',
    label: 'Do you currently receive financial aid?',
    choices: YesNoChoicesWithDontKnowAndOptOut,
};

export const demo7 = {
    id: 'demo7',
    label: 'What is your annual household income (total income earned by everyone in your household)?',
    choices: [
        {
            label: 'less than $20,000 per year',
            value: '0',
        },
        {
            label: '$20,000-40,000 per year',
            value: '1',
        },
        {
            label: '$40,000-60,000 per year',
            value: '2',
        },
        {
            label: '$60,000- 80,000 per year',
            value: '3',
        },
        {
            label: '$80,000-$100,000 per year',
            value: '4',
        },
        {
            label: 'more than $100,000 per year ',
            value: '5',
        },
        {label: 'Do not know', value: 'unknown'},
        {label: 'Prefer not to respond', value: 'noResponse'},
    ]
};

export const demo8 = {
    id: 'demo8',
    label: 'Thinking about the income that you reported above, how many people in your household are supported by that income?',
    min: 1,
    max: 15,
    choices: generateNumericalDropdownChoices(16, 1),
};

export const demo9a = {
    id: 'demo9a',
    label: 'Do you have a disability?',
    choices: YesNoChoicesWithOptOut,
};

export const demo9b = {
    id: 'demo9b',
    onlyIf: 'demo1a__N__demo9a__Y',
    label: 'Which of the following conditions do you have?',
    choices: disabilityChoices,
    type: 'checkbox',
};

export const demo10 = {
    id: 'demo10',
    label: 'Is there anything else you would like to share about how course availability (e.g., whether courses are offered asynchronously or online, the times that courses are offered, etc.) impacts you?  This could include information about how this impacts you academically (e.g., grades, degree progress), but also how this impacts your life outside of CUNY, including your work and finances, your family, your physical and mental health, and your quality of life? [optional]',
};
