import React from 'react';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components/macro';

type Props = { timeout: number } & Omit<CSSTransitionProps, 'timeout' | 'classNames'>;

const Container = styled.div<{ timeout: number }>`
    .fade-enter { opacity: 0; will-change: opacity; }
    .fade-exit { opacity: 1; will-change: opacity; }

    .fade-enter.fade-enter-active {
        opacity: 1;
        transition: opacity ${props => props.timeout}ms ease-in;
    }
    
    .fade-exit.fade-exit-active {
        opacity: 0;
        transition: opacity ${props => props.timeout}ms ease-out;
    }
`;

const Fade = ({ timeout, children, ...props }: Props) => {
    return (
        <Container timeout={timeout}>
            <CSSTransition timeout={timeout} classNames={'fade'} {...props}>
                {children}
            </CSSTransition>
        </Container>
    );
};

export { Fade };
