import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import SelectQuestion from '@/components/survey/SelectQuestion';
import tw from 'twin.macro';
import graphClient from '@/api/graph';
import {
    getSubjectsByInstitutionAndTermQuery_Original,
    rawDataToSubjectOptions
} from '@/api/graphql/queries/getSubjectsByInstitutionAndTerm';
import { InstitutionOption, Option } from '@/api/types';
import styled from 'styled-components/macro';
import { CourseOffer, getCoursesOffered, rawDataToCourseOptions } from '@/api/graphql/queries/getCoursesOffered';
import _ from 'lodash';
import { getSummerTerms } from '@/components/screener/summerCourseQueries';

const SelectContainerStyleProps: CSSProperties = {
    border: 'none',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '5px',
    paddingBottom: '5px',
};

const SelectInputStyleProps: CSSProperties = {
};

const ContainerStyle = styled.div`
    ${tw`flex flex-row`}
    > * {
        width: 100%;
        ${tw`mx-2`}
    }
    select {
        width: 100%;
        
        ${tw`m-0 px-2 py-1`}
    }
`;

interface Props {
    initialValue?: CourseOffer;
    institutions: InstitutionOption[]
    onCourseSelected:(course: CourseOffer | undefined) => void;
}

export default ({ initialValue, onCourseSelected, institutions }: Props) => {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ subjects, setSubjects ] = useState<Option[]>([]);
    const [ courses, setCourses ] = useState<CourseOffer[]>([]);

    const courseOptions = useMemo<Option[]>(() => {
        return rawDataToCourseOptions(courses);
    }, [ courses ]);

    const [ _institution, _setInstitution ] = useState<string>('');
    const [ _subject, _setSubject ] = useState<string>('');
    const [ _course, _setCourse ] = useState<string>('');

    const onCourseChange = (courseId: string) => {
        console.log('onCourseChange courseId: ', courseId);
        _setCourse(courseId);
    };

    const onSubjectChange = (subject: string) => {
        console.log('onSubjectChange subject: ', subject);
        setLoading(true);
        _setSubject(subject);
        _setCourse('');
        setCourses([]);

        getCoursesOffered(_institution, subject, getSummerTerms(_institution))
            .then(results => {
                console.log('results: ', results);
                setCourses(results);

                setTimeout(() => {
                    setLoading(false);
                }, 50);
            })
            .catch(err => {
                console.error('getCoursesOffered err: ', err);
                setTimeout(() => {
                    setLoading(false);
                }, 50);
            });
    };

    const onInstitutionChange = (institution: string) => {
        console.log('onInstitutionChange: ', institution);

        setLoading(true);

        _setInstitution(institution);
        _setSubject('');
        setSubjects([]);
        _setCourse('');
        setCourses([]);

        graphClient.query({
            query: getSubjectsByInstitutionAndTermQuery_Original,
            variables: {
                institution,
                terms: getSummerTerms(institution)
            }
        })
            .then(results => {
                console.log('subjects results: ', results);
                const updatedSubjects = rawDataToSubjectOptions(results.data.items);
                setSubjects(updatedSubjects);

                setTimeout(() => {
                    setLoading(false);
                }, 50);
            })
            .catch(err => {
                console.error('err: ', err);
                setTimeout(() => {
                    setLoading(false);
                }, 50);
            });
    };

    useEffect(() => {
        const selected = _.find(courses, {courseId: _course});
        onCourseSelected(selected);
    }, [ _course ]);

    useEffect(() => {
        if (initialValue) {
            console.log('hasInitialValue: ', initialValue);
            setLoading(true);

            _setInstitution(initialValue.institution);

            graphClient.query({
                query: getSubjectsByInstitutionAndTermQuery_Original,
                variables: {
                    institution: initialValue.institution,
                    terms: getSummerTerms(initialValue.institution)
                }
            })
                .then(results => {
                    console.log('subjects results: ', results);
                    const updatedSubjects = rawDataToSubjectOptions(results.data.items);
                    setSubjects(updatedSubjects);
                    _setSubject(initialValue.subject);

                    getCoursesOffered(initialValue.institution, initialValue.subject, getSummerTerms(initialValue.institution))
                        .then(results => {
                            console.log('results: ', results);
                            setCourses(results);
                            _setCourse(initialValue.courseId);

                            setTimeout(() => {
                                setLoading(false);
                            }, 50);
                        })
                        .catch(err => {
                            console.error('getCoursesOffered err: ', err);
                            setTimeout(() => {
                                setLoading(false);
                            }, 50);
                        });

                })
                .catch(err => {
                    console.error('err: ', err);
                    setTimeout(() => {
                        setLoading(false);
                    }, 50);
                });


        }
    }, []);

    return (
        <ContainerStyle>
            <SelectQuestion
                containerStyle={SelectContainerStyleProps}
                inputStyle={SelectInputStyleProps}
                // label="Institution"
                name="institution"
                choices={institutions}
                onChange={e => onInstitutionChange(e.target.value)}
                selected={_institution}
            />
            <SelectQuestion
                containerStyle={SelectContainerStyleProps}
                inputStyle={SelectInputStyleProps}
                // label="Subject"
                name="subject"
                choices={subjects}
                onChange={e => onSubjectChange(e.target.value)}
                selected={_subject}
            />
            <SelectQuestion
                containerStyle={SelectContainerStyleProps}
                inputStyle={SelectInputStyleProps}
                // label="Course"
                name="course"
                choices={courseOptions}
                onChange={e => onCourseChange(e.target.value)}
                selected={_course}
            />

        </ContainerStyle>
    );
};
