import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartOptions, ChartData } from 'chart.js';
import { RegistrationStats } from '@/api/types';
import { chartColors } from '../../chartUtils';
import styled from 'styled-components/macro';
import ChartDataLabels from 'chartjs-plugin-datalabels';
interface Props {
    data: RegistrationStats;
}

const RegistrationChartContainerStyle = styled.div`
    position: relative;
    width: 180px;
    height: 180px;
    flex-shrink: 0;
    
    @media (min-width: 1301px) and (max-width: 1450px){
        width: 160px;
    }

    @media (max-width: 1000px) {
        width: 140px;
        // height: 140px;
    }
`;

export default ({ data }: Props) => {
    const [ chartData, setChartData ] = useState<ChartData<'doughnut', any[], string>>({
        labels: [],
        datasets: [ {
            data: [],
        } ],
    });

    const [ chartOptions ] = useState<ChartOptions<'doughnut'>>({
        responsive: true,
        aspectRatio: 1,
        maintainAspectRatio: false,
        events: [],
        cutout: '60%',
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                color: '#fff',
            },
        },
        animation: {
            duration: 500,
            easing: 'easeOutQuart',
        },
    });

    const generateChartData = (data: RegistrationStats) => {
        const labels: any = [ 'Registered Only', 'Registered & Completed', 'Not Eligible' ];
        const values: any = [ data.registeredOnly, data.registeredAndCompleted, data.notEligible ];
        const colors = [ chartColors[0].faded, chartColors[0].normal, chartColors[1].faded ];
        const totalValue = data.registeredOnly + data.registeredAndCompleted + data.notEligible;
        const updatedChartData: ChartData<'doughnut', any[], string> = {
            labels,
            datasets: [ {
                data: values,
                backgroundColor: colors,
                datalabels: {
                    formatter: function (value) {
                        return Math.round((value * 100) / totalValue).toString() + `%\n(${value})`;
                    },
                },
            } ],
        };
        return updatedChartData;
    };

    useEffect(() => {
        const updatedChartData = generateChartData(data);
        setChartData(updatedChartData);
    }, [ data ]);

    return (
        <RegistrationChartContainerStyle>
            <Doughnut
                // type={'doughnut'}
                plugins={[ ChartDataLabels ]}
                height={180}
                width={180}
                data={chartData}
                options={chartOptions}
            />
        </RegistrationChartContainerStyle>
    );
};
