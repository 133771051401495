import { Redirect, RouteComponentProps } from 'react-router';
import useSimulationHooks from '@/components/simulation/useSimulationHooks';
import React from 'react';

export default (Component: React.ComponentType) => {
    return (_props: RouteComponentProps) => {
        const { isSimulationInProgress } = useSimulationHooks();
        if (isSimulationInProgress) {
            return <Redirect to="/" />;
        }
        return (
            <Component/>
        );
    };
};
