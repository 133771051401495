import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import _ from 'lodash';
import { Option } from '@/api/types';
import { disableSubmitOnEnter } from '@/components/common/inputUtils';

const ContainerStyle = styled.div`
    ${tw`mb-2 flex justify-between`}
    ${tw`border-0 border-solid border-b border-gray-300`}
    
    .slider-container {
        display: flex;
        width: 50%;
        flex-shrink: 0;
        align-items: center;
        
        .value-label {
        }
        
        input, .value-input {
            vertical-align: middle;
        }
        .value-input {
            ${tw`mx-1 px-1 py-2`}
            width: 55px;
            text-align: center;
            border: 1px solid #ccc;
            border-radius: 3px;
        }
        
        .range-input {
         touch-action: none;
            -webkit-appearance: none;
              width: 100%;
              height: 10px;
              border-radius: 5px;
              background: #d7dcdf;
              outline: none;
              padding: 0;
              margin: 0;
              
                // Range Handle
              &::-webkit-slider-thumb {
                appearance: none;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #2c3e50;
                cursor: pointer;
                transition: background .15s ease-in-out;
            
                &:hover {
                  background: #3BC6A0;
                }
              }
        }
    }
`;

interface Props {
    option: Option;
    onChange: (e: number) => void;
    value: number;
    displayValue?: boolean;
    minLabel?: string;
    maxLabel?: string;
}

export default ({ option, onChange, value, displayValue = false, minLabel = '', maxLabel = '' }: Props) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        let newValue = _.parseInt(event.target.value);
        if (!newValue) {
            newValue = 1;
        } else if (newValue > 100) {
            newValue = 100;
        }
        onChange(newValue);
        return false;
    };
    return (
        <ContainerStyle>
            <p css={tw`text-right flex-grow mr-4 mb-2 font-normal`}>{option.label}</p>
            <div className={'slider-container'}>
                <span className={'value-label'}>{minLabel}</span>
                <input
                    className={'range-input'}
                    type="range"
                    name={option.label}
                    min={1}
                    max={100}
                    value={value}
                    onChange={handleChange}
                    onKeyPress={disableSubmitOnEnter}
                />
                <span className={'value-label'}>{maxLabel}</span>
                {
                    displayValue ?
                        <input
                            className={'value-input'}
                            type={'number'}
                            min={1}
                            max={100}
                            value={value}
                            onChange={handleChange}
                            onKeyPress={disableSubmitOnEnter}
                        />
                        : null
                }
            </div>
        </ContainerStyle>
    );
};
