import { gql } from '@apollo/client';
import graphClient from '@/api/graph';
import { parseCourseTitle } from '@/api/transformers';
import { Option } from '@/api/types';
import _ from 'lodash';

export const GET_COURSES_OFFERED = gql`
query getCourses(
    $filter: custom_crse_offer_view_bool_exp,
) {
    items: custom_crse_offer_view(
        where:$filter,
    ) {
        courseId: crse_id
        subject
        courseNumber: catalog_nbr
        institution
        units: units_acad_prog
        academicGroup: acad_group
        courseTitle: course_title_long
    }
}
`;

export const GET_COURSES_OFFERED_ORIGINAL = gql`
query getCoursesOfferedOriginal($institution: String!, $subject: String!, $terms: [String!]) {
    items: ps_class_tbl(
        where: {
            institution: {
                _eq: $institution
            },
            acad_career: {
                _in: [ "UGRD", "UKCC", "ULAG" ]
            },
            strm: {
                _in: $terms
            },
            subject: {
                _eq: $subject
            }
        },
        distinct_on: crse_id
    ) {
        courseId: crse_id
        subject
        courseNumber: catalog_nbr
        term:strm
        institution
        academicGroup: acad_group

        catalog {
            courseTitle: course_title_long
            units: units_acad_prog
        }
    }
}
`;

export type CourseOffer = {
    courseId: string;
    subject: string;
    courseNumber: string;
    institution: string;
    academicGroup: string;
    term: string;
    catalog: {
        courseTitle: string;
        units: number;
    }


    // if transferring in
    sendingCourseId?: string;
}
export const getCoursesOffered = async (institution: string, subject: string, terms: string[]): Promise<CourseOffer[]> => {
    console.log('getCoursesOffered institution: ', institution);
    const results = await graphClient.query({
        query: GET_COURSES_OFFERED_ORIGINAL,
        variables: {
            institution,
            subject,
            terms,
        },
        fetchPolicy: 'cache-first'
    });

    if (results.data.items) {
        return sortCourseNumbers(results.data.items);
    }
    return [];
};


export const hasLettersBetweenNumbers = (catalogNbr: string): boolean => {
    return /^\d+[a-zA-Z]+\d+$/.test(catalogNbr);
};

export const replaceLettersWithZeros = (catalogNbr: string): string => {
    return catalogNbr.replace(/[a-zA-Z]/g, '0');
};

export const rankCourseNumber = (catalogNbr: string): string | number => {
    const numbersOnly = catalogNbr.replace(/[^\d.]/g, '');

    // for catalog_nbr that contains ALL letters:
    if (!numbersOnly) {
        return catalogNbr;
    }

    if (hasLettersBetweenNumbers(catalogNbr)) {
        return replaceLettersWithZeros(catalogNbr);
    }

    return parseFloat(numbersOnly);
};

export const sortCourseNumbers = (courses: CourseOffer[]): CourseOffer[] => {
    return _.sortBy<CourseOffer>(courses, [ o => rankCourseNumber(o.courseNumber), 'courseNumber' ]);
};

export const rawDataToCourseOptions = (items: CourseOffer[]): Option[] => {
    const options = items.map(o => {
        return {
            value: o.courseId,
            // label: parseCourseTitle({...o, courseTitle: o.catalog?.courseTitle}) + ` (rank: ${rankCourseNumber(o.courseNumber)}`,
            label: parseCourseTitle({...o, courseTitle: o.catalog?.courseTitle}),
        };
    });
    return options;
};
