import React, { useEffect, useMemo, useState } from 'react';
import { clearResultsById } from '@/api/simulation/ResultsAPI';
import { Spinner } from '../common';
import { SimulationResultsRaw } from '@/api/simulation/SimulationAPI';
import { formatShortTimestamp } from '@/components/simulation/SimulationDebugger';
import tw from 'twin.macro';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import graphClient from '@/api/graph';
import getStudentInfoById from '@/api/graphql/queries/getStudentInfoById';
import { RegistrationStats, StudentInfo } from '@/api/types';
import { Link } from 'react-router-dom';
import StudentPreviewerModal from '@/components/dashboard/StudentPreviewer';
import {
    AccountTagStyle,
    DashboardContainerStyle,
    GalleryGridItemStyle, GalleryGridStyle, ResultsTableStyle,
    TableContainerStyle
} from './styled';
import { formatLastInteractionRaw } from '@/components/dashboard/dashboardUtils';
import RawResultsModal from '@/components/dashboard/RawResultsModal';
import Paginator from '@/components/search-results/Paginator';
// Configure ChartJS.
import '@/chartjs-adapter';
import TotalRegistrations from '@/components/dashboard/components/registrations/TotalRegistrations';
import { simulateCompletedEnrollments } from '@/utils/enrollmentUtils';
import getResultsAggregate, {
    generateEmptyAggregateStats,
    ResultsAggregateResponse
} from '@/api/simulation/getResultsAggregate';
import NewResponsesByDay from '@/components/dashboard/components/new-responses/NewResponsesByDay';
import InteractionCategories from '@/components/dashboard/components/interactions/InteractionCategories';
import CartDistributions from '@/components/dashboard/components/cart-distribution/CartDistributions';
import { DateRangePresetEnum } from '@/components/dashboard/components/date-range-picker/DateRangeUtils';
import ResultsFilterBar from '@/components/dashboard/components/filter-bar/ResultsFilterBar';
import getResults from '@/api/simulation/getResults';
import { FiltersParams, QueryParams } from '@/components/dashboard/components/filter-bar/filterParams';

const limit = 100;
const INITIAL_PRESET = DateRangePresetEnum.ThisWeek;

export default () => {
    const [ filtersParams, setFiltersParams ] = useState<FiltersParams>({});

    const [ loading, setLoading ] = useState<boolean>(false);

    const [ page, setPage ] = useState<number>(0);

    const [ results, setResults ] = useState<SimulationResultsRaw[]>([]);

    const [ aggregateStats, setAggregateStats ] = useState<ResultsAggregateResponse>(generateEmptyAggregateStats());


    const registrationStats = useMemo<RegistrationStats>(() => {
        const { counts } = aggregateStats;

        const totalRegistrations = counts.find(o => o.id === 'countTotal')?.count || 0;
        const registeredAndCompleted = counts.find(o => o.id === 'countCompletedSimulation')?.count || 0;
        const notEligible = counts.find(o => o.id === 'countNotEligible')?.count || 0;
        const registeredOnly = totalRegistrations - registeredAndCompleted - notEligible;

        return {
            totalRegistrations,
            registeredAndCompleted,
            notEligible,
            registeredOnly,
        };

    }, [ aggregateStats ]);

    const [ orderBy, setOrderBy ] = useState<{ column: string, reverse: boolean }>({
        column: 'last_logged_in',
        reverse: true
    });

    const queryParams = useMemo<QueryParams>(() => {
        return {
            ...filtersParams,
            offset: limit * page,
            limit,
            order: `${orderBy.column}__${orderBy.reverse ? 'desc' : 'asc'}`,
        };
    }, [ filtersParams, page, orderBy ]);

    const fetchResults = () => {
        setLoading(true);
        Promise.all([
            getResultsAggregate(queryParams),
            getResults(queryParams)
        ])
            .then(responses => {
                setAggregateStats(responses[0]);
                setResults(responses[1]);
            })
            .catch(err => console.error(err))
            .then(() => setLoading(false));
    };

    const sort = (column: string) => {
        setPage(0);
        setOrderBy({
            column,
            reverse: column === orderBy.column ? !orderBy.reverse : false
        });
    };

    const clearStudentResults = (studentId: string) => {
        const r = confirm(`Are you sure want to clear simulation results for student: ${studentId}?`);
        if (r) {
            setLoading(true);
            clearResultsById(studentId)
                .catch(err => {
                    console.error(err);
                })
                .then(() => {
                    setLoading(false);
                    fetchResults();
                });
        }
    };

    const [ studentInfo, setStudentInfo ] = useState<StudentInfo | null>(null);
    const [ studentResults, setStudentResults ] = useState<SimulationResultsRaw | null>(null);
    const [ showRawResultsModal, setShowRawResultsModal ] = useState<boolean>(false);

    const selectStudent = (student: SimulationResultsRaw) => {
        setLoading(true);
        setStudentInfo(null);
        setStudentResults(student);

        if (!studentInfo) {
            setLoading(false);
        }

        graphClient.query({
            query: getStudentInfoById,
            variables: {
                studentId: student.student_id
            }
        })
            .then(results => {
                console.log('getStudentInfo results.data.items: ', results.data.items);
                if (results.data.items[0]) {
                    const simulatedInfo: StudentInfo = {
                        ...results.data.items[0],
                        enrollments: simulateCompletedEnrollments(results.data.items[0].enrollments),
                    };
                    setStudentInfo(simulatedInfo);
                }
            })
            .catch(err => console.error(err))
            .then(() => setLoading(false));
    };

    const fetchMoreHandler = (pageProp: number) => {
        setPage(pageProp - 1);
    };

    // retrieves column filters and grouping. We then need to combine with our order/page/limit params here
    const onSubmitFilters = (params: FiltersParams) => {
        console.log('onSubmitFilters params: ', params);
        setFiltersParams(params);
    };

    useEffect(() => {
        fetchResults();
    }, [ queryParams ]);

    return (
        <DashboardContainerStyle>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />

            <div css={tw`text-right`} style={{maxWidth: '1500px'}}>
                <Link to="/admin/accounts">
                    <span>Accounts</span>
                </Link>
            </div>

            <div css={tw`flex flex-row justify-between`}>
                <ResultsFilterBar onSubmit={onSubmitFilters}/>
            </div>


            <GalleryGridStyle>
                {
                    registrationStats ?
                        <GalleryGridItemStyle
                            rowSpan={1}
                            colSpan={1}
                        >
                            <TotalRegistrations
                                data={registrationStats}
                            />
                        </GalleryGridItemStyle>
                        : null
                }

                <GalleryGridItemStyle
                    rowSpan={1}
                    colSpan={2}
                >
                    <NewResponsesByDay
                        data={aggregateStats.byDay}
                        // dateRange={dates}
                    />
                </GalleryGridItemStyle>

                <GalleryGridItemStyle
                    rowSpan={1}
                    colSpan={2}
                >
                    <CartDistributions
                        currentCartSizes={aggregateStats.byCurrentCartSizeStartedOnly}
                        submittedCartSizes={aggregateStats.bySubmittedCartSizeStartedOnly}
                        counts={aggregateStats.counts}
                    />
                </GalleryGridItemStyle>



                <GalleryGridItemStyle
                    rowSpan={1}
                    colSpan={1}
                >
                    <InteractionCategories
                        data={aggregateStats.byInteraction}
                    />
                </GalleryGridItemStyle>

            </GalleryGridStyle>


            <Paginator
                totalItemsCount={registrationStats.totalRegistrations}
                onChange={page => fetchMoreHandler(page)}
                itemsCountPerPage={limit}
                activePage={page + 1}
            />

            <TableContainerStyle>
                <ResultsTableStyle css={tw`flex-shrink-0`}>
                    <thead>
                        <tr>
                            <th><span>#</span></th>

                            <th>
                                <a onClick={() => sort('student_id')}>
                                    <span>Student Id</span>
                                    {
                                        orderBy.column === 'student_id' ?
                                            <FontAwesomeIcon icon={orderBy.reverse ? faCaretDown : faCaretUp } />
                                            : null
                                    }
                                </a>
                            </th>
                            <th>
                                <a onClick={() => sort('cohort')}>
                                    <span>Cohort</span>
                                    {
                                        orderBy.column === 'cohort' ?
                                            <FontAwesomeIcon icon={orderBy.reverse ? faCaretDown : faCaretUp } />
                                            : null
                                    }
                                </a>
                            </th>
                            <th>
                                <a onClick={() => sort('created_at')}>
                                    <span>First Logged In</span>
                                    {
                                        orderBy.column === 'created_at' ?
                                            <FontAwesomeIcon icon={orderBy.reverse ? faCaretDown : faCaretUp } />
                                            : null
                                    }
                                </a>
                            </th>
                            <th>
                                <a onClick={() => sort('last_logged_in')}>
                                    <span>Last Logged In</span>
                                    {
                                        orderBy.column === 'last_logged_in' ?
                                            <FontAwesomeIcon icon={orderBy.reverse ? faCaretDown : faCaretUp } />
                                            : null
                                    }
                                </a>

                            </th>
                            <th>
                                <span>Last Submitted Interaction</span>
                            </th>
                            <th>
                                <a onClick={() => sort('screener')}>
                                    <span>Screener</span>
                                    {
                                        orderBy.column === 'screener' ?
                                            <FontAwesomeIcon icon={orderBy.reverse ? faCaretDown : faCaretUp } />
                                            : null
                                    }
                                </a>

                            </th>
                            <th>
                                <a onClick={() => sort('consent_ts')}>
                                    <span>Consent</span>
                                    {
                                        orderBy.column === 'consent_ts' ?
                                            <FontAwesomeIcon icon={orderBy.reverse ? faCaretDown : faCaretUp } />
                                            : null
                                    }
                                </a>
                            </th>

                            <th>
                                <span>Survey Submission</span>
                            </th>

                            <th>
                                <span>Payment Info</span>
                            </th>

                            <th>
                                <span>Class Search<br/>(First)</span>
                            </th>
                            <th>
                                <span>Class Search<br/>(Last)</span>
                            </th>

                            <th>
                                <span>Cart Size<br/>(Submitted)</span>
                            </th>

                            <th>
                                <span>Cart Size<br/>(Current)</span>
                            </th>

                            <th>
                                {/* Controls column */}
                            </th>

                            {/* <th><span>Shopping Cart</span></th> */}
                            {/* <th><span>Data</span></th> */}
                            {/* <th><span>Survey</span></th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            results.map((o, i) => {
                                return (
                                    <tr key={o.student_id}>
                                        <td><span css={tw`text-right`}>{i + 1 + (limit * page)}.</span></td>
                                        <td>
                                            <span>{o.student_id}</span>
                                            {o.is_tester && <AccountTagStyle css={tw`mx-1`}>T</AccountTagStyle>}
                                            {/* {o.isStaff && <AccountTagStyle css={tw`bg-blue-500`}>S</AccountTagStyle>} */}
                                        </td>
                                        <td>{o.cohort}</td>
                                        <td><span>{formatShortTimestamp(o.first_logged_in)}</span></td>
                                        <td><span>{formatShortTimestamp(o.last_logged_in)}</span></td>

                                        <td>
                                            <span>{formatLastInteractionRaw(o.last_interaction)}</span>
                                        </td>

                                        <td><span>{o.screener.registerIntent}</span></td>

                                        <td><span>{formatShortTimestamp(o.consent_ts)}</span></td>

                                        <td><span>{formatShortTimestamp(_.get(o, 'survey.demographics.submittedTs'))}</span></td>


                                        <td>
                                            {
                                                _.map(_.pickBy(o.payment_info, (v, k) => k === 'type' && _.identity(v)), (value, key) => {
                                                    return (
                                                        <p key={key}>{_.capitalize(key)}: {value}</p>
                                                    );
                                                })
                                            }
                                        </td>

                                        <td>
                                            {
                                                <p>{formatShortTimestamp(_.get(o, 'stats.firstSearch.ts'))}</p>
                                            }
                                        </td>

                                        <td>
                                            {
                                                <p>{formatShortTimestamp(_.get(o, 'stats.lastSearch.ts'))}</p>
                                            }
                                        </td>

                                        <td>
                                            <span>{o.data.originalSubmittedCart?.length || 'N/A'}</span>
                                        </td>

                                        <td>
                                            <span>{o.shopping_cart.length}</span>
                                        </td>




                                        <td>
                                            <a css={tw`mx-3 text-red-500 hover:underline cursor-pointer hover:opacity-75`} onClick={() => clearStudentResults(o.student_id)}>Clear</a>
                                            {/* <a */}
                                            {/*    css={tw`mx-3 hover:opacity-75 hover:underline cursor-pointer text-link`} */}
                                            {/*    onClick={() => selectStudent(o)} */}
                                            {/* > */}
                                            {/* Details */}
                                            {/* </a> */}

                                            <a
                                                css={tw`mx-3 hover:opacity-75 hover:underline cursor-pointer text-link`}
                                                onClick={() => {
                                                    setStudentResults(o);
                                                    setShowRawResultsModal(true);
                                                }}
                                            >
                                            Raw
                                            </a>
                                        </td>


                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </ResultsTableStyle>

            </TableContainerStyle>

            <Paginator
                totalItemsCount={registrationStats.totalRegistrations}
                onChange={page => fetchMoreHandler(page)}
                itemsCountPerPage={limit}
                activePage={page + 1}
            />

            <StudentPreviewerModal
                onClose={() => {
                    console.log('onClose');
                    setStudentInfo(null);
                    setStudentResults(null);
                }}
                student={studentInfo}
                results={studentResults}
            />

            <RawResultsModal
                results={studentResults}
                visible={showRawResultsModal}
                onClose={() => {
                    setShowRawResultsModal(false);
                }}
            />

        </DashboardContainerStyle>
    );

};

