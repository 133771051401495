import React from 'react';
import { CardBodyStyle, CardHeaderStyle, CardStyle } from '../../styled';
import tw from 'twin.macro';
import { IntegerFormatter } from '@/utils/utilities';
import RegistrationsChart from './RegistrationsChart';
import MetricSummary from '../../styled/MetricSummary';
import LegendLabel from '../../styled/LegendLabel';
import { RegistrationStats } from '@/api/types';
import { chartColors } from '@/components/dashboard/chartUtils';

interface Props {
    data: RegistrationStats;
}

const TotalRegistrations = ({ data }: Props) => {
    return (
        <CardStyle>
            <CardHeaderStyle>Registrations</CardHeaderStyle>
            <CardBodyStyle>
                <div css={tw`flex items-center justify-between mt-8`}>
                    <RegistrationsChart
                        data={data}
                    />

                    <div css={tw`flex w-full justify-center self-stretch`}>
                        <div css={tw`flex flex-col items-start justify-between self-stretch`}>

                            <MetricSummary
                                label="Total Registrations"
                                value={IntegerFormatter.format(data.totalRegistrations)}
                                containerStyle={tw`self-start`}
                            />

                            <div>
                                <div css={tw`mb-2`}>
                                    <LegendLabel
                                        label="Registrations Only"
                                        shape="rect"
                                        color={chartColors[0].faded}
                                        fontStyle={tw`text-base whitespace-no-wrap`}
                                    />
                                </div>
                                <div>
                                    <LegendLabel
                                        label="Registered & Completed"
                                        shape="rect"
                                        color={chartColors[0].normal}
                                        fontStyle={tw`text-base whitespace-no-wrap`}
                                    />
                                </div>
                                <div>
                                    <LegendLabel
                                        label="Not Eligible"
                                        shape="rect"
                                        color={chartColors[1].faded}
                                        fontStyle={tw`text-base whitespace-no-wrap`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CardBodyStyle>
        </CardStyle>
    );
};

export default TotalRegistrations;
