import { SelectedCourse } from '@/api/types';
import _ from 'lodash';
import updateSimResults from '@/api/simulation/updateSimResults';
import store from '@/store';

// classToAdd will have already been updated with the simulated sectionMode, since it originates from addClass().
export default async (classToAdd: SelectedCourse, classToRemove: SelectedCourse): Promise<SelectedCourse> => {
    const { student } = store.getActions();
    const studentDetails = await student.getStudentDetails();

    const { shoppingCart } = studentDetails;

    const updatedCart = _.cloneDeep(shoppingCart);
    const removed = _.remove(updatedCart, { courseId: classToRemove.courseId });
    if (!removed.length) {
        console.warn('trying to replace a class that doesn\'t seem to belong in the cart. was it already removed?');
    }

    // we shouldn't need to check, but just in case if user double clicked before store state updated or something else
    const existing = _.find(updatedCart, {courseId: classToAdd.courseId});
    if (!existing) {
        updatedCart.push(classToAdd);
    }

    return new Promise<SelectedCourse>((resolve, reject) => {
        updateSimResults(studentDetails.studentId, {
            shoppingCart: updatedCart
        })
            .then(() => {
                resolve(classToAdd);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    });
};
