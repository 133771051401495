import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const FilterRowStyle = styled.div`
    ${tw`flex flex-row items-end`}
    
    select, input {
        ${tw`px-2 py-2 text-base`}
    }

`;

export const TextColumnValueInputStyle = styled.div`
    ${tw`flex flex-row`}
    input, select {
        ${tw`text-sm`}
    }
`;

export const TimestampInputStyle = styled.div`
    ${tw`flex flex-row`}
    
    .react-datepicker__input-container {
        input {
            ${tw`text-sm pr-6`}
        }
        .react-datepicker__close-icon {
            &::after {
                ${tw`bg-gray-600`}
            }
            
        }
    }
`;

export const ColumnSelectStyle = styled.div`
    position: relative;
    // z-index: 999;
    // ${tw`px-2 py-2 rounded border border-solid border-gray-700`}
    min-width: 210px;
`;

export const ColumnSelectDropdownStyle = styled.div`
    ${tw`border border-gray-400 rounded py-3 bg-white shadow-md`}
    z-index: 999;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 210px;
    max-height: 300px;
    overflow: auto;
    
`;

interface ColumnItemStyleProps {
    selected: boolean;
}

export const ColumnItemGroupStyle = styled.div`
    ${tw`pl-2 pr-1 py-1 mt-2`}
    label {
        ${tw`italic`}
    }
`;

export const ColumnItemStyle = styled.div<ColumnItemStyleProps>`
    ${tw`cursor-pointer pl-2 pr-1 py-1 hover:bg-gray-100 hover:opacity-75`}
    ${props => props.selected ? tw`font-medium bg-gray-200 hover:opacity-100` : ''}
`;
