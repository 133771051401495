import React from 'react';
import { parseClassFromStudentEnrollmentHistory } from '@/api/transformers';
import { currentTerm, termMappings } from '@/constants/terms';
import { InstitutionName } from '@/constants/institutions';
import tw from 'twin.macro';
import { CourseComponentName } from '@/constants/courseComponents';
import { StudentEnrollment } from '@/api/types';
import { StickyTableStyle } from '@/components/enrollment-history/styled';

interface Props {
    enrollments?: StudentEnrollment[];
}

export default ({ enrollments = [] }: Props) => {
    if (!enrollments.length) {
        return <p css={tw`ml-4`}>None</p>;
    }
    return (
        <StickyTableStyle>
            <thead>
                <tr>
                    <th>Term</th>
                    <th>Institution</th>
                    <th>Course</th>
                    <th>Component</th>
                    <th>Units Taken</th>
                    <th>Units Earned</th>
                    <th>Grade</th>
                    <th>Grade Points</th>
                    <th>Sim</th>
                </tr>
            </thead>
            <tbody>
                {enrollments.map(en => {
                    return (
                        <tr key={en.institution + en.strm + en.classId}>

                            <td><span>{termMappings[en.strm].replace(' Term', '')}</span></td>

                            <td><span>{InstitutionName[en.institution]}</span></td>

                            <td><span>{parseClassFromStudentEnrollmentHistory(en)}</span></td>
                            <td><span>{CourseComponentName[en.class.courseComponent]}</span></td>


                            <td css={tw`text-center`}><span>{en.unitsTaken ? en.unitsTaken.toFixed(2) : ''}</span></td>
                            <td css={tw`text-center`}><span>{en.unitsEarned ? en.unitsEarned.toFixed(2) : ''}</span></td>
                            <td css={tw`text-left`}><span css={tw`pl-2`}>{en.grade}</span></td>
                            <td css={tw`text-center`}><span>{en.gradePoints ? en.gradePoints.toFixed(2) : ''}</span></td>

                            <td><span css={tw`text-center`}>{en.simulated ? 'Y' : ''}</span></td>


                        </tr>
                    );
                })}
            </tbody>
        </StickyTableStyle>
    );
};
