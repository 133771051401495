import { CourseClass } from '@/api/types';
import { formatCourseDate } from '@/utils/dateUtils';
import { MeetingInfoTableStyle } from './styled';
import React from 'react';
import ClassTimes from '@/components/shopping-cart/ClassTimes';

interface Props {
    courseClass: CourseClass
}

export default ({ courseClass }: Props) => {
    return (
        <MeetingInfoTableStyle>
            <thead>
                <tr>
                    <th><span>Days & Times</span></th>
                    <th><span>Room</span></th>
                    <th><span>Instructor</span></th>
                    <th><span>Meeting Dates</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><ClassTimes item={courseClass}/></td>
                    <td>
                        <span>TBA</span>
                    </td>
                    <td>
                        <span>Staff</span>
                    </td>
                    <td><span>{formatCourseDate(courseClass.startDate)} - {formatCourseDate(courseClass.endDate)}</span></td>
                </tr>
            </tbody>
        </MeetingInfoTableStyle>
    );
};
