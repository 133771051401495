import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import CUNYFirstLogo from '@/assets/images/cuny-first-logo.jpg';
import tw from 'twin.macro';
import { useRole } from '@/api/AuthService';
import { Link } from 'react-router-dom';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import { useGetSimResults } from '@/api/simulation/SimulationAPI';
import { useStoreActions } from '@/store';

const CUNYFirstLogoStyle = styled.img`
    max-width: 110px;
`;

const HeaderStyle = styled.div`
    ${tw`bg-blue-500 flex justify-between px-2 items-center overflow-hidden`}
    a, span {
        ${tw`text-white hover:opacity-75 cursor-pointer`}
        text-decoration: none;
    }
    a {
        ${tw`mx-5`}
    }
    
    .logo-link {
        width: 110px;
        height: 40px;
        position: relative;
        display: inline-block;

        img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    
    @media (max-width: 649px) {
        .nav-links {
            display: none;
            a:not(:last-child) {
                display: none;
            }
        }
    }
    
    .nav-links-mobile-wrapper {
        .nav-links-mobile {
            position: absolute;
            right: 0;
            ${tw`bg-blue-500`}
            display: flex;
            flex-direction: column;
            z-index: 2;
            a {
                ${tw`my-3`}
            }
        }
    }
    @media (min-width: 650px) {
        ${tw`px-2`}
        
        .nav-links-mobile, .nav-links-mobile-wrapper {
            display: none;
        }
    }
`;

export default () => {
    const history = useHistory();
    const logout = useStoreActions(actions => actions.logout);

    const onLogout = () => {
        logout();
    };

    const role = useRole();
    const { data: simulationData } = useGetSimResults();

    const [ showMobileHeader, setShowMobileHeader ] = useState<boolean>(false);

    useEffect(() => {
        setShowMobileHeader(false);
    }, [ history.location ]);

    const [ classSearchPath, setClassSearchPath ] = useState<string>('/search');
    useEffect(() => {
        setClassSearchPath(simulationData?.currentStage ? '/replacement-search' : '/search');
    }, [ simulationData, simulationData?.currentStage ]);

    const headerLinks = useMemo<JSX.Element[]>(() => {
        if (role === 'dev') {
            return [
                (
                    <Link key="availability" to="/availability">
                        <span>Calendar</span>
                    </Link>
                ),
                (
                    <Link key="class-search" to={classSearchPath}>
                        <span>Class Search</span>
                    </Link>
                ),
                (
                    <Link key="cart" to="/cart">
                        <span>Shopping Cart</span>
                    </Link>
                ),
                (
                    <Link key="survey" to="/survey">
                        <span>Survey</span>
                    </Link>
                ),
                // (
                //     <Link key="enrollment-history" to="/enrollment-history">
                //         <span>History</span>
                //     </Link>
                // ),
                // (
                //     <Link key="degree" to="/degree">
                //         <span>Degree</span>
                //     </Link>
                // ),
                <a key="logout" onClick={() => onLogout()} css={tw`text-white hover:opacity-75 cursor-pointer`}>Logout</a>
            ];
        } else {
            return [
                <a key="logout" onClick={() => onLogout()} css={tw`text-white hover:opacity-75 cursor-pointer`}>Logout</a>
            ];
        }

    }, [ role, classSearchPath ]);

    return (
        <HeaderStyle>
            <Link className="logo-link" to="/">
                <CUNYFirstLogoStyle src={CUNYFirstLogo}/>
            </Link>

            <div className="nav-links">
                {
                    role === 'dev' ?
                        <Link key="admin" to="/admin">
                            <span>Admin</span>
                        </Link>
                        : null
                }

                {headerLinks}
            </div>

            <div className="nav-links-mobile-wrapper">
                <a onClick={() => setShowMobileHeader(!showMobileHeader)}>
                    <FontAwesomeIcon icon={faBars}/>
                </a>
                {
                    showMobileHeader ?
                        <div className="nav-links-mobile">
                            {headerLinks}
                        </div>
                        : null
                }
            </div>
        </HeaderStyle>
    );
};
