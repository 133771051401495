import React, { useEffect, useMemo, useState } from 'react';
import { InfoSectionContainerStyle, ClassDetailsContainerStyle } from './styled';
import {
    CourseClassWithGroupData,
    InstitutionType, RequisiteCourse,
    SectionMode
} from '@/api/types';
import {
    generateEmptyClassSearchForm,
    parseAvailableSeats,
    parseClassTitle,
} from '@/api/transformers';
import tw from 'twin.macro';
import { InstitutionName } from '@/constants/institutions';
import { termOverrideName } from '@/constants/terms';
import { CourseComponentName } from '@/constants/courseComponents';
import PropertyInfo from './PropertyInfo';
import { EnrollmentStatusEnum, AcademicCareerEnum } from '@/constants/enums';
import { parseSessionCode } from '@/constants/sessionTypes';
import { InstructionModeName } from '@/constants/instructionModes';
import { formatCourseDate } from '@/utils/dateUtils';
import MeetingInfoTable from './MeetingInfoTable';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Button, Spinner } from '../common';
import { getClassById } from '@/api/graphql/queries/getClassById';
import { useStoreActions, useStoreState } from '@/store';
import { CriteriaType } from '@/components/class-search/SearchCriteria';
import EnrollmentRequisiteSummary from '@/components/class-detail/requirements/EnrollmentRequisiteSummary';
import { useRole } from '@/api/AuthService';

interface LocationProps {
    from?: string;
    sectionMode?: SectionMode;
}

interface Params {
    classId: string;
}

export default () => {
    const role = useRole();
    const history = useHistory();
    const location = useLocation <LocationProps>();
    const params = useParams<Params>();

    const [ error, setError ] = useState<string>('');
    const [ loading, setLoading ] = useState<boolean>(false);

    const [ backButtonText, setBackButtonText ] = useState<string>('View Search Results');
    const [ selectedClass, setSelectedClass ] = useState<CourseClassWithGroupData | null>(null);

    const filters = useStoreState(state => state.search.filters);
    const setFilters = useStoreActions(actions => actions.search.setFilters);

    const goToClassResults = (course: RequisiteCourse) => {
        setFilters({
            ...generateEmptyClassSearchForm(),
            institution: selectedClass?.institution as InstitutionType,
            subjectId: course.subject,
            courseNumber: course.courseNumber,
            courseNumberCriteria: CriteriaType.IsExactly,
        });
        history.push('/results');
    };

    useEffect(() => {
        console.log('selectedClass: ', selectedClass);
    }, [ selectedClass ]);

    useEffect(() => {
        const { classId } = params;
        if (!classId) {
            history.push('/search');
        } else {
            setLoading(true);
            getClassById(parseInt(classId))
                .then(result => {
                    setSelectedClass(result);
                })
                .catch(err => setError(err))
                .then(() => setLoading(false));
        }

        if (location.state?.from === 'cart') {
            setBackButtonText('Back to Cart');
        } else {
            setBackButtonText('View Search Results');
        }
    }, [ location, params ]);

    // If the class is OA then we go ahead and display
    // However for Online Synchronous and On Campus we need to
    // use Section Mode since these are artificially created only for front-end. They don't exist in the backend.
    // This means we'll try to parse it from the shopping cart but
    // if student is clicking from search results then this field will be undefined.
    const sectionMode = useMemo<SectionMode | '' | undefined>(() => {
        if (selectedClass?.instructionMode === 'OA') return 'OA';

        return location.state?.sectionMode || '';
    }, [ location, selectedClass ]);

    return (
        <ClassDetailsContainerStyle>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />
            {
                selectedClass ?
                    <>
                        {error &&
                        <div css={[ tw`w-full mt-4 bg-red-200 p-2 text-red-600 rounded mb-2` ]}>
                            <span css={[ tw`font-medium` ]}>Error:</span> {error}
                        </div>
                        }

                        <h3>Class Detail</h3>

                        <p css={tw`text-cunyblue font-medium text-base`}>{parseClassTitle(selectedClass)}</p>

                        {/* <p css={tw` text-sm text-gray-500`}>{selectedClass.courseId}</p> */}
                        <p css={tw`mb-3 text-sm text-gray-500`}>{InstitutionName[selectedClass.institution]} | {termOverrideName} | {CourseComponentName[selectedClass.courseComponent]} | Course ID: {selectedClass.courseId}</p>

                        <InfoSectionContainerStyle>
                            <h3>Class Details</h3>

                            <div css={tw`flex px-3 py-2`}>
                                <div css={tw`w-full flex flex-col`}>
                                    <PropertyInfo label="Status" value={EnrollmentStatusEnum[selectedClass.enrollmentStatus]}/>
                                    <PropertyInfo label="Class Number" value={selectedClass.classId} />
                                    <PropertyInfo label="Session" value={parseSessionCode(selectedClass.sessionCode)} />

                                    <PropertyInfo label="Instruction Mode" value={sectionMode ? InstructionModeName[sectionMode] : ''} />
                                    <PropertyInfo label="Class Components" value={`${CourseComponentName[selectedClass.courseComponent]} Required`} />
                                </div>
                                <div css={tw`w-full flex flex-col`}>
                                    <PropertyInfo width={60} label="Career" value={selectedClass.academicCareer ? AcademicCareerEnum[selectedClass.academicCareer] : ''} />
                                    <PropertyInfo width={60} label="Dates" value={`${formatCourseDate(selectedClass.startDate)} - ${formatCourseDate(selectedClass.endDate)}`} />
                                    <PropertyInfo width={60} label="Grading" value={''} />
                                    <PropertyInfo width={60} label="Location" value={''} />
                                    <PropertyInfo width={60} label="Campus" value={''} />
                                </div>
                            </div>

                        </InfoSectionContainerStyle>

                        <InfoSectionContainerStyle>
                            <h3>Meeting Information</h3>
                            <MeetingInfoTable courseClass={selectedClass}/>
                        </InfoSectionContainerStyle>

                        {
                            role === 'dev' ?
                                <EnrollmentRequisiteSummary selectedClass={selectedClass} goToClassResults={goToClassResults}/>
                                :
                                <InfoSectionContainerStyle>
                                    <h3>Enrollment Information</h3>
                                    {
                                        selectedClass.requirementGroupName ?
                                            <div css={tw`mx-1`}>
                                                <PropertyInfo label="Requirement Group" value={`[${selectedClass.requirementGroup}] ${selectedClass.requirementGroupName}`}/>
                                                <PropertyInfo label="Group Description" value={selectedClass.requirementGroupDescriptionFull}/>
                                            </div>
                                            : null
                                    }
                                </InfoSectionContainerStyle>
                        }

                        <InfoSectionContainerStyle>
                            <h3>Class Availability</h3>
                            <div css={tw`flex px-3 py-2`}>
                                <div css={tw`w-full flex flex-col`}>
                                    <PropertyInfo width={120} label="Class Capacity" value={selectedClass.enrollmentCap} />
                                    <PropertyInfo width={120} label="Enrollment Total" value={selectedClass.enrollmentTotal} />
                                    {/* @ts-ignore */}
                                    <PropertyInfo width={120} label="Available Seats" value={parseAvailableSeats(selectedClass)} />
                                </div>
                                <div css={tw`w-full flex flex-col`}>

                                    <PropertyInfo width={120} label="Wait List Capacity" value={selectedClass.waitCap} />
                                    <PropertyInfo width={120} label="Wait List Total" value={selectedClass.waitTotal} />
                                </div>
                            </div>
                        </InfoSectionContainerStyle>

                        <InfoSectionContainerStyle>
                            <h3>Description</h3>
                            <div css={tw`flex px-3 py-2`}>
                                <p css={tw`text-sm`}>{selectedClass.description}</p>
                            </div>
                        </InfoSectionContainerStyle>

                        {error &&
                        <div css={[ tw`w-full mt-4 bg-red-200 p-2 text-red-600 rounded mb-2` ]}>
                            <span css={[ tw`font-medium` ]}>Error:</span> {error}
                        </div>
                        }

                        <div css={tw`flex justify-end`}>
                            <Button onClick={() => {
                                // Ensures against visiting directly to the class details page where we
                                // may not necessarily have any state loaded
                                if (filters.ts) {
                                    history.goBack();
                                } else {
                                    history.push('/search');
                                }

                            }}
                            >{backButtonText}
                            </Button>

                            {/* Removed add button because currently no functionality for handling adding during simulation */}

                            {/* <Button onClick={onAddClass} */}
                            {/*    css={tw`ml-4`} */}
                            {/* >Add Class */}
                            {/* </Button> */}
                        </div>
                    </>
                    : null
            }

        </ClassDetailsContainerStyle>
    );
};
