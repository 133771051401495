import React from 'react';
import { parseClassNumber, generateCourseClassExtract, formatClassSection } from '@/api/transformers';
import { parseSessionCode } from '@/constants/sessionTypes';
import { CourseClassItemStyle } from './styled';
import { InstructionModeName } from '@/constants/instructionModes';
import { Link } from 'react-router-dom';
import { useStoreActions } from '@/store';
import { Button } from '../common';
import { CourseClass, CourseGroup } from '@/api/types';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import ClassTimes from '@/components/shopping-cart/ClassTimes';
import { AddCourseProps } from '@/components/search-results/SearchResultsContainer';

interface Props {
    courseGroup: CourseGroup
    courseClass: CourseClass;
    onAddClass: (props: AddCourseProps) => void;
}

const AddSectionButtonsContainer = styled.div`
    ${tw`flex`}
    
    @media (max-width: 450px) {
        ${tw`flex-wrap`}
        > button {
            margin-bottom: 8px;
        }
    }
`;
const AddSectionButtonStyle = styled(Button).attrs(props => ({
    type: 'button',
    size: 'small',
}))`
    margin-top: 2px;
    padding-top: 1px;
    padding-bottom: 1px;
    
    margin-left: 5px;
    margin-right: 5px;
    
    width: 150px;
    height: 50px;
    
    flex-shrink: 0;
    
    font-size: 13px;
    padding-left: 4px;
    padding-right: 4px;
    
    @media (max-width: 700px) {
        width: 110px;
        font-size: 12px;
        padding-left: 3px;
        padding-right: 3px;
    }
    @media (max-width: 600px) {
        font-size: 12px;
        width: 90px;
    }
    
`;

export default ({ onAddClass, courseGroup, courseClass }: Props) => {
    const setSelectedCourseAndClass = useStoreActions(actions => actions.search.setSelectedCourseClass);

    const renderAddSectionButtons = () => {
        return courseClass.sectionModeOfferings?.map(mode => (
            // generateCourseClassExtract(courseGroup, courseClass), mode
            <AddSectionButtonStyle key={mode} onClick={() => onAddClass({
                courseGroup: courseGroup,
                selectedCourse: {
                    ...courseGroup,
                    selectedSections: [
                        {
                            ...generateCourseClassExtract(courseGroup, courseClass),
                            sectionMode: mode,
                        },
                    ],
                    sectionMode: mode
                },
                skipValidation: false
            })}
            >
                Add {InstructionModeName[mode]} Section
            </AddSectionButtonStyle>
        ));
    };

    return (
        <CourseClassItemStyle>
            <thead>
                <tr>
                    <th><span>Class</span></th>
                    <th><span>Section</span></th>
                    <th><span>Days & Times</span></th>
                    <th><span>Instruction Mode</span></th>
                    {/* <th></th> */}
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>
                        <Link
                            onClick={() => {
                                setSelectedCourseAndClass({ courseGroup, courseClass });
                            }}
                            to={{
                                pathname: `/class/${courseClass.classId}`,
                                state: {
                                    courseGroup,
                                    courseClass
                                }
                            }}
                        >{parseClassNumber(courseGroup, courseClass)}
                        </Link>
                    </td>
                    <td>
                        <Link
                            onClick={() => {
                                setSelectedCourseAndClass({ courseGroup, courseClass });
                            }}
                            to={{
                                pathname: `/class/${courseClass.classId}`,
                                state: {
                                    courseGroup,
                                    courseClass
                                }
                            }}
                        >{formatClassSection(courseClass.classSection)}-{courseClass.courseComponent} {parseSessionCode(courseClass.sessionCode)}
                        </Link>

                    </td>
                    <td>
                        <ClassTimes item={courseClass}/>
                    </td>

                    <td>
                        <AddSectionButtonsContainer>
                            {renderAddSectionButtons()}
                        </AddSectionButtonsContainer>
                    </td>
                </tr>
            </tbody>
        </CourseClassItemStyle>
    );
};
