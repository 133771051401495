import { parseISO, setYear, setMonth, setDate, areIntervalsOverlapping } from 'date-fns';
import { CourseClassMeeting, CourseClass, CourseClassExtract } from '@/api/types';
import _ from 'lodash';

export const resetYearMonthDate = (date: Date): Date => {
    let d = setYear(date, 2020);
    d = setMonth(d, 0);
    d = setDate(d, 1);
    return d;
};

export const validateNoMeetingConflicts = (p1: CourseClassMeeting, p2: CourseClassMeeting): boolean => {
    if (
        (p1.mon === 'Y' && p2.mon === 'Y') ||
        (p1.tue === 'Y' && p2.tue === 'Y') ||
        (p1.wed === 'Y' && p2.wed === 'Y') ||
        (p1.thu === 'Y' && p2.thu === 'Y') ||
        (p1.fri === 'Y' && p2.fri === 'Y') ||
        (p1.sat === 'Y' && p2.sat === 'Y') ||
        (p1.sun === 'Y' && p2.sun === 'Y')
    ) {
        const p1Start = resetYearMonthDate(parseISO(p1.startTime));
        const p1End = resetYearMonthDate(parseISO(p1.endTime));
        const p2Start = resetYearMonthDate(parseISO(p2.startTime));
        const p2End = resetYearMonthDate(parseISO(p2.endTime));
        const overlap = areIntervalsOverlapping(
            {start: p1Start, end: p1End},
            {start: p2Start, end: p2End},
        );
        return !overlap;
    }

    return true;
};
// c1: potential class, c2: existing class
// Returns True if no overlap in times
export const compareClassTimes = (c1: CourseClass | CourseClassExtract, c2: CourseClass | CourseClassExtract): boolean => {
    if (c1.sectionMode === 'OA' || c2.sectionMode === 'OA' || !c1.meetings.length || !c2.meetings.length) return true;

    for (let i = 0; i < c1.meetings.length; i++) {
        const meeting1 = c1.meetings[i];

        for (let j = 0; j < c2.meetings.length; j++) {
            const meeting2 = c2.meetings[j];

            if (validateNoMeetingConflicts(meeting1, meeting2)) {
                continue;
            } else {
                return false;
            }
        }
    }


    return true;
};

export const areClassTimesEqual = (c1: CourseClass | CourseClassExtract, c2: CourseClass | CourseClassExtract): boolean => {

    for (let i = 0; i < c1.meetings.length; i++) {
        const meeting1 = {
            startTime: c1.meetings[i].startTime,
            endTime: c1.meetings[i].endTime,
            mon: c1.meetings[i].mon,
            tue: c1.meetings[i].tue,
            wed: c1.meetings[i].wed,
            thu: c1.meetings[i].thu,
            fri: c1.meetings[i].fri,
            sat: c1.meetings[i].sat,
            sun: c1.meetings[i].sun,
        };

        for (let j = 0; j < c2.meetings.length; j++) {
            const meeting2 = {
                startTime: c2.meetings[i].startTime,
                endTime: c2.meetings[i].endTime,
                mon: c2.meetings[i].mon,
                tue: c2.meetings[i].tue,
                wed: c2.meetings[i].wed,
                thu: c2.meetings[i].thu,
                fri: c2.meetings[i].fri,
                sat: c2.meetings[i].sat,
                sun: c2.meetings[i].sun,
            };

            const isEqual = _.isEqual(meeting1, meeting2);
            if (isEqual) {
                continue;
            } else {
                return false;
            }
        }
    }
    return true;

};
