import { parseMeetingPattern } from '@/api/transformers';
import React, { useMemo } from 'react';
import { CourseClass, CourseClassExtract } from '@/api/types';

interface Props {
    item: CourseClass | CourseClassExtract;
    noneLabel?: string;
}

export default ({ item, noneLabel = 'N/A' }: Props) => {

    // use sectionMode if it exists, since this component
    // is being shared by both actual and simulated course components
    const instructionMode = useMemo<string>(() => {
        return item.sectionMode || item.instructionMode;
    }, [ item ]);

    return (
        <div>
            {
                instructionMode === 'OA' ?
                    (<p>{noneLabel}</p>)
                    :
                    item.meetings.map((meeting: any) => {
                        return (
                            <p key={meeting.meetingNumber}>{parseMeetingPattern(meeting)}</p>
                        );
                    })
            }
        </div>
    );
};
