import React, { useEffect, useState } from 'react';
import { TimestampInputStyle } from '@/components/dashboard/components/filter-bar/styled';
import DatePicker from 'react-datepicker';
import { formatISO, isValid } from 'date-fns';
import tw from 'twin.macro';
import { Option } from '@/api/types';

const DATEPICKER_FORMAT = 'MMM d, yyyy h:mm aa';

interface Props {
    value: string;
    column: Option | null;
    onChange: (dateRange: string) => void;
}
export default ({ onChange, column, value }: Props) => {

    const s = value.split('__');
    const from = new Date(s[0]);
    const to = new Date(s[1]);

    const [ fromDate, setFromDate ] = useState<Date | null>(isValid(from) ? from : null);
    const [ toDate, setToDate ] = useState<Date | null>(isValid(to) ? to : null);

    const onFromDateChange = (value: Date | null) => {
        setFromDate(value);
    };

    const onToDateChange = (value: Date | null) => {
        setToDate(value);
    };

    useEffect(() => {
        let s = [
            fromDate ? formatISO(fromDate) : '',
            toDate ? formatISO(toDate) : '',
        ].join('__');
        if (s === '__') {
            s = '';
        }
        onChange(s);

    }, [ fromDate, toDate ]);

    useEffect(() => {
        setFromDate(null);
        setToDate(null);
    }, [ column ]);

    return (
        <TimestampInputStyle>
            <div>
                <label css={tw`mr-2`}>From</label>

                <DatePicker
                    selected={fromDate}
                    onChange={onFromDateChange}
                    showTimeSelect
                    isClearable
                    dateFormat={DATEPICKER_FORMAT}
                />
            </div>

            <div css={tw`ml-4`}>
                <label css={tw`mr-2`}>To</label>
                <DatePicker
                    selected={toDate}
                    onChange={onToDateChange}
                    showTimeSelect
                    isClearable
                    dateFormat={DATEPICKER_FORMAT}
                />
            </div>


        </TimestampInputStyle>
    );
};
