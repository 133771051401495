import React, { useEffect, useMemo, useState } from 'react';
import { useGetSimResults } from '@/api/simulation/SimulationAPI';
import { QuestionParams } from '@/constants/questions';
import { SelectedCourse, SelectedCourseExtract } from '@/api/types';
import {
    hashSelectedCourse,
    parseClassSection, parseMeetingPattern,
} from '@/api/transformers';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { getCurrentTree } from '@/store/simulation/simulationUtils';
import { NoReplacement, StageData } from '@/store/simulation/types';
import _ from 'lodash';
import { InstructionModeSurveyFormat } from '@/constants/instructionModes';

interface Props {
    params: QuestionParams
}

const DiffContainerStyle = styled.div`
    display: flex;
`;
const ScheduleContainerStyle = styled.div`
width: 100%;
    border: 1px solid #ccc;
    ${tw`px-2 py-2`}
    h4 {
        ${tw`text-center`}
        font-weight: 500
        
    }
`;

export default ({ params }: Props) => {

    const { shoppingCart, data: simulationData } = useGetSimResults();

    const [ oldCart, setOldCart ] = useState<SelectedCourseExtract[] | null>(null);

    useEffect(() => {
        setOldCart(null);

        const currentTree = getCurrentTree(simulationData);
        const previousStage: StageData | undefined = currentTree?.completedStages ? _.last(currentTree?.completedStages) : undefined;
        if (previousStage) {

            const selectedCourseExtracts: SelectedCourseExtract[] = previousStage.resolvedCart!;
            setOldCart(selectedCourseExtracts);

        // if we're in the first stage of a tree then we know the Old Cart is the Originally Submitted Cart
        } else {
            setOldCart(simulationData.originalSubmittedCart);
        }

    }, [ simulationData ]);

    const untouchedClasses = useMemo<SelectedCourse[]>(() => {
        return shoppingCart.filter(c => {
            return hashSelectedCourse(c) !== hashSelectedCourse(params.replacementClass);
        });

    }, [ params, shoppingCart ]);

    // const renderClassSections = (sections: CourseClassExtract[], highlight = false) => {
    //     if (sections.length) {
    //         const cssProp = highlight ? tw`font-medium` : '';
    //         return (
    //             <>
    //                 {
    //                     sections.map(c => (
    //                         <p css={cssProp} key={c.classId}>{parseClassSchedule(c)}</p>
    //                     ))
    //                 }
    //             </>
    //         );
    //     }
    //     return null;
    // };

    const renderSelectedCourseExtract = (selectedCourse: SelectedCourseExtract | NoReplacement, highlight = false) => {
        const cssProp = highlight ? tw`font-medium` : '';

        if (selectedCourse === 'NoReplacement' || selectedCourse.courseId === 'NoReplacement') return <p css={cssProp}>No Replacement</p>;

        if (selectedCourse.selectedSections.length) {
            return (
                <div key={selectedCourse.courseId}>
                    {
                        selectedCourse.selectedSections.map(section => {
                            const sectionStr = parseClassSection({
                                classId: section.classId,
                                classSection: section.classSection,
                                courseComponent: section.courseComponent,
                                courseNumber: selectedCourse.courseNumber,
                                sectionMode: selectedCourse.sectionMode,
                                subject: selectedCourse.subject,
                            });
                            const mode = InstructionModeSurveyFormat[selectedCourse.sectionMode as any];
                            const meetingTimes = selectedCourse.sectionMode === 'OA' ? '' : section.meetings.map((m: any) => parseMeetingPattern(m)).join(' ');

                            const str = [ sectionStr, mode, meetingTimes ].filter(o => o).join(', ');

                            return (
                                <p css={cssProp} key={section.classId}>{str}</p>
                            );
                        })
                    }
                </div>
            );
        }
        return null;
    };

    const oldScheduleItems = useMemo(() => {
        const highlight = _.differenceBy(oldCart, shoppingCart, hashSelectedCourse);
        const remainder = _.intersectionBy(oldCart, shoppingCart, hashSelectedCourse);
        return {
            highlight,
            remainder,
        };
    }, [ oldCart, shoppingCart ]);

    console.log('params.replacementClass: ', params.replacementClass);

    const isNoReplacement = (selectedCourse: SelectedCourseExtract | NoReplacement): boolean => {
        return (selectedCourse === 'NoReplacement' || selectedCourse.courseId === 'NoReplacement');
    };

    return (
        <div>
            <p>You just chose to make the following {isNoReplacement(params.replacementClass) ? 'change' : 'switch'} in your schedule:</p>
            <DiffContainerStyle>
                <ScheduleContainerStyle>
                    <h4>Old Schedule</h4>
                    {oldScheduleItems.highlight.map(o => renderSelectedCourseExtract(o, true))}
                    <hr/>
                    {oldScheduleItems.remainder.map(o => renderSelectedCourseExtract(o, false))}

                </ScheduleContainerStyle>

                <ScheduleContainerStyle>
                    <h4>New Schedule</h4>
                    {renderSelectedCourseExtract(params.replacementClass, true)}
                    <hr/>
                    {untouchedClasses.map(o => renderSelectedCourseExtract(o))}
                </ScheduleContainerStyle>
            </DiffContainerStyle>
        </div>
    );
};
