import React, { useState } from 'react';
import { useStoreActions, useStoreState } from '@/store';
import QuickSearch from '@/components/replacements/QuickSearch';
import { EventResponseEnum } from '@/constants/simulationConditions';
import { generateEmptyClassSearchForm, parseCourseClassShortNameWithMode, parseCourseShortName, } from '@/api/transformers';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import { useHistory } from 'react-router';
import { useGetSimResults } from '@/api/simulation/SimulationAPI';
import useSimulationHooks from '@/components/simulation/useSimulationHooks';
import { Spinner } from '../common';
import useCurrentlyReplacing from '@/components/replacements/useCurrentlyReplacing';
import { useReactiveVar } from '@apollo/client';
import { secondChanceOfferVar } from '@/api/graph';

export default () => {
    const history = useHistory();
    const [ loading, setLoading ] = useState<boolean>(false);
    const filters = useStoreState(state => state.search.filters);
    const setFilters = useStoreActions(actions => actions.search.setFilters);

    const { data: simulationData } = useGetSimResults();

    const { continueWithoutReplacing } = useSimulationHooks();
    const secondChanceOffer = useReactiveVar(secondChanceOfferVar);

    const currentlyReplacing = useCurrentlyReplacing();

    const handleContinueWithoutReplacing = () => {

        if (secondChanceOffer) {
            secondChanceOfferVar(null);
            history.push('/sim');
        } else {
            setLoading(true);
            continueWithoutReplacing()
                .catch(err => console.error(err))
                .then(() => {
                    setLoading(false);
                    history.push('/sim');
                });
        }
    };

    if (!currentlyReplacing) return null;
    return (
        <div>
            <h4 css={tw`font-medium`}>Which course would you like to choose to replace {parseCourseShortName(currentlyReplacing)}?</h4>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />
            <Link
                onClick={() => {
                    setFilters({
                        ...generateEmptyClassSearchForm(),
                        ...filters,
                    });
                }}
                to={'/replacement-search'}
            >Back to full search
            </Link>

            {
                // only show QuickSearch if on t1r1 and response was DifferentCourseAsynchronous
                simulationData?.currentStage?.id === 't1r1' && simulationData?.currentStage?.initialResponse?.response === EventResponseEnum.DifferentCourseAsynchronous
                    ? <QuickSearch institution={simulationData.currentStage.unavailableClass.institution}/> : null
            }

            <div css={tw`flex justify-end`}>
                <a style={{color: 'rgb(29,58,131)'}} css={tw`font-medium text-lg cursor-pointer underline hover:opacity-75`} onClick={() => handleContinueWithoutReplacing()}>I want to drop this course instead of replacing it.</a>
            </div>

        </div>
    );
};
