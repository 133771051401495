import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { parseClassSection, parseCourseShortName } from '@/api/transformers';
import { Button } from '@/components/common';
import { SelectedCourse } from '@/api/types';
import { ConflictingClassContainerStyle } from '@/components/search-results/add-class-error/styled';
import ClassTimes from '@/components/shopping-cart/ClassTimes';

interface Props {
    conflictingClasses: [SelectedCourse, SelectedCourse];
    onCancel: () => void;
    onReplace: (c1: SelectedCourse, c2: SelectedCourse) => void;
}

export default ({ conflictingClasses, onCancel, onReplace }: Props) => {


    const [ errorMsg, setErrorMsg ] = useState<string>('');
    useEffect(() => {
        if (conflictingClasses?.length === 2) {

            if (conflictingClasses[0].courseId === conflictingClasses[1].courseId) {
                setErrorMsg('There is already another class section for this course. Which of these two classes would you like to keep?');
            } else {
                setErrorMsg(`The meeting times for ${parseCourseShortName(conflictingClasses[0])} conflict with the meeting times for ${parseCourseShortName(conflictingClasses[1])}. Which of these two classes would you like to keep?`);
            }
        }

    }, [ conflictingClasses ]);


    return (
        <>
            <p css={tw`text-red-500 whitespace-pre-line`} dangerouslySetInnerHTML={{__html: errorMsg}}/>
            <div css={tw`flex justify-center mt-3`}>
                <ConflictingClassContainerStyle>
                    <p>{parseCourseShortName(conflictingClasses[0])}</p>
                    <div>
                        {
                            conflictingClasses[0].selectedSections.map(section => {
                                return (
                                    <div key={section.classId}>
                                        <p>{parseClassSection(section)}</p>
                                        <ClassTimes item={section} noneLabel={''}/>
                                    </div>
                                );
                            })
                        }
                    </div>

                    <div>
                        {/* accept the new replacement, so then we need to show the second chance offer. don't
                             fire onReplaceSuccess yet */}
                        <Button onClick={() => {
                            onReplace(conflictingClasses![0], conflictingClasses![1]);
                        }}
                        >Select
                        </Button>
                    </div>

                </ConflictingClassContainerStyle>

                <ConflictingClassContainerStyle>
                    <p>{parseCourseShortName(conflictingClasses[1])}</p>
                    <div>
                        {
                            conflictingClasses[1].selectedSections.map(section => {
                                return (
                                    <div key={section.classId}>
                                        <p>{parseClassSection(section)}</p>
                                        <ClassTimes item={section} noneLabel={''}/>
                                    </div>
                                );
                            })
                        }
                    </div>

                    <div>
                        {/* keep the original class so just close and do nothing. */}
                        <Button onClick={onCancel}>Select</Button>
                    </div>

                </ConflictingClassContainerStyle>
            </div>
        </>
    );
};
