import React from 'react';
import { Field } from 'formik';
import { InputContainerStyle } from './styled';
import TooltipIcon from '@/components/common/TooltipIcon';

interface Props {
    label: string;
    fieldName: string;
    tooltip?: string;
}

export default ({ label, fieldName, tooltip }: Props) => {

    return (
        <InputContainerStyle>
            <label>
                {label}
                {
                    tooltip ?
                        // <InputTooltipStyle/>
                        <TooltipIcon message={tooltip}/>
                        : null
                }
            </label>

            <Field name={fieldName} as="input"/>

        </InputContainerStyle>
    );
};
