import _ from 'lodash';
import { gql } from '@apollo/client';
import * as fragments from '@/api/graphql/fragments';
import { ClassSearchCriteriaValues } from '@/components/class-search/ClassSearchForm';
import {
    convertDaysCriteriaToFilter,
    convertTimesCriteriaToFilter,
    convertFuzzyTextCriteriaToFilter,
    convertNumberCriteriaToFilter,
    convertReqDesignationCriteriaToFilter,
    convertExactStringCriteriaToFilter
} from '@/api/transformers';
import { WithoutBetweenCriteria } from '@/components/class-search/SearchCriteria';
import { defaultTerm } from '@/constants/terms';
import { getUndergradCareerCode } from '@/constants/institutions';

// for ordering of catalog numbers: https://stackoverflow.com/a/32391788

export default gql`
query searchClasses(
    $filter: custom_crse_offer_view_bool_exp,
    $classFilter: custom_class_tbl_bool_exp,
    $classMeetingsFilter: custom_class_mtg_pat_bool_exp,
    $classAttributesFilter: ps_class_attribute_bool_exp,
    $offset: Int
) {
    items_aggregate:custom_crse_offer_view_aggregate(
        where: $filter,
    ) {
        aggregate {
            totalCount: count
        }
    }
    items: custom_crse_offer_view(
        where:$filter,
        distinct_on: [subject, catalog_nbr_int, catalog_nbr, crse_id],
        limit: 40,
        offset: $offset,
        order_by: [{subject: asc}, {catalog_nbr_int: asc}, {catalog_nbr: asc}, {crse_id:asc}, {effdt:desc}, {crse_offer_nbr:desc}]
    ) {
        courseId: crse_id
        subject
        courseNumber: catalog_nbr

        institution
        
        courseTitle: course_title_long
        units: units_acad_prog

        classes (
            where: $classFilter
            order_by: [{instruction_mode: asc, custom_class_section: asc_nulls_first}]
        ) {
            attributes (
                where: $classAttributesFilter
            ) {
                courseAttribute: crse_attr
                courseAttributeValue: crse_attr_value
            }
            meetings (
                where: $classMeetingsFilter
            ) {
                ...meetingPatterns
            }

            classId: class_nbr
            customClassSection: custom_class_section
            classSection: class_section
            startDate: start_dt
            endDate: end_dt
            instructionMode: instruction_mode,

            sessionCode: session_code
            modifiers: modifiers
            
            courseComponent: ssr_component
            
            classType: class_type
            autoEnrollSect1: auto_enroll_sect_1
            autoEnrollSect2: auto_enroll_sect_2
            associatedClass: associated_class
        }
    }
}
${fragments.meetingPatterns}
`;

// For nested ARRAYS, the where clauses need to be present in BOTH the top level filter, AND down in their property level
// The nested where clause is to filter the results in the array, whereas the top level where clause is to ensure
// we only return top level objects that have an array exactly matching the criteria.
// This means every nested filter has to recursively included up thru each parent filter

// Not required for nested single object relationship since there is only a single record

/*
    Instruction Mode override:

    Basically we will return all filtered 'P', and all 'OA'. Then front end will filter out results based on whether
    student selected a specific instruction mode.
*/

export const convertSearchCriteriaToFilter = (criteria: ClassSearchCriteriaValues) => {
    const daysFilter = convertDaysCriteriaToFilter(criteria.daysOfWeek, criteria.daysOfWeekCriteria);

    const classMeetingsFilter = {
        _and: [
            // hmm if meeting_time_start is null then display as 'TBA'
            convertTimesCriteriaToFilter('meeting_time_start', criteria.startTime, criteria.startTimeCriteria),
            convertTimesCriteriaToFilter('meeting_time_end', criteria.endTime, criteria.endTimeCriteria),
            daysFilter
        ]
    };
    _.remove(classMeetingsFilter._and, _.isEmpty);

    const classAttributesFilter = {
        _and: [
            {
                crse_attr: {
                    _eq: criteria.courseAttribute
                }
            },
            {
                crse_attr_value: {
                    _eq: criteria.courseAttributeValue || null
                }
            }
        ]
    };

    const classFilter = {
        _and: [
            {
                strm: {
                    // _eq: criteria.termId || null
                    _eq: defaultTerm.id
                }
            },

            {
                session_code: {
                    // _eq: criteria.sessionType || null
                    _eq: '1'
                }
            },
            // {
            //     instruction_mode: {
            //         _eq: criteria.instructionMode === 'OA' ? 'OA' : 'P'
            //     }
            // },
            {
                instruction_mode: criteria.instructionMode === 'OA'
                    ? {_eq: 'OA'}
                    : {}
            },
            {
                class_nbr: {
                    _eq: criteria.classNumber || null
                }
            },
            {meetings: classMeetingsFilter},

            {attributes: classAttributesFilter}
        ] as any[]
    };

    if (!criteria.classNumber) {
        _.remove(classFilter._and, o => {
            return o.class_nbr;
        });
    }

    // Okay for this one we DON'T pass attributes filter up to the class level. This is because
    // not all classes have attributes and so by passing an empty object to class,
    // GraphQL will only return classes that have AT LEAST 1 attribute. Instead here it is okay
    // to exclude the attributes filter on the class level if it is not present in search criteria.
    if (!criteria.courseAttribute) {
        // IMPORTANT: remember to be in sync with the correct index
        // rather than pop or splice, just use lodash's remove() to be safe
        _.remove(classFilter._and, o => {
            return o.attributes;
        });

        _.remove(classAttributesFilter._and, o => {
            return o.crse_attr || o.crse_attr_value;
        });
    }

    const classFilterWrapper = {
        _or: [
            {
                instruction_mode: {
                    _eq: 'OA'
                }
            },
            classFilter
        ] as any[]
    };

    if (criteria.instructionMode === 'P' || criteria.instructionMode === 'OS' || criteria.instructionMode === 'S') {
        classFilterWrapper._or.shift();
    }

    // internal filter usage
    const courseIdFilter = criteria._courseId
        ? {
            crse_id: {
                _neq: criteria._courseId
            }
        } : {};

    const undergradOnly = {
        acad_career: {
            _eq: getUndergradCareerCode(criteria.institution)
        }
    };

    const catalogNumberFilter = criteria.courseNumber
        ? convertNumberCriteriaToFilter('catalog_nbr', criteria.courseNumber, (criteria.courseNumberCriteria as WithoutBetweenCriteria))
        : {};

    const courseOfferFilter = {
        _and: [
            courseIdFilter,
            convertExactStringCriteriaToFilter('institution', criteria.institution),
            convertExactStringCriteriaToFilter('subject', criteria.subjectId),
            undergradOnly,
            catalogNumberFilter,
            convertNumberCriteriaToFilter('units_minimum', criteria.minUnits, (criteria.minUnitsCriteria as WithoutBetweenCriteria)),
            convertNumberCriteriaToFilter('units_maximum', criteria.maxUnits, (criteria.maxUnitsCriteria as WithoutBetweenCriteria)),
            convertFuzzyTextCriteriaToFilter('descrlong', criteria.courseKeyword),

            convertReqDesignationCriteriaToFilter(criteria.requirementDesignation),

            convertExactStringCriteriaToFilter('ssr_component', criteria.courseComponent),
        ]
    };

    _.remove(courseOfferFilter._and, _.isEmpty);

    const filter = {
        ...courseOfferFilter,
        classes: {
            ...classFilterWrapper
        }
    };

    return {
        filter,
        classFilter: classFilterWrapper,
        classMeetingsFilter,
        classAttributesFilter,
    };

    // dynamic operators
    /*
-   course number
-   units_minimum
-   units_maximum
    */


    /* missing / to-do
-   beginning time
-   ending time
-   days of week

-   course keywords (figure out which column or table to search exactly. course description, name, etc.)
- campus (just skip for now)

    */
};



