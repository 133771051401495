import { Form } from 'formik';
import { Accordion } from 'react-accessible-accordion';
import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const ClassSearchContainerStyle = styled.div`
    ${tw`border border-solid border-gray-200`}

    width: 100%;
    @media (min-width: 650px) {
        width: 630px;
    }
    
`;

export const ClassSearchFormStyle = styled(Form)`
    ${tw`py-4 px-1`}
    @media (min-width: 650px) {
        ${tw`px-4`}
    }
    
`;

interface InputContainerStyleProps {
    labelColor?: string;
    labelFontWeight?: number;
}
export const InputContainerStyle = styled.div<InputContainerStyleProps>`
    ${tw`mb-3 flex justify-start items-start`}

    label {
        ${tw`text-base`}
        width: 160px;
        flex-shrink: 0;
        color: ${props => props.labelColor ? props.labelColor : 'rgb(40, 66, 119)'};
        font-weight: ${props => props.labelFontWeight ? props.labelFontWeight : '500'};
    }

    select {
        width: 100%;
        flex-shrink: 1;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    @media (min-width: 650px) {
        select {
            width: 220px;
            flex-shrink: 0;
        }   
    }

    select, input {
        padding: 3px 1px;
    }

    .trailingInput {
        width: 75px;
        flex-shrink: 0;
        margin-left: 10px;
    }
    
    &.hardcoded {
        select, input {
            ${tw`appearance-none outline-none pointer-events-none select-none border-transparent`}
        }
        
    }

    @media (max-width: 649px) {
        ${tw`flex-wrap`}
        label {
            width: 100%;
        }
        // ${tw`justify-end`}
    }
`;

export const InputWithCriteriaContainerStyle = styled(InputContainerStyle)`
    ${tw`flex-wrap`}

    @media (max-width: 649px) {
        label {
            width: 100%;
        }
        // ${tw`justify-end`}
    }
    
    select.criteria-select {
        flex-shrink: 1;
        width: auto;
    }
`;

export const InputTooltipStyle = styled.div`
    ${tw`cursor-pointer`}
    background-color: blue;
    width: 10px;
    height: 10px;
`;

export const AccordionStyle = styled(Accordion)`
    .accordion__item {
        // ${tw`my-2 border border-gray-300 border-solid`}
    }
    .accordion__heading {
        .accordion__button {
            ${tw`flex items-center cursor-pointer py-3 px-2 bg-gray-100`}
            svg {
                ${tw`mr-4 text-lg`}
            }
            h3 {
                ${tw`text-base`}
                color: rgb(40,66,119);
                font-weight: 500;
            }
        }
    }
    .accordion__panel {
        ${tw`py-4`}

        @media (min-width: 650px) {
            ${tw`py-4 px-4`}
        }
    }
`;

interface FormButtonStyleProps {
    backgroundColor?: string;
    color?: string;
}
export const FormButtonStyle = styled.button<FormButtonStyleProps>`
    ${tw`cursor-pointer mx-2 px-4 py-2 shadow hover:opacity-75 text-base`}
    border: 1px solid #ccc;
    // border: none;
    border-radius: 6px;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : 'initial'};
    color: ${props => props.color ? props.color : 'initial'};
`;
