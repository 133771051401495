import { action, Action } from 'easy-peasy';
import { ClassSearchCriteriaValues } from '@/components/class-search/ClassSearchForm';
import { generateEmptyClassSearchForm } from '@/api/transformers';
import {
    CourseAndClass, CourseInfo,
    InstitutionType,
    Option, SelectedCourse,
    SetReplacementFiltersArgs
} from '@/api/types';
import { CriteriaType } from '@/components/class-search/SearchCriteria';
import { EventResponseEnum } from '@/constants/simulationConditions';
import { formatISO } from 'date-fns';

export interface SearchStore {
    filters: ClassSearchCriteriaValues;

    // cache most recent subjects search, so that when we get to simulation we can quickly load to QuickSearch.tsx
    subjects: Option[];
    setSubjects: Action<SearchStore, Option[]>;

    // blockedCourses: string[];

    selectedCourseClass: CourseAndClass | null;

    setReplacementFilters: Action<SearchStore, SetReplacementFiltersArgs>;
    setReplacementFiltersForConflictingSchedule: Action<SearchStore, SelectedCourse>;

    setFilters: Action<SearchStore, ClassSearchCriteriaValues | null>;
    setSelectedCourseClass: Action<SearchStore, CourseAndClass | null>;

    clearAllFiltersExceptInstitution: Action<SearchStore>;

    // setBlockedCourses: Action<SearchStore, string[]>;
    clear: Action<SearchStore, any>;

    acknowledgedCourseRequisitesMap: {[key: string]: boolean};
    setAcknowledgedCourseRequisitesMap: Action<SearchStore, {[key: string]: boolean}>;

}

const initialState = {
    filters: generateEmptyClassSearchForm(),
    // blockedCourses: [],
    selectedCourseClass: null,
    subjects: [],
    acknowledgedCourseRequisitesMap: {},
};

const search: SearchStore = {
    ...initialState,

    setSubjects: action((state, payload) => {
        state.subjects = payload;
    }),

    setSelectedCourseClass: action((state, payload) => {
        state.selectedCourseClass = payload;
    }),

    setFilters: action((state, payload) => {
        if (!payload) {
            state.filters = generateEmptyClassSearchForm();
        } else {
            state.filters = {
                ...payload,
                // internal parameter only used by simulation via setReplacementFilters in order to exclude unavailable courses
                _courseId: '',
                ts: formatISO(new Date())
            };
        }
    }),

    setReplacementFilters: action((state, { response, removedClass, extraFilterProps = {}, originalCourse }: SetReplacementFiltersArgs) => {
        const filters = generateEmptyClassSearchForm();
        if (state.filters.requirementDesignation) {
            filters.requirementDesignation = state.filters.requirementDesignation;
        }
        if (state.filters.subjectId) {
            filters.subjectId = state.filters.subjectId;
        }

        filters.institution = removedClass.institution as InstitutionType;

        switch (response) {
            case EventResponseEnum.DifferentCourseAsynchronous:
                filters._courseId = removedClass.courseId;
                filters._courseIdCriteria = CriteriaType.NotEqualTo;
                filters.instructionMode = 'OA';
                break;
            case EventResponseEnum.SameCourseAsynchronous:
                filters.subjectId = removedClass.subject;
                filters.courseNumber = removedClass.courseNumber;
                filters.courseNumberCriteria = CriteriaType.Contains;
                filters.instructionMode = 'OA';
                break;
            case EventResponseEnum.SameCourseSynchronous:
                filters.subjectId = removedClass.subject;
                filters.courseNumber = removedClass.courseNumber;
                filters.courseNumberCriteria = CriteriaType.Contains;
                filters.instructionMode = 'S';
                break;

            case EventResponseEnum.DifferentCourseOnCampus:
                filters._courseId = removedClass.courseId;
                filters._courseIdCriteria = CriteriaType.NotEqualTo;
                filters.instructionMode = 'P';
                break;

            case EventResponseEnum.DifferentCourseEntirely:
                filters._courseId = removedClass.courseId;
                filters._courseIdCriteria = CriteriaType.NotEqualTo;
                filters.instructionMode = '';
                break;

            case EventResponseEnum.DifferentCourseOnlineSynchronous:
                filters._courseId = removedClass.courseId;
                filters._courseIdCriteria = CriteriaType.NotEqualTo;
                filters.instructionMode = 'OS';
                break;

            case EventResponseEnum.SameCourseOnCampus:
                filters.subjectId = removedClass.subject;
                filters.courseNumber = removedClass.courseNumber;
                filters.courseNumberCriteria = CriteriaType.Contains;
                filters.instructionMode = 'P';
                break;
            case EventResponseEnum.SameCourseOnlineSynchronous:
                filters.subjectId = removedClass.subject;
                filters.courseNumber = removedClass.courseNumber;
                filters.courseNumberCriteria = CriteriaType.Contains;
                filters.instructionMode = 'OS';
                break;

            case EventResponseEnum.DifferentCourseSynchronous:
                filters._courseId = removedClass.courseId;
                filters._courseIdCriteria = CriteriaType.NotEqualTo;
                filters.instructionMode = 'S';
                break;

            case EventResponseEnum.OriginalCourseAsynchronous:
                filters.subjectId = originalCourse.subject;
                filters.courseNumber = originalCourse.courseNumber;
                filters.courseNumberCriteria = CriteriaType.Contains;
                filters.instructionMode = 'OA';
                break;
            case EventResponseEnum.OriginalCourseOnCampus:
                filters.subjectId = originalCourse.subject;
                filters.courseNumber = originalCourse.courseNumber;
                filters.courseNumberCriteria = CriteriaType.Contains;
                filters.instructionMode = 'P';
                break;
            case EventResponseEnum.OriginalCourseOnlineSynchronous:
                filters.subjectId = originalCourse.subject;
                filters.courseNumber = originalCourse.courseNumber;
                filters.courseNumberCriteria = CriteriaType.Contains;
                filters.instructionMode = 'OS';
                break;

            case EventResponseEnum.OriginalCourseSynchronous:
                filters.subjectId = originalCourse.subject;
                filters.courseNumber = originalCourse.courseNumber;
                filters.courseNumberCriteria = CriteriaType.Contains;
                filters.instructionMode = 'S';
                break;

            default:
                throw new Error('unhandled response for setReplacementFiltersResponse');
        }

        state.filters = { ...filters, ...extraFilterProps };
    }),

    setReplacementFiltersForConflictingSchedule: action((state, payload: SelectedCourse) => {
        const filters = generateEmptyClassSearchForm();
        filters.institution = payload.institution as InstitutionType;

        if (state.filters.requirementDesignation) {
            filters.requirementDesignation = state.filters.requirementDesignation;
        }

        filters.subjectId = payload.subject;
        filters.courseNumber = payload.courseNumber;
        filters.courseNumberCriteria = CriteriaType.Contains;

        state.filters = { ...filters };
    }),

    clearAllFiltersExceptInstitution: action((state) => {
        state.filters = {
            ...generateEmptyClassSearchForm(),
            institution: state.filters.institution
        };
    }),

    // setBlockedCourses: action((state, payload) => {
    //     state.blockedCourses = payload;
    // }),
    clear: action((state, payload) => {
        state = {
            ...initialState
        };
    }),

    setAcknowledgedCourseRequisitesMap: action((state, payload) => {
        state.acknowledgedCourseRequisitesMap = {
            ...payload
        };
    }),

};

export default search;
