import React from 'react';
import { AcademicPlanInfo } from '@/api/types';
import tw from 'twin.macro';
import { TableStyle } from '@/components/enrollment-history/styled';

interface Props {
    plans: AcademicPlanInfo[];
    showFormattedColumns?: boolean;
}

export default ({ plans, showFormattedColumns = false }: Props) => {
    if (!plans.length) {
        return <p css={tw`ml-4`}>None</p>;
    }

    return (
        <TableStyle>
            <thead>
                <tr>
                    <th>Effective Date</th>
                    <th>Plan</th>
                    {
                        showFormattedColumns ?
                            <th><span>Formatted</span></th>
                            : null
                    }
                    <th>Declared Date</th>
                    <th>Req Term</th>
                </tr>
            </thead>
            <tbody>
                {
                    plans.map((plan, i) => (
                        <tr key={i}>
                            <td>{plan.effdt}</td>
                            <td css={tw`whitespace-no-wrap`}>{plan.academicPlan}</td>
                            {
                                showFormattedColumns ?
                                    <td className={'truncate'}>{plan.plan?.transcriptDescription}</td>
                                    : null
                            }
                            <td>{plan.declaredDate}</td>
                            <td>{plan.reqTerm}</td>
                        </tr>
                    ))
                }

            </tbody>
        </TableStyle>
    );
};
