import React from 'react';
import tw from 'twin.macro';
import styled, { CSSObject } from 'styled-components/macro';

const LegendIconStyle = styled.span<LegendIconStyleProps>`
    display: inline-block;
    background-color: ${props => props.color};
    position: absolute;
    top: 50%;
    left: -5px;
    transform: translate(-120%, -50%);

    ${props => props.shape === 'circle' ?
        `
            width: 10px;
            height: 10px;
            border-radius: 100%;
            `
        :
        `
            width: 20px;
            height: 10px;
            `
}
`;

interface LegendIconStyleProps {
    shape?: 'circle' | 'rect';
    color: string;
}

interface LegendLabelProps extends LegendIconStyleProps {
    label: string;
    fontStyle?: CSSObject;
}

const LegendLabel = ({ shape = 'rect', color, label, fontStyle = {} }: LegendLabelProps) => {
    return (
        <p css={tw`relative font-normal text-lg text-gray-900`} style={{ ...fontStyle }}>
            <LegendIconStyle shape={shape} color={color}/>
            {label}
        </p>
    );
};

export default LegendLabel;
