import { Action, action } from 'easy-peasy';
import { AccountRole } from '@/api/types';

export interface UserStore {
    role?: AccountRole | null;
    token?: string | null;
    setToken: Action<UserStore, string | null | undefined>;
    setRole: Action<UserStore, AccountRole | null | undefined>;
}

const user: UserStore = {
    token: undefined,
    role: undefined,
    setToken: action((state, payload) => {
        state.token = payload;
    }),
    setRole: action((state, payload) => {
        state.role = payload;
    }),
};

export default user;
