import React, { useCallback, useState } from 'react';
import tw from 'twin.macro';
import ShoppingCartWidget from '../shopping-cart/ShoppingCartWidget';
import ClassSearchForm, { ClassSearchCriteriaValues } from '@/components/class-search/ClassSearchForm';
import { SimulationStats, useGetSimResults, useUpdateSimResults } from '@/api/simulation/SimulationAPI';
import { useHistory } from 'react-router-dom';
import useSimulationHooks from '@/components/simulation/useSimulationHooks';
import Instructions from '@/components/class-search/Instructions';
import useCurrentlyReplacing from '@/components/replacements/useCurrentlyReplacing';
import { formatSelectedCourseAndSectionsWithInstitution } from '@/api/transformers';
import SimulationDebugger from '@/components/simulation/SimulationDebugger';
import { formatISO } from 'date-fns';
import { Spinner } from '../common/Spinner';

export default () => {
    const history = useHistory();

    const [ loading, setLoading ] = useState<boolean>(false);
    const { shoppingCart, stats} = useGetSimResults();
    const updateSimResults = useUpdateSimResults();
    const currentlyReplacing = useCurrentlyReplacing();
    const { isSimulationInProgress } = useSimulationHooks();

    const onSubmit = useCallback((filters: ClassSearchCriteriaValues) => {
        setLoading(true);
        const statsProps: SimulationStats = {
            ...stats
        };
        const ts = formatISO(new Date());

        const cleanedFilters = {
            subjectId: filters.subjectId,
            institution: filters.institution
        };

        if (!stats.firstSearch) {
            statsProps.firstSearch = {
                filters: cleanedFilters,
                ts
            };
        }
        statsProps.lastSearch = {
            filters: cleanedFilters,
            ts
        };

        updateSimResults({
            stats: {
                ...stats,
                ...statsProps,
            }
        })
            .then(() => {
                setLoading(false);
                history.push(isSimulationInProgress ? '/replacement-results' : '/results');

            })
            .catch(err => {
                console.error('update stats err: ', err);
                setLoading(false);
            });

    }, [ isSimulationInProgress, stats ]);


    return (
        <div css={tw`max-w-screen-xl`}>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />

            {/* Only show instructions during pre-simulation. */}
            { isSimulationInProgress ? null : <Instructions/>}

            { currentlyReplacing ?
                <h3 css={tw`font-medium mb-3`}>Which course would you like to choose to replace {formatSelectedCourseAndSectionsWithInstitution(currentlyReplacing)}?</h3>
                : null
            }

            <div css={tw`flex flex-col-reverse md:flex-row md:items-start`}>
                <ClassSearchForm onSubmit={onSubmit}/>

                <div css={tw`mb-3`}>
                    <ShoppingCartWidget items={shoppingCart}/>
                </div>
            </div>

            { isSimulationInProgress ? <SimulationDebugger/> : null}
        </div>
    );
};
