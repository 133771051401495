import React, { useEffect, useState } from 'react';
import { termNameYearLowercase } from '@/constants/terms';
import RadioQuestion from '@/components/survey/RadioQuestion';
import { AgreeChoices, WorkInput, YesNoChoices } from '@/components/survey/surveyQuestions';
import tw from 'twin.macro';
import { Button, Spinner } from '@/components/common';
import { useHistory } from 'react-router-dom';
import { formatISO } from 'date-fns';
import { useGetSurveyAnswers, useUpdateSurvey } from '@/api/simulation/SimulationAPI';
import AgreeStatementsTable from '@/components/survey/AgreeStatementsTable';
import AgreeStatementRow from '@/components/survey/AgreeStatementRow';

const q13 = {
    id: 'q13',
    label: `Do you have a paid job during the ${termNameYearLowercase} term (or do you expect to have one)?`,
    choices: YesNoChoices,
};
const q14 = [
    {
        id: 'q14a',
        showIf: 'q13__Y',
        label: 'I have to work in order to pay for food, lodging, education and/or health care for me or my family.',
        choices: AgreeChoices
    },
    {
        id: 'q14b',
        showIf: 'q13__Y',
        label: 'I would work less and spend more time on my studies, if I could afford to financially.',
        choices: AgreeChoices
    },
    {
        id: 'q14c',
        showIf: 'q13__Y',
        label: 'I would take more classes if I could afford to work less.',
        choices: AgreeChoices
    }
];

export default () => {
    const history = useHistory();

    const survey = useGetSurveyAnswers();
    const updateSurvey = useUpdateSurvey();

    const [ loading, setLoading ] = useState<boolean>(false);
    const [ workInput, setWorkInput ] = useState<WorkInput>(survey.work);

    useEffect(() => {
        setWorkInput(survey.work);
    }, [ survey ]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWorkInput({
            ...workInput,
            [event.target.name]: event.target.value
        });
    };

    const onSubmit = (e: React.FormEvent) => {
        setLoading(true);
        e.preventDefault();
        e.stopPropagation();

        const finalInput: WorkInput = {
            ...workInput,
            submittedTs: formatISO(new Date())
        };
        // if answering no job then clear all job related answers
        if (finalInput.q13 === 'N') {
            finalInput.q14a = finalInput.q14b = finalInput.q14c = '';
        }

        updateSurvey({work: finalInput})
            .then(() => {
                history.push('/survey/family');
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    };

    return (
        <div>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />
            <form onSubmit={onSubmit}>
                <RadioQuestion selected={workInput.q13} required={true} label={q13.label} name={q13.id} choices={q13.choices} onChange={handleChange} inlineChoices={true}/>

                {
                    workInput.q13 === 'Y' ?
                        <div>
                            <hr/>
                            <p css={tw`italic mb-2`}>Please rate how strongly you agree or disagree with each of the following statements.</p>

                            <AgreeStatementsTable>
                                {
                                    q14.map(statement => (
                                        <AgreeStatementRow
                                            key={statement.id}
                                            statement={statement}
                                            selected={workInput[statement.id as keyof WorkInput] || ''}
                                            onChange={handleChange}
                                        />
                                    ))
                                }
                            </AgreeStatementsTable>

                        </div>
                        : null
                }
                <div css={tw`mt-8 flex justify-between`}>
                    <div>
                        {/* <Button type="button" onClick={() => history.push('/survey/major')}>Back</Button> */}
                    </div>

                    <Button type="submit">Next</Button>
                </div>
            </form>
        </div>
    );
};
