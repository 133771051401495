import React, { useMemo } from 'react';
import {
    formatCumulativeGradeAverageCondition,
    formatRequirementConditionOperator, formatStudentGroupCondition,
    formatTestScoreCondition,
    RequirementGroupDetailsWithDepth
} from '@/utils/catalogUtils';
import { RequirementLine } from '@/api/types';
import tw from 'twin.macro';
import ConditionSpecInfo from '@/components/class-detail/requirements/ConditionSpecInfo';

interface Props {
    detail: RequirementGroupDetailsWithDepth | RequirementLine;
}

export const conditionRenderer: {[key: string]: string} = {
    LVL: 'Academic Level',
    TST: 'Test Score',
    GRP: 'Student Group',
    GRS: 'Student Group',
    PL: 'Academic Plan',
    PR: 'Academic Program',
    TBL: 'Dynamic Condition',
    SPL: 'Academic Subplan',
    CGA: 'Cumulative Grade Average',
    PLS: 'Academic Plan Entity Group'
};

export default ({ detail }: Props) => {
    const title = useMemo<JSX.Element | null>(() => {
        const s = `Condition: ${conditionRenderer[detail.conditionCode]}`;

        if ('groupLineNumber' in detail) {
            return (
                <div className={'requirement-title-container'}>
                    { detail.parenthesis === '(' ?
                        <span css={tw`mx-2`}>(</span>
                        : null
                    }
                    <p className={'requirement-title'} css={tw`font-black`}>{s}</p>
                    { detail.parenthesis === ')' ?
                        <span css={tw`mx-2`}>)</span>
                        : null
                    }
                </div>
            );
        } else {
            return <p css={tw`font-medium`}>{s}</p>;
        }
    }, [ detail ]);

    const body = useMemo<JSX.Element | null>(() => {
        if (detail.conditionCode === 'LVL') {
            return <p>Academic Level is {formatRequirementConditionOperator(detail.conditionOperator)} {detail.conditionData}</p>;
        }
        if (detail.conditionCode === 'TST') {
            return <p>{formatTestScoreCondition(detail)}</p>;
        }

        if (detail.conditionCode === 'GRP' || detail.conditionCode === 'GRS') {
            return <p>{formatStudentGroupCondition(detail)}</p>;
        }

        if (detail.conditionCode === 'PL') {

            if (detail.conditionOperator === 'IN' || detail.conditionOperator === 'NI') {
                return (
                    <div>
                        <p>Entity Group [{detail.conditionData}]: {detail.entityGroup?.descr}</p>
                        <p>Academic Plan is {formatRequirementConditionOperator(detail.conditionOperator)}:</p>
                        <div css={tw`m-0 p-0 ml-4`}>

                            {detail.entityGroupDetails.map((o, i) => (
                                <span css={tw`text-sm`} key={i}>
                                    <span>{o.academicPlan}</span>
                                    {detail.entityGroupDetails.length > 1 && i !== detail.entityGroupDetails.length - 1 ? ', ' : ''}
                                </span>
                            ))}
                        </div>
                    </div>
                );

            } else {
                return <p>Academic Plan is {formatRequirementConditionOperator(detail.conditionOperator)} {detail.planDescription}</p>;
            }
        }


        if (detail.conditionCode === 'PR') {
            return <p>Academic Program is {formatRequirementConditionOperator(detail.conditionOperator)} {detail.planDescription}</p>;
        }

        if (detail.conditionCode === 'TBL') {
            return <ConditionSpecInfo requirementLine={detail}/>;
        }

        if (detail.conditionCode === 'SPL') {
            return <p>Academic Subplan is {formatRequirementConditionOperator(detail.conditionOperator)} {detail.subplanDescription}</p>;
        }

        if (detail.conditionCode === 'CGA') {
            return <p>{formatCumulativeGradeAverageCondition(detail)}</p>;
        }

        if (detail.conditionCode === 'PLS') {
            return (
                <div>
                    <p>Entity Group [{detail.conditionData}]: {detail.entityGroup?.descr}</p>
                    <p>Academic Plan is {formatRequirementConditionOperator(detail.conditionOperator)}:</p>
                    <div css={tw`m-0 p-0 ml-4`}>

                        {detail.entityGroupDetails.map((o, i) => (
                            <span css={tw`text-sm`} key={i}>
                                <span>{o.academicPlan}</span>
                                {detail.entityGroupDetails.length > 1 && i !== detail.entityGroupDetails.length - 1 ? ', ' : ''}
                            </span>
                        ))}
                    </div>
                </div>
            );
        }

        console.error('Unhandled condition code: ', detail.conditionCode);
        return null;
    }, [ detail ]);

    return (
        <>
            {title}
            {body}
        </>
    );
};
