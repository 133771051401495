import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components/macro';

const TableStyle = styled.table`
    ${tw`table-fixed`}
    * {
        ${tw`font-normal`}
    }
    
    input[type=radio] {
        width: 20px;
        height: 20px;
    }

    th:not(:first-child), td:not(:first-child) {
        width: 89px;
    }
    th {
        ${tw`text-center`}
        padding-bottom: 20px;
    }
    td {
        ${tw`text-center py-2`}
    }
    td:first-child {
        ${tw`text-left px-2`}
    }
    
    tr:not(:last-child) {
        td {
            ${tw`border-0 border-solid border-b border-gray-300`}
        }
    }
    
    @media (max-width: 649px) {
        th:not(:first-child) {
            display: none;
        }
        th {
            padding-bottom: 0;
        }
    }
    @media (min-width: 650px) {
        select {
            display: none;
        }
    }
`;

interface Props {
    children: React.ReactNode;
}

export default ({ children }: Props) => {
    return (
        <TableStyle>
            <thead>
                <tr>
                    <th/>
                    <th><span>strongly disagree</span></th>
                    <th><span>disagree</span></th>
                    <th><span>neither agree nor disagree</span></th>
                    <th><span>somewhat agree</span></th>
                    <th><span>agree</span></th>
                    <th><span>strongly agree</span></th>
                </tr>
            </thead>
            <tbody>
                {children}
            </tbody>
        </TableStyle>
    );
};
