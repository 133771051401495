import { gql } from '@apollo/client';

export default gql`
fragment courseOffer on custom_crse_offer {
    courseId: crse_id
    subject
    catalogId: catalog_nbr
    academicCareer: acad_career
    requirementId: rqrmnt_group
    requirement {
        description: descrlong
    }
    catalog {
        courseTitle: course_title_long
        description: descrlong
        units: units_acad_prog
    }
    classes {
        classId: class_nbr
        academicCareer: acad_career
        classSection: class_section
        startDate: start_dt
        endDate: end_dt
        enrollmentCap: enrl_cap
        waitCap: wait_cap
        waitTotal: wait_tot
        enrollmentTotal: enrl_tot,
        instructionMode: instruction_mode,
        enrollmentStatus: enrl_stat
    }
}
`;

export const catalogFields = gql`
fragment catalogFields on custom_crse_offer_view {
    requirementDesignation: rqmnt_designtn
    courseTitle: course_title_long
    courseComponent: ssr_component
    description: descrlong
    units: units_acad_prog
    minUnits: units_minimum
    maxUnits: units_maximum
}
`;

export const requirementGroupFields = gql`
fragment requirementGroupFields on custom_crse_offer_view {
    requirementGroupName: rq_grp_descr
    requirementGroupDescription: rq_grp_descrlong
    requirementGroupDescriptionFull: rq_grp_descr254a
}
`;
