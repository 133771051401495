import React from 'react';
import tw from 'twin.macro';
import { activeTerm } from '@/constants/terms';



export default () => {
    return (
        <>
            <h3 css={tw`font-medium text-black`}>Please pretend that it is before the {activeTerm.season} {activeTerm.year} semester and select the course schedule that you would choose if the courses here were the only ones available.</h3>
            <h3 css={tw`font-medium text-red-500`}>This is just a simulation and will not impact your actual CUNY course registration.</h3>

            <p>For the purposes of keeping this simulation simple, we have omitted special course sections and have only included the regular {activeTerm.season} session.  So: </p>
            <ul css={tw`pl-8`}>
                <li>
                    <p>Please <span css={tw`font-medium text-black`}>only select courses for the long, regular {activeTerm.season} session</span> (and not for {activeTerm.season} II, winter, summer, or other special sessions).</p>
                </li>
                <li>
                    <p>Please also <span css={tw`font-medium text-black`}>assume that whatever sections you choose would fulfill any special course categories</span> (e.g., WI, Honors, ASAP) that you need for next semester.</p>
                </li>
                <li>
                    <p>You can look up any information about course requirements if you would normally do this when choosing classes in CUNYfirst.</p>
                </li>
            </ul>
        </>
    );
};
