import { GET_SIM_RESULTS_BY_PK, SimulationResults } from '@/api/simulation/SimulationAPI';
import graphClient from '@/api/graph';

export default async (studentId: string): Promise<SimulationResults> => {
    const { data } = await graphClient.query({
        query: GET_SIM_RESULTS_BY_PK,
        variables: {
            id: studentId
        },
        fetchPolicy: 'network-only',
    });
    return data?.results || {};
};
