import {
    QuestionConditionEnum,
    QuestionParams,
    SimulationQuestion,
} from '@/constants/questions';
import { checkboxOrRadio } from '@/api/types';
import { interpolateQuestion } from '@/constants/questions/questionUtils';

// excludes SameCourseSameMode
enum ReplacementConditionEnum {
    SameCourseDifferentMode = 'SameCourseDifferentMode',
    DifferentCourseSameMode = 'DifferentCourseSameMode',
    DifferentCourseDifferentMode = 'DifferentCourseDifferentMode',
}

// this assumes student replaced the course, and NOT just dropped without replacing.
const Question: {[key in ReplacementConditionEnum]: SimulationQuestion} = {
    [QuestionConditionEnum.SameCourseDifferentMode]: {
        id: QuestionConditionEnum.SameCourseDifferentMode,
        label: 'Which of the following best explains why you chose to take {{replacementCourseName}} as {{replacementAan}} <b>{{replacementCourseMode}} course</b?',
        type: 'radio' as checkboxOrRadio,
        choices: [
            {
                value: 'CriticalForSemester',
                label: 'I strongly preferred the {{previousCourseMode}} section to the {{replacementCourseMode}} section, but taking {{replacementCourseName}} this semester is critical to completing my degree on time (or meeting other key educational goals).'
            },
            {
                value: 'PreferredPreviousModeButNoProblem',
                label: 'I preferred {{previousAan}} {{previousCourseMode}} section, but it wasn\'t a problem for me to take {{replacementAan}} {{replacementCourseMode}} section instead.'
            },
            {
                value: 'DontCareMode',
                label: 'I really don\'t care whether the section of {{previousCourseName}} that I take is {{previousCourseMode}} or {{replacementCourseMode}}.'
            },

        ]
    },
    [QuestionConditionEnum.DifferentCourseSameMode]: {
        id: QuestionConditionEnum.DifferentCourseSameMode,
        label: 'Which of the following best explains why you chose to take {{replacementCourseName}} instead of {{originalCourseName}}?',
        type: 'radio' as checkboxOrRadio,
        choices: [
            {
                value: 'PreferredPreviousCourseButNeededMode',
                label: 'I strongly preferred {{previousCourseName}} over {{replacementCourseName}}, but I just really need {{replacementAan}} {{replacementCourseMode}} course.'
            },
            {
                value: 'PreferredPreviousCourseButNoProblem',
                label: 'I preferred {{previousCourseName}}, but it wasn\'t a problem for me to take {{replacementCourseName}} instead.'
            },
            {
                value: 'DontCareCourse',
                label: 'I really don\'t care whether I take {{previousCourseName}} or {{replacementCourseName}}.'
            },
        ]
    },
    [QuestionConditionEnum.DifferentCourseDifferentMode]: {
        id: QuestionConditionEnum.DifferentCourseDifferentMode,
        label: 'Why did you choose to change to {{replacementAan}} <b>{{replacementCourseMode}}</b> section of the course {{replacementCourseName}}?',
        type: 'checkbox' as checkboxOrRadio,
        choices: [
            {
                value: 'BetterReplacementCourseAndMode',
                label: 'I feel the subject matter in {{replacementCourseName}} is better taken as {{replacementAan}} {{replacementCourseMode}} course.'
            },
            {
                value: 'ReplacementCourseEssential',
                label: 'Since {{originalCourseName}} {{originalCourseMode}} was not available, it is important to me to take {{replacementCourseName}} this semester, regardless of how it is taught (asynchronous online, synchronous online, or on campus).'
            },
            {
                value: 'ReplacementModeBetterFitLearning',
                label: '{{replacementCourseMode}} sections of {{replacementCourseName}} just fit my learning style better.  '
            },
            {
                value: 'ReplacementModeBetterFitSchedule',
                label: '{{replacementCourseMode}} sections of {{replacementCourseName}} just fit my schedule/life responsibilities better.'
            },
            {
                value: 'PreferredDifferentModeButNotAvailable',
                label: 'I would have preferred not to take {{replacementAan}} {{replacementCourseMode}} section, but {{replacementCourseName}} was only available as {{replacementAan}} {{replacementCourseMode}}.'
            },
        ]
    },
};

export const getReplacementReasonQuestion = (params: QuestionParams): SimulationQuestion | null => {

    const { previousClass, replacementClass } = params;

    let condition: ReplacementConditionEnum | undefined;

    if (replacementClass.courseId === previousClass.courseId &&
        replacementClass.sectionMode !== previousClass.sectionMode) {
        condition = ReplacementConditionEnum.SameCourseDifferentMode;

    } else if (replacementClass.courseId !== previousClass.courseId) {

        if (replacementClass.sectionMode === previousClass.sectionMode) {
            condition = ReplacementConditionEnum.DifferentCourseSameMode;
        } else {
            condition = ReplacementConditionEnum.DifferentCourseDifferentMode;
        }
    }

    if (!condition) {
        return null;
    }

    return interpolateQuestion(Question[condition], params);
};
