import { Redirect, RouteComponentProps } from 'react-router';
import React from 'react';
import { useRole } from '@/api/AuthService';

export default (Component: React.ComponentType) => {
    return (_props: RouteComponentProps) => {
        const role = useRole();

        if (role !== 'dev') {
            return <Redirect to='/'/>;
        }
        return (
            <Component/>
        );
    };
};
