import React, { useState } from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import FeedbackModal from '@/components/FeedbackModal';
import { useLocation } from 'react-router-dom';

const FooterStyle = styled.div`

    ${tw`py-4 w-full bg-white text-center`}
`;

export default () => {
    const [ showFeedbackModal, setShowFeedbackModal ] = useState<boolean>(false);

    const location = useLocation();

    return (
        <FooterStyle>
            {
                location.pathname === '/sim'
                || location.pathname === '/search'
                || location.pathname === '/replacement-search'
                || location.pathname === '/cart'
                || location.pathname === '/results'
                || location.pathname === '/replacement-results'
                || location.pathname.indexOf('/class/') !== -1

                    ?
                    <p css={tw`text-red-500`}>This is just a simulation and will not impact your actual CUNY course registration.</p>
                    : null
            }


            <a onClick={() => setShowFeedbackModal(true)} css={tw`text-center text-link cursor-pointer`}>Technical Problems?</a>
            <FeedbackModal isVisible={showFeedbackModal} onClose={() => setShowFeedbackModal(false)}/>
        </FooterStyle>
    );
};
