import { Option, Day } from '@/api/types';

const daysOfWeek: Option<Day>[] = [
    {
        value: 'mon',
        label: 'Mon'
    },
    {
        value: 'tues',
        label: 'Tues'
    },
    {
        value: 'wed',
        label: 'Wed'
    },
    {
        value: 'thurs',
        label: 'Thurs'
    },
    {
        value: 'fri',
        label: 'Fri'
    },
    {
        value: 'sat',
        label: 'Sat'
    },
    {
        value: 'sun',
        label: 'Sun'
    }
];

export default daysOfWeek;
