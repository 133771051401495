import { gql } from '@apollo/client';

export default gql`
query getStudentInfoBasic($studentId:String!) {
    items: custom_names(where:{
      emplid: {
        _eq: $studentId
      } 
    } limit:1) {
        studentId: emplid,
        cohort: cohort
        name: name_display,
        isDev: is_dev,
    }
}
`;
