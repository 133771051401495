import React, { CSSProperties, useMemo } from 'react';
import tw from 'twin.macro';
import { Option } from '@/api/types';
import { disableSubmitOnEnter } from '@/components/common/inputUtils';

interface Props {
    label: string;
    htmlLabel?: boolean;
    name: string;
    choices: Option[];
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    inlineChoices?: boolean;
    required?: boolean;
    selected: string;
    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
}

export default ({ label, htmlLabel = false, name, choices, onChange, inlineChoices, required, selected, containerStyle = {}, labelStyle = {}, inputStyle = {} }: Props) => {

    const choicesContainerStyle = useMemo<CSSProperties>(() => {
        return {
            display: inlineChoices ? 'flex' : 'block'
        };
    }, [ inlineChoices ]);

    return (
        <div css={tw`mb-4`} style={containerStyle}>
            {
                htmlLabel ?
                    <p css={tw`font-normal mr-2`} style={labelStyle} dangerouslySetInnerHTML={{__html: label}}/>
                    :
                    <p css={tw`font-normal mr-2`} style={labelStyle}>{label}</p>
            }

            <div style={choicesContainerStyle} role="group">
                {choices.map(choice => {
                    return (
                        <label key={choice.value} css={tw`block mb-2 mx-2`}>
                            <input
                                style={inputStyle}
                                required={required}
                                css={tw`align-middle mt-0 mr-2`}
                                type="radio"
                                name={name}
                                checked={choice.value === selected}
                                value={choice.value}
                                onChange={onChange}
                                onKeyPress={disableSubmitOnEnter}
                            />
                            <span css={tw`align-middle`}>{choice.label}</span>
                        </label>
                    );
                })}
            </div>
        </div>
    );
};
