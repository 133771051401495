import React, { useState, useMemo } from 'react';
import conditions, {
    SimulatedEvent,
    SimulatedEventChoice
} from '@/constants/simulationConditions';
import { SelectedCourse } from '@/api/types';
import { parseCourseShortName } from '@/api/transformers';
import tw from 'twin.macro';
import { Button } from '@/components/common';
import _ from 'lodash';
import SimulationCart from '@/components/simulation/SimulationCart';
import useCurrentlyReplacing from '@/components/replacements/useCurrentlyReplacing';
import { useGetSimResults } from '@/api/simulation/SimulationAPI';
import { getCurrentTree } from '@/store/simulation/simulationUtils';

interface Props {
    originalCourse: SelectedCourse;
    onSubmitChoice: (choice: SimulatedEventChoice) => void;
}
export default ({ onSubmitChoice, originalCourse }: Props) => {

    const { shoppingCart, data: simulationData } = useGetSimResults();

    const currentlyReplacing = useCurrentlyReplacing();

    const interpolatedQuestion = useMemo<SimulatedEvent>(() => {
        const condition = conditions[simulationData.currentStage!.id!];
        console.log('condition: ', condition);

        const originalCourseName = `${parseCourseShortName(originalCourse)} (${originalCourse.courseTitle})`;
        const replacementCourseName = currentlyReplacing ? `${parseCourseShortName(currentlyReplacing)} (${currentlyReplacing.courseTitle})` : '';

        const questionKey = condition.questionAlt && condition.questionValidator ? condition.questionValidator(simulationData) : 'question';
        const question: string = condition[questionKey] as string;

        const interpolatedQuestion = _.upperFirst(question
            .replace(/{{originalCourse}}/g, originalCourseName)
            .replace(/{{replacementCourse}}/g, replacementCourseName)
        );

        const choices = condition.choices.map(choice => ({
            ...choice,
            label: _.upperFirst(choice.label
                .replace(/{{originalCourse}}/g, originalCourseName)
                .replace(/{{replacementCourse}}/g, replacementCourseName)
            )
        }));

        return {
            desc: condition.desc,
            question: interpolatedQuestion,
            choices
        };

    }, [ simulationData, simulationData.currentStage, simulationData.currentStage?.id, currentlyReplacing ]);

    const [ selectedChoice, setSelectedChoice ] = useState<SimulatedEventChoice | null>(null);

    const renderChoices = () => {

        const currentTree = getCurrentTree(simulationData);
        const completedStages = currentTree?.completedStages || [];

        const filteredChoices = _.filter(interpolatedQuestion.choices, choice => {
            if (choice.hideIf) {
                for (let i = 0; i < completedStages.length || 0; i++) {
                    if (completedStages[i].id === choice.hideIf) {
                        return false;
                    }
                }
            }
            return true;
        });
        // console.log('filteredChoices: ', filteredChoices);

        return filteredChoices.map((choice, i) => {
            return (
                <li css={tw`mb-2`} key={choice.value}>
                    <label>
                        <input value={choice.response} type="radio" name={simulationData.currentStage!.id!} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            // onRadioSelect(e.target.value as QuestionResponseEnum)
                            if (e.target.value === choice.response) {
                                setSelectedChoice(choice);
                            }
                        }}
                        />
                        <span css={tw`align-middle`} dangerouslySetInnerHTML={{__html: choice.label}}/>
                    </label>
                </li>
            );
        });
    };

    return (
        <div>
            <p dangerouslySetInnerHTML={{__html: interpolatedQuestion.question}}/>
            <p css={tw`text-sm`}>(Note: This is just a simulation and does not reflect actual course availability. Please tell us what you would do if this actually happened in real life.)</p>

            <div css={tw`pt-3 pb-1 md:w-1/2`}>
                <SimulationCart unavailable={currentlyReplacing || originalCourse} items={shoppingCart}/>
            </div>


            <ul css={tw`relative list-none p-0`}>
                {renderChoices()}
            </ul>

            <div css={tw`text-right pr-3`}>
                <Button disabled={!selectedChoice} onClick={() => onSubmitChoice(selectedChoice!)}>Next</Button>
            </div>
        </div>
    );
};
