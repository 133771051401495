import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { InputWithCriteriaContainerStyle } from './styled';
import { TimeCriteriaOptions, SearchCriteria, CriteriaType } from './SearchCriteria';
import TimeSlotPicker from './TimeSlotPicker';
import { format } from 'date-fns';

interface Props {
    label: string;
    fieldName: string;
    criteriaFieldName: string;
}

export default ({ label, fieldName, criteriaFieldName }: Props) => {

    const {
        values: { [criteriaFieldName]: criteria, [fieldName]: fieldValue },
        setFieldValue
    } = useFormikContext<any>();

    const [ showEndInput, setShowEndInput ] = useState<boolean>(false);

    useEffect(() => {
        setShowEndInput(criteria === CriteriaType.Between);
    }, [ criteria ]);

    const onStartInputChange = (date: Date) => {
        let time = '';
        if (date) {
            time = format(date, 'h:mm aa');
        }

        const value = [ ...fieldValue ];
        value[0] = time;
        setFieldValue(fieldName, value);
    };

    const onEndInputChange = (date: Date) => {
        let time = '';
        if (date) {
            time = format(date, 'h:mm aa');
        }

        const value = [ ...fieldValue ];
        value[1] = time;
        setFieldValue(fieldName, value);
    };

    return (
        <InputWithCriteriaContainerStyle>
            <label>{label}</label>

            <Field className="criteria-select" name={criteriaFieldName} as="select">
                <option value=""></option>
                {
                    TimeCriteriaOptions.map((o: SearchCriteria) => (
                        <option key={o.value} value={o.value}>
                            {o.label}
                        </option>
                    ))
                }
            </Field>

            <div className="trailingInput">
                <TimeSlotPicker onChange={onStartInputChange} />
            </div>

            {
                showEndInput ?
                    <div className="trailingInput">
                        <TimeSlotPicker onChange={onEndInputChange} />
                    </div>
                    : null
            }


        </InputWithCriteriaContainerStyle>
    );
};
