import React, { useEffect, useMemo, useState } from 'react';
import { useStoreState } from '@/store';
import tw from 'twin.macro';
import { Button, Spinner } from '../../common';
import {
    parseClassWithNonLectureComponent,
    parseCurrentEnrollments,
} from '@/api/transformers';
import { QuestionContainerStyle } from '@/components/major/styled';
import getMajorsByInstitution from '@/api/graphql/queries/getMajorsByInstitution';
import graphClient from '@/api/graph';
import { Option, StudentEnrollment } from '@/api/types';
import _ from 'lodash';
import {
    useGetSurveyAnswers,
    useUpdateSurvey
} from '@/api/simulation/SimulationAPI';
import { formatISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { atLeastOneLifeEvent } from '@/components/survey/surveyQuestions';
import RadioQuestion from '@/components/survey/RadioQuestion';
import {
    major1a,
    major1b,
    major2a,
    major2b, major3a, major3b, major5,
    MajorResponse,
} from '@/components/survey/major/majorQuestions';
import SelectQuestion from '@/components/survey/SelectQuestion';
import institutions, { InstitutionName } from '@/constants/institutions';

export default () => {
    const enrollments = useStoreState(state => state.student.studentInfo?.enrollments);
    const institution = useStoreState(state => state.student.institution);
    const major = useStoreState(state => state.student.major);
    const academicPlan = useStoreState(state => state.student.academicPlan);
    console.log('major: ', major);

    const survey = useGetSurveyAnswers();
    const updateSurvey = useUpdateSurvey();

    const [ loading, setLoading ] = useState<boolean>(false);
    const [ majorInput, setMajorInput ] = useState<MajorResponse>(survey.major || {});

    const [ error, setError ] = useState<string>('');

    const [ coursesRetaking, setCoursesRetaking ] = useState<string[]>([]);

    const [ currentInstitution, setCurrentInstitution ] = useState<string>(institution);
    const [ currentInstitutionMajors, setCurrentInstitutionMajors ] = useState<Option[]>([]);
    const [ currentMajor, setCurrentMajor ] = useState<string>(academicPlan);

    const [ changingInstitution, setChangingInstitution ] = useState<string>(survey.major.q3b !== 'SKIPPED' ? survey.major.q3b?.institution || '' : '');
    const [ changingInstitutionMajors, setChangingInstitutionMajors ] = useState<Option[]>([]);

    const history = useHistory();

    const currentEnrollments = useMemo<StudentEnrollment[]>(() => {
        return parseCurrentEnrollments(enrollments || []);
    }, [ enrollments ]);

    useEffect(() => {
        if (institution) {
            setLoading(true);
            graphClient.query({
                query: getMajorsByInstitution,
                variables: {
                    institution: institution
                }
            })
                .then(results => {
                    const updatedInstitutionMajors = _.uniqBy((results.data.items as Option[]), 'value') as Option[];
                    setCurrentInstitutionMajors(updatedInstitutionMajors);

                    if (institution === changingInstitution) {
                        setChangingInstitutionMajors(updatedInstitutionMajors);
                        setLoading(false);
                    } else if (changingInstitution) {
                        return graphClient.query({
                            query: getMajorsByInstitution,
                            variables: {
                                institution: changingInstitution
                            }
                        })
                            .then(results => {
                                const updatedInstitutionMajors = _.uniqBy((results.data.items as Option[]), 'value') as Option[];
                                setChangingInstitutionMajors(updatedInstitutionMajors);
                                setLoading(false);
                            })
                            .catch(err => {
                                console.error(err);
                            });
                    }
                })
                .catch(err => {
                    console.error(err);
                })
                .then(() => {
                    setLoading(false);
                });
        }

    }, [ institution ]);

    useEffect(() => {
        console.log('survey.major.major3: ', survey.major.q4);
        const timer1 = setTimeout(() => {
            if (survey.major.q4 !== 'SKIPPED') {
                setCoursesRetaking((survey.major.q4 || []).map(o => o.classId));
            }
        }, 50);

        return () => {
            clearTimeout(timer1);
        };

    }, [ survey.major.q4 ]);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setError('');
        setLoading(true);
        const finalInput: MajorResponse = {
            ...majorInput,
            submittedTs: formatISO(new Date())
        };

        const allCurrentMajors = [];

        if (finalInput.q1a === 'Y') {
            finalInput.q1b = 'SKIPPED';
            allCurrentMajors.push(currentMajor);

        } else if (finalInput.q1b !== 'SKIPPED') {
            allCurrentMajors.push(finalInput.q1b.major);

            if (currentMajor === finalInput.q1b.major) {
                setError('This is the same major already selected. Please pick a different one.');
                setLoading(false);
                return;
            }
        }

        if (finalInput.q2a === 'N') {
            finalInput.q2b = 'SKIPPED';
        } else if (finalInput.q2b !== 'SKIPPED') {
            allCurrentMajors.push(finalInput.q2b.major2);
            if (finalInput.q2b.major3) {
                allCurrentMajors.push(finalInput.q2b.major3);
            }
        }

        const uniqueMajors = _.uniq(allCurrentMajors);
        if (uniqueMajors.length !== allCurrentMajors.length) {
            setError('This is the same major already selected. Please pick a different one.');
            setLoading(false);
            return;
        }


        if (finalInput.q3a === 'N') {
            finalInput.q3b = 'SKIPPED';
        } else if (finalInput.q3b !== 'SKIPPED') {
            if (!finalInput.q3b.major2 && finalInput.q3b.major3) {
                setError('Please select your second major.');
                setLoading(false);
                return;
            }

            const allChangingMajors = _.filter([ finalInput.q3b.major, finalInput.q3b.major2, finalInput.q3b.major3 ], s => !!(s));
            const uniqueChangingMajors = _.uniq(allChangingMajors);
            if (uniqueChangingMajors.length !== allChangingMajors.length) {
                setError('This is the same major already selected. Please pick a different one.');
                setLoading(false);
                return;
            }
        }

        if (!currentEnrollments.length) {
            finalInput.q4 = 'SKIPPED';
        }

        console.log('onSubmit finalInput: ', finalInput);

        updateSurvey({major: finalInput})
            .then(() => {
                history.push('/survey/demographics');
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    };

    const onBack = () => {
        if (atLeastOneLifeEvent(survey.lifeEvents)) {
            history.push('/survey/life2');
        } else {
            history.push('/survey/life1');
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const field = event.target.name;
        const value: string | number = event.target.value;

        const additionalProps: Partial<MajorResponse> = {};
        if (field === 'q3a' && value === 'Y') {
            console.log('majorInput.q2b: ', majorInput.q2b);

            additionalProps.q3b = {
                institution: currentInstitution,
                major: currentMajor,
                major2: majorInput.q2b !== 'SKIPPED' ? majorInput.q2b?.major2 || '' : '',
                major3: majorInput.q2b !== 'SKIPPED' ? majorInput.q2b?.major3 || '' : '',
            };
            console.log('additionalProps: ', additionalProps);
            setChangingInstitution(currentInstitution);
            setChangingInstitutionMajors(currentInstitutionMajors);
        }

        setMajorInput({
            ...majorInput,
            [field]: value,
            ...additionalProps,
        });
    };

    const onInstitutionChange = (institution: string) => {
        setCurrentInstitution(institution);
        setMajorInput({
            ...majorInput,
            q1b: {
                institution,
                major: ''
            }
        });

        setCurrentInstitutionMajors([]);
        setCurrentMajor('');

        if (institution) {
            setLoading(true);
            graphClient.query({
                query: getMajorsByInstitution,
                variables: {
                    institution: institution
                }
            })
                .then(results => {
                    const updatedInstitutionMajors = _.uniqBy((results.data.items as Option[]), 'value') as Option[];
                    setCurrentInstitutionMajors(updatedInstitutionMajors);
                })
                .catch(err => {
                    console.error(err);
                })
                .then(() => setLoading(false));
        }
    };
    const onChangingInstitutionChange = (institution: string) => {
        setChangingInstitution(institution);
        setChangingInstitutionMajors([]);

        if (institution) {
            setLoading(true);
            graphClient.query({
                query: getMajorsByInstitution,
                variables: {
                    institution: institution
                }
            })
                .then(results => {
                    const updatedInstitutionMajors = _.uniqBy((results.data.items as Option[]), 'value') as Option[];
                    setChangingInstitutionMajors(updatedInstitutionMajors);
                })
                .catch(err => {
                    console.error(err);
                })
                .then(() => setLoading(false));
        }
    };

    if (!enrollments) {
        return null;
    }

    return (
        <div>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />
            <form onSubmit={onSubmit}>
                <RadioQuestion htmlLabel={true} labelStyle={{ fontWeight: 500 }} selected={majorInput.q1a} required={true} label={major1a.label.replace('{major}', major).replace('{institution}', InstitutionName[institution])} name={major1a.id} choices={major1a.choices} onChange={handleChange} inlineChoices={true}/>
                {
                    majorInput.q1a === 'N' ?
                        <div>
                            <label css={tw`font-medium`}>{major1b.label}</label>
                            <div css={tw`flex flex-row w-full md:w-1/2`}>
                                <SelectQuestion
                                    inputStyle={{
                                        width: '100%',
                                        margin: 0,
                                    }}
                                    containerStyle={{
                                        width: '100%',
                                        border: 'none',
                                        marginBottom: '10px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        display: 'block',
                                    }}
                                    label="Institution"
                                    required={true}
                                    name="institution"
                                    choices={institutions}
                                    onChange={e => onInstitutionChange(e.target.value)}
                                    selected={currentInstitution}
                                />
                                <SelectQuestion
                                    inputStyle={{
                                        width: '100%',
                                        margin: 0,
                                    }}
                                    containerStyle={{
                                        width: '100%',
                                        border: 'none',
                                        marginBottom: '10px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        display: 'block',
                                    }}
                                    label="Major"
                                    required={true}
                                    name="major"
                                    choices={currentInstitutionMajors}
                                    onChange={e => {
                                        setMajorInput({
                                            ...majorInput,
                                            q1b: {
                                                institution: currentInstitution,
                                                major: e.target.value,
                                            }
                                        });
                                    }}
                                    selected={majorInput.q1b !== 'SKIPPED' ? majorInput.q1b?.major || '' : ''}
                                />
                            </div>


                        </div>

                        : null
                }

                <RadioQuestion labelStyle={{ fontWeight: 500 }} selected={majorInput.q2a} required={true} label={major2a.label} name={major2a.id} choices={major2a.choices} onChange={handleChange} inlineChoices={true}/>
                {
                    majorInput.q2a === 'Y' ?
                        <div css={tw`mb-4`}>
                            <label css={tw`font-medium`}>{major2b.label}</label>
                            <div css={tw`w-full md:w-1/2`}>
                                <SelectQuestion
                                    labelStyle={{
                                        textAlign: 'right',
                                        flexShrink: 0,
                                        minWidth: '112px',

                                    }}
                                    inputStyle={{
                                        display: 'inline',
                                        flexShrink: 1,
                                    }}
                                    containerStyle={{
                                        width: '100%',
                                        border: 'none',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        display: 'flex',
                                    }}
                                    label="2nd major"
                                    required={true}
                                    name="major2"
                                    choices={currentInstitutionMajors}
                                    onChange={e => {
                                        const q2b = {
                                            ...(majorInput.q2b !== 'SKIPPED' ? majorInput.q2b || {} : {}),
                                            major2: e.target.value
                                        };

                                        setMajorInput({
                                            ...majorInput,
                                            q2b
                                        });
                                    }}
                                    selected={majorInput.q2b !== 'SKIPPED' ? majorInput.q2b?.major2 || '' : ''}
                                />
                                <SelectQuestion
                                    labelStyle={{
                                        textAlign: 'right',
                                        flexShrink: 0,
                                        minWidth: '112px',

                                    }}
                                    inputStyle={{
                                        display: 'inline',
                                        flexShrink: 1,
                                    }}
                                    containerStyle={{
                                        width: '100%',
                                        border: 'none',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        display: 'flex',
                                    }}
                                    label="3rd major (if any)"
                                    required={false}
                                    name="major3"
                                    choices={currentInstitutionMajors}
                                    onChange={e => {
                                        const q2b = {
                                            ...(majorInput.q2b !== 'SKIPPED' ? majorInput.q2b || {} : {}),
                                            major3: e.target.value
                                        };
                                        setMajorInput({
                                            ...majorInput,
                                            q2b
                                        });
                                    }}
                                    selected={majorInput.q2b !== 'SKIPPED' ? majorInput.q2b?.major3 || '' : ''}
                                />
                            </div>

                        </div>
                        : null
                }

                <RadioQuestion labelStyle={{ fontWeight: 500 }} selected={majorInput.q3a} required={true} label={major3a.label} name={major3a.id} choices={major3a.choices} onChange={handleChange} inlineChoices={true}/>
                {
                    majorInput.q3a === 'Y' ?
                        <div css={tw`mb-4`}>
                            <label css={tw`font-medium`}>{major3b.label}</label>
                            <div css={tw`w-full md:w-1/2`}>
                                <SelectQuestion
                                    labelStyle={{
                                        textAlign: 'right',
                                        flexShrink: 0,
                                        minWidth: '112px',

                                    }}
                                    inputStyle={{
                                        display: 'inline',
                                        flexShrink: 1,
                                    }}
                                    containerStyle={{
                                        width: '100%',
                                        border: 'none',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        display: 'flex',
                                    }}
                                    label="Institution"
                                    required={true}
                                    name="institution"
                                    choices={institutions}
                                    onChange={e => onChangingInstitutionChange(e.target.value)}
                                    selected={changingInstitution}
                                />

                                <SelectQuestion
                                    labelStyle={{
                                        textAlign: 'right',
                                        flexShrink: 0,
                                        minWidth: '112px',

                                    }}
                                    inputStyle={{
                                        display: 'inline',
                                        flexShrink: 1,
                                    }}
                                    containerStyle={{
                                        width: '100%',
                                        border: 'none',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        display: 'flex',
                                    }}
                                    label="Major"
                                    required={true}
                                    name="major"
                                    choices={changingInstitutionMajors}
                                    onChange={e => {
                                        const q3b = {
                                            ...(majorInput.q3b !== 'SKIPPED' ? majorInput.q3b || {} : {}),
                                            major: e.target.value
                                        };
                                        setMajorInput({
                                            ...majorInput,
                                            q3b
                                        });
                                    }}
                                    selected={majorInput.q3b !== 'SKIPPED' ? majorInput.q3b?.major || '' : ''}
                                />
                                <SelectQuestion
                                    labelStyle={{
                                        textAlign: 'right',
                                        flexShrink: 0,
                                        minWidth: '112px',

                                    }}
                                    inputStyle={{
                                        display: 'inline',
                                        flexShrink: 1,
                                    }}
                                    containerStyle={{
                                        width: '100%',
                                        border: 'none',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        display: 'flex',
                                    }}
                                    label="2nd major (if any)"
                                    required={false}
                                    name="major"
                                    choices={changingInstitutionMajors}
                                    onChange={e => {
                                        const q3b = {
                                            ...(majorInput.q3b !== 'SKIPPED' ? majorInput.q3b || {} : {}),
                                            major2: e.target.value
                                        };
                                        setMajorInput({
                                            ...majorInput,
                                            q3b
                                        });
                                    }}
                                    selected={majorInput.q3b !== 'SKIPPED' ? majorInput.q3b?.major2 || '' : ''}
                                />
                                <SelectQuestion
                                    labelStyle={{
                                        textAlign: 'right',
                                        flexShrink: 0,
                                        minWidth: '112px',

                                    }}
                                    inputStyle={{
                                        display: 'inline',
                                        flexShrink: 1,
                                    }}
                                    containerStyle={{
                                        width: '100%',
                                        border: 'none',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        display: 'flex',
                                    }}
                                    label="3rd major (if any)"
                                    required={false}
                                    name="major"
                                    choices={changingInstitutionMajors}
                                    onChange={e => {
                                        const q3b = {
                                            ...(majorInput.q3b !== 'SKIPPED' ? majorInput.q3b || {} : {}),
                                            major3: e.target.value
                                        };
                                        setMajorInput({
                                            ...majorInput,
                                            q3b
                                        });
                                    }}
                                    selected={majorInput.q3b !== 'SKIPPED' ? majorInput.q3b?.major3 || '' : ''}
                                />
                            </div>


                        </div>

                        : null
                }

                {
                    currentEnrollments.length ?
                        <QuestionContainerStyle>
                            <p css={tw`mb-3`}>CUNYfirst shows that you are registered for the following courses. <strong>Do you currently plan to retake any of these courses</strong> (e.g., because you dropped it)?</p>

                            <div css={tw`mb-3 w-full md:w-1/2`}>
                                {currentEnrollments.map((enrollment: StudentEnrollment) => {
                                    return (
                                        <div css={tw`flex justify-between mb-2`} key={enrollment.classId}>
                                            <span css={tw`whitespace-pre-wrap`}>{parseClassWithNonLectureComponent(enrollment)}</span>
                                            <div>
                                                <label css={tw`mr-3`} htmlFor={`${enrollment.classId}-yes}`}>
                                                    <input
                                                        type="radio"
                                                        id={`${enrollment.classId}-yes}`}
                                                        name={enrollment.classId}
                                                        value="yes"
                                                        checked={coursesRetaking.indexOf(enrollment.classId) !== -1}
                                                        onChange={e => {
                                                            console.log('e.target.checked ', e.target.checked);
                                                            if (e.target.checked && coursesRetaking.indexOf(enrollment.classId) === -1) {
                                                                const updatedCoursesRetaking = _.cloneDeep(coursesRetaking);
                                                                updatedCoursesRetaking.push(enrollment.classId);
                                                                setCoursesRetaking(updatedCoursesRetaking);
                                                            }
                                                        }}
                                                    />
                                                    <span>Yes</span>
                                                </label>
                                                <label htmlFor={`${enrollment.classId}-no}`}>
                                                    <input
                                                        type="radio"
                                                        id={`${enrollment.classId}-no}`}
                                                        name={enrollment.classId}
                                                        value="no"
                                                        checked={coursesRetaking.indexOf(enrollment.classId) === -1}
                                                        onChange={e => {
                                                            console.log('e.target.checked: ', e.target.checked);
                                                            if (e.target.checked) {
                                                                const updatedCoursesRetaking = _.cloneDeep(coursesRetaking);
                                                                _.pull(updatedCoursesRetaking, enrollment.classId);
                                                                console.log('removed updatedCoursesRetaking: ', updatedCoursesRetaking);
                                                                setCoursesRetaking(updatedCoursesRetaking);
                                                            }
                                                        }}
                                                    />
                                                    <span>No</span>
                                                </label>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </QuestionContainerStyle>
                        :
                        null
                }

                <RadioQuestion labelStyle={{ fontWeight: 500 }} selected={majorInput.q5} required={true} label={major5.label} name={major5.id} choices={major5.choices} onChange={handleChange}/>


                <div css={tw`mt-8 flex justify-between`}>
                    <div>
                        <Button type="button" onClick={onBack}>Back</Button>
                    </div>

                    <div css={tw`text-right pr-3`}>
                        <p css={tw`mb-1 text-red-500`}>{error}</p>
                        <Button type="submit">Next</Button>
                    </div>
                </div>
            </form>
        </div>

    );
};
