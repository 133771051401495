import { gql } from '@apollo/client';
import graphClient from '@/api/graph';
import { CourseClassQueryResult, CourseClassWithGroupData, CourseInfo } from '@/api/types';
import * as fragments from '@/api/graphql/fragments';
import { convertQueryResultToClassWithGroupData } from '@/api/transformers';

// get class by class_nbr, which is unique
const GET_COURSE_INFO = gql`
query($filter: custom_crse_offer_view_bool_exp) {
  items: custom_crse_offer_view(
    where:$filter,
  ) {
        requirementGroupDetails: requirement_group_details(
            order_by: {rq_grp_line_nbr: asc}
         ){
            ...requirementGroupDetails
        }

      requirementGroup: rqrmnt_group
      
      ...requirementGroupFields
      ...catalogFields
  }
}
${fragments.requirementGroupDetails}
${fragments.requirementGroupFields}
${fragments.catalogFields}
`;

export interface GetCourseInfoProps {
    institution: string;
    courseId: string;
    subject: string;
}
export const getCourseInfo = async (props: GetCourseInfoProps): Promise<CourseInfo | null> => {
    const results = await graphClient.query({
        query: GET_COURSE_INFO,
        variables: {
            filter: {
                crse_id: {
                    _eq: props.courseId
                },
                institution: {
                    _eq: props.institution
                },
                subject: {
                    _eq: props.subject
                }
            }
        }
    });

    console.log('getCourseInfo results: ', results);
    if (results.data.items.length) {
        const courseInfo: CourseInfo = results.data.items[0];
        return courseInfo;
    }
    return null;
};
