import { action, Action, thunk, Thunk } from 'easy-peasy';
import {
    parseMajorFromStudentInfo,
    parseInstitutionFromStudentInfo,
    parseLatestDeclaredPlanFromStudentInfo
} from '@/api/transformers';
import { StudentInfo } from '@/api/types';
import * as Sentry from '@sentry/react';
import {
    convertCourseOfferToCompletedEnrollment,
    simulateCompletedEnrollments,
    StudentDetails
} from '@/utils/enrollmentUtils';
import { StoreModel } from '@/store/index';
import getSimResults from '@/api/simulation/getSimResults';

export interface StudentStore {
    studentInfo: StudentInfo | null;
    major: string;
    institution: string;

    academicPlan: string;

    setStudentInfo: Action<StudentStore, StudentInfo | null>;

    getStudentDetails: Thunk<StudentStore, undefined, any, StoreModel, Promise<StudentDetails>>;
}

const initialState = {
    studentInfo: null,
    major: '',
    institution: '',
    academicPlan: '',
};

const student: StudentStore = {
    ...initialState,

    setStudentInfo: action((state, payload) => {
        console.log('setStudentInfo payload: ', payload);
        if (payload) {
            state.studentInfo = {
                ...payload,
                enrollments: simulateCompletedEnrollments(payload.enrollments)
            };
        }

        state.major = parseMajorFromStudentInfo(payload);
        state.academicPlan = parseLatestDeclaredPlanFromStudentInfo(payload)?.academicPlan || '';

        const institution = parseInstitutionFromStudentInfo(payload);
        state.institution = institution;

        if (payload) {
            Sentry.setUser({
                id: payload.studentId,
                username: payload.name,
            });
        }
    }),

    getStudentDetails: thunk(async (actions, payload, helpers): Promise<StudentDetails> => {
        const studentInfo: StudentInfo | null = helpers.getState().studentInfo;
        if (studentInfo) {
            const { shoppingCart, screener } = await getSimResults(studentInfo.studentId);
            return {
                studentId: studentInfo.studentId,
                academicPlans: studentInfo.academicPlans,
                academicSubplans: studentInfo.academicSubplans,
                academicPrograms: studentInfo.academicPrograms,
                enrollments: studentInfo.enrollments,
                groups: studentInfo.groups,
                milestones: studentInfo.milestones,
                placements: studentInfo.placements,
                shoppingCart,
                simCompletedEnrollments: (screener.summerRegisterIntentCourses || []).map(convertCourseOfferToCompletedEnrollment),
            };
        }
        return helpers.fail();
    })
};

export default student;
