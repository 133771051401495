import { Option, CourseComponent } from '@/api/types';

export const CourseComponentName: any = {
    CLN: 'Clinical',
    CHR: 'Conference Hour',
    CON: 'Continuance',
    DIS: 'Discussion',
    DTN: 'Dissertation',
    FLD: 'Field Studies',
    IND: 'Independent Study',
    INT: 'Internship',
    LAB: 'Laboratory',
    LEC: 'Lecture',
    MSG: 'Message',
    PRA: 'Practicum',
    REC: 'Recitation',
    REH: 'Rehearsal Hour',
    RSC: 'Research',
    SEM: 'Seminar',
    SPV: 'Supervision',
    SUP: 'Supplemental Instruction',
    THE: 'Thesis Research',
    TUT: 'Tutorial',
};

const courseComponents: Option<CourseComponent>[] = [
    {
        value: 'CLN',
        label: 'Clinical'
    },
    {
        value: 'CHR',
        label: 'Conference Hour'
    },
    {
        value: 'CON',
        label: 'Continuance'
    },
    {
        value: 'DIS',
        label: 'Discussion'
    },
    {
        value: 'DTN',
        label: 'Dissertation'
    },
    {
        value: 'FLD',
        label: 'Field Studies'
    },
    {
        value: 'IND',
        label: 'Independent Study'
    },
    {
        value: 'INT',
        label: 'Internship'
    },
    {
        value: 'LAB',
        label: 'Laboratory'
    },
    {
        value: 'LEC',
        label: 'Lecture'
    },
    {
        value: 'MSG',
        label: 'Message'
    },
    {
        value: 'PRA',
        label: 'Practicum'
    },
    {
        value: 'REC',
        label: 'Recitation'
    },
    {
        value: 'REH',
        label: 'Rehearsal Hour'
    },
    {
        value: 'RSC',
        label: 'Research'
    },
    {
        value: 'SEM',
        label: 'Seminar'
    },
    {
        value: 'SPV',
        label: 'Supervision'
    },
    {
        value: 'SUP',
        label: 'Supplemental Instruction'
    },
    {
        value: 'THE',
        label: 'Thesis Research'
    },
    {
        value: 'TUT',
        label: 'Tutorial'
    }
];

export default courseComponents;
