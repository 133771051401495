import React, { useEffect, useState } from 'react';
import RadioQuestion from '@/components/survey/RadioQuestion';
import { DemographicsInput, generateEmptyDemographicsInput } from '@/components/survey/surveyQuestions';
import tw from 'twin.macro';
import { Button, TextArea, Spinner } from '@/components/common';
import { useHistory } from 'react-router-dom';
import { useGetSurveyAnswers, useUpdateSurvey } from '@/api/simulation/SimulationAPI';
import { formatISO } from 'date-fns';
import CheckboxQuestion from '@/components/survey/CheckboxQuestion';
import {
    demo10,
    demo1a,
    demo1b,
    demo2,
    demo3,
    demo4,
    demo5,
    demo6,
    demo7,
    demo8, demo9a,
    demo9b, demoDescribe, demoZip
} from './demographicsQuestions';
import SelectQuestion from '@/components/survey/SelectQuestion';
import { Demo8ContainerStyle } from '@/components/survey/styled';
import InputQuestion from '@/components/survey/InputQuestion';

export default () => {
    const history = useHistory();
    const survey = useGetSurveyAnswers();
    const updateSurvey = useUpdateSurvey();

    const [ error, setError ] = useState<string>('');
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ demographicsInput, setDemographicsInput ] = useState<DemographicsInput>(survey.demographics || generateEmptyDemographicsInput());

    useEffect(() => {
        setDemographicsInput(survey.demographics);
    }, [ survey ]);

    const onSubmit = (e: React.FormEvent) => {
        setError('');
        setLoading(true);
        e.preventDefault();
        e.stopPropagation();

        const finalInput: DemographicsInput = {
            ...demographicsInput,
        };

        if (finalInput.demoZip && finalInput.demoZip.length < 5) {
            setError('Please enter a valid zip code.');
            setLoading(false);
            return;
        }

        if (demographicsInput.demo1a === 'N') {
            finalInput.demo1b = {};
        }
        if (demographicsInput.demo1a === 'Y' || demographicsInput.demo9a === 'N') {
            finalInput.demo9b = {};
        }

        updateSurvey({
            demographics: {
                ...finalInput,
                submittedTs: formatISO(new Date()),
            }
        }).then(() => {
            setTimeout(() => {
                history.push('/payment');
            }, 200);
        }).catch(err => {
            console.log('err: ', err);
            setLoading(false);
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const field = event.target.name;
        let value: string | number = event.target.value;

        if (field === 'demo8') {
            value = parseInt(value);
        }

        setDemographicsInput({
            ...demographicsInput,
            [field]: value
        });
    };

    return (
        <div>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />
            <form onSubmit={onSubmit}>
                <RadioQuestion labelStyle={{ fontWeight: 500 }} selected={demographicsInput.demo1a} label={demo1a.label} name={demo1a.id} choices={demo1a.choices} onChange={handleChange} inlineChoices={true}/>
                {
                    demographicsInput.demo1a === 'Y' ?
                        <CheckboxQuestion
                            label={demo1b.label}
                            choices={demo1b.choices}
                            noneKey={'noResponse'}
                            otherKey={'other'}
                            otherValueKey={'otherValue'}
                            response={demographicsInput.demo1b || {}}
                            onResponse={response => {
                                setDemographicsInput({
                                    ...demographicsInput,
                                    demo1b: response
                                });
                            }}
                        />
                        : null
                }

                <RadioQuestion labelStyle={{ fontWeight: 500 }} selected={demographicsInput.demo2} label={demo2.label} name={demo2.id} choices={demo2.choices} onChange={handleChange}/>


                <InputQuestion
                    label={demoZip.label}
                    name={demoZip.id}
                    onChange={value => {
                        setDemographicsInput({
                            ...demographicsInput,
                            demoZip: value
                        });
                    }}
                    value={demographicsInput.demoZip}
                    onKeyPress={(event) => {
                        const charCode = event.charCode;
                        if (charCode !== 45 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                            event.preventDefault();
                            return false;
                        }

                        return true;
                    }}
                />

                <CheckboxQuestion
                    label={demoDescribe.label}
                    choices={demoDescribe.choices}
                    noneKey={'noResponse'}
                    response={demographicsInput.demoDescribe || {}}
                    onResponse={response => {
                        setDemographicsInput({
                            ...demographicsInput,
                            demoDescribe: response
                        });
                    }}
                />

                <RadioQuestion labelStyle={{ fontWeight: 500 }} selected={demographicsInput.demo3} label={demo3.label} name={demo3.id} choices={demo3.choices} onChange={handleChange} inlineChoices={true}/>
                <RadioQuestion labelStyle={{ fontWeight: 500 }} selected={demographicsInput.demo4} label={demo4.label} name={demo4.id} choices={demo4.choices} onChange={handleChange}/>
                <RadioQuestion labelStyle={{ fontWeight: 500 }} selected={demographicsInput.demo5} label={demo5.label} name={demo5.id} choices={demo5.choices} onChange={handleChange}/>
                <RadioQuestion labelStyle={{ fontWeight: 500 }} selected={demographicsInput.demo6} label={demo6.label} name={demo6.id} choices={demo6.choices} onChange={handleChange} inlineChoices={true}/>
                <RadioQuestion labelStyle={{ fontWeight: 500 }} selected={demographicsInput.demo7} label={demo7.label} name={demo7.id} choices={demo7.choices} onChange={handleChange}/>

                <SelectQuestion
                    labelStyle={{
                        fontWeight: 500,
                        marginBottom: '10px',
                    }}
                    // containerStyle={{
                    //     border: 'none',
                    //     marginBottom: '10px',
                    //     display: 'block',
                    // }}
                    container={Demo8ContainerStyle}
                    inputStyle={{
                        width: '50px',
                        textAlign: 'right',
                        paddingRight: '3px',
                    }}
                    label={demo8.label}
                    name={demo8.id} selected={`${demographicsInput.demo8 || ''}`} choices={demo8.choices}
                    onChange={(e) => {
                        const n = parseInt(e.target.value);
                        setDemographicsInput({
                            ...demographicsInput,
                            demo8: n
                        });
                    }}
                />

                <RadioQuestion labelStyle={{ fontWeight: 500 }} selected={demographicsInput.demo9a} label={demo9a.label} name={demo9a.id} choices={demo9a.choices} onChange={handleChange} inlineChoices={true}/>
                {
                    demographicsInput.demo1a === 'N' && demographicsInput.demo9a === 'Y' ?
                        // <RadioQuestion labelStyle={{ fontWeight: 500 }} selected={demographicsInput.demo9b} label={demo9b.label} name={demo9b.id} choices={demo9b.choices} onChange={handleChange}/>
                        <CheckboxQuestion
                            label={demo9b.label}
                            choices={demo9b.choices}
                            noneKey={'noResponse'}
                            otherKey={'other'}
                            otherValueKey={'otherValue'}
                            response={demographicsInput.demo9b || {}}
                            onResponse={response => {
                                setDemographicsInput({
                                    ...demographicsInput,
                                    demo9b: response
                                });
                            }}
                        />
                        : null
                }

                <div css={tw`mt-4`}>
                    <p css={tw`text-left mb-3`}>{demo10.label}</p>
                    <TextArea
                        css={tw`text-base`}
                        placeholder={''}
                        name={demo10.id}
                        value={demographicsInput[demo10.id as keyof DemographicsInput]}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                            setDemographicsInput({
                                ...demographicsInput,
                                [event.target.name]: event.target.value
                            });
                        }}
                        rows={5}
                    />
                </div>

                <div css={tw`mt-8 flex justify-between`}>
                    <div>
                        <Button type="button" onClick={() => history.push('/survey/major')}>Back</Button>
                    </div>

                    <div css={tw`text-right pr-3`}>
                        <p css={tw`mb-1 text-red-500`}>{error}</p>
                        <Button type="submit">Next</Button>
                    </div>
                </div>
            </form>
        </div>
    );
};
