import React, { useMemo, useState } from 'react';
import tw from 'twin.macro';
import { Option } from '@/api/types';
import { disableSubmitOnEnter } from '@/components/common/inputUtils';

interface Props {
    label: string;
    choices: Option[];

    response: any;
    onResponse: (response: any) => void;

    otherKey?: string;
    otherValueKey?: string;
    noneKey?: string;
    requireAtLeastOne?: boolean;
}

export default ({ noneKey = '', otherKey = '', otherValueKey = '', requireAtLeastOne = false, label, choices, response, onResponse }: Props) => {

    const [ formInput, setFormInput ] = useState(response || {});

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedResponse: any;
        if (e.target.name === noneKey) {
            updatedResponse = {
                [e.target.name]: e.target.checked
            };

        } else {
            updatedResponse = {
                // @ts-ignore
                ...formInput,
                [noneKey]: false,
                [e.target.name]: e.target.checked
            };
        }
        setFormInput(updatedResponse);
        onResponse(updatedResponse);
    };

    const atLeastOneSelected = useMemo<boolean>(() => {
        for (const key in formInput) {
            if (formInput[key]) return true;
        }
        return false;
    }, [ formInput ]);

    const isRequired = useMemo<boolean>(() => {
        if (!requireAtLeastOne) return false;

        return !atLeastOneSelected;
    }, [ requireAtLeastOne, atLeastOneSelected ]);

    return (
        <div css={tw`my-6 relative`}>
            <p css={tw`font-medium`} dangerouslySetInnerHTML={{__html: label}}/>
            <p css={tw`mb-3`}>Please select <span css={tw`font-medium`}>ALL</span> that apply.</p>

            <input
                style={{
                    position: 'absolute',
                    top: 0,
                    opacity: 0,
                }}
                css={tw`pointer-events-none`}
                required={isRequired}
            />

            {
                choices.map((choice) => {
                    return (
                        <div css={tw`flex flex-row mb-1`} key={choice.value}>
                            <label css={tw`block mx-2`}>
                                {/* Need to explicitly cast to boolean otherwise if checked is undefined then React throws the error about handling controlled vs uncontrolled inputs */}
                                <input
                                    css={tw`align-middle mr-2`}
                                    checked={
                                        // @ts-ignore
                                        !!formInput[choice.value]
                                    }
                                    type="checkbox"
                                    name={choice.value}
                                    onChange={handleCheckboxChange}
                                    onKeyPress={disableSubmitOnEnter}
                                />
                                <span css={tw`align-middle`} dangerouslySetInnerHTML={{__html: choice.label}}/>
                            </label>
                            {
                                choice.value === otherKey ?
                                    <input
                                        css={tw`ml-2 block`}
                                        // @ts-ignore
                                        value={formInput[otherValueKey] || ''}
                                        type={'text'}
                                        onKeyPress={disableSubmitOnEnter}
                                        onChange={(e) => {
                                            const updatedResponse: any = {
                                                ...formInput,
                                                [noneKey]: false,
                                                [otherKey]: true,
                                                // @ts-ignore
                                                [otherValueKey]: e.target.value
                                            };
                                            setFormInput(updatedResponse);
                                            onResponse(updatedResponse);
                                        }}
                                    />
                                    : null
                            }
                        </div>
                    );
                })
            }
        </div>
    );
};
