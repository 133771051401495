import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { useGetSimResults } from '@/api/simulation/SimulationAPI';
import { getCurrentScreenForSimulation } from '@/components/navigation/navigationUtils';

export default () => {
    const location = useLocation();
    const history = useHistory();

    const simResults = useGetSimResults();

    useEffect(() => {
        console.log('location: ', location);
        const path = getCurrentScreenForSimulation(simResults);
        console.log('currentScreen path: ', path);

        if (location.pathname !== path || location.search) {
            history.push(path);
        }

    }, [ location ]);

    return null;
};
