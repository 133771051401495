import { CourseComponent, CourseGroup } from '@/api/types';
import _ from 'lodash';

export const getCourseSectionComponents = (courseGroup: CourseGroup): CourseComponent[] => {
    const componentTypes: CourseComponent[] = _.chain(courseGroup.classes)
        .map('courseComponent')
        .uniq()
        .value();
    return componentTypes;
};
