import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from '@/components/common';
import {
    CourseComponent,
    CourseClass,
    CourseClassExtract, SelectedCourse
} from '@/api/types';
import _ from 'lodash';
import tw from 'twin.macro';
import { AddCourseProps } from '@/components/search-results/SearchResultsContainer';
import { generateCourseClassExtract, parseCourseShortName } from '@/api/transformers';
import { InstructionModeName } from '@/constants/instructionModes';
import CourseComponentGroup from '@/components/course-components/CourseComponentGroup';

type ComponentClassIdMap = {[key in CourseComponent]?: string};

interface Props {
    isVisible: boolean;
    onClose: () => void;
    onAddOverride: (props: AddCourseProps) => void;
    addCourseProps: Partial<AddCourseProps> | null;
}

export default ({ addCourseProps = {}, isVisible, onClose, onAddOverride }: Props) => {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ selectedSectionsMap, setSelectedSectionsMap ] = useState<ComponentClassIdMap>({});

    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [ isVisible ]);

    const componentGroups: {componentType: CourseComponent, sections: CourseClass[]}[] = useMemo(() => {
        const courseGroup = addCourseProps?.courseGroup || {classes: []};

        // we need to filter for only the relevant instruction modes. For synchronous modes this
        // doesn't matter as much since one section is represented for both. However for OA modes we need to
        // explicitly filter for those sections.
        const selectedMode = addCourseProps?.selectedCourse?.sectionMode;
        if (!selectedMode) return [];

        let qualifyingSections: CourseClass[] = [];
        if (selectedMode === 'OA') {
            qualifyingSections = _.filter(courseGroup.classes, {instructionMode: 'OA'});
        } else {
            qualifyingSections = _.cloneDeep(courseGroup.classes);
            _.remove(qualifyingSections, {instructionMode: 'OA'});
        }

        return _.chain(qualifyingSections)
            .groupBy('courseComponent')
            .map((v, k) => {
                return {
                    componentType: k,
                    sections: v,
                } as {componentType: CourseComponent, sections: CourseClass[]};
            })
            .value();
    }, [ addCourseProps, addCourseProps?.courseGroup, addCourseProps?.selectedCourse?.sectionMode ]);

    useEffect(() => {
        const state: ComponentClassIdMap = componentGroups.reduce<{[key: string]: CourseClass | null}>((acc, cur) => {
            acc[cur.componentType] = null;
            return acc;
        }, {});

        if (addCourseProps?.selectedCourse?.selectedSections) {
            addCourseProps.selectedCourse.selectedSections.forEach(section => {
                // need to cast to string since html input values cast to string
                state[section.courseComponent] = section.classId.toString();
            });
        }
        setSelectedSectionsMap(_.cloneDeep(state));
    }, [ componentGroups, addCourseProps?.selectedCourse ]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let updated = _.cloneDeep(selectedSectionsMap);
        const { name, value, checked } = event.target;

        updated = {
            ...updated,
            [name]: value
        };

        console.log('onChange updated: ', updated);
        setSelectedSectionsMap(updated);
    };

    const selectedCourse = useMemo<SelectedCourse | undefined>(() => {
        return addCourseProps?.selectedCourse;
    }, [ addCourseProps, addCourseProps?.selectedCourse ]);

    const onSubmit = (e: React.FormEvent) => {
        setLoading(true);
        e.preventDefault();
        e.stopPropagation();

        const { selectedCourse, courseGroup } = addCourseProps || {};
        console.log('onSubmit selectedCourse: ', selectedCourse);
        console.log('onSubmit courseGroup: ', courseGroup);

        if (!selectedCourse || !courseGroup) return;

        const updatedSelectedCourse = _.cloneDeep(selectedCourse);
        const selectedSections: CourseClassExtract[] = [];
        for (const [ key, value ] of Object.entries(selectedSectionsMap)) {
            const courseClass = _.find(courseGroup.classes, {classId: parseInt(value)});
            if (courseClass) {
                selectedSections.push({
                    ...generateCourseClassExtract(courseGroup, courseClass as CourseClass),
                    sectionMode: selectedCourse.sectionMode,
                });
            } else {
                console.error('Unable to find class id: ', value);
            }

        }
        updatedSelectedCourse.selectedSections = selectedSections;

        onAddOverride({
            courseGroup: courseGroup,
            selectedCourse: updatedSelectedCourse,
            skipValidation: true
        });
        onClose();
    };

    useEffect(() => {
        console.log('selectedSectionsMap: ', selectedSectionsMap);
    }, [ selectedSectionsMap ]);

    if (!selectedCourse || !addCourseProps?.courseGroup) return null;

    return (
        <Modal visible={isVisible} onDismissed={onClose}>
            <form onSubmit={onSubmit}>
                <p css={tw`pb-4`}>Please choose the appropriate course components to go along with <strong>{`${parseCourseShortName(selectedCourse)} (${InstructionModeName[selectedCourse.sectionMode]})`}</strong>:</p>
                <div style={{
                    minHeight: '400px'
                }}
                >
                    {
                        componentGroups.map(o => {
                            return (
                                <CourseComponentGroup
                                    key={o.componentType}
                                    sectionMode={selectedCourse.sectionMode}
                                    courseGroup={addCourseProps.courseGroup}
                                    sections={o.sections}
                                    courseComponent={o.componentType}
                                    onChange={onChange}
                                    selectedClassId={selectedSectionsMap[o.componentType] || ''}
                                    startCollapsed={selectedCourse.selectedSections[0].courseComponent === o.componentType}
                                />
                            );
                        })
                    }
                </div>


                <div css={tw`mt-8 flex justify-between`}>
                    <Button type="button" onClick={onClose}>Cancel</Button>
                    <Button type="submit">Submit</Button>
                </div>
            </form>
        </Modal>
    );
};
