import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const BaseTableStyle = styled.table`
    ${tw`w-full mb-3`}

    table-layout: fixed;

    thead {
        th {
            ${tw`bg-cunylightblue border border-solid border-gray-400 text-left px-1 py-0 whitespace-no-wrap`}
            span {
                ${tw`text-cunyblue text-xs font-medium`}
            }
            
        }
    }
    tbody {
        td {
            ${tw`border border-solid border-gray-400 text-left px-1 text-xs font-normal align-top`}
        }
        
    }
`;
