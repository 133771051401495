import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { generateUUID } from '@/utils/utilities';

const TooltipIconStyle = styled.div`
    ${tw`inline-block align-middle`}

    svg {
        ${tw`text-blue-400 cursor-pointer text-lg ml-1`}
    }

    .tooltip {
        max-width: 80%;
        ${tw`shadow text-base`}

        &:after {
            // border-top-color: orange !important;
            // border-top-style: solid !important;
            // border-top-width: 6px !important;
        }
        
        .message {
            ${tw`whitespace-pre-wrap max-w-full text-base font-normal`}
        }
    }
`;

interface Props {
    message: string;
}

export default ({ message }: Props) => {

    const id = generateUUID();
    return (
        <TooltipIconStyle>
            <FontAwesomeIcon icon={faQuestionCircle} data-tip data-for={id}/>

            <ReactTooltip borderColor='#ccc' border className="tooltip" id={id} place="top" type="light" effect="solid">
                <span className={'message'}>{message}</span>
            </ReactTooltip>
        </TooltipIconStyle>
    );
};
