import React, { useState } from 'react';
import { atLeastOneLifeEvent, LifeEventsInput, LifeRatingsInput } from '@/components/survey/surveyQuestions';
import tw from 'twin.macro';
import { Button, Spinner } from '@/components/common';
import { useHistory } from 'react-router-dom';
import { useGetSurveyAnswers, useUpdateSurvey } from '@/api/simulation/SimulationAPI';
import { formatISO } from 'date-fns';
import { lifeEvents, lifeQuestions, otherLifeEvent } from '@/components/survey/life/lifeQuestions';
import { disableSubmitOnEnter } from '@/components/common/inputUtils';

export default () => {
    const history = useHistory();

    const survey = useGetSurveyAnswers();
    const updateSurvey = useUpdateSurvey();

    const [ loading, setLoading ] = useState<boolean>(false);
    const [ formInput, setFormInput ] = useState<LifeEventsInput>(survey.lifeEvents || {});

    // useEffect(() => {
    //     setFormInput(survey.lifeEvents);
    // }, [ survey ]);


    const onBack = () => {
        history.push('/survey/time2');
    };

    const onSubmit = (e: React.FormEvent) => {
        setLoading(true);
        console.log('onSubmit!');

        e.preventDefault();
        e.stopPropagation();

        const updatedLifeEvents = {
            ...formInput,
            submittedTs: formatISO(new Date())
        };

        const reconciledLifeRatings = {
            ...(survey.lifeRatings || {})
        };
        // reconcile ratings by removing any that are no longer selected
        lifeEvents.forEach(option => {
            if (!updatedLifeEvents[option.value as keyof LifeEventsInput]) {
                delete reconciledLifeRatings[option.value as keyof LifeRatingsInput];
            }
        });

        if (!updatedLifeEvents[otherLifeEvent.value as keyof LifeEventsInput]) {
            delete reconciledLifeRatings[otherLifeEvent.value as keyof LifeRatingsInput];
        }

        const updateProps = {
            lifeEvents: updatedLifeEvents,
            lifeRatings: reconciledLifeRatings
        };

        console.log('updateProps: ', updateProps);

        updateSurvey(updateProps).then(() => {
            console.log('updateSurvey resolved callback');

            setTimeout(() => {
                if (atLeastOneLifeEvent(formInput)) {
                    history.push('/survey/life2');
                } else {
                    history.push('/survey/major');
                }
            }, 200);

        }).catch(err => {
            console.log('err in updateSurvey err: ', err);
            console.error(err);
            setLoading(false);
        });
    };

    return (
        <div>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />

            <form onSubmit={onSubmit}>
                <h4 css={tw`mt-8`}>{lifeQuestions.events.label}</h4>
                <p css={tw`mb-3`}>Please select <span css={tw`font-bold`}>ALL</span> that apply.</p>

                <div css={tw`mt-6 mb-2 lg:w-4/6 md:w-5/6`}>

                    {
                        lifeEvents.map((lifeEvent) => {
                            return (
                                <div css={tw`flex flex-row mb-1`} key={lifeEvent.value}>
                                    <label>
                                        {/* Need to explicitly cast to boolean otherwise if checked is undefined then React throws the error about handling controlled vs uncontrolled inputs */}
                                        <input
                                            onKeyPress={disableSubmitOnEnter}
                                            css={tw`align-middle`}
                                            checked={
                                                // @ts-ignore
                                                !!formInput[lifeEvent.value]
                                            }
                                            type="checkbox"
                                            name={lifeEvent.value}
                                            onChange={(e) => {
                                                setFormInput({
                                                    ...formInput,
                                                    [e.target.name]: e.target.checked
                                                });
                                            }}
                                        />
                                        <span css={tw`align-middle`} dangerouslySetInnerHTML={{__html: lifeEvent.label}}/>
                                    </label>
                                </div>
                            );
                        })
                    }
                    <div css={tw`mt-3 ml-3 flex flex-row mb-1`}>
                        <span css={tw`align-middle mr-2`}>{otherLifeEvent.label}</span>
                        <input
                            onKeyPress={disableSubmitOnEnter}
                            css={tw`align-middle`}
                            type={'text'}
                            name={otherLifeEvent.value}
                            value={formInput[otherLifeEvent.value as keyof LifeEventsInput] as string || ''}
                            onChange={(e) => {
                                setFormInput({
                                    ...formInput,
                                    [e.target.name]: e.target.value
                                });
                            }}
                        />
                    </div>


                </div>




                <div css={tw`mt-8 flex justify-between`}>
                    <Button type="button" onClick={onBack}>Back</Button>
                    <Button type="submit">Next</Button>
                </div>
            </form>
        </div>
    );
};
