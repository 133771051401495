import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const MajorContainerStyle = styled.div`
    ${tw`w-full lg:px-12`}
`;

export const QuestionContainerStyle = styled.div`
    ${tw`mb-8`}

    > p:first-child {
        ${tw`font-medium`}
    }

    > :not(p) {
        ${tw`px-4`}
    }
`;
