import React, { useEffect } from 'react';
import { useGetSimResults } from '@/api/simulation/SimulationAPI';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { getCurrentScreenForSimulation } from '@/components/navigation/navigationUtils';

export default () => {
    const simResults = useGetSimResults();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const path = getCurrentScreenForSimulation(simResults);
        if (path === '/completed' &&
            location.pathname !== path &&
            location.pathname.indexOf('admin') === -1) {
            history.push(path);
        }
    }, [ location ]);

    return (
        <></>
    );
};
