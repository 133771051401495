import { Option } from '@/api/types';
import { generateUUID } from '@/utils/utilities';

export interface FiltersParams {
    filters?: ColumnArg[];
    grouping?: GroupOpEnum;
}
export interface QueryParams extends FiltersParams {
    offset?: number;
    limit?: number;
    order?: string;
}
export interface ColumnArg {
    key: string;
    value: string;
}

export interface ColumnFilter {
    // internal key for react
    _uid: string;
    column: Option | null;
    value: string;
}

export const generateEmptyColumnFilter = (): ColumnFilter => {
    return {
        _uid: generateUUID(),
        column: null,
        value: '',
    };
};

export enum GroupOpEnum {
    AND = 'AND',
    OR = 'OR',
}
export const groupOperators: Option<GroupOpEnum>[] = [
    {
        value: GroupOpEnum.AND,
        label: 'All of the following',
    },
    {
        value: GroupOpEnum.OR,
        label: 'Any of the following',
    },
];

export enum OperatorEnum {
    Equal = 'eq',
    NotEqual = 'ne',
    Contains = 'like',
    NotContains = 'nlike',
}

export const textOperators: Option<OperatorEnum>[] = [
    {
        value: OperatorEnum.Equal,
        label: 'Equals'
    },
    {
        value: OperatorEnum.NotEqual,
        label: 'Not Equals'
    },
    {
        value: OperatorEnum.Contains,
        label: 'Contains'
    },
    {
        value: OperatorEnum.NotContains,
        label: 'Does Not Contain'
    },
];

export const textColumns: Option[] = [
    {
        value: 'cohort',
        label: 'Cohort',
        type: 'text',
    },
    {
        value: 'student_id',
        label: 'Student ID',
        type: 'text',
    },
    {
        value: 'screener__registerIntent',
        label: 'Screener Register Intent',
        type: 'text',
    },
    {
        value: 'screener__summerRegisterIntent',
        label: 'Summer Register Intent',
        type: 'text',
    },
];


export const timestampColumns: Option[] = [
    {
        value: 'created_at',
        label: 'First Logged In',
        type: 'timestamp',
    },
    {
        value: 'last_logged_in',
        label: 'Last Logged In',
        type: 'timestamp',
    },
    {
        value: 'consent_ts',
        label: 'Consent',
        type: 'timestamp',
    },
    {
        value: 'screener__registerIntentTs',
        label: 'Screener',
        type: 'timestamp',
    },
    {
        value: 'screener__summerRegisterIntentTs',
        label: 'Summer Register',
        type: 'timestamp',
    },
    {
        value: 'available_times_submitted_ts',
        label: 'Available Times',
        type: 'timestamp',
    },
    {
        value: 'survey__work__submittedTs',
        label: 'Survey - Work',
        type: 'timestamp',
    },
    {
        value: 'survey__family__submittedTs',
        label: 'Survey - Family',
        type: 'timestamp',
    },
    {
        value: 'survey__timeQuality__submittedTs',
        label: 'Survey - Time1',
        type: 'timestamp',
    },
    {
        value: 'survey__timeQuantity__submittedTs',
        label: 'Survey - Time2',
        type: 'timestamp',
    },
    {
        value: 'survey__lifeEvents__submittedTs',
        label: 'Survey - Life1',
        type: 'timestamp',
    },
    {
        value: 'survey__lifeRatings__submittedTs',
        label: 'Survey - Life2',
        type: 'timestamp',
    },
    {
        value: 'survey__major__submittedTs',
        label: 'Survey - Major',
        type: 'timestamp',
    },
    {
        value: 'survey__demographics__submittedTs',
        label: 'Survey - Demographics',
        type: 'timestamp',
    },
    {
        value: 'alias__survey__demographics__submittedTs',
        label: 'Survey Submission',
        type: 'timestamp',
    },
    {
        value: 'payment_info_submitted_ts',
        label: 'Payment Info',
        type: 'timestamp',
    },
    {
        value: 'data__startedTs',
        label: 'Simulation - Started',
        type: 'timestamp',
    },
    {
        value: 'data__completedTs',
        label: 'Simulation - Completed',
        type: 'timestamp',
    },
    {
        value: 'stats__firstSearch',
        label: 'Class Search (First)',
        type: 'timestamp',
    },
    {
        value: 'stats__lastSearch',
        label: 'Class Search (First)',
        type: 'timestamp',
    },
];
