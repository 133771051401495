import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components/macro';

interface Props {
    label: string;
    value?: any;
    width?: number;
    render?: () => React.ReactNode;
}

interface PropertyInfoStyleProps {
    width?: number;
}
const PropertyInfoStyle = styled.div<PropertyInfoStyleProps>`
    ${tw`flex items-start mb-1`}
    span {
        ${tw`text-sm`}
    }
    span.label {
        ${tw`text-cunyblue font-medium whitespace-no-wrap`}
        min-width: ${props => props.width ? props.width : '120'}px;
        margin-right: 8px;
    }
`;

export const PropertyLabelStyle = styled.span`
    span {
        ${tw`text-cunyblue font-medium whitespace-no-wrap text-sm`}
    }
`;

export default ({ label, value, width, render }: Props) => {
    if (render) {
        return (
            <PropertyInfoStyle width={width}>
                <span className="label">{label}</span>
                <>
                    {render()}
                </>
            </PropertyInfoStyle>
        );
    }
    return (
        <PropertyInfoStyle width={width}>
            <span className="label">{label}</span>
            <span>{value}</span>
        </PropertyInfoStyle>
    );
};
