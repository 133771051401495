import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components/macro';
import SimulationDebugger from '@/components/simulation/SimulationDebugger';
import { simulationReminder } from '@/constants/instructions';

const ContainerStyle = styled.div`
    ${tw`w-full h-full min-h-screen`}
    
    ${tw`pt-4 pb-4 px-4 md:px-12 max-w-screen-lg`}
    
    input {
        ${tw`py-1 px-1`}
    }
`;

export default () => {
    return (
        <ContainerStyle>
            <p css={tw`text-center mb-4`}>Your response has been received. Thank you for completing the survey!</p>

            <p css={tw`text-center mb-4`}>{simulationReminder}</p>

            <SimulationDebugger/>
        </ContainerStyle>
    );
};

