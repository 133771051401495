import React, { CSSProperties, useEffect } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components/macro';
import _ from 'lodash';

interface Props {
    label: string;
    htmlLabel?: boolean;
    name: string;
    required?: boolean;
    value: number | '';
    defaultValue?: number;
    min: number;
    max: number;
    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    onChange: (newValue: number) => void;
}

const ContainerStyle = styled.div`
    ${tw`flex items-center py-3`}
    &:not(:last-child) {
        ${tw`border-0 border-solid border-b border-gray-300`}
    }
    
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
`;

export default ({ label, htmlLabel = false, name, onChange, required, value, defaultValue, min, max, containerStyle = {}, labelStyle = {}, inputStyle = {} }: Props) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = _.parseInt(event.target.value);
        onChange(newValue);
    };

    useEffect(() => {
        if (defaultValue) {
            onChange(defaultValue);
        }
    }, [ defaultValue ]);

    return (
        <ContainerStyle style={containerStyle}>
            {
                htmlLabel ?
                    <p css={tw`font-normal mr-2`} style={labelStyle} dangerouslySetInnerHTML={{__html: label}}/>
                    :
                    <p css={tw`font-normal mr-2`} style={labelStyle}>{label}</p>
            }

            <input
                style={inputStyle}
                required={required}
                css={tw`mt-0 mr-2 py-2 px-2`}
                type="number"
                min={min}
                max={max}
                name={name}
                value={isNaN(value as number) ? '' : Number(value).toString()}
                onChange={handleChange}
            />
        </ContainerStyle>
    );
};
