import { snakeCase } from 'snake-case';

export const convertToSnakeCase = (obj: any) => {
    const res = {} as any;
    Object.keys(obj).forEach(key => {
        res[snakeCase(key)] = obj[key];
    });
    return res;
};

export const isJWTExpiredError = (error: any): boolean => {
    return error?.message === 'JWTExpired';
};
