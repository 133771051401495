import { termNameYearLowercase } from '@/constants/terms';
import { Option } from '@/api/types';

export const lifeQuestions = {
    events: {
        label: `For each of the following life events, please indicate if you are currently experiencing them, or expect to experience them during the ${termNameYearLowercase}:`
    },
    ratings: {
        label: 'For each of the life events that you checked on the previous page, please rate how strongly they impact the time or energy that you have for your college studies:'
    }
};


export const lifeEvents: Option[] = [
    {
        value: 'life1',
        label: 'serious illness/injury/disability, including mental health (your own)',
    },
    {
        value: 'life2',
        label: 'serious illness/injury/disability, including mental health (your family)',
    },
    {
        value: 'life3',
        label: 'caring for sick or disabled family member or close friend',
    },
    {
        value: 'life4',
        label: 'death in the family',
    },
    {
        value: 'life5',
        label: 'birth of a child',
    },
    {
        value: 'life6',
        label: 'childcare issues',
    },
    {
        value: 'life7',
        label: 'divorce or breakup of a long-term serious relationship',
    },
    {
        value: 'life8',
        label: 'starting a major relationship',
    },
    {
        value: 'life9',
        label: 'planning a wedding/getting married',
    },
    {
        value: 'life10',
        label: 'moving house',
    },
    {
        value: 'life11',
        label: 'job stressors',
    },
    {
        value: 'life12',
        label: 'unemployment',
    },
    {
        value: 'life13',
        label: 'job change',
    },
    {
        value: 'life14',
        label: 'financial issues (including difficulty paying bills, rent, or mortgage)',
    },
    {
        value: 'life15',
        label: 'housing insecurity (difficulty finding a place to live, or homelessness)',
    },
    {
        value: 'life16',
        label: 'food insecurity (difficulty finding enough food to eat, for you or your family)',
    },
    {
        value: 'life17',
        label: 'domestic violence',
    },
    {
        value: 'life18',
        label: 'was victim of a crime',
    },
    {
        value: 'life19',
        label: 'legal trouble or proceedings',
    },
    {
        value: 'life20',
        label: 'immigration issues, or navigating the immigration process',
    },
];

export const otherLifeEvent = {
    value: 'lifeOther',
    label: 'other:',
};

export const describeImpactQuestion = {
    id: 'describeImpact',
    label: 'Please briefly describe the impact these life events have on the time or energy you have for your college studies [optional]:',
    type: 'text'
};
