import React from 'react';
import { Fade } from './Fade';
import { BaseModalProps } from './Modal';
import tw from 'twin.macro';

export type OverlayProps = BaseModalProps & {
    children: React.ReactNode;
    withContainer?: boolean;
}

const background = {
    // dark: 'rgba(0, 0, 0, 0.8)',
    dark: 'rgba(5, 12, 31, 0.75)',
    light: 'rgba(255, 255, 255, 0.8)'
};

const Overlay = ({ visible, onDismissed, children, withContainer = true, overlayMode = 'dark', ...props }: OverlayProps) => (
    <Fade timeout={150} in={visible} unmountOnExit onExit={() => onDismissed && onDismissed()} {...props}>
        {withContainer ?
            <div
                css={tw`top-0 left-0 fixed w-full h-screen flex flex-col items-center justify-center`}
                style={{
                    background: background[overlayMode],
                    zIndex: 9999,
                }}
            >
                {children}
            </div>
            :
            children
        }
    </Fade>
);

export { Overlay };
