import tw from 'twin.macro';
import { Button, Modal } from '@/components/common';
import React, { useEffect, useState } from 'react';
import { SelectedCourse } from '@/api/types';
import ConflictingClassError from '@/components/search-results/add-class-error/ConflictingClassError';
import {
    ShoppingCartError,
    UnsatisfiedRequisiteError
} from '@/components/shopping-cart/api/errors';
import transferError from '@/utils/transferError';
import { differenceInHours } from 'date-fns';
import { formatShortTimestamp } from '@/components/simulation/SimulationDebugger';
import { useHistory } from 'react-router-dom';
import { AddCourseProps } from '@/components/search-results/SearchResultsContainer';
import { useRole } from '@/api/AuthService';

interface Props {
    isVisible: boolean;
    error: ShoppingCartError | undefined;
    onReplace: (classToAdd: SelectedCourse, classToRemove: SelectedCourse) => void;
    onAddOverride: (props: AddCourseProps) => void;
    onCancel: () => void;
}

// ConflictingScheduleModal is really also the handler for all shopping cart related errors. (e.g. unsatisfied requisites)
export default ({ isVisible, error, onReplace, onAddOverride, onCancel }: Props) => {
    const history = useHistory();

    const role = useRole();

    const [ errorMsg, setErrorMsg ] = useState<string>('');
    const [ showModal, setShowModal ] = useState<boolean>(false);

    useEffect(() => {
        setShowModal(isVisible);
    }, [ isVisible ]);

    const handleClose = () => {
        setShowModal(false);
        onCancel();
    };

    const handleReplace = (c1: SelectedCourse, c2: SelectedCourse): void => {
        onReplace(c1, c2);
    };

    const renderIgnorableRequisiteError = (error: UnsatisfiedRequisiteError) => {
        return (
            <>
                <p css={tw`mb-2`}>According to our records, <strong>this course has prerequisites</strong> (requirements prior to taking this course) or <strong>corequisites</strong> (courses that must be taken at the same time):</p>

                <p css={tw`mb-4`}>{error.message}</p>

                <p css={tw`mb-2`}>If you are sure that you have <strong>already met the prerequisites</strong> for this course, <strong>and</strong> you will <strong>register for any required corequisites this term</strong>, click here to add the course to your cart.</p>

                <div css={tw`text-center mb-4`}>
                    <Button onClick={() => onAddOverride({
                        courseGroup: error.courseGroup,
                        selectedCourse: error.selectedCourse,
                        skipValidation: true
                    })}
                    >Add Course
                    </Button>
                </div>

                <p css={tw`mb-2`}>If you have <strong>NOT</strong> yet met the required prerequisites for the course, or you will <strong>NOT</strong> enroll in the required corequisites this term, please click here to choose a different course.</p>

                <div css={tw`mt-4 text-center`}>
                    <Button onClick={() => {
                        // const updatedFilters = {
                        //     ...generateEmptyClassSearchForm(),
                        //     ...filters,
                        // };
                        // setFilters(updatedFilters);
                        history.push('/search');

                    }}
                    >New Course Search
                    </Button>
                </div>
            </>
        );
    };

    const renderError = () => {
        if (error?.unsatisfiedCourseRequisites) {
            return renderIgnorableRequisiteError(error.unsatisfiedCourseRequisites);
        }
        if (error?.conflictingClasses) {
            return <ConflictingClassError conflictingClasses={error.conflictingClasses} onCancel={handleClose} onReplace={handleReplace}/>;
        }
        return <p css={tw`text-red-500 whitespace-pre-line`} dangerouslySetInnerHTML={{__html: errorMsg}}/>;
    };

    const renderTransferError = () => {
        if (role !== 'dev') {
            return null;
        }

        if (transferError.lastEncountered) {
            const hourDiff = differenceInHours(transferError.lastEncountered, new Date());
            if (hourDiff < 1) {
                return <p css={tw`mt-4 text-red-500 whitespace-pre-line`}>Unable to process Transfer Rules. Last Observed: {formatShortTimestamp(transferError.lastEncountered)}</p>;
            }
        }
        return null;
    };

    return (
        <Modal visible={showModal} onDismissed={handleClose}>
            {renderError()}
            {renderTransferError()}
        </Modal>
    );

};
