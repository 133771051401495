import React, { useEffect, useState } from 'react';
import { atLeastOneLifeEvent, LifeEventsInput, LifeRatingsInput } from '@/components/survey/surveyQuestions';
import tw from 'twin.macro';
import { Button, TextArea, Spinner } from '@/components/common';
import { useHistory } from 'react-router-dom';
import { useGetSurveyAnswers, useUpdateSurvey } from '@/api/simulation/SimulationAPI';
import { formatISO } from 'date-fns';
import {
    describeImpactQuestion,
    lifeEvents,
    lifeQuestions,
    otherLifeEvent
} from '@/components/survey/life/lifeQuestions';
import { Option } from '@/api/types';
import SliderQuestion from '@/components/survey/SliderQuestion';
import { Redirect } from 'react-router';

export default () => {
    const history = useHistory();

    const survey = useGetSurveyAnswers();
    const updateSurvey = useUpdateSurvey();

    const [ loading, setLoading ] = useState<boolean>(false);
    const [ formInput, setFormInput ] = useState<LifeRatingsInput>(survey.lifeRatings || {});
    const [ selectedLifeEvents, setSelectedLifeEvents ] = useState<Option[]>([]);

    useEffect(() => {
        if (survey?.lifeEvents) {

            if (atLeastOneLifeEvent(survey.lifeEvents)) {
                const selectedEvents: Option[] = [];

                const reconciledRatings = { ...formInput };

                lifeEvents.forEach(option => {
                    if (survey.lifeEvents[option.value as keyof LifeEventsInput]) {
                        selectedEvents.push(option);

                        if (!reconciledRatings[option.value as keyof LifeRatingsInput]) {
                            // @ts-ignore
                            reconciledRatings[option.value as keyof LifeRatingsInput] = 50;
                        }
                    }
                });

                setSelectedLifeEvents(selectedEvents);
                setFormInput(reconciledRatings);

            } else {
                history.push('/survey/life1');
            }
        }
    }, [ survey?.lifeEvents ]);

    const onBack = () => {
        history.push('/survey/life1');
    };

    const onSubmit = (e: React.FormEvent) => {
        setLoading(true);
        console.log('onSubmit!');

        e.preventDefault();
        e.stopPropagation();

        updateSurvey({
            lifeRatings: {
                ...formInput,
                submittedTs: formatISO(new Date())
            },
        }).then(() => {
            setTimeout(() => {
                history.push('/survey/major');
            }, 200);

        })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    };

    if (!survey.lifeEvents?.submittedTs) {
        return <Redirect to="/survey/life1" />;
    }

    return (
        <div>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />

            <form onSubmit={onSubmit}>
                <h4 css={tw`mt-8`}>{lifeQuestions.ratings.label}</h4>

                <div css={tw`mt-6 lg:w-4/6 md:w-5/6`}>

                    <div css={tw`flex flex-row justify-end`}>
                        <div css={tw`w-1/2 flex flex-row justify-between`}>
                            <span>no impact</span>
                            <span>strong impact</span>
                        </div>

                    </div>

                    {
                        selectedLifeEvents.map((lifeEvent) => {
                            return (
                                <SliderQuestion
                                    // minLabel={'no impact'}
                                    // maxLabel={'strong impact'}
                                    key={lifeEvent.value}
                                    option={lifeEvent}
                                    onChange={(value) => {
                                        setFormInput({
                                            ...formInput,
                                            [lifeEvent.value]: value
                                        });
                                    }}
                                    value={formInput[lifeEvent.value as keyof LifeRatingsInput] as number}
                                />
                            );
                        })
                    }

                    {
                        survey.lifeEvents.lifeOther ?
                            <SliderQuestion
                                // minLabel={'no impact'}
                                // maxLabel={'strong impact'}
                                key={otherLifeEvent.value}
                                option={{
                                    ...otherLifeEvent,
                                    label: survey.lifeEvents.lifeOther
                                }}
                                onChange={(value) => {
                                    setFormInput({
                                        ...formInput,
                                        [otherLifeEvent.value]: value
                                    });
                                }}
                                value={formInput[otherLifeEvent.value as keyof LifeRatingsInput] as number}
                            />
                            : null
                    }

                    <div css={tw`mt-4`}>
                        <p css={tw`text-left mb-3`}>{describeImpactQuestion.label}</p>
                        <TextArea
                            css={tw`text-base`}
                            placeholder={''}
                            name={describeImpactQuestion.id}
                            value={formInput[describeImpactQuestion.id as keyof LifeRatingsInput]}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                setFormInput({
                                    ...formInput,
                                    [event.target.name]: event.target.value
                                });
                            }}
                            rows={2}
                        />
                    </div>
                </div>

                <div css={tw`mt-8 flex justify-between`}>
                    <Button type="button" onClick={onBack}>Back</Button>
                    <Button type="submit">Next</Button>
                </div>
            </form>
        </div>
    );
};
