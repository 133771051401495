import React, { useEffect, useMemo, useState } from 'react';
import { SimulationQuestion, } from '@/constants/questions';
import _ from 'lodash';
import tw from 'twin.macro';
import { Button } from '@/components/common';
import { QuestionResponse } from '@/store/simulation/types';
import { formatISO } from 'date-fns';

interface Props {
    onSubmitResponse: (response: QuestionResponse) => void;
    question: SimulationQuestion | null
}

// Reason question should come properly formatted via usePendingReasonQuestion in SimulationContainer
// this is so that we can optimize for performance
export default ({ onSubmitResponse, question }: Props) => {

    const [ otherReason, setOtherReason ] = useState<string>('');
    const [ checkedItems, setCheckedItems ] = useState<{ [key: string]: boolean }>({});
    const [ selectedRadio, setSelectedRadio ] = useState<string>('');

    const selectedReasons = useMemo<string[]>(() => {
        const reasons: string[] = _.keys(_.pickBy(checkedItems));
        if (selectedRadio) {
            reasons.push(selectedRadio);
        }
        return reasons;
    }, [ checkedItems, selectedRadio ]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        if (target.name === 'None') {
            setCheckedItems({
                [target.name]: target.checked
            });
        } else {
            setCheckedItems({
                ...checkedItems,
                None: false,
                [target.name]: target.checked
            });
        }
    };

    const handleRadioChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRadio(target.value);
    };

    const handleSubmit = () => {
        if (!question) return null;

        const reasons = _.cloneDeep(selectedReasons);
        if (otherReason) {
            reasons.push(`Other: ${otherReason}`);
        }

        const reasonResponse: QuestionResponse = {
            questionId: question.id,
            answer: reasons,
            submittedTs: formatISO(new Date()),
        };

        // Need to ensure state is cleared because selectedReasons relies on useMemo which causes cache issues when
        // going to the next question.
        setCheckedItems({});
        setSelectedRadio('');
        setOtherReason('');
        onSubmitResponse(reasonResponse);
    };

    useEffect(() => {
        setCheckedItems({});
    }, [ question ]);

    const renderCheckboxes = () => {
        return (
            <div>
                <p>Please select <span css={tw`font-bold`}>ALL</span> that apply.</p>
                <ul css={tw`relative list-none p-0`}>
                    {question!.choices.map(choice => {
                        if (choice.value === 'Other') {
                            return (
                                <li css={tw`mb-2`} key={choice.value}>
                                    <label>
                                        <span>Other: </span>
                                        <input type="text" onChange={(e) => setOtherReason(e.target.value)}/>
                                    </label>

                                </li>
                            );
                        } else {
                            return (
                                <li css={tw`mb-2`} key={choice.value}>
                                    <label>
                                        {/* Need to explicitly cast to boolean otherwise if checked is undefined then React throws the error about handling controlled vs uncontrolled inputs */}
                                        <input css={tw`align-middle`} checked={!!checkedItems[choice.value]} type="checkbox" name={choice.value} onChange={handleCheckboxChange}/>
                                        <span css={tw`align-middle`} dangerouslySetInnerHTML={{__html: choice.label}}/>
                                    </label>
                                </li>
                            );
                        }
                    })}
                </ul>
            </div>
        );
    };

    const renderRadios = () => {
        return (
            <div>
                <p>Please select the <span css={tw`font-bold`}>one response</span> that BEST explains your decision:</p>
                <ul css={tw`relative list-none p-0`}>
                    {question!.choices.map(choice => {
                        return (
                            <li css={tw`mb-2`} key={choice.value}>
                                <label>
                                    <input value={choice.value} type="radio" name="a" onChange={handleRadioChange}/>
                                    <span css={tw`align-middle`} dangerouslySetInnerHTML={{__html: choice.label}}/>
                                </label>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    if (!question) return null;

    return (
        <div>
            <p dangerouslySetInnerHTML={{__html: question.label}}/>
            {
                question.type === 'radio' ?
                    renderRadios()
                    : renderCheckboxes()
            }

            <div css={tw`text-right pr-3`}>
                <Button disabled={!selectedReasons.length && !otherReason} onClick={() => handleSubmit()}>Next</Button>
            </div>
        </div>
    );
};
