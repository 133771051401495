const VERSION = 'v20240403.1';

let local = false;
let apiUrl, graphUrl;
if (window.origin.indexOf('//localhost:') !== -1) {
    apiUrl = 'http://localhost:7272/api';
    local = true;
    graphUrl = 'https://graph.cunyresearch.com/v1/graphql';
} else if (window.origin.indexOf('//192') !== -1) {
    apiUrl = 'http://192.168.1.9:7272/api';
    local = true;
    graphUrl = 'https://graph.cunyresearch.com/v1/graphql';
} else {
    apiUrl = 'https://api.cunyresearch.com/api';
    graphUrl = 'https://graph.cunyresearch.com/v1/graphql';
}

interface Config {
    version: string;
    local: boolean;
    apiUrl: string;
    graphUrl: string;
    debug: boolean;
    contactEmail: string;
    sentry: {
        dsn: string;
        enabled?: boolean;
        environment?: string;
    };
}

const sentryEnvironment = process.env.NODE_ENV || 'development';
const config: Config = {
    version: VERSION,
    local,
    apiUrl,
    graphUrl,
    debug: sentryEnvironment === 'development',
    contactEmail: 'elearningresearch@bmcc.cuny.edu',
    sentry: {
        dsn: 'https://10980cc4f98945e2a45cfb280c7b0219@o369152.ingest.sentry.io/5738019',
        enabled: sentryEnvironment !== 'development',
        environment: sentryEnvironment,
    },
};

export default config;
