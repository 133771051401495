import React, { useEffect, useState } from 'react';
import { theme } from 'twin.macro';
import { Bar } from 'react-chartjs-2';
import { format as dateFormat, parse } from 'date-fns';
import { ChartOptions, ChartData, TooltipItem } from 'chart.js';
import { chartColors, defaultTooltipOptions } from '../../chartUtils';
import styled from 'styled-components/macro';
import { AggregateStats } from '@/api/simulation/getResultsAggregate';
import _ from 'lodash';

interface Props {
    data: AggregateStats[];
}

const DailyResponsesChartContainerStyle = styled.div`
    position: relative;
    width: 550px;
    height: 250px;
    flex-shrink: 0;
    @media (min-width: 1301px) and (max-width: 1350px){
        width: 450px;
    }
`;

export default ({ data }: Props) => {
    const [ chartData, setChartData ] = useState<ChartData<'bar', any[], string>>({
        datasets: [
            {
                yAxisID: 'y',
                type: 'bar',
                label: 'Responses',
                data: [],
                backgroundColor: chartColors[2].normal,
                borderColor: chartColors[2].normal,
                hoverBackgroundColor: chartColors[2].normal,
                hoverBorderColor: chartColors[2].normal,
            },
        ],
    });

    const chartOptions: ChartOptions<'bar'> = {
        responsive: false,
        aspectRatio: 16 / 9,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                ...defaultTooltipOptions,
                mode: 'index',
                intersect: false,
                displayColors: false,
                bodySpacing: 10,
                itemSort: (itemA: TooltipItem<'bar'>, itemB: TooltipItem<'bar'>): number => {
                    return (itemB?.datasetIndex || 0) - (itemA?.datasetIndex || 0);
                },
                callbacks: {
                    title: function (tooltipItem) {
                        let label = '';
                        const format = 'EEE MMM d';

                        if (tooltipItem[0].label) {
                            const datetime = parse(tooltipItem[0].label, 'MMM d, yyyy, h:mm:ss a', new Date());
                            label = dateFormat(datetime, format) as string;
                        }
                        return label;
                    },
                    labelTextColor: function () {
                        return theme`colors.gray.700`.toString();
                    },
                    labelColor: function () {
                        return {
                            borderColor: 'rgb(0, 0, 255)',
                            backgroundColor: 'rgb(255, 0, 0)',
                            borderWidth: 2,
                            borderDash: [ 2, 2 ],
                            borderRadius: 2,
                        };
                    },
                    label: function (this, data): string {
                        let label = data.dataset ? data.dataset.label : '';
                        if (label) {
                            label += ': ';
                        }
                        const rounded = (Math.round(data.parsed.y * 100) / 100);
                        return `${label}${rounded}`;
                    },
                },
            },
        },
        scales: {
            x: {
                offset: true,
                display: true,
                grid: {
                    display: false,
                },
                type: 'timeseries',
                time: {
                    displayFormats: {
                        day: 'M/d',
                    },
                    unit: 'day',
                },
                ticks: {
                    source: 'auto',
                },
                bounds: 'ticks',
            },
            y: {
                // num responses bar chart
                display: true,
                position: 'left',
                grid: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: false,
                },
                ticks: {
                    major: {
                        enabled: true,
                    },
                },
                title: {
                    display: false,
                    text: '# of Responses',
                    font: { weight: 'normal', size: 16, family: 'brandon-grotesque,sans-serif', style: 'normal', lineHeight: 24 },
                },
            },
            y2: {
                display: false,
                position: 'right',
                suggestedMax: 10,
                suggestedMin: 0,
                grid: {
                    display: false,
                },
                ticks: {
                    major: {
                        enabled: true,
                    },
                    maxTicksLimit: 10,
                    stepSize: 1,
                },
            },
        },
    };

    useEffect(() => {
        const updatedChartData = _.cloneDeep(chartData);
        updatedChartData.datasets[0].data = data.map(o => ({ x: parse(o.id, 'yyyy-MM-dd', new Date()), y: o.count }));
        setChartData(updatedChartData);
    }, [ data ]);

    return (
        <DailyResponsesChartContainerStyle>
            <Bar
                width={550}
                height={250}
                data={chartData}
                options={chartOptions}
            />
        </DailyResponsesChartContainerStyle>
    );
};
