import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const SearchResultsContainerStyle = styled.div`
    width: 100%;
    ${tw`pb-4`}

    @media (min-width: 650px) {
        min-width: 650px;
        max-width: 800px;
        ${tw`px-4 `}
    }

    > h3 {
        font-size: 16px;
        color: rgb(74,89,140);
        margin-bottom: 17px;
        padding-right: 6px;

        ${tw`font-normal text-lg`};
    }
`;

export const CourseGroupItemStyle = styled.div`
    ${tw`mb-6`}
    
    .header {
        ${tw`text-white bg-blue-600 py-1 flex items-center px-2`}
        
        a {
            width: 20px;
            ${tw`px-1 select-none cursor-pointer hover:opacity-75`}
        }
    }
    h3 {
        ${tw`text-white text-sm font-normal`}   
    }
`;

export const CourseClassItemStyle = styled.table`
    ${tw`w-full mb-3`}

    table-layout: fixed;

    thead {
        th {
            ${tw`bg-cunylightblue border border-solid border-gray-400 text-left px-1 py-0 whitespace-no-wrap`}
            span {
                ${tw`text-cunyblue text-xs font-medium`}
            }
        }
        @media (max-width: 649px) {
            th:nth-last-child(2) {
                border-right: none;
            }
            th:last-child {
                border-left: none;
            }
        }
    }
    tbody {
        td {
            ${tw`text-left px-1 text-xs font-normal align-top`}
            a {
                ${tw`text-blue-500 cursor-pointer underline hover:text-blue-700`}
            }
        }

        tr:hover {
            td {
                background-color: #efefef;
            }
        }

        td:last-child {
            ${tw`text-right`}
            @media (min-width: 650px) {
                ${tw`text-center`}
            }
            
        }
    }
    

    th:nth-child(1) {
        width: 60px;
    }
    th:nth-child(2) {
        width: 130px;
    }
    th:nth-child(3) {
        width: 150px;
    }
    th:nth-child(4) {
        width: 100%;
    }
    
    @media (max-width: 650px) {
        th:nth-child(2) {
            width: 65px;
        }
        th:nth-child(3) {
            width: 90px;
        }
    }
`;

export const RequisiteDetailContainerStyle = styled.div`
    width: 80%;
    margin: 0 auto;
    padding-left: 2rem;
   
    
    .line-item {
        ${tw`text-left mb-2 flex`}

        .numbering {
            width: 20px;
            display: inline-block;
            flex-shrink: 0;
            // padding-left: 2rem;
 
            ${tw`font-medium`}
        }

        > p {
            text-indent: -2rem;
            padding-left: 2rem;
        }
    
    }
`;
