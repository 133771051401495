import { GET_SIM_RESULTS_BY_PK, SimulationResults, UPDATE_SIM_RESULTS } from '@/api/simulation/SimulationAPI';
import graphClient from '@/api/graph';
import { convertToSnakeCase } from '@/api/graphUtils';

export default async (studentId: string, props: Partial<SimulationResults>): Promise<SimulationResults> => {
    const { data } = await graphClient.mutate({
        mutation: UPDATE_SIM_RESULTS,
        variables: {
            id: studentId,
            data: convertToSnakeCase(props)
        },
        fetchPolicy: 'network-only',
        refetchQueries: [ { query: GET_SIM_RESULTS_BY_PK, variables: {id: studentId} } ],
        awaitRefetchQueries: true,
    });
    return data?.results || {};
};
