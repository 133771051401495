import React, { useRef, useState } from 'react';
import http from '@/api/http';
import { Spinner } from '../common';
import { BaseButtonStyle } from '@/components/dashboard/styled';
import { QueryParams } from '@/components/dashboard/components/filter-bar/filterParams';

interface Props {
    params: QueryParams
}

const ExportResultsButton = ({ params }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [ loading, setLoading ] = useState(false);

    const exportBlobData = (data: any) => {
        const blob = new Blob([ data ], { type: 'text/csv' });
        const url = window.URL?.createObjectURL
            ? window.URL.createObjectURL(blob)
            : window.webkitURL.createObjectURL(blob);

        if (!ref.current) return;

        const element = document.createElement('a');
        element.style.display = 'none';
        element.href = url;
        element.setAttribute('download', 'cuny_sim_results.csv');

        // Safari supports the download attribute to avoid a popup blocker warning
        // when downloading this. Other browsers will not, so just check if undefined.
        if (typeof element.download === 'undefined') {
            element.setAttribute('target', '_blank');
        }

        ref.current.appendChild(element);
        element.click();

        requestAnimationFrame(() => {
            if (ref.current) {
                ref.current.removeChild(element);
            }
            window.URL.revokeObjectURL(url);
        });
    };

    const onExportResults = () => {
        setLoading(true);
        http.post('/results/export', params, {
            responseType: 'blob',
        })
            .then(response => {
                exportBlobData(response.data);
                setTimeout(() => {
                    setLoading(false);
                }, 250);
            })
            .catch(error => {
                // alert(getErrorFromResponse(error));
                console.error('onExportResults err: ', error);
                setLoading(false);
            });
    };

    return (
        <div ref={ref}>
            <Spinner.Overlay visible={loading}/>
            <BaseButtonStyle type={'button'} onClick={onExportResults}>
                Export Results
            </BaseButtonStyle>
        </div>
    );
};

export default ExportResultsButton;
