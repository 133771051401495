import React, { useEffect, useRef } from 'react';
import tw from 'twin.macro';
import Header from '@/components/Header';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import ClassSearchContainer from '@/components/class-search/ClassSearchContainer';
import SearchResultsContainer from './search-results/SearchResultsContainer';
import ClassDetailsContainer from './class-detail/ClassDetailsContainer';
import SurveyContainer from './survey/SurveyContainer';
import ShoppingCartContainer from './shopping-cart/ShoppingCartContainer';
import SimulationContainer from './simulation/SimulationContainer';
import AvailabilityContainer from '@/components/preferences/AvailabilityContainer';
import ConsentContainer from '@/components/consent/ConsentContainer';
import PaymentsContainer from '@/components/payments/PaymentsContainer';
import CompletedContainer from '@/components/CompletedContainer';
import SimulationListener from '@/components/SimulationListener';
import EnrollmentHistoryContainer from '@/components/enrollment-history/EnrollmentHistoryContainer';
import Footer from '@/components/Footer';
import DegreeContainer from '@/components/degree/DegreeContainer';
import simulationRedirect from '@/components/simulation/simulationRedirect';
import ScreenerContainer from '@/components/screener/ScreenerContainer';
import SummerPlanContainer from '@/components/screener/SummerPlanContainer';
import DashboardContainer from '@/components/dashboard/DashboardContainer';
import requireAdmin from '@/components/dashboard/requireAdmin';
import NavigationRouter from '@/components/navigation/NavigationRouter';
import VersionInfo from '@/components/VersionInfo';
import AccountsContainer from '@/components/dashboard/AccountsContainer';
import ScreenerTransferContainer from '@/components/screener/ScreenerTransferContainer';
import PretendScheduleContainer from '@/components/screener/PretendScheduleContainer';

export default () => {
    const prevLocation = useRef<string>('');
    const location = useLocation();

    useEffect(() => {
        if (prevLocation.current !== location.pathname) {
            window.scrollTo(0, 0);
            prevLocation.current = location.pathname;
        }

    }, [ location ]);

    return (
        <div>
            <SimulationListener/>

            <Header/>

            <div css={tw`pt-4 pb-4 px-4`}>
                <VersionInfo/>


                <Switch>
                    <Route path="/admin/accounts" component={requireAdmin(AccountsContainer)}/>
                    <Route path="/admin" component={requireAdmin(DashboardContainer)}/>


                    <Route path="/screener" component={ScreenerContainer}/>
                    <Route path="/screener-transfer" component={ScreenerTransferContainer}/>
                    <Route path="/consent" component={ConsentContainer}/>
                    <Route path="/summer" component={SummerPlanContainer}/>
                    <Route path="/availability" component={AvailabilityContainer} />
                    <Route path="/simulation-notice" component={PretendScheduleContainer} />

                    <Route path="/class/:classId" exact component={ClassDetailsContainer} />

                    {/* <Route path="/enrollment-history" component={EnrollmentHistoryContainer} /> */}
                    {/* <Route path="/degree" component={DegreeContainer} /> */}

                    <Route path="/cart" component={simulationRedirect(ShoppingCartContainer)} />
                    <Route path="/search" component={ClassSearchContainer} />
                    <Route path="/results" component={SearchResultsContainer} />

                    <Route path="/sim" component={SimulationContainer} />
                    <Route path="/replacement-search" component={ClassSearchContainer} />
                    <Route path="/replacement-results" component={SearchResultsContainer} />

                    <Route path="/survey" component={SurveyContainer}/>
                    <Route path="/payment" component={PaymentsContainer} />
                    <Route path="/completed" component={CompletedContainer} />

                    <Route path="/" component={NavigationRouter} />

                    <Redirect to={'/'} />
                </Switch>

                <Footer/>
            </div>
        </div>
    );
};
