import { DividerStyle, formatTimestamp, TimestampStyle } from '@/components/simulation/SimulationDebugger';
import React, { useMemo } from 'react';
import { convertAvailableTimesToMap, DayName } from '@/api/transformers';
import tw from 'twin.macro';
import { useGetSimResults } from '@/api/simulation/SimulationAPI';

export default () => {
    const { availableTimes, availableTimesSubmittedTs } = useGetSimResults();

    const availableDays = useMemo<{day: string, slots: string[]}[]>(() => {
        const mapped = convertAvailableTimesToMap(availableTimes);

        const arr: {day: string, slots: string[]}[] = [];
        for (const key in mapped) {
            arr.push({
                day: key,
                slots: mapped[key]
            });
        }
        return arr;

    }, [ availableTimes ]);
    return (
        <DividerStyle>
            <div css={tw`flex items-center`}>
                <h4 css={tw`mr-2`}>Available Times</h4>
                <TimestampStyle>Submitted: {formatTimestamp(availableTimesSubmittedTs)}</TimestampStyle>
            </div>

            {
                availableDays.map(day => {
                    return (
                        <div key={day.day}>
                            <span css={tw`text-sm`}>{DayName[day.day]}: </span>
                            <span css={tw`text-sm`}>{day.slots.join(', ')}</span>
                        </div>

                    );
                })
            }
        </DividerStyle>
    );
};
