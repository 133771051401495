class BroadcastManager {

    _bc: BroadcastChannel;

    browserSupported: boolean;

    constructor() {
        try {
            // Safari seems to crash the entire website even just for checking whether a "variable" is truthy...
            if (BroadcastChannel) {
                this.browserSupported = true;
            }

        } catch (err) {
            this.browserSupported = false;
            console.error('Current browser does not support BroadcastChannel');
        }

    }

    init() {
        if (this._bc) {
            this.cleanup();
        }

        if (this.browserSupported) {
            this._bc = new BroadcastChannel('cuny');
            this._bc.onmessage = function(ev) {
                console.log('bc heard message: ', ev);
            };
        }
    }

    onMessage(listener: (ev: MessageEvent) => void) {
        if (this.browserSupported) {
            this._bc.onmessage = listener;
        }

    }

    cleanup() {
        if (this.browserSupported) {
            this._bc.close();
        }

    }

    send(message: string) {
        if (this.browserSupported) {
            this._bc.postMessage(message);
        }

    }
}

export default new BroadcastManager();
