import { IntegerFormatter, PercentFormatterWithDecimal } from '@/utils/utilities';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import tw from 'twin.macro';
import { StatsTableStyle } from '../../styled';
import { AggregateStats } from '@/api/simulation/getResultsAggregate';
import _ from 'lodash';
import { InteractionName } from '@/components/dashboard/dashboardUtils';

interface Props {
    data: AggregateStats[];
}

const InteractionsTable = ({ data: raw }: Props) => {

    const cleaned = useMemo(() => {
        const total = _.sumBy(raw, 'count');
        const c = _.cloneDeep(raw);
        c.forEach(o => {
            o.id = o.id ? InteractionName[o.id] : 'N/A';
            o.percentTotal = o.count / total;
        });
        return c;
    }, [ raw ]);

    const [ reverse, setReverse ] = useState(false);
    const [ sortField, setSortField ] = useState<keyof AggregateStats>('count');

    const sortedData = useMemo(() => {
        const sorted = _.orderBy(cleaned, [ sortField ], [ reverse ? 'asc' : 'desc' ]);
        return sorted;
    }, [ sortField, reverse, cleaned ]);

    const toggleSort = (field: keyof AggregateStats) => {
        if (sortField === field) return setReverse(s => !s);

        setReverse(false);
        setSortField(field);
    };

    return (
        <StatsTableStyle sortable>
            <thead>
                <tr>
                    <th>
                        <span css={tw`cursor-pointer`} onClick={() => toggleSort('id')}>
                            Interaction
                            {sortField === 'id' &&
                                <FontAwesomeIcon
                                    css={tw`ml-1 text-gray-900`}
                                    icon={reverse ? faCaretUp : faCaretDown}
                                />
                            }
                        </span>
                    </th>
                    <th css={tw`text-right whitespace-no-wrap`}>
                        <span css={tw`cursor-pointer`} onClick={() => toggleSort('count')}>
                            Count
                            {sortField === 'count' &&
                                <FontAwesomeIcon
                                    css={tw`ml-1 text-gray-900`}
                                    icon={reverse ? faCaretUp : faCaretDown}
                                />
                            }
                        </span>

                    </th>
                    <th css={tw`text-right whitespace-no-wrap`}>
                        <span css={tw`cursor-pointer`} onClick={() => toggleSort('percentTotal')}>
                            % Total
                            {sortField === 'percentTotal' &&
                                    <FontAwesomeIcon
                                        css={tw`ml-1 text-gray-900`}
                                        icon={reverse ? faCaretUp : faCaretDown}
                                    />
                            }
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {sortedData.map(o => (
                    <tr key={o.id}>
                        <td>
                            <p>{o.id}</p>
                        </td>
                        <td css={tw`text-right`}>
                            <p>{IntegerFormatter.format(o.count)}</p>
                        </td>
                        <td css={tw`text-right`}>
                            <p>{o.percentTotal && PercentFormatterWithDecimal.format(o.percentTotal)}</p>
                        </td>
                    </tr>
                ))}
            </tbody>
        </StatsTableStyle>
    );
};

export default InteractionsTable;
