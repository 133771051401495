import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components/macro';
import { ScreenerAnswers, summerRegisterIntentQuestion } from '@/components/screener/screenerQuestions';
import RadioQuestion from '@/components/survey/RadioQuestion';
import { useGetSimResults, useUpdateSimResults } from '@/api/simulation/SimulationAPI';
import { formatISO } from 'date-fns';
import { useHistory } from 'react-router';
import { Button, Spinner } from '../common';
import CourseSelection from '@/components/screener/CourseSelection';
import _ from 'lodash';
import { IsNo, IsYes } from '@/components/survey/surveyQuestions';
import { CourseOffer } from '@/api/graphql/queries/getCoursesOffered';
import { summerTerm } from '@/constants/terms';
import { InstitutionOption } from '@/api/types';
import { summerInstitutions } from '@/components/screener/summerCourseQueries';

const ContainerStyle = styled.div`
    ${tw`w-full h-full min-h-screen`}

    ${tw`pt-4 pb-4 px-4 md:px-12 max-w-screen-xl`}
`;

const CourseSelectionTableStyle = styled.div`
    display: flex;
    flex-direction: column;
    
    .header {
        margin-top: 5px;
        
        display: flex;
        
        > * {
            width: 100%;
        }
        font-weight: 500;
        span {
            display: block;
            text-align: center;
        }
    }
`;

export default () => {
    const history = useHistory();

    const { screener } = useGetSimResults();
    const updateSimResults = useUpdateSimResults();

    const [ loading, setLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>('');

    const [ formInput, setFormInput ] = useState<ScreenerAnswers>(screener);

    const [ plannedCourses, setPlannedCourses ] = useState<Array<CourseOffer | undefined>>(_.fill(Array(10), undefined));

    const [ availableInstitutions, setAvailableInstitutions ] = useState<InstitutionOption[]>([]);

    useEffect(() => {
        setAvailableInstitutions(summerInstitutions);
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setError('');
        const field = event.target.name;
        const value: string | number = event.target.value;

        setFormInput({
            ...formInput,
            [field]: value
        });
    };

    const onSubmit = (e: React.FormEvent) => {
        setError('');
        setLoading(true);
        e.preventDefault();
        e.stopPropagation();

        let filteredCourses: CourseOffer[] = _.filter(plannedCourses, o => !!o) as CourseOffer[];
        const summerCourses = filteredCourses.map(o => {
            return {
                ...o,
                term: summerTerm.id
            };
        });
        console.log('summerCourses: ', summerCourses);

        if (IsYes(formInput.summerRegisterIntent) && !filteredCourses.length) {
            setError('Please enter at least one course.');
            setLoading(false);
            return;

        } else if (IsNo(formInput.summerRegisterIntent)) {
            filteredCourses = [];
        }

        updateSimResults({
            screener: {
                ...screener,
                summerRegisterIntentCourses: summerCourses,
                summerRegisterIntent: formInput.summerRegisterIntent,
                summerRegisterIntentTs: formatISO(new Date())
            }
        }).then(() => {
            setTimeout(() => {
                history.push('/availability');
            }, 200);
        }).catch(err => {
            console.log('err: ', err);
            setLoading(false);
        });
    };

    const renderSummerCourseInputs = () => {

        return (
            <div>
                <p style={{fontWeight: 500}}>Please briefly indicate which courses you took during Summer {summerTerm.year}, entering one course per line using as many lines as needed.</p>

                <div css={tw`mt-8 flex justify-end`}>
                    <p css={tw`text-red-500 mb-2 mx-2`}>{error}</p>
                    <Button type="submit">Next</Button>
                </div>

                <CourseSelectionTableStyle>
                    <div className={'header'}>
                        <div><span>Institution</span></div>
                        <div><span>Subject</span></div>
                        <div><span>Course</span></div>
                    </div>

                    <div>
                        {
                            plannedCourses.map((id, index) => {
                                return (
                                    <CourseSelection
                                        initialValue={screener?.summerRegisterIntentCourses?.[index] ? screener?.summerRegisterIntentCourses?.[index] : undefined}
                                        institutions={availableInstitutions}
                                        key={index}
                                        onCourseSelected={(courseOffer) => {
                                            const updatedPlannedCourses = _.cloneDeep(plannedCourses);
                                            updatedPlannedCourses[index] = courseOffer;
                                            setPlannedCourses(updatedPlannedCourses);
                                        }}
                                    />
                                );
                            })
                        }
                    </div>
                </CourseSelectionTableStyle>
            </div>

        );
    };

    return (
        <ContainerStyle>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />
            <form onSubmit={onSubmit}>
                <RadioQuestion labelStyle={{ fontWeight: 500 }} selected={formInput.summerRegisterIntent} required={true} label={summerRegisterIntentQuestion.label} name={summerRegisterIntentQuestion.id} choices={summerRegisterIntentQuestion.choices} onChange={handleChange} inlineChoices={true}/>

                {
                    formInput.summerRegisterIntent === 'Y' ?
                        renderSummerCourseInputs()
                        :
                        null
                }

                <div css={tw`mt-8 flex justify-end`}>
                    <p css={tw`text-red-500 mb-2 mx-2`}>{error}</p>
                    <Button type="submit">Next</Button>
                </div>

            </form>
        </ContainerStyle>
    );
};
