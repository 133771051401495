import React, { useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import RadioQuestion from '@/components/survey/RadioQuestion';
import { Button, Spinner } from '../common';
import { useHistory } from 'react-router-dom';
import { useGetSimResults, useUpdateSimResults } from '@/api/simulation/SimulationAPI';
import { formatISO } from 'date-fns';
import {
    screenerTransferQuestion,
    unqualifiedTransferMessage
} from '@/components/screener/screenerQuestions';
import { IsYes } from '@/components/survey/surveyQuestions';
import { useStoreState } from '@/store';

const ContainerStyle = styled.div`
    ${tw`w-full h-full min-h-screen`}

    ${tw`pt-4 pb-4 px-4 md:px-12 max-w-screen-xl`}
`;

export default () => {
    const history = useHistory();

    const updateSimResults = useUpdateSimResults();
    const { screener } = useGetSimResults();

    const [ loading, setLoading ] = useState<boolean>(false);
    const [ selectedChoice, setSelectedChoice ] = useState<string>('');

    const studentInfo = useStoreState(state => state.student.studentInfo);
    const unqualifiedMessage = useMemo<string>(() => {
        if (IsYes(screener.hasExternalTransfer)) {
            return unqualifiedTransferMessage;
        }
        return '';
    }, [ studentInfo, screener ]);

    const onSubmit = (e: React.FormEvent) => {
        setLoading(true);
        e.preventDefault();
        e.stopPropagation();

        updateSimResults({
            screener: {
                ...screener,
                hasExternalTransfer: selectedChoice,
                hasExternalTransferTs: formatISO(new Date())
            }
        })
            .then(() => {
                setLoading(false);
                if (!IsYes(selectedChoice)) {
                    history.push('/consent');
                } else {

                }

            })
            .catch(err => {
                console.error('screener err: ', err);
                setLoading(false);
            });
    };

    return (
        <ContainerStyle>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />

            {
                !loading && unqualifiedMessage ?
                    <p css={tw`text-lg`}>{unqualifiedMessage}</p>
                    :
                    <form onSubmit={onSubmit}>
                        <RadioQuestion labelStyle={{ fontWeight: 500 }} selected={selectedChoice} required={true} label={screenerTransferQuestion.label} name={screenerTransferQuestion.id} choices={screenerTransferQuestion.choices} onChange={(e) => setSelectedChoice(e.target.value)} inlineChoices={true}/>

                        <div css={tw`mt-8 flex justify-end`}>
                            <Button type="submit">Next</Button>
                        </div>
                    </form>
            }
        </ContainerStyle>
    );
};
