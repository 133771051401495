export const generateEmptyResults = () => {
    return {
        availableTimes: [],
        availableTimesSubmittedTs: null,
        consentTs: null,
        lastLoggedIn: null,
        paymentInfo: null,
        paymentInfoSubmittedTs: null,
        shoppingCart: [],
        data: {
            startedTs: '',
            availableTimes: [],
            originalSubmittedCart: [],
        },
        survey: {
            work: {},
            family: {},
            timeQuality: {},
            timeQuantity: {},
            // life: {},
            major: {},
            demographics: {},
        },
        screener: {},
        stats: {},
    };
};
