import { gql } from '@apollo/client';

export default gql`
fragment conditionLineDetails on ps_rq_cond_ln_detl {
    conditionSpec: condition_spec
    lineSequence: cond_line_seq
    lineDetailSequence: cond_ln_detl_seq
    milestone
    milestoneLevel: milestone_level
    milestoneTitle: milestone_title
}
`;
