import React from 'react';
import { DividerStyle, formatTimestamp, TimestampStyle } from '@/components/simulation/SimulationDebugger';
import { ScreenerAnswers } from '@/components/screener/screenerQuestions';
import tw from 'twin.macro';
import { DebuggerTableStyle } from '@/components/simulation/SurveyDebugger';
import { IsYes } from '@/components/survey/surveyQuestions';

interface Props {
    screener: ScreenerAnswers
}

export default ({ screener }: Props) => {

    return (
        <DividerStyle>
            <h4 css={tw`mr-2`}>Screener</h4>

            <p>registerIntent: {screener.registerIntent}</p>
            <TimestampStyle>Submitted: {formatTimestamp(screener.registerIntentTs)}</TimestampStyle>

            <p css={tw`mt-4`}>summerRegisterIntent: {screener.summerRegisterIntent}</p>
            <TimestampStyle>Submitted: {formatTimestamp(screener.summerRegisterIntentTs)}</TimestampStyle>
            {
                IsYes(screener.summerRegisterIntent) ?
                    <DebuggerTableStyle>
                        <thead>
                            <tr>
                                <th>Institution</th>
                                <th>Course</th>
                                <th>Units</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                screener.summerRegisterIntentCourses.map((course, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{course.institution}</td>
                                            <td>{`${course.subject} ${course.courseNumber}`}</td>
                                            <td>{course.catalog.units}</td>
                                        </tr>
                                    );
                                })
                            }


                        </tbody>
                    </DebuggerTableStyle>
                    :
                    null
            }




        </DividerStyle>

    );
};
